import React from "react";
import PropTypes from "prop-types";
import Tab from 'react-bootstrap/Tab';
import SiteSafeTab from './sitesafe/SiteSafeTab';
import LeakSurveyTab from './leaksurvey/LeakSurveyTab';
import InvestigationTab from './investigation/InvestigationTab';
import SiteSurveyTab from './sitesurvey/SiteSurveyTab';
import FormsTab from './forms/FormsTab';
import auth from '../common/Auth';
import { withRouter, Link } from 'react-router-dom';
import OrgUtility from '../../Utility/Utility';
import TicketTab from "./Tickets/TicketTab";

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: this.props.match.params.id || "forms",
    }
  }

  componentDidUpdate(){
    // STYLE CSS by ORG
    new OrgUtility().updateOrgCSS();
  }

  componentDidMount() {
    var orgUtility = new OrgUtility();
    this.setState({orgId: orgUtility.getOrgId(), displayInvestigationData: orgUtility.displayInvestigationData()});
    this.props.forceRefresh();
    window.resize();
  }

  changeActiveItem = activeItem => {
    this.setState({ activeItem });
    this.forceUpdate();
  }

  checkAuth() {
    let retVal = false;
    if (typeof auth.isAuthenticated() === "string") {
      if (localStorage.getItem("access_token") != null) {
        let today = new Date();
        if (today < Date.parse(localStorage.getItem("expires"))) {
          retVal = true;
        }
      }
    } else {
      retVal = auth.isAuthenticated();
    }
    return retVal;
  }

  hasLicense(licenseId){
    const licensesString = auth.licenses();
    if(licensesString){
      const licenseArray = licensesString.split(',');
      if(licenseArray.indexOf(licenseId) > -1)
        return true;
      return false;
    }else{
      return false;
    }
  }

  checkSuperAdmin(){
    let isSuper = false;
    let _SuperAdminRoleId = 0;
    var roles = localStorage.getItem("roles");
    if(roles != null) { roles.split(',').forEach(element => { if (element == _SuperAdminRoleId) { isSuper = true;}});}
    return isSuper;
  }

  saveDataLocally = (resData, dashboardId) => {
    let id = `dashboard-${dashboardId}`;
    localStorage.setItem(id, JSON.stringify(resData));
  }

  getLocallySavedDashboard = dashboardId => {
    let id = `dashboard-${dashboardId}`;
    let dashboards = localStorage.getItem(id);
    if(dashboards !== null && dashboards !== undefined && dashboards !== ""){
      let p = JSON.parse(dashboards);
      if(p !== undefined && p !== null){
        return p;
      }
    }
    return false;
  }

  render() {
    const { activeItem } = this.state;
    return(
      <div id="dashboard-container" className="col-10 d-flex flex-column p-0 m-0 container container-fluid" style={{backgroundColor: "#E9E9EF", minWidth: 300}}>
        <Tab.Container activeKey={activeItem} onSelect={() => null} id="dashboard-tab-con">
          <div className="pageBar p-0 d-flex">
            {
              this.hasLicense("customforms") && (
              <Link 
                to="/dashboard/forms"
                onClick={() => this.changeActiveItem("forms")} 
                className={activeItem === "forms" ? "labelHeader barHeaderDiv selected pr-5" : "labelHeader barHeaderDiv pr-5" }
              >
                FORM DATA
              </Link>
              )
            }
            {
              this.hasLicense("ticket") && (
              <Link 
                to="/dashboard/tickets"
                onClick={() => this.changeActiveItem("tickets")} 
                className={activeItem === "tickets" ? "labelHeader barHeaderDiv selected pr-5" : "labelHeader barHeaderDiv pr-5" }
              >
                TICKETS
              </Link>
              )
            }
            {/* {
              this.hasLicense("sitesafe") &&  (
              <Link 
                to="/dashboard/sitesafe"
                onClick={() => this.changeActiveItem("sitesafe")} 
                className={activeItem === "sitesafe" ? "labelHeader barHeaderDiv selected pr-5" : "labelHeader barHeaderDiv pr-5" }
              >
                Site Safe
              </Link>
              )
            } */}
            {
             (this.hasLicense("leak")) && (
              <Link 
                to="/dashboard/leaksurvey"
                onClick={() => this.changeActiveItem("leaksurvey")} 
                className={activeItem === "leaksurvey" ? "labelHeader barHeaderDiv selected pr-5" : "labelHeader barHeaderDiv pr-5" }
              >
                GIS Survey
              </Link>
              )
            }
             {this.state.displayInvestigationData && <Link 
                to="/dashboard/investigation"
                onClick={() => this.changeActiveItem("investigation")} 
                className={activeItem === "investigation" ? "labelHeader barHeaderDiv selected pr-5" : "labelHeader barHeaderDiv pr-5" }
              >
              Investigation
              </Link>
            }
            {
              (this.hasLicense('site')) && (
                <Link
                  to="/dashboard/sitesurvey"
                  onClick={() => this.changeActiveItem("sitesurvey")}
                  className={activeItem === "sitesurvey" ? "labelHeader barHeaderDiv selected pr-5" : "labelHeader barHeaderDiv pr-5"}
                >
                  Site Survey
                </Link>
              )
            }
          </div>
          <Tab.Content className="h-100 w-100">
          {/* {
            this.hasLicense("sitesafe")&&(
            <Tab.Pane eventKey="sitesafe" className="sitesafe-tab" mountOnEnter unmountOnExit>
              <SiteSafeTab saveDataLocally={this.saveDataLocally} getLocallySavedDashboard={this.getLocallySavedDashboard} {...this.props}/>
            </Tab.Pane>
            )
          } */}
          {
            (this.hasLicense("leak"))&&(
            <Tab.Pane eventKey="leaksurvey" id="leak-survey-tab" mountOnEnter unmountOnExit>
              <LeakSurveyTab saveDataLocally={this.saveDataLocally} getLocallySavedDashboard={this.getLocallySavedDashboard}/>
            </Tab.Pane>
            )
          }
          {
            (this.hasLicense("site")) &&(
              <Tab.Pane eventKey="sitesurvey" id="site-survey-tab" mountOnEnter unmountOnExit>
                <SiteSurveyTab saveDataLocally={this.saveDataLocally} getLocallySavedDashboard={this.getLocallySavedDashboard}/>
              </Tab.Pane>
            )
          }
          {
            (this.hasLicense("customforms")) &&(
              <Tab.Pane eventKey="forms" id="forms-tab" mountOnEnter unmountOnExit>
                <FormsTab saveDataLocally={this.saveDataLocally} getLocallySavedDashboard={this.getLocallySavedDashboard}/>
              </Tab.Pane>
            )
          }
          {
            (this.hasLicense("customforms")) &&(
              <Tab.Pane eventKey="tickets" id="tickets-tab" mountOnEnter unmountOnExit>
                <TicketTab saveDataLocally={this.saveDataLocally} getLocallySavedDashboard={this.getLocallySavedDashboard}/>
              </Tab.Pane>
            )
          }
          {
            this.state.displayInvestigationData && <Tab.Pane eventKey="investigation" id="investigation-tab" mountOnEnter unmountOnExit>
              <InvestigationTab saveDataLocally={this.saveDataLocally} getLocallySavedDashboard={this.getLocallySavedDashboard}/>
            </Tab.Pane>
          }
          </Tab.Content>
        </Tab.Container>
      </div>
    );
  }
}

DashboardPage.propTypes = {
  forceRefresh: PropTypes.func,
  match : PropTypes.object,
  displayInvestigationData : PropTypes.bool
};

export default withRouter(DashboardPage);
