import React from "react";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { GLOBAL } from "../../Global";
import colors from "../../Global/colors"
import sizes from "../../Global/sizes"
import PersonTwoToneIcon from '@material-ui/icons/PersonTwoTone';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import CheeseburgerMenu from 'cheeseburger-menu'
import OrgUtility from '../../Utility/Utility'
import auth from '../common/Auth';
import { del } from 'idb-keyval';

//eslint-disable-next-line
import LanguageSharpIcon from '@material-ui/icons/LanguageSharp';
//eslint-disable-next-line
import contact from '../../../src/media/btn_contact.png';
//eslint-disable-next-line
import lps from '../../../src/media/btn_solutions.png';
//eslint-disable-next-line
import learn from "../../../src/media/btn_register.png";
//eslint-disable-next-line
import register from "../../../src/media/btn_learn.png";
//eslint-disable-next-line
import signin from "../../../src/media/btn_signin.png";
//eslint-disable-next-line
import wide from "../../../src/media/lps_feature_wide.png";
//eslint-disable-next-line
import bak from "../../../src/media/bak_login.png";
//eslint-disable-next-line
import bak4 from "../../../src/media/landing_org4.jpg";

class Header extends React.Component {

  constructor(props) {
    super(props);
    let currentCompany = "";
    let companyId = localStorage.getItem("companyId");
    if (props.companies.length > 0 && companyId) {
      let company = props.companies.find(x => x.CompanyId == parseInt(companyId));
      if (company) {
        currentCompany = company.CompanyName;
      }
    }
    this.state = {
      reportingVisibility: [],
      currentCompany,
      width: 0,
      height: 0,
      profileDiv: false,
      menuIsOpen: false,
      newUpdates: false
    };
    this.changeCompany = this.changeCompany.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.checkUpdates = this.checkUpdates.bind(this);

    this.showTopBarLinksMinWidth = 1040;
    this.headerHeight = sizes.homePageHeaderHeight;
    this.launchpointLogoContainerHeight = 85;
    this.launchpointLogoContainerWidth = 240;
    this.launchpointLogoContainerBottomBorderRadius = 40;
    this.hamburgerMenuItemStyle={
      borderRadius: 0, 
      color: colors.officialLightGray, 
      width: '100%', 
      height: '60px', 
      backgroundColor: colors.officialDarkBlue, 
      borderTop: '1px solid ' + colors.officialLightGray,
      borderBottom: '1px solid ' + colors.officialLightGray
    };
    this.hamburgerMenuSocialMediaRowStyle = {
      borderRadius: 0, 
      color: colors.officialLightGray, 
      width: '100%', 
      height: '60px', 
      backgroundColor: '#555555', 
      borderTop: '1px solid ' + colors.officialLightGray
    }
  }

  closeMenu() {
    this.setState({menuIsOpen: false});
  }

  openMenu() {
    this.setState({menuIsOpen: true});
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    var orgUtil = new OrgUtility();
    this.setState({ reportingVisibility: GLOBAL.HEADER.BREADCRUMBS, orgId: orgUtil.getOrgId(), displayInternalLinks: orgUtil.displayInternalLinks() });
    this.checkUpdates();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  userAuthenticatedAtRootPage() {
    let path = window.location.pathname;
    if (path == "/") return true && this.props.isAuth;
    return false;
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  checkUpdates(){
    var updateVersion = 0;
    var latestAppVersion = 0;
    var updateEntries = [];

    console.info('fetching updates...');
    fetch(process.env.API_URL + "/api/account/updates",{ 
      method : "POST",
      headers : {
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
    })
    .then(req =>{
      if(req.status == 200){
        req.json().then(responseJson => {
          let response = JSON.parse(responseJson);
          updateEntries = response;
          updateVersion = response.UpdateVersion;
          latestAppVersion = response.LatestAppVersion;
          
          // Check Update
          var newUpdates = false;
          if (localStorage.getItem("updateCheckCode") == null || localStorage.getItem("updateCheckCode") != updateVersion) {
            newUpdates = true;
          }

          // Set Update
          this.setState({updateCollection : response, updateVersion: updateVersion, newUpdates: newUpdates});
          localStorage.setItem("updateVersion", updateVersion);
          localStorage.setItem("latestAppVersion", latestAppVersion);
          localStorage.setItem("updateEntries", JSON.stringify(updateEntries));
        });
      }
    })
    //eslint-disable-next-line
    .catch(error =>{
      console.info(error);
      this.setState({updateStatusText : 'Something went wrong. Try again.', alertSeverity : "error"})
    })
  }

  changeCompany() {
    let i = document.getElementById("companySelect").value;
    let com = this.props.companies.filter(x => x.CompanyName == i)[0];
    if (com) {
      this.setState({ currentCompany: com.CompanyName });
      localStorage.setItem("companyId", com.CompanyId);
      del('resultData');
      del('resultSet');
      del('resultSet_LeakSurvey');
      del('resultSet_Survey');
      //delMany(['resultData','resultSet', 'resultSet_LeakSurvey','resultSet_Survey']);
      if (location.pathname != "/reporting") {
        location.reload();
      }
    }
    else {
      this.setState({ currentCompany: i });
    }
  }

  renderMarketingLinks() {
    if(window.location.pathname == "/arcgisauth") return <div></div>;
    
    if (this.userAuthenticatedAtRootPage()) {
      return <Redirect to="/reporting" />;
    }
    else if (!this.props.isAuth) {
      var hamburgerMenuButtonClass = "navbar-toggler" + (this.state.menuIsOpen ? '' : ' collapsed');
        return (
          <div className='headerRow'>
            <div id='lpLogoContainerHeader' style={{ zIndex: '1002', position: 'absolute', left:'0px', right:'0px', top: '-3px', margin: 'auto', 
            width: this.launchpointLogoContainerWidth + 'px', height: this.launchpointLogoContainerHeight + 'px', backgroundColor: colors.officialLightGray, 
            border: '2px solid #EEE', justifyContent:'center', overflow:'hidden', boxShadow:'0px 1px 2px #444' }}>
            <a 
                id="btnHome"
                href={this.state.displayInternalLinks ? '/' : '/login'}
                className="linkButtonHeader"
                style={{position: 'absolute', width: '100%', height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent'
              }}>
              <img id="headerLogoImg" src={
                this.state.orgId == 3 ? require("../../../src/media/logo_header_org3.png") : this.state.orgId == 4 ? require("../../../src/media/logo_header_org4.png") : require("../../../src/media/launchpointLogoHeader.png")
                } style={{ width: "200px" }}/>
              </a>
            </div>
            {this.state.width < this.showTopBarLinksMinWidth &&
             <div style={{ zIndex: '1001', position: 'absolute', left:this.state.width < 350 ? '-5px' :'10px', top: '21px', margin: 'auto', width: '48px', height: this.headerHeight + 'px', justifyContent:'center', alignItems:'center' }}>
                <button className={hamburgerMenuButtonClass} id="navbarButton" type="button" onClick={()=>{if(this.state.menuIsOpen){this.closeMenu()}else{this.openMenu()}}}  data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className='icon-bar top-bar'></span>
                <span className='icon-bar middle-bar'></span>
                <span className='icon-bar bottom-bar'></span>
            </button>
            </div>
            }
            {this.state.width >= this.showTopBarLinksMinWidth && 
            <div style={{ zIndex: '1001', position: 'absolute', right:'0px', top: '0px', margin: 'auto', width: '140px', height: this.headerHeight + 'px', justifyContent:'center', overflow:'hidden' }}>
              <div style={{display:'flex', flexDirection:'row', alignSelf:'center', height: '100%', alignItems:'center'}}>
              {this.state.orgId != 4 && <Button 
                    variant = "contained"
                    href={this.state.orgId == 2 ? "https://www.facebook.com/people/Vivax-Metrotech/100009316460831/" : "https://www.facebook.com/launchpointsoftwaresystems"}
                    target='_blank'
                    className="linkButtonHeader"
                    style={{borderRadius: 0, width: '34px', height: '34px', display: 'flex', backgroundColor: 'transparent',
                  }}>
                <img id="headerLogoImg" src={require("../../../src/media/socialMediaIcons/fbIcon34.png")} style={{ width: "28px", height: '28px' }}/>
              </Button>}
              <Button 
                    variant = "contained"
                    href={this.state.orgId == 2 ? "https://www.linkedin.com/company/vivax-metrotech/" : this.state.orgId == 4 ? "https://www.linkedin.com/company/pelicancorp-pty-ltd/" : "https://www.linkedin.com/company/launchpointsoftwaresystems/"}
                    target='_blank'
                    className="linkButtonHeader"
                    style={{borderRadius: 0, width: '34px', height: '34px', display: 'flex', backgroundColor: 'transparent',
                  }}>
                <img id="headerLogoImg" src={require("../../../src/media/socialMediaIcons/inIcon34.png")} style={{ width: "28px", height: '28px' }}/>
              </Button>
              {this.state.orgId != 4 && <Button 
                    variant = "contained"
                    href={this.state.orgId == 2 ? "https://www.instagram.com/vivax_metrotech/" : "https://www.instagram.com/launchpointsoftwaresystems/"}
                    target='_blank'
                    className="linkButtonHeader"
                    style={{borderRadius: 0, width: '34px', height: '34px', display: 'flex', backgroundColor: 'transparent',
                  }}>
                <img id="headerLogoImg" src={require("../../../src/media/socialMediaIcons/igIcon34.png")} style={{ width: "28px", height: '28px' }}/>
              </Button>}
              </div>
            </div>
            }
            <div className={'backgroundBase'} style={{ height: this.headerHeight + 'px', width: '100%', display: 'flex', marginBottom: '0px'}}>
              {this.state.width >= this.showTopBarLinksMinWidth && 
                <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', paddingRight: '120px'}}>
                  <div style={{ display:'flex', flexDirection:'row', alignSelf:'center', height: '100%' }}>
                    <Button 
                      variant = "contained"
                      id="btnProducts"
                      component={Link}
                      to="/products"
                      className="linkButtonHeaderCenter"
                      style={{ borderRadius: 0, color: colors.officialLightGray, backgroundColor:'transparent', marginRight: '-15px', height: '100%', visibility: this.state.displayInternalLinks ? 'initial' : 'hidden'
                    }}>
                      Products
                    </Button>
                    <Button 
                      variant = "contained"
                      id="btnLearn"
                      component={Link}
                      to="/learningcenter"
                      className="linkButtonHeaderCenter"
                      style={{ borderRadius: 0, color: colors.officialLightGray, backgroundColor:'transparent', height: '100%', visibility: this.state.displayInternalLinks ? 'initial' : 'hidden'
                    }}>
                      Learning Center
                    </Button>
                  </div>
                </div>
              }
              {this.state.width >= this.showTopBarLinksMinWidth && 
                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', paddingLeft: '120px', height: '100%' }}>
                  <Button 
                    variant = "contained"
                    id="btnRegister"
                    component={Link}
                    to="/register"
                    className="linkButtonHeaderCenter"
                    style={{ borderRadius: 0, color: colors.officialLightGray, backgroundColor:'transparent', marginRight: '-15px', height: '100%'
                  }}>
                    Free Trial
                  </Button>
                  <Button 
                    variant = "contained"
                    id="btnPricing"
                    component={Link}
                    to="/pricing"
                    className="linkButtonHeaderCenter"
                    style={{ borderRadius: 0, color: colors.officialLightGray, backgroundColor:'transparent', marginRight: '-15px', height: '100%', visibility: this.state.displayInternalLinks ? 'initial' : 'hidden'
                  }}>
                    Pricing
                  </Button>
                  <Button 
                    variant = "contained"
                    id="btnSignIn"
                    component={Link}
                    to="/login"
                    className="linkButtonHeaderCenter"
                    style={{ borderRadius: 0, backgroundColor:'transparent', height: '100%', color: this.state.displayInternalLinks ? colors.officialLightGray : '#ffc13c'
                  }}>
                    Sign In
                  </Button>
                </div>
              }
             
            </div>
            {this.state.width < this.showTopBarLinksMinWidth &&
            <CheeseburgerMenu overlayClassName={'cheesebugerMenuOverlay'} backgroundColor={'#555555'} topOffset={this.headerHeight} isOpen={this.state.menuIsOpen} closeCallback={this.closeMenu.bind(this)}>
              <div>
                <div style={{flexDirection: 'column', borderTop: '1px solid ' + colors.officialLightGray}}>
                  <Button 
                    variant = "contained"
                    id="btnProducts"
                    component={Link}
                    to="/products"
                    className="linkButtonHamburgerMenu"
                    style={this.hamburgerMenuItemStyle, {visibility: this.state.displayInternalLinks ? 'initial' : 'hidden'}}
                    onClick={this.closeMenu.bind(this)}>
                    Products
                  </Button>
                  <Button 
                    variant = "contained"
                    id="btnLearn"
                    component={Link}
                    to={"/learningcenter"}
                    className="linkButtonHamburgerMenu"
                    style={this.hamburgerMenuItemStyle, {visibility: this.state.displayInternalLinks ? 'initial' : 'hidden'}}
                    onClick={this.closeMenu.bind(this)}>
                    Learning Center
                  </Button>
                  <Button 
                      variant = "contained"
                      id="btnRegister"
                      component={Link}
                      to="/register"
                      className="linkButtonHamburgerMenu"
                      style={this.hamburgerMenuItemStyle}
                      onClick={this.closeMenu.bind(this)}>
                    Free Trial
                  </Button>
                  <Button 
                    variant = "contained"
                    id="btnPricing"
                    component={Link}
                    to="/pricing"
                    className="linkButtonHamburgerMenu"
                    style={this.hamburgerMenuItemStyle, {visibility: this.state.displayInternalLinks ? 'initial' : 'hidden'}}
                    onClick={this.closeMenu.bind(this)}>
                    Pricing
                  </Button>
                  <Button 
                    variant = "contained"
                    id="btnSignIn"
                    component={Link}
                    to="/login"
                    className="linkButtonHamburgerMenu"
                    style={this.hamburgerMenuItemStyle}
                    onClick={this.closeMenu.bind(this)}>
                    Sign In
                  </Button>
                </div>
                <div style={this.hamburgerMenuSocialMediaRowStyle}>
              <div style={{display:'flex', flexDirection:'row', alignSelf:'center', height: '100%', width: '100%', alignItems:'center'}}>
              <Button 
                    variant = "contained"
                    href={this.state.orgId == 2 ? "https://www.facebook.com/people/Vivax-Metrotech/100009316460831/" : "https://www.facebook.com/launchpointsoftwaresystems"}
                    target='_blank'
                    className="linkButtonHeader"
                    style={{borderRadius: 0, width: '100%', height: '100%', display: 'flex', backgroundColor: 'transparent',
                  }}>
                <img id="headerLogoImg" src={require("../../../src/media/socialMediaIcons/fbIcon34.png")} style={{ width: "28px", height: '28px', alignSelf:'flex-end' }}/>
              </Button>
              <Button 
                    variant = "contained"
                    href={this.state.orgId == 2 ? "https://www.linkedin.com/company/vivax-metrotech/" : "https://www.linkedin.com/company/launchpointsoftwaresystems/"}
                    target='_blank'
                    className="linkButtonHeader"
                    style={{borderRadius: 0, width: '100%', height: '100%', display: 'flex', backgroundColor: 'transparent',
                  }}>
                <img id="headerLogoImg" src={require("../../../src/media/socialMediaIcons/inIcon34.png")} style={{ width: "28px", height: '28px', alignSelf:'center' }}/>
              </Button>
              <Button 
                    variant = "contained"
                    href={this.state.orgId == 2 ? "https://www.instagram.com/vivax_metrotech/" : "https://www.instagram.com/launchpointsoftwaresystems/"}
                    target='_blank'
                    className="linkButtonHeader"
                    style={{borderRadius: 0, width: '100%', height: '100%', display: 'flex', backgroundColor: 'transparent',
                  }}>
                <img id="headerLogoImg" src={require("../../../src/media/socialMediaIcons/igIcon34.png")} style={{ width: "28px", height: '28px', alignSelf:'flex-start' }}/>
              </Button>
              </div>
            </div>
              </div>
            </CheeseburgerMenu>
            }
          </div>
        );
    } else {
      return (
        <div className="Header">
          <div className="d-flex flex-row p-0 m-0">
            <div className="col-2  p-0">
              <img id="headerLogoImg" src={this.state.orgId == 3 ? require("../../../src/media/logo_header_org3.png") : 
              this.state.orgId == 4 ? require("../../../src/media/logo_header_org4.png") : 
              require("../../../src/media/lps_feature_wide.png")} style={{ margin: 18, padding: 2, height: "46px", }} />
            </div>
            <div className="col-10 p-0 m-0">
              <div className="d-flex flex-row-reverse p-0 m-0">
                <div className="p-2">
                  {this.userAuthenticatedAtRootPage() &&
                    <Button className="buttonActionLightBorder btn btn-primary menuButton" variant="contained" id="btnSignOut" component={Link} to="/reporting"
                    ><LanguageSharpIcon className="buttonIcon" style={{ marginRight: 2 }} /> Portal
                  </Button>
                  }
                  <Button className="buttonActionLightBorder btn btn-primary menuButton" variant="contained" id="btnSignOut"
                    onClick={() => {
                      this.setState({ profileDiv: !this.state.profileDiv });
                    }}
                    title={this.state.newUpdates ? "New Updates !!" : "Profile"}>
                    <PersonTwoToneIcon className="buttonIcon profileIcon" />
                    {this.state.newUpdates ? <NewReleasesIcon className="buttonIcon newReleaseIcon" /> : null}
                    
                  </Button>
                  {this.state.profileDiv && <div className="profileDiv">
                    <ul className="profileDivUl">
                      <li className="profileDivLi" onClick={() => { window.location.href = "/support" }}>
                        <label className="labelUser profileDivLabel" >Support & Config</label>
                      </li>
                      <li className="profileDivLi" onClick={() => { window.location.href = "/updates" }}>
                        <label className="labelUser profileDivLabel" >New Updates {this.state.newUpdates ? <NewReleasesIcon className="buttonIcon newReleaseIcon" /> : "" }</label>
                      </li>
                      <li className="profileDivLi" onClick={() => { window.location.href = "/editPassword" }}>
                        <label className="labelUser profileDivLabel" >Change Password</label>
                      </li>
                      <li className="profileDivLi" onClick={() => { window.location.href = "/logout" }}>
                        <label className="labelUser profileDivLabel">Sign out</label></li>
                    </ul>
                  </div>}
                </div>
                {(this.props.isSuper || this.props.isMultiCompany) && 
                  <div>  
                    <input list="data" type="text" className="form-control companyList" id="companySelect" value={this.state.currentCompany}
                    onChange={() => { this.changeCompany() }} />
                    <datalist id="data">  {
                      this.props.companies.length > 0 && this.props.companies.map((company, index) => (
                        <option value={company.CompanyName} key={index} />
                      ))
                    }
                    </datalist>
                  </div>}
                <div className="labelUser">{this.props.userName}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    return this.renderMarketingLinks();
  }
}

Header.propTypes = {
  isAuth: PropTypes.bool,
  userName: PropTypes.string,
  onLaunchPoint: PropTypes.bool,
  companies: PropTypes.array,
  isSuper: PropTypes.bool,
  isMultiCompany: PropTypes.bool
};

export default Header;