import React from 'react';
import auth from '../../common/Auth';
import ReactGridLayout, { WidthProvider } from 'react-grid-layout';
import Button from 'react-bootstrap/Button';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import DateFnsUtils from '@date-io/date-fns';
import "react-datepicker/dist/react-datepicker.css";
import { GLOBAL } from "../../../Global";
import FormDataPage from '../../portal/FormDataPage';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText'
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateRange from '@material-ui/icons/DateRange';
import GetApp from "@material-ui/icons/GetApp";
import { BarLoader } from 'react-spinners';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListIcon from '@material-ui/icons/List';
import CountBox from '../forms/CountBox';
import EditChart from '../forms/EditChart';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import SettingsTwoTone from "@material-ui/icons/SettingsTwoTone"
import ToggleOffTwoTone from "@material-ui/icons/ToggleOffTwoTone";
import ToggleOnTwoTone from "@material-ui/icons/ToggleOnTwoTone";
import { RingLoader } from "react-spinners";
import Functions from '../../../Functions/Functions';

// Widget Imports 
import CountByTech from './CountByTech';
import CountByStatus from './CountByStatus';
import OnTimePerformance from './OnTimePerformance';
import CountByTicketType from './CountByTicketType';
import CountByMemberCode from './CountByMemberCode';
import PropTypes from 'prop-types';
import CountTagByTicketDate from './CountTagByTicketDate';
import CountTagByTagName from './CountTagByTagName';
const GridLayout = WidthProvider(ReactGridLayout);

const defaultLayout = [
  {i: "countByTech", x:0, y:1, w:6, h:5, static: false, minW: 2, maxW: 12, minH:4, maxH: 8},
  {i: "countByStatus", x:0, y:6, w:6, h:5, static: false, minW: 2, maxW: 12, minH:4, maxH: 8},
  {i: "countByTicketType", x:6, y:1, w:6, h:5, static: false, minW: 5, maxW: 12, minH:4, maxH: 8},
  {i: "countByMemberCode", x:6, y:6, w:6, h:5, static: false, minW: 5, maxW: 12, minH:4, maxH: 8},
  {i: "countTagByTicketDate", x:6, y:11, w:6, h:5, static: false, minW: 5, maxW: 12, minH:4, maxH: 8},
  {i: "countTagByTagName", x:0, y:11, w:6, h:5, static: false, minW: 2, maxW: 12, minH:4, maxH: 8},
  {i: "onTimePerformance", x:0, y:15, w:6, h:5, static: false, minW: 5, maxW: 12, minH:4, maxH: 8},
  

  {i: "count-pass-total", x:0, y: 0, w: 2, h: 1, static: false, minW: 2, maxW: 5, minH: 1, maxH: 1},
  {i: "count-pass2", x:2, y: 0, w: 2, h: 1, static: false, minW: 2, maxW: 5, minH: 1, maxH: 1},
  {i: "count-pass", x:4, y: 0, w: 2, h: 1, static: false, minW: 2, maxW: 5, minH: 1, maxH: 1},
  {i: "count-pass1", x:6, y: 0, w: 2, h: 1, static: false, minW: 2, maxW: 5, minH: 1, maxH: 1}
  
  
]

class TicketTab extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      loading: false,
      data : [],
      filterData : [],
      userCollection :[],
      loadingFilter: false,
      layout: defaultLayout,
      dateFrom: null,
      dateTo: null,
      fromPickerOpen: false,
      toPickerOpen: false,  
      searchVal: "", 
      refreshKeys:{
        countByTech: 0,
        countByTicketType : 0,
        countByMemberCode : 0,
        countByStatus : 0,
        onTimePerformance : 0,
        countTagByTicketDate : 0,
        countTagByTagName : 0
      },
      expandedCustomList : [],
      companyResponseCodes : [],
      SurveyType: ["Open", "Closed", "Emergency", "Total"],
      CountByType: [0,0,0],
      editMode : false,
      originalLayout : [],
      loadingLayout : false,
      isTableOpen : false,
      ticketAssignmentAreas : [],
      filterStatusValue : -1,
      filterTicketAssignmentAreasValue : [],
      buttonText : "View",
      selectedAreas : [],
      tagFormSearchOptions: null,
      loadingTagFormSearchOptions: false,
      CompanyArcGisLayerFeatures : [],
      filterTicketFeatures : [],
      selectedFeatures : [],
      formDataAssignmentAreaCollection : []
    }
    this.getCompanyResponseCodes = this.getCompanyResponseCodes.bind(this);
  }

  componentDidMount(){
    this.getTagFormSearchOptions();
    this.getUserList();
    this.getCompanyResponseCodes();
    this.getCompanyLayerFeatures();
    let today = new Date();  
    let d = new Date();
    today.setDate(today.getDate());
    //d.setDate(d.getDate() - 1);
    this.setState({dateFrom : d.toLocaleDateString(), dateTo: today.toLocaleDateString()} , () =>{
      let isSavedValue = this.props.getLocallySavedDashboard("tickets");
      if(isSavedValue.data && isSavedValue.dateFrom && isSavedValue.dateTo){
        let finalData = this.setTableRows(isSavedValue.data);
        this.setState({ data: finalData, tableData: finalData, originalData: finalData, dateFrom: isSavedValue.dateFrom, 
          dateTo: isSavedValue.dateTo, searchVal: isSavedValue.searchVal})
      }
      else{
        this.searchData();
      }
    })

     // GET USERS LAYOUT
     this.setState({loadingLayout: true});
     let requestBody = {
       UserId: auth.userId(),
       DashboardLayoutUpdate: false,
       User: {
         DashboardLayout: null,
         UserId: auth.userId()
       }
     };
 
     //console.log('fetch_dashboardLayout', requestBody);
 
     fetch(process.env.API_URL + "/api/account/dashboardlayout", {
       method: "POST",
       headers: {
         "Content-Type": "application/json; charset=utf-8",
         Accept: "application/json",
         Authorization: "Bearer " + auth.token()
       },
       body: JSON.stringify(requestBody)
     })
     .then(res => res.json())
     .then(response =>{
       let r = JSON.parse(response);
       //console.log('fetch_dashboardLayout_response', r);
 
       if(r.DashboardLayout){
         let layouts = JSON.parse(r.DashboardLayout);
         this.setState({ originalLayouts: layouts });
         if(layouts.ticket){
           let finalLayout = defaultLayout.map(item =>{
             let userItem = layouts.ticket.find(userItem => userItem.i === item.i);
             if(userItem){
               return { ...item, x: userItem.x, y: userItem.y, w: userItem.w, h: userItem.h};
             } else{ return item }
           });
           this.setState({ layout: finalLayout });
         }else{
           this.setState({ layout: defaultLayout });  
         }
       }else{
         this.setState({ layout: defaultLayout });
       }
     })
     .catch(error => console.log("error " + error))
     .finally(
       () => {this.setState({ loadingLayout: false}); console.log('fetch_dashboardLayout_response_finally', this.state.loadingLayout, this.state.loading);}
     );
     window.resize();
  }

  componentDidUpdate(){
    window.resize();
  }

  getTagFormSearchOptions(){
    let body = {
      CompanyId : auth.companyId(),
      FormTypeId : 5 //Tag
    }
    this.setState({loadingTagFormSearchOptions:true});
    fetch(process.env.API_URL +"/api/account/formsInfo",{
    method: "POST",
    headers:{
      "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    }).then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let tagFormSearchOptions = [];
          let json = JSON.parse(responseJson);
          if (json.ResponseType == 0){
            if (json.FormInfoCollection) {
              json.FormInfoCollection.forEach(form =>{  
                form.filterEnabled = false;
                tagFormSearchOptions.push(form);
              });
              tagFormSearchOptions.sort((a,b) => (a.FormOrder > b.FormOrder) ? 1 : -1);
              this.setState({tagFormSearchOptions, loadingTagFormSearchOptions: false});
            }
          }
        });
      } else{
        console.warn("Non 200 response on TagFormSearchOptions fetch. Status: " + r.status);
        this.setState({loadingTagFormSearchOptions:false});
      }
    }).catch(error =>{
      console.log(error.message);
      this.setState({loadingTagFormSearchOptions:false});
    })
  }

  showAdvancedSearchDialog(){
    let tagFormSearchOptions = this.state.tagFormSearchOptions ? this.state.tagFormSearchOptions : this.props.tagFormSearchOptions;
    this.setState({showAdvancedSearchDialog: true, tagFormSearchOptions});
  }

  closeAdvancedSearchDialog(){
    this.setState({showAdvancedSearchDialog: false});
  }

  resetAdvancedSearchSettings(){
    let tagFormSearchOptions = this.state.tagFormSearchOptions;
    for(var i = 0; i < tagFormSearchOptions.length; i++) tagFormSearchOptions[i].filterEnabled = false;
    this.setState({tagFormSearchOptions, advancedSearchTagsFilterApplied: false});
  }

  toggleTagFilterOption(index){
    let tagFormSearchOptions = this.state.tagFormSearchOptions;
    tagFormSearchOptions[index].filterEnabled = !tagFormSearchOptions[index].filterEnabled;
    let advancedSearchTagsFilterApplied = tagFormSearchOptions.findIndex(x=>x.filterEnabled) != -1;
    this.setState({tagFormSearchOptions, advancedSearchTagsFilterApplied});
  }

  getUserList(){
    let body = {CompanyId : auth.companyId()};
    fetch(process.env.API_URL + "/api/account/users",{
    method: "POST",
    headers:{
      "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    }).then(r =>{
      if(r.status ==  200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0 && json.UserCollection.length>0){
            let i = json.UserCollection.length;
            while(i--){
              if(json.UserCollection[i].DeletedBy != null || json.UserCollection[i].DeletedDate != null || json.UserCollection[i].DeletedDateUTCOffset != null){
                json.UserCollection.splice(i,1);
              }
            }
            Functions.sortOn(json.UserCollection, 'LastName');
            this.setState({userCollection : json.UserCollection});
          }
        })
      }
    })
  }

  getCompanyResponseCodes(){
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }

    fetch(process.env.API_URL + "/api/account/getCompanyResponseCodes",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.CompanyResponseCodes){
              this.setState({companyResponseCodes : json.CompanyResponseCodes});
            }
          }
        });
      }
    })
    .catch(error =>{
      console.log(error);
    });
  }

  openFromDate = e => {
    e.preventDefault();
    this.setState({ fromPickerOpen: true });
  }

  openToDate = e => {
    e.preventDefault();
    this.setState({ toPickerOpen: true });
  }

  setupTableRows = data => data.map(item =>({
    ...item,
    FormattedModifiedDate: new Date(item.ModifiedDate + "Z").toLocaleString("en-us", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"}),
    FormattedCreatedDate: new Date(item.CreatedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"}),
    //DetailsBtn: <Link to={{pathname: "/survey", survey: item}} className="anchorButton padded">DETAILS</Link>,
    DetailsBtn: <Link to={{ pathname: '/formdata', leakSurvey: item, formData : item}} className="anchorButton padded">DETAILS</Link>,
  }))

  viewEntity(element){

    //console.log('viewEntity', element);

    // copy entity the entity with parse and stringify trick, fixes by-reference use bugs
    this.setState({viewingDetails: true, entityToView: {FormData: JSON.parse(JSON.stringify(element))} });
  }

  saveLayout = (newLayout) => {
    //console.log('saveLayout()', newLayout);

    this.setState({ layout: newLayout});
    setTimeout(() =>{this.setState({draggingElement : false })},250)
    let requestBody = {
      UserId: auth.userId(),
      DashboardLayoutUpdate: true,
      User: {
        DashboardLayout: JSON.stringify({
          ...this.state.originalLayouts,
          ticket: newLayout
        }),
        UserId: auth.userId()
      }
    };

    fetch(process.env.API_URL + "/api/account/dashboardlayout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        Authorization: "Bearer " + auth.token()
      },
      body: JSON.stringify(requestBody)
    })
    .then(res => res.json())
    .then(()=>{})
    .catch(error => console.log('error' + error));
  }

  //eslint-disable-next-line
  handleResizeChange = (layout, oldItem, newItem, placeholder, e, element) => {
    e.preventDefault();
    
    // changes refresh keys of widgets based on their id
    let item = newItem.i;
    this.setState({
      refreshKeys: {...this.state.refreshKeys, [item]: this.state.refreshKeys[item] + 1}
    });
    this.saveLayout(layout);
  }

  handleSearchChange = e => {
    e.preventDefault();
    this.setState({ searchVal: e.target.value });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.searchData();
  }

  getCompanyLayerFeatures(){
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      Web : true,
      TicketArea : true
    }
    fetch(process.env.API_URL + "/api/account/getCompanyArcGisLayers",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.FeatureCollection){
              this.setState({CompanyArcGisLayerFeatures : json.FeatureCollection, filterTicketFeatures : JSON.parse(JSON.stringify(json.FeatureCollection)), selectedFeatures : JSON.parse(JSON.stringify(json.FeatureCollection))});
            }
          }
        })
      }
    })
    .catch(error =>{
      console.log(error);
    })
  }

  // MAIN SEARCH FUNCTION
  searchData = () => {
    const { searchVal, dateFrom, dateTo } = this.state;
    this.setState({ loading: true});
    let withAttachedTagFormIds = this.state.tagFormSearchOptions ? this.state.tagFormSearchOptions.filter(x=>x.filterEnabled).map(x=>x.FormId) : [];
    let rb = {
      CompanyId : auth.companyId(),
      SearchParam : searchVal,
      UserId : auth.employeeId(),
      DateFrom : dateFrom ? dateFrom : null,
      DateTo : dateTo ? dateTo : null,
      WebSearch : true,
      UTCOffset: - new Date().getTimezoneOffset() /60,
      UseModifiedDate: this.state.useModifiedDate,
      TicketSearch : true,
      WithAttachedTagFormIds: withAttachedTagFormIds
    }
    console.time('search');
    fetch(process.env.API_URL + "/api/searchmin", {
      method: "POST",
      headers : {
        Accept : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(rb)
    })
    .then(res => res.json())
    .then(r => {
      if(r.length > 0){
        let response = JSON.parse(r);
        console.timeEnd('search');
        console.log('searchData()::', response);
        let ticketData = response.TicketDataSearchCollection;
        let forms = response.FormCollection;

        let countByType = [0,0,0];
        let ticketAssignmentAreas = [];
        ticketData.forEach(ticket =>{
          if(ticket.AssignmentAreaLabels && ticket.AssignmentAreaLabels != ""){
            let splitLabels = ticket.AssignmentAreaLabels.split(',');
            splitLabels.forEach(label =>{
              if(!ticketAssignmentAreas.includes(label)){
                ticketAssignmentAreas.push(label);
              }
            })
          }
          if(ticket.TicketDisplayValues && ticket.TicketDisplayValues != ''){
            let split = ticket.TicketDisplayValues.split("|");
            if(split[2] == "Emergency")countByType[2]++;
          }
          if(ticket.FormTaskStatus == 0){
            countByType[0]++;
          }
          else if(ticket.FormTaskStatus == 1){
            countByType[1]++;
          }
        });
        let finalData = this.setupTableRows(ticketData);
        this.setState({data: ticketData,ticketAssignmentAreas, formDataAssignmentAreaCollection : response.FormDataAssignmentAreaCollection, selectedAreas : JSON.parse(JSON.stringify(ticketAssignmentAreas)), filterTicketAssignmentAreasValue : JSON.parse(JSON.stringify(ticketAssignmentAreas)), originalData: finalData, tableData: finalData, CountByType: countByType, forms, isTableOpen:false, filterMessage:"", allTableData:[], allTableDataCount:0}, () => {
          this.filterData();
        });
        this.setState({entityToView: ticketData[0]});

        const { dateTo, dateFrom, searchVal } = this.state;
        this.props.saveDataLocally({dateTo, dateFrom, searchVal, data: ticketData}, "formdata");
      }
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() =>{
      this.setState({ loading : false});
    })
  }

  editMode = () => {
    this.setState({editMode:!this.state.editMode});
  }

  toggleToDatePicker = () => {
    this.setState({ toPickerOpen: !this.state.toPickerOpen });
  };
  
  toggleFromDatePicker = () => {
    this.setState({ fromPickerOpen: !this.state.fromPickerOpen });
  };

  toHandleChange = selectedDate => {
    selectedDate.setHours(new Date().getHours())
    selectedDate.setTime(selectedDate.getTime());
    this.setState({ dateTo: selectedDate.toLocaleDateString()});
  };

  fromHandleChange = selectedDate => {
    selectedDate.setHours(new Date().getHours())
    selectedDate.setTime(selectedDate.getTime());
    this.setState({ dateFrom: selectedDate.toLocaleDateString()});
  };

  filterStatusUpdate(e){
    this.setState({filterStatusValue : e.target.value}, this.filterData(e.target.value));
  }

  selectTicketArea(area){
    let selectedAreas = this.state.selectedAreas;
    let selectedFeatures = this.state.selectedFeatures;
    let allFeatures = JSON.parse(JSON.stringify(this.state.CompanyArcGisLayerFeatures));
    if(area == "ANY"){
      //REMOVE
      if(selectedAreas.length == this.state.ticketAssignmentAreas.length && selectedFeatures.length == this.state.CompanyArcGisLayerFeatures.length){
        selectedAreas = [];
        selectedFeatures = [];
      }
      //ADD ALL
      else{
        selectedAreas = JSON.parse(JSON.stringify(this.state.ticketAssignmentAreas));
        selectedFeatures = allFeatures;
      }
    }
    else if(area.CompanyArcGISLayerId && area.FeatureId){
      let foundIndex = selectedFeatures.findIndex(x => x.CompanyArcGISLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId);
      //remove
      if(foundIndex != -1){
        selectedFeatures.splice(foundIndex, 1);
      }
      //ADD
      else{
        selectedFeatures.push(area);
      }
    }
    else{
      let foundIndex = selectedAreas.indexOf(area);
      //REMOVE
      if(foundIndex != -1){
        selectedAreas.splice(foundIndex,1);
      }
      //ADD
      else{
        selectedAreas.push(area);
      }
    }
    this.setState({selectedAreas, selectedFeatures},()=>{
      this.setState({filterTicketAssignmentAreasValue : selectedAreas, filterTicketFeatures : selectedFeatures}, this.filterData(null,selectedAreas, selectedFeatures));
    });
  }

  filterData(filterStatusValue, filterTicketAssignmentAreasValue, filterTicketFeatures, filterTypeValue){
    let filterData = [];
    let data = this.state.data;
    if(!filterStatusValue) filterStatusValue = this.state.filterStatusValue;
    if(!filterTicketAssignmentAreasValue) filterTicketAssignmentAreasValue = this.state.filterTicketAssignmentAreasValue;
    if(!filterTicketFeatures) filterTicketFeatures = this.state.filterTicketFeatures;
    if(!filterTypeValue) filterTypeValue = this.state.filterTypeValue;
    let anyArea = filterTicketAssignmentAreasValue.length == this.state.ticketAssignmentAreas.length && filterTicketFeatures.length == this.state.CompanyArcGisLayerFeatures.length;
    data.forEach(item =>{
      let inAselectedArea = false;
      if(item.AssignmentAreaLabels && item.AssignmentAreaLabels != ""){
        let splitLabels = item.AssignmentAreaLabels.split(',');
        splitLabels.forEach(label =>{
          if(filterTicketAssignmentAreasValue.includes(label)){
            inAselectedArea = true;
          }
        })
      }
      let inSelectedFeature = false;
      if(filterTicketFeatures && filterTicketFeatures.length > 0 && this.state.formDataAssignmentAreaCollection && this.state.formDataAssignmentAreaCollection.length > 0){
        filterTicketFeatures.forEach(feature =>{
          if(this.state.formDataAssignmentAreaCollection.find(x => x.FormDataId == item.FormDataId && x.CompanyArcGisLayerId == feature.CompanyArcGISLayerId && x.FeatureObjectId == feature.FeatureId) != null){
            inSelectedFeature = true;
          }
        })
      }
      let timeTillDue = 0;
      if(item.DueDate){
        let dueDate = new Date(item.DueDate);
        if(item.CloseDate){
          timeTillDue = (dueDate - new Date(item.CloseDate)) /1000;
        }
        else if(item.FormTaskStatus != 3){
          timeTillDue = (dueDate - new Date())/1000 ;
        }
      }
      let ticketType = -1;
      try{
        if(item.TicketDisplayValues && item.TicketDisplayValues != ''){
          let split = item.TicketDisplayValues.split("|");
          ticketType = split[2];
        }
        //if (props.formData != undefined) formId = props.formData.find(function (element) {return element.FormId == item.FormId;}).Name;
      }
      catch(eat){
        //eat
      }
      if((filterStatusValue == -1 || (filterStatusValue == "Open" && item.FormTaskStatus == 0) || (filterStatusValue == "Closed" && item.FormTaskStatus == 1)|| (filterStatusValue == "Project" && item.FormTaskStatus == 2)|| (filterStatusValue == "Canceled" && item.FormTaskStatus == 3) || (filterStatusValue == "PastDue" && timeTillDue < 0)|| (filterStatusValue == "OnTime" && timeTillDue >= 0)) &&
      //( !filterTypeValue || (filterTypeValue == ticketType )) && Don't fliter by ticket type. That should just filter by date only. 
      (anyArea || inAselectedArea || inSelectedFeature)){
        filterData.push(item);
      }
    });
    this.setState({filterData},() => {
      if(this.state.unfilteredFormDatacollection && this.state.unfilteredFormDatacollection.length > 0){
        this.setupData(this.state.unfilteredFormDatacollection).then(r => {this.setState({loadingFilter: false, allTableData:r});});
      }
    } );
  }

  filterTable = (filter, message) => {
    console.log('filter', filter);
    if(filter.filterType == "ByDateTicket" && filter.filterSubType == "status"){
      this.setState({filterStatusValue : filter.filterTicketType, filterTypeValue : null}, () =>{
        this.filterData(filter.filterTicketType);
        this.subQuery(filter.filterType, filter.filterValue);
        this.setState({isTableOpen: true, filterMessage: message});
      } );
    }
    else if(filter.filterType == "ByDateTicket" && filter.filterSubType == "onTimePerformance"){
      this.setState({filterStatusValue : filter.filterTicketType, filterTypeValue : null}, () =>{
        this.filterData(filter.filterTicketType);
        this.subQuery(filter.filterType, filter.filterValue);
        this.setState({isTableOpen: true, filterMessage: message});
      } );
    }
    else if(filter.filterType == "ByDateTicket" && filter.filterSubType == "type"){
      this.setState({filterTypeValue : filter.filterTicketType}, () =>{
        this.filterData(null,null,null,filter.filterTicketType);
        this.subQuery(filter.filterType, filter.filterValue, filter.filterTicketType);
        this.setState({isTableOpen: true, filterMessage: message});
      });
    }
    else{
      this.setState({filterTypeValue : null}, () =>{
        this.subQuery(filter.filterType, filter.filterValue);
        this.setState({isTableOpen: true, filterMessage: message});
      });
    }
    
  }

  async subQuery(filterType, filterValue){
    this.setState({ loadingFilter: true});
    let withAttachedTagFormIds = this.state.tagFormSearchOptions ? this.state.tagFormSearchOptions.filter(x=>x.filterEnabled).map(x=>x.FormId) : [];

    var dateTo = this.state.dateTo;
    var dateFrom = this.state.dateFrom;
    if(filterType == "ByDateTicket" && filterValue != null){
      dateTo = filterValue;
      dateFrom = filterValue;
    }
    let rb = {
      CompanyId : auth.companyId(),
      SearchParam : this.state.searchVal,
      DataFilterType: filterType,
      DataFilterValue: filterValue,
      UserId : auth.employeeId(),
      DateFrom : dateFrom,
      DateTo :  dateTo,
      UseModifiedDate: this.state.useModifiedDate,
      WebSearch : true,
      UTCOffset: - new Date().getTimezoneOffset() /60,
      TicketSearch : true,
      WithAttachedTagFormIds: withAttachedTagFormIds
    }
    this.lastSubQueryParams = rb;

    console.time('searchDataFilter');
    fetch(process.env.API_URL + "/api/searchDataFilter", {
      method: "POST",
      headers : {
        Accept : "application/json", 
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(rb)
    })
    .then(res => res.json())
    .then(async r => {
      if(r.length > 0){
        let response = JSON.parse(r);
        console.timeEnd('searchDataFilter');
        console.log('getDataFilter()::', response);

        var formDataCollection = response.FormDataCollection;
        var formTaskCollection = response.FormTaskCollection;
        var formDataAssignmentAreaCollection = response.FormDataAssignmentAreaCollection;
        formDataCollection.forEach(formData => {
          const formDataId = formData.FormTypeId == 5 ? formData.ParentFormDataId : formData.FormDataId;
          formData.FormTask = formTaskCollection.find(x => x.FormDataId == formDataId);
          formData.FormDataAssignmentAreas = formDataAssignmentAreaCollection.filter(x => x.FormDataId == formDataId);
          if(formData.FormTypeId == 4 && this.state.data){
            const matchingFormData = this.state.data.find(x=>x.FormDataId == formData.FormDataId);
            formData.AttachedTagNames = (matchingFormData && matchingFormData.AttachedTagNames) ? matchingFormData.AttachedTagNames.replaceAll('|', ', ') : null; 
          }
        })
        this.setupData(formDataCollection).then(r => {this.setState({loadingFilter: false, allTableData:r, unfilteredFormDatacollection : formDataCollection});});
      }
    });
  }

  setupData = async (formDataCollection, blockSetState) => {
    
    // PARSE ROW DATA
    var data, rows, cols, newRow, newRowClean, colsAll;
    var allTableData = [];
    var tableData = {};
    let filterTicketAssignmentAreasValue = this.state.filterTicketAssignmentAreasValue;
    let filterTicketFeatures = this.state.filterTicketFeatures;
    let anyArea = filterTicketAssignmentAreasValue.length == this.state.ticketAssignmentAreas.length && filterTicketFeatures.length == this.state.CompanyArcGisLayerFeatures.length;
    // SEPARATE DATA BY FORMID
    formDataCollection.forEach(e => {
      let isInArea = false;
      if(e.FormDataAssignmentAreas && e.FormDataAssignmentAreas.length > 0){
        e.FormDataAssignmentAreas.forEach(area =>{
          if(filterTicketAssignmentAreasValue.includes(area.Label)){
            isInArea = true;
          }
        });
      }
      let inSelectedFeature = false;
      if(filterTicketFeatures && filterTicketFeatures.length > 0 && this.state.formDataAssignmentAreaCollection && this.state.formDataAssignmentAreaCollection.length > 0){
        filterTicketFeatures.forEach(feature =>{
          if(this.state.formDataAssignmentAreaCollection.find(x => x.FormDataId == e.FormDataId && x.CompanyArcGisLayerId == feature.CompanyArcGISLayerId && x.FeatureObjectId == feature.FeatureId) != null){
            inSelectedFeature = true;
          }
        })
      }
      let timeTillDue = 0;
      let filterStatusValue = this.state.filterStatusValue;
      
      let filterTypeValue = this.state.filterTypeValue;
      if(e.FormTask && e.FormTask.DueDate){
        let dueDate = new Date(e.FormTask.DueDate);
        if(e.FormTask.CloseDate){
          timeTillDue = (dueDate - new Date(e.FormTask.CloseDate)) /1000;
        }
        else if(e.FormTask.FormTaskStatus != 3){
          timeTillDue = (dueDate - new Date())/1000 ;
        }
      }
      let ticketType = -1;
        try{
          if(e.TicketDisplayValues && e.TicketDisplayValues != ''){
            let split = e.TicketDisplayValues.split("|");
            ticketType = split[2];
          }
        }
        catch(eat){
          //eat
        }
      if((filterStatusValue == -1 || (filterStatusValue == "Open" && e.FormTask && e.FormTask.FormTaskStatus == 0) || (filterStatusValue == "Closed" && e.FormTask && e.FormTask.FormTaskStatus == 1) || (filterStatusValue == "Project" && e.FormTask && e.FormTask.FormTaskStatus == 2)|| (filterStatusValue == "Canceled" && e.FormTask && e.FormTask.FormTaskStatus == 3)|| (filterStatusValue == "PastDue" && timeTillDue < 0) || (filterStatusValue == "OnTime" && timeTillDue >= 0)) &&
      // ( !filterTypeValue || (filterTypeValue == ticketType )) && Don't filter by type anymore.
      (anyArea || isInArea || inSelectedFeature)){
        // FORMAT ROW DATA and push to collection
        tableData = allTableData.filter(obj => {return obj.name === e.Name})[0];
        if (tableData == undefined) { tableData = {name: e.Name, rows: [], cols:[{field:"Details", label:""}], colsAll:[], }; allTableData.push(tableData); }

        rows = tableData.rows;
        cols = tableData.cols;
        colsAll = tableData.colsAll;

        newRow = {Details: <button className="anchorButton submitButton survey padded small" onClick={() => 
          {this.viewEntity(e);}}><div className="text-center full">{this.state.buttonText + (e.ParentFormDataId ? " Tag" : "")}</div></button>};


        if(e.ParentFormDataId){
          if (cols.length < 10 && cols.filter(obj => {return obj.field === "TagTicketViewButton"}).length == 0) cols.push({field: "TagTicketViewButton", label: ""});
          if (colsAll.filter(obj => {return obj.field === "TagTicketViewButton"}).length == 0) colsAll.push({field: "TagTicketViewButton", label: ""});
          if (cols.length < 10 && cols.filter(obj => {return obj.field === "Ticket Number"}).length == 0) cols.push({field: "Ticket Number", label: "Ticket Number"});
          if (colsAll.filter(obj => {return obj.field === "Ticket Number"}).length == 0) colsAll.push({field: "Ticket Number", label: "Ticket Number"});
          if (cols.length < 10 && cols.filter(obj => {return obj.field === "Rev"}).length == 0) cols.push({field: "Rev", label: "Rev"});
          if (colsAll.filter(obj => {return obj.field === "Rev"}).length == 0) colsAll.push({field: "Rev", label: "Rev"});
          newRow["TagTicketViewButton"] = 
            <button className="anchorButton submitButton survey padded short" onClick={() => {this.viewEntity({FormDataId: e.ParentFormDataId, FormTypeId: 4});}}>
              <div className="text-center full">View Ticket</div>
            </button>;
          if(e.ParentFieldsValues){
            const parentFieldsValues = JSON.parse(e.ParentFieldsValues);
            const ticketNumberField = parentFieldsValues.find(x=>x.Name == "Ticket Number");
            const revField = parentFieldsValues.find(x=>x.Name == "Rev");
            newRow["Ticket Number"] = (ticketNumberField && ticketNumberField.Value != null) ? ticketNumberField.Value : "";
            newRow["Rev"] = (revField && revField.Value != null) ? revField.Value : "";
          }
          if (colsAll.filter(obj => {return obj.field === "ParentFormDataId"}).length == 0) colsAll.push({field: "ParentFormDataId", label: "ParentFormDataId"});
          newRow["ParentFormDataId"] = e.ParentFormDataId.toString();
        }

        data = JSON.parse(e.FieldsValues);
        data.forEach(field => {
          if (field.Type != "Section" && field.Type != "Signature" && field.Type != "Scanner"){
            let fieldName = field.Name;
            let fieldValue = field.Value;
            if (cols.length < 10 && cols.filter(obj => {return obj.field === fieldName}).length == 0) cols.push({field: fieldName, label: fieldName});
            if (colsAll.filter(obj => {return obj.field === fieldName}).length == 0) colsAll.push({field: fieldName, label: fieldName});
            newRow[fieldName] = fieldValue;
          }
        });

        if(e.AttachedTagNames){
          if (cols.filter(obj => {return obj.field === "Tags"}).length == 0) cols.push({field: "Tags", label: "Tags"});
          if (colsAll.filter(obj => {return obj.field === "Tags"}).length == 0) colsAll.push({field: "Tags", label: "Tags"});
          newRow["Tags"] = e.AttachedTagNames;
        }
        if (colsAll.filter(obj => {return obj.field === "FormDataId"}).length == 0) colsAll.push({field: "FormDataId", label: "FormDataId"});
        newRow["FormDataId"] = e.FormDataId.toString();
        rows.push(newRow);
      }
      
    });

    // ALL TABLES row count
    var allTableDataCount = 0;
    allTableData.forEach(tab => {
      allTableDataCount += tab.rows.length;
    });
    if(!blockSetState) this.setState({allTableDataCount});

    return allTableData;
  }

  toggleTableOpen = e => {
    e.preventDefault();
    this.setState({ isTableOpen: !this.state.isTableOpen});
  }

  cancelFilters = e => {
    e.preventDefault();
    this.setState({ tableData: this.setupTableRows(this.state.originalData), filterMessage: ""})
  }

  openDetailsView = e => {
    this.setState({viewingDetails:!this.state.viewingDetails});
  }

  downloadData = async e => {
    e.preventDefault();

    let tagTableData = [];
    let tablesWithTags = this.state.allTableData.filter(x=>x.colsAll.findIndex(x=> x.field == 'Tags') > -1);

    if(tablesWithTags.length > 0){  
      let rb = this.lastSubQueryParams;
      rb.isMatchingTagSearch = true;
      console.time('searchDataFilter');
      let tagSearchResponse = await fetch(process.env.API_URL + "/api/searchDataFilter", {
        method: "POST",
        headers : {
          Accept : "application/json", 
          "Content-Type" : "application/json; charset=utf-8",
          Authorization : "Bearer " + auth.token()
        },
        body : JSON.stringify(this.lastSubQueryParams)
      });
      tagSearchResponse = await tagSearchResponse.json();
      if(tagSearchResponse.length > 0){
        tagSearchResponse = JSON.parse(tagSearchResponse);
        console.timeEnd('searchDataFilter');
        console.log('getDataFilter()::', tagSearchResponse);

        var tagFormDataCollection = tagSearchResponse.FormDataCollection;
        var tagFormTaskCollection = tagSearchResponse.FormTaskCollection;
        var formDataAssignmentAreaCollection = tagSearchResponse.FormDataAssignmentAreaCollection;
        tagFormDataCollection.forEach(formData => {
          formData.FormTask = tagFormTaskCollection.find(x => x.FormDataId == formData.ParentFormDataId);
          formData.FormDataAssignmentAreas = formDataAssignmentAreaCollection.filter(x => x.FormDataId == formData.ParentFormDataId);
        });

        // let finalTagFormDataCollection = [];

        // finalTagFormDataCollection = tagFormDataCollection.filter(x=> this.state.allTableData.findIndex(y=> y.rows.findIndex(z=>z.FormDataId == x.ParentFormDataId) > -1) > -1);
        tagTableData = await this.setupData(tagFormDataCollection, true);
        //this.setupData(formDataCollection).then(r => {this.setState({loadingFilter: false, allTableData:r, unfilteredFormDatacollection : formDataCollection});});
      }
    }

    let allTableData = tagTableData.length > 0 ? tagTableData.concat(this.state.allTableData) : this.state.allTableData;


    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    var JSZip = require("jszip");
    var zip = new JSZip();
    
    var fileName, data, fileType;
    let today = new Date;
    var zipFileName = today.getFullYear() + " " + month[today.getMonth()] + " " + today.getDay() + "__" + this.state.filterMessage + ".zip";

    allTableData.forEach(table => {
      fileName = table.name.replace(/\/+$/, '');
      fileType = 'text/csv';
      data = this.arrayToCSV(table.rows, table.colsAll.filter(x=> x.field != 'TagTicketViewButton'));
      console.log('data', data);
      const blobdata = new Blob([data], { type: fileType });
      zip.file(table.name + '.csv', blobdata);      
    });
    

    // DOWNLOAD ZIP FILE
    zip.generateAsync({type: "blob"}).then(content => {
      const a = document.createElement('a');
      a.download = zipFileName;
      a.href = window.URL.createObjectURL(content);
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      a.dispatchEvent(clickEvt);
      a.remove();
    });
  }

  downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  }

  arrayToCSV(objArray, colsAll) {

    var headersStr = colsAll.map(value => value.label).join(",") + '\r\n';    
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;    
    objArray.forEach(row => {
      headersStr += colsAll.map(col => `"${row[col.label] || ""}"`).join(",") + '\r\n';
    });

    return headersStr;
  }

  closeEntity = () => {
    this.openDetailsView();
  }

  saveCharts = () => {
    console.log('saveCharts');
  }

  render(){

    return(
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div>

        {/* DETAILS DIALOG */}
        <Dialog open={this.state.viewingDetails} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth='lg' fullWidth='true' scroll='paper'>
          {/* <DialogTitle id="alert-dialog-title">{""}</DialogTitle> */}
          <DialogContent>
            <FormDataPage userCollection={this.state.userCollection} location={{formData: this.state.entityToView}} closeEntity={() =>{this.closeEntity()}} fromDashboard={true} hideClose={true}/>
          </DialogContent>
          <DialogActions>
            <Button className="buttonAction btn btn-primary" onClick={this.openDetailsView} color="primary">
              <label className="buttonText small" style={{ margin: 0, cursor: 'pointer'}}>Close</label>
            </Button>
          </DialogActions>
        </Dialog>

        {/* ADVANCED SEARCH OPTIONS DIALOGE} */}
        {(this.state.tagFormSearchOptions && this.state.tagFormSearchOptions.length > 0) && 
          <Dialog open={this.state.showAdvancedSearchDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth='xs' fullWidth='false' scroll='paper'>
            <DialogTitle id="alert-dialog-title">{"Advanced Search Settings"}</DialogTitle>
            <DialogContent>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center', textAlign:'center', paddingBottom: 5}}>
                <span style={{fontWeight: 'bolder', textDecoration:'underline', fontSize: 20, paddingTop: 5}}>TAGS</span>
                <span>If tags selected below, search will only return tickets<br/>with one or more of the selected tags attached</span>
              </div>
              {this.state.tagFormSearchOptions.map((tagFormOption, index) => ( 
                <div style={{marginTop: 5, marginBottom: 5, display: 'flex', justifyContent:'center', alignItems: 'center'}} className="col-12" key={index}>
                  <Button style={{verticalAlign:'middle', marginTop: 5, marginBottom: 5, width: 250, right: 0, left: 0}} className="buttonAction" onClick={()=>{this.toggleTagFilterOption(index)}}>
                    <div style={{width: 20}}>
                      {tagFormOption.filterEnabled ?
                        <RadioButtonCheckedIcon className="tagFilterRadioButton"/>
                        :
                        <RadioButtonUncheckedIcon className="tagFilterRadioButton"/>
                      }
                    </div>
                    <div style={{flex: 1}}>
                      <label className="buttonText withIcon" style={{margin:0, cursor: 'pointer'}}>{tagFormOption.Name}</label>
                    </div>
                  </Button>
                </div>
              ))}
              {this.state.savingNewTagData &&
                  <div style={{marginTop: 15, marginBottom: 15, display: 'flex', justifyContent:'center', alignItems: 'center'}} className="col-12">
                  <BarLoader sizeUnit={"px"} width={250} size={250} color={"#095399"} loading={true}/>
                </div>
              }
              {this.state.savingNewTagDataErrorMessage &&
                <div style={{marginTop: 5, marginBottom: 5, display: 'flex', justifyContent:'center', alignItems: 'center', textAlign:'center'}} className="col-12">
                  <span>{this.state.savingNewTagDataErrorMessage}</span>
                </div>
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={this.resetAdvancedSearchSettings.bind(this)} className="buttonAction btn btn-primary" color="primary">
                <label className="buttonText small" style={{ margin: 0, cursor: 'pointer'}}>Reset</label>
              </Button>
              <Button onClick={this.closeAdvancedSearchDialog.bind(this)} className="buttonAction btn btn-primary" color="primary">
                <label className="buttonText small" style={{ margin: 0, cursor: 'pointer'}}>Close</label>
              </Button>
            </DialogActions>
          </Dialog>
        }

        {/* SEARCH BAR */}
        <div className="w-100 d-flex flex-row justify-content-center align-items-center counters-container">
          {!this.state.editMode ? 
            <div className="d-flex align-items-center flex-row" style={{paddingRight:10, width:500}}>
              <input onChange={this.handleSearchChange} value={this.state.searchVal} className="form-control searchInput" type="search" placeholder="Search"/>
              <Button onClick={this.handleSubmit} className="buttonAction btn btn-primary buttonSearch">
                <SearchSharpIcon className="buttonIcon light large"/>
              </Button>
            </div> : <label className="labelHeader" style={{ marginTop: 0, marginLeft: 0, justifyContent:'flex-start', flex:1 }}>EDIT charts</label>
          }
          {(!this.state.editMode && ((this.state.tagFormSearchOptions != null && this.state.tagFormSearchOptions.length > 0) || this.state.loadingTagFormSearchOptions)) &&
          <Button disabled={this.state.loadingTagFormSearchOptions} className="buttonAction btn-primary buttonAdvancedSearchOptions" style={{marginLeft: -8, marginRight: 10}} onClick={() => this.showAdvancedSearchDialog()}>
            {this.state.loadingTagFormSearchOptions ?
              <div style={{paddingBottom: 5, paddingLeft: 3}}>
                <RingLoader color={"white"} loading={true} size={20}/>
              </div>
            :
              <div style={{paddingBottom: 5, paddingLeft: 3}}>
                <SettingsTwoTone fontSize="medium" style={{color: "#b3bec8e0", marginBottom: -5}}/>
                {this.state.advancedSearchTagsFilterApplied ?
                  <ToggleOnTwoTone fontSize="medium" style={{color: "#15BF15", marginTop: -5}}/>
                :
                  <ToggleOffTwoTone fontSize="medium" style={{color: "#b3bec8e0", marginTop: -5}}/>
                }
              </div>
            }
          </Button>
        }
          <div className="d-flex flex-row">
            <select id="filterStatus" type="text"
            onChange={(e) =>{this.filterStatusUpdate(e)}} className="form-control filterInput short" value={this.state.filterStatusValue}>
              <option value='-1' defaultValue>Any Status</option>
              <option value='Open'>Open</option>
              <option value='Closed'>Closed</option>
              <option value='Project'>Project</option>
              <option value='Canceled'>Canceled</option>
              <option value='PastDue'>Past Due</option>
            </select>
          </div>
          {((this.state.ticketAssignmentAreas && this.state.ticketAssignmentAreas.length > 0) || (this.state.CompanyArcGisLayerFeatures && this.state.CompanyArcGisLayerFeatures.length > 0)) && 
            <div id={"assignmentAreasList"} className="dropdown-check-list-searchstrip" style={{marginTop: 5}}>
              <span className="anchor itemsFont" style={{maxWidth:150, minHeight: 41}} onClick={() =>{
                let checkList = document.getElementById("assignmentAreasList");
                if(checkList.classList.contains("visible")) checkList.classList.remove("visible");
                else checkList.classList.add("visible");
                }}><span style={{top: 11, position: 'absolute', color:'#7b7b7b'}}>Ticket Areas</span>
              </span>
              <ul className="items itemsFont" style={{width:277, maxHeight:500}}>
                <li style={{fontWeight: 700, color: '#16548e'}} className="itemLine">
                <input checked={this.state.ticketAssignmentAreas.length == this.state.selectedAreas.length && this.state.CompanyArcGisLayerFeatures.length == this.state.selectedFeatures.length} onChange={() =>{this.selectTicketArea("ANY");}} type="checkbox"/>
                <div style={{maringLeft : 5, display: "inline", cursor : "pointer"}} onClick={() =>{this.selectTicketArea("ANY");}}> All Areas</div>
                </li>
                {this.state.ticketAssignmentAreas.map((area, i) => !area.HideFromDropDown && 
                  <li key={i} style={{}} className="itemLine">
                  <input checked={this.state.selectedAreas.includes(area)} onChange={() =>{this.selectTicketArea(area);}} type="checkbox"/>
                  <div style={{maringLeft : 5, display: "inline", cursor : "pointer"}} onClick={() =>{this.selectTicketArea(area);}}> {area}</div>
                </li>)}
                {this.state.CompanyArcGisLayerFeatures.map((area, i) => (
                  <li key={i} style={{}} className="itemLine">
                    <input checked={this.state.selectedFeatures.find(x => x.CompanyArcGISLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId) != null} onChange={() =>{this.selectTicketArea(area);}} type="checkbox"/>
                    <div style={{maringLeft : 5, display: "inline", cursor : "pointer"}} onClick={() =>{this.selectTicketArea(area);}}> {area.FeatureName} </div>
                  </li>
                ))

                }
              </ul>
            </div>

           }

          {/* DATETIME EDIT */}
          {!this.state.editMode ? 
            
            <div className="d-flex flex-row ">

              {/* FROM PICKER */}
              <Button className="buttonAction btn btn-primary dehighlighted" onClick={this.toggleFromDatePicker}  >
                <DateRange className="buttonIcon light" style={{marginRight:4, marginBottom:2}}/>
                <label className="buttonText withIcon small" id="FromDate" style={{ margin: 0, cursor: "pointer" }}>
                  {this.state.dateFrom}
                </label>
              </Button>
              <label className="labelSubHeader4 labelSearchBar" >-</label>
              {this.state.fromPickerOpen && ( <DatePicker value={this.state.dateFrom} onChange={this.fromHandleChange} onClose={ () => {this.setState({fromPickerOpen: false})}} open={this.state.fromPickerOpen} />)}
              
              {/* TO PICKER */}
              <Button className="buttonAction btn btn-primary dehighlighted" onClick={this.toggleToDatePicker}  >
                <DateRange className="buttonIcon light" style={{marginRight:4, marginBottom:2}}/>
                <label className="buttonText withIcon small" id="ToDate" style={{ margin: 0, cursor: "pointer" }}>
                  {this.state.dateTo}
                </label>
              </Button>
              { this.state.toPickerOpen && ( <DatePicker value={this.state.dateTo} onChange={this.toHandleChange} onClose={ () => {this.setState({toPickerOpen : false})}} open={this.state.toPickerOpen} /> )}
              
              <div className="dateOptionDiv">
                <input id="useCreatedDateButton" className='dateOptionRadio' type = "radio"checked={!this.state.useModifiedDate} onChange={() => {this.setState({useModifiedDate: false})}}/>
                <label htmlFor="useCreatedDateButton" className='dateOptionLabel'>Created Date</label>
                <br/>
                <input id="useModifiedDateButton" className='dateOptionRadio'type = "radio"  checked={this.state.useModifiedDate} onChange={() => {this.setState({useModifiedDate: true})}}/>
                <label htmlFor="useModifiedDateButton" className='dateOptionLabel'>Modified Date</label>
              </div>

            </div> : null

           
          }

        </div>

        <div id="forms-tab-container" style={{ overflowY: "hidden"}}>

          {/* LOADING MSG */}
          {this.state.loading || this.state.loadingLayout ? 
            <div className="row mapspin">
              <div className="spinnerDiv">
                <div className="authSpinnerDiv">
                  <div className="authSpinnerDivMessage">{this.state.loading ? "Loading...": "Loading Layout..."}</div>
                  <div className="barLoaderPortal">
                    <BarLoader sizeUnit={"px"} size={150} color={"#095399"} loading={true}/>
                  </div>
                </div>
              </div>
            </div>: null}
          {
            this.state.loading || this.state.loadingLayout || this.state.editMode
            ? null
            :
            <div id="forms-content-area" className="h-100">
              <div className="d-flex flex-column justify-content-center align-items-center" 
                style={{ height: this.state.isTableOpen ? "45%": 'calc(100% - 40px)'}}>
                
                {/* Draggable Area */}
                <GridLayout
                  cols={12}
                  id="dashboard-forms-grid"
                  className="w-100 h-100 d-flex justify-content-between hide-vertical-scrollbar"
                  layout={this.state.layout}
                  onLayoutChange={this.saveLayout}
                  rowHeight={65}
                  margin={[10, 10]}
                  autoSize={false}
                  onResizeStop={this.handleResizeChange}
                  onDragStop={()=>{this.setState({draggingElement : true})}}
                >
                  <div key="countByTech">
                    <CountByTech filterCallback={this.filterTable} 
                    refreshKey={this.state.refreshKeys.countByTech} 
                    data={this.state.filterData} 
                    formData={this.state.forms} 
                    dateTo={this.state.dateTo} 
                    dateFrom={this.state.dateFrom}
                    useModified={this.state.useModifiedDate}
                    />
                  </div>

                  <div key ="countByTicketType">
                    <CountByTicketType filterCallback={this.filterTable} 
                    refreshKey={this.state.refreshKeys.countByTicketType} 
                    data={this.state.filterData} 
                    formData={this.state.forms} 
                    dateTo={this.state.dateTo} 
                    dateFrom={this.state.dateFrom}
                    useModified={this.state.useModifiedDate}/>
                  </div>
                  
                  <div key="countByMemberCode">
                    <CountByMemberCode filterCallback={this.filterTable} 
                    companyResponseCodes={this.state.companyResponseCodes}
                    refreshKey={this.state.refreshKeys.countByMemberCode} 
                    data={this.state.filterData} 
                    formData={this.state.forms} 
                    dateTo={this.state.dateTo} 
                    dateFrom={this.state.dateFrom}
                    useModified={this.state.useModifiedDate}/>
                  </div>

                  <div key ="countByStatus">
                    <CountByStatus filterCallback={this.filterTable} 
                    refreshKey={this.state.refreshKeys.countByStatus} 
                    data={this.state.filterData} 
                    formData={this.state.forms} 
                    dateTo={this.state.dateTo} 
                    dateFrom={this.state.dateFrom}
                    useModified={this.state.useModifiedDate}/>
                  </div>
                  
                  <div key ="countTagByTicketDate">
                    <CountTagByTicketDate filterCallback={this.filterTable} 
                    refreshKey={this.state.refreshKeys.countTagByTicketDate} 
                    data={this.state.filterData} 
                    formData={this.state.forms} 
                    dateTo={this.state.dateTo} 
                    dateFrom={this.state.dateFrom}
                    useModified={this.state.useModifiedDate}/>
                  </div>

                  <div key ="countTagByTagName">
                    <CountTagByTagName filterCallback={this.filterTable} 
                    refreshKey={this.state.refreshKeys.countTagByTicketDateange} 
                    data={this.state.filterData} 
                    formData={this.state.forms} 
                    dateTo={this.state.dateTo} 
                    dateFrom={this.state.dateFrom}
                    useModified={this.state.useModifiedDate}/>
                  </div>
                  

                  <div key ="onTimePerformance">
                    <OnTimePerformance filterCallback={this.filterTable} 
                    refreshKey={this.state.refreshKeys.onTimePerformance} 
                    data={this.state.filterData} 
                    formData={this.state.forms} 
                    dateTo={this.state.dateTo} 
                    dateFrom={this.state.dateFrom}
                    useModified={this.state.useModifiedDate}/>
                  </div>

                  <div key="count-pass-total" className="border border-dark count-pass">
                    <CountBox draggingElement={this.state.draggingElement} TicketTab={true} filterCallback={this.filterTable} id={3} label={this.state.SurveyType[3]} surveyTypes={this.state.SurveyType} count={this.state.data.length} 
                      styleclass="border-right text-white border-dark h-100 m-0 d-flex justify-content-center align-items-center surveyBlock"/>
                  </div>
                  <div key="count-pass2" className="border border-dark count-pass ">
                    <CountBox draggingElement={this.state.draggingElement} TicketTab={true} filterCallback={this.filterTable} id={2 } label={this.state.SurveyType[2]} surveyTypes={this.state.SurveyType} count={this.state.CountByType[2]} 
                      styleclass="border-right text-white border-dark h-100 m-0 d-flex justify-content-center align-items-center emergencyCountBlock"/>
                  </div>
                  <div key="count-pass" className="border border-dark count-pass">
                    <CountBox draggingElement={this.state.draggingElement} TicketTab={true} filterCallback={this.filterTable} id={0} label={this.state.SurveyType[0]} surveyTypes={this.state.SurveyType} count={this.state.CountByType[0]} 
                      styleclass="border-right text-white border-dark h-100 m-0 d-flex justify-content-center align-items-center openCountBlock" />
                  </div>

                  <div key="count-pass1" className="border border-dark count-pass">
                    <CountBox draggingElement={this.state.draggingElement} TicketTab={true} filterCallback={this.filterTable} id={1} label={this.state.SurveyType[1]} surveyTypes={this.state.SurveyType} count={this.state.CountByType[1]} 
                      styleclass="border-right text-white border-dark h-100 m-0 d-flex justify-content-center align-items-center closedCountBlock"/>
                  </div>

                </GridLayout>
              </div>
              
              {/*Toggle Button */}
              <button onClick={this.toggleTableOpen} className="toggleButton text-center border d-flex align-items-center justify-content-center w-100">
               
                
                {this.state.isTableOpen ? <ExpandMoreIcon title="Open" style={{color:'#797979'}}/> : <ExpandLessIcon title="Close" style={{color:'#797979'}}/>} 
                <label style={{margin:0, cursor: 'pointer'}} className="labelHeader barHeaderDiv pr-5 org1baseText org1basetextHover"> TABLE DATA </label>
                
              
                <div className="datatable-filter-message">
                  <span className='filterMessage'>{this.state.filterMessage ? "Filter: " + this.state.filterMessage : null}</span>
                  {this.state.isTableOpen ? <ExpandMoreIcon title="Open" style={{color:'#797979'}}/> : <ExpandLessIcon title="Close" style={{color:'#797979'}}/>} 
                </div>

              </button>

              {!this.state.filterMessage ? null : <div className="dashboardDownloadDiv">
                <Button onClick={this.downloadData} className="buttonAction buttonAccount vsmall btn btn-primary" style={{background:'#406a91'}}>
                <GetApp className="buttonIcon light"/>
                  <label style={{margin:0, cursor: 'pointer'}} className="buttonText withIcon">DOWNLOAD DATA</label>
                </Button></div>}

              {/*Datatable Wrapper */}
              {this.state.isTableOpen && <div className="chartTableMainDiv">
                <div style={{ height: "calc(55% - 40px)", overflowY: "scroll"}}>
                  {/* LOADING FILTER MSG */}
                  {this.state.loadingFilter ? 
                    <div className="row mapspin">
                      <div className="spinnerDiv">
                        <div className="authSpinnerDiv">
                          <div className="authSpinnerDivMessage">{this.state.loading ? "Loading...": "Loading Filtered Data..."}</div>
                          <div className="barLoaderPortal">
                            <BarLoader sizeUnit={"px"} size={150} color={"#095399"} loading={true}/>
                          </div>
                        </div>
                      </div>
                    </div>: null}

                  {!this.state.loadingFilter && this.state.allTableData && this.state.allTableData.map((td, f_index) => (
                    <div key={"triggerGroup"+f_index} className="dashboardFormTriggerGroup">

                      
                      <div className='formsTableHeader'
                        onClick ={() =>{
                          let expanded = this.state.expandedCustomList;
                          if(expanded.includes(td.name)){
                            let index = expanded.indexOf(td.name);
                            expanded.splice(index, 1);
                          }
                          else{
                            expanded.push(td.name)
                          }
                          this.setState({expandedCustomList: expanded});
                          }}>
                        <div className="formsTableHeaderCount">
                          {td.rows.length}
                        </div>
                        {td.name}
                        {this.state.expandedCustomList && this.state.expandedCustomList.includes(td.name) ? <KeyboardArrowUpIcon className="buttonIcon padded"/>  :
                              <KeyboardArrowDownIcon className="buttonIcon padded"/>}
                      </div>

                      <MDBDataTable
                          id="forms-dashboard-table"
                          className="w-100 formdata-dashboard-table"
                          data={{ columns: td.cols, rows: td.rows || []}}
                          style={{display: this.state.expandedCustomList && this.state.expandedCustomList.includes(td.name) ? '' : 'none' }}
                          small responsiveMd searching={true} btn striped
                          bordered hover sortable paging={false} searchLabel="⁪"
                        />

                    </div>
                  ))}
                  
                </div>
              </div>}
            </div>
          }
          {
            this.state.loading || this.state.loadingLayout ? null :
            <div id='forms-edit' className="h-100">
              {/* <EditChart save={this.saveCharts()} formCollection={this.state.FormCollection}/> */}
            </div>
          }
        </div>

      </div>
      </MuiPickersUtilsProvider>
    )
  }

}

TicketTab.propTypes = {
  getLocallySavedDashboard: PropTypes.func,
  saveDataLocally : PropTypes.func
}

export default TicketTab;