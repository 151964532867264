import React from 'react';
import { Link } from "react-router-dom";
import { BarLoader } from "react-spinners"; 
import { Button } from "react-bootstrap";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import OrgUtility from '../../Utility/Utility'
import PropTypes from 'prop-types';

class Register extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {
      width : 0,
      height : 0,
      buttonBackgroundColor: "#095399",
      currentForm : "companyName",
      cardName : "",
      cardNumber : "",
      cardMonth : "",
      cardYear : "",
      companyName :"",
      contactNameFirst : "",
      contactNameLast : "",
      contactEmail : "",
      subscriptionPlan : "",
      contactNumber : "",
      contactState : "",
      CVC : "",
      hover : false,
      companyNameColor : "black",
      contactNameFirstColor : "black",
      contactNameLastColor : "black",
      contactEmailColor : "black",
      cardNameColor : "black",
      cardNumberColor : "black",
      cardYearColor : "black",
      cardMonthColor : "black",
      cvcColor : "black",
      contactNumberColor : "black",
      contactStateColor : "black",
      loading : false,
      eulaAccepted : false,
      statusText : "",
      siteScreeningTrial : false,
      states : ["","Alaska","Alabama","Arkansas","Arizona","California","Colorado","Connecticut","District of Columbia",
      "Delaware","Florida","Georgia","Hawaii","Iowa","Idaho","Illinois","Indiana","Kansas","Kentucky","Louisiana",
      "Massachusetts","Maryland","Maine","Michigan","Minnesota","Missouri","Mississippi","Montana","North Carolina",
      "North Dakota","Nebraska","New Hampshire","New Jersey","New Mexico","Nevada","New York","Ohio","Oklahoma",
      "Oregon","Pennsylvania","Rhode Island","South Carolina","South Dakota","Tennessee","Texas","Utah","Virginia",
      "Vermont","Washington","Wisconsin","West Virginia","Wyoming"]
    }
    this.goToNextForm = this.goToNextForm.bind(this);
    this.registerAccount = this.registerAccount.bind(this);
    this.changeCardNumber = this.changeCardNumber.bind(this);
    this.changeCardYear = this.changeCardYear.bind(this);
    this.changeCardMonth = this.changeCardMonth.bind(this);
    this.changeCardName = this.changeCardName.bind(this);
    this.changeCVC = this.changeCVC.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidUpdate(){
    // STYLE CSS by ORG
    new OrgUtility().updateOrgCSS();
  }

  componentDidMount(){
    this.updateWindowDimensions();
    window.addEventListener('resize',this.updateWindowDimensions);
    this.props.swap();

    var orgUtil = new OrgUtility();
    this.setState({orgId: orgUtil.getOrgId(), AppName: orgUtil.getAppName(), displayInternalLink: orgUtil.displayInternalLinks()});
    
  }

  componentWillUnmount(){
    window.removeEventListener('resize',this.updateWindowDimensions);
  }

  updateWindowDimensions(){
    this.setState({width: window.innerWidth, height: window.innerHeight});
  }

  learnMore(){
    // Redirect to homepage with solutions details
    window.location.href='/'
  }

  registerAccount(){
    
    let required = true;
    if(!this.state.companyName || this.state.companyName == ""){ required = false; this.setState({companyNameColor : "red"})}
    if(!this.state.contactNameFirst || this.state.contactNameFirst == ""){ required = false; this.setState({contactNameFirstColor : "red"});}
    if(!this.state.contactNameLast || this.state.contactNameLast == ""){ required = false; this.setState({contactNameLastColor : "red"});}
    if(!this.state.contactEmail || this.state.contactEmail == ""){ required = false; this.setState({contactEmailColor : "red"});}
    if(!this.state.contactNumber || this.state.contactNumber == ""){ required = false; this.setState({contactNumberColor : "red"});}

    if (required) {
      let req = new XMLHttpRequest();
      req.open( "POST", process.env.API_URL + "/api/account/registeraccount", true);
      
      let body = {
        CompanyName: this.state.companyName,
        ContactNameFirst: this.state.contactNameFirst,
        ContactNameLast: this.state.contactNameLast,
        ContactState: this.state.contactState,
        Email: this.state.contactEmail,
        SiteScreeningTrial: this.state.siteScreeningTrial,
        PhoneNumber: this.state.contactNumber,
        OrgId: this.state.orgId
      }
      req.setRequestHeader("Content-Type", "application/json");
      req.setRequestHeader("Accept","application/json");
      req.onload = () =>{
        if(req.status == 200){
          let response = JSON.parse(JSON.parse(req.responseText));
          if(response.ResponseType == 0){
            this.setState({currentForm  : "", loading : false, statusText : ""});
          }
          else{
            let errorMessages = "";
            for(let i =0; i < response.ResponseMessageCollection.length; i++){
              if(response.ResponseMessageCollection[i].Message != null && response.ResponseMessageCollection[i].Message != undefined && response.ResponseMessageCollection[i].Message !="undefined"){
                errorMessages += "\n" + response.ResponseMessageCollection[i].Message;
              }

            }
            this.setState({loading : false, statusText : "There was a problem creating your account." + errorMessages});
          }
        }
        else{
          this.setState({loading : false, statusText : "There was a problem creating your account. Check your information and try again."});
        }
        this.setState({siteScreeningTrial : false})
      }
      

      req.onerror = () =>{ this.setState({loading : false, statusText : "There was a problem creating your account. Check your information and try again"}); }
      req.send(JSON.stringify(body));
      this.setState({loading : true, statusText : "CREATING YOUR ACCOUNT..."});
    }
    else{
      this.setState({siteScreeningTrial : false});
    }
  }

  goToNextForm(){
    let required = true;
    switch(this.state.currentForm){
      case "companyName" :
        if(!this.state.companyName || this.state.companyName == ""){
          required = false;
          this.setState({companyNameColor : "red"})
        }
        if(!this.state.contactNameFirst || this.state.contactNameFirst == ""){
          required = false;
          this.setState({contactNameFirstColor : "red"});
        }
        if(!this.state.contactNameLast || this.state.contactNameLast == ""){
          required = false;
          this.setState({contactNameLastColor : "red"});
        }
        if(!this.state.contactEmail || this.state.contactEmail == ""){
          required = false;
          this.setState({contactEmailColor : "red"});
        }
        if(required){
          this.setState({currentForm : "creditCard"});
        }
        break;

      default:
        this.setState({currentForm : "companyName"});
        break;
    }
  }

  changeCardNumber(num) {
    if (num.length > this.state.cardNumber.length){
      let pureNum = num.replace(/-/g,'');
      if( (pureNum.length >0 && pureNum.length <15) && pureNum.length % 4 ==0 ){
        num = num + "-";
      }
    }
    else if(this.state.cardNumber[this.state.cardNumber.length-1] == '-'){
      num = num.slice(0,num.length -1);
    }
    
    this.setState({ cardNumber: num, cardNumberColor : "black" });
  }

  changeCardYear(year) {
    this.setState({ cardYear: year, cardYearColor : "black" });
  }

  changeCardMonth(month) {
    this.setState({ cardMonth: month, cardMonthColor : "black" });
  }

  changeCardName(name) {
    this.setState({ cardName: name, cardNameColor : "black" });
  }

  changeCVC(cvc){
    if(cvc.length <= 3){
      this.setState({ CVC : cvc, cvcColor : "black" });
    }
  }
   
  render(){
    if(this.state.width < 800){

      return (
          <div className="container-fluid" style={{position : 'relative', bottom : 50}}>
            <div className ="row">
              <div className = "col-12 d-flex justify-content-center mt-5 loginTitle">
                <label className ="mainHeader">{this.state.AppName}</label>
                <label className ="subHeader" style = {{marginBottom : 5}}>Account Registration</label>
                <label className ="subHeader" style = {{marginBottom : 5, color:'#779'}}>30 Day Free Trial</label>
              </div>
            </div>
            <div className ="row">
            <div className ="col-12 loginDialogRow1" style={{marginBottom:100}} >
              {this.state.currentForm == "companyName" ?
              <div>
                <label  htmlFor = "companyName" className="formLabel smallLabel" style = {{color : this.state.companyNameColor}}>Company Name</label>
                <input id ="companyName"
                  onChange = {(e) =>{this.setState({companyName : e.target.value, companyNameColor : "black"})}}
                  value = {this.state.companyName}
                  placeholder = ""
                  className = "form-control"
                />
                <label htmlFor = "contactNameFirst" className="formLabel smallLabel" style ={{ color : this.state.contactNameFirstColor}}>Contact Name First</label>
                <input id = "contactNameFirst"
                  onChange = {(e) =>{this.setState({contactNameFirst : e.target.value, contactNameFirstColor : "black"})}}
                  value = {this.state.contactNameFirst}
                  placeholder = ''
                  className = "form-control"
                />
                <label htmlFor = "contactNameLast" className="formLabel smallLabel" style = {{color : this.state.contactNameLastColor}}>Contact Name Last</label>
                <input id ="contactNameLast"
                  onChange = {(e) =>{this.setState({contactNameLast : e.target.value, contactNameLastColor : "black"})}}
                  value = {this.state.contactNameLast}
                  placeholder = ""
                  className = "form-control"
                />
                <label htmlFor = "contactEmail" className="formLabel smallLabel" style = {{color : this.state.contactEmailColor}}>Contact Email</label>
                <input id = "contactEmail"
                  onChange = {(e) =>{this.setState({contactEmail : e.target.value, contactEmailColor : "black"})}}
                  value = {this.state.contactEmail}
                  placehold = ""
                  className = "form-control"
                />
                <label htmlFor = "contactNumber" className="formLabel smallLabel" style = {{color : this.state.contactNumberColor}}>Contact Phone Number</label>
                <input id = "contactNumber"
                  onChange = {(e) =>{this.setState({contactNumber : e.target.value, contactNumberColor : "black"})}}
                  value = {this.state.contactNumber}
                  placehold = ""
                  className = "form-control"
                />

                <div style={{display:'flex'}}>
                  {this.state.displayInternalLink ? 
                  <div className="registrationTypeDiv">
                  {/* <label className="accountHeader registrationLabel small"> <AssignmentTwoToneIcon className="buttonIcon withLabel" style={{color:'#4591af', marginBottom:0, transform:'scale(1.2)', height:30, marginRight:15}}/> Site Survey Investigation</label>
                  <label style={{marginLeft:10, marginRight:5, marginBottom:15 , minHeight : 100}} htmlFor = "contactEmail" className="formLabel smallLabel">Collects and reports site survey and incident investigation data</label> */}
                  <Button onClick ={()=>{this.learnMore()}} className ="buttonAction btn btn-primary small light" style={{margin:'auto', width:'50%'}}>
                      <label className ="buttonText small">Learn More</label>
                  </Button>
                  <Button onClick ={()=>{this.setState({showEULA : true})}} className ="buttonAction btn btn-primary" style={{width:'100%'}}>
                      <label className ="buttonText">Start free trial {this.state.displayInternalLink}</label>
                  </Button>
                </div>: 
                <div className="registrationTypeDiv">
                  <Button onClick ={()=>{this.setState({showEULA : true})}} className ="buttonAction btn btn-primary" style={{width:'100%'}}>
                      <label className ="buttonText">Start free trial {this.state.displayInternalLink}</label>
                  </Button>
                </div>}
                  
                </div>

                <div className = "row d-flex align-items-center justify-content-center registerMessage" >
                  <div className ="col-12 d-flex align-items-center justify-content-center text-center authSpinnerDivClean">
                  {this.state.statusText} 
                  <div className="barLoaderPortal">
                    <BarLoader
                    sizeUnit={"px"}
                    size={10}
                    color={"#095399"}
                    loading={ this.state.loading }
                    /></div>
                  </div>
                </div>
              </div> : <div style={{margin: 30}}> Your Account has been created, check your email for sign-in information. </div>
              }
            </div>
          </div>
          {this.state.currentForm == "companyName" ? <div></div>
            : <Link to = "/login">
            <div className ="row d-flex align-items-center justify-content-center">
              <Button className ="buttonLogin btn btn-primary signInRegister" style ={{
                  width: "100%", 
                  borderRadius: "0px",
                  backgroundColor: this.state.buttonBackgroundColor,
                  borderLeft: "2px solid #ffffff",
                  borderRight: "2px solid #ffffff",
                  borderBottom: "2px solid #ffffff",
                  borderTop: "2px solid #ffffff !important",
                  cursor: "pointer",
                  paddingTop: "10px !important",
                  margin: "0px !important"}}
                  onMouseOver ={() =>{this.setState({buttonBackgroundColor: "#0069D9"})}}
                  onMouseOut={()=>{this.setState({buttonBackgroundColor: "#095399"})}}>
                <label style ={{margin: 0, cursor: "pointer", fontSize:15 }} className = "buttonText">SIGN IN</label>
              </Button>
            </div>
          </Link>
          }
          <Dialog open={this.state.showEULA} onClose={()=>{}}>
          <DialogTitle>{'END USER LICENSE AGREEMENT ("EULA")'}</DialogTitle>
          
          {(this.state.orgId == 1 || this.state.orgId == 4) && 
          <DialogContent>
            <Typography gutterBottom align="center" variant="h5" fontWeight="bold">LaunchPoint Licensed Applications</Typography>
            <Typography gutterBottom align="left">Applications (“Apps”) made available from LaunchPoint are licensed, not sold, to you. Your license to LaunchPoint’s App is subject to your prior acceptance of this Licensed Application End User License Agreement (“EULA”). Your license to any LaunchPoint App under this EULA is a license granted by LaunchPoint. Any App that is subject to this EULA is referred to herein as the “Licensed Application.” LaunchPoint (“Licensor”) reserves all rights in and to the Licensed Application no rights are expressly or indirectly granted to you under this EULA.</Typography>
            <Typography gutterBottom align="left">a.Scope of License.Licensor grants to you a nontransferable license to use the Licensed Application on any products(mobile or otherwise) that you own or control.The terms of this EULA will govern any content, materials, or services accessible from or purchased within the Licensed Application as well as upgrades provided by Licensor that replace or supplement the original Licensed Application.You may not distribute or make the Licensed Application available over a network where it could be used by multiple devices at the same time.You may not transfer, redistribute or sublicense the Licensed Application and, if you sell any devices, upon which you have downloaded the Licensed Application, to a third party, you must remove the Licensed Application from the device before doing so. You may not copy(except as permitted by this license), reverse - engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Licensed Application, any updates, or any part thereof(except as and only to the extent that any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the licensing terms governing use of any open - sourced components included with the Licensed Application).</Typography>
            <Typography gutterBottom align="left">b.Consent to Use of Data.You agree that Licensor may collect and use technical data and related information—including but not limited to technical information about your device, system and application software, and peripherals—that is gathered periodically to facilitate the provision of software updates, product support, and other services to you(if any) related to the Licensed Application.Licensor may use this information, as long as it is in a form that does not personally identify you, to improve its products or to provide services or technologies to you.</Typography>
            <Typography gutterBottom align="left">c.Third Party Vendors.Licensor may utilize third party vendor software, products and materials in providing its Apps, materials, products and services to you.Such third party vendors may include, but are not limited to, Microsoft Corporation.You agree to adhere to the third party vendor terms of use, privacy policies, end user license agreements and any other agreements as they relate to your use of LaunchPoint’s products and services.</Typography>
            <Typography gutterBottom align="left">d.Termination.This EULA is effective until terminated by you or Licensor.Your rights under this EULA will terminate automatically if you fail to comply with any of its terms.</Typography>
            <Typography gutterBottom align="left">e.External Services. The Licensed Application may enable access to Licensor’s and/ or third - party services and websites(collectively and individually, &quot;External Services&quot;).You agree to use the External Services at your sole risk. Licensor is not responsible for examining or evaluating the content or accuracy of any third - party External Services, and shall not be liable for any such third - party External Services.Data displayed by any Licensed Application or External Service, including but not limited to financial, medical and location information, is for general informational purposes only and is not guaranteed by Licensor or its agents.You will not use the External Services in any manner that is inconsistent with the terms of this EULA or that infringes the intellectual property rights of Licensor or any third party.You agree not to use the External Services to harass, abuse, stalk, threaten or defame any person or entity, and that Licensor is not responsible for any such use.External Services may not be available in all languages or in your Home Country, and may not be appropriate or available for use in any particular location.To the extent you choose to use such External Services, you are solely responsible for compliance with any applicable laws.Licensor reserves the right to change, suspend, remove, disable or impose access restrictions or limits on any External Services at any time without notice or liability to you.</Typography>
            <Typography gutterBottom align="left">f.NO WARRANTY: YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE LICENSED APPLICATION IS AT YOUR SOLE RISK.TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE LICENSED APPLICATION AND ANY SERVICES PERFORMED OR PROVIDED BY THE LICENSED APPLICATION ARE PROVIDED &quot;AS IS&quot; AND “AS AVAILABLE,” WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND LICENSOR HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE LICENSED APPLICATION AND ANY SERVICES, EITHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND / OR CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND OF NONINFRINGEMENT OF THIRD - PARTY RIGHTS.NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY LICENSOR OR ITS AUTHORIZED REPRESENTATIVE SHALL CREATE A WARRANTY.SHOULD THE LICENSED APPLICATION OR SERVICES PROVE DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION.SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.</Typography>
            <Typography gutterBottom align="left">g.Limitation of Liability.TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL LICENSOR BE LIABLE FOR PERSONAL INJURY OR ANY INCIDENTAL, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OF OR INABILITY TO USE THE LICENSED APPLICATION, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY(CONTRACT, TORT, OR OTHERWISE) AND EVEN IF LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU.In no event shall Licensor’s total liability to you for all damages(other than as may be required by applicable law in cases involving personal injury) exceed the amount of fifty dollars($50.00). The foregoing limitations will apply even if the above stated remedy fails of its essential purpose.</Typography>
            <Typography gutterBottom align="left">h.This Agreement and the relationship between you and Licensor shall be governed by the laws of the State of Illinois, excluding its conflicts of law provisions.You and Licensor agree to submit to the personal and exclusive jurisdiction of the courts located within the county of Peoria, Illinois, to resolve any dispute or claim arising from this Agreement.</Typography>
          </DialogContent>}

          {this.state.orgId == 3 && 
          <DialogContent>
            <Typography gutterBottom align="center" variant="h5" fontWeight="bold">UtiliSource Licensed Applications</Typography>
            <Typography gutterBottom align="left">Applications (“Apps”) made available from UtiliSource are licensed, not sold, to you. Your license to UtiliSource’s App is subject to your prior acceptance of this Licensed Application End User License Agreement (“EULA”). Your license to any UtiliSource App under this EULA is a license granted by UtiliSource. Any App that is subject to this EULA is referred to herein as the “Licensed Application.” UtiliSource (“Licensor”) reserves all rights in and to the Licensed Application no rights are expressly or indirectly granted to you under this EULA.</Typography>
            <Typography gutterBottom align="left">a.Scope of License.Licensor grants to you a nontransferable license to use the Licensed Application on any products(mobile or otherwise) that you own or control.The terms of this EULA will govern any content, materials, or services accessible from or purchased within the Licensed Application as well as upgrades provided by Licensor that replace or supplement the original Licensed Application.You may not distribute or make the Licensed Application available over a network where it could be used by multiple devices at the same time.You may not transfer, redistribute or sublicense the Licensed Application and, if you sell any devices, upon which you have downloaded the Licensed Application, to a third party, you must remove the Licensed Application from the device before doing so. You may not copy(except as permitted by this license), reverse - engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Licensed Application, any updates, or any part thereof(except as and only to the extent that any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the licensing terms governing use of any open - sourced components included with the Licensed Application).</Typography>
            <Typography gutterBottom align="left">b.Consent to Use of Data.You agree that Licensor may collect and use technical data and related information—including but not limited to technical information about your device, system and application software, and peripherals—that is gathered periodically to facilitate the provision of software updates, product support, and other services to you(if any) related to the Licensed Application.Licensor may use this information, as long as it is in a form that does not personally identify you, to improve its products or to provide services or technologies to you.</Typography>
            <Typography gutterBottom align="left">c.Third Party Vendors.Licensor may utilize third party vendor software, products and materials in providing its Apps, materials, products and services to you.Such third party vendors may include, but are not limited to, Microsoft Corporation.You agree to adhere to the third party vendor terms of use, privacy policies, end user license agreements and any other agreements as they relate to your use of UtiliSource’s products and services.</Typography>
            <Typography gutterBottom align="left">d.Termination.This EULA is effective until terminated by you or Licensor.Your rights under this EULA will terminate automatically if you fail to comply with any of its terms.</Typography>
            <Typography gutterBottom align="left">e.External Services. The Licensed Application may enable access to Licensor’s and/ or third - party services and websites(collectively and individually, &quot;External Services&quot;).You agree to use the External Services at your sole risk. Licensor is not responsible for examining or evaluating the content or accuracy of any third - party External Services, and shall not be liable for any such third - party External Services.Data displayed by any Licensed Application or External Service, including but not limited to financial, medical and location information, is for general informational purposes only and is not guaranteed by Licensor or its agents.You will not use the External Services in any manner that is inconsistent with the terms of this EULA or that infringes the intellectual property rights of Licensor or any third party.You agree not to use the External Services to harass, abuse, stalk, threaten or defame any person or entity, and that Licensor is not responsible for any such use.External Services may not be available in all languages or in your Home Country, and may not be appropriate or available for use in any particular location.To the extent you choose to use such External Services, you are solely responsible for compliance with any applicable laws.Licensor reserves the right to change, suspend, remove, disable or impose access restrictions or limits on any External Services at any time without notice or liability to you.</Typography>
            <Typography gutterBottom align="left">f.NO WARRANTY: YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE LICENSED APPLICATION IS AT YOUR SOLE RISK.TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE LICENSED APPLICATION AND ANY SERVICES PERFORMED OR PROVIDED BY THE LICENSED APPLICATION ARE PROVIDED &quot;AS IS&quot; AND “AS AVAILABLE,” WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND LICENSOR HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE LICENSED APPLICATION AND ANY SERVICES, EITHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND / OR CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND OF NONINFRINGEMENT OF THIRD - PARTY RIGHTS.NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY LICENSOR OR ITS AUTHORIZED REPRESENTATIVE SHALL CREATE A WARRANTY.SHOULD THE LICENSED APPLICATION OR SERVICES PROVE DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION.SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.</Typography>
            <Typography gutterBottom align="left">g.Limitation of Liability.TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL LICENSOR BE LIABLE FOR PERSONAL INJURY OR ANY INCIDENTAL, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OF OR INABILITY TO USE THE LICENSED APPLICATION, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY(CONTRACT, TORT, OR OTHERWISE) AND EVEN IF LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU.In no event shall Licensor’s total liability to you for all damages(other than as may be required by applicable law in cases involving personal injury) exceed the amount of fifty dollars($50.00). The foregoing limitations will apply even if the above stated remedy fails of its essential purpose.</Typography>
            <Typography gutterBottom align="left">h.This Agreement and the relationship between you and Licensor shall be governed by the laws of the State of Illinois, excluding its conflicts of law provisions.You and Licensor agree to submit to the personal and exclusive jurisdiction of the courts located within the county of Peoria, Illinois, to resolve any dispute or claim arising from this Agreement.</Typography>
          </DialogContent>}

          <DialogActions>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={()=>{this.setState({showEULA : false})}} color="primary" autoFocus>
              <label className="buttonText small" style={{ margin:0, cursor:"pointer"}}>Cancel</label>
            </Button>
            <Button className="buttonAction btn btn-primary" onClick={()=>{this.setState({showEULA : false, eulaAccepted : true},()=>{this.registerAccount()})}} color="primary" autoFocus>
              <label className="buttonText small" style={{ margin:0, cursor:"pointer"}}>Read and Accepted</label>
            </Button>
          </DialogActions>
        </Dialog>
          </div>
      )
    }

    return(
      <div style={{ width: '100%', display: 'flex', flexDirection:'column', alignContent:'center', alignItems: 'center'}}>
        <div className = "registrationColumn">
          <div className ="row">
            <div className = "col-12 d-flex justify-content-center mt-5 loginTitle">
              <label className ="mainHeader">{this.state.AppName}</label>
              <label className ="subHeader" style = {{marginBottom : 5}}>Account Registration</label>
              <label className ="subHeader" style = {{marginBottom : 5, color:'#779'}}>30 Day Free Trial</label>
            </div>
          </div>
          <div className ="row">
            <div className ="col-12 loginDialogRow1" style={{marginBottom:100}} >
              {this.state.currentForm == "companyName" ?
              <div>
                <label  htmlFor = "companyName" className="formLabel smallLabel" style = {{color : this.state.companyNameColor}}>Company Name</label>
                <input id ="companyName"
                  onChange = {(e) =>{this.setState({companyName : e.target.value, companyNameColor : "black"})}}
                  value = {this.state.companyName}
                  placeholder = ""
                  className = "form-control"
                />
                <label htmlFor = "contactNameFirst" className="formLabel smallLabel" style ={{ color : this.state.contactNameFirstColor}}>Contact Name First</label>
                <input id = "contactNameFirst"
                  onChange = {(e) =>{this.setState({contactNameFirst: e.target.value, contactNameFirstColor : "black"})}}
                  value = {this.state.contactNameFirst}
                  placeholder = ''
                  className = "form-control"
                />
                <label htmlFor = "contactNameLast" className="formLabel smallLabel" style = {{color : this.state.contactNameLastColor}}>Contact Name Last</label>
                <input id ="contactNameLast"
                  onChange = {(e) =>{this.setState({contactNameLast : e.target.value, contactNameLastColor : "black"})}}
                  value = {this.state.contactNameLast}
                  placeholder = ""
                  className = "form-control"
                />
                <label htmlFor = "contactEmail" className="formLabel smallLabel" style = {{color : this.state.contactEmailColor}}>Contact Email</label>
                <input id = "contactEmail"
                  onChange = {(e) =>{this.setState({contactEmail : e.target.value, contactEmailColor : "black"})}}
                  value = {this.state.contactEmail}
                  placehold = ""
                  className = "form-control"
                />
                <label htmlFor = "contactNumber" className="formLabel smallLabel" style = {{color : this.state.contactNumberColor}}>Contact Phone Number</label>
                <input id = "contactNumber"
                  onChange = {(e) =>{this.setState({contactNumber : e.target.value, contactNumberColor : "black"})}}
                  value = {this.state.contactNumber}
                  placehold = ""
                  className = "form-control"
                />

                <div style={{display:'flex'}}>
                  {this.state.displayInternalLink ? 
                    <div className="registrationTypeDiv">
                    <Button onClick ={()=>{this.learnMore()}} className ="buttonAction btn btn-primary small light" style={{margin:'auto', width:'50%'}}>
                        <label className ="buttonText small">Learn More</label>
                    </Button>
                    <Button onClick ={()=>{this.setState({showEULA : true})}} className ="buttonAction btn btn-primary" style={{width:'100%'}}>
                        <label className ="buttonText">Start free trial {this.state.displayInternalLink}</label>
                    </Button>
                  </div>: 
                  <div className="registrationTypeDiv">
                    <Button onClick ={()=>{this.setState({showEULA : true})}} className ="buttonAction btn btn-primary" style={{width:'100%'}}>
                        <label className ="buttonText">Start free trial {this.state.displayInternalLink}</label>
                    </Button>
                  </div>}
                </div>

                <div className = "row d-flex align-items-center justify-content-center registerMessage" >
                  <div className ="col-12 d-flex align-items-center justify-content-center text-center authSpinnerDivClean">
                  {this.state.statusText} 
                  <div className="barLoaderPortal">
                    <BarLoader sizeUnit={"px"} size={10} color={"#095399"} loading={ this.state.loading }
                    /></div>
                  </div>
                </div>
              </div> : <div className="subHeader" style={{margin: 30}}> Your Account has been created, check your email for sign-in information. </div>
              }
            </div>
          </div>
          {this.state.currentForm == "companyName" ? 
              <div></div>
            : <Link to = "/login">
            <div className ="row d-flex align-items-center justify-content-center">
              <Button className ="buttonLogin btn btn-primary signInRegister" style ={{
                  width: "100%", 
                  borderRadius: "0px",
                  backgroundColor: this.state.buttonBackgroundColor,
                  borderLeft: "2px solid #ffffff",
                  borderRight: "2px solid #ffffff",
                  borderBottom: "2px solid #ffffff",
                  borderTop: "2px solid #ffffff !important",
                  cursor: "pointer",
                  paddingTop: "10px !important",
                  margin: "0px !important"}}
                  onMouseOver ={() =>{this.setState({buttonBackgroundColor: "#0069D9"})}}
                  onMouseOut={()=>{this.setState({buttonBackgroundColor: "#095399"})}}>
              <label style ={{margin: 0, cursor: "pointer", fontSize:15 }} className = "buttonText">SIGN IN</label>
              </Button>
            </div>
          </Link>
          }
        </div>

        <Dialog open={this.state.showEULA} onClose={()=>{}}>
          <DialogTitle>{'END USER LICENSE AGREEMENT ("EULA")'}</DialogTitle>
          
          {(this.state.orgId == 1 || this.state.orgId == 4) && 
          <DialogContent>
            <Typography gutterBottom align="center" variant="h5" fontWeight="bold">LaunchPoint Licensed Applications</Typography>
            <Typography gutterBottom align="left">Applications (“Apps”) made available from LaunchPoint are licensed, not sold, to you. Your license to LaunchPoint’s App is subject to your prior acceptance of this Licensed Application End User License Agreement (“EULA”). Your license to any LaunchPoint App under this EULA is a license granted by LaunchPoint. Any App that is subject to this EULA is referred to herein as the “Licensed Application.” LaunchPoint (“Licensor”) reserves all rights in and to the Licensed Application no rights are expressly or indirectly granted to you under this EULA.</Typography>
            <Typography gutterBottom align="left">a.Scope of License.Licensor grants to you a nontransferable license to use the Licensed Application on any products(mobile or otherwise) that you own or control.The terms of this EULA will govern any content, materials, or services accessible from or purchased within the Licensed Application as well as upgrades provided by Licensor that replace or supplement the original Licensed Application.You may not distribute or make the Licensed Application available over a network where it could be used by multiple devices at the same time.You may not transfer, redistribute or sublicense the Licensed Application and, if you sell any devices, upon which you have downloaded the Licensed Application, to a third party, you must remove the Licensed Application from the device before doing so. You may not copy(except as permitted by this license), reverse - engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Licensed Application, any updates, or any part thereof(except as and only to the extent that any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the licensing terms governing use of any open - sourced components included with the Licensed Application).</Typography>
            <Typography gutterBottom align="left">b.Consent to Use of Data.You agree that Licensor may collect and use technical data and related information—including but not limited to technical information about your device, system and application software, and peripherals—that is gathered periodically to facilitate the provision of software updates, product support, and other services to you(if any) related to the Licensed Application.Licensor may use this information, as long as it is in a form that does not personally identify you, to improve its products or to provide services or technologies to you.</Typography>
            <Typography gutterBottom align="left">c.Third Party Vendors.Licensor may utilize third party vendor software, products and materials in providing its Apps, materials, products and services to you.Such third party vendors may include, but are not limited to, Microsoft Corporation.You agree to adhere to the third party vendor terms of use, privacy policies, end user license agreements and any other agreements as they relate to your use of LaunchPoint’s products and services.</Typography>
            <Typography gutterBottom align="left">d.Termination.This EULA is effective until terminated by you or Licensor.Your rights under this EULA will terminate automatically if you fail to comply with any of its terms.</Typography>
            <Typography gutterBottom align="left">e.External Services. The Licensed Application may enable access to Licensor’s and/ or third - party services and websites(collectively and individually, &quot;External Services&quot;).You agree to use the External Services at your sole risk. Licensor is not responsible for examining or evaluating the content or accuracy of any third - party External Services, and shall not be liable for any such third - party External Services.Data displayed by any Licensed Application or External Service, including but not limited to financial, medical and location information, is for general informational purposes only and is not guaranteed by Licensor or its agents.You will not use the External Services in any manner that is inconsistent with the terms of this EULA or that infringes the intellectual property rights of Licensor or any third party.You agree not to use the External Services to harass, abuse, stalk, threaten or defame any person or entity, and that Licensor is not responsible for any such use.External Services may not be available in all languages or in your Home Country, and may not be appropriate or available for use in any particular location.To the extent you choose to use such External Services, you are solely responsible for compliance with any applicable laws.Licensor reserves the right to change, suspend, remove, disable or impose access restrictions or limits on any External Services at any time without notice or liability to you.</Typography>
            <Typography gutterBottom align="left">f.NO WARRANTY: YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE LICENSED APPLICATION IS AT YOUR SOLE RISK.TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE LICENSED APPLICATION AND ANY SERVICES PERFORMED OR PROVIDED BY THE LICENSED APPLICATION ARE PROVIDED &quot;AS IS&quot; AND “AS AVAILABLE,” WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND LICENSOR HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE LICENSED APPLICATION AND ANY SERVICES, EITHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND / OR CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND OF NONINFRINGEMENT OF THIRD - PARTY RIGHTS.NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY LICENSOR OR ITS AUTHORIZED REPRESENTATIVE SHALL CREATE A WARRANTY.SHOULD THE LICENSED APPLICATION OR SERVICES PROVE DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION.SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.</Typography>
            <Typography gutterBottom align="left">g.Limitation of Liability.TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL LICENSOR BE LIABLE FOR PERSONAL INJURY OR ANY INCIDENTAL, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OF OR INABILITY TO USE THE LICENSED APPLICATION, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY(CONTRACT, TORT, OR OTHERWISE) AND EVEN IF LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU.In no event shall Licensor’s total liability to you for all damages(other than as may be required by applicable law in cases involving personal injury) exceed the amount of fifty dollars($50.00). The foregoing limitations will apply even if the above stated remedy fails of its essential purpose.</Typography>
            <Typography gutterBottom align="left">h.This Agreement and the relationship between you and Licensor shall be governed by the laws of the State of Illinois, excluding its conflicts of law provisions.You and Licensor agree to submit to the personal and exclusive jurisdiction of the courts located within the county of Peoria, Illinois, to resolve any dispute or claim arising from this Agreement.</Typography>
          </DialogContent>}

          {this.state.orgId == 3 && 
          <DialogContent>
            <Typography gutterBottom align="center" variant="h5" fontWeight="bold">UtiliSource Licensed Applications</Typography>
            <Typography gutterBottom align="left">Applications (“Apps”) made available from UtiliSource are licensed, not sold, to you. Your license to UtiliSource’s App is subject to your prior acceptance of this Licensed Application End User License Agreement (“EULA”). Your license to any UtiliSource App under this EULA is a license granted by UtiliSource. Any App that is subject to this EULA is referred to herein as the “Licensed Application.” UtiliSource (“Licensor”) reserves all rights in and to the Licensed Application no rights are expressly or indirectly granted to you under this EULA.</Typography>
            <Typography gutterBottom align="left">a.Scope of License.Licensor grants to you a nontransferable license to use the Licensed Application on any products(mobile or otherwise) that you own or control.The terms of this EULA will govern any content, materials, or services accessible from or purchased within the Licensed Application as well as upgrades provided by Licensor that replace or supplement the original Licensed Application.You may not distribute or make the Licensed Application available over a network where it could be used by multiple devices at the same time.You may not transfer, redistribute or sublicense the Licensed Application and, if you sell any devices, upon which you have downloaded the Licensed Application, to a third party, you must remove the Licensed Application from the device before doing so. You may not copy(except as permitted by this license), reverse - engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Licensed Application, any updates, or any part thereof(except as and only to the extent that any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the licensing terms governing use of any open - sourced components included with the Licensed Application).</Typography>
            <Typography gutterBottom align="left">b.Consent to Use of Data.You agree that Licensor may collect and use technical data and related information—including but not limited to technical information about your device, system and application software, and peripherals—that is gathered periodically to facilitate the provision of software updates, product support, and other services to you(if any) related to the Licensed Application.Licensor may use this information, as long as it is in a form that does not personally identify you, to improve its products or to provide services or technologies to you.</Typography>
            <Typography gutterBottom align="left">c.Third Party Vendors.Licensor may utilize third party vendor software, products and materials in providing its Apps, materials, products and services to you.Such third party vendors may include, but are not limited to, Microsoft Corporation.You agree to adhere to the third party vendor terms of use, privacy policies, end user license agreements and any other agreements as they relate to your use of UtiliSource’s products and services.</Typography>
            <Typography gutterBottom align="left">d.Termination.This EULA is effective until terminated by you or Licensor.Your rights under this EULA will terminate automatically if you fail to comply with any of its terms.</Typography>
            <Typography gutterBottom align="left">e.External Services. The Licensed Application may enable access to Licensor’s and/ or third - party services and websites(collectively and individually, &quot;External Services&quot;).You agree to use the External Services at your sole risk. Licensor is not responsible for examining or evaluating the content or accuracy of any third - party External Services, and shall not be liable for any such third - party External Services.Data displayed by any Licensed Application or External Service, including but not limited to financial, medical and location information, is for general informational purposes only and is not guaranteed by Licensor or its agents.You will not use the External Services in any manner that is inconsistent with the terms of this EULA or that infringes the intellectual property rights of Licensor or any third party.You agree not to use the External Services to harass, abuse, stalk, threaten or defame any person or entity, and that Licensor is not responsible for any such use.External Services may not be available in all languages or in your Home Country, and may not be appropriate or available for use in any particular location.To the extent you choose to use such External Services, you are solely responsible for compliance with any applicable laws.Licensor reserves the right to change, suspend, remove, disable or impose access restrictions or limits on any External Services at any time without notice or liability to you.</Typography>
            <Typography gutterBottom align="left">f.NO WARRANTY: YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE LICENSED APPLICATION IS AT YOUR SOLE RISK.TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE LICENSED APPLICATION AND ANY SERVICES PERFORMED OR PROVIDED BY THE LICENSED APPLICATION ARE PROVIDED &quot;AS IS&quot; AND “AS AVAILABLE,” WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND LICENSOR HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE LICENSED APPLICATION AND ANY SERVICES, EITHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND / OR CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND OF NONINFRINGEMENT OF THIRD - PARTY RIGHTS.NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY LICENSOR OR ITS AUTHORIZED REPRESENTATIVE SHALL CREATE A WARRANTY.SHOULD THE LICENSED APPLICATION OR SERVICES PROVE DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION.SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.</Typography>
            <Typography gutterBottom align="left">g.Limitation of Liability.TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL LICENSOR BE LIABLE FOR PERSONAL INJURY OR ANY INCIDENTAL, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OF OR INABILITY TO USE THE LICENSED APPLICATION, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY(CONTRACT, TORT, OR OTHERWISE) AND EVEN IF LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU.In no event shall Licensor’s total liability to you for all damages(other than as may be required by applicable law in cases involving personal injury) exceed the amount of fifty dollars($50.00). The foregoing limitations will apply even if the above stated remedy fails of its essential purpose.</Typography>
            <Typography gutterBottom align="left">h.This Agreement and the relationship between you and Licensor shall be governed by the laws of the State of Illinois, excluding its conflicts of law provisions.You and Licensor agree to submit to the personal and exclusive jurisdiction of the courts located within the county of Peoria, Illinois, to resolve any dispute or claim arising from this Agreement.</Typography>
          </DialogContent>}

          <DialogActions>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={()=>{this.setState({showEULA : false})}} color="primary" autoFocus>
              <label className="buttonText small" style={{ margin:0, cursor:"pointer"}}>Cancel</label>
            </Button>
            <Button className="buttonAction btn btn-primary" onClick={()=>{this.setState({showEULA : false, eulaAccepted : true},()=>{this.registerAccount()})}} color="primary" autoFocus>
              <label className="buttonText small" style={{ margin:0, cursor:"pointer"}}>Read and Accepted</label>
            </Button>
          </DialogActions>
        </Dialog>
        
      </div>
    )
  }
}

Register.propTypes = {
    swap : PropTypes.func
};

export default Register;