import React, {createRef} from "react";
import auth from "../common/Auth";
import { Button } from "react-bootstrap";
import { BarLoader, RingLoader } from "react-spinners";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText'
import CreditCardModal from "./CreditCardModal";
import PropTypes from "prop-types";
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import HighlightOffSharpIcon from '@material-ui/icons/CancelPresentationTwoTone';
import PaymentSharpIcon from '@material-ui/icons/CreditCardTwoTone';
import UndoTwoTone from '@material-ui/icons/UndoTwoTone'
import SaveSharpIcon from '@material-ui/icons/SaveTwoTone';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import AddBoxSharpIcon from '@material-ui/icons/LibraryAddTwoTone';
import CreateNewFolderSharp from '@material-ui/icons/CreateNewFolderSharp';
import AssignmentTurnedInTwoToneIcon from '@material-ui/icons/AssignmentTurnedInTwoTone';
import SearchIcon from '@material-ui/icons/Search';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ArrowForwardIcon from '@material-ui/icons/Forward';
import ListIcon from '@material-ui/icons/List';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import Tab from 'react-bootstrap/Tab';
import DatetimePicker from '../dashboard/sitesafe/DatetimePicker';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup";
import { MDBDataTable } from 'mdbreact';
import { CompactPicker } from 'react-color';
import DataGroupTab from './DataGroupTab';
import moment from 'moment';
import GroupManagementTab from '../portal/GroupManagementTab';
import GroupsPage from '../portal/GroupsPage';
import WindowOpener from 'react-window-opener';
import { get, set, del } from 'idb-keyval';
import Functions from "../../Functions/Functions";
import AttachmentIcon from '@material-ui/icons/Attachment';
import ProjectManagementTab from "../portal/ProjectManagementTab";
import OrgUtility from '../../Utility/Utility';
import MarkerUtility from '../../Utility/MarkerUtility';
import { loadModules } from 'esri-loader';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import { NumericFormat } from 'react-number-format';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateRange from "@material-ui/icons/DateRange";

class AccountPage extends React.Component {
  constructor(props) {
    super(props);
    this.MarkerUtil = new MarkerUtility();
    this.dragItem = createRef();
    this.dragOverItem = createRef();
    this.state = {
      markerList : this.MarkerUtil.getMarkerList(),
      processingRules : [],
      surveyForms : [],
      investigationForms : [],
      gisForms : [],
      gpsForms : [],
      tagForms : [],
      ticketAssignmentAreas : [],
      newFormTypeOpen : false,
      selectCSVFileForData : false,
      maxCSVUploadAmount : 1000,
      uploadingCSVFormData : false,
      arcGisAuthenticationUri: null,
      showHelp : false,
      showSafeHelp : false,
      showSafeScanHelp : false,
      showInvestigationHelp : false,
      showGPSHelp : false,
      showLeakHelp : false,
      downloadSubscriptions : [],
      loadingDownloadSubscriptions : false,
      loadingProcessingRules : false,
      loadingCompanyKMLShapeLayers : false,
      CompanyArcGisLayers : [],
      CompanyArcGisLayerFeatures : [],
      updatingCompanyKMLShapeLayers : "",
      companyKMLShapeLayers : [],
      pendingUploadedFile : false,
      arcGisConnectedFeatureLayerList : [],
      validatingARCGISMapping : false,
      alertPdfs : [],
      uploadingPDFAlert : false,
      siteSafeRows : [
        {sub:'1', licenses:'10',cost:'$100'},
        {sub:'2', licenses:'20',cost:'$110'},
        {sub:'3', licenses:'30',cost:'$120'}
      ],
      siteSurveyRows : [
        {sub : '1', licenses:'1', cost:'$12'},
        {sub : '2', licenses:'2', cost:'$24'},
        {sub : '3', licenses:'3', cost:'$36'}
      ],
      investigationRows : [
        {sub : '1', licenses:'1', cost:'$22'},
        {sub : '2', licenses:'2', cost:'$44'},
        {sub : '3', licenses:'3', cost:'$66'}
      ],
      gpsRows : [
        {sub : '1', licenses:'1', cost:'$50'},
        {sub : '2', licenses:'2', cost:'$100'},
        {sub : '3', licenses:'3', cost:'$150'}
      ],
      leakRows : [
        {sub : '1', licenses:'1', cost:'$65'},
        {sub : '2', licenses:'2', cost:'$130'},
        {sub : '3', licenses:'3', cost:'$195'}
      ],
      safescanRows : [
        {sub : '1', licenses:'1', cost:'$50'},
        {sub : '2', licenses:'2', cost:'$100'},
        {sub : '3', licenses:'3', cost:'$150'}
      ],
      statusText : "",
      card: "",
      payments: [],
      amount: 0,
      cardName: "",
      plan : "",
      cardMonth: "",
      CVC : "",
      showButtons: true,
      showUpdateButton: false,
      subscriptionActiveDate: "",
      invoiceDueDate:"",
      invoiceAmount:"$0",
      creditCards: [],
      cardNumber: "",
      cardYear: "",
      logoImage: "",
      cardNameColor : "black",
      cardNumberColor : "black",
      cardYearColor : "black",
      cardMonthColor : "black",
      CCStatusBarVisible: false,
      cvcColor : "black" ,
      cardInfo : {},

      billingAddressLine1: "",
      billingAddressLine2: "",
      billingAddressCity: "",
      billingAddressState: "",
      billingAddressZip:  "",
      billingAddressPhone: "",

      billingAddressLine1Color: "black",
      billingAddressCityColor: "black",
      billingAddressStateColor: "black",
      billingAddressZipColor:  "black",
      billingAddressPhoneColor: "black",

      subID : "",
      dirtKey : "",
      dirtSecret : "",
      SubscriptionProductId : "",
      SubscriptionItemId : "",
      loading : false,
      updateStatusText : "",
      creditCardStatus : "",
      Triggers : [{
        Field : "",
        Equality : ">",
        Grade : "",
        Emails : "",
        CompanyNotificationTriggerId : -1
      }],
      companyGroups : [{
        GroupLabel : "All",
        CompanyGroupId : -1,
        DeletedDate : new Date()
      }],
      dataGroups : [{
        GroupLabel : "All",
        DataGroupId : -1,
        DeletedDate : new Date()
      }],
      userAssignmentAreasSelected: [{}],
      userAssignmentAreas: [{}],
      assignmentAreas: [{}],
      currentAssignmentArea: {
        Label : "",
        AssignmentAreaId : -1
      },
      userSelected : {
        UserId : -1,
        DisplayNameProper : ""
      },
      userSelectedForAfterHours: {
        UserId : -1,
        DisplayNameProper : ""
      },
      companyAfterHoursEmail : "",
      loadingAfterHoursAssignment : false,
      afterHoursAssignmentAreas:[],
      users : [],
      assignmentAreasForUser: [{}],
      companyProjects : [{
        ProjectLabel : "All",
        ProjectId : -1,
        DeletedDate : new Date()
      }],
      dataUserGroups:[],
      currentFormEdit: {Name:"", Fields:[], Color:'#69A'},
      customFormsEnabled: true,
      customFormsTriggersEnabled: true,
      FormCollection: [],
      loadingForms: false,
      Forms: [],
      editCustomForm: false,
      monthTotal: 0,
      licenseScreening:0,
      licenseLeak:0,
      licenseSite:0,
      licenseGps:0,
      licenseInvestigation:0,
      customFormsLicense : false,
      ticketModuleLicense : false,
      licenseMeta:{
        EntryScreening:{
          ProductId: "",
          Price: 0
        }, 
        Site:{
          ProductId: "",
          Price: 0
        }, 
        GPS:{
          ProductId: "",
          Price: 0
        }, 
        Leak:{
          ProductId: "",
          Price: 0
        }, 
        Investigation:{
          ProductId: "",
          Price: 0
        }, 
        SiteScan:{
          ProductId: "",
          Price: 0
        },
        CustomForms : {
          ProductId: "",
          Price: 0
        },
        TicketModule : {
          ProductId: "",
          Price: 0
        }
      },
      alertSeverity: "success",
      selectedGroups: [],
      selectedProjects : [],
      formGroups: [],
      formProjects : [],
      hasCustomFormLicense: false,
      activeItem: auth.isManager() ? "Misc" : (this.props.match.params.id || "licences"),
      notificationTypeOpen: false,
      csvCustomFormTypeOpen : false,
      fieldNameLabels: {UtilityType:'Utility Type', UtilitySubType:'Utility Subtype'},
      couponCodeApplied: false,
      couponCode: "",
      couponCodeInvalid: false,
      couponCodeExpirationDate: new Date().toLocaleDateString(),
      couponCodeName: "",
      couponCodeAppliesToProductIds: [],
      couponCodePercentOff: 0,
      totalDiscount: 0,
      showGeometryTypeSelector: false,
      showColor: false,
      showMarkerSelection : false,
      showLineMarkerSelection : false,
      arcGisConnected: false,
      arcGisToken: null,
      arcGisUserFeatureLayerList: [],
      companyFormsWithFeatureLayerMappings: [],
      deleteExportMapFormIndex: -1,
      deleteExportMapWarning: false,
      copyCustomFormTypeOpen : false,
      validArcGisKeys : false,
      loadingMapReports : false,
      mapReports : [],
      companyResponseCodes : [],
      useArcGisEnterprise: false,
      ARCGISEnterpriseUrl: '',
      ARCGISEnterprisePortalUrl: '',
      ARCGISEnterpriseLayerListUrl : '',
      RequiredTasks : [],
      loadRequiredTasks : false,
      showSurveyForms : true,
      loadingSurveyFormOrder : false,
      showInvestigationForms : true,
      loadingInvestigationFormOrder : false,
      showGPSForms : true,
      loadingGPSFormOrder : false,
      showGISForms : true,
      loadingGISFormOrder : false,
      showTagForms : true,
      loadingTagFormOrder: false,
      loadingCompanyResponseCodes : false,
      selectedMemberCode : "",
      selectedCallCenter : {CallCenterId : null, CallCenterName : "", CallCenterUserName : "", CallCenterPassword : ""},
      loadingCompanyConfig: true,
      companyConfigFetchFailed: false,
      companyConfiguration: null,
      loadingHours : false,

      distinctMemberCodesUnsorted : [],

      loadingCompanyTicketBillingGroups: false,
      loadingCompanyTicketBillingGroupMemberCodeAssignments: false,
      companyTicketBillingGroups: [],
      companyTicketBillingGroupMemberCodeAssignments: [],
      currentCompanyTicketBillingGroups: "[]",
      currentCompanyTicketBillingGroupMemberCodeAssignments: "[]",
      selectedBillingGroupIdForAssignment: -1,
      
      billingReportDateFrom: new Date().toLocaleDateString(),
      billingReportDateTo: new Date().toLocaleDateString(),
      billingReportToPickerOpen: false,
      billingReportFromPickerOpen: false,
    };
    
    this.isUsersAvailableToOnCall = this.isUsersAvailableToOnCall.bind(this);
    this.isTicketAreaAvailableToOnCall = this.isTicketAreaAvailableToOnCall.bind(this);
    this.updateOperationalHours = this.updateOperationalHours.bind(this);
    this.addFormCopy = this.addFormCopy.bind(this);
    this.editFormProjects = this.editFormProjects.bind(this);
    this.getAccountInformation = this.getAccountInformation.bind(this);
    this.readyNewPicture = this.readyNewPicture.bind(this);
    this.saveInfo = this.saveInfo.bind(this);
    this.sendInvoice = this.sendInvoice.bind(this);
    this.addCard = this.addCard.bind(this);
    this.cancelSub = this.cancelSub.bind(this);
    this.changeCard = this.changeCard.bind(this);
    this.clearCardInfo = this.clearCardInfo.bind(this);
    this.changeCardNumber = this.changeCardNumber.bind(this);
    this.changeCardYear = this.changeCardYear.bind(this);
    this.changeCardMonth = this.changeCardMonth.bind(this);
    this.changeCardName = this.changeCardName.bind(this);
    this.changeCVC = this.changeCVC.bind(this);
    this.updateARCGISKeys = this.updateARCGISKeys.bind(this);
    this.saveCompanyArcGisLayers = this.saveCompanyArcGisLayers.bind(this);
    this.saveCompanyKMLShapeLayers = this.saveCompanyKMLShapeLayers.bind(this);
    this.readyNewKMLShapeFile = this.readyNewKMLShapeFile.bind(this);

    this.changeBillingAddressLine1 = this.changeBillingAddressLine1.bind(this);
    this.changeBillingAddressLine2 = this.changeBillingAddressLine2.bind(this);
    this.changeBillingAddressCity = this.changeBillingAddressCity.bind(this);
    this.changeBillingAddressState = this.changeBillingAddressState.bind(this);
    this.changeBillingAddressZip = this.changeBillingAddressZip.bind(this);
    this.changeBillingAddressPhone = this.changeBillingAddressPhone.bind(this);

    this.updateCreditCard = this.updateCreditCard.bind(this);
    this.updateNotifications = this.updateNotifications.bind(this);
    this.updateDownloadSubcriptions = this.updateDownloadSubcriptions.bind(this);
    this.updateMapReports = this.updateMapReports.bind(this);
    this.updateCompanyResponseCodes = this.updateCompanyResponseCodes.bind(this);
    this.getNotifications = this.getNotifications.bind(this);
    this.getProcessingRules = this.getProcessingRules.bind(this);
    this.getDownloadSubscriptions = this.getDownloadSubscriptions.bind(this);
    this.getMapReportSubscriptions = this.getMapReportSubscriptions.bind(this);
    this.getCompanyArcGisLayers = this.getCompanyArcGisLayers.bind(this);
    this.getCompanyKMLShapeLayers = this.getCompanyKMLShapeLayers.bind(this);
    this.getGroups = this.getGroups.bind(this);
    this.getForms = this.getForms.bind(this);
    this.addNotification = this.addNotification.bind(this);
    this.addDownloadSubcription = this.addDownloadSubcription.bind(this);
    this.addMapReport = this.addMapReport.bind(this);
    this.addCompanyReponseCode = this.addCompanyReponseCode.bind(this);
    this.deleteNotification = this.deleteNotification.bind(this);
    this.deleteDownloadSubscription = this.deleteDownloadSubscription.bind(this);
    this.deleteDownloadSubscriptionTrigger = this.deleteDownloadSubscriptionTrigger.bind(this);
    this.deleteMapReport = this.deleteMapReport.bind(this);
    this.updateDirtCredentials = this.updateDirtCredentials.bind(this);
    this.updateCustomFormNotificationTriggerFieldValue = this.updateCustomFormNotificationTriggerFieldValue.bind(this);
    this.getCustomValues = this.getCustomValues.bind(this);
    this.addDataGroup = this.addDataGroup.bind(this);
    this.editDataGroup = this.editDataGroup.bind(this);
    this.deleteDataGroup = this.deleteDataGroup.bind(this);
    this.updateDataGroups = this.updateDataGroups.bind(this);
    this.expandGroup = this.expandGroup.bind(this);
    this.updateDataUserGroups = this.updateDataUserGroups.bind(this);
    this.setUpdateStatusText = this.setUpdateStatusText.bind(this);
    this.addCustomFormFromCSV = this.addCustomFormFromCSV.bind(this);
    this.importFormDataFromCSV = this.importFormDataFromCSV.bind(this);
    this.setProjects = this.setProjects.bind(this);
    this.showLineMarkerSelection = this.showLineMarkerSelection.bind(this);
    this.setLineMarkerSymbol = this.setLineMarkerSymbol.bind(this);
    this.loadArchiveHistory = this.loadArchiveHistory.bind(this);
    this.parseResultSet = this.parseResultSet.bind(this);
    this.importCustomValues = this.importCustomValues.bind(this);
    this.sortCustomValues = this.sortCustomValues.bind(this);
    this.addRequiredTask = this.addRequiredTask.bind(this);
    this.selectGroupForRequiredTask = this.selectGroupForRequiredTask.bind(this);
    this.deleteRequiredTask = this.deleteRequiredTask.bind(this);
    this.handleCloseRequiredTaskWarning = this.handleCloseRequiredTaskWarning.bind(this);
    this.updateRequiredTasks = this.updateRequiredTasks.bind(this);
    this.drop = this.drop.bind(this);
    this.saveFormsOrder = this.saveFormsOrder.bind(this);
    this.getCompanyResponseCodes = this.getCompanyResponseCodes.bind(this);
    this.addDownloadSubTrigger = this.addDownloadSubTrigger.bind(this);
    this.selectMemberCode = this.selectMemberCode.bind(this);
    this.selectResponseCode = this.selectResponseCode.bind(this);
    this.selectFacility = this.selectFacility.bind(this);
    this.formToFeatureLayerMappings = [];
    this.updateCallCenter = this.updateCallCenter.bind(this);
    this.updateCallCenterUserName = this.updateCallCenterUserName.bind(this);
    this.updateCallCenterPassword = this.updateCallCenterPassword.bind(this);
    this.updateCallCenterConfig = this.updateCallCenterConfig.bind(this);
    this.updateProcessingRules = this.updateProcessingRules.bind(this);
    this.getUserAssignmentArea = this.getUserAssignmentArea.bind(this);
    this.getAfterHoursAssignmentAreas = this.getAfterHoursAssignmentAreas.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.uploadAlertPDF = this.uploadAlertPDF.bind(this);
    this.selectUserForAfterHours = this.selectUserForAfterHours.bind(this);
    this.selectTicketAreaFeatureForUser = this.selectTicketAreaFeatureForUser.bind(this);
    this.updateAfterHoursAssignments = this.updateAfterHoursAssignments.bind(this);
  }

  componentDidMount() {
    this.getAccountInformation();
    this.getNotifications();
    this.getDownloadSubscriptions();
    this.getProcessingRules();
    this.getMapReportSubscriptions();
    this.getCompanyResponseCodes();
    this.getCompanyTicketBillingGroups();
    this.getCompanyTicketBillingGroupMemberCodeAssignments();
    this.getCompanyKMLShapeLayers();
    this.getGroups();
    this.getForms();
    this.getDataGroups();
    this.getUsers();
    this.getUserAssignmentArea();
    this.getAfterHoursAssignmentAreas();
    this.getArcGisParameters();
    this.getRequiredTasks();
    this.getCompanyConfiguration();
    this.getAlertPDFS();
    this.getCompanyArcGisLayers();

    get("arcGisOnlineToken").then((token)=>{
      if(token){
        let tokenObj = JSON.parse(token);
        if(!tokenObj.expirationDateTimeUTC || tokenObj.refresh_token || tokenObj.userId != auth.userId()){
          del("arcGisOnlineToken");
        } else{
          this.setState({arcGisToken: tokenObj});
          this.validateArcGisToken(tokenObj);
        }
      }
    });

    var orgUtil = new OrgUtility();
    this.setState({ orgId: orgUtil.getOrgId(), displayInternalLinks: orgUtil.displayInternalLinks(), displayInvestigationData: orgUtil.displayInvestigationData(), displaySiteSafeContent: orgUtil.displaySiteSafeContent() });

    window.onclick = e => {
      let modal = document.getElementById("addCard");
      if (e.target == modal) {
        this.clearCardInfo();
        modal.style.display = "none";
      }
    };
    this.props.forceRefresh();
  }

  componentDidUpdate(){ 
    window.resize();

    // STYLE CSS by ORG
    new OrgUtility().updateOrgCSS();
  }

  isUsersAvailableToOnCall(userId){
    if(auth.isAdmin() || auth.isSuperAdmin()){
      return true;
    }
    else{
      if(userId == auth.employeeId()){
        return true;
      }
      if(!this.state.userAssignmentAreas){
        return false;
      }
      var myAssignments = this.state.userAssignmentAreas.filter(x => x.UserId == auth.employeeId());
      var hadSameArea = false;
      myAssignments.forEach(assignment =>{
        let areaIndex = this.state.userAssignmentAreas.findIndex(x => x.UserId == userId && x.CompanyArcGisLayerId == assignment.CompanyArcGisLayerId && x.FeatureId == assignment.FeatureId && x.AssignmentAreaId == assignment.AssignmentAreaId);
        if(areaIndex != -1){
          hadSameArea = true;
        }
      });
      return hadSameArea;
    }
  }

  isTicketAreaAvailableToOnCall(area){
    if(auth.isAdmin() || auth.isSuperAdmin()){
      return true;
    }
    else{
      if(!this.state.userAssignmentAreas){
        return false;
      }
      var myAssignments = this.state.userAssignmentAreas.filter(x => x.UserId == auth.employeeId());
      return myAssignments.findIndex(x => x.CompanyArcGisLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId && x.AssignmentAreaId == area.AssignmentAreaId) != -1;
    }
  }

  dragStart(e,position){
    this.dragItem.current = position;
  }

  drop(formType){
    let surveyList = [];
    if(formType == "survey"){
      surveyList = this.state.surveyForms;
    }
    else if(formType == "investigation"){
      surveyList = this.state.investigationForms;
    }
    else if(formType == "gps"){
      surveyList = this.state.gpsForms;
    }
    else if(formType == "gis"){
      surveyList = this.state.gisForms;
    }
    else if(formType == "tag"){
      surveyList = this.state.tagForms;
    }
    let dragForm = surveyList[this.dragItem.current];
    surveyList.splice(this.dragItem.current,1);
    surveyList.splice(this.dragOverItem.current, 0, dragForm);
    this.dragItem.current = null;
    this.dragOverItem.current = null;
    surveyList.forEach((survey,index) =>{
      survey.FormOrder = index;
    })
    if(formType == "survey"){
      this.setState({surveyForms : surveyList, surveyOrderChanged : true});
    }
    else if(formType == "investigation"){
      this.setState({investigationForms : surveyList, investigationOrderChanged : true});
    }
    else if(formType == "gps"){
      this.setState({gpsForms : surveyList, gpsOrderChanged : true});
    }
    else if(formType == "gis"){
      this.setState({gisForms : surveyList, gisOrderChanged : true});
    }
    else if(formType == "tag"){
      this.setState({tagForms : surveyList, tagOrderChanged : true});
    }
  }

  dragEnter(e, position){
    e.preventDefault();
    this.dragOverItem.current = position;
  }

  componentWillUnmount(){
    if(this.refreshTokenTimeout) clearTimeout(this.refreshTokenTimeout);
  }

  setProjects(companyProjects){
    this.setState({companyProjects});
  }

  setUpdateStatusText(updateStatusText, alertSeverity){
    this.setState({updateStatusText, alertSeverity});
  }


  getArcGisParameters(){
    fetch(process.env.API_URL + "/api/arcgis/getArcGisConnectParameters",{
      method : "Post",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token(),
      },
      body : JSON.stringify({orgId: new OrgUtility().getOrgId()})
    })
    .then(r =>{
      if(r.status == 200){
       r.json().then((parameters)=>{
        this.setState({arcGisAuthenticationUri: parameters.arcGisRestApiPrefix + "oauth2/authorize/?client_id=" + parameters.clientId + "&redirect_uri=" + parameters.redirectUri + "&expiration=-1&grant_type=code&response_type=code"})
      });
      }
    })
    .catch(error =>{
      console.log(error);
    });
  }

  updateARCGISKeys(){
    if(auth.isSuperViewer()){
      return;
    }
    let arcGisEnterprisePortalUrl = this.state.ARCGISEnterprisePortalUrl.trim();

    if(this.state.useArcGisEnterprise && (arcGisEnterprisePortalUrl == "" || !Functions.isValidHttpsUrl(arcGisEnterprisePortalUrl))){
      this.setState({updateStatusText : "Enter a valid ArcGIS Enterprise Portal URL", alertSeverity : "error", validArcGisKeys : false});
      return;
    }

    arcGisEnterprisePortalUrl = arcGisEnterprisePortalUrl.replace('\\', '/');
    if(arcGisEnterprisePortalUrl && arcGisEnterprisePortalUrl != '' && arcGisEnterprisePortalUrl[arcGisEnterprisePortalUrl.length - 1] == '/') arcGisEnterprisePortalUrl = arcGisEnterprisePortalUrl.slice(0, arcGisEnterprisePortalUrl.length - 1);

    let arcGisEnterpriseUrl = this.state.ARCGISEnterpriseUrl.trim();

    if(this.state.useArcGisEnterprise && (arcGisEnterpriseUrl == "" || !Functions.isValidHttpsUrl(arcGisEnterpriseUrl))){
      this.setState({updateStatusText : "Enter a valid ArcGIS Enterprise REST URL", alertSeverity : "error",validArcGisKeys : false});
      return;
    }

    arcGisEnterpriseUrl = arcGisEnterpriseUrl.replace('\\', '/');
    if(arcGisEnterpriseUrl && arcGisEnterpriseUrl != '' && arcGisEnterpriseUrl[arcGisEnterpriseUrl.length - 1] != '/') arcGisEnterpriseUrl += '/';

    let aRCGISEnterpriseLayerListUrl = this.state.ARCGISEnterpriseLayerListUrl.trim();

    if(this.state.useArcGisEnterprise && (aRCGISEnterpriseLayerListUrl == "" || !Functions.isValidHttpsUrl(aRCGISEnterpriseLayerListUrl))){
      this.setState({updateStatusText : "Enter a valid ArcGIS Enterprise Layer List URL", alertSeverity : "error",validArcGisKeys : false});
      return;
    }

    aRCGISEnterpriseLayerListUrl = aRCGISEnterpriseLayerListUrl.replace('\\', '/');
    if(aRCGISEnterpriseLayerListUrl && aRCGISEnterpriseLayerListUrl != '' && aRCGISEnterpriseLayerListUrl[aRCGISEnterpriseLayerListUrl.length - 1] != '/') aRCGISEnterpriseLayerListUrl += '/';
    this.setState({validatingARCGISKeys : true});
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      ARCGISClientId : this.state.ARCGISClientId,
      ARCGISClientSecret : this.state.ARCGISClientSecret,
      ARCGISEnterpriseUrl : arcGisEnterpriseUrl == "" || !this.state.useArcGisEnterprise ? null : arcGisEnterpriseUrl,
      ARCGISEnterprisePortalUrl : arcGisEnterprisePortalUrl == "" || !this.state.useArcGisEnterprise ? null : arcGisEnterprisePortalUrl,
      ARCGISEnterpriseLayerListUrl : aRCGISEnterpriseLayerListUrl == "" || !this.state.useArcGisEnterprise ? null : aRCGISEnterpriseLayerListUrl
    }

    fetch(process.env.API_URL + "/api/account/updateARCGISClientIdAndSecret",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson)
          if(json.ResponseType == 0){
            this.setState({updateStatusText : "Connection validated and account information updated", validatingARCGISKeys : false, alertSeverity : "success",validArcGisKeys : true, ARCGISEnterpriseUrl: arcGisEnterpriseUrl, ARCGISEnterpriseLayerListUrl : aRCGISEnterpriseLayerListUrl, ARCGISEnterprisePortalUrl : arcGisEnterprisePortalUrl});
            this.getArcGisParameters();
          }
          else{
            this.setState({updateStatusText : "There was a problem updating your information. " + json.ResponseMessageCollection[0].Message, validatingARCGISKeys : false, alertSeverity : "error",validArcGisKeys : false});
          }
        });
      }
      else{
        this.setState({updateStatusText : "There was a problem updaing your information. Try again. ", validatingARCGISKeys : false, alertSeverity : 'error',validArcGisKeys : false });
      }
    })
    .catch(error =>{
      this.setState({updateStatusText : "There was a problem updaing your information. Try again. " + error.Message, validatingARCGISKeys : false, alertSeverity : 'error',validArcGisKeys : false });
    })
  }

  validateArcGisToken(token){
    let me = this;
    let body = {
      CompanyId : auth.companyId(),
      Token : token.access_token
    }
    fetch(process.env.API_URL + "/api/arcgis/validateToken/" ,{
      method : "POST",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
      r.json().then((isValid)=>{
        if(isValid){
          this.setState({arcGisConnected: true});

          let currentDateTimeUTC = Date.now();
          let msUntilExpiration = token.expirationDateTimeUTC - currentDateTimeUTC;

          if(msUntilExpiration < 300000){
            this.refreshArcGisToken();
          } else{
            this.refreshTokenTimeout = setTimeout(()=>{
              me.refreshArcGisToken();
            }, msUntilExpiration - 120000);
            this.getArcGisUserFeatureLayers(); 
          }
        } else{
          this.refreshArcGisToken();
        }
      });
      }
    })
    .catch(error =>{
      console.log(error);
    });
  }

  refreshArcGisToken(){
    let me = this;
    let body = {
      arcGisUsername : this.state.arcGisToken.username
    }
    fetch(process.env.API_URL + "/api/arcgis/refreshtoken",{
      method : "Post",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then((token)=>{
          let currentDateTimeUTC = Date.now();
          token.expirationDateTimeUTC = currentDateTimeUTC + (token.expires_in * 1000);
          token.userId = auth.userId();
          this.setState({arcGisConnected: true, arcGisToken: token});
          this.refreshTokenTimeout = setTimeout(()=>{
            me.refreshArcGisToken();
          }, (token.expires_in - 120) * 1000);
          set('arcGisOnlineToken', JSON.stringify(token));
          this.getArcGisUserFeatureLayers();
        });
      } else{
        this.setState({arcGisConnected: false, arcGisToken: null});
        del('arcGisOnlineToken');
      }
    })
    .catch(error =>{
      this.setState({arcGisConnected: false, arcGisToken: null});
      del('arcGisOnlineToken');
      console.log(error);
    });
  }

  revokeArcGisToken(){
    fetch(process.env.API_URL + "/api/arcgis/tokenrevoke?arcGisUsername=" + this.state.arcGisToken.username,{
      method : "Get",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      //body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
      r.json().then((isSuccess)=>{
        if(isSuccess){
          this.setState({arcGisConnected: false, arcGisToken: null});
          if(this.refreshTokenTimeout) clearTimeout(this.refreshTokenTimeout);
          del('arcGisOnlineToken');
        }
        else{
          this.setState({updateStatusText : "There was an error disconnecting from ArcGIS Online. Try again.", alertSeverity : "error"});
        }
      });
      }
    })
    .catch(error =>{
      this.setState({updateStatusText : "There was an error disconnecting from ArcGIS Online. Try again." + error.message, alertSeverity : "error"});
    });
  }

  arcGisPopupWindowResponse(err, res){
    let me = this;
    let currentDateTimeUTC = Date.now();
    res.res.expirationDateTimeUTC = currentDateTimeUTC + (res.res.expires_in * 1000);
    res.res.userId = auth.userId();
    this.setState({arcGisConnected: true, arcGisToken: res.res});
    set('arcGisOnlineToken', JSON.stringify(res.res));
    this.refreshTokenTimeout = setTimeout(()=>{
      me.refreshArcGisToken();
    }, (res.res.expires_in - 120) * 1000);
    this.getArcGisUserFeatureLayers();
    if(!this.formFeatureLayerDataLoaded){
      this.loadFeatureLayerDataSequential(0, this.state.companyFormsWithFeatureLayerMappings);
      this.formFeatureLayerDataLoaded = true;
    } 
  }

  getArcGisUserFeatureLayers(){
    this.setState({loadingUserFeatureLayers : true});
    fetch(process.env.API_URL + "/api/arcgis/getUserFeatureLayers",{
      method : "Post",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify({username: this.state.arcGisToken.username, token: this.state.arcGisToken.access_token})
    })
    .then(r =>{
      if(r.status == 200){
      r.json().then((arcGisFeatureLayerSearchResponse)=>{
        this.setState({arcGisUserFeatureLayerList: arcGisFeatureLayerSearchResponse.results,arcGisConnectedFeatureLayerList : arcGisFeatureLayerSearchResponse.features, loadingUserFeatureLayers : false});
      });
      }
    })
    .catch(error =>{
      console.log(error);
    });
  }

  getUsers(){
    let body = {CompanyId: auth.companyId()};
    fetch(process.env.API_URL + "/api/account/users",{
      method: "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0 && json.UserCollection.length >0){
            let i = json.UserCollection.length;
            while (i--) {
              if (json.UserCollection[i].DeletedBy != null ||json.UserCollection[i].DeletedDate != null || json.UserCollection[i].DeletedDateUTCOffset != null) {
                json.UserCollection.splice(i, 1);
              }
            }
            Functions.sortOn(json.UserCollection, 'LastName');
            this.setState({users : json.UserCollection,  statusText : ""});
          }
        })
      }
    })
  }

  loadFeatureLayerDataSequential(loadIndex, formCollection){
    if(!formCollection[loadIndex].featureLayerMappings){
      loadIndex++;
      if(loadIndex < formCollection.length){
        this.loadFeatureLayerDataSequential(loadIndex, formCollection);
      }
      return;
    }

    fetch(process.env.API_URL + "/api/arcgis/getFeatureLayer",{
      method : "Post",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify({token: this.state.arcGisToken.access_token, featureLayerUrl: formCollection[loadIndex].featureLayerMappings.FeatureServiceUrl})
    })
    .then(r =>{
      if(r.status == 200){
      r.json().then((featureLayerString)=>{
        let featureLayer = JSON.parse(featureLayerString);
        if(formCollection[loadIndex].GeometryType == "Line"){
          formCollection[loadIndex].AvailableLayers = featureLayer.layers.filter((x) => x.geometryType == "esriGeometryPolyline" || x.geometryType == "esriGeometryPoint");
        } else if(formCollection[loadIndex].GeometryType == "Polygon"){
          formCollection[loadIndex].AvailableLayers = featureLayer.layers.filter((x) => x.geometryType == "esriGeometryPolygon" || x.geometryType == "esriGeometryPoint");
        } else{
          formCollection[loadIndex].AvailableLayers = featureLayer.layers.filter((x) => x.geometryType == "esriGeometryPoint");
        }
        if(formCollection[loadIndex].AvailableLayers.length > 0){
          let selectedLayerId = formCollection[loadIndex].AvailableLayers[0].id;
          let selectedLayerIndex = 0;

          for(let i = 0; i < formCollection[loadIndex].featureLayerMappings.LayerMappings.length; i++){
            selectedLayerId = parseInt(formCollection[loadIndex].featureLayerMappings.LayerMappings[i].FeatureLayerId);
            selectedLayerIndex = formCollection[loadIndex].AvailableLayers.findIndex((x)=>{return x.id == selectedLayerId});
            break;
          } 

          formCollection[loadIndex].SelectedLayerId = selectedLayerId;
          formCollection[loadIndex].SelectedLayerIndex = selectedLayerIndex;
          this.setState({companyFormsWithFeatureLayerMappings: formCollection}, ()=>{
            this.loadLayerDataFields(loadIndex, formCollection[loadIndex].featureLayerMappings.FeatureServiceUrl, formCollection[loadIndex].AvailableLayers, 0, formCollection, true);
          });
        }else{
          formCollection[loadIndex].SelectedLayerId = -1
          formCollection[loadIndex].SelectedLayerIndex = -1
          formCollection[loadIndex].loadingFeatureLayerData = false;
          this.setState({companyFormsWithFeatureLayerMappings: formCollection}), ()=>{
            loadIndex++;
            if(loadIndex < formCollection.length){
              this.loadFeatureLayerDataSequential(loadIndex, formCollection);
            }
          };
        }
      });
      } else{
        formCollection[loadIndex].loadingFeatureLayerData = false;
        this.setState({companyFormsWithFeatureLayerMappings: formCollection}), ()=>{ 
          loadIndex++;
          if(loadIndex < formCollection.length){
            this.loadFeatureLayerDataSequential(loadIndex, formCollection);
          }
        };
      }
    })
    .catch(error =>{
      formCollection[loadIndex].loadingFeatureLayerData = false;
        this.setState({companyFormsWithFeatureLayerMappings: formCollection}), ()=>{
          loadIndex++;
          if(loadIndex < formCollection.length){
            this.loadFeatureLayerDataSequential(loadIndex, formCollection);
          }
        };
      console.log(error);
    });
  }

  loadFeatureLayerData(f_index, featureLayerUrl){
    let formCollection = this.state.companyFormsWithFeatureLayerMappings;
    fetch(process.env.API_URL + "/api/arcgis/getFeatureLayer",{
      method : "Post",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify({token: this.state.arcGisToken.access_token, featureLayerUrl: featureLayerUrl})
    })
    .then(r =>{
      if(r.status == 200){
      r.json().then((featureLayerString)=>{
        let featureLayer = JSON.parse(featureLayerString);
        if(formCollection[f_index].GeometryType == "Line"){
          formCollection[f_index].AvailableLayers = featureLayer.layers.filter((x) => x.geometryType == "esriGeometryPolyline" || x.geometryType == "esriGeometryPoint");
        } else if(formCollection[f_index].GeometryType == "Polygon"){
          formCollection[f_index].AvailableLayers = featureLayer.layers.filter((x) => x.geometryType == "esriGeometryPolygon" || x.geometryType == "esriGeometryPoint");
        } else{
          formCollection[f_index].AvailableLayers = featureLayer.layers.filter((x) => x.geometryType == "esriGeometryPoint");
        }
        if(formCollection[f_index].AvailableLayers.length > 0){
          let selectedLayerId = formCollection[f_index].AvailableLayers[0].id;
          let selectedLayerIndex = 0;

          for(let i = 0; i < formCollection[f_index].featureLayerMappings.LayerMappings.length; i++){
            selectedLayerId = parseInt(formCollection[f_index].featureLayerMappings.LayerMappings[i].FeatureLayerId);
            selectedLayerIndex = formCollection[f_index].AvailableLayers.findIndex((x)=>{return x.id == selectedLayerId});
            break;
          } 

          formCollection[f_index].SelectedLayerId = selectedLayerId;
          formCollection[f_index].SelectedLayerIndex = selectedLayerIndex;
          this.setState({companyFormsWithFeatureLayerMappings: formCollection}, ()=>{
            this.loadLayerDataFields(f_index, featureLayerUrl, formCollection[f_index].AvailableLayers, 0, formCollection);
          });
        }else{
          formCollection[f_index].SelectedLayerId = -1
          formCollection[f_index].SelectedLayerIndex = -1
          formCollection[f_index].loadingFeatureLayerData = false;
          this.setState({companyFormsWithFeatureLayerMappings: formCollection});
        }
      });
      } else{
        formCollection[f_index].loadingFeatureLayerData = false;
        this.setState({companyFormsWithFeatureLayerMappings: formCollection});
      }
    })
    .catch(error =>{
      formCollection[f_index].loadingFeatureLayerData = false;
      this.setState({companyFormsWithFeatureLayerMappings: formCollection});
      console.log(error);
    });
  }

  loadLayerDataFields(f_index, featureLayerUrl, availableLayers, loadIndex, formCollection, isSequential){
    fetch(process.env.API_URL + "/api/arcgis/getLayer",{
      method : "Post",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify({token: this.state.arcGisToken.access_token, featureLayerUrl: featureLayerUrl, layerId: availableLayers[loadIndex].id})
    })
    .then(r =>{
      if(r.status == 200){
      r.json().then((layerString)=>{
        let layer = JSON.parse(layerString);
        formCollection[f_index].AvailableLayers[loadIndex].fields = layer.fields.filter((x) => x.name != "OBJECTID");

        if(loadIndex == availableLayers.length - 1){
          formCollection[f_index].loadingFeatureLayerData = false;
          this.setState({companyFormsWithFeatureLayerMappings: formCollection},()=>{
            if(isSequential){
              f_index++;
              if(f_index < formCollection.length){
                this.loadFeatureLayerDataSequential(f_index, formCollection);
              }
            }
          });
        } else{
          loadIndex ++;
          this.loadLayerDataFields(f_index, featureLayerUrl, availableLayers, loadIndex, formCollection, isSequential);
        }
      });
      } else{
        if(loadIndex == availableLayers.length - 1){
          formCollection[f_index].loadingFeatureLayerData = false;
          this.setState({companyFormsWithFeatureLayerMappings: formCollection},()=>{
            if(isSequential){
              f_index++;
              if(f_index < formCollection.length){
                this.loadFeatureLayerDataSequential(f_index, formCollection);
              }
            }
          });
        } else{
          loadIndex ++;
          this.loadLayerDataFields(f_index, featureLayerUrl, availableLayers, loadIndex, formCollection, isSequential);
        }
      }
    })
    .catch(error =>{
      if(loadIndex == availableLayers.length - 1){
        formCollection[f_index].loadingFeatureLayerData = false;
        this.setState({companyFormsWithFeatureLayerMappings: formCollection},()=>{
          if(isSequential){
            f_index++;
            if(f_index < formCollection.length){
              this.loadFeatureLayerDataSequential(f_index, formCollection);
            }
          }
        });
      } else{
        loadIndex ++;
        this.loadLayerDataFields(f_index, featureLayerUrl, availableLayers, loadIndex, formCollection, isSequential);
      }
      console.log(error);
    });
  }

  getFormToGisLayerMappings(){
    let companyId = this.checkSuper() || this.checkMultiCompany() ? auth.companyId() : -9999;
    fetch(process.env.API_URL + "/api/arcgis/getCompanyFormToFeatureLayerMappings/" + companyId,{
      method : "Post",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      }
    })
    .then(r =>{
      if(r.status == 200){
        let formCollectionCopy = JSON.parse(JSON.stringify(this.state.FormCollection));
        // formCollectionCopy.forEach((form)=>{
        //   form.featureLayerMappings = [];
        // });
        formCollectionCopy = this.addGPSSurveyGeoDataFieldsToGPSFormFields(formCollectionCopy);
        formCollectionCopy = this.addFormDataFieldsToFormFields(formCollectionCopy);
        
        r.json().then((companyFormToFeatureLayerMappings)=>{
          let mappings = JSON.parse(companyFormToFeatureLayerMappings);
          mappings.forEach((mapping)=>{
            let i = Functions.findWithAttr(formCollectionCopy, "FormId", mapping.FormId);
            if(i != -1){
              formCollectionCopy[i].featureLayerMappings = mapping;
              formCollectionCopy[i].hasSavedMappings = true;
              formCollectionCopy[i].AvailableLayers = [];
              formCollectionCopy[i].SelectedLayerId = null;
              formCollectionCopy[i].SelectedLayerIndex = 0;
              formCollectionCopy[i].loadingFeatureLayerData = true;
            }
          });
          this.setState({companyFormsWithFeatureLayerMappings: formCollectionCopy}, ()=>{
              if(this.state.arcGisConnected){
                this.loadFeatureLayerDataSequential(0, formCollectionCopy);
                this.formFeatureLayerDataLoaded = true;
              }
          });
        });
      }
    })
    .catch(error =>{
      console.log(error);
    });
  }

  checkSuper(){
    let isSuper = false;
    let _SuperAdminRoleId = 0;
    let _SuperViewerRoleId = 11;
    var roles = localStorage.getItem("roles");
    if(roles != null) { roles.split(',').forEach(element => { if (element == _SuperAdminRoleId || element == _SuperViewerRoleId) { isSuper = true;}});}
    return isSuper;
  }

  checkMultiCompany(){
    var userAccessCompanyIds = localStorage.getItem("userAccessCompanyIds");
    if(userAccessCompanyIds != null) return true;
    return false;
  }

  changeCardNumber(num) {
    if (num.length > this.state.cardNumber.length){
      let pureNum = num.replace(/-/g,'');
      if( (pureNum.length >0 && pureNum.length <15) && pureNum.length % 4 ==0 ){
        num = num + "-";
      }
    }
    else if(this.state.cardNumber[this.state.cardNumber.length-1] == '-'){
      num = num.slice(0,num.length -1);
    }
    
    this.setState({ cardNumber: num, cardNumberColor : "black" });
  }

  changeCardYear(year) {
    this.setState({ cardYear: year, cardYearColor : "black" });
  }

  changeCardMonth(month) {
    this.setState({ cardMonth: month, cardMonthColor : "black" });
  }

  changeCardName(name) {
    this.setState({ cardName: name, cardNameColor : "black" });
  }

  changeCVC(cvc){
    if(cvc.length <= 3){
      this.setState({ CVC : cvc, cvcColor : "black" });
    }
  }

  clearCardInfo() {
    this.setState({
      cardNumber: "",
      cardYear: "",
      cardMonth: "",
      cardName: "",
      card: "",
      CVC : "",
      cardNameColor : "black",
      cardNumberColor : "black",
      cardYearColor : "black",
      cardMonthColor : "black",
      cvcColor : "black",
      creditCardStatus : ""
    });
  }

  changeBillingAddressPhone(phone){
    if(phone){
      phone = phone.replace(/\D/g, '');
      phone = phone.replace(/(\d{3})(\d{3})(\d+)/, '$1-$2-$3');
      this.setState({billingAddressPhone: phone, billingAddressPhoneColor: "black"});
    }else{
      this.setState({billingAddressPhone: "", billingAddressPhoneColor: "black"});
    }
  }

  changeBillingAddressLine1(address){
    this.setState({billingAddressLine1: address, billingAddressLine1Color: "black"});
  }

  changeBillingAddressLine2(address2){
    this.setState({billingAddressLine2: address2});
  }

  changeBillingAddressCity(city){
    this.setState({billingAddressCity: city, billingAddressCityColor: "black"});
  }

  changeBillingAddressState(state){
    this.setState({billingAddressState: state, billingAddressStateColor: "black"});
  }

  changeBillingAddressZip(zip){
    zip = zip.replace(/\D/g, '');
    this.setState({billingAddressZip: zip, billingAddressZipColor: "black"});
  }

  updateLicensesPressed(){
    if(auth.isSuperViewer()){
      return;
    }
    if(this.state.couponCodeInvalid){
      return;
    }
    if(!this.state.couponCode || this.state.couponCode == ""){
      this.saveInfo();
      return;
    }
    if(!this.state.couponCodeApplied && this.state.couponCode.trim() != ""){
      fetch(process.env.API_URL + "/api/stripe/ValidateStripePromoCode/" + this.state.couponCode.trim(),{
        method : "Put",
        headers:{
          "Access-Control-Allow-Origin" : "*",
          "Content-Type" : "application/json; charset=utf-8",
          Accept : "application/json",
          Authorization : "Bearer " + auth.token()
        }
      })
      .then(r =>{
        if(r.status == 200){
          this.saveInfo()
        }else if(r.status == 404){
          this.setState({couponCodeInvalid: true});
        }
      })
      .catch(error =>{
        this.setState({updateStatusText : "There was a problem validating the coupon code. Please try again " + error.Message, loadingCredentials : false, alertSeverity:"error"});
      });
    }else{
      this.saveInfo();
    }
  }

  sendInvoice(){
    // Send copy of latest invoice
    this.saveInfo(false, true);
  }

  cancelSub(){
    // Cancel all subscriptions
    if(auth.isSuperViewer()){
      return;
    }
    this.saveInfo(true);
  }

  saveInfo(cancel, sendInvoice) {
    
    // SUPER USER check
    let companyId = auth.companyId();
    let realCompany = localStorage.getItem("actualCompanyId");

    if (companyId != realCompany){ this.setState({updateStatusText : "This account cannot make License updates.", loadingCredentials : false, alertSeverity:"warning"}); return; }

    let req = new XMLHttpRequest();
    req.open( "POST", process.env.API_URL + "/api/account/updateSubscription", true);

    // CANNOT UNASSIGN ALL LICENSES (unless cancelling) - inform user to use Cancel Subscription instead of removing all licenses
    if (cancel == false && this.state.licenseGps == 0 && this.state.licenseSite == 0 && this.state.licenseLeak == 0 && this.state.licenseInvestigation == 0){
      this.setState({showButtons : true, updateStatusText : "Cannot remove all licenses, use Cancel Subscription.", alertSeverity:"warning"});
      return;
    }

    let body = {
      UserId : auth.employeeId(),
      CancelSubscription: cancel == true,
      SendInvoiceOnly: sendInvoice == true,
      CouponCode: this.state.couponCode && this.state.couponCode.trim(),
      CompanyId: companyId,
      CompanyLicenseCollection: [
        {"LicenseId": "gps", "LicenseAmount": this.state.licenseGps},
        {"LicenseId": "site", "LicenseAmount": this.state.licenseSite},
        {"LicenseId": "leak", "LicenseAmount": this.state.licenseLeak},
        {"LicenseId": "investigation", "LicenseAmount": this.state.licenseInvestigation},
        {"LicenseId": "sitesafe", "LicenseAmount": this.state.licenseScreening},
        {"LicenseId": "sitesafescan", "LicenseAmount": this.state.licenseScreeningGuest},
        {"LicenseId" : "customforms", "LicenseAmount": this.state.customFormsLicense ? 1 : 0},
        {"LicenseId" : "ticket", "LicenseAmount": this.state.ticketModuleLicense ? 1 : 0}
      ],
    };

    req.setRequestHeader("Content-Type", "application/json");
    req.setRequestHeader("Accept", "application/json");
    req.setRequestHeader("Authorization", "Bearer " + auth.token());
    
    req.onload = () =>{
      if(req.status == 200){
        let response = JSON.parse(JSON.parse(req.responseText));
        if(response.ResponseType == 0){
          this.setState({showButtons : true, updateStatusText : cancel == true ? "Subscription Cancelled" : 
          sendInvoice == true ? "Invoice Sent" : "Account Updated", alertSeverity:"success"});
          // Refresh data, or anything other than invoice request
          if (sendInvoice != true) this.getAccountInformation();
          //this.updateLicenseTotal();
        }
        else{
          let errorMessages = "";
          for(let i =0; i < response.ResponseMessageCollection.length; i++){
            if(response.ResponseMessageCollection[i].Message != null && response.ResponseMessageCollection[i].Message != undefined && response.ResponseMessageCollection[i].Message != "undefined"){
              errorMessages += "\n" + response.ResponseMessageCollection[i].Message;
            }
          }
          this.setState({showButtons : true, updateStatusText : "There was a problem updating your information." + errorMessages, alertSeverity:"error"});
        }
      }
      else{
        this.setState({showButtons : true, updateStatusText : "There was a problem updating your information. Try again.", alertSeverity:"error"});
      }
    }
    req.onerror = ()=>{
      this.setState({showButtons : true, updateStatusText : "There was a problem updating your information. Try again.", alertSeverity:"error"});
    }
    req.send(JSON.stringify(body));
    this.setState({showButtons : false});
  }

  changeCard(card) {
    this.setState({card});
    switch (card) {
      case "Add":
        document.getElementById("addCard").style.display = "block";
        break;
      default:
        break;
    }
  }

  getAfterHoursAssignmentAreas(){
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }

    fetch(process.env.API_URL + "/api/account/getAfterHoursAssignmentAreas",{
      method: 'POST',
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({afterHoursAssignmentAreas: json.AfterHoursAssignmentAreas});
            console.log('AfterHoursAssignmentAreas', json.AfterHoursAssignmentAreas);
          }
        });
      }
    })
    .catch(error =>{
      console.log(error.message);
    });
  }

  getUserAssignmentArea(){
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }

    fetch(process.env.API_URL + "/api/account/getUserAssignmentAreas",{
      method: 'POST',
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({userAssignmentAreas: json.UserAssignmentAreas});
            console.log('userAssignmentAreas', json.UserAssignmentAreas);
          }
        });
      }
    })
    .catch(error =>{
      console.log(error.message);
    });

  }

  getDataGroups(){
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }

    fetch(process.env.API_URL + "/api/account/getDataGroups",{
      method: 'POST',
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.Groups){
              let dataGroups = [{
                GroupLabel : "All",
                DataGroupId : -1,
                DeletedDate : new Date()
              }];
              dataGroups = dataGroups.concat(json.Groups);
              let idMapping = dataGroups.reduce((acc, el, i) =>{
                acc[el.DataGroupId] = i;
                return acc;
              }, {});
              dataGroups.forEach(group =>{
                group.expanded = true;
                if(!group.ParentId){
                  group.isRoot = true;
                  return;
                }
                let parent = dataGroups[idMapping[group.ParentId]];
                if(parent)parent.children = [...(parent.children || []), group];
              });
              this.setState({dataGroups,dataUserGroups : json.UserGroups});
            }
          }
        });
      }
    })
    .catch(error =>{
      console.log(error.message);
    });
  }

  updateDataUserGroups(dataUserGroups){
    this.setState({dataUserGroups});
  }

  addDataGroup(ParentId){
    if(auth.isSuperViewer()){
      return;
    }
    let dataGroups = this.state.dataGroups;
    let newDataGroupId = -1;
    while(dataGroups.find(x => x.DataGroupId == newDataGroupId)){
      newDataGroupId--;
    }
    let date = new Date();
    let group = {
      GroupLabel : "",
      DataGroupId : newDataGroupId,
      CreatedDate : date,
      CreatedBy : auth.employeeId(),
      CreatedDateUTCOffset : - date.getTimezoneOffset() / 60,
      ParentId,
      expanded : true,
      isRoot : ParentId == null || ParentId == undefined
    }
    dataGroups.push(group);
    if(ParentId){
      let idMapping = dataGroups.reduce((acc, el, i) =>{
        acc[el.DataGroupId] = i;
        return acc;
      }, {});
      let parent = dataGroups[idMapping[ParentId]];
      parent.children = [...(parent.children || []), group];
    }
    this.setState({dataGroups});
  }

  editDataGroup(value, groupLabel, dataGroupId){
    let dataGroups = this.state.dataGroups;
    let group = dataGroups.find(x => x.DataGroupId == dataGroupId && x.GroupLabel == groupLabel);
    group.GroupLabel = value;
    this.setState({dataGroups});
  }

  getAllOffspring(dataGroup){
    let idList = [];
    idList.push(dataGroup.DataGroupId);
    if(dataGroup.children){
      dataGroup.children.forEach(child =>{
        idList = idList.concat(this.getAllOffspring(child));
      });
    }
    return idList;
  }

  deleteDataGroup(groupLabel, dataGroupId){
    if(auth.isSuperViewer()){
      return;
    }
    let dataGroups = this.state.dataGroups;
    //Remove from list if it was new.
    if(dataGroupId == -1){
      let index = dataGroups.findIndex(x => x.DataGroupId == dataGroupId && x.GroupLabel == groupLabel);
      if(index >-1){
        let group = dataGroups[index];
        //Need to also remove it from parent's child list
        if(group.ParentId){
          let parentGroup = dataGroups.find(x => x.DataGroupId == group.ParentId);
          let childIndex = parentGroup.children.findIndex(x => x.DataGroupId == dataGroupId && x.GroupLabel == groupLabel);
          parentGroup.children = parentGroup.children.filter((x,ind) => ind !== childIndex);
        }
        dataGroups = dataGroups.filter((x,ind) => ind !== index);
      }
    }
    else{
      let group = dataGroups.find(x => x.DataGroupId == dataGroupId && x.GroupLabel == groupLabel);
      let groupList = this.getAllOffspring(group);
      groupList.forEach(groupId =>{
        let groupToDelete = dataGroups.find(x => x.DataGroupId == groupId);
        groupToDelete.DeletedDate = new Date();
        groupToDelete.DeletedBy = auth.employeeId();
        groupToDelete.DeletedByUTCOffset = - new Date().getTimezoneOffset() / 60;
      })
      
    }
    this.setState({dataGroups});
  }

  expandGroup(group){
    let dataGroups = this.state.dataGroups;
    let foundGroup  = dataGroups.find(x => x.DataGroupId == group.DataGroupId);
    foundGroup.expanded = !group.expanded;
    this.setState({dataGroups});
  }

  updateDataGroups(){
    if(auth.isSuperViewer()){
      return;
    }
    // let companyId = auth.companyId();
    // let realCompany = localStorage.getItem("actualCompanyId");
    // if(companyId != realCompany){
    //   return;
    // }
    let update = true;
    for(let i=0; i<this.state.dataGroups.length; i++){
      if(this.state.dataGroups[i].GroupLabel.length == 0 && this.state.dataGroups[i].DeletedBy == null){
        update = false;
      }
    }
    if(update){
      this.setState({statusText : 'UPDATING GROUPS...'});
      let spliceIndex = 0;
      let Groups = JSON.parse(JSON.stringify(this.state.dataGroups));
      if(Groups.find(x => x.GroupLabel == "All")){
        spliceIndex = Groups.indexOf(x => x.GroupLabel == "All");
        if(spliceIndex == -1){
          for(let i = 0; i<Groups.length; i++){
            if(Groups[i].GroupLabel == "All"){
              spliceIndex = i;
            }
          }
          Groups.splice(spliceIndex, 1);
        }
        else{
          Groups.splice(spliceIndex, 1);
        }
      }
      let body ={
        UserId: auth.employeeId(),
        CompanyId : auth.companyId(),
        Groups,
        UTCOffset : - (new Date().getTimezoneOffset()/60)
      }
      fetch(process.env.API_URL + "/api/account/updateDataGroups",{
        method: "POST",
        headers:{
          "Content-Type" : "application/json; charset=utf-8",
          Accept : "application/json",
          Authorization : "Bearer " + auth.token()
        },
        body : JSON.stringify(body)
      })
      .then(r => {
        if(r.status == 200){
          r.json().then(responseJson =>{
            let json = JSON.parse(responseJson);
            if(json.ResponseType == 0){
              let dataGroups = [{
                GroupLabel : "All",
                DataGroupId : -1,
                DeletedDate : new Date()
              }];
              dataGroups = dataGroups.concat(json.Groups);
              let idMapping = dataGroups.reduce((acc, el, i) =>{
                acc[el.DataGroupId] = i;
                return acc;
              }, {});
              dataGroups.forEach(group =>{
                group.expanded = true;
                if(group.ParentId == null){
                  group.isRoot = true;
                  return;
                }
                let parent = dataGroups[idMapping[group.ParentId]];
                parent.children = [...(parent.children || []), group];
              });
              this.setState({dataGroups, updateStatusText : 'Updated Data Groups', loadingText : "", alertSeverity : "success"});
            }
            else{
              this.setState({updateStatusText : "There was a problem updating groups. Try again.", loadingText : "", alertSeverity : "error"});
            }
          });
        }
      })
      .catch(error =>{
        this.setState({updateStatusText : "There was a problem updating groups. Try again." + error.message, loadingGroups : false, alertSeverity : "error"});
      })
      .finally(() =>{
        this.setState({statusText : ""});
      });
    }
    else{
      this.setState({updateStatusText : "Enter in a name for the group.", alertSeverity: "warning"});
    }
  }

  enableDisableBaseForms(HideBaseInvestigation, HideBaseSurvey){  
    if(auth.isSuperViewer()){
      return;
    }
    this.setState({loadingForms : true});
    let body = {
      UserId : auth.employeeId(),
      CompanyId  : auth.companyId(),
      HideBaseInvestigation,
      HideBaseSurvey
    }
    fetch(process.env.API_URL + "/api/account/enableDisableBaseForms",{
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({updateStatusText : "Account information updated", loadingForms : false, alertSeverity:"success", hideBaseInvestigation : HideBaseInvestigation, hideBaseSurvey : HideBaseSurvey});
          }
          else{
            this.setState({updateStatusText : "There was a problem updating your information. " + json.ValidationMessageCollection[0].MessageText, loadingForms : false, alertSeverity:"error"});
          }
        })
      }
    })
    .catch(error =>{
      this.setState({updateStatusText : "There was a problem updating your information. Try again " + error.Message , alertSeverity:"error", loadingForms : false});
    });
  }

  getCompanyConfiguration(){
    if(auth.isSuperViewer()){
      return;
    }
    let body = {
      UserId : auth.employeeId(),
      CompanyId  : auth.companyId()
    }
    fetch(process.env.API_URL + "/api/account/company/GetCompanyConfiguration",{
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        r.json().then(responseJson =>{
          responseJson = JSON.parse(responseJson);
          this.setState({companyConfiguration: responseJson.CompanyConfigurationId != -1 ? responseJson : null, loadingCompanyConfig: false});
        });
      } else {
        this.setState({companyConfigFetchFailed: true, loadingCompanyConfig: false});
        console.log("getCompanyConfiguration: Error getting company config. Status: " + r.status);
      }
    })
    .catch(error =>{
      this.setState({companyConfigFetchFailed: true, loadingCompanyConfig: false});
      console.log("getCompanyConfiguration: Error getting company config: " + error);
    });
  }

  setMobileLocationTracking(enabled){
    if(auth.isSuperViewer()){
      return;
    }
    this.setState({loadingCompanyConfig : true});
    let now = new Date();
    var companyConfig = this.state.companyConfiguration ? JSON.parse(JSON.stringify(this.state.companyConfiguration)) : 
    {
      CompanyConfigurationId: -1,
      CompanyId: auth.companyId(),
      OfflineBasemapDisabledLeakSurvey: false,
      OfflineFeatureLayersDisabledLeakSurvey: false,
      ModifiedDate: now,
      ModifiedDateUTCOffset: -(now.getTimezoneOffset() / 60),
      ModifiedBy: auth.userId(),
      CreatedDate: now,
      CreatedDateUTCOffset: -(now.getTimezoneOffset() / 60),
      CreatedBy: auth.userId(),
      AppLocationTrackingEnabled: enabled
    };
    companyConfig.AppLocationTrackingEnabled = enabled;
    companyConfig.ModifiedDate = now;
    companyConfig.ModifiedDateUTCOffset = -(now.getTimezoneOffset() / 60);
    companyConfig.ModifiedBy = auth.userId();

    let body = {
      UserId : auth.userId(),
      CompanyId  : auth.companyId(),
      CompanyConfiguration: companyConfig
    }
    fetch(process.env.API_URL + "/api/account/company/UpdateCompanyConfiguration",{
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({updateStatusText : "App location tracking " + (enabled ? "enabled" : "disabled"), loadingCompanyConfig : false, alertSeverity:"success", companyConfiguration: json.CompanyConfiguration});
          }
          else{
            this.setState({updateStatusText : "There was a problem " + (enabled ? "enabling" : "disabling") + " app location tracking." + json.ValidationMessageCollection[0].MessageText, loadingCompanyConfig : false, alertSeverity:"error"});
          }
        })
      }
    })
    .catch(error =>{
      this.setState({updateStatusText : "There was a problem " + (enabled ? "enabling" : "disabling") + " app location tracking. Try again " + error.Message , alertSeverity:"error", loadingCompanyConfig : false});
    });
  }

  updateOperationalHours(){
    if(auth.isSuperViewer()){
      return;
    }
    if(!this.state.companyTimezone || this.state.companyTimezone == ""){
      this.setState({updateStatusText : "Select a timezone before saving.", alertSeverity:"warning"});
      return;
    }
    if(!this.state.companyHourStart || this.state.companyHourStart == ""){
      this.setState({updateStatusText : "Select a starting time before saving.", alertSeverity:"warning"});
      return;
    }
    if(!this.state.companyHourEnd || this.state.companyHourEnd == ""){
      this.setState({updateStatusText : "Select an ending time before saving.", alertSeverity:"warning"});
      return;
    }
    if(!this.state.companyDays || this.state.companyDays.length == 0){
      this.setState({updateStatusText : "Select at least one day of the week before saving.", alertSeverity:"warning"});
      return;
    }
    var days = "";
    this.state.companyDays.forEach(day =>{
      days += (day + ",");
    });
    if(days[days.length -1] == ","){
      days = days.substring(0, days.length - 1);
    }
    this.setState({loadingHours : true});
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      Timezone : this.state.companyTimezone,
      OperationalHourStart : this.state.companyHourStart,
      OperationalHourEnd : this.state.companyHourEnd,
      OperationalDays : days
    }
    fetch(process.env.API_URL + "/api/account/updateOperationalHours",{
      method : "Post",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({updateStatusText : "Account information updated", loadingHours : false, alertSeverity:"success"});
          }
          else{
            this.setState({updateStatusText : "There was a problem updating your information. " + json.ValidationMessageCollection[0].MessageText, loadingHours : false, alertSeverity:"error"});
          }
        });
      }
      else{
        this.setState({updateStatusText : "There was a problem updating your information. Try again ", loadingHours : false, alertSeverity:"error"});
      }
    })
    .catch(error =>{
      this.setState({updateStatusText : "There was a problem updating your information. Try again " + error.Message , loadingHours : false, alertSeverity:"error"});
    });
  }

  updateAfterHoursAssignments(){
    if(auth.isSuperViewer()){
      return;
    }
    this.setState({loadingAfterHoursAssignment : true});
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      CompanyAfterHoursAlertEmail : this.state.companyAfterHoursEmail,
      AfterHoursAssignmentAreas : this.state.afterHoursAssignmentAreas,
      UTCOffset : - (new Date().getTimezoneOffset() / 60)
    }
    fetch(process.env.API_URL + "/api/account/updateAfterHours",{
      method : "Post",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({updateStatusText : "Account information updated", loadingAfterHoursAssignment : false, alertSeverity:"success"});
          }
          else{
            this.setState({updateStatusText : "There was a problem updating your information. " + json.ValidationMessageCollection[0].MessageText, loadingAfterHoursAssignment : false, alertSeverity:"error"});
          }
        });
      }
      else{
        this.setState({updateStatusText : "There was a problem updating your information. ", loadingAfterHoursAssignment : false, alertSeverity:"error"});
      }
    })
    .catch(error =>{
      this.setState({updateStatusText : "There was a problem updating your information. Try again " + error.Message , loadingAfterHoursAssignment : false, alertSeverity:"error"});
    });
  }

  updateDirtCredentials(){
    if(auth.isSuperViewer()){
      return;
    }
    this.setState({loadingCredentials : true});
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      DirtKey : this.state.dirtKey,
      DirtHash : this.state.dirtSecret
    }
    fetch(process.env.API_URL + "/api/account/updateDirtInfo",{
      method : "Post",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({updateStatusText : "Account information updated", loadingCredentials : false, alertSeverity:"success"});
          }
          else{
            this.setState({updateStatusText : "There was a problem updating your information. " + json.ValidationMessageCollection[0].MessageText, loadingCredentials : false, alertSeverity:"error"});
          }
        });
      }
    })
    .catch(error =>{
      this.setState({updateStatusText : "There was a problem updating your information. Try again " + error.Message , loadingCredentials : false, alertSeverity:"error"});
    });
  }

  getNotifications(){
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }

    fetch(process.env.API_URL +"/api/account/getNotifications",{
      method: "POST",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.Triggers){
              this.setState({Triggers : json.Triggers});
            }
          }
        });
      }
    })
    .catch(error =>{
      console.log(error.message);
    })
  }

  getCompanyKMLShapeLayers(){
    let body={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      GIS : true,
      GPS : true,
      Survey : true,
      ShowAllCompanyLayers : true
    }
    fetch(process.env.API_URL + "/api/account/getCompanyKMLShapeLayers",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status ==200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.CompanyKMLSHAPELayers){
              this.setState({companyKMLShapeLayers : json.CompanyKMLSHAPELayers});
            }
          }
        });
      }
    })
    .catch(error =>{
      console.log(error);
    })
  }

  getCompanyArcGisLayers(){
    let body={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      GIS : true,
      GPS : true,
      Survey : true,
      Analysis : true,
      TicketArea : true,
      ShowAllCompanyLayers : true
    }

    fetch(process.env.API_URL + "/api/account/getCompanyArcGisLayers",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status ==200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.CompanyArcGisLayers){
              this.setState({CompanyArcGisLayers : json.CompanyArcGisLayers});
              console.log('CompanyArcGisLayers', json.CompanyArcGisLayers);
            }
            if(json.FeatureCollection){
              var assignmentAreas = json.FeatureCollection;
              assignmentAreas = assignmentAreas.concat(this.state.ticketAssignmentAreas);
              this.setState({CompanyArcGisLayerFeatures : json.FeatureCollection, assignmentAreas}); //Added this in case this gets returned after the get forms method that was doing this before.
              console.log('FeatureCollection', json.FeatureCollection);
            }
          }
        });
      }
    })
    .catch(error =>{
      console.log(error);
    })
  }

  getCompanyResponseCodes(){
    this.setState({loadingCompanyResponseCodes : true});
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }

    fetch(process.env.API_URL + "/api/account/getCompanyResponseCodes",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      this.setState({loadingCompanyResponseCodes : false});
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.CompanyResponseCodes){
              var distinctMemberCodes = {nullCallCenterId : []};
              var distinctMemberCodesUnsorted = [];
              var distinctResponseCodes = [];
              var distinctFacilities = [];
              json.CompanyResponseCodes.forEach(companyCode =>{
                if(distinctMemberCodesUnsorted.indexOf(companyCode.MemberCode) == -1) distinctMemberCodesUnsorted.push(companyCode.MemberCode);
                if(!companyCode.CallCenterId){
                  if(!companyCode.DeletedDate && !companyCode.CallCenterId && companyCode.MemberCode && companyCode.MemberCode != "" && !distinctMemberCodes.nullCallCenterId.includes(companyCode.MemberCode)){
                    distinctMemberCodes.nullCallCenterId.push(companyCode.MemberCode);
                  }
                }
                else{
                  if(!distinctMemberCodes[companyCode.CallCenterId]){
                    distinctMemberCodes[companyCode.CallCenterId] = [];
                  }
                  if(!companyCode.DeletedDate && companyCode.MemberCode && companyCode.MemberCode != "" && !distinctMemberCodes[companyCode.CallCenterId].includes(companyCode.MemberCode)){
                    distinctMemberCodes[companyCode.CallCenterId].push(companyCode.MemberCode);
                  }
                }
                if(!companyCode.DeletedDate && companyCode.ResponseName && companyCode.ResponseName != "" && !distinctResponseCodes.find(x => x.ResponseName == companyCode.ResponseName && x.CallCenterId == companyCode.CallCenterId)){
                  distinctResponseCodes.push({ResponseName : companyCode.ResponseName, ResponseCode : companyCode.ResponseCode, CallCenterId : companyCode.CallCenterId});
                }
                if(!companyCode.DeletedDate && companyCode.Facility && companyCode.Facility != "" && !distinctFacilities.find(x => x.Facility == companyCode.Facility && x.CallCenterId == companyCode.CallCenterId)){
                  distinctFacilities.push({Facility : companyCode.Facility, CallCenterId : companyCode.CallCenterId});
                }
              });
              this.setState({companyResponseCodes : json.CompanyResponseCodes,distinctResponseCodes,distinctMemberCodes,distinctMemberCodesUnsorted, distinctFacilities });
            }
          }
        });
      }
    })
    .catch(error =>{
      this.setState({loadingCompanyResponseCodes : false});
      console.log(error);
    });
  }
  
  addCompanyTicketBillingGroup(){
    let billingGroups = this.state.companyTicketBillingGroups;
    let now = new Date();
    billingGroups.push({
      CompanyTicketBillingGroupId : -1,
      CompanyId : auth.companyId(),
      Name: "",
      TicketBillingTypeId : 1,
      PricePerUnit : 0,
      PricePerUnitDisplay: "$0.00",
      DistancePerUnit: null,
      DistancePerUnitDisplay: "",
      CreatedDate : now,
      CreatedBy : auth.employeeId(),
      ModifiedDate : now,
      ModifiedBy : auth.employeeId(),
    });
    this.setState({companyTicketBillingGroups : billingGroups});
  }

  removeCompanyTicketBillingGroup(index){
    let billingGroups = this.state.companyTicketBillingGroups;
    let billingGroup = billingGroups[index];
    if(billingGroup.CompanyTicketBillingGroupId == -1){
      billingGroups.splice(index, 1)
    } else{
      let now = new Date();
      billingGroup.ModifiedDate = now;
      billingGroup.ModifiedBy = auth.employeeId();
      billingGroup.DeletedDate = now;
      billingGroup.DeletedBy = auth.employeeId();
      billingGroups[index] = billingGroup;
    }
    this.setState({companyTicketBillingGroups : billingGroups});

  }

  addCompanyTicketBillingGroupMemberCodeAssignment(billingGroupId, memberCode){
    let assignments = this.state.companyTicketBillingGroupMemberCodeAssignments;
    assignments.push({
      CompanyTicketBillingGroupMemberCodeAssignmentId : -1,
      CompanyId : auth.companyId(),
      CompanyTicketBillingGroupId: billingGroupId,
      MemberCode : memberCode,
      CreatedDate : new Date(),
      CreatedBy : auth.employeeId()
    });
    this.setState({companyTicketBillingGroupMemberCodeAssignments : assignments});
  }

  removeCompanyTicketBillingGroupMemberCodeAssignment(index){
    let assignments = this.state.companyTicketBillingGroupMemberCodeAssignments;
    let assignment = assignments[index];
    if(assignment.CompanyTicketBillingGroupMemberCodeAssignmentId == -1){
      assignments.splice(index, 1);
    } else{
      let now = new Date();
      assignment.ModifiedDate = now;
      assignment.ModifiedBy = auth.employeeId();
      assignment.DeletedDate = now;
      assignment.DeletedBy = auth.employeeId();
    }
    this.setState({companyTicketBillingGroupMemberCodeAssignments : assignments});
  }

  getCompanyTicketBillingGroups(){
    this.setState({loadingCompanyTicketBillingGroups : true});
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }

    fetch(process.env.API_URL + "/api/account/getCompanyTicketBillingGroups",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.CompanyTicketBillingGroups){
              for(let i = 0; i < json.CompanyTicketBillingGroups.length; i++){
                json.CompanyTicketBillingGroups[i].PricePerUnitDisplay = "$" + json.CompanyTicketBillingGroups[i].PricePerUnit.toFixed(2);
                if(json.CompanyTicketBillingGroups[i].TicketBillingTypeId == 4 && json.CompanyTicketBillingGroups[i].DistancePerUnit != null){
                  json.CompanyTicketBillingGroups[i].DistancePerUnitDisplay = json.CompanyTicketBillingGroups[i].DistancePerUnit;
                }
              }
              this.setState({companyTicketBillingGroups: json.CompanyTicketBillingGroups, currentCompanyTicketBillingGroups: JSON.stringify(json.CompanyTicketBillingGroups), loadingCompanyTicketBillingGroups: false});
            } else {
              this.setState({loadingCompanyTicketBillingGroups : false});
            }
          } else{
            this.setState({loadingCompanyTicketBillingGroups : false});
          }
        });
      } else{
        
        this.setState({loadingCompanyTicketBillingGroups : false});
      }
    })
    .catch(error =>{
      this.setState({loadingCompanyTicketBillingGroups : false});
      console.log(error);
    });
  }

  getCompanyTicketBillingGroupMemberCodeAssignments(){
    this.setState({loadingCompanyResponseCodes : true});
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }

    fetch(process.env.API_URL + "/api/account/getCompanyTicketBillingGroupMemberCodeAssignments",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.CompanyTicketBillingGroupMemberCodeAssignments){
              this.setState({companyTicketBillingGroupMemberCodeAssignments: json.CompanyTicketBillingGroupMemberCodeAssignments, currentCompanyTicketBillingGroupMemberCodeAssignments: JSON.stringify(json.CompanyTicketBillingGroupMemberCodeAssignments), loadingCompanyTicketBillingGroupMemberCodeAssignments: false, selectedBillingGroupIdForAssignment: -1});
            } else {
              this.setState({loadingCompanyTicketBillingGroupMemberCodeAssignments : false});
            }
          } else{
            this.setState({loadingCompanyTicketBillingGroupMemberCodeAssignments : false});
          }
        });
      } else{
        
        this.setState({loadingCompanyTicketBillingGroupMemberCodeAssignments : false});
      }
    })
    .catch(error =>{
      this.setState({loadingCompanyTicketBillingGroupMemberCodeAssignments : false});
      console.log(error);
    });
  }

  updateCompanyTicketBillingGroups(){
    if(auth.isSuperViewer()){
      return;
    }
    // let companyId = auth.companyId();
    // let realCompany = localStorage.getItem("actualCompanyId");
    // if(companyId != realCompany){
    //   return;
    // }
    
    this.setState({loadingCompanyTicketBillingGroups: true});
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      CompanyTicketBillingGroups : JSON.parse(JSON.stringify(this.state.companyTicketBillingGroups))
    };

    for(let i = 0; i < body.CompanyTicketBillingGroups.length; i++){
      //check for blank names
      body.CompanyTicketBillingGroups[i].Name = body.CompanyTicketBillingGroups[i].Name.trim();
      if(body.CompanyTicketBillingGroups[i].Name == ""){
        this.setState({updateStatusText :"Billing group names cannot be blank", loadingCompanyTicketBillingGroups : false, alertSeverity : 'warning'});
        return;
      }
      //check for names longer than 50 characters
      if(body.CompanyTicketBillingGroups[i].Name.length > 50){
        this.setState({updateStatusText :"Billing group names cannot be longer than 50 characters", loadingCompanyTicketBillingGroups : false, alertSeverity : 'warning'});
        return;
      }
      //validate price
      if(body.CompanyTicketBillingGroups[i].PricePerUnit < 0 || body.CompanyTicketBillingGroups[i].PricePerUnit > 9999999.99){
        this.setState({updateStatusText :"Billing group price must be between $0 and $9,999,999.99", loadingCompanyTicketBillingGroups : false, alertSeverity : 'warning'});
        return;
      }
      if(body.CompanyTicketBillingGroups[i].TicketBillingTypeId == 4){
        if((body.CompanyTicketBillingGroups[i].DistancePerUnit == null || (typeof body.CompanyTicketBillingGroups[i].DistancePerUnit == 'undefined') || body.CompanyTicketBillingGroups[i].DistancePerUnit < 1)){
          this.setState({updateStatusText :"Billing group feet must be 1 or greater", loadingCompanyTicketBillingGroups : false, alertSeverity : 'warning'});
          return;
        }
      } else{
        body.CompanyTicketBillingGroups[i].DistancePerUnit = null;
      }
      //check for duplicate names
      for(let j = i + 1; j < body.CompanyTicketBillingGroups.length; j++){
        if(i != j && body.CompanyTicketBillingGroups[i].Name == body.CompanyTicketBillingGroups[j].Name.trim() && (body.CompanyTicketBillingGroups[i].DeletedDate != null && body.CompanyTicketBillingGroups[j].DeletedDate != null)){
          this.setState({updateStatusText : "There are multiple billing groups with name '" + body.CompanyTicketBillingGroups[i].Name + "'. Billing group names must be unique", loadingCompanyTicketBillingGroups : false, alertSeverity : 'warning'});
          return;
        }
      }
    }

    fetch(process.env.API_URL + "/api/account/updateCompanyTicketBillingGroups",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            let companyTicketBillingGroups = json.CompanyTicketBillingGroups.filter(x => x.DeletedDate == null);
            for(let i = 0; i < companyTicketBillingGroups.length; i++){
              companyTicketBillingGroups[i].PricePerUnitDisplay = "$" + companyTicketBillingGroups[i].PricePerUnit.toFixed(2);
              if(companyTicketBillingGroups[i].TicketBillingTypeId == 4 && companyTicketBillingGroups[i].DistancePerUnit != null){
                companyTicketBillingGroups[i].DistancePerUnitDisplay = companyTicketBillingGroups[i].DistancePerUnit;
              }
            }
            this.setState({companyTicketBillingGroups, currentCompanyTicketBillingGroups: JSON.stringify(companyTicketBillingGroups), updateStatusText : "Updated Billing Groups", loadingCompanyTicketBillingGroups : false, alertSeverity : "success" });
            if(body.CompanyTicketBillingGroups.findIndex(x => x.DeletedDate != null) != -1) this.getCompanyTicketBillingGroupMemberCodeAssignments();
          }
          else{
            if(json.ResponseType == 1 && json.CompanyTicketBillingGroups){
              let companyTicketBillingGroups = json.CompanyTicketBillingGroups.filter(x => x.DeletedDate == null);
              for(let i = 0; i < companyTicketBillingGroups.length; i++){
                companyTicketBillingGroups[i].PricePerUnitDisplay = "$" + companyTicketBillingGroups[i].PricePerUnit.toFixed(2);
                if(companyTicketBillingGroups[i].TicketBillingTypeId == 4 && companyTicketBillingGroups[i].DistancePerUnit != null){
                  companyTicketBillingGroups[i].DistancePerUnitDisplay = companyTicketBillingGroups[i].DistancePerUnit;
                }
              }
              this.setState({companyTicketBillingGroups, currentCompanyTicketBillingGroups: JSON.stringify(companyTicketBillingGroups), updateStatusText :"There was a problem updating one or more Billing Groups. Try again.", loadingCompanyTicketBillingGroups : false, alertSeverity : 'error'});
            } else if(json.ResponseMessageCollection[0]){
              this.setState({updateStatusText : json.ResponseMessageCollection[0].Message, loadingCompanyTicketBillingGroups : false, alertSeverity : 'error'});
            }
          }
        });
      }
      else{
        this.setState({updateStatusText :"There was a problem updating Billing Groups. Try again.", loadingCompanyTicketBillingGroups : false, alertSeverity : 'error'});
      }
    })
    .catch(e =>{
      this.setState({updateStatusText :"There was a problem updating Billing Groups. Try again. " + e.message, loadingCompanyTicketBillingGroups : false, alertSeverity : 'error'});
    })
  }

  revertCompanyTicketBillingGroups(){
    this.setState({loadingCompanyTicketBillingGroups : true});
    setTimeout(()=>{
      this.setState({companyTicketBillingGroups : JSON.parse(this.state.currentCompanyTicketBillingGroups), loadingCompanyTicketBillingGroups : false});
    },500);
  }

  updateCompanyTicketBillingGroupMemberCodeAssignments(){
    if(auth.isSuperViewer()){
      return;
    }
    // let companyId = auth.companyId();
    // let realCompany = localStorage.getItem("actualCompanyId");
    // if(companyId != realCompany){
    //   return;
    // }
    
    this.setState({loadingCompanyTicketBillingGroupMemberCodeAssignments : true});

    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      //No need to add codes that were added just to be removed. 
      CompanyTicketBillingGroupMemberCodeAssignments : this.state.companyTicketBillingGroupMemberCodeAssignments,
      UTCOffset : - (new Date().getTimezoneOffset()/60)
    };
    fetch(process.env.API_URL + "/api/account/updateCompanyTicketBillingGroupMemberCodeAssignments",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            const companyTicketBillingGroupMemberCodeAssignments = json.CompanyTicketBillingGroupMemberCodeAssignments.filter(x => x.DeletedDate == null);
            this.setState({companyTicketBillingGroupMemberCodeAssignments, currentCompanyTicketBillingGroupMemberCodeAssignments: JSON.stringify(companyTicketBillingGroupMemberCodeAssignments), updateStatusText : "Updated Billing Group assignments", loadingCompanyTicketBillingGroupMemberCodeAssignments : false, alertSeverity : "success" });
          }
          else{
            if(json.ResponseType == 1 && json.CompanyTicketBillingGroupMemberCodeAssignments){
              const companyTicketBillingGroupMemberCodeAssignments = json.CompanyTicketBillingGroupMemberCodeAssignments.filter(x => x.DeletedDate == null);
              this.setState({companyTicketBillingGroupMemberCodeAssignments, currentCompanyTicketBillingGroupMemberCodeAssignments: JSON.stringify(companyTicketBillingGroupMemberCodeAssignments), updateStatusText :"There was a problem updating one or more Billing Group assignments. Try again.", loadingCompanyTicketBillingGroupMemberCodeAssignments : false, alertSeverity : 'error'}); 
            } else if(json.ResponseMessageCollection[0]){
              this.setState({updateStatusText : json.ResponseMessageCollection[0].Message, loadingCompanyTicketBillingGroupMemberCodeAssignments : false, alertSeverity : 'error'});
            }
          }
        });
      }
      else{
        this.setState({updateStatusText :"There was a problem updating Billing Group assignments. Try again.", loadingCompanyTicketBillingGroupMemberCodeAssignments : false, alertSeverity : 'error'});
      }
    })
    .catch(e =>{
      this.setState({updateStatusText :"There was a problem updating Billing Group assignments. Try again. " + e.message, loadingCompanyTicketBillingGroupMemberCodeAssignments : false, alertSeverity : 'error'});
    })
  }

  revertCompanyTicketBillingGroupMemberCodeAssignments(){
    this.setState({companyTicketBillingGroupMemberCodeAssignments : JSON.parse(this.state.currentCompanyTicketBillingGroupMemberCodeAssignments)});
  }

  selectBillingGroupForAssignment(e){
    this.setState({selectedBillingGroupIdForAssignment : e.target.value });
  }

  toggleAllBillingGroupMemberCodeOptions(e){
    if(e.target.checked){
      this.assignAllAvailableMemberCodesToCurrentBillingGroup();
    } else{
      this.unassignAllMemberCodesFromCurrentBillingGroup();
    }
  }

  assignAllAvailableMemberCodesToCurrentBillingGroup(){
    if(this.state.selectedBillingGroupIdForAssignment == -1) return;
    let unassignedMemberCodes = this.state.distinctMemberCodesUnsorted.filter(x => !this.state.companyTicketBillingGroupMemberCodeAssignments.find(y => y.MemberCode == x && !y.DeletedDate));
    unassignedMemberCodes.forEach((memberCode)=>{
      this.toggleBillingGroupMemberCodeAssignment(memberCode);
    });
  }

  unassignAllMemberCodesFromCurrentBillingGroup(){
    if(this.state.selectedBillingGroupIdForAssignment == -1) return;
    let assignments = this.state.companyTicketBillingGroupMemberCodeAssignments.filter(x=> x.CompanyTicketBillingGroupId == this.state.selectedBillingGroupIdForAssignment && !x.DeletedDate);
    assignments.forEach((assignment)=>{
      this.toggleBillingGroupMemberCodeAssignment(assignment.MemberCode);
    });
  }

  toggleBillingGroupMemberCodeAssignment(memberCode){
    if(this.state.selectedBillingGroupIdForAssignment == -1) return;
    let assignments = this.state.companyTicketBillingGroupMemberCodeAssignments;
    //Find existing assignment if present
    let assignmentIndex = assignments.findIndex(x=>x.MemberCode == memberCode && x.CompanyTicketBillingGroupId == this.state.selectedBillingGroupIdForAssignment);
    if(assignmentIndex > -1){
      if(assignments[assignmentIndex].DeletedDate){
        assignments[assignmentIndex].ModifiedDate = new Date();
        assignments[assignmentIndex].ModifiedBy = auth.employeeId();
        assignments[assignmentIndex].DeletedDate = null;
        assignments[assignmentIndex].DeletedBy = null;
      } else if(assignments[assignmentIndex].CompanyTicketBillingGroupMemberCodeAssignmentId == -1){
        assignments.splice(assignmentIndex, 1);
      } else{
        assignments[assignmentIndex].DeletedDate = new Date();
        assignments[assignmentIndex].DeletedBy = auth.employeeId();
      }
    } else{
      assignments.push({
        CompanyTicketBillingGroupMemberCodeAssignmentId : -1,
        CompanyId : auth.companyId(),
        CompanyTicketBillingGroupId: this.state.selectedBillingGroupIdForAssignment,
        MemberCode : memberCode,
        CreatedDate : new Date(),
        CreatedBy : auth.employeeId()
      });
    }
    this.setState({companyTicketBillingGroupMemberCodeAssignments : assignments});
  }

  selectMemberCode(e){
    this.setState({selectedMemberCode : e.target.value });
  }

  updateCallCenter(e){
    let selectedCallCenter = this.state.CallCenters.find(x => x.CallCenterId == e.target.value);
    if(this.state.CompanyCallCenterConfigs && this.state.CompanyCallCenterConfigs.find(x => x.CallCenterId == e.target.value)){
      selectedCallCenter = this.state.CompanyCallCenterConfigs.find(x => x.CallCenterId == e.target.value)
    }
    else{
      let now = new Date();
      if(!selectedCallCenter){
        selectedCallCenter = {CallCenterId : null, CallCenterName : ""}
      }
      selectedCallCenter.CompanyCallCenterConfigId = -1;
      selectedCallCenter.CallCenterUserName = "";
      selectedCallCenter.CallCenterPassword = "";
      selectedCallCenter.CompanyId = auth.companyId();
      selectedCallCenter.ModifiedDate = now; 
      selectedCallCenter.ModifiedDateUTCOffset = - (now.getTimezoneOffset() /60); 
      selectedCallCenter.ModifiedBy = auth.employeeId(); 
      selectedCallCenter.CreatedDate = now; 
      selectedCallCenter.CreatedDateUTCOffset = - (now.getTimezoneOffset() /60); 
      selectedCallCenter.CreatedBy = auth.employeeId();
    }
    this.setState({selectedCallCenter});
  }

  updateCallCenterUserName(e){
    let selectedCallCenter = this.state.selectedCallCenter;
    selectedCallCenter.CallCenterUserName = e.target.value;
    this.setState({selectedCallCenter});
  }

  updateCallCenterPassword(e){
    let selectedCallCenter = this.state.selectedCallCenter;
    selectedCallCenter.CallCenterPassword = e.target.value;
    this.setState({selectedCallCenter});
  }

  updateCallCenterConfig(){
    this.setState({loadingCallCenterConfig : true});
    var CompanyCallCenterConfig = this.state.selectedCallCenter;
    CompanyCallCenterConfig.ModifiedDate = new Date();
    CompanyCallCenterConfig.ModifiedDateUTCOffset = - (new Date().getTimezoneOffset() / 60);
    CompanyCallCenterConfig.ModifiedBy = auth.employeeId();
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      CompanyCallCenterConfig : this.state.selectedCallCenter
    }
    fetch(process.env.API_URL + "/api/account/updateCallCenterConfig",{
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=ust-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      this.setState({loadingCallCenterConfig : false});
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({loadingCallCenterConfig : false, CompanyCallCenterConfigs : responseJson.CompanyCallCenterConfigCollection, updateStatusText : "Successfully Updated Call Center Config.", alertSeverity:"success"});
          }
          else{
            this.setState({loadingCallCenterConfig : false, updateStatusText : "Error updating Call Center Config.", alertSeverity:"error"});
          }
        });
      }
      else{
        this.setState({loadingCallCenterConfig : false, updateStatusText : "Error updating Call Center Config.", alertSeverity:"error"});
      }
    })
    .catch(error =>{
      this.setState({loadingCallCenterConfig : false, updateStatusText : "Error updating Call Center Config.", alertSeverity:"error"});
      console.log(error);
    });
  }

  selectFacility(facility, addingCode){
    let companyResponseCodes = this.state.companyResponseCodes;
    let now = new Date();
    if(facility.Facility == "ALL"){
      this.state.distinctFacilities.forEach(distinctFacility =>{
        if(distinctFacility.CallCenterId == this.state.selectedCallCenter.CallCenterId){
          let code = companyResponseCodes.find(x => x.Facility == distinctFacility.Facility);
          if(addingCode){
            //CODE EXISTED BUT WAS DELETED AND NOW ADDING BACK.
            if(code){
              code.ModifiedDate = now;
              code.ModifiedBy = auth.employeeId();
              code.ModifiedDateDateUTCOffset = - now.getTimezoneOffset() / 60;
              code.DeletedBy = null;
              code.DeletedDateUTCOffset = null;
              code.DeletedDate = null;
            }
            //FIRST TIME ADDING RESPONSE CODE
            else{
              companyResponseCodes.push({
                CompanyResponseCodeId : -1,
                CompanyId : auth.companyId(), 
                MemberCode : this.state.selectedMemberCode, 
                CreatedDate :  now,
                CreatedDateUTCOffset : - now.getTimezoneOffset() / 60,
                CreatedBy : auth.employeeId(),
                ModifiedDateDate :  now,
                ModifiedDateDateUTCOffset : - now.getTimezoneOffset() / 60,
                ModifiedDateBy : auth.employeeId(),
                CallCenterId : this.state.selectedCallCenter.CallCenterId,
                Facility : distinctFacility.Facility
              });
            }
          }
          else{
            //code found set deleted date
            if(code){
              code.ModifiedDate = now;
              code.ModifiedDateDateUTCOffset = - now.getTimezoneOffset() / 60;
              code.ModifiedBy = auth.employeeId();
              code.DeletedBy = auth.employeeId();
              code.DeletedDateUTCOffset = - now.getTimezoneOffset() / 60,
              code.DeletedDate = now;
            }
            //Don't think this should ever happen but else code wasn't found for somereason but nothing to do since we are trying to delete anyways.
          }
        }
      })
    }
    else{
      //ADDING RESPONSE CODE
      let code = companyResponseCodes.find(x => x.Facility == facility.Facility );
      if(addingCode){
        //CODE EXISTED BUT WAS DELETED AND NOW ADDING BACK.
        if(code){
          code.ModifiedDate = now;
          code.ModifiedBy = auth.employeeId();
          code.ModifiedDateDateUTCOffset = - now.getTimezoneOffset() / 60;
          code.DeletedBy = null;
          code.DeletedDateUTCOffset = null;
          code.DeletedDate = null;
        }
        //FIRST TIME ADDING RESPONSE CODE
        else{
          companyResponseCodes.push({
            CompanyResponseCodeId : -1,
            CompanyId : auth.companyId(), 
            MemberCode : this.state.selectedMemberCode, 
            ResponseName : null, 
            ResponseCode : null,
            CreatedDate :  now,
            CreatedDateUTCOffset : - now.getTimezoneOffset() / 60,
            CreatedBy : auth.employeeId(),
            ModifiedDateDate :  now,
            ModifiedDateDateUTCOffset : - now.getTimezoneOffset() / 60,
            ModifiedDateBy : auth.employeeId(),
            CallCenterId : this.state.selectedCallCenter.CallCenterId,
            Facility : facility.Facility
          });
        }
      }
      //REMOVE RESPONSE CODE
      else{
        //code found set deleted date
        if(code){
          code.ModifiedDate = now;
          code.ModifiedDateDateUTCOffset = - now.getTimezoneOffset() / 60;
          code.ModifiedBy = auth.employeeId();
          code.DeletedBy = auth.employeeId();
          code.DeletedDateUTCOffset = - now.getTimezoneOffset() / 60,
          code.DeletedDate = now;
        }
        //Don't think this should ever happen but else code wasn't found for somereason but nothing to do since we are trying to delete anyways.
      }
    }
    
    this.setState({companyResponseCodes});
  }

  selectResponseCode(responseCode, addingCode){
    let companyResponseCodes = this.state.companyResponseCodes;
    let now = new Date();
    if(responseCode.ResponseName == "ALL"){
      this.state.distinctResponseCodes.forEach(distinctCode =>{
        if(distinctCode.CallCenterId == this.state.selectedCallCenter.CallCenterId){
          let code = companyResponseCodes.find(x => x.ResponseName == distinctCode.ResponseName && x.MemberCode == this.state.selectedMemberCode);
          if(addingCode){
            //CODE EXISTED BUT WAS DELETED AND NOW ADDING BACK.
            if(code){
              code.ModifiedDate = now;
              code.ModifiedBy = auth.employeeId();
              code.ModifiedDateDateUTCOffset = - now.getTimezoneOffset() / 60;
              code.DeletedBy = null;
              code.DeletedDateUTCOffset = null;
              code.DeletedDate = null;
            }
            //FIRST TIME ADDING RESPONSE CODE
            else{
              companyResponseCodes.push({
                CompanyResponseCodeId : -1,
                CompanyId : auth.companyId(), 
                MemberCode : this.state.selectedMemberCode, 
                ResponseName : distinctCode.ResponseName, 
                ResponseCode : distinctCode.ResponseCode,
                CreatedDate :  now,
                CreatedDateUTCOffset : - now.getTimezoneOffset() / 60,
                CreatedBy : auth.employeeId(),
                ModifiedDateDate :  now,
                ModifiedDateDateUTCOffset : - now.getTimezoneOffset() / 60,
                ModifiedDateBy : auth.employeeId(),
                CallCenterId : this.state.selectedCallCenter.CallCenterId
              });
            }
          }
          else{
            //code found set deleted date
            if(code){
              code.ModifiedDate = now;
              code.ModifiedDateDateUTCOffset = - now.getTimezoneOffset() / 60;
              code.ModifiedBy = auth.employeeId();
              code.DeletedBy = auth.employeeId();
              code.DeletedDateUTCOffset = - now.getTimezoneOffset() / 60,
              code.DeletedDate = now;
            }
            //Don't think this should ever happen but else code wasn't found for somereason but nothing to do since we are trying to delete anyways.
          }
        }
      });
    }
    else{
      //ADDING RESPONSE CODE
      let code = companyResponseCodes.find(x => x.ResponseName == responseCode.ResponseName && x.MemberCode == this.state.selectedMemberCode);
      if(addingCode){
        //CODE EXISTED BUT WAS DELETED AND NOW ADDING BACK.
        if(code){
          code.ModifiedDate = now;
          code.ModifiedBy = auth.employeeId();
          code.ModifiedDateDateUTCOffset = - now.getTimezoneOffset() / 60;
          code.DeletedBy = null;
          code.DeletedDateUTCOffset = null;
          code.DeletedDate = null;
        }
        //FIRST TIME ADDING RESPONSE CODE
        else{
          companyResponseCodes.push({
            CompanyResponseCodeId : -1,
            CompanyId : auth.companyId(), 
            MemberCode : this.state.selectedMemberCode, 
            ResponseName : responseCode.ResponseName, 
            ResponseCode : responseCode.ResponseCode,
            CreatedDate :  now,
            CreatedDateUTCOffset : - now.getTimezoneOffset() / 60,
            CreatedBy : auth.employeeId(),
            ModifiedDateDate :  now,
            ModifiedDateDateUTCOffset : - now.getTimezoneOffset() / 60,
            ModifiedDateBy : auth.employeeId(),
            CallCenterId : this.state.selectedCallCenter.CallCenterId
          });
        }
      }
      //REMOVE RESPONSE CODE
      else{
        //code found set deleted date
        if(code){
          code.ModifiedDate = now;
          code.ModifiedDateDateUTCOffset = - now.getTimezoneOffset() / 60;
          code.ModifiedBy = auth.employeeId();
          code.DeletedBy = auth.employeeId();
          code.DeletedDateUTCOffset = - now.getTimezoneOffset() / 60,
          code.DeletedDate = now;
        }
        //Don't think this should ever happen but else code wasn't found for somereason but nothing to do since we are trying to delete anyways.
      }
    }
    
    this.setState({companyResponseCodes});
  }

  getMapReportSubscriptions(){
    this.setState({loadingMapReports : true});
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }

    fetch(process.env.API_URL + "/api/account/getMapReportSubscriptions",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer "+ auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      this.setState({loadingMapReports : false});
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.MapReportSubscriptions){
              this.setState({mapReports : json.MapReportSubscriptions});
            }
          }
        });
      }
    })
    .catch(error =>{
      this.setState({loadingMapReports : false});
      console.log(error);
    });
  }

  getProcessingRules(){
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }

    fetch(process.env.API_URL + "/api/account/getProcessingRules",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer "+ auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.ProcessingRules){
              this.setState({processingRules : json.ProcessingRules});
            }
          }
        });
      }
    })
    .catch(error =>{
      console.log(error);
    });
  }

  getDownloadSubscriptions(){
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }

    fetch(process.env.API_URL + "/api/account/getDownloadSubscriptions",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer "+ auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.DownloadSubscriptions){
              this.setState({downloadSubscriptions : json.DownloadSubscriptions});
            }
          }
        });
      }
    })
    .catch(error =>{
      console.log(error);
    });
  }

  getGroups(){
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }

    fetch(process.env.API_URL + "/api/account/getGroups",{
      method: "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.Groups){
              let companyGroups = [{
                GroupLabel : "All",
                CompanyGroupId : -1,
                DeletedDate : new Date()
              }];
              companyGroups = companyGroups.concat(json.Groups);
              this.setState({companyGroups});
            }
          }
        });
      }
    })
    .catch(error =>{
      console.log(error.message);
    });
  }

  selectProject(project){
    try{
      let selectedProjects = this.state.selectedProjects;
      if(selectedProjects.find(x => x.ProjectId == project.ProjectId)){
        if(project.ProjectId == -1){
          selectedProjects = [];
        }
        else{
          let index = selectedProjects.indexOf(x => x.ProjectId == project.ProjectId);
          if(index == -1){
            selectedProjects.forEach(selectedProject =>{
              index++;
              if(project.ProjectId == selectedProject.ProjectId){
                selectedProjects.splice(index,1);
              }
            });
          }
          else{
            selectedProjects.splice(index,1);
          }
          // Deselect "All" button if something else is deselected
          if(selectedProjects.find(x => x.ProjectId == -1)){
            index = selectedProjects.indexOf(x => x.ProjectId == -1);
            if(index == -1){
              selectedProjects.forEach(selectedProject =>{
                index++;
                if(selectedProject.ProjectId == -1){
                  selectedProjects.splice(index,1);
                }
              });
            }
            else{
              selectedProjects.splice(index,1);
            }
          }
        }
      }
      else{
        if(project.ProjectId == -1){
          this.state.companyProjects.forEach(companyProject =>{
            selectedProjects.push(companyProject);
          });
        }
        else{
          selectedProjects.push(project);
        }
      }
      this.setState({selectedProjects});
    }
    catch(e){
      console.log(e);
    }
  }

  selectGroup(group){
    try {
      let selectedGroups = this.state.selectedGroups.filter(function(x) { return x !== undefined; });
      if(selectedGroups.find(x => x != undefined && x.CompanyGroupId == group.CompanyGroupId)){
        if(group.CompanyGroupId == -1){
          selectedGroups = [];
        }
        else{
          let index = selectedGroups.indexOf(x => x.CompanyGroupId == group.CompanyGroupId);
          if(index == -1){
            selectedGroups.forEach(selectedGroup =>{
              index++;
              if(selectedGroup != undefined && group.CompanyGroupId == selectedGroup.CompanyGroupId){
                selectedGroups.splice(index,1);
              }
            });
          }
          else{
            selectedGroups.splice(index,1);
          }
          // Deselect "All" button if something else is deselected
          if(selectedGroups.find(x => x.CompanyGroupId == -1)){
            index = selectedGroups.indexOf(x => x.CompanyGroupId == -1);
            if(index == -   1){
              selectedGroups.forEach(selectedGroup =>{
                index++;
                if(selectedGroups != undefined && selectedGroup.CompanyGroupId == -1){
                  selectedGroups.splice(index,1);
                }
              });
            }
            else{
              selectedGroups.splice(index,1);
            }
          }
        }
      }
      else{
        if(group.CompanyGroupId == -1){
          this.state.companyGroups.forEach(companyGroup =>{
            selectedGroups.push(companyGroup);
          })
        }
        else{
          selectedGroups.push(group);
        }
      }
      this.setState({selectedGroups});
    }
    catch(e){
      console.log(e);
    }
  }

  saveCompanyKMLShapeLayers(){
    if(auth.isSuperViewer()){
      return;
    }
    this.setState({updatingCompanyKMLShapeLayers : "UPDATING KML/SHAPEFILE LAYERS..."});

    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      CompanyKMLSHAPELayers : this.state.companyKMLShapeLayers,
      UTCOffset : - new Date().getTimezoneOffset() /60,
    }
    let fd = new FormData();
    if(this.state.pendingUploadedFile){
      //JSON from shapefile
      if(this.state.pendingUploadedFile.layers){
        body.FeatureLayersFromShape = JSON.stringify(this.state.pendingUploadedFile);
      }
      //kml file
      else{
        fd.append('file', this.state.pendingUploadedFile);
      }
    }
    fd.append('DataObject', JSON.stringify(body));
    fetch(process.env.API_URL + "/api/account/updateCompanyKMLShapeLayers",{
      method : "POST",
      headers : {
        Authorization : "Bearer " + auth.token()
      },
      body : fd
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({pendingUploadedFile : null,companyKMLShapeLayers : json.CompanyKMLSHAPELayers, updateStatusText : "Updated KML/Shape file Map Layers.", updatingCompanyKMLShapeLayers : "", alertSeverity:"success"});
          }
          else{
            //Remove new layer from state, since they will have to reupload the file anyways.
            let companyKMLShapeLayers = this.state.companyKMLShapeLayers;
            let newLayerIndex = companyKMLShapeLayers.findIndex(x => x.CompanyKMLSHAPELayerId == -1);
            if(newLayerIndex != -1){
              companyKMLShapeLayers.splice(newLayerIndex,1);
            }
            this.setState({pendingUploadedFile : null,updateStatusText : json.ResponseMessageCollection[0].Message, updatingCompanyKMLShapeLayers : "", alertSeverity:"error",companyKMLShapeLayers});
          }
        });
      }
      else{
        //Remove new layer from state, since they will have to reupload the file anyways.
        let companyKMLShapeLayers = this.state.companyKMLShapeLayers;
        let newLayerIndex = companyKMLShapeLayers.findIndex(x => x.CompanyKMLSHAPELayerId == -1);
        if(newLayerIndex != -1){
          companyKMLShapeLayers.splice(newLayerIndex,1);
        }
        this.setState({companyKMLShapeLayers,pendingUploadedFile : null,updateStatusText : "There was a problem updating KML/Shape file Map Layers. Try again.", updatingCompanyKMLShapeLayers : "", alertSeverity:"error"});
      }
    })
    .catch(error =>{
      //Remove new layer from state, since they will have to reupload the file anyways.
      let companyKMLShapeLayers = this.state.companyKMLShapeLayers;
      let newLayerIndex = companyKMLShapeLayers.findIndex(x => x.CompanyKMLSHAPELayerId == -1);
      if(newLayerIndex != -1){
        companyKMLShapeLayers.splice(newLayerIndex,1);
      }
      this.setState({companyKMLShapeLayers,pendingUploadedFile : null,updateStatusText : "There was a problem updating KML/Shape file Map Layers. Try again. " + error.message, updatingCompanyKMLShapeLayers : "", alertSeverity:"error"});
    });
  }

  saveCompanyArcGisLayers(){
    if(auth.isSuperViewer()){
      return;
    }

    this.setState({loadingCompanyArcGisLayers : true});
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      CompanyArcGisLayers : this.state.CompanyArcGisLayers,
      UTCOffset : - new Date().getTimezoneOffset() /60
    }
    fetch(process.env.API_URL + "/api/account/updateCompanyArcGisLayers",{
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({CompanyArcGisLayers : json.CompanyArcGisLayers, updateStatusText : "Updated Map Layers.", loadingCompanyArcGisLayers : false, alertSeverity:"success"});
          }
          else{
            this.setState({updateStatusText : "There was a problem updating Map Layers. Try again.", loadingCompanyArcGisLayers : false, alertSeverity:"error"});
          }
        });
      }
    })
    .catch(error =>{
      this.setState({updateStatusText : "There was a problem updating Map Layers. Try again." + error.message, loadingCompanyArcGisLayers : false, alertSeverity:"error"});
    });
  }

  updateNotifications(){
    if(auth.isSuperViewer()){
      return;
    }
    // Check to see if the user is super user and changed their company.
    // let companyId = auth.companyId();
    // let realCompany = localStorage.getItem("actualCompanyId");
    // if(companyId != realCompany){
    //   return;
    // }

    let update = true;
    let validEmail = true;
    let triggers = this.state.Triggers;
    for(let i = 0; i< triggers.length;i++){
      if(triggers[i].Emails.length == 0 && triggers[i].DeletedBy == null){
        update = false;
      }
      else{
        let addresses = triggers[i].Emails;
        addresses = addresses.replaceAll(';',',');
        let emails = addresses.split(',');
        emails.forEach(email =>{
          let validFormat = /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,4})+$/;
          if(!validFormat.test(email)){
            update = false;
            validEmail = false;
          }
        });
        addresses = addresses.replaceAll(',',';');
        triggers[i].Emails = addresses;
      }
    }
    if(update){
      this.setState({loadingNotifications : true});
      let body = {
        UserId: auth.employeeId(),
        CompanyId : auth.companyId(),
        Triggers : triggers,
        UTCOffset : - (new Date().getTimezoneOffset()/60)
      };

      fetch(process.env.API_URL + "/api/account/updateNotifications",{
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin" : "*",
          "Content-Type" : "application/json; charset=utf-8",
          Accept : "application/json",
          Authorization : "Bearer " + auth.token()
        },
        body: JSON.stringify(body)
      })
      .then(r =>{
        if(r.status == 200){
          r.json().then(responseJson =>{
            let json = JSON.parse(responseJson);
            if(json.ResponseType == 0){
              this.setState({Triggers : json.Triggers, updateStatusText : "Updated notifications.", loadingNotifications : false, alertSeverity:"success"});
            }
            else{
              this.setState({updateStatusText : "There was a problem updating notifications. Try again.", loadingNotifications : false, alertSeverity:"error"});
            }
          });
        }
      })
      .catch(error =>{
        this.setState({updateStatusText : "There was a problem updating notifications. Try again." + error.message, loadingNotifications : false, alertSeverity:"error"});
      });
    }
    else{
      if(!validEmail){
        this.setState({updateStatusText: "One or more Email Addresses are invalid.", alertSeverity:"warning"});
      }
      else{
        this.setState({updateStatusText: "Enter in an email address.", alertSeverity:"warning"});
      }
    }
  }

  updateCompanyResponseCodes(){
    if(auth.isSuperViewer()){
      return;
    }
    // let companyId = auth.companyId();
    // let realCompany = localStorage.getItem("actualCompanyId");
    // if(companyId != realCompany){
    //   return;
    //}
    let duplicateMemberResponse = false;
    let SaveCodes = [];
    this.state.companyResponseCodes.forEach(responseCode =>{
      responseCode.MemberCode = responseCode.MemberCode.trim();
      SaveCodes.push(responseCode);
      let codes = this.state.companyResponseCodes.filter(x => x.MemberCode == responseCode.MemberCode && x.ResponseName == responseCode.ResponseName && x.CallCenterId == responseCode.CallCenterId);
      if(codes && codes.length > 0){
        duplicateMemberResponse = codes.length > 1;  
      }
    });
    if(duplicateMemberResponse && this.state.selectedCallCenter.CallCenterId == 1){
      this.setState({updateStatusText :"Cannot have duplicate response name for one member code.", alertSeverity : 'warning'})
      return;
    }
    this.setState({loadingCompanyResponseCodes : true});

    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      //No need to add codes that were added just to be removed. 
      CompanyResponseCodes : SaveCodes.filter(x => x.DeletedDate == null || x.CompanyResponseCodeId != -1),
      UTCOffset : - (new Date().getTimezoneOffset()/60)
    };
    fetch(process.env.API_URL + "/api/account/updateCompanyResponseCodes",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({companyResponseCodes : json.CompanyResponseCodes, updateStatusText : "Updated Company Response Codes", loadingCompanyResponseCodes : false, alertSeverity : "success" });
          }
          else if(json.ResponseType == 1){
            this.setState({updateStatusText :"One or more Member Codes are already in use by another user in your same Call Center.", loadingCompanyResponseCodes : false, alertSeverity : 'warning'});
          }
          else{
            this.setState({updateStatusText :"There was a problem updating Response Codes. Try again.", loadingCompanyResponseCodes : false, alertSeverity : 'error'});
          }
        });
      }
      else{
        this.setState({updateStatusText :"There was a problem updating Response Codes. Try again.", loadingCompanyResponseCodes : false, alertSeverity : 'error'});
      }
    })
    .catch(e =>{
      this.setState({updateStatusText :"There was a problem updating Response Codes. Try again. " + e.message, loadingCompanyResponseCodes : false, alertSeverity : 'error'});
    })
  }

  updateMapReports(){
    if(auth.isSuperViewer()){
      return;
    }
    // let companyId = auth.companyId();
    // let realCompany = localStorage.getItem("actualCompanyId");
    // if(companyId != realCompany){
    //   return;
    // }
    let validEmail = true;
    let mapReports = this.state.mapReports;
    mapReports.forEach(report => {
      if(!report.DeletedDate){
        let emails = report.Emails.split(',');
        emails.forEach(email =>{
          let validFormat = /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,4})+$/;
          if(!validFormat.test(email)){
            validEmail = false;
          }
        });
      }
    });
    if(!validEmail){
      this.setState({updateStatusText: "One or more Email Addresses are invalid.", alertSeverity:"warning"});
      return;
    }
    this.setState({loadingMapReports : true});
    mapReports.forEach(report =>{
      let IncludedFormIds = "";
      let IncludedCompanyArcGisLayerUrls = "";
      if(report.IncludedFormIdList && report.IncludedFormIdList.length > 0){
         report.IncludedFormIdList.forEach(id =>{
          IncludedFormIds += (id + ",");
         });
         IncludedFormIds = IncludedFormIds.slice(0,-1);
      }
      if(report.IncludedCompanyArcGisLayerUrlList && report.IncludedCompanyArcGisLayerUrlList.length > 0){
        report.IncludedCompanyArcGisLayerUrlList.forEach(id =>{
         IncludedCompanyArcGisLayerUrls += (id + ",");
        });
        IncludedCompanyArcGisLayerUrls = IncludedCompanyArcGisLayerUrls.slice(0,-1);
     }
     report.IncludedFormIds = IncludedFormIds;
     report.IncludedCompanyArcGisLayerUrls = IncludedCompanyArcGisLayerUrls;
    });
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      MapReportSubscriptions : mapReports,
      UTCOffset : - (new Date().getTimezoneOffset()/60)
    };
    fetch(process.env.API_URL + "/api/account/updateMapReportSubscriptions",{
      method : "POST",
      headers: {
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({mapReports : json.MapReportSubscriptions, updateStatusText : "Updated Map Reports.", loadingMapReports : false, alertSeverity : 'success'});
          }
          else{
            this.setState({updateStatusText : "There was a problem updated Map Reports. Try again.", loadingMapReports : false, alertSeverity : 'error'});
          }
        });
      }
      else{
        this.setState({updateStatusText : "There was a problem updated Map Reports. Try again.", loadingMapReports : false, alertSeverity : 'error'});
      }
    })
    .catch(e =>{
      this.setState({updateStatusText : "There was a problem updated Map Reports. Try again. " + e.message, loadingMapReports : false, alertSeverity : 'error'});
    });
  }

  updateProcessingRules(){
    if(auth.isSuperViewer()){
      return;
    }
    //check to see if the user is super user and changed their company;
    // let companyId = auth.companyId();
    // let realCompany = localStorage.getItem("actualCompanyId");
    // if(companyId != realCompany){
    //   return;
    // }
    let validEmail = true;
    let processingRules = this.state.processingRules;
    processingRules.forEach(rule =>{
      if(!rule.DeletedDate){
        rule.ActionsObject.forEach(action =>{
          if( action.ProcessAction == "forward"){
            let emails = action.ActionFieldValue.split(',');
            emails.forEach(email =>{
              let validFormat = /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,4})+$/;
              if(!validFormat.test(email)){
                validEmail = false;
              }
            });
          }
        });
      }
      rule.Triggers = JSON.stringify(rule.TriggersObject);
      rule.Actions = JSON.stringify(rule.ActionsObject);
    });
    if(!validEmail){
      this.setState({updateStatusText: "One or more Email Addresses are invalid.", alertSeverity:"warning"});
      return;
    }
    this.setState({loadingProcessingRules : true});
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      ProcessingRules : processingRules,
      UTCOffset : - (new Date().getTimezoneOffset()/60)
    }
    fetch(process.env.API_URL + "/api/account/updateProcessingRules",{
      method : "POST",
      headers : {
        "Content-Type": "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()  
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({processingRules : json.ProcessingRules, updateStatusText : "Updated Processing Rules", loadingProcessingRules : false, alertSeverity : "success"});
          }
          else{
            this.setState({updateStatusText : "There was a problem updating Processing Rules. Try again.", loadingProcessingRules : false, alertSeverity : "error"});
          }
        });
      }
      else{
        this.setState({updateStatusText : "There was a problem updating Processing Rules. Try again.", loadingProcessingRules : false, alertSeverity : "error"});
      }
    })
    .catch(error =>{
      this.setState({updateStatusText : "There was a problem updating Processing Rules. Try again. " + error.message, loadingProcessingRules : false, alertSeverity : "error"});
    })
  }

  getAlertPDFS(){
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }

    fetch(process.env.API_URL + "/api/companyalertpdfs",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer "+ auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.AlertPdfs){
              this.setState({alertPdfs : json.AlertPdfs});
            }
          }
        });
      }
    })
    .catch(error =>{
      console.log(error);
    });
  }

  uploadAlertPDF(index){
    this.setState({uploadingPDFAlert : true});
   let files = document.getElementById("uploadAlertPDF"+index).files;
   let doc = files[0];
   let date = new Date();
   let body = {
    MultimediaAsset : {
      //Alert pdfs dont have entity or entity types and it is a non nullable int where 0 is site survey so have to send up -1
      EntityId : -1,
      EntityTypeId : -1,
      Companyid : auth.companyId(),
      ModifiedBy : auth.employeeId(),
      CreatedBy : auth.employeeId(),
      CreatedDate : date,
      ModifiedDate : date,
      UTCOffset : date.getTimezoneOffset(),
      ModifiedDateUTCOffset : -date.getTimezoneOffset() / 60,
      CreatedDateUTCOffset : -date.getTimezoneOffset() / 60,
      Timestamp : date.getTime(),
      Latitude : 0,
      Heading : 0,
      Label : doc.name,
      Longitude : 0,
      MediaTypeId : 4
    },
    Latitude : 0,
    Longitude : 0,
    Heading : 0,
    CreatedDate : date,
    UTCOffset : date.getTimezoneOffset(),
    Label : doc.name,
    FileType : doc.type
  }
  let fd = new FormData();
  fd.append('Document', doc);
  fd.append('DataObject', JSON.stringify(body));
  //This has to be an XMLHttpRequest to work.
  //If a fetch is used the data will not show up on the server.
  let req = new XMLHttpRequest();
  req.open("POST", process.env.API_URL + "/api/alertpdf",true);
  req.setRequestHeader("Authorization" , "Bearer " + auth.token());
  req.onload = () =>{
    if(req.status == 200 && req.response){
      let alertPdfs = this.state.alertPdfs;
      let response = JSON.parse(req.response);
      if(response.multimediaAsset){
        alertPdfs.push(JSON.parse(response.multimediaAsset));
        this.setState({alertPdfs,uploadingPDFAlert : false});
      }
    }
    else{
      this.setState({updateStatusText : "There was a problem uploading your alert pdf", alertSeverity : 'error',uploadingPDFAlert : false});
    }
  }
  req.onerror = () =>{
    this.setState({updateStatusText : "There was a problem uploading your alert pdf", alertSeverity : 'error',uploadingPDFAlert : false});
  }
  req.send(fd);
  }

  updateDownloadSubcriptions(){
    if(auth.isSuperViewer()){
      return;
    }
    //check to see if the user is super user and changed their company;
    // let companyId = auth.companyId();
    // let realCompany = localStorage.getItem("actualCompanyId");
    // if(companyId != realCompany){
    //   return;
    // }
    let validEmail = true;
    let validPhone = true;
    let downloadSubscriptions = this.state.downloadSubscriptions;
    downloadSubscriptions.forEach(sub =>{
      if(sub.Triggers){
        sub.TriggerRules = JSON.stringify(sub.Triggers);
      }
      if(!sub.DeletedDate && sub.NotificationType == "email"){
        let addresses = sub.Emails;
        addresses = addresses.replaceAll(';',',');
        let emails = addresses.split(',');
        emails.forEach(email =>{
          let validFormat = /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,4})+$/;
          if(!validFormat.test(email)){
            validEmail = false;
          }
        });
        addresses = addresses.replaceAll(',',';');
        sub.Emails = addresses;
      }
      else if(!sub.DeletedDate && sub.NotificationType == "phone"){
        var match = sub.Phone.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im);
        if(!match){
          validPhone = false;
        }
      }
      
    });
    if(!validEmail){
      this.setState({updateStatusText: "One or more Email Addresses are invalid.", alertSeverity:"warning"});
      return;
    }
    if(!validPhone){
      this.setState({updateStatusText: "One or more Phone Numbers are invalid.", alertSeverity:"warning"});
      return;
    }
    this.setState({loadingDownloadSubscriptions : true});
    //Need to turn FormIdList into comma seperated string for FormList since it is a varchar in database.
    downloadSubscriptions.forEach(sub =>{
      let formList = "";
      if(sub.FormIdList && sub.FormIdList.length > 0){
        sub.FormIdList.forEach(id =>{
          formList += (id + ",");
        });
        //Remove last ,
        formList = formList.slice(0,-1);
      }
      sub.FormList = formList;
    });
    let body = {
      UserId: auth.employeeId(),
      CompanyId : auth.companyId(),
      DownloadSubscriptions : this.state.downloadSubscriptions,
      UTCOffset : - (new Date().getTimezoneOffset()/60)
    };
    fetch(process.env.API_URL + "/api/account/updateDownloadSubscriptions",{
      method: "POST",
      headers: {
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({downloadSubscriptions : json.DownloadSubscriptions, updateStatusText : "Updated Download Subscriptions.", loadingDownloadSubscriptions : false, alertSeverity : "success"});
          }
          else{
            this.setState({updateStatusText : "There was a problem updating Download Subscriptions. Try again.", loadingDownloadSubscriptions : false, alertSeverity : 'error'});
          }
        });
      }
      else{
        this.setState({updateStatusText : "There was a problem updating Download Subscriptions. Try again.", loadingDownloadSubscriptions : false, alertSeverity : 'error'});
      }
    })
    .catch(error =>{
      this.setState({updateStatusText : "There was a problem updating Download Subscriptions. Try again. " + error.message, loadingDownloadSubscriptions : false, alertSeverity : 'error'});
    });

  }

  updateGroups(){
    // let companyId = auth.companyId();
    // let realCompany = localStorage.getItem("actualCompanyId");
    // if(companyId != realCompany){
    //   return;
    // }
    let update = true;
    for(let i=0; i<this.state.companyGroups.length; i++){
      if(this.state.companyGroups[i].GroupLabel.length == 0 && this.state.companyGroups[i].DeletedBy == null){
        update = false;
      }
    }
    if(update){
      this.setState({loadingGroups : true});
      let spliceIndex = 0;
      let Groups = JSON.parse(JSON.stringify(this.state.companyGroups));
      if(Groups.find(x => x.GroupLabel == "All")){
        spliceIndex = Groups.indexOf(x =>x.GroupLabel == "All");
        if(spliceIndex == -1){
          for(let i = 0; i<Groups.length; i++){
            if(Groups[i].GroupLabel == "All"){
              spliceIndex = i;
            }
          }
          Groups.splice(spliceIndex,1);
        }
        else{
          Groups.splice(spliceIndex, 1)
        }
      }
      let body = {
        UserId: auth.employeeId(),
        CompanyId : auth.companyId(),
        Groups,
        UTCOffset : - (new Date().getTimezoneOffset()/60)
      };
      fetch(process.env.API_URL + "/api/account/updateGroups",{
        method: "POST",
        headers:{
          "Content-Type" : "application/json; charset=utf-8",
          Accept : "application/json",
          Authorization : "Bearer " + auth.token()
        },
        body : JSON.stringify(body)
      })
      .then(r =>{
        if(r.status == 200){
          r.json().then(responseJson =>{
            let json = JSON.parse(responseJson);
            if(json.ResponseType == 0){
              let companyGroups = [{
                GroupLabel : "All",
                CompanyGroupId : -1,
                DeletedDate : new Date()
              }];
              companyGroups = companyGroups.concat(json.Groups);
              this.setState({companyGroups, updateStatusText : 'Updated groups', loadingGroups : false, alertSeverity: "success"});
            }
            else{
              this.setState({updateStatusText : "There was a problem updating groups. Try again.", loadingGroups : false, alertSeverity : "error"});
            }
          });
        }
      })
      .catch(error =>{
        this.setState({updateStatusText : "There was a problem updating groups. Try again." + error.message, loadingGroups : false, alertSeverity:"error"});
      })
    }
    else{
      this.setState({updateStatusText: "Enter in a name for the group.", alertSeverity:"warning"});
    }
  }

  getForms(){
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }

    this.setState({loadingForms:true});

    fetch(process.env.API_URL +"/api/account/form",{
      method: "POST",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let surveyForms = [];
          let investigationForms = [];
          let gisForms = [];
          let gpsForms = [];
          let tagForms = [];
          let json = JSON.parse(responseJson);
          if (json.ResponseType == 0){
            if (json.FormCollection) {
              json.FormCollection.forEach(form =>{
                if(form.Type == "GPS Survey"){
                  gpsForms.push(form);
                }
                else if(form.Type == "Pipeline Survey"){
                  gisForms.push(form);
                }
                else if(form.Type == "Survey"){
                  surveyForms.push(form);
                }
                else if(form.Type == "Investigation"){
                  investigationForms.push(form);
                }
                else if(form.Type == "Tag"){
                  tagForms.push(form);
                }
                form.Fields.forEach(field =>{
                  if(!field.Type){
                    field.Type = "Text";
                  }
                });
              });
              surveyForms.forEach((survey,index) => {
                if(!survey.FormOrder && survey.FormOrder != 0){
                  survey.FormOrder = index;
                }
              });
              surveyForms.sort((a,b) => (a.FormOrder > b.FormOrder) ? 1 : -1);
              investigationForms.forEach((survey,index) => {
                if(!survey.FormOrder && survey.FormOrder != 0){
                  survey.FormOrder = index;
                }
              });
              investigationForms.sort((a,b) => (a.FormOrder > b.FormOrder) ? 1 : -1);
              gpsForms.forEach((survey,index) => {
                if(!survey.FormOrder && survey.FormOrder != 0){
                  survey.FormOrder = index;
                }
              });
              gpsForms.sort((a,b) => (a.FormOrder > b.FormOrder) ? 1 : -1);
              gisForms.forEach((survey,index) => {
                if(!survey.FormOrder && survey.FormOrder != 0){
                  survey.FormOrder = index;
                }
              });
              gisForms.sort((a,b) => (a.FormOrder > b.FormOrder) ? 1 : -1);
              tagForms.forEach((survey,index) => {
                if(!survey.FormOrder && survey.FormOrder != 0){
                  survey.FormOrder = index;
                }
              });
              tagForms.sort((a,b) => (a.FormOrder > b.FormOrder) ? 1 : -1);
              let ticketAssignmentAreas = [];
              if(json.AssignmentAreaCollection){
                json.AssignmentAreaCollection.forEach(area => {
                  if(area.GroupId){
                    if(!ticketAssignmentAreas.find(x => x.GroupId == area.GroupId)){
                      ticketAssignmentAreas.push(area);
                    }
                  }
                  else{
                    ticketAssignmentAreas.push(area);
                  }
                });
              }

              // combine assignment area and arcgis layers of type "ticketarea"
              var assignmentAreas = ticketAssignmentAreas;
              assignmentAreas = assignmentAreas.concat(this.state.CompanyArcGisLayerFeatures);

              this.setState({surveyForms, ticketAssignmentAreas, assignmentAreas, investigationForms, gisForms, gpsForms, tagForms,
                FormCollection: json.FormCollection, loadingForms: false, formGroups: json.FormGroupCollection, formProjects : json.FormProjectCollection});

              console.log('CALF', this.state.CompanyArcGisLayerFeatures);
              console.log('assignmentAreasFULL', assignmentAreas);
              
              this.getFormToGisLayerMappings();
            }
          }
        });
      }
    })
    .catch(error =>{
      console.log(error.message);
      this.setState({loadingForms:false});
    })
  }

  saveTriggers(save, edit, formIndex){
    this.setState({currentFormIndex: formIndex});
    this.saveCustomForm(save, edit, formIndex);
  }

  saveFormsOrder(formType){
    let surveyList = [];
    if(formType == "survey"){
      surveyList = this.state.surveyForms;
      this.setState({loadingSurveyFormOrder : true});
    }
    else if(formType == "investigation"){
      surveyList = this.state.investigationForms;
      this.setState({loadingInvestigationFormOrder : true});
    }
    else if(formType == "gps"){
      surveyList = this.state.gpsForms;
      this.setState({loadingGPSFormOrder : true});
    }
    else if(formType == "gis"){
      surveyList = this.state.gisForms;
      this.setState({loadingGISFormOrder : true});
    }
    else if(formType == "tag"){
      surveyList = this.state.tagForms;
      this.setState({loadingTagFormOrder : true});
    }
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      Forms : surveyList
    }
    fetch(process.env.API_URL +"/api/account/updateFormOrder",{
      method: "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(formType == "survey"){
            this.setState({surveyOrderChanged : false, loadingSurveyFormOrder : false, updateStatusText : 'Updated Survey Forms Order.', alertSeverity: "success"});
          }
          else if(formType == "investigation"){
            this.setState({investigationOrderChanged : false, loadingInvestigationFormOrder : false, updateStatusText : 'Updated Investigation Forms Order.', alertSeverity: "success"});
          }
          else if(formType == "gps"){
            this.setState({gpsOrderChanged : false, loadingGPSFormOrder : false, updateStatusText : 'Updated GPS Forms Order.', alertSeverity: "success"});
          }
          else if(formType == "gis"){
            this.setState({gisOrderChanged : false, loadingGISFormOrder : false, updateStatusText : 'Updated GIS Forms Order.', alertSeverity: "success"});
          }
          else if(formType == "tag"){
            this.setState({tagOrderChanged : false, loadingTagFormOrder : false, updateStatusText : 'Updated Tag Forms Order.', alertSeverity: "success"});
          }
        });
      }
      else{
        if(formType == "survey"){
          this.setState({loadingSurveyFormOrder : false,updateStatusText : "There was a problem updating Survey Forms Order. Try again.", alertSeverity:"error"});
        }
        else if(formType == "investigation"){
          this.setState({investigationOrderChanged : false,updateStatusText : "There was a problem updating Investigation Forms Order. Try again.", alertSeverity:"error"});
        }
        else if(formType == "gps"){
          this.setState({loadingGPSFormOrder : false, updateStatusText : "There was a problem updating GPS Forms Order. Try again.", alertSeverity: "error"});
        }
        else if(formType == "gis"){
          this.setState({loadingGISFormOrder : false, updateStatusText : "There was a problem updating GIS Forms Order. Try again.", alertSeverity: "error"});
        }
        else if(formType == "tag"){
          this.setState({loadingTagFormOrder : false, updateStatusText : "There was a problem updating Tag Forms Order. Try again.", alertSeverity: "error"});
        }
      }
    })
    .catch(error =>{
      console.log(error.message);
      if(formType == "survey"){
        this.setState({loadingSurveyFormOrder : false,updateStatusText : "There was a problem updating Survey Forms Order. Try again." + error.message, alertSeverity:"error"});
      }
      else if(formType == "investigation"){
        this.setState({investigationOrderChanged : false,updateStatusText : "There was a problem updating Investigation Forms Order. Try again." + error.message, alertSeverity:"error"});
      }
      else if(formType == "gps"){
        this.setState({loadingGPSFormOrder : false, updateStatusText : "There was a problem updating GPS Forms Order. Try again. " + error.message, alertSeverity: "error"});
      }
      else if(formType == "gis"){
        this.setState({loadingGISFormOrder : false, updateStatusText : "There was a problem updating GIS Forms Order. Try again. " + error.message, alertSeverity: "error"});
      }
      else if(formType == "tag"){
        this.setState({loadingTagFormOrder : false, updateStatusText : "There was a problem updating Tag Forms Order. Try again. " + error.message, alertSeverity: "error"});
      }
    });
  }

  saveCustomForm(save, edit, formIndex, formType){
    if (save == false)
    {
      // Cancel Edits: Revert form to old state
      let currentFormEdit = this.state.FormCollection[this.state.currentFormIndex];
      this.setState({currentFormEdit});
    }
    else
    {
      
      // Saved Edits: Updated existing form state and save
      let stateFormEdit = null;
      if (edit == true) stateFormEdit = this.state.currentFormEdit;
      else if(formType == "survey"){
        stateFormEdit = this.state.surveyForms[formIndex];
      }
      else if(formType == "investigation"){
        stateFormEdit = this.state.investigationForms[formIndex];
      }
      else if(formType == "gps"){
        stateFormEdit = this.state.gpsForms[formIndex];
      }
      else if(formType == "gis"){
        stateFormEdit = this.state.gisForms[formIndex];
      }
      else if(formType == "tag"){
        stateFormEdit = this.state.tagForms[formIndex];
      }
      else stateFormEdit = this.state.FormCollection[formIndex];

      //Check to see if name has non alphanumeric characters. 
      let reg = /^[\w\-\s]+$/i;
      if(!reg.test(stateFormEdit.Name)){
        this.setState({updateStatusText : 'Cannot have non-alphanumeric characters in the form name.', alertSeverity : 'error'});
        return;
      }
      let emptyCustoms = false;
      stateFormEdit.Fields.forEach(field =>{
        if((field.Type == "Drop Down" || field.Type == "MultiSelect") && (!field.CustomValues || field.CustomValues.length == 0 || (field.CustomValues.length == 1 && field.CustomValues[0] == ""))){
          this.setState({updateStatusText : "Cannot save " + field.Type + " without custom values.", alertSeverity : 'error'});
          emptyCustoms = true;
        }
      });
      if(emptyCustoms) return;
      stateFormEdit.FieldsSchema = JSON.stringify(stateFormEdit.Fields);
      stateFormEdit.TriggerCollection = JSON.stringify(stateFormEdit.FormTriggerCollection);

      if (stateFormEdit.Type == "Survey") stateFormEdit.FormTypeId = 0;
      if (stateFormEdit.Type == "Investigation") stateFormEdit.FormTypeId = 1;
      if (stateFormEdit.Type == "Pipeline Survey") stateFormEdit.FormTypeId = 2;
      if (stateFormEdit.Type == "GPS Survey") stateFormEdit.FormTypeId = 3;
      if (stateFormEdit.Type == "Ticket Survey") stateFormEdit.FormTypeId = 4;
      if (stateFormEdit.Type == "Tag") stateFormEdit.FormTypeId = 5;

      let updatedFormGroups = [];
      let selectedGroups = this.state.selectedGroups;
      let formGroups = this.state.formGroups;
      console.log(selectedGroups, formGroups);
      selectedGroups.forEach(group => {
        // add new forms to group but check to make sure they aren't already in the group.
        if (group != undefined && group.CompanyGroupId != -1 && !formGroups.find(x => x.FormId == stateFormEdit.FormId && x.CompanyGroupId == group.CompanyGroupId)){
          updatedFormGroups.push({FormId : stateFormEdit.FormId, CompanyGroupId : group.CompanyGroupId, Add : true});
        }
      });
      
      if(stateFormEdit.FormId != 0){
        formGroups.forEach(formGroup =>{
          // add removed formgroups so that they will be deleted if it isn't a new form
          if(!selectedGroups.find(x => x && x.CompanyGroupId == formGroup.CompanyGroupId)){
            updatedFormGroups.push({FormId : stateFormEdit.FormId, CompanyGroupId : formGroup.CompanyGroupId, Add : false});
          }
        });
      }

      let updatedFormProjects = [];
      let selectedProjects = this.state.selectedProjects;
      let formProjects = this.state.formProjects;
      selectedProjects.forEach(project =>{
        // add new forms to project but check to make sure they aren't already in the project
        if(project.ProjectId != -1 && !formProjects.find(x => x.FormId == stateFormEdit.FormId && x.ProjectId == project.ProjectId)){
          updatedFormProjects.push({FormId : stateFormEdit.FormId, ProjectId : project.ProjectId, Add : true});
        }
      });

      if(stateFormEdit.FormId != 0){
        formProjects.forEach(formProject => {
          //add removed fromProjects so taht they will be deleted if it isn't a new form
          if(!selectedProjects.find(x => x.ProjectId == formProject.ProjectId)){
            updatedFormProjects.push({FormId : stateFormEdit.FormId, ProjectId : formProject.ProjectId, Add : false});
          }
        });
      }
    
      let body = {
        UserId : auth.employeeId(),
        CompanyId : auth.companyId(),
        EditForm : stateFormEdit,
        FormGroups : updatedFormGroups,
        FormProjects : updatedFormProjects
      }
      this.setState({loadingForms:true});
      fetch(process.env.API_URL +"/api/account/form",{
        method: "POST",
        headers:{
          "Access-Control-Allow-Origin" : "*",
          "Content-Type" : "application/json; charset=utf-8",
          Accept : "application/json",
          Authorization : "Bearer " + auth.token()
        },
        body : JSON.stringify(body)
      })
      .then(r =>{
        if(r.status == 200){
          r.json().then(responseJson =>{
            let json = JSON.parse(responseJson);
            if (json.ResponseType == 0){
              if (json.FormCollection) {
                let surveyForms = [];
                let investigationForms = [];
                let gpsForms = [];
                let gisForms = [];
                let tagForms = [];
                json.FormCollection.forEach(form =>{
                  form.Fields.forEach(field =>{
                    if(!field.Type){
                      field.Type = "Text";
                    }
                  });
                  if(form.Type == "GPS Survey"){
                    gpsForms.push(form);
                  }
                  else if(form.Type == "Pipeline Survey"){
                    gisForms.push(form);
                  }
                  else if(form.Type == "Survey"){
                    surveyForms.push(form);
                  }
                  else if(form.Type == "Investigation"){
                    investigationForms.push(form);
                  }
                  else if(form.Type == "Tag"){
                    tagForms.push(form);
                  }
                  form.Fields.forEach(field =>{
                    if(!field.Type){
                      field.Type = "Text";
                    }
                  });
                });
                surveyForms.forEach((survey,index) => {
                  if(!survey.FormOrder && survey.FormOrder != 0){
                    survey.FormOrder = index;
                  }
                });
                surveyForms.sort((a,b) => (a.FormOrder > b.FormOrder) ? 1 : -1);
                investigationForms.forEach((survey,index) => {
                  if(!survey.FormOrder && survey.FormOrder != 0){
                    survey.FormOrder = index;
                  }
                });
                investigationForms.sort((a,b) => (a.FormOrder > b.FormOrder) ? 1 : -1);
                gpsForms.forEach((survey,index) => {
                  if(!survey.FormOrder && survey.FormOrder != 0){
                    survey.FormOrder = index;
                  }
                });
                gpsForms.sort((a,b) => (a.FormOrder > b.FormOrder) ? 1 : -1);
                gisForms.forEach((survey,index) => {
                  if(!survey.FormOrder && survey.FormOrder != 0){
                    survey.FormOrder = index;
                  }
                });
                gisForms.sort((a,b) => (a.FormOrder > b.FormOrder) ? 1 : -1);
                tagForms.forEach((survey,index) => {
                  if(!survey.FormOrder && survey.FormOrder != 0){
                    survey.FormOrder = index;
                  }
                });
                tagForms.sort((a,b) => (a.FormOrder > b.FormOrder) ? 1 : -1);
                this.setState({FormCollection : json.FormCollection,surveyForms,investigationForms,gpsForms,gisForms,tagForms,formGroups : json.FormGroupCollection, formProjects : json.FormProjectCollection});
              }
            }
          });
        }
        this.setState({loadingForms:false})
      })
      .catch(error =>{
        console.log(error.message);
        this.setState({loadingForms:false});
      })
      
    }
    this.setState({editCustomForm:false});
  }

  importCustomValues(index){
    let currentFormEdit = this.state.currentFormEdit; 
    let customValues = currentFormEdit.Fields[index].CustomValues;
    let maxValues = false;
    let files = document.getElementById("uploadCustomValuesFor" + index)["files"];
    if(files.length > 0){
      let reader = new FileReader();
      reader.onloadend = (event) =>{
        let vals = event.target.result.split(/\n/);
        vals.forEach(value =>{ 
          if(customValues.length < 50){
            customValues.push(value.replace("\r",""));
          }
          else{
            maxValues = true;
          }
        });
        currentFormEdit.Fields[index].CustomValues = customValues;
        this.setState({currentFormEdit});
        if(maxValues){
          this.setState({updateStatusText : "You can only import up to 50 custom values.", alertSeverity : "warning"});
        }
      }
      reader.readAsText(files[0]);
    }
  }

  importFormDataFromCSV(){
    let form = this.state.customFormForImport;
    let now = new Date();
    let files = document.getElementById("uploadFormDataCSV"+form.FormId)["files"];
    let formDataList = [];
    if(files.length > 0){
      let reader = new FileReader();
      reader.onloadend = (event) =>{
        this.setState({uploadingCSVFormData : true});
        try{
          //Regex here and below is to account for commas and new line characters being in field values. 
          let dataSet = event.target.result.split(/\n(?=(?:(?:[^"]*"){2})*[^"]*$)/);
          if(dataSet.length > this.state.maxCSVUploadAmount + 1){
            this.setState({updateStatusText : 'The CSV data import is limited to ' + this.state.maxCSVUploadAmount + " records.", alertSeverity : 'error',uploadingCSVFormData : false});
            return;
          }
          let fieldNames = dataSet[0].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
          let latitude = 0;
          let longitude = 0;
          for(let i = 1; i < dataSet.length; i++){
            if(!dataSet[i] || dataSet[i] == ""){
              continue;
            }
            let record = dataSet[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
            let formFields = [];
            record.forEach((value,index) =>{
              let fieldName = fieldNames[index];
              //Need to remove \r
              fieldName = fieldName.replace('\r','');
              value = value.replace('\r','');
              let field = form.Fields.find(x => x.OriginalName == fieldName);
              if(field && field.Type !="Drop Down" && field.Type != "Multiple Select"){
                field.CustomValues = null;
              }
              if(fieldName == "Project"){
                let formProjects = this.state.formProjects.filter(x => x.FormId == form.FormId && x.ProjectLabel != null);
                let CustomValues = [null];
                formProjects.forEach(project =>{
                  CustomValues.push(project.ProjectLabel);
                });
                formFields.push({Name:"Project",Type:"Drop Down",CustomValues, Value : value});
              }
              if(field && fieldName.toLowerCase() != "lat" && fieldName.toLowerCase() != "lon" && fieldName.toLowerCase() != "latitude" && fieldName.toLowerCase() != "longitude"){
                field.Value = value;
                if(field && field.Type == "Date"){
                  let date = new Date(value);
                  if(date != 'Invalid Date' && value != null){
                    field.Label = moment(date).format('MMM DD, YYYY');
                  }
                  else{
                    field.Label = value; 
                  }
                }
                if(field && field.Type == "Time"){
                  let date = new Date(value);
                  if(date != 'Invalid Date' && value != null){
                    field.Label = date.toLocaleTimeString('en-US', {hour12: true, hour : "numeric", minute : "numeric"});
                  }
                  else{
                    field.Label = value;
                  }
                }
                formFields.push(field);
              }
              if((fieldName.toLowerCase() == "lat" || fieldName.toLowerCase() == "latitude") && !isNaN(parseFloat(value))){
                latitude = parseFloat(value);
              }
              else if((fieldName.toLowerCase() == "lon" || fieldName.toLowerCase() == "longitude") && !isNaN(parseFloat(value))){
                longitude = parseFloat(value);
              }
            });
            //Need to add these fields to leak survey types for tasks to work. 
            if(form.FormTypeId == 2){
              if(!formFields.find(x => x.Name == "AssignedUserId" && (x.Type == "Text" || !x.Type))){
                formFields.push({Name:"AssignedUserId",Type:"Text",Required:false,RequiredPhoto:false,RequiredPhotoMultimediaAssetId:null,CustomValues:null,Value:null,Label:"AssignedUserId",RepeatGroup:false,DisplayField:false,ReadOnly:false,Hide:false,OriginalName:"AssignedUserId"});
              }
              if(!formFields.find(x => x.Name == "TaskCounter" && x.Type == "Number")){
                formFields.push({Name:"TaskCounter",Type:"Number",Required:false,RequiredPhoto:false,RequiredPhotoMultimediaAssetId:null,CustomValues:null,Value:null,Label:"Number",RepeatGroup:false,DisplayField:false,ReadOnly:false,Hide:false,OriginalName:"TaskCounter"});
              }
              if(!formFields.find(x => x.Name == "Status" && x.Type == "Drop Down")){
                formFields.push({Name:"Status",Type:"Drop Down",Required:true,RequiredPhoto:false,RequiredPhotoMultimediaAssetId:null,CustomValues:["Open","Closed"],Value:null,Label:"Status",RepeatGroup:false,DisplayField:false,ReadOnly:false,Hide:false,OriginalName:"Status"});
              }
            }
            let formData = {
              FormDataId : -1,
              Fields : JSON.parse(JSON.stringify(formFields)),
              FieldsToSave : JSON.parse(JSON.stringify(formFields)),
              FormId : form.FormId,
              FormTypeId : form.FormTypeId,
              Name : form.Name,
              CompanyId : auth.companyId(),
              CreatedDate : now,
              CreatedDateUTCOffset : -now.getTimezoneOffset()/60,
              CreatedBy : auth.employeeId(),
              ModifiedDate : now,
              ModifiedDateUTCOffset : -now.getTimezoneOffset()/60,
              ModifiedBy : auth.employeeId(),
              AllowPics : form.AllowPics,
              Latitude : latitude ? latitude : 0,
              Longitude : longitude ? longitude : 0,
              AllowVids : form.AllowVids
            }
            formDataList.push(formData);
          }
          let body ={
            UserId : auth.employeeId(),
            CompanyId : auth.companyId(),
            FormDataCollection : formDataList
          }
          fetch(process.env.API_URL + "/api/formDataCollection/save",{
            method : "POST",
            headers : {
              Accept : "application/json",
              "Content-Type" : "application/json; charset=utc-8",
              Authorization : "Bearer " + auth.token()
            },
            body : JSON.stringify(body)
          })
          .then(response => response)
          .then((response) =>{
            if(response.status == 200){
              response.json().then(bodyJson =>{
                let response = JSON.parse(bodyJson);
                let success = true;
                if(response && response.length > 0){
                  response.forEach(res =>{
                    if(res.ResponseType != 0) success = false;
                  });
                }
                if(!success){
                  this.setState({updateStatusText : 'There was a problem uploading one or more form data records.', alertSeverity : 'error',uploadingCSVFormData : false, customFormForImport : null});
                }
                else{
                  this.setState({updateStatusText : 'Successfully Uploaded ' + response.length + " Form Data Records.", alertSeverity : 'success',uploadingCSVFormData : false, customFormForImport : null});
                }
              });
            }
            else{
              this.setState({updateStatusText : 'There was an error uploading form data records.', alertSeverity : 'error',uploadingCSVFormData : false, customFormForImport : null});
            }
          })
          .catch((e) =>{
            console.log(e);
            this.setState({updateStatusText : 'There was an error uploading form data records.', alertSeverity : 'error',uploadingCSVFormData : false, customFormForImport : null});
          });
        }
        catch(e){
          console.log(e);
          this.setState({uploadingCSVFormData : false, updateStatusText : "There was a problem reading this file", alertSeverity : 'error', customFormForImport : null});
        }
      }
      reader.readAsText(files[0]);
    }
  }

  addFormCopy(id){
    let surveyForms = this.state.surveyForms;
    let investigationForms = this.state.investigationForms;
    let gpsForms = this.state.gpsForms;
    let gisForms = this.state.gisForms;
    let tagForms = this.state.tagForms;
    if(surveyForms.find(x => x.FormId == id)){
      let newForm = surveyForms.find(x => x.FormId == id);
      newForm = JSON.parse(JSON.stringify(newForm));
      newForm.Name = newForm.Name + " Copy";
      newForm.unsaved = true;
      newForm.FormId = -1;
      surveyForms.push(newForm);
    }
    else if(investigationForms.find(x => x.FormId == id)) {
      let newForm = investigationForms.find(x => x.FormId == id);
      newForm = JSON.parse(JSON.stringify(newForm));
      newForm.Name = newForm.Name + " Copy";
      newForm.FormId = -1;
      newForm.unsaved = true;
      investigationForms.push(newForm);
    }
    else if(gpsForms.find(x => x.FormId == id)) {
      let newForm = gpsForms.find(x => x.FormId == id);
      newForm = JSON.parse(JSON.stringify(newForm));
      newForm.Name = newForm.Name + " Copy";
      newForm.FormId = -1;
      newForm.unsaved = true;
      gpsForms.push(newForm);
    }
    else if(gisForms.find(x => x.FormId == id)) {
      let newForm = gisForms.find(x => x.FormId == id);
      newForm = JSON.parse(JSON.stringify(newForm));
      newForm.Name = newForm.Name + " Copy";
      newForm.FormId = -1;
      newForm.unsaved = true;
      gisForms.push(newForm);
    }
    else if(tagForms.find(x => x.FormId == id)) {
      let newForm = tagForms.find(x => x.FormId == id);
      newForm = JSON.parse(JSON.stringify(newForm));
      newForm.Name = newForm.Name + " Copy";
      newForm.FormId = -1;
      newForm.unsaved = true;
      tagForms.push(newForm);
    }
    
    this.setState({surveyForms,investigationForms,gpsForms,gisForms,tagForms, copyCustomFormTypeOpen : false});
  }

  addCustomFormFromCSV(){
    let files = document.getElementById("newFormCSV")["files"];
    if(files.length > 0){
      let reader =  new FileReader();
      let fieldList = [];
      let hasLat = false;
      let hasLon = false;
      reader.onloadend = (event) =>{
        //Regex here and below is to account for commas and new line characters being in field values. 
        let form = event.target.result.split(/\n(?=(?:(?:[^"]*"){2})*[^"]*$)/);
        form[0].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/).forEach(field =>{
          //Need to remove \r as it causes issues with data download. 
          field = field.replace('\r','');
          if((field.toLowerCase() == "lat" || field.toLowerCase() == "latitude")){
            hasLat = true;
          }
          else if((field.toLowerCase() == "lon" || field.toLowerCase() == "longitude")){
            hasLon = true;
          }
          fieldList.push({CustomValues : [], DisplayField : false, OriginalName: field, Hide: false, Label: null, Name : field, ReadOnly : false, RepeatGroup : false, Required: false, RequiredPhoto : false, RequiredPhotoMultimediaAssetId : null, Type : 'Text', Value : null });
        });
        let FormCollection = this.state.FormCollection;
        if(form.length > 1){
          for(let i = 1; i < form.length; i++){
            let record = form[i];
            record.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/).forEach((value,index) =>{
              let field = fieldList[index];
              if(field && value && value != "" && value != '\r' && field.CustomValues.length < 20 && !field.CustomValues.find(x => x == value)){
                value = value.replace('\r','');
                field.CustomValues.push(value);
              }
            });
          }
        }
        if(!hasLat || !hasLon){
          this.setState({updateStatusText : "We did not detect Location data. Add Latitude\\Longitude columns (eg. lat or latitude) to add locations to imported data.", alertSeverity : 'warning'});
        }
        else{
          this.setState({updateStatusText : "Validate each field and field's type. Once data is imported field types cannot be updated", alertSeverity : "success"});
        }
        let surveyForms = this.state.surveyForms;
        let investigationForms = this.state.investigationForms;
        let gpsForms = this.state.gpsForms;
        let gisForms = this.state.gisForms;
        let tagForms = this.state.tagForms;
        let newForm = {Name: files[0].name.split('.')[0], FormId: 0, Fields : fieldList, FormTriggerCollection : [], Color : "#095399", Type :this.state.csvCustomFormType, AutoGenerated : true, unsaved : true}
        if(this.state.csvCustomFormType == "Survey"){
          newForm.Type = this.state.csvCustomFormType;
          newForm.FormTypeId = 0;
          if(surveyForms.length > 0){
            let lastInList = surveyForms[surveyForms.length -1];
            if(lastInList.FormOrder){
              newForm.FormOrder = lastInList.FormOrder +1;
            }
          }
          else newForm.FormOrder = 1;
          surveyForms.push(newForm);
        }
        else if(this.state.csvCustomFormType == "Investigation"){
          newForm.Type = this.state.csvCustomFormType;
          newForm.FormTypeId = 1;
          if(investigationForms.length > 0){
            let lastInList = investigationForms[investigationForms.length -1];
            if(lastInList.FormOrder){
              newForm.FormOrder = lastInList.FormOrder +1;
            }
          }
          else newForm.FormOrder = 1;
          investigationForms.push(newForm);
        }
        else if(this.state.csvCustomFormType == "GPS Survey"){
          newForm.Type = this.state.csvCustomFormType;
          newForm.FormTypeId = 3;
          if(gpsForms.length > 0){
            let lastInList = gpsForms[gpsForms.length -1];
            if(lastInList.FormOrder){
              newForm.FormOrder = lastInList.FormOrder +1;
            }
          }
          else newForm.FormOrder = 1;
          gpsForms.push(newForm);
        }
        else if(this.state.csvCustomFormType == "Pipeline Survey"){
          newForm.Type = this.state.csvCustomFormType;
          newForm.FormTypeId = 2;
          if(gisForms.length > 0){
            let lastInList = gisForms[gisForms.length -1];
            if(lastInList.FormOrder){
              newForm.FormOrder = lastInList.FormOrder +1;
            }
          }
          else newForm.FormOrder = 1;
          gisForms.push(newForm);
        }
        else if(this.state.csvCustomFormType == "Tag"){
          newForm.Type = this.state.csvCustomFormType;
          newForm.FormTypeId = 5;
          if(tagForms.length > 0){
            let lastInList = tagForms[tagForms.length -1];
            if(lastInList.FormOrder){
              newForm.FormOrder = lastInList.FormOrder +1;
            }
          }
          else newForm.FormOrder = 1;
          tagForms.push(newForm);
        }
        FormCollection.push(newForm);
        this.setState({FormCollection, surveyForms, investigationForms,gpsForms, gisForms, tagForms, currentFormEdit : newForm, currentFormIndex: FormCollection.length -1, 
          editCustomForm: true, selectedGroups :[], selectedProjects : [], csvCustomFormTypeOpen : false});
      }
      reader.readAsText(files[0]);
    }
  }

  addCustomForm(type){
    if(auth.isSuperViewer()){
      return;
    }
    let FormCollection = this.state.FormCollection;
    let surveyForms = this.state.surveyForms;
    let investigationForms = this.state.investigationForms;
    let gpsForms = this.state.gpsForms;
    let gisForms = this.state.gisForms;
    let tagForms = this.state.tagForms;
    let newForm = {Name:"New Form", FormId:0, Fields:[], FormTriggerCollection:[], Color : '#095399'}
    
    if(type == "Survey"){
      if(surveyForms.length > 0){
        let lastInList = surveyForms[surveyForms.length -1];
        if(lastInList.FormOrder){
          newForm.FormOrder = lastInList.FormOrder +1;
        }
      }
      else newForm.FormOrder = 1;
      newForm.Type = type;
      newForm.FormTypeId = 0;
      surveyForms.push(newForm);
    }
    else if(type == "investigation"){
      newForm.Type = "Investigation";
      newForm.FormTypeId = 1;
      if(investigationForms.length > 0){
        let lastInList = investigationForms[investigationForms.length -1];
        if(lastInList.FormOrder){
          newForm.FormOrder = lastInList.FormOrder +1;
        }
      }
      else newForm.FormOrder = 1;
      investigationForms.push(newForm);
    }
    else if(type == "gps"){
      newForm.Type = "GPS Survey";
      newForm.FormTypeId = 3;
      if(gpsForms.length > 0){
        let lastInList = gpsForms[gpsForms.length -1];
        if(lastInList.FormOrder){
          newForm.FormOrder = lastInList.FormOrder +1;
        }
      }
      else newForm.FormOrder = 1;
      gpsForms.push(newForm);
    }
    else if(type == "gis"){
      newForm.Type = "Pipeline Survey";
      newForm.FormTypeId = 2;
      if(gisForms.length > 0){
        let lastInList = gisForms[gisForms.length -1];
        if(lastInList.FormOrder){
          newForm.FormOrder = lastInList.FormOrder +1;
        }
      }
      else newForm.FormOrder = 1;
      gisForms.push(newForm);
    }
    else if(type == "tag"){
      newForm.Type = "Tag";
      newForm.FormTypeId = 5;
      if(tagForms.length > 0){
        let lastInList = tagForms[tagForms.length -1];
        if(lastInList.FormOrder){
          newForm.FormOrder = lastInList.FormOrder +1;
        }
      }
      else newForm.FormOrder = 1;
      tagForms.push(newForm);
    }
    FormCollection.push(newForm);
    this.setState({FormCollection, surveyForms, investigationForms, gpsForms, gisForms, tagForms});
  }

  onAutoExportDataCheckboxChange(f_index){
    let formCollection = JSON.parse(JSON.stringify(this.state.companyFormsWithFeatureLayerMappings));
    if(!formCollection[f_index].revertToMappings){
      formCollection[f_index].revertToMappings = 
      {
        featureLayerMappings: JSON.parse(JSON.stringify(formCollection[f_index].featureLayerMappings)),
        SelectedLayerIndex: JSON.parse(JSON.stringify(formCollection[f_index].SelectedLayerIndex)),
        SelectedLayerId: JSON.parse(JSON.stringify(formCollection[f_index].SelectedLayerId)),
        AvailableLayers: JSON.parse(JSON.stringify(formCollection[f_index].AvailableLayers))
      }
    }
    let formToChange = formCollection[f_index];
    if(formToChange.featureLayerMappings.Frequency == null){
      if(!this.state.validArcGisKeys){
        this.setState({updateStatusText : "Cannot auto export data without validated Id\\Secret", alertSeverity : "warning"});
        return;
      }
      else{
        formToChange.featureLayerMappings.Frequency = "On Creation";
        formToChange.unsavedFormToLayerMap = true;
      }
    }
    else{
      formToChange.featureLayerMappings.Frequency = null;
      formToChange.unsavedFormToLayerMap = true;
    }

    this.setState({companyFormsWithFeatureLayerMappings : formCollection});
  }

  onAutoExportFrequencyDropdownChange(e, f_index){
    let formCollection = JSON.parse(JSON.stringify(this.state.companyFormsWithFeatureLayerMappings));
    if(!formCollection[f_index].revertToMappings){
      formCollection[f_index].revertToMappings = 
      {
        featureLayerMappings: JSON.parse(JSON.stringify(formCollection[f_index].featureLayerMappings)),
        SelectedLayerIndex: JSON.parse(JSON.stringify(formCollection[f_index].SelectedLayerIndex)),
        SelectedLayerId: JSON.parse(JSON.stringify(formCollection[f_index].SelectedLayerId)),
        AvailableLayers: JSON.parse(JSON.stringify(formCollection[f_index].AvailableLayers))
      }
    }
    let formToChange = formCollection[f_index];
    formToChange.unsavedFormToLayerMap = true;
    formToChange.featureLayerMappings.Frequency = e.target.value;
    this.setState({companyFormsWithFeatureLayerMappings : formCollection});
  
  }
  
  saveArcGisFormToLayerMap(f_index){
    let date = new Date();
    let formCollection = this.state.companyFormsWithFeatureLayerMappings;
    if(!formCollection[f_index].deletingExportMap){
      formCollection[f_index].mappingDataSaving = true;
    }
    this.setState({companyFormToFeatureLayerMappings: formCollection});
    for(var i = 0; i < formCollection[f_index].featureLayerMappings.FieldMappings.length; i++){
      if(!formCollection[f_index].featureLayerMappings.FieldMappings[i].CreatedBy) {
        formCollection[f_index].featureLayerMappings.FieldMappings[i].CreatedBy = auth.userId();
        formCollection[f_index].featureLayerMappings.FieldMappings[i].CreatedDate = date;
        formCollection[f_index].featureLayerMappings.FieldMappings[i].CreatedDateUTCOffset = - date.getTimezoneOffset() / 60;
      }
      formCollection[f_index].featureLayerMappings.FieldMappings[i].ModifiedBy = auth.userId();
      formCollection[f_index].featureLayerMappings.FieldMappings[i].ModifiedDate = date;
      formCollection[f_index].featureLayerMappings.FieldMappings[i].ModifiedDateUTCOffset = - date.getTimezoneOffset() / 60;
    }
    if(!formCollection[f_index].featureLayerMappings.CreatedBy) {
      formCollection[f_index].featureLayerMappings.CreatedBy = auth.userId();
      formCollection[f_index].featureLayerMappings.CreatedDate = date;
      formCollection[f_index].featureLayerMappings.CreatedDateUTCOffset = - date.getTimezoneOffset() / 60;
    }
    formCollection[f_index].featureLayerMappings.ModifiedBy = auth.userId();
    formCollection[f_index].featureLayerMappings.ModifiedDate = date;
    formCollection[f_index].featureLayerMappings.ModifiedDateUTCOffset = - date.getTimezoneOffset() / 60;
    let body = {
      companyFormToFeatureService: formCollection[f_index].featureLayerMappings,
      companyFormToFeatureServiceFieldMapIdsToDelete: formCollection[f_index].fieldMapIdsToBeDeleted ? formCollection[f_index].fieldMapIdsToBeDeleted : [],
      companyFormToFeatureServiceLayerMapIdsToDelete: formCollection[f_index].layerMapIdsToBeDeleted ? formCollection[f_index].layerMapIdsToBeDeleted : []
    } 
    fetch(process.env.API_URL + "/api/arcgis/saveCompanyFormToFeatureService",{
      method : "Post",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then((newRecord)=>{
          let companyFormToFeatureService = JSON.parse(newRecord);
          if(companyFormToFeatureService.DeletedDate){
            formCollection[f_index].featureLayerMappings = null;
            formCollection[f_index].hasSavedMappings = false;
          } else{
            formCollection[f_index].featureLayerMappings = companyFormToFeatureService;
            formCollection[f_index].hasSavedMappings = true;
          }
          formCollection[f_index].unsavedFormToLayerMap = false;
          formCollection[f_index].fieldMapIdsToBeDeleted = [];
          formCollection[f_index].layerMapIdsToBeDeleted = [];
          formCollection[f_index].revertToMappings = null;
          formCollection[f_index].mappingDataSaving = false;
          formCollection[f_index].deletingExportMap = false;
          this.setState({companyFormsWithFeatureLayerMappings: formCollection});
        });
      } else{
        if(formCollection[f_index].deletingExportMap){
          formCollection[f_index].featureLayerMappings.DeletedDate = null;
          formCollection[f_index].featureLayerMappings.DeletedBy = null;
          formCollection[f_index].featureLayerMappings.DeletedDateUTCOffset = null;
          for(var i = 0; i < formCollection[f_index].featureLayerMappings.FieldMappings.length; i++){
            formCollection[f_index].featureLayerMappings.FieldMappings[i].DeletedBy = null;
            formCollection[f_index].featureLayerMappings.FieldMappings[i].DeletedDate = null;
            formCollection[f_index].featureLayerMappings.FieldMappings[i].DeletedDateUTCOffset = null;
          }
        }
        formCollection[f_index].mappingDataSaving = false;
        formCollection[f_index].deletingExportMap = false;
        this.setState({companyFormsWithFeatureLayerMappings: formCollection});
      }
    })
    .catch(error =>{
      if(formCollection[f_index].deletingExportMap){
        formCollection[f_index].featureLayerMappings.DeletedDate = null;
        formCollection[f_index].featureLayerMappings.DeletedBy = null;
        formCollection[f_index].featureLayerMappings.DeletedDateUTCOffset = null;
        for(var i = 0; i < formCollection[f_index].featureLayerMappings.FieldMappings.length; i++){
          formCollection[f_index].featureLayerMappings.FieldMappings[i].DeletedBy = null;
          formCollection[f_index].featureLayerMappings.FieldMappings[i].DeletedDate = null;
          formCollection[f_index].featureLayerMappings.FieldMappings[i].DeletedDateUTCOffset = null;
        }
      }
      formCollection[f_index].mappingDataSaving = false;
      formCollection[f_index].deletingExportMap = false;
      this.setState({companyFormsWithFeatureLayerMappings: formCollection});
      console.log(error);
    });
  }

  unsavedFormToLayerMap(f_index){
    return this.state.companyFormsWithFeatureLayerMappings[f_index].unsavedFormToLayerMap;
  }

  discardFormFieldMappingChanges(f_index){
    let formCollection = JSON.parse(JSON.stringify(this.state.companyFormsWithFeatureLayerMappings));
    formCollection[f_index].fieldMapIdsToBeDeleted = [];
    formCollection[f_index].layerMapIdsToBeDeleted = [];

    if(formCollection[f_index].revertToMappings){
      let selectedLayerId = JSON.parse(JSON.stringify(formCollection[f_index].revertToMappings.SelectedLayerId));
      let selectedLayerIndex = JSON.parse(JSON.stringify(formCollection[f_index].revertToMappings.SelectedLayerIndex));

      for(let i = 0; i < formCollection[f_index].revertToMappings.featureLayerMappings.LayerMappings.length; i++){
        selectedLayerId = parseInt(formCollection[f_index].revertToMappings.featureLayerMappings.LayerMappings[i].FeatureLayerId);
        selectedLayerIndex = formCollection[f_index].revertToMappings.AvailableLayers.findIndex((x)=>{return x.id == selectedLayerId});
        break;
      } 
      formCollection[f_index].SelectedLayerId = selectedLayerId;
      formCollection[f_index].SelectedLayerIndex = selectedLayerIndex;
      formCollection[f_index].featureLayerMappings =  JSON.parse(JSON.stringify(formCollection[f_index].revertToMappings.featureLayerMappings));
      formCollection[f_index].AvailableLayers =  JSON.parse(JSON.stringify(formCollection[f_index].revertToMappings.AvailableLayers));
      formCollection[f_index].revertToMappings = null;
    } else{
      formCollection[f_index].featureLayerMappings = null;
    }
    formCollection[f_index].unsavedFormToLayerMap = false;
    this.setState({companyFormsWithFeatureLayerMappings: formCollection});
  }

  updateFormExportFeatureLayer(e, f_index){
    
    let companyFormToFeatureServiceId = null;
    let formCollection = this.state.companyFormsWithFeatureLayerMappings;
    
    if(formCollection[f_index].featureLayerMappings){
      companyFormToFeatureServiceId = formCollection[f_index].featureLayerMappings.CompanyFormToFeatureServiceId;
      if(formCollection[f_index].hasSavedMappings){
        for(var i = 0; i < formCollection[f_index].featureLayerMappings.FieldMappings.length; i++){
          if(formCollection[f_index].featureLayerMappings.FieldMappings[i].CompanyFormToFeatureServiceFieldMapId){
            if(!formCollection[f_index].fieldMapIdsToBeDeleted) formCollection[f_index].fieldMapIdsToBeDeleted = [];
            formCollection[f_index].fieldMapIdsToBeDeleted.push(formCollection[f_index].featureLayerMappings.FieldMappings[i].CompanyFormToFeatureServiceFieldMapId);
          }
        }
        for(var j = 0; j < formCollection[f_index].featureLayerMappings.LayerMappings.length; j++){
          if(formCollection[f_index].featureLayerMappings.LayerMappings[j].CompanyFormToFeatureServiceLayerMapId){
            if(!formCollection[f_index].layerMapIdsToBeDeleted) formCollection[f_index].layerMapIdsToBeDeleted = [];
            formCollection[f_index].layerMapIdsToBeDeleted.push(formCollection[f_index].featureLayerMappings.LayerMappings[j].CompanyFormToFeatureServiceLayerMapId);
          }
        }
        if(!formCollection[f_index].revertToMappings){
          formCollection[f_index].revertToMappings = 
          {
            featureLayerMappings: JSON.parse(JSON.stringify(formCollection[f_index].featureLayerMappings)),
            SelectedLayerIndex: JSON.parse(JSON.stringify(formCollection[f_index].SelectedLayerIndex)),
            SelectedLayerId: JSON.parse(JSON.stringify(formCollection[f_index].SelectedLayerId)),
            AvailableLayers: JSON.parse(JSON.stringify(formCollection[f_index].AvailableLayers))
    
          }
        }
      }
    } 
    let selectedFeatureLayerIndex  = Functions.findWithAttr(this.state.arcGisUserFeatureLayerList, "id", e.target.value);
    let selectedFeatureLayer = this.state.arcGisUserFeatureLayerList[selectedFeatureLayerIndex];
    
    formCollection[f_index].featureLayerMappings = {
      CompanyFormToFeatureServiceId: companyFormToFeatureServiceId,
      CompanyId: auth.companyId(),
      FormId: formCollection[f_index].FormId,
      FeatureServiceId : selectedFeatureLayer.id,
      FeatureServiceUrl: selectedFeatureLayer.url,
      FeatureServiceName: selectedFeatureLayer.name,
      FieldMappings: [],
      LayerMappings: []
    }
    formCollection[f_index].AvailableLayers = [];
    formCollection[f_index].SelectedLayerId = null;
    formCollection[f_index].SelectedLayerIndex = 0;

    formCollection[f_index].unsavedFormToLayerMap = true;

      formCollection[f_index].loadingFeatureLayerData = true;
      this.setState({companyFormsWithFeatureLayerMappings: formCollection}, ()=>{
        this.loadFeatureLayerData(f_index, selectedFeatureLayer.url);
      });

  }

  selectedExportLayerChange(e, f_index){
    let formCollection = this.state.companyFormsWithFeatureLayerMappings;
    formCollection[f_index].SelectedLayerId = parseInt(e.target.value);
    formCollection[f_index].SelectedLayerIndex = Functions.findWithAttr(formCollection[f_index].AvailableLayers, "id", formCollection[f_index].SelectedLayerId);
    this.setState({companyFormsWithFeatureLayerMappings: formCollection});
  }

  toggleGisLayerExport(e, f_index){
    let formCollection = this.state.companyFormsWithFeatureLayerMappings;
    let selectedLayer = formCollection[f_index].AvailableLayers[formCollection[f_index].SelectedLayerIndex];

    if(formCollection[f_index].featureLayerMappings){
      if(!formCollection[f_index].revertToMappings && formCollection[f_index].hasSavedMappings) 
      formCollection[f_index].revertToMappings = 
      {
        featureLayerMappings: JSON.parse(JSON.stringify(formCollection[f_index].featureLayerMappings)),
        SelectedLayerIndex: JSON.parse(JSON.stringify(formCollection[f_index].SelectedLayerIndex)),
        SelectedLayerId: JSON.parse(JSON.stringify(formCollection[f_index].SelectedLayerId)),
        AvailableLayers: JSON.parse(JSON.stringify(formCollection[f_index].AvailableLayers))

      }
    } 

    if(e.target.checked){
      formCollection[f_index].featureLayerMappings.LayerMappings.push(
        {
          CompanyFormToFeatureServiceLayerMapId: null,
          CompanyFormToFeatureServiceId: formCollection[f_index].featureLayerMappings.CompanyFormToFeatureServiceId,
          FeatureLayerId: selectedLayer.id,
          FeatureLayerName: selectedLayer.name,
          FeatureLayerGeometryType: selectedLayer.geometryType
        }
      );
    } else{
      for(var i = formCollection[f_index].featureLayerMappings.FieldMappings.length - 1; i > -1 ; i--){
        if(formCollection[f_index].featureLayerMappings.FieldMappings[i].FeatureLayerId == formCollection[f_index].SelectedLayerId){
          formCollection = this.deleteLayerFieldMapping(f_index, i, formCollection);
        }
      }

      if(!formCollection[f_index].layerMapIdsToBeDeleted) formCollection[f_index].layerMapIdsToBeDeleted = [];
      const layerMapping = formCollection[f_index].featureLayerMappings.LayerMappings.filter(x => x.FeatureLayerId == selectedLayer.id)[0];
      if(layerMapping.CompanyFormToFeatureServiceLayerMapId)
      formCollection[f_index].layerMapIdsToBeDeleted.push(layerMapping.CompanyFormToFeatureServiceLayerMapId);
      
      formCollection[f_index].featureLayerMappings.LayerMappings = formCollection[f_index].featureLayerMappings.LayerMappings.filter(x => x.FeatureLayerId != selectedLayer.id);
    }
    formCollection[f_index].unsavedFormToLayerMap = true;
    this.setState({companyFormsWithFeatureLayerMappings: formCollection});
  }

  selectedLayerExportFormFieldChange(e, f_index, mapping_index){
    let formCollection = this.state.companyFormsWithFeatureLayerMappings;
    if(formCollection[f_index].featureLayerMappings){
      if(!formCollection[f_index].revertToMappings && formCollection[f_index].hasSavedMappings) 
      formCollection[f_index].revertToMappings = 
      {
        featureLayerMappings: JSON.parse(JSON.stringify(formCollection[f_index].featureLayerMappings)),
        SelectedLayerIndex: JSON.parse(JSON.stringify(formCollection[f_index].SelectedLayerIndex)),
        SelectedLayerId: JSON.parse(JSON.stringify(formCollection[f_index].SelectedLayerId)),
        AvailableLayers: JSON.parse(JSON.stringify(formCollection[f_index].AvailableLayers))

      }
    } 
    
    formCollection[f_index].featureLayerMappings.FieldMappings[mapping_index].FormFieldName = e.target.value.split("|")[0];
    formCollection[f_index].featureLayerMappings.FieldMappings[mapping_index].IsGeoDataField = e.target.value.split("|")[1] == "1" ? true : false;
    formCollection[f_index].featureLayerMappings.FieldMappings[mapping_index].IsFormDataField = e.target.value.split("|")[2] == "1" ? true : false;
    formCollection[f_index].unsavedFormToLayerMap = true;
    this.setState({companyFormsWithFeatureLayerMappings: formCollection});
  }

  selectedLayerExportLayerFieldChange(e, f_index, mapping_index){
    let formCollection = this.state.companyFormsWithFeatureLayerMappings;
    if(formCollection[f_index].featureLayerMappings){
      if(!formCollection[f_index].revertToMappings && formCollection[f_index].hasSavedMappings) 
      formCollection[f_index].revertToMappings = 
      {
        featureLayerMappings: JSON.parse(JSON.stringify(formCollection[f_index].featureLayerMappings)),
        SelectedLayerIndex: JSON.parse(JSON.stringify(formCollection[f_index].SelectedLayerIndex)),
        SelectedLayerId: JSON.parse(JSON.stringify(formCollection[f_index].SelectedLayerId)),
        AvailableLayers: JSON.parse(JSON.stringify(formCollection[f_index].AvailableLayers))

      }
    } 
    formCollection[f_index].featureLayerMappings.FieldMappings[mapping_index].FeatureLayerFieldName = e.target.value;
    formCollection[f_index].unsavedFormToLayerMap = true;
    this.setState({companyFormsWithFeatureLayerMappings: formCollection});
  }

  validateArcGisMapping(f_index){
    this.setState({validatingARCGISMapping : true});
    let formCollection = this.state.companyFormsWithFeatureLayerMappings;
    let ArcGisToken = this.state.arcGisToken.access_token;
    let FeatureLayerGeometryType = formCollection[f_index].AvailableLayers[formCollection[f_index].SelectedLayerIndex].geometryType;
    let ValidationModelList = [];
    formCollection[f_index].featureLayerMappings.FieldMappings.forEach(mapping =>{
      if(formCollection[f_index].SelectedLayerId.toString() != mapping.FeatureLayerId) return; // doesn't belong to this feature layer.
      let model = {FeatureLayerFieldName : mapping.FeatureLayerFieldName}
      var field = formCollection[f_index].Fields.find(x => x.Name == mapping.FormFieldName);
      if(!field){
        this.setState({validatingARCGISMapping : false,updateStatusText : "Could not find field " + mapping.FormFieldName + " in form.", alertSeverity : "error" });
        return;
      }
      if(field.IsGeoDataField){
        switch(field.Name){
          case "positionDateTimeUtc":
            model.TestValue = "09/13/2022 11:11:11.11";
            break;
          case "locateLogNumber":
            model.TestValue = "128";
            break;
          case "locateFreqHz":
            model.TestValue = "3278";
            break;
          case "locateMesuredDepthMm":
            model.TestValue = "0.59";
            break;
          case "locateMeasuredCurrentMa":
            model.TestValue = "0.000";
            break;
          case "locateMeasuredSignalDirection":
            model.TestValue = "Unkown";
            break;
          case "locateView":
            model.TestValue = "Trans. Graph locate view";
            break;
          case "locateMode":
            model.TestValue = "Peak";
            break;
          case "locateGainDb":
            model.TestValue = "100";
            break;
          case "locateVectorOffset":
            model.TestValue = "0.17";
            break;
          case "locateVectorSeparation":
            model.TestValue = "0.180";
            break;
          case "locateCompassAngle":
            model.TestValue = "0.90";
            break;
          case "locateDistanceFromLastLog":
            model.TestValue = "0.36";
            break;
          case "logNumber":
            model.TestValue = "128";
            break;
          case "lat":
            model.TestValue = 48.480415973333336;
            break;
          case "lon":
            model.TestValue = -114.35742561333333;
            break;
          case "fix":
            model.TestValue = "RTK";
            break;
          case "numberOfSatellites":
            model.TestValue = "28";
            break;
          case "positionalDilutionOfPrecision":
            model.TestValue = "0.88";
            break;
          case "horizontalDilutionOfPrecision":
            model.TestValue = "0.48";
            break;
          case "verticalDilutionOfPrecision":
            model.TestValue = "0.73";
            break;
          case "altitude":
            model.TestValue = "1420.31";
            break;
          case "geoidSeparation":
            model.TestValue = "-17.11";
            break;
          case "standardDeviationLat":
            model.TestValue = "0.01";
            break;
          case "standardDeviationLon":
            model.TestValue = "0.01";
            break;
          case "standardDeviationAltitude":
            model.TestValue = "42";
            break;
          case "IsTiltCorrected":
            model.TestValue = "true";
            break;
        }
      }
      else if(field.IsFormDataField){
        switch(field.Name){
          case "Latitude (Form)":
            model.TestValue = 46.3953349000;
            break;
          case "Longitude (Form)":
            model.TestValue = -112.7280245000;
            break;
          case "CreatedBy (Form)":
            model.TestValue = "Test,User";
            break;
          case "CreatedDate UTC (Form)":
            model.TestValue = "2022-09-13 20:09:19.200";
            break;
        }
      }
      else{
        switch(field.Type){
          case "Drop Down":
          case "Comments":
          case "MultiSelect":
          case "Text":
            model.TestValue = "Abcd1234!";
            break;
          case "Number":
            model.TestValue = 123;
            break;
          case "Date":
            model.TestValue = "2022-09-13T20:00:40.507Z";
            break;
          case "Time":
            model.TestValue = "2:08 PM";
            break;
          case "Yes/No":
            model.TestValue = "true";
            break;
          default:
            model.TestValue = "";
        }
      }
      ValidationModelList.push(model);
    });
    let body = {
      FeatureServiceUrl : formCollection[f_index].featureLayerMappings.FeatureServiceUrl,
      FeatureLayerId : formCollection[f_index].AvailableLayers[formCollection[f_index].SelectedLayerIndex].id.toString(),
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      ValidationModelList,
      ArcGisToken,
      FeatureLayerGeometryType,
    }
    fetch(process.env.API_URL + "/api/arcgis/validateLayerMapping",{
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=utf-8",
          Accept : "application/json",
          Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(response =>{
          if(response && response.length > 0){
            let errormessage = response[0].description;
            this.setState({updateStatusText : errormessage, alertSeverity : "error",validatingARCGISMapping : false});
          }
          else{
            this.setState({updateStatusText : "Field mappings for this layer are valid.", alertSeverity : "success",validatingARCGISMapping : false});
          }
        });
      }
      else{
        this.setState({updateStatusText : "There was an error validating the field mappings. Try again.", alertSeverity : "error",validatingARCGISMapping : false});
      }
    }).catch(e =>{
      this.setState({updateStatusText : "There was an error validating the field mappings. Try again.", alertSeverity : "error",validatingARCGISMapping : false})
    })
  }

  addArcGisFormFieldMapping(f_index){
    let formCollection = this.state.companyFormsWithFeatureLayerMappings;
    let selectedLayer = formCollection[f_index].AvailableLayers[formCollection[f_index].SelectedLayerIndex];
    let mapCountForSelectedLayer = formCollection[f_index].featureLayerMappings.FieldMappings.filter((x) => x.FeatureLayerId == selectedLayer.id).length;
    
    let totalFormFieldsCount = formCollection[f_index].Fields.length;
    if(selectedLayer.geometryType == "esriGeometryPolyline" || selectedLayer.geometryType == "esriGeometryPolygon"){
      totalFormFieldsCount = formCollection[f_index].Fields.filter(x => !x.IsGeoDataField).length;
    }

    if(mapCountForSelectedLayer >= selectedLayer.fields.length || mapCountForSelectedLayer >= totalFormFieldsCount) return;

    if(formCollection[f_index].featureLayerMappings){
      if(!formCollection[f_index].revertToMappings && formCollection[f_index].hasSavedMappings) 
      formCollection[f_index].revertToMappings = 
      {
        featureLayerMappings: JSON.parse(JSON.stringify(formCollection[f_index].featureLayerMappings)),
        SelectedLayerIndex: JSON.parse(JSON.stringify(formCollection[f_index].SelectedLayerIndex)),
        SelectedLayerId: JSON.parse(JSON.stringify(formCollection[f_index].SelectedLayerId)),
        AvailableLayers: JSON.parse(JSON.stringify(formCollection[f_index].AvailableLayers))

      }
    } 

    

    let defaultFormField = null;
    let defaultLayerField = null;

    for(var i = 0; i < formCollection[f_index].Fields.length; i++){
      if(!this.formFieldIsAlreadyMapped(formCollection[f_index].Fields[i], f_index, -1)){
        defaultFormField = formCollection[f_index].Fields[i];
        break;
      }
    }

    for(var j = 0; j < selectedLayer.fields.length; j++){
      if(!this.layerFieldIsAlreadyMapped(selectedLayer.fields[j], f_index, -1)){
        defaultLayerField = selectedLayer.fields[j];
        break;
      }
    }

    formCollection[f_index].featureLayerMappings.FieldMappings.push(
      {
        CompanyFormToFeatureServiceFieldMapId: null,
        CompanyFormToFeatureServiceId: formCollection[f_index].featureLayerMappings.CompanyFormToFeatureServiceId,
        FeatureLayerId: selectedLayer.id,
        FeatureLayerName: selectedLayer.name,
        FeatureLayerGeometryType: selectedLayer.geometryType,
        FormFieldName: defaultFormField ?  defaultFormField.Name : null,
        IsGeoDataField: defaultFormField.IsGeoDataField ? true : false,
        IsFormDataField: defaultFormField.IsFormDataField ? true : false,
        FeatureLayerFieldName: defaultLayerField ?  defaultLayerField.name : null
      }
    );
    formCollection[f_index].unsavedFormToLayerMap = true;
    this.setState({companyFormsWithFeatureLayerMappings: formCollection});

  }

  formFieldIsAlreadyMapped(field, f_index, mapping_index){
    let formCollection = this.state.companyFormsWithFeatureLayerMappings;
    let isAlreadyMapped = false
    formCollection[f_index].featureLayerMappings.FieldMappings.forEach((mapping, index)=>{
      if(mapping_index != index && mapping.FormFieldName == field.Name && mapping.FeatureLayerId == formCollection[f_index].SelectedLayerId) isAlreadyMapped = true; 
    });
    return isAlreadyMapped;
  }

  layerFieldIsAlreadyMapped(field, f_index, mapping_index){
    let formCollection = this.state.companyFormsWithFeatureLayerMappings;
    let isAlreadyMapped = false
    formCollection[f_index].featureLayerMappings.FieldMappings.forEach((mapping, index)=>{
      if(mapping_index != index && mapping.FeatureLayerId == formCollection[f_index].SelectedLayerId){
        if(mapping.FeatureLayerFieldName == field.name) isAlreadyMapped = true;
      }
    });
    return isAlreadyMapped;
  }

  deleteLayerFieldMapping(f_index, mapping_index, formCollection){
    let returnFormCollection = true;

    if(!formCollection){
      formCollection = this.state.companyFormsWithFeatureLayerMappings;
      returnFormCollection = false;
    } 

    if(formCollection[f_index].featureLayerMappings){
      if(!formCollection[f_index].revertToMappings && formCollection[f_index].hasSavedMappings) 
      formCollection[f_index].revertToMappings = 
      {
        featureLayerMappings: JSON.parse(JSON.stringify(formCollection[f_index].featureLayerMappings)),
        SelectedLayerIndex: JSON.parse(JSON.stringify(formCollection[f_index].SelectedLayerIndex)),
        SelectedLayerId: JSON.parse(JSON.stringify(formCollection[f_index].SelectedLayerId)),
        AvailableLayers: JSON.parse(JSON.stringify(formCollection[f_index].AvailableLayers))

      }
    } 

    if(!formCollection[f_index].fieldMapIdsToBeDeleted) formCollection[f_index].fieldMapIdsToBeDeleted = [];

    if(formCollection[f_index].featureLayerMappings.FieldMappings[mapping_index].CompanyFormToFeatureServiceFieldMapId)
    formCollection[f_index].fieldMapIdsToBeDeleted.push(formCollection[f_index].featureLayerMappings.FieldMappings[mapping_index].CompanyFormToFeatureServiceFieldMapId);
    
    formCollection[f_index].featureLayerMappings.FieldMappings.splice(mapping_index, 1);
    formCollection[f_index].unsavedFormToLayerMap = true;

    if(returnFormCollection) return formCollection;

    this.setState({companyFormsWithFeatureLayerMappings: formCollection});
  }

  addTrigger(formIndex){
    if(auth.isSuperViewer()){
      return;
    }
    // new trigger - default trigger values
    let FormCollection = this.state.FormCollection;
    FormCollection[formIndex].FormTriggerCollection.push({Enabled:false, TriggerFieldUpdateIndex:'setValue', TriggerFieldFromValueUpdateIndex:'any'});
    this.setState({FormCollection});
  }
  
  saveTrigger(formIndex){
    if(auth.isSuperViewer()){
      return;
    }
    this.setState({loadingForms:true});
    this.saveTriggers(true, false, formIndex);
  }

  unsavedTriggers(formIndex){
    return this.state.FormCollection[formIndex].unsavedTriggers;
  }


  updateTriggerFieldFrom(e, form, f_index, t_index){
    let FormCollection = this.state.FormCollection;
    FormCollection[f_index].unsavedTriggers = true;
    FormCollection[f_index].FormTriggerCollection[t_index].TriggerFieldFromName = form.Fields[e.target.value].Name;
    FormCollection[f_index].FormTriggerCollection[t_index].TriggerFieldFromNameIndex = e.target.value;
    FormCollection[f_index].FormTriggerCollection[t_index].TriggerField = form.Fields[e.target.value];
    if(FormCollection[f_index].FormTriggerCollection[t_index].TriggerFieldUpdateNameIndex == "requirePhoto"){
      FormCollection[f_index].FormTriggerCollection[t_index].TriggerFieldUpdateName = form.Fields[e.target.value].Name;
      FormCollection[f_index].FormTriggerCollection[t_index].TriggerFieldUpdate = form.Fields[e.target.value];
    }
    this.setState({FormCollection});
  }

  updateTriggerFieldSetValue(e, form, f_index, t_index){
    let FormCollection = this.state.FormCollection; 
    FormCollection[f_index].unsavedTriggers = true;
    FormCollection[f_index].FormTriggerCollection[t_index].TriggerFieldSetValue = e.target.value;
    this.setState({FormCollection});
  }

  updateTriggerFieldFromValue(e, form, f_index, t_index, customValue){
    let FormCollection = this.state.FormCollection; 
    FormCollection[f_index].unsavedTriggers = true;
    FormCollection[f_index].FormTriggerCollection[t_index].TriggerFieldValue = 
      customValue ? form.FormTriggerCollection[t_index].TriggerField.CustomValues[e.target.value] : e.target != undefined ? e.target.value : e;
    FormCollection[f_index].FormTriggerCollection[t_index].TriggerFieldValueIndex = e.target != undefined ? e.target.value : -1;
    this.setState({FormCollection});
  }

  updateTriggerFieldValueEventAction(e, form, f_index, t_index){
    let FormCollection = this.state.FormCollection;
    FormCollection[f_index].unsavedTriggers = true;
    FormCollection[f_index].FormTriggerCollection[t_index].TriggerFieldFromValueUpdateIndex = e.target.value;
    this.setState({FormCollection});
  }

  updateTriggerFieldUpdate(e, form, f_index, t_index){
    let FormCollection = this.state.FormCollection;
    FormCollection[f_index].unsavedTriggers = true;
    if(e.target.value == "requirePhoto"){
      FormCollection[f_index].FormTriggerCollection[t_index].TriggerFieldUpdateName = FormCollection[f_index].FormTriggerCollection[t_index].TriggerFieldFromName;
      FormCollection[f_index].FormTriggerCollection[t_index].TriggerFieldUpdateNameIndex = e.target.value;
      FormCollection[f_index].FormTriggerCollection[t_index].TriggerFieldUpdate = FormCollection[f_index].FormTriggerCollection[t_index].TriggerField;
    }
    else{
      FormCollection[f_index].FormTriggerCollection[t_index].TriggerFieldUpdateName = form.Fields[e.target.value].Name;
      FormCollection[f_index].FormTriggerCollection[t_index].TriggerFieldUpdateNameIndex = e.target.value;
      FormCollection[f_index].FormTriggerCollection[t_index].TriggerFieldUpdate = form.Fields[e.target.value];
    }
    this.setState({FormCollection});
  }

  updateTriggerFieldEventAction(e, form, f_index, t_index){
    let FormCollection = this.state.FormCollection;
    FormCollection[f_index].unsavedTriggers = true;
    FormCollection[f_index].FormTriggerCollection[t_index].TriggerFieldUpdateIndex = e.target.value;
    this.setState({FormCollection});
  }

  disableTrigger(trigger, f_index, t_index){
    if(auth.isSuperViewer()){
      return;
    }
    let FormCollection = this.state.FormCollection;
    FormCollection[f_index].unsavedTriggers = true;
    FormCollection[f_index].FormTriggerCollection[t_index].Enabled = false;
    this.setState({FormCollection});
  }

  enableTrigger(trigger, f_index, t_index){
    if(auth.isSuperViewer()){
      return;
    }
    let FormCollection = this.state.FormCollection;
    FormCollection[f_index].unsavedTriggers = true;
    FormCollection[f_index].FormTriggerCollection[t_index].Enabled = true;
    this.setState({FormCollection});
  }

  deleteTriggerWarning(trigger, f_index, t_index){
    if(auth.isSuperViewer()){
      return;
    }
    this.setState({currentFormIndex: f_index, currentTriggerIndex: t_index, deleteTriggerWarning: true});
  }

  deleteTrigger(){
    if(auth.isSuperViewer()){
      return;
    }
    let FormCollection = this.state.FormCollection;
    FormCollection[this.state.currentFormIndex].FormTriggerCollection[this.state.currentTriggerIndex].DeletedDate = new Date();
    FormCollection[this.state.currentFormIndex].FormTriggerCollection[this.state.currentTriggerIndex].DeletedBy = auth.userId();
    this.setState({FormCollection, deleteTriggerWarning:false});
    this.saveTriggers(true, false, this.state.currentFormIndex, this.state.currentTriggerIndex);
  }

  deleteCustomFormWarning(index,deleteFormType){
    if(auth.isSuperViewer()){
      return;
    }
    this.setState({currentFormIndex: index, deleteWarning: true, deleteFormType});
  }

  deleteCustomForm(){
    let index = this.state.currentFormIndex;
    let formType = this.state.deleteFormType
    let surveyForms = this.state.surveyForms;
    let investigationForms = this.state.investigationForms;
    let gpsForms = this.state.gpsForms;
    let gisForms = this.state.gisForms;
    let tagForms = this.state.tagForms;
    if(formType == "survey"){
      surveyForms[index].DeletedDate = new Date();
      surveyForms[index].DeletedBy = auth.userId();
    }
    else if(formType == "investigation"){
      investigationForms[index].DeletedDate = new Date();
      investigationForms[index].DeletedBy = auth.userId();
    }
    else if(formType == "gps"){
      gpsForms[index].DeletedDate = new Date();
      gpsForms[index].DeletedBy = auth.userId();
    }
    else if(formType == "gis"){
      gisForms[index].DeletedDate = new Date();
      gisForms[index].DeletedBy = auth.userId();
    }
    else if(formType == "tag"){
      tagForms[index].DeletedDate = new Date();
      tagForms[index].DeletedBy = auth.userId();
    }
    this.setState({surveyForms,investigationForms,gpsForms,gisForms,tagForms, deleteWarning:false},() =>{
      this.saveCustomForm(true, false, index, formType);
    });
  }

  deleteArcGisExportMapping(){
    var f_index = this.state.deleteExportMapFormIndex;
    let formCollection = this.state.companyFormsWithFeatureLayerMappings;
    let deletedDate = new Date();
    formCollection[f_index].featureLayerMappings.DeletedDate = deletedDate;
    formCollection[f_index].featureLayerMappings.DeletedBy = auth.userId();
    formCollection[f_index].featureLayerMappings.DeletedDateUTCOffset = - deletedDate.getTimezoneOffset() /60;
    for(var i = 0; i < formCollection[f_index].featureLayerMappings.FieldMappings.length; i++){
      formCollection[f_index].featureLayerMappings.FieldMappings[i].DeletedBy = auth.userId();
      formCollection[f_index].featureLayerMappings.FieldMappings[i].DeletedDate = deletedDate;
      formCollection[f_index].featureLayerMappings.FieldMappings[i].DeletedDateUTCOffset = - deletedDate.getTimezoneOffset() / 60;
    }
    for(var j = 0; j < formCollection[f_index].featureLayerMappings.LayerMappings.length; j++){
      formCollection[f_index].featureLayerMappings.LayerMappings[j].DeletedBy = auth.userId();
      formCollection[f_index].featureLayerMappings.LayerMappings[j].DeletedDate = deletedDate;
      formCollection[f_index].featureLayerMappings.LayerMappings[j].DeletedDateUTCOffset = - deletedDate.getTimezoneOffset() / 60;
    }
    formCollection[f_index].fieldMapIdsToBeDeleted = [];
    formCollection[f_index].layerMapIdsToBeDeleted = [];
    formCollection[f_index].deletingExportMap = true;
    this.setState({companyFormsWithFeatureLayerMappings: formCollection, deleteExportMapWarning: false},()=>{
      this.saveArcGisFormToLayerMap(f_index);
    });
    
  }

  enableForm(index, formType){
    if(auth.isSuperViewer()){
      return;
    }
    let surveyForms = this.state.surveyForms;
    let investigationForms = this.state.investigationForms;
    let gpsForms = this.state.gpsForms;
    let gisForms = this.state.gisForms;
    let tagForms = this.state.tagForms;
    if(formType == "survey"){
      surveyForms[index].Enabled = true;
    }
    else if(formType == "investigation"){
      investigationForms[index].Enabled = true;
    }
    else if(formType == "gps"){
      gpsForms[index].Enabled = true;
    }
    else if(formType == "gis"){
      gisForms[index].Enabled = true;
    }
    else if(formType == "tag"){
      tagForms[index].Enabled = true;
    }
    this.setState({surveyForms,investigationForms,gpsForms,gisForms,tagForms, currentFormIndex: index},() =>{
      this.saveCustomForm(true, false, index, formType);
    });
  }

  disableForm(index, formType){
    if(auth.isSuperViewer()){
      return;
    }
    let surveyForms = this.state.surveyForms;
    let investigationForms = this.state.investigationForms;
    let gpsForms = this.state.gpsForms;
    let gisForms = this.state.gisForms;
    let tagForms = this.state.tagForms;
    if(formType == "survey"){
      surveyForms[index].Enabled = false;
    }
    else if(formType == "investigation"){
      investigationForms[index].Enabled = false;
    }
    else if(formType == "gps"){
      gpsForms[index].Enabled = false;
    }
    else if(formType == "gis"){
      gisForms[index].Enabled = false;
    }
    else if(formType == "tag"){
      tagForms[index].Enabled = false;
    }
    this.setState({surveyForms,investigationForms,gpsForms,gisForms,tagForms, currentFormIndex: index},() =>{
      this.saveCustomForm(true, false, index, formType);
    });
  }

  editFormProjects(projectId, formId){
    let formProjects = this.state.formProjects;
    let projectIndex = formProjects.findIndex(x => x.ProjectId == projectId && x.FormId == formId);
    if(projectIndex == -1){
      //Need to add
      formProjects.push({FormId : formId, ProjectId : projectId});
    }
    else{
      //Need to remove
      formProjects.splice(projectIndex, 1);
    }
    this.setState({formProjects});
  }

  editForm(index, formType){
    if(auth.isSuperViewer()){
      return;
    }
    
    let currentFormEdit = {};
    if(formType == "survey"){
      currentFormEdit = this.state.surveyForms[index];
    }
    else if(formType == "investigation"){
      currentFormEdit = this.state.investigationForms[index];
    }
    else if(formType == "gps"){
      currentFormEdit = this.state.gpsForms[index];
    }
    else if(formType == "gis"){
      currentFormEdit = this.state.gisForms[index];
    }
    else if(formType == "tag"){
      currentFormEdit = this.state.tagForms[index];
    }
    
    let selectedGroups = [];
    this.state.formGroups.forEach(formGroup =>{
      if(formGroup.FormId == currentFormEdit.FormId){
        selectedGroups.push(this.state.companyGroups.find(x => x.CompanyGroupId == formGroup.CompanyGroupId));
      }
    });
    if(selectedGroups.length > 0 && selectedGroups.length == this.state.companyGroups.length-1){
      selectedGroups.push({CompanyGroupId : -1, GroupLabel : "All"});
    }
    let selectedProjects = [];
    
    this.state.formProjects.forEach(formProject =>{
      if(formProject.FormId == currentFormEdit.FormId){
        let proj = this.state.companyProjects.find(x => x.ProjectId == formProject.ProjectId);
        if(proj)selectedProjects.push(proj);
      }
    });
    
    if(selectedProjects.length > 0 && selectedProjects.length == this.state.companyProjects.length -1){
      selectedProjects.push({ProjectId : -1, ProjectLabel : "All"});
    }
    
    this.setState({currentFormEdit, currentFormIndex: index, editCustomForm: true, selectedGroups, selectedProjects});
  }

  //eslint-disable-next-line
  saveCustomFieldValues(fieldIndex){
    this.setState({editCustomFieldValues:false,  editCustomForm:true});
  }

  sortCustomValues(fieldIndex){
    let currentFormEdit = this.state.currentFormEdit;
    let currentFieldEdit = currentFormEdit.Fields[fieldIndex];
    if (currentFieldEdit.CustomValues) {
      currentFieldEdit.CustomValues.sort();
    }
    this.setState({currentFormEdit});
  }

  editCustomValues(fieldIndex){
    let currentFieldEdit = this.state.currentFormEdit.Fields[fieldIndex];
    if (currentFieldEdit.CustomValues == undefined) currentFieldEdit.CustomValues = [""];
    currentFieldEdit.ValueExpand = !currentFieldEdit.ValueExpand;
    this.setState({currentFieldEdit});
  }

  addField(){
    let currentFormEdit = this.state.currentFormEdit;
    currentFormEdit.Fields.push({});
    this.setState({currentFormEdit});
  }

  addFieldValue(fieldIndex){
    let currentFieldEdit = this.state.currentFormEdit.Fields[fieldIndex];
    currentFieldEdit.CustomValues.push("");
    this.setState({currentFieldEdit});
  }

  deleteCustomField(fieldIndex){
    let currentFieldEdit = this.state.currentFormEdit.Fields[fieldIndex];
    this.state.currentFormEdit.Fields.splice(fieldIndex, 1);
    this.setState({currentFieldEdit});
  }

  deleteCustomValue(fieldIndex, valueIndex){
    let currentFieldEdit = this.state.currentFormEdit.Fields[fieldIndex];
    currentFieldEdit.CustomValues.splice(valueIndex, 1); 
    this.setState({currentFieldEdit});
  }

  addNotification(type){
    console.log(type);
    let Triggers = this.state.Triggers;
    Triggers.push({
      Field : type == "LeakSurvey" ? "" : type,
      Equality : ">",
      Grade : "",
      Emails : "",
      CustomFormNotificationObject : type =="CustomForm" ? {CustomFields : [], CustomFieldValue : "any"} : null,
      CompanyNotificationTriggerId : -1
    });
    this.setState({Triggers,notificationTypeOpen:false})
  }

  addCompanyReponseCode(){
    let companyResponseCodes = this.state.companyResponseCodes;
    let now = new Date();
    companyResponseCodes.push({
      MemberCode : "",
      ResponseName : "",
      ResponseCode : "",
      CompanyResponseCodeId : -1,
      CompanyId : auth.companyId(),
      CreatedDate : now,
      CreatedBy : auth.employeeId(),
      CreatedDateUTCOffset : - (now.getTimezoneOffset() / 60)
    });
    this.setState({companyResponseCodes});
  }

  addMapReport(){
    let mapReports = this.state.mapReports;
    let now = new Date();
    mapReports.push({
      MapReportSubscriptionId : -1,
      FormIdToTriggerOn : null,
      Emails : "",
      IncludedFormIds : "",
      IncludedFormIdList :[],
      IncludedCompanyArcGisLayerUrls : "",
      IncludedCompanyArcGisLayerUrlList : [],
      HeaderField : "",
      CompanyId : auth.companyId(),
      CreatedDate : now,
      CreatedBy : auth.employeeId(),
      CreatedDateUTCOffset : - (now.getTimezoneOffset() / 60),
    });
    this.setState({mapReports});
  }

  addDownloadSubcription(OnCreation){
    let downloadSubscriptions = this.state.downloadSubscriptions;
    let now = new Date();
    downloadSubscriptions.push({
      DownloadSubscriptionId : -1,
      Frequency : OnCreation ? "OnCreation" : "",
      FormList : "",
      FormIdList : [],
      Emails : "",
      UseModified : false,
      CompanyId : auth.companyId(),
      CreatedDate : now,
      CreatedBy : auth.employeeId(),
      CreatedDateUTCOffset : - (now.getTimezoneOffset() / 60),
    });
    this.setState({downloadSubscriptions,notificationTypeOpen : false});
  }

  updateCustomFormNotificationTriggerFieldValue(e, index, customValue){
    let triggers = this.state.Triggers;
    triggers[index].CustomFormNotificationObject.TriggerFieldValue = customValue ? e.target.value : e.target != undefined ? e.target.value : e; 
    this.setState({Triggers : triggers});
  }

  updateCustomFormNotificationTriggerFieldValueForDownloadSubscriptionTrigger(e, index, t_index, customValue){
    let downloadSubscriptions = this.state.downloadSubscriptions;
    downloadSubscriptions[index].Triggers[t_index].TriggerFieldValue = customValue ? e.target.value : e.target != undefined ? e.target.value : e;
    this.setState(downloadSubscriptions);
  }

  getCustomValues(trigger){
    if(trigger.CustomFormNotificationObject.CustomFormField && trigger.CustomFormNotificationObject.CustomFields){
      let value = trigger.CustomFormNotificationObject.CustomFields.find(x => x.Name == trigger.CustomFormNotificationObject.CustomFormField);
      if(value){
        return value.CustomValues
      }
    }
    return null;
  }

  getCustomValuesForDownloadSubscriptionTrigger(trigger){
    if(trigger.CustomFormField && trigger.CustomFields){
      let value = trigger.CustomFields.find(x => x.Name == trigger.CustomFormField);
      if(value && value.CustomValues && value.CustomValues.length > 0){
        return value.CustomValues;
      }
    }
    return null;
  }

  addGroup(){
    let companyGroups = this.state.companyGroups;
    companyGroups.push({
      GroupLabel : "",
      CompanyGroupId : -1
    });
    this.setState({companyGroups});
  }

  addDownloadSubTrigger(index, onCreation){
    let downloadSubscriptions = this.state.downloadSubscriptions;
    let form =  null;
    let newTrigger = {};
    downloadSubscriptions[index].showTriggers = true;
    if(onCreation){
      if(downloadSubscriptions[index].FormIdList[0]){
        form = this.state.FormCollection.find(x => x.FormId == downloadSubscriptions[index].FormIdList[0]);
      }
      newTrigger = {CustomFormId : form ? form.FormId :null, CustomFormType : form ? form.Type : null, CustomFormName : form ? form.Name : null, CustomFormField : null, CustomFields : form ? form.Fields : null, CustomFieldValue : "any", TriggerFieldValue : null, AndOr : "OR" }
    }
    else{
      newTrigger = {CustomFormId : null, CustomFormField : null, CustomFieldValue : "any", TriggerFieldValue : null, AndOr : "OR" }
    }
    
    if(!downloadSubscriptions[index].Triggers){
      downloadSubscriptions[index].Triggers = [newTrigger];
    }
    else{
      downloadSubscriptions[index].Triggers.push(newTrigger);
    }
    this.setState({downloadSubscriptions});
  }

  deleteDownloadSubscription(index){
    if(auth.isSuperViewer()){
      return;
    }
    let downloadSubscriptions = this.state.downloadSubscriptions;
    downloadSubscriptions[index].DeletedDate = new Date();
    downloadSubscriptions[index].DeletedBy = auth.employeeId()
    downloadSubscriptions[index].DeletedDateUTCOffset = - new Date().getTimezoneOffset() / 60;
    this.setState({downloadSubscriptions});
  }

  deleteDownloadSubscriptionTrigger(index, t_index){
    if(auth.isSuperViewer()){
      return;
    }
    let downloadSubscriptions = this.state.downloadSubscriptions;
    downloadSubscriptions[index].Triggers.splice(t_index, 1);
    this.setState({downloadSubscriptions});
  }

  deleteMapReport(index){
    if(auth.isSuperViewer()){
      return;
    }
    let mapReports = this.state.mapReports;
    mapReports[index].DeletedDate = new Date();
    mapReports[index].DeletedBy = auth.employeeId()
    mapReports[index].DeletedDateUTCOffset = - new Date().getTimezoneOffset() / 60;
    this.setState({mapReports});
  }

  deleteNotification(index){
    if(auth.isSuperViewer()){
      return;
    }
    let Triggers = this.state.Triggers;
    Triggers[index].DeletedDate = new Date();
    Triggers[index].DeletedBy = auth.employeeId()
    Triggers[index].DeletedDateUTCOffset = - new Date().getTimezoneOffset() /60;
    this.setState({Triggers});
  }

  deleteGroup(index){
    let companyGroups = this.state.companyGroups;
    companyGroups[index].DeletedDate = new Date();
    companyGroups[index].DeletedBy = auth.employeeId();
    companyGroups[index].DeletedDateUTCOffset = - new Date().getTimezoneOffset() / 60;
    this.setState({companyGroups});
  }

  moveFieldUp(index){
    let currentFormEdit = this.state.currentFormEdit;
    if (index > 0) {
      var f = currentFormEdit.Fields.splice(index, 1)[0];
      currentFormEdit.Fields.splice(index-1, 0, f);
      this.setState({currentFormEdit});
    }
  }

  moveFieldDown(index){
    let currentFormEdit = this.state.currentFormEdit;
    if (index < currentFormEdit.Fields.length) {
      var f = currentFormEdit.Fields.splice(index, 1)[0];
      currentFormEdit.Fields.splice(index+1, 0, f);
      this.setState({currentFormEdit});
    }
  }

  addCard() {
    let required = true;
    if(!this.state.cardNumber || this.state.cardNumber == ""){
        required = false;
        this.setState({cardNumberColor : "#ca0000"});
    }
    if(!this.state.cardMonth || this.state.cardMonth == ""){
        required = false;
        this.setState({cardMonthColor : "#ca0000"});
    }
    if(!this.state.cardYear || this.state.cardYear == ""){
        required = false;
        this.setState({cardYearColor : "#ca0000"});
    }
    if(!this.state.CVC || this.state.CVC == ""){
        required = false;
        this.setState({cvcColor : "#ca0000"});
    }
    if(!this.state.billingAddressLine1 || this.state.billingAddressLine1 == ""){
      required = false;
      this.setState({billingAddressLine1Color : "#ca0000"});
    }
    if(!this.state.billingAddressCity || this.state.billingAddressCity == ""){
        required = false;
        this.setState({billingAddressCityColor : "#ca0000"});
    }
    if(!this.state.billingAddressState || this.state.billingAddressState == ""){
      required = false;
      this.setState({billingAddressStateColor : "#ca0000"});
    }
    if(!this.state.billingAddressZip || this.state.billingAddressZip == "" || this.state.billingAddressZip.length < 5){
        required = false;
        this.setState({billingAddressZipColor : "#ca0000"});
    }
    if(!this.state.billingAddressPhone || this.state.billingAddressPhone == "" || this.state.billingAddressPhone.length < 12){
        required = false;
        this.setState({billingAddressPhoneColor : "#ca0000"});
    }


    if(required){

      let body = {
        line1: this.state.billingAddressLine1.trim(),
        line2: this.state.billingAddressLine2.trim(),
        city: this.state.billingAddressCity.trim(),
        region: this.state.billingAddressState.trim(),
        country: "USA",
        postalCode: this.state.billingAddressZip.trim(),
      }

      this.updateCreditCard();

      // fetch(process.env.API_URL + "/api/account/validateBillingAddress", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: "Bearer " + auth.token()
      //   },
      //   body : JSON.stringify(body)
      // }).then(response => {
      //     if(response.status == 200){
      //       this.updateCreditCard();
      //     }
      //     else{
      //       this.setState({creditCardStatus : "We could not validate this address, are you sure this info is correct? To continue with this address click Save again.", CCStatusBarVisible : true});
      //     }
      //   })
      //   .catch(() => {
      //     this.setState({creditCardStatus : "We could not validate this address, are you sure this info is correct? To continue with this address click Save again.", CCStatusBarVisible : true});
      //   });

      
    }
  }

  updateCreditCard(){
    //Check to see if the user is super user and changed their company.
    let companyId = auth.companyId();
    let realCompany = localStorage.getItem("actualCompanyId");
    if(companyId != realCompany){
      return;
    }

    let body = {
      UserId: auth.employeeId(),
      Number: this.state.cardNumber,
      ExpMonth: this.state.cardMonth,
      ExpYear: this.state.cardYear,
      Cvc: this.state.CVC,
      CompanyId: companyId,
      SubscriptionId: this.state.subID,
      BillingAddressLine1: this.state.billingAddressLine1.trim(),
      BillingAddressLine2: this.state.billingAddressLine2.trim() != "" ? this.state.billingAddressLine2 : null ,
      BillingAddressCity: this.state.billingAddressCity.trim(),
      BillingAddressState: this.state.billingAddressState,
      BillingAddressZip: this.state.billingAddressZip,
      BillingAddressPhone: this.state.billingAddressPhone
    };
    
    let req = new XMLHttpRequest();
    req.open( "POST", process.env.API_URL + "/api/account/updatepayment", true);
    req.setRequestHeader("Content-Type", "application/json");
    req.setRequestHeader("Accept", "application/json");
    req.setRequestHeader("Authorization", "Bearer " + auth.token());
    req.onload =() => {

      if(req.status == 200){
        let response = JSON.parse(JSON.parse(req.responseText));
        if(response.ResponseType == 0){
          let modal = document.getElementById("addCard");
          this.setState(
            { loading : false, 
              noDefault: false, 
              cardInfo: response.PaymentMethod.card, 
              showButtons:true, 
              creditCardStatus: "", 
              updateStatusText: "Payment Method Updated",
              CCStatusBarVisible: false,
              alertSeverity:"success",
            });
          this.clearCardInfo();
          modal.style.display = "none";
        }        
        else{
          let errorMessages = "";
          for(let i =0; i < response.ResponseMessageCollection.length; i++){
            if(response.ResponseMessageCollection[i].Message != null && response.ResponseMessageCollection[i].Message != undefined && response.ResponseMessageCollection[i].Message != "undefined"){
              errorMessages += "\n" + response.ResponseMessageCollection[i].Message;
            }
          }
          this.setState({loading : false, creditCardStatus : "There was a problem updating your information." + errorMessages});
        }
        
      }
      else{
        this.setState({loading : false, creditCardStatus : "Error updating payment information. Try again."});
      }
    }
    req.onerror = () =>{
      this.setState({loading : false, creditCardStatus : "Error updating payment information. Try again."});
    }
    req.send(JSON.stringify(body));
    this.setState({loading : true, creditCardStatus : "UPDATING PAYMENT INFORMATION...", CCStatusBarVisible:true});
  }

  loadArchiveHistory(){
    this.setState({loadingArchiveHistory : true});
    let body = {
      UserId : auth.userId(),
      CompanyId : auth.companyId()
    }
    fetch(process.env.API_URL + "/api/data/archiveHistory",{
      method : "POST",
      headers :{
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        Authorization: "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    }).then(r => {
      if (r.status == 200) {
        r.json().then(responseJson => {
          if (responseJson.length > 0) {
            // PARSE RESULTS | into table format
            let response = JSON.parse(responseJson);
            this.parseResultSet(response);
          }
        });
      }
    })
    .catch(error => {
      console.log(error.message);
      this.setState({loadingArchiveHistory : false, updateStatusText : "Error retrieving archived data. Try again.", alertSeverity : "error"});
    });
  }

  parseResultSet(response){
    if(response == null || response == undefined || Object.entries(response).length == 0){
      this.setState({loadingArchiveHistory : false, updateStatusText : "No archived data returned.",alertSeverity:"success"});
      return;
    }
    let resultDataIn = [];
    let totalCount = response.SiteSurveyCollection.length + response.InvestigationCollection.length + 
    response.GPSSurveyCollection.FormDataCollection.length + response.LeakSurveyCollection.length;
    if(response.SiteSurveyCollection){
      response.SiteSurveyCollection.forEach((element) =>{
        element.SurveyType = "Site Survey";
        element.EntityId = element.SiteSurveyId
        element.SortableTimestamp = new Date(element.DeletedDate + "Z").valueOf();
        element.DeletedDateFormatted = new Date(element.DeletedDate[element.DeletedDate.length]-1 !="Z" ? element.DeletedDate + "Z" : element.DeletedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
        element.UnArchive = <button className="anchorButton padded" onClick={() => {this.unArchive(element);}}><div className="text-center full">UnArchive</div></button>;
        if(element.FormData){
          element.SurveyType = "Custom " + element.SurveyType;
          element.FormName = element.FormData.Name;
          element.EntityId = element.FormData.FormDataId;
        }
        resultDataIn.push(element);
      });
    }
    if(response.InvestigationCollection){
      response.InvestigationCollection.forEach((element) =>{
        element.SurveyType = "Investigation";
        element.EntityId = element.InvestigationId;
        element.SortableTimestamp = new Date(element.DeletedDate + "Z").valueOf();
        element.DeletedDateFormatted = new Date(element.DeletedDate[element.DeletedDate.length]-1 !="Z" ? element.DeletedDate + "Z" : element.DeletedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
        element.UnArchive = <button className="anchorButton padded" onClick={() => {this.unArchive(element);}}><div className="text-center full">UnArchive</div></button>;
        if(element.FormData){
          element.SurveyType = "Custom " + element.SurveyType;
          element.FormName = element.FormData.Name;
          element.EntityId = element.FormData.FormDataId;
        }
        resultDataIn.push(element);
      });
    }
    if(response.LeakSurveyCollection){
      response.LeakSurveyCollection.forEach((element) =>{
        element.SurveyType = "GIS Survey";
        element.EntityId = element.LeakSurveyId;
        element.SortableTimestamp = new Date(element.DeletedDate + "Z").valueOf();
        element.DeletedDateFormatted = new Date(element.DeletedDate[element.DeletedDate.length]-1 !="Z" ? element.DeletedDate + "Z" : element.DeletedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
        element.UnArchive = <button className="anchorButton padded" onClick={() => {this.unArchive(element);}}><div className="text-center full">UnArchive</div></button>;
        if(element.FormData){
          element.SurveyType = "Custom " + element.SurveyType;
          element.FormName = element.FormData.Name;
          element.EntityId = element.FormData.FormDataId;
        }
        resultDataIn.push(element);
      });
    }
    if(response.GPSSurveyCollection && response.GPSSurveyCollection.FormDataCollection){
      response.GPSSurveyCollection.FormDataCollection.forEach((element) =>{
        element.SurveyType = "GPS Survey";
        element.FormName = element.Name;
        element.EntityId = element.FormDataId;
        element.SortableTimestamp = new Date(element.DeletedDate + "Z").valueOf();
        element.UnArchive = <button className="anchorButton padded" onClick={() => {this.unArchive(element);}}><div className="text-center full">UnArchive</div></button>;
        element.DeletedDateFormatted = new Date(element.DeletedDate[element.DeletedDate.length]-1 !="Z" ? element.DeletedDate + "Z" : element.DeletedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
        resultDataIn.push(element);
      });
    }
    if(response.TagCollection && response.TagCollection.FormDataCollection){
      response.TagCollection.FormDataCollection.forEach((element) =>{
        element.SurveyType = "Tag";
        element.FormName = element.Name;
        element.EntityId = element.FormDataId;
        element.SortableTimestamp = new Date(element.DeletedDate + "Z").valueOf();
        element.UnArchive = <button className="anchorButton padded" onClick={() => {this.unArchive(element);}}><div className="text-center full">UnArchive</div></button>;
        element.DeletedDateFormatted = new Date(element.DeletedDate[element.DeletedDate.length]-1 !="Z" ? element.DeletedDate + "Z" : element.DeletedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
        resultDataIn.push(element);
      });
    }
    this.setState({loadingArchiveHistory : false, updateStatusText : "Retrived " + totalCount + " archived records.", alertSeverity : "success", archiveData : resultDataIn});
  }

  handleUnarchivedSort = (filters) =>{
    // Finds "UPDATED" column in reporting data table and resets className
    if (filters.column !== "SortableTimestamp"){
      let element = document.querySelector("#siteright-rendered-timestamp");
      if(element) {element.className = "sorting";}
    }
  }

  unArchive(element){
    let now = new Date();
    let url = process.env.API_URL;
    let body ={
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      NewTaskFromWeb : true,
      UnArchiveRequest : true
    }
    if(element.FormData){
      element.FormData.DeletedBy = null;
      element.FormData.DeletedDate = null;
      element.FormData.DeletedDateUTCOffset = null;
      element.FormData.ModifiedBy = auth.userId();
      element.FormData.ModifiedDate = now;
      element.FormData.ModifiedDateUTCOffset = - now.getTimezoneOffset() / 60;
      element.FormData.FieldsToSave = element.FormData.Fields;
      url += "/api/form/save";
      body.FormData = element.FormData;
    }
    else{
      element.DeletedBy = null;
      element.DeletedDate = null;
      element.DeletedDateUTCOffset = null;
      element.ModifiedBy = auth.userId();
      element.ModifiedDate = now;
      element.ModifiedDateUTCOffset = - now.getTimezoneOffset() / 60;
      if(element.SurveyType == "GPS Survey" || element.SurveyType == "Tag"){
        element.FieldsToSave = element.Fields;
        body.FormData = element;
        url += "/api/form/save";
      }
      if(element.SurveyType == "GIS Survey"){
        body.LeakSurvey = element;
        url += "/api/LeakSurvey/save";
      }
      else if(element.SurveyType == "Investigation"){
        body.Investigation = element;
        url += "/api/investigation/save";
      }
      else if(element.SurveyType == "Site Survey"){
        body.SiteSurvey = element;
        url += "/api/survey/save";
      }
    }
    fetch(url,{
      method : "POST",
      headers : {
        Accept : "application/json;",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then((response => response))
    .then((response) =>{
      if(response.status == 200){
        response.json().then(() =>{
          let archiveData = this.state.archiveData.filter((item) =>{
            return item !== element;
          });
          this.setState({updateStatusText : "Successfully unarchived record." , alertSeverity:"success", archiveData});
        });
      }
      else{
        this.setState({updateStatusText : "There was a problem unarchiving this record. Try again. " , alertSeverity:"error"});
      }
    })
    .catch(error =>{
      this.setState({updateStatusText : "There was a problem unarchiving this record: " + error, alertSeverity:"error"});
    })
  }

  getLogo(assetId) {
    this.setState({ loadingLogo: true });
    fetch(process.env.API_URL + "/api/image/" + assetId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token()
      }
    }).then(r => r.blob()).then(blob => {
        let img = document.getElementById("logo");
        let reader = new FileReader();
        let baseString;
        if (img != null)
        {
          reader.onloadend = () =>{
            baseString = reader.result;
            img.src = baseString;
            this.setState({ logoImage: baseString });
          }
        }
        reader.readAsDataURL(blob);
          this.setState({ loadingLogo: false });
      })
      .catch(error => {
        console.log(error.message);
      });
  }
  
  getAccountInformation() {
    
    let req = new XMLHttpRequest();
    let body = { CompanyId : auth.companyId(), UserID: auth.userId() };
    req.open("POST", process.env.API_URL + "/api/account/account", true );
    req.setRequestHeader("Content-Type", "application/json");
    req.setRequestHeader("Accept", "application/json");
    req.setRequestHeader("Authorization", "Bearer " + auth.token());
    req.onload = () =>{
      
      if (req.status == 200) {
        
        let response = JSON.parse(JSON.parse(req.responseText));
        let invoices = response.InvoiceCollection;
        let payments = [];
        
        // PAYMENT HISTORY
        if (invoices != undefined && invoices != null) {
          for (let i = 0; i < invoices.length; i++){
            let date = new Date(0);
            date.setUTCSeconds(invoices[i].created);
            payments.push({
              date: date.toISOString(),
              amount : invoices[i].amount_due / 100,
              invoice : invoices[i].invoice_pdf
            });
          }
        }

        let subs = response.Customer.subscriptions.data;
        let date = new Date(0);
        let defaultId = null;
        let subDate = '';
        let subscriptions = {quantity:0,plan:{nickname:'trial'},items:{data:[{id:'',product:''}]}};
        var subStatus = "Active";
        var collectionMethod = "";
        let trialMode = false;

        if (subs.length > 0) {          
          
          // Get most recent subscriptions. Can not be sure that there will only be one sub in list.
          subs.sort((a,b) => a.current_period_start - b.current_period_start)
          subscriptions = subs[subs.length -1];

          date.setUTCSeconds(subscriptions.current_period_end);
          defaultId = subscriptions.default_payment_method;
          subDate = date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
          var subId = subs[0].plan != null ? subs[0].plan.id : 'active';

          collectionMethod = subs[0].collection_method == 'charge_automatically' ? 'Automatic Payment' : 'Manual Payment - Invoice sent'; 
          subStatus = subs[0].status == 'past_due' ? 'Past Due' : 'Active' ;

          // SUBSCRIPTION STATUS LABEL (active, cancelled, or trial)
          if (subId == "trial" || (subs[0].plan != null && 
            (subs[0].plan.id == 'price_1KbRvmAAlcrTfXAihFVowTrc' || subs[0].plan.id == 'price_1KosfMAAlcrTfXAiQBFKFXKj' || subs[0].plan.id == 'price_1PWPvs2nSdUv22fAsXc7DPW1' || subs[0].plan.id == 'price_1PVhWz2nSdUv22fAz2es4CpU'))) 
            { subStatus = "in a TRIAL, and will expire on "; trialMode = true; }
          else if (subs[0].cancel_at_period_end == true) { subStatus = "CANCELLED, but will remain ACTIVE until end of invoice period"; }
        }
        else
        {
          if (response.Company.SubscriptionPlanId == "TicketingAndSurvey")
            subStatus = "Enterprise Ticketing System with Survey";
          else if (response.Company.SubscriptionPlanId == "TicketingAndSurveyAndInvestigation")
            subStatus = "Enterprise Ticketing System with Survey and Investigation";
          else 
            subStatus = "None";
        }

        // PAYMENT HISTORY
        let cards = response.PaymentCollection;
        let cardInfo;
        if (cards != null){
          for (let i = 0; i<cards.length; i++) {
            if (cards[i].id == defaultId || defaultId == null) {
              cardInfo = cards[i].card;
            }
          }
        }

        let showButtons = true;
        let noDefault = false;
        
        // DEFAULT PAYMENT not found, disable license update until payment method is collected
        if (cardInfo == null) {
          showButtons = false;
          noDefault = true;
        }
        let callCenters = [];
        if(response.CallCenterConfig && response.CallCenterConfig != ""){
          try{
            let configs = response.CallCenterConfig.split('|');
            configs.forEach(config =>{
              let settings = config.split(',');
              callCenters.push({CallCenterId : settings[0],CallCenterName : settings[1]});
            });
          }
          catch(e){
            callCenters = [];
          }
        }
        callCenters.sort(function(a, b) {
          var textA = a.CallCenterName.toUpperCase();
          var textB = b.CallCenterName.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      let operationalDays = [];
      if(response.Company.OperationalDays){
        try{
          response.Company.OperationalDays.split(',').forEach(day =>{
            operationalDays.push(day);
          });
        }
        catch(e){
          //EAT
        }
      }
        this.setState({
          SubscriptionPlanId: response.Company.SubscriptionPlanId,
          CallCenters : callCenters,
          //selectedCallCenter : callCenters[0],
          CompanyCallCenterConfigs : response.CompanyCallCenterConfigCollection,
          payments, 
          subscriptionActiveDate : subDate,
          invoiceDueDate : subDate,
          plan : subscriptions.object,
          cardInfo,
          subStatus,
          collectionMethod,
          subID : subscriptions.id,
          showButtons,
          noDefault,
          statusText: '',
          dirtKey : response.Company.DirtKey != null ? response.Company.DirtKey : "",
          companyTimezone : response.Company.Timezone,
          companyHourStart : response.Company.OperationalHourStart,
          companyAfterHoursEmail : response.Company.AfterHoursAlertEmail,
          companyHourEnd : response.Company.OperationalHourEnd,
          companyDays : operationalDays,
          dirtSecret : response.Company.DirtHash != null ? response.Company.DirtHash : "",
          company: response.Company,
          ARCGISClientId : response.Company.ARCGISClientId ? response.Company.ARCGISClientId : "",
          ARCGISClientSecret : response.Company.ARCGISClientSecret ? response.Company.ARCGISClientSecret : "",
          ARCGISEnterpriseLayerListUrl : response.Company.ARCGISEnterpriseLayerListUrl ? response.Company.ARCGISEnterpriseLayerListUrl : "",
          ARCGISEnterpriseUrl: response.Company.ARCGISEnterpriseUrl ? response.Company.ARCGISEnterpriseUrl : "",
          ARCGISEnterprisePortalUrl: response.Company.ARCGISEnterprisePortalUrl ? response.Company.ARCGISEnterprisePortalUrl : "",
          useArcGisEnterprise: response.Company.ARCGISEnterpriseUrl ? true : false,
          validArcGisKeys : response.Company.ARCGISClientId && response.Company.ARCGISClientId != "" && response.Company.ARCGISClientSecret &&response.Company.ARCGISClientSecret != null,
          licenseMeta: response.LicenseMetadata,
          subscriptionStatus: subStatus,
          trialMode,
          subscriptionId: subId,
          billingAddressLine1: response.Company.StreetAddressLine1 ? response.Company.StreetAddressLine1 : "",
          billingAddressLine2: response.Company.StreetAddressLine2 ? response.Company.StreetAddressLine2 : "",
          billingAddressCity: response.Company.City ? response.Company.City : "",
          billingAddressState: response.Company.State ? response.Company.State : "",
          billingAddressZip: response.Company.PostalCode ? response.Company.PostalCode : "",
          billingAddressPhone: response.Company.Phone ? response.Company.Phone : "",
          couponCode: response.AppliedDiscount && response.AppliedDiscount.promotion_code ? response.AppliedDiscount.promotion_code.code : "",
          couponCodeApplied: response.AppliedDiscount != null && response.AppliedDiscount.promotion_code != null,
          couponCodeExpirationDate: response.AppliedDiscount && response.AppliedDiscount.end != null ? new Date(response.AppliedDiscount.end * 1000).toLocaleDateString() : new Date().toLocaleDateString(),
          couponCodeName: response.AppliedDiscount && response.AppliedDiscount.coupon ? response.AppliedDiscount.coupon.name : "",
          couponCodeAppliesToProductIds: response.AppliedDiscount && response.AppliedDiscount.coupon ? response.AppliedDiscount.coupon.applies_to.products : [],
          couponCodePercentOff: response.AppliedDiscount && response.AppliedDiscount.coupon ? response.AppliedDiscount.coupon.percent_off : 0,
          logoId : response.Company.Logo != null ? response.Company.Logo.MultimediaAssetId : null,
          hideBaseSurvey : response.Company.HideBaseSurvey,
          hideBaseInvestigation : response.Company.HideBaseInvestigation
        });

        var gpsCount=0, investigationCount=0, leakCount=0, siteCount=0, sitesafeCount=0, sitesafescanCount=0;
        let customFormsLicense = false;
        let ticketModuleLicense = false;
        if (response.Company != undefined){

          let customFormsObj = response.Company.LicenseCollection && response.Company.LicenseCollection.find(x => x.LicenseId == 'customforms');
          if(customFormsObj){
            customFormsLicense = customFormsObj.LicenseAmount == 1;
            this.setState({ hasCustomFormLicense: customFormsLicense });
          }

          let ticketModuleObj = response.Company.LicenseCollection && response.Company.LicenseCollection.find(x => x.LicenseId == 'ticket');
          if(ticketModuleObj){
            ticketModuleLicense = ticketModuleObj.LicenseAmount == 1;
            this.setState({ hasTicketModuleLicense: ticketModuleLicense});
          }

          response.Company.LicenseCollection.forEach(element => {
            if (element.LicenseId == 'gps') gpsCount = element.LicenseAmount
            if (element.LicenseId == 'investigation') investigationCount = element.LicenseAmount
            if (element.LicenseId == 'leak') leakCount = element.LicenseAmount
            if (element.LicenseId == 'site') siteCount = element.LicenseAmount
            if (element.LicenseId == 'sitesafe') sitesafeCount = element.LicenseAmount
            if (element.LicenseId == 'sitesafescan') sitesafescanCount = element.LicenseAmount
          });

          this.setState({customFormsLicense, customFormsEnabled :customFormsLicense,ticketModuleLicense, ticketModuleEnabled: ticketModuleLicense,licenseGps: gpsCount, licenseInvestigation: investigationCount, licenseLeak: leakCount, licenseSite: siteCount, licenseScreening: sitesafeCount, licenseScreeningGuest: sitesafescanCount});
          if(!customFormsObj && auth.licenses().includes('customforms')){ //Trial mode will have this but not a license object
            this.setState({hasCustomFormLicense: true, customFormsEnabled : true});
          }
        }

        // CALCULATE MONTH TOTAL
        this.updateLicenseTotal();

        // If in Trial mode, update to Basic
        if (this.state.plan == "Trial" || this.state.plan == "trial") this.setState({plan:"basic"});

        let logo = response.Company.Logo;
        if (logo != null && logo.MultimediaAssetId != null) {
          this.getLogo(logo.MultimediaAssetId);
        }

      }else if(req.status == 403){
        //this.setState({statusText : "Not authorized to view this page"});
      }else{
        this.setState({statusText : "There was an error retrieving your information."});
      }
    }
    req.onerror = () =>{
      this.setState({statusText :"There was an error retrieving your information."});
    }
    req.send(JSON.stringify(body));
    this.setState({statusText: "Loading Account Information..."});
  }

  selectUserForAfterHours(e){
    if(e.target.value == -1){
      this.setState({userSelectedForAfterHours : {UserId : -1,DisplayNameProper : ""}});
    }
    else{
      let user = this.state.users.find(x => x.UserId == e.target.value);
      this.setState({userSelectedForAfterHours : user});
    }
  }

  selectTicketAreaFeatureForUser(feature){
    let areas = this.state.afterHoursAssignmentAreas;
    let otherUserArea = null;
    if(feature.CompanyArcGISLayerId && feature.FeatureId)otherUserArea = areas.find(x => x.DeletedDate == null && x.UserId != this.state.userSelectedForAfterHours.UserId && x.CompanyArcGISLayerId == feature.CompanyArcGISLayerId && x.FeatureId == feature.FeatureId);
    else if(feature.AssignmentAreaId)otherUserArea = areas.find(x => x.DeletedDate == null && x.UserId != this.state.userSelectedForAfterHours.UserId && x.AssignmentAreaId == feature.AssignmentAreaId);
    if(otherUserArea){
      this.setState({updateStatusText : "This ticket area is already assigned.", alertSeverity : "warning"});
    }
    else{
      let date = new Date();
      if(this.state.userSelectedForAfterHours.UserId != -1){
        var existingArea = null;
        if(feature.CompanyArcGISLayerId && feature.FeatureId)existingArea = areas.find(x => x.UserId == this.state.userSelectedForAfterHours.UserId && x.CompanyArcGISLayerId == feature.CompanyArcGISLayerId && x.FeatureId == feature.FeatureId);
        else if(feature.AssignmentAreaId)existingArea = areas.find(x => x.UserId == this.state.userSelectedForAfterHours.UserId && x.AssignmentAreaId == feature.AssignmentAreaId);
        if(!existingArea){
          areas.push({
            AfterHoursAssignmentAreaId : -1,
            UserId : this.state.userSelectedForAfterHours.UserId,
            CompanyArcGISLayerId : feature.CompanyArcGISLayerId,
            CompanyId : auth.companyId(),
            FeatureId : feature.FeatureId,
            AssignmentAreaId : feature.AssignmentAreaId,
            CreatedDate : date,
            CreatedDateUTCOffset : - (date.getTimezoneOffset()/60),
            CreatedBy : auth.employeeId()
          });
        }
        else if(existingArea.AfterHoursAssignmentAreaId == -1){
          var index = -1;
          if(feature.CompanyArcGISLayerId && feature.FeatureId)index = areas.findIndex(x => x.UserId == this.state.userSelectedForAfterHours.UserId && x.CompanyArcGISLayerId == feature.CompanyArcGISLayerId && x.FeatureId == feature.FeatureId);
          else if(feature.AssignmentAreaId)index = areas.findIndex(x => x.UserId == this.state.userSelectedForAfterHours.UserId && x.AssignmentAreaId == feature.AssignmentAreaId);
          areas.splice(index,1);
        }
        else{
          //This assignment has been saved before, need to check deleted dates.
          if(existingArea.DeletedDate){
            existingArea.DeletedDate = null;
            existingArea.DeletedDateUTCOffset = null;
            existingArea.DeletedBy = null;
          }
          else{
            existingArea.DeletedDate = date;
            existingArea.DeletedDateUTCOffset = - (date.getTimezoneOffset()/60);
            existingArea.DeletedBy = auth.employeeId();
          }
        }
      }
      this.setState({afterHoursAssignmentAreas : areas});
    }
    
  }

  selectUserToSeeAssignmentArea(e){
    let user = this.state.users.find(x => x.UserId == e.target.value);
    let userAssignmentAreasSelected = this.state.userAssignmentAreas.filter(x => x.UserId == e.target.value);
    this.setState({userSelected: user, userAssignmentAreasSelected});
    console.log('userSelected', user);
    console.log('userAssignmentAreasSelected', userAssignmentAreasSelected);
  }

  selectAssignmentAreaForUser(area, e){
    let userSelected = this.state.userSelected;
    let userAssignmentAreas = this.state.userAssignmentAreas;
    let userInArea = this.state.userAssignmentAreas.findIndex(x => x.UserId == userSelected.UserId && 
      (area.AssignmentAreaId ? x.AssignmentAreaId == area.AssignmentAreaId : ((x.CompanyArcGisLayerId == area.CompanyArcGISLayerId || x.CompanyArcGISLayerId == area.CompanyArcGISLayerId) && x.FeatureId == area.FeatureId)));

    console.log('area', area);

    if (userInArea > -1){
      userAssignmentAreas.splice(userInArea, 1);
    }
    else
    {
      const newArea = JSON.parse(JSON.stringify(area));
      newArea.UserId = userSelected.UserId;
      newArea.CompanyId = userSelected.CompanyId;
      console.log('addArea', newArea);
      userAssignmentAreas = userAssignmentAreas.concat(newArea);
    }
    
    console.log('assignmentAreas', this.state.assignmentAreas);
    console.log('userAssignmentAreas', userAssignmentAreas);
    this.setState({userAssignmentAreas});
  }

  saveDataUserAssignmentArea(){
    
    if(auth.isSuperViewer()){
      console.log('denied');
      return;
    }

    this.setState({ statusText : 'SAVING AREAS...'});
    let body={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      UTCOffset : - (new Date().getTimezoneOffset()/60),
      UserAssignmentAreas : this.state.userAssignmentAreas
    }
    fetch(process.env.API_URL + "/api/account/updateUserAssignmentAreas",{
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            console.log('updateUserAssignmentAreas()_response', json);
            this.setState({statusText : "", updateStatusText : "User Group Assignment saved.", alertSeverity : "success"});
          }
          else{
            this.setState({statusText : "", updateStatusText : "Something went wrong. Try again.", alertSeverity : "error"});
          }
        });
      }
      else{
        this.setState({statusText : "", updateStatusText : "Something went wrong. Try again.", alertSeverity : "error"});
      }
    })
    .catch(error =>{
      console.log(error.message);
      this.setState({statusText :"", updateStatusText : "Something went wrong. Try again.", alertSeverity : "error"});
    });
  }

  getRequiredTasks(){
    this.setState({ loadRequiredTasks : true });
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }
    fetch(process.env.API_URL + "/api/account/getRequiredTasks",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          this.setState({RequiredTasks : json.RequiredTasks, loadRequiredTasks : false});
        });
      }
      else{
        this.setState({loadRequiredTasks : false});
      }
    })
    .catch(error =>{
      this.setState({loadRequiredTasks : false});
      console.log(error.message);
    })
  }

  updateRequiredTasks(){
    if(auth.isSuperViewer()){
      return;
    }
    // let companyId = auth.companyId();
    // let realCompany = localStorage.getItem("actualCompanyId");
    // if(companyId != realCompany){
    //   return;
    // }
    this.setState({loadRequiredTasks : true});
    let RequiredTasks = this.state.RequiredTasks;
    RequiredTasks.forEach(task =>{
      let Groups = '';
      task.GroupsList.forEach((group,index) =>{
        Groups += group;
        if(index != task.GroupsList.length -1){
          Groups +=  ','
        }
      });
      task.Groups = Groups;
    });
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      RequiredTasks,
      UTCOffset : - (new Date().getTimezoneOffset()/60)
    }
    fetch(process.env.API_URL + "/api/account/updateRequiredTasks",{
      method: "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({RequiredTasks : json.RequiredTasks, updateStatusText : "Updated Required Tasks", loadRequiredTasks : false, alertSeverity : "success"});
          }
          else{
            this.setState({updateStatusText : "There was a problem updating your Required Tasks. Try again.", loadRequiredTasks : false, alertSeverity : "error"});
          }
        })
      }
      else{
        this.setState({updateStatusText : "There was a problem updating your Required Tasks. Try again.", loadRequiredTasks : false, alertSeverity : "error"});
      }
    })
    .catch(err =>{
      this.setState({updateStatusText : "There was a problem updating your Required Tasks. Try again. "+ err.message, loadRequiredTasks : false, alertSeverity : "error"});
    })
  }

  addRequiredTask(){
    if(auth.isSuperViewer()){
      return;
    }
    let RequiredTasks = this.state.RequiredTasks;
    RequiredTasks.push({
      RequiredTaskId : -1,
      GroupsList : [],
      CompanyId : auth.companyId(),
      CreatedBy : auth.employeeId(),
      CreatedDate : new Date(),
      CreatedDateUTCOffset : - (new Date().getTimezoneOffset()/60)
    });
    this.setState({RequiredTasks});
  }

  handleCloseRequiredTaskWarning(){
    this.setState({showDeleteRequiredTaskWarning : false});
  }

  deleteRequiredTask(index){
    if(auth.isSuperViewer()){
      return;
    }
    let RequiredTasks = this.state.RequiredTasks;
    RequiredTasks[index].DeletedDate = new Date();
    RequiredTasks[index].DeletedBy = auth.employeeId();
    RequiredTasks[index].DeletedDateUTCOffset = - new Date().getTimezoneOffset() / 60;
    this.setState({RequiredTasks});
  }

  selectGroupForRequiredTask(group, taskIndex){
    let RequiredTasks = this.state.RequiredTasks;
    if(group.CompanyGroupId == -1){
      let currentGroupLength = RequiredTasks[taskIndex].GroupsList.length
      RequiredTasks[taskIndex].GroupsList = [];
      if( currentGroupLength != this.state.companyGroups.length -1){
        this.state.companyGroups.forEach(g =>{
          if(g.CompanyGroupId != -1){
            RequiredTasks[taskIndex].GroupsList.push(g.CompanyGroupId);
          }
        }); 
      }
    }
    else{
      let groupIndex = RequiredTasks[taskIndex].GroupsList.findIndex(x => x == group.CompanyGroupId);
      if(groupIndex != -1){
        RequiredTasks[taskIndex].GroupsList.splice(groupIndex,1);
      }
      else{
        RequiredTasks[taskIndex].GroupsList.push(group.CompanyGroupId);
      }
    }  
    this.setState({RequiredTasks});
  }

  toggleToDatePicker = () => {
    this.setState({ billingReportToPickerOpen: !this.state.billingReportToPickerOpen });
  };
  
  toggleFromDatePicker = () => {
    this.setState({ billingReportFromPickerOpen: !this.state.billingReportFromPickerOpen });
  };


  toHandleChange = selectedDate => {
    selectedDate.setHours(new Date().getHours())
    selectedDate.setTime(selectedDate.getTime());
    this.setState({ billingReportDateTo: selectedDate.toLocaleDateString()});
    this.toggleToDatePicker();
  };


  fromHandleChange = selectedDate => {
    selectedDate.setHours(new Date().getHours())
    selectedDate.setTime(selectedDate.getTime());
    this.setState({ billingReportDateFrom: selectedDate.toLocaleDateString() });
    this.toggleFromDatePicker();
  };

  generateBillingReport() {

    let me = this;
    me.setState({ loadingBillingReport: true });

    let fromDate = new Date(this.state.billingReportDateFrom);
    let toDate = new Date(this.state.billingReportDateTo);
    fromDate = moment(fromDate).add({minutes: fromDate.getTimezoneOffset()}).toDate();
    toDate = moment(toDate).add({hours: 23, minutes: 59, seconds: 59, milliseconds: 999}).add({minutes: toDate.getTimezoneOffset()}).toDate();

    console.log(toDate.getTimezoneOffset());

    let body = {
      UserId: auth.employeeId(),
      CompanyId: auth.companyId(),
      FromDate: fromDate.toLocaleString(),
      ToDate: toDate.toLocaleString(),
      Offset: toDate.getTimezoneOffset()
    };

    fetch(process.env.API_URL + "/api/data/DownloadBillingReports", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(r => {
      if (r.status == 200) {
        r.blob().then(blob =>{
          let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display:none";
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = blob.type == "application/pdf" ? "SiteRight_Data.pdf" : "SiteRight_Data.zip";
            a.click();
            window.URL.revokeObjectURL(url);
            this.setState({updateStatusText : "Report Generation Success", alertSeverity:"success", loadingBillingReport: false});
        }).catch(error => { console.log(error.message);this.setState({updateStatusText : "Report Generation Failed - Try again or contact support", alertSeverity:"error", loadingBillingReport: false});});
      }
      else{
        me.setState({ updateStatusText : "Report Generation Failed - Try again or contact support", alertSeverity:"error", loadingBillingReport: false });
      }
    })
    .catch(error => {
      console.log(error.message);
      me.setState({ updateStatusText : "Report Generation Failed - Try again or contact support", alertSeverity:"error", loadingBillingReport: false });
    });
  }
  updateLicenseTotal(updatingCustomForms, updatingTicketModule){
    if (this.state.licenseMeta != undefined)
    {
      let customFormsLicense = this.state.customFormsLicense;
      let ticketModuleLicense = this.state.ticketModuleLicense;
      if(updatingCustomForms){
        customFormsLicense = !customFormsLicense;
        this.setState({customFormsLicense});
      }
      if(updatingTicketModule){
        ticketModuleLicense = !ticketModuleLicense;
        this.setState({ticketModuleLicense});
      }
      var s = this.state;
      var l = s.licenseMeta;
      var priceTotal = (s.licenseSite * l.Site.Price) + (s.licenseInvestigation * l.Investigation.Price) + (s.licenseGps * l.GPS.Price) + (s.licenseLeak * l.Leak.Price) + (s.licenseScreeningGuest * l.SiteScan.Price) + ((s.licenseScreening > 0 ? (s.licenseScreening-1) * 10 : 0) + (s.licenseScreening > 0 ? 100 : 0) + (customFormsLicense && l.CustomForms.Price ? l.CustomForms.Price : 0) + (ticketModuleLicense && l.TicketModule.Price ? l.TicketModule.Price : 0));
      if (priceTotal.toString() == "NaN") priceTotal = 0;
      
      let finalDiscountTotal = 0;

      if(this.state.couponCodeApplied && this.state.couponCodeAppliesToProductIds.length > 0){
        let totalDiscount = 0;
        this.state.couponCodeAppliesToProductIds.forEach((productId)=>{
          let discountPerLicense = 0;
          if(l.Site.ProductId == productId){
            discountPerLicense = (this.state.couponCodePercentOff * .01)  * l.Site.Price;
            totalDiscount += discountPerLicense * s.licenseSite;
          } else if(l.GPS.ProductId == productId){
            discountPerLicense = (this.state.couponCodePercentOff * .01)  * l.GPS.Price;
            totalDiscount += discountPerLicense * s.licenseGps;
          } else if(l.Investigation.ProductId == productId){
            discountPerLicense = (this.state.couponCodePercentOff * .01)  * l.Investigation.Price;
            totalDiscount += discountPerLicense * s.licenseInvestigation;
          } else if(l.Leak.ProductId == productId){
            discountPerLicense = (this.state.couponCodePercentOff * .01)  * l.Leak.Price;
            totalDiscount += discountPerLicense * s.licenseLeak;
          } else if(l.EntryScreening.ProductId == productId){
            totalDiscount += (this.state.couponCodePercentOff * .01)  * ((s.licenseScreening > 0 ? (s.licenseScreening-1) * 10 : 0) + (s.licenseScreening > 0 ? 100 : 0));
          } else if(l.SiteScan.ProductId == productId){
            discountPerLicense = (this.state.couponCodePercentOff * .01)  * l.SiteScan.Price;
            totalDiscount += discountPerLicense * s.licenseScreeningGuest;
          } else if(l.CustomForms.ProductId == productId && customFormsLicense){
            totalDiscount += (this.state.couponCodePercentOff * .01) * l.CustomForms.Price
          } else if(l.TicketModule.ProductId == productId && ticketModuleLicense){
            totalDiscount += (this.state.couponCodePercentOff && .01) * l.TicketModule.Price
          }
        });
        finalDiscountTotal = Math.round(totalDiscount * 100) / 100
        priceTotal += -finalDiscountTotal;
      }
      
      this.setState({monthTotal: priceTotal, totalDiscount: finalDiscountTotal, showUpdateButton: true, customFormsLicense, ticketModuleLicense });
    }
  }

  readyNewKMLShapeFile(){
    if (auth.isSuperViewer()){
      return;
    }

    let file = document.querySelector("input[type=file]")["files"][0];
    

    let splitFile = file.name.split('.');
    let fileName = splitFile[0];
    let fileType = splitFile[1];
    if(fileType == "zip"){
      //Only allow files up to 2MB to be uploaded on shape as per esri restrictions
      if(file.size > 2097152){
        this.setState({updateStatusText : "Uploaded file was too large. Shape Files must be less than 2MB", alertSeverity:"error"});
        return;
      }
      this.setState({updatingCompanyKMLShapeLayers :  "Loading File..."});
      fileType = "shape";
      loadModules(['esri/request'])
      .then(([request]) =>{
        let params = {
          name : fileName,
          enforceInputFileSizeLimit: true,
          enforceOutputJsonSizeLimit: true
        }
        params.generalize = true;
        params.maxAllowableOffset = 10;
        params.reducePrecision = true;
        params.numberOfDigitsAfterDecimal = 0;
        let myContent = {
          filetype : "shapefile",
          publishParameters : JSON.stringify(params),
          f : "json"
        }
        let fd = new FormData();
        fd.append('file', file);
        
        request("https://www.arcgis.com/sharing/rest/content/features/generate",{
          query : myContent,
          body : fd,
          responseType : "json"
        })
        .then((response) =>{
          if(response.data.featureCollection){
            let companyKMLShapeLayers = this.state.companyKMLShapeLayers;
            let now = new Date();
            companyKMLShapeLayers.push({CompanyKMLSHAPELayerId : -1, Name : fileName, FileType: fileType,LayerType : 'GPS', ShowOnApp : false, ShowOnWeb : false, CompanyId : auth.companyId(), CreatedDate :  now, CreatedDateUTCOffset : - now.getTimezoneOffset() /60, CreatedBy : auth.employeeId(), ModifiedDate :  now, ModifiedDateUTCOffset : - now.getTimezoneOffset() /60, ModifiedBy : auth.employeeId()})
            this.setState({pendingUploadedFile : response.data.featureCollection, companyKMLShapeLayers, updatingCompanyKMLShapeLayers : ""});
          }
          else{
            this.setState({updateStatusText : "This file was not recognized as a valid Shapefile. Try again.", updatingCompanyKMLShapeLayers : "", alertSeverity:"error"});
          }
        })
        .catch(e =>{
          this.setState({updateStatusText : e.message, updatingCompanyKMLShapeLayers : "", alertSeverity:"error"});
        })
      });
    }
    else{
      //NON KML/SHAPE file uploaded. Shouldn't ever happen since we only show kml and zip in the file input but just in case it does. 
      if(fileType != "kml"){
        this.setState({updateStatusText : "Unrecoginized file type. Only KML and SHAPE files can be uploaded. ", alertSeverity:"error"});
        return;
      }
      //Only allow files up to 100MB to be uploaded on kml
      if(file.size > 104857600){
        this.setState({updateStatusText : "Uploaded file was too large. KML Files must be less than 100MB", alertSeverity:"error"});
        return;
      }
      let companyKMLShapeLayers = this.state.companyKMLShapeLayers;
      let now = new Date();
      companyKMLShapeLayers.push({CompanyKMLSHAPELayerId : -1, Name : fileName, FileType: fileType,LayerType : 'GPS', ShowOnApp : false, ShowOnWeb : false, CompanyId : auth.companyId(), CreatedDate :  now, CreatedDateUTCOffset : - now.getTimezoneOffset() /60, CreatedBy : auth.employeeId(), ModifiedDate :  now, ModifiedDateUTCOffset : - now.getTimezoneOffset() /60, ModifiedBy : auth.employeeId()})
      this.setState({pendingUploadedFile : file, companyKMLShapeLayers});
    }
  }

  readyNewPicture() {
    if (auth.isSuperViewer()){
      return;
    }
    
    // check to see if the user is super user and changed their company.
    // let companyId = auth.companyId();
    // let realCompany = localStorage.getItem("actualCompanyId");
    // if (companyId != realCompany){ return; }
    let img = document.getElementById("logo");
    let file = document.querySelector("input[type=file]")["files"][0];
    let reader = new FileReader();
    let baseString;

    reader.onloadend = () => {
      baseString = reader.result;
      img.src = baseString;
      
      // Need to parse off prefix part when sending up to api. (looks something like: data:image/jpeg;base64,)
      baseString = baseString.split(",")[1];
      let pic = new XMLHttpRequest();
      pic.open('POST', process.env.API_URL + "/api/image",true);
      pic.setRequestHeader("Accept","application/json");
      pic.setRequestHeader("Content-type","application/json");
      pic.setRequestHeader("Authorization","Bearer " + auth.token());
      let body ={
        MultimediaAsset :{
          EntityTypeId : 12,
          EntitiyId : -1,
          ModifiedBy : auth.employeeId(),
          CreatedBy : auth.employeeId(),
          CompanyId : auth.companyId(),
          Timestamp : new Date().getTime()
        },
        Base64Img : baseString,
        FileType : file.type
      }
      pic.onload = () =>{
        if(pic.status == 200){
          let response = JSON.parse(pic.responseText);
          if(response.message != "success"){
            this.setState({updateStatusText : "There was a problem uploading your company logo: " + response.message, alertSeverity:"error"});
          }
          else{
            this.setState({updateStatusText : "Company logo updated", alertSeverity:"success"});
          }  
        }
        else{
          this.setState({updateStatusText : "There was a problem uploading your company logo.", alertSeverity:"error"});
        }
      }
      pic.onerror = () =>{
        this.setState({updateStatusText :"There was a problem uploading your company logo", alertSeverity:"error"});
      }
      pic.send(JSON.stringify(body));
    }
    if(file){
      reader.readAsDataURL(file);
    }
  }

  changeActiveItem = activeItem =>{
    if(activeItem == "Misc" && this.state.logoId != null && this.state.logoId != ""){
      this.getLogo(this.state.logoId);
    }
    this.setState({ activeItem });
    this.props.forceRefresh();
  }

  setFormType(type){
    let currentFormEdit = this.state.currentFormEdit; 
    currentFormEdit.Type = type == "GIS Survey" ? "Pipeline Survey" : type; 
    this.setState({currentFormEdit, showGeometryTypeSelector: type == 'GPS Survey'});
  }

  setFormColor = (color) => {
    if (color){
      let currentFormEdit = this.state.currentFormEdit; 
      currentFormEdit.Color = color.hex ? color.hex : color;
      this.setState({currentFormEdit});
    }
  };

  setGeometryType(type){
    console.log('setGeometryType', type);
    let currentFormEdit = this.state.currentFormEdit; 
    currentFormEdit.GeometryType = type; 
    this.setState({currentFormEdit, showGeometryTypeSelector: type == 'GPS Survey'});
  }
  
  showColor(){
    var showColor = !this.state.showColor;
    this.setState({showColor:showColor})
  }

  showMarkerSelection(){
    var showMarkerSelection = !this.state.showMarkerSelection;
    this.setState({showMarkerSelection});
  }

  showLineMarkerSelection(){
    let showLineMarkerSelection = !this.state.showLineMarkerSelection;
    this.setState({showLineMarkerSelection});
  }

  setLineMarkerSymbol(lineMarkerSymbol){
    let currentFormEdit = this.state.currentFormEdit;
    currentFormEdit.LineMarkerSymbol = lineMarkerSymbol;
    this.setState({currentFormEdit, showLineMarkerSelection : false });
  }
  
  // add or remove utility fields based on boolean switch (includeUtilityFields)
  // addUtilityFields(includeUtilityFields){
  //   let currentFormEdit = this.state.currentFormEdit; 
  //   currentFormEdit.AddUtilityFields = includeUtilityFields;
    
  //   if (includeUtilityFields){
  //     currentFormEdit.Fields.push({Name:this.state.fieldNameLabels["UtilityType"], AllowDelete:false, Type:"Utility Type"})
  //     currentFormEdit.Fields.push({Name:this.state.fieldNameLabels["UtilitySubType"], AllowDelete:false, Type:"Utility SubType"})
  //   }
  //   else{
  //     let uField1Index = -1;
  //     let uField2Index = -1;

  //     for (let index = 0; index < currentFormEdit.Fields.length; index++) {
  //       const element = currentFormEdit.Fields[index];
  //       if (element.Name == this.state.fieldNameLabels["UtilityType"]) uField1Index = index;
  //       if (element.Name == this.state.fieldNameLabels["UtilitySubType"]) uField2Index = index;
  //     }

  //     if (uField1Index > -1) currentFormEdit.Fields.splice(uField1Index, 1);
  //     if (uField2Index > -1) currentFormEdit.Fields.splice(uField2Index-1, 1);
  //   }

  //   this.setState({currentFormEdit})
  // }

  handleClose(){
    // snackbar close 
  }

  onCouponCodeTextInput(e){
    this.setState({couponCode: e.target.value, couponCodeInvalid: false});
  }

  addGPSSurveyGeoDataFieldsToGPSFormFields(formCollection){
    let GeoDataFields = [
      {
        Name: "locateLogNumber",
        IsGeoDataField: true
      },
      {
        Name: "locateFreqHz",
        IsGeoDataField: true
      },
      {
        Name: "locateMesuredDepthMm",
        IsGeoDataField: true
      },
      {
        Name: "locateMeasuredCurrentMa",
        IsGeoDataField: true
      },
      {
        Name: "locateMeasuredSignalDirection",
        IsGeoDataField: true
      },
      {
        Name: "locateView",
        IsGeoDataField: true
      },
      {
        Name: "locateMode",
        IsGeoDataField: true
      },
      {
        Name: "locateGainDb",
        IsGeoDataField: true
      },
      {
        Name: "locateVectorOffset",
        IsGeoDataField: true
      },
      {
        Name: "locateVectorSeparation",
        IsGeoDataField: true
      },
      {
        Name: "locateCompassAngle",
        IsGeoDataField: true
      },
      {
        Name: "locateDistanceFromLastLog",
        IsGeoDataField: true
      },
      {
        Name: "logNumber",
        IsGeoDataField: true
      },
      {
        Name: "positionDateTimeUtc",
        IsGeoDataField: true
      },
      {
        Name: "lat",
        IsGeoDataField: true
      },
      {
        Name: "lon",
        IsGeoDataField: true
      },
      {
        Name: "fix",
        IsGeoDataField: true
      },
      {
        Name: "numberOfSatellites",
        IsGeoDataField: true
      },
      {
        Name: "positionalDilutionOfPrecision",
        IsGeoDataField: true
      },
      {
        Name: "horizontalDilutionOfPrecision",
        IsGeoDataField: true
      },
      {
        Name: "verticalDilutionOfPrecision",
        IsGeoDataField: true
      },
      {
        Name: "altitude",
        IsGeoDataField: true
      },
      {
        Name: "geoidSeparation",
        IsGeoDataField: true
      },
      {
        Name: "standardDeviationLat",
        IsGeoDataField: true
      },
      {
        Name: "standardDeviationLon",
        IsGeoDataField: true
      },
      {
        Name: "standardDeviationAltitude",
        IsGeoDataField: true
      },
      {
        Name: "IsTiltCorrected",
        IsGeoDataField: true
      },
    ]
    for(let i =0; i < formCollection.length; i++){
      if(formCollection[i].FormTypeId == 3){//GPS Survey
        GeoDataFields.forEach((field)=>{
          formCollection[i].Fields.push(field);
        });
        
      }
    }
    return(formCollection);
  }

  addFormDataFieldsToFormFields(formCollection){
    let formDataFields = [
      {
        Name: "Latitude (Form)",
        IsFormDataField: true
      },
      {
        Name: "Longitude (Form)",
        IsFormDataField: true
      },
      {
        Name: "CreatedBy (Form)",
        IsFormDataField: true
      },
      {
        Name: "CreatedDate UTC (Form)",
        IsFormDataField: true
      }
    ]
    for(let i =0; i < formCollection.length; i++){
      formDataFields.forEach((field)=>{
        formCollection[i].Fields.push(field);
      });
    }
    return(formCollection);
  }

  render() {
    let tableData;
    const companyTicketBillingGroupsStringified = JSON.stringify(this.state.companyTicketBillingGroups);
    const companyTicketBillingGroupMemberCodeAssignmentsStringified = JSON.stringify(this.state.companyTicketBillingGroupMemberCodeAssignments);

    let renderRow = (payment, i) => {
      let date = new Date(payment.date);
      if (payment.amount > 0){
        return (
          <tr key={i}>
            <td>
              { date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear() }
            </td>
            <td>{"$" + payment.amount.toFixed(2)}</td>
            <td onClick={() =>{window.open(payment.invoice,'_blank')}}>
              <Button className="buttonAction buttonAccount" style={{height : 30}}>
                <label className="buttonText" style={{paddingTop : 0, position: 'relative', bottom : 4}}>Invoice</label>
              </Button></td>
          </tr>
        );
      }
    };
    const {activeItem} = this.state;
    if(this.state.archiveData && this.state.archiveData.length > 0){
      tableData = {columns : [
          { label : "Survey Type", field: "SurveyType"},
          { label : "Form Name", field: "FormName"},
          { label : "Id", field: "EntityId"},
          { label : "Deleted Date", field: "DeletedDateFormatted", attributes:{
            onClick : async () =>{
              let elm = document.querySelector("#siteright-sortable-timestamp");
              let toSet = document.querySelector("#siteright-rendered-timestamp");
              await elm.click();
              if(toSet){toSet.className = elm.className;}
            },
            id: "siteright-rendered-timestamp"
          }},
          { label : "Deleted By", field: "DeletedByDisplayName"},
          { label : "", field: "UnArchive"},
          { label: "Sortable Timestamp", field: "SortableTimestamp", attributes: { id: "siteright-sortable-timestamp" } },
        ],
        rows: this.state.archiveData
      }
    }
    return (
      <div className="col-10 d-flex flex-column justify-content-center p-0 m-0" style={{ backgroundColor: "#E9E9EF" }}>
        <a ref={this.certLinkRef}/>
        <Tab.Container activeKey={activeItem} onSelect={()=> null} id="user-tab-con">
          <React.Fragment>
          <div className="pageBar p-0 d-flex nowrap">
            {
              (auth.isAdmin() || auth.isSuperAdmin()) && 
              <Link
                to="/configuration/licences"
                //style={activeItem === "licences" ? {cursor: "pointer", borderBottom: "3px solid #16548E"} :  {cursor: "pointer"}}
                onClick={() => this.changeActiveItem("licences")}
                className={activeItem === "licences" ? "labelHeader barHeaderDiv selected pr-5" : "labelHeader barHeaderDiv pr-5" }
              >
                Subscription 	&amp; Licences
              </Link>
            }
            {
              this.state.hasCustomFormLicense && (auth.isAdmin() || auth.isSuperAdmin()) && 
              <Link
                to="/configuration/forms"  
                //style={activeItem === "forms" ? {cursor: "pointer", borderBottom: "3px solid #16548E"} : {cursor : "pointer"}}
                onClick={()=> this.changeActiveItem("forms")}
                className={activeItem === "forms" ? "labelHeader barHeaderDiv selected pr-5" : "labelHeader barHeaderDiv pr-5" }
              >
                Forms
              </Link>
            }
            {
              (auth.isAdmin() || auth.isSuperAdmin()) && 
              <Link
                to="/configuration/dataGroups"
                onClick={() => this.changeActiveItem("dataGroups")}
                className={activeItem === "dataGroups" ? "labelHeader barHeaderDiv selected pr-5" : "labelHeader barHeaderDiv pr-5"}
              >
                Data Groups
              </Link>
            }
            {
              (auth.isAdmin() || auth.isSuperAdmin()) && 
              <Link
                to="/configuration/notifications"
                onClick={()=> this.changeActiveItem("notifications")}
                className={activeItem === "notifications" ? "labelHeader barHeaderDiv selected pr-5" : "labelHeader barHeaderDiv pr-5"} 
                >
                  Data Activity
                </Link>
            }
            { 
            (auth.isAdmin() || auth.isSuperAdmin()) && 
              <Link 
                to="/configuration/GisExport"
                onClick={() => this.changeActiveItem("GisExport")}
                className={activeItem === "GisExport" ? "labelHeader barHeaderDiv selected pr-5" : "labelHeader barHeaderDiv pr-5" }
              >
                GIS Connection
              </Link>
            }
            {
              (auth.isAdmin() || auth.isSuperAdmin()) && 
              <Link
                to="/configuration/tickets"
                onClick={() => this.changeActiveItem("tickets")}
                className={activeItem === "tickets" ? "labelHeader barHeaderDiv selected pr-5" : "labelHeader barHeaderDiv pr-5"}
              >
                Tickets
              </Link>
            }
            {( this.state.hasTicketModuleLicense && (auth.isAdmin() || auth.isSuperAdmin())) &&
              <Link
                to="/configuration/ticket-billing"
                onClick={() => this.changeActiveItem("ticket-billing")}
                className={activeItem === "ticket-billing" ? "labelHeader barHeaderDiv selected pr-5" : "labelHeader barHeaderDiv pr-5"}
              >
                Billing
              </Link>
            }
            {
              <Link 
                to="/configuration/Misc"
                onClick={() => this.changeActiveItem("Misc")}
                className={activeItem === "Misc" ? "labelHeader barHeaderDiv selected pr-5" : "labelHeader barHeaderDiv pr-5" }
              >
                Company
              </Link>
            }
          </div>


          {this.state.statusText == "" ? (
            <div className="container-fluid" >
              <CreditCardModal
                cardName={this.state.cardName}
                cardNumber={this.state.cardNumber}
                cardMonth={this.state.cardMonth}
                cardYear={this.state.cardYear}
                CVC={this.state.CVC}
                statusBarVisible={this.state.CCStatusBarVisible}
                cardNameColor={this.state.cardNameColor}
                cardNumberColor={this.state.cardNumberColor}
                cardYearColor={this.state.cardYearColor}
                cardMonthColor={this.state.cardMonthColor}
                cvcColor={this.state.cvcColor} 
                loading={this.state.loading}
                statusText = {this.state.creditCardStatus}
                clearCardInfo={this.clearCardInfo}
                changeCardMonth={this.changeCardMonth}
                changeCardNumber={this.changeCardNumber}
                changeCardName={this.changeCardName}
                changeCardYear={this.changeCardYear}
                changeCVC={this.changeCVC}
                billingAddressLine1={this.state.billingAddressLine1}
                billingAddressLine2={this.state.billingAddressLine2}
                billingAddressCity={this.state.billingAddressCity}
                billingAddressState={this.state.billingAddressState}
                billingAddressZip={this.state.billingAddressZip}
                billingAddressPhone={this.state.billingAddressPhone}
                changeBillingAddressLine1={this.changeBillingAddressLine1}
                changeBillingAddressLine2={this.changeBillingAddressLine2}
                changeBillingAddressCity={this.changeBillingAddressCity}
                changeBillingAddressState={this.changeBillingAddressState}
                changeBillingAddressZip={this.changeBillingAddressZip}
                changeBillingAddressPhone={this.changeBillingAddressPhone}

                billingAddressLine1Color={this.state.billingAddressLine1Color}
                billingAddressCityColor={this.state.billingAddressCityColor}
                billingAddressStateColor={this.state.billingAddressStateColor}
                billingAddressZipColor={this.state.billingAddressZipColor}
                billingAddressPhoneColor={this.state.billingAddressPhoneColor}

                addCard={this.state.creditCardStatus == "We could not validate this address, are you sure this info is correct? To continue with this address click Save again." ? this.updateCreditCard : this.addCard}
              />

              <div id="accountDiv" className="row accountDiv">

                <Snackbar open={this.state.updateStatusText != "" ? true : false} onClick={() => {this.setState({updateStatusText:''})}} autoHideDuration={6000} onClose={this.handleClose} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant="filled">
                      {this.state.updateStatusText}
                    </Alert>
                </Snackbar>

                <div className="" style={{width:'94%'}}>
                  <Tab.Content className="d-flex flex-column justify-content-center">

                    {
                      <Tab.Pane className="d-flex flex-column w-100 p-0 m-0 justify-content-center" eventKey="dataGroups" id="dataGroups-account-tab" mountOnEnter unmountOnExit>
                        <DataGroupTab updateStatusText={this.setUpdateStatusText} addDataGroup={this.addDataGroup}  forceRefresh={this.props.forceRefresh} groups={this.state.dataGroups} UserGroups={this.state.dataUserGroups} updateUserGroups={this.updateDataUserGroups} expandGroup={this.expandGroup} editGroup ={this.editDataGroup} deleteGroup={this.deleteDataGroup} updateGroups={this.updateDataGroups}/>
                      </Tab.Pane>
                    }
                    {
                      <Tab.Pane className="d-flex flex-column w-100 p-0 m-0 justify-content-center" eventKey="licences" mountOnEnter unmountOnExit>
                        {/* SUBSCRIPTION & LICENCES */}
                          <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10}}>SUBSCRIPTION & LICENCES
                          <div className="labelHeaderBarSubHeader">Update account payment method, Cancel Subscription, Update License Totals, and View Payment History</div>
                          </div>
                          <div className="licenseEditDivContent">                
                            <div className="row" style={{display: "flex", alignItems: "baseline", marginTop:8}}>
                              <label  className="accountHeader">
                                Subscription:
                              </label>
                              <label id="a2ctiveSub" style={{ marginLeft: 5, fontSize: "0.9rem" }}>
                                {this.state.trialMode ? this.state.subscriptionStatus + " " + this.state.invoiceDueDate : this.state.subscriptionStatus}
                              </label>
                            </div>

                            <div className="row" style={{display: "flex", alignItems: "baseline", marginTop:8}}>
                              <label  className="accountHeader">
                                Invoice:
                              </label>
                              <label id="a2ctiveSub" style={{ marginLeft: 5, fontSize: "0.9rem" }}>
                                {this.state.trialMode ? '' : (this.state.invoiceDueDate == '') ? '' : 'is due ' + this.state.invoiceDueDate}
                              </label>
                            </div>

                            <div className="row" style={{ marginTop: 8 }}>
                              <div className="" style={{display:"flex",alignItems:"baseline"}}>
                                <label className="accountHeader" > Payment Method:{" "} </label>
                                {(this.state.cardInfo && this.state.cardInfo.brand ) &&
                                  <label  id ="currentPayment" style={{ marginLeft: 5, fontSize: "0.9rem" }}>
                                    {this.state.cardInfo.brand.charAt(0).toUpperCase() + this.state.cardInfo.brand.slice(1)}&nbsp;-&nbsp;{this.state.cardInfo.last4}
                                  </label>}
                              </div>
                            </div>

                            <div className="row" style={{display: "flex", alignItems: "baseline", marginTop:8}}>
                              <label  className="accountHeader">
                                Payment Type:
                              </label>
                              <label id="a2ctiveSub" style={{ marginLeft: 5, fontSize: "0.9rem" }}>
                                { this.state.collectionMethod}
                              </label>
                            </div>
                            
                            <div className="horizontalLine"></div>

                            <div className="row" style={{ marginTop: 10 }}>
                              <div className="justify-content-left">
                                <Button onClick={() =>{if(auth.isSuperViewer()){return;}document.getElementById("addCard").style.display = "block"}} className = "buttonAction buttonAccount" >
                                  <PaymentSharpIcon className="buttonIcon"/>
                                  <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon">PAYMENT METHOD</label>
                                </Button>
                                {(this.state.subscriptionStatus == "Active" || this.state.subscriptionStatus == "Past Due") && <Button onClick={this.cancelSub} className = "buttonAction buttonAccount buttonFaded" >
                                  <HighlightOffSharpIcon className="buttonIcon"/>
                                  <label style={{ margin: 0, cursor: "pointer" }} className ="buttonText withIcon">CANCEL SUBSCRIPTION</label>
                                </Button>}
                              </div>
                            </div>

                            
                            {(this.state.SubscriptionPlanId != "TicketingAndSurvey" && this.state.SubscriptionPlanId != "TicketingAndSurveyAndInvestigation") && <div>
                              <label className="accountHeader" style={{marginTop:40}} > Licenses: </label>
                              
                              <div className="licenseColumn">
                                
                                <div className="licenseRow">
                                  <div className="licenseRowInside"><label className="labelText">SITE SURVEY</label><label className="labelText account labelSmall">${this.state.licenseMeta.Site.Price} / month:</label></div>
                                  {this.state.displayInvestigationData && <div className="licenseRowInside"><label className="labelText">INCIDENT INVESTIGATION</label><label className="labelText account labelSmall">${this.state.licenseMeta.Investigation.Price} / month:</label></div>}
                                  <div className="licenseRowInside"><label className="labelText">GPS SURVEY</label><label className="labelText account labelSmall">${this.state.licenseMeta.GPS.Price} / month:</label></div>
                                  <div className="licenseRowInside"><label className="labelText">GIS SURVEY</label><label className="labelText account labelSmall">${this.state.licenseMeta.Leak.Price} / month:</label></div>
                                  <div className="licenseRowInside"><label className="labelText">CUSTOM FORMS</label><label className="labelText account labelSmall">${this.state.licenseMeta.CustomForms.Price} / month:</label></div>
                                  <div className="horizontalLineBuffer"></div>
                                  {this.state.couponCodeApplied &&
                                    <div>
                                      <div className="licenseRowInside licensesTotal"><label className="labelText account labelLarge licensesTotal">SUBTOTAL / month:</label></div>
                                      <div className="licenseRowInside licensesTotal"><label className="labelText account labelLarge licensesTotal">COUPON CODE DISCOUNT / month:</label></div>
                                    </div>
                                  }
                                  <div className="licenseRowInside licensesTotal"><label className="labelText account labelLarge licensesTotal">TOTAL / month:</label></div>
                                </div>

                                <div className="licenseRow">

                                  <input id="amount1" autoComplete="off" type="number" className="form-control licenseInput" value={this.state.licenseSite}
                                    onChange={e => {
                                      if(this.state.company && this.state.company.LicenseCollection && this.state.company.LicenseCollection.find(x => x.LicenseId == "site")){
                                        if(this.state.company.LicenseCollection.find(x => x.LicenseId == "site").Used <= parseInt(e.target.value)){
                                          this.setState({ licenseSite: e.target.value }, () =>this.updateLicenseTotal()); 
                                        }
                                        else{
                                          this.setState({updateStatusText : "Cannot go below allocatted amount. You need to un-assign licenses first.", alertSeverity:"warning"});
                                        }
                                      }
                                      else{
                                        this.setState({ licenseSite: e.target.value }, () =>this.updateLicenseTotal()); 
                                      }
                                      }} pattern="[0-9]" min="0"/>
                                  {this.state.displayInvestigationData && <input id="amount2" autoComplete="off" type="number" className="form-control licenseInput" value={this.state.licenseInvestigation}
                                    onChange={e => {
                                      if(this.state.company && this.state.company.LicenseCollection && this.state.company.LicenseCollection.find(x => x.LicenseId == "investigation")){
                                        if(this.state.company.LicenseCollection.find(x => x.LicenseId =="investigation").Used <= parseInt(e.target.value)){
                                          this.setState({ licenseInvestigation: e.target.value }, () =>this.updateLicenseTotal()); 
                                        }
                                        else{
                                          this.setState({updateStatusText : "Cannot go below allocatted amount. You need to un-assign licenses first.", alertSeverity : "warning"});
                                        }
                                      }
                                      else{
                                        this.setState({ licenseInvestigation: e.target.value }, () =>this.updateLicenseTotal()); 
                                      }
                                    }} min="0"/> }
                                  <input id="amount3" autoComplete="off" type="number" className="form-control licenseInput" value={this.state.licenseGps}
                                    onChange={e => {
                                      if(this.state.company && this.state.company.LicenseCollection && this.state.company.LicenseCollection.find(x => x.LicenseId =="gps")){
                                        if(this.state.company.LicenseCollection.find(x => x.LicenseId =="gps").Used <= parseInt(e.target.value)){
                                          this.setState({ licenseGps: e.target.value }, () =>this.updateLicenseTotal()); 
                                        }
                                        else{
                                          this.setState({updateStatusText : "Cannot go below allocatted amount. You need to un-assign licenses first.", alertSeverity : "warning"});
                                        }
                                      }
                                      else{
                                        this.setState({ licenseGps: e.target.value }, () =>this.updateLicenseTotal()); 
                                      }
                                    }} min="0"/>
                                  <input id="amount4" autoComplete="off" type="number" className="form-control licenseInput" value={this.state.licenseLeak}
                                    onChange={e => {
                                      if(this.state.company && this.state.company.LicenseCollection && this.state.company.LicenseCollection.find(x => x.LicenseId =="leak")){
                                        if(this.state.company.LicenseCollection.find(x => x.LicenseId == "leak").Used <= parseInt(e.target.value)){
                                          this.setState({ licenseLeak: e.target.value }, () =>this.updateLicenseTotal()); 
                                        }
                                        else{
                                          this.setState({updateStatusText : "Cannot go below allocatted amount. You need to un-assign licenses first.", alertSeverity : "warning"});
                                        }
                                      }
                                      else{
                                        this.setState({ licenseLeak: e.target.value }, () =>this.updateLicenseTotal()); 
                                      }
                                    }} min="0"/>
                                  {/* {this.state.displaySiteSafeContent && <input id="amount5" autoComplete="off" type="number" className="form-control licenseInput" value={this.state.licenseScreening}
                                    onChange={e => {
                                      if(this.state.company && this.state.company.LicenseCollection && this.state.company.LicenseCollection.find(x => x.LicenseId == "sitesafe")){
                                        if(this.state.company.LicenseCollection.find(x => x.LicenseId == "sitesafe").Used <= (parseInt(e.target.value) * 10)){
                                          this.setState({ licenseScreening: e.target.value }, () =>this.updateLicenseTotal()); 
                                        }
                                        else{
                                          this.setState({updateStatusText : "Cannot go below allocatted amount. You need to un-assign licenses first.", alertSeverity : "warning"});
                                        }
                                      }
                                      else{
                                        this.setState({ licenseScreening: e.target.value }, () =>this.updateLicenseTotal()); 
                                      }
                                    }} pattern="[0-9]" min="0"/>}
                                  {this.state.displaySiteSafeContent && <input id="amount6" autoComplete="off" type="number" className="form-control licenseInput" value={this.state.licenseScreeningGuest}
                                    onChange={e => {
                                      if(this.state.company && this.state.company.LicenseCollection && this.state.company.LicenseCollection.find(x => x.LicenseId == "sitesafescan")){
                                        if(this.state.company.LicenseCollection.find(x => x.LicenseId == "sitesafescan").Used <= parseInt(e.target.value)){
                                          this.setState({ licenseScreeningGuest: e.target.value }, () =>this.updateLicenseTotal()); 
                                        }
                                        else{
                                          this.setState({updateStatusText : "Cannot go below allocatted amount. You need to un-assign licenses first.", alertSeverity : "warning"});
                                        }
                                      }else{
                                        this.setState({ licenseScreeningGuest: e.target.value }, () =>this.updateLicenseTotal()); 
                                      }
                                    }} pattern="[0-9]" min="0"/>} */}
                                  <input className="customFormCheckbox"  type="checkbox" id="customFormCheckBox" checked={this.state.customFormsLicense || false} onChange={(e) => {this.updateLicenseTotal(e)}}/>
                                  {/* {this.state.orgId != 3 && <input className="customFormCheckbox"  type="checkbox" id="customFormCheckBox" checked={this.state.ticketModuleLicense || false} onChange={(e) => {this.updateLicenseTotal(false,e)}}/>} */}
                                  <div className="horizontalLineBuffer"></div>
                                  {this.state.couponCodeApplied &&
                                    <div style={{flex:1}}>
                                      <div className="licenseRowInside licensesTotal"><label className="labelText labelLarge licensesTotal">{"$" + (this.state.monthTotal + this.state.totalDiscount).toFixed(2)}</label></div>
                                      <div className="licenseRowInside licensesTotal"><label className="labelText labelLarge licensesTotal">{"-$" + this.state.totalDiscount.toFixed(2)}</label></div>
                                    </div>
                                  }
                                  <div className="licenseRowInside licensesTotal"><label className="labelText labelLarge licensesTotal">{"$" + this.state.monthTotal.toFixed(2)}</label></div>
                                </div>

                              </div>
                              
                              <div className="licenseRowInside"><label className="labelText account labelSmall">* Plus applicable taxes</label></div>

                              {this.state.orgId != 3 && <div className="row" style={{ marginTop: 10 }}>
                                <div className="justify-content-left labelText">
                                  {this.state.couponCodeApplied ? "Coupon Code Applied: " : "Coupon Code"}
                                  <b>{this.state.couponCodeApplied ? this.state.couponCodeName : null}</b>
                                </div>
                              </div>  }
                              {this.state.orgId != 3 && <div className="row" style={{ }}>
                                <div className="justify-content-left labelText">
                                  <input id="couponCode" autoComplete="off" type="text" className="form-control couponCodeInput" value={this.state.couponCode} readOnly={this.state.couponCodeApplied} 
                                  onChange={this.onCouponCodeTextInput.bind(this)}
                                  />
                                  {this.state.couponCodeApplied ? "Expires " + this.state.couponCodeExpirationDate : null}
                                </div>
                              </div>}
                              {this.state.orgId != 3 && this.state.couponCodeInvalid && 
                                <div className="row" style={{}}>
                                  <div className="justify-content-left labelTextError">
                                    Coupon Code Invalid. Please check the code and try again.
                                  </div>
                                </div>  
                              }
                              
                              <div className="horizontalLine"></div>

                              {this.state.noDefault ?
                                <div style ={{fontWeight : "600" , marginTop : 15, color:"#c37108"}}>
                                  Add a payment method before adding licenses.
                                </div> 
                              : 
                              !this.state.showButtons ? 
                                <div className="row  d-flex align-items-center justify-content-center" style={{width:500, marginTop:10}}>
                                  <div className=" d-flex align-items-center justify-content-center authSpinnerDiv" style={{width:'100%'}}>
                                  <div className="authSpinnerDivMessage">UPDATING SUBSCRIPTION...</div>
                                    <div className="barLoaderPortal">
                                    <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true }/> </div>
                                  </div>
                                </div> 
                                : (this.state.showUpdateButton && 
                                <div>

                                <div className="row" style={{ marginTop: 10 }}>
                                  <div className="justify-content-left">
                                    <Button onClick={this.updateLicensesPressed.bind(this)} className = "buttonAction buttonAccount" >
                                      <SaveSharpIcon className="buttonIcon"/>
                                      <label className ="buttonText withIcon">UPDATE LICENSES</label>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                              )}
                            </div>}
                            {(this.state.subStatus == "TicketingAndSurvey") && <label className="labelText account labelLarge licensesTotal">Unlimited Ticket Licensing with Survey</label>}
                            {(this.state.subStatus == "TicketingAndSurveyAndInvestigation") && <label className="labelText account labelLarge licensesTotal">Unlimited Ticket Licensing with Survey and Investigation</label>}
                          </div>

                          {/* PAYMENT HISTORY */}
                          <div className="labelHeaderBar labelHeaderBarTight" style={{marginTop:40, padding:10}}>Payment History
                          <div className="labelHeaderBarSubHeader">History of license purchases and payments by date</div>
                          </div>
                          <div className="row">
                            <div className="paymentHistoryTable">
                              <table className="table table-striped table-hover">
                                <tbody>
                                  <tr>
                                    <td style={{ fontWeight: "bold" }}>DATE</td>
                                    <td style={{ fontWeight: "bold" }}>AMOUNT</td>
                                    <td></td>
                                  </tr>
                                </tbody>
                                <tbody>
                                  {this.state.payments.map((payment, index) => renderRow(payment, index))}
                                </tbody>
                              </table>
                            </div>
                          </div>

                      </Tab.Pane>
                    }
                    {
                      this.state.hasCustomFormLicense &&
                      <Tab.Pane className="d-flex flex-column w-100 p-0 m-0 justify-content-center" eventKey="forms" id="forms-account-tab" mountOnEnter unmountOnExit>
                        <React.Fragment>
                        {/* CUSTOM FORMS */}
                        {this.state.customFormsEnabled == true && 
                          <div>
                            <div className="labelHeaderBar labelHeaderBarTight" style={{marginTop:40, padding:10}}>Forms 
                            <div className="labelHeaderBarSubHeader">Facilitate unique workflows in your organization through custom forms and requirements</div>
                            </div>
                            
                            <div className="licenseEditDivContent">
                              {/* Base survey and investigation */}
                              {(this.state.licenseSite && this.state.licenseSite > 0 && !this.state.loadingForms) ? <div style={{display: "flex", flexDirection : "column"}}>
                                <div className="row customFormRowDivPopup">
                                  <div className="row customFormRowDiv">
                                    <Button className="buttonAction buttonAccount formsElement">
                                      <label className="buttonText">Survey</label>
                                    </Button>
                                    {this.state.hideBaseSurvey == true ?
                                      <Button onClick={()=>{this.enableDisableBaseForms(this.state.hideBaseInvestigation, false);}} className="buttonAction buttonAccount buttonFaded formsElementsmall">
                                        <RadioButtonUncheckedIcon className="buttonIcon small"/>
                                        <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon small">Disabled</label>
                                      </Button>
                                     :
                                     <Button onClick={()=>{this.enableDisableBaseForms(this.state.hideBaseInvestigation, true);}} className="buttonAction buttonAccount formsElementsmall">
                                      <RadioButtonCheckedIcon className="buttonIcon small"/>
                                      <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon small">Enabled</label>
                                     </Button>
                                    }
                                  </div>
                                </div>
                              </div> : null}
                              {(this.state.licenseInvestigation && this.state.licenseInvestigation > 0 && !this.state.loadingForms) ? <div style={{display: "flex", flexDirection : "column"}}>
                                <div className="row customFormRowDivPopup">
                                  <div className="row customFormRowDiv">
                                    <Button className="buttonAction buttonAccount formsElement">
                                      <label className="buttonText">Investigation</label>
                                    </Button>
                                    {this.state.hideBaseInvestigation == true ?
                                      <Button onClick={()=>{this.enableDisableBaseForms(false,this.state.hideBaseSurvey);}} className="buttonAction buttonAccount buttonFaded formsElementsmall">
                                        <RadioButtonUncheckedIcon className="buttonIcon small"/>
                                        <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon small">Disabled</label>
                                      </Button>
                                    :
                                    <Button onClick={()=>{this.enableDisableBaseForms(true,this.state.hideBaseSurvey);}} className="buttonAction buttonAccount formsElementsmall">
                                      <RadioButtonCheckedIcon className="buttonIcon small"/>
                                      <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon small">Enabled</label>
                                    </Button> 
                                    }
                                  </div>
                                </div>
                              </div> : null}
                              {!this.state.loadingForms && this.state.FormCollection && this.state.surveyForms.length > 0 && <div style={{display:'flex', flexDirection: "column"}}>
                                <div  className="row customFormRowDivPopup" >
                                  <div className="row customFormRowDiv">
                                    <Button onClick={()=>{this.setState({showSurveyForms : !this.state.showSurveyForms})}} className="ButtonAction buttonAccount formsElement">
                                      <ListIcon className="buttonIcon"/>
                                      <label htmlFor={"pickSurveyForms"} className="buttonText withIcon">Custom Surveys</label>
                                    </Button>
                                    {this.state.surveyOrderChanged && <Button onClick={()=>{this.saveFormsOrder("survey")}} className="ButtonAction buttonAccount formsElement">
                                      <lable className="buttonText">Save Forms Order</lable>
                                    </Button>}
                                  </div>
                                </div>
                                {(this.state.showSurveyForms && this.state.loadingSurveyFormOrder) && <div className="row  d-flex align-items-center justify-content-center" style={{width:500, marginTop:10, marginBottom : 10}}>
                                  <div className=" d-flex align-items-center justify-content-center authSpinnerDiv" style={{width:'100%'}}>
                                    <span className="labelLoadingBar">SAVING FORMS ORDER...</span>
                                    <div className="barLoaderPortal">
                                    <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true }/> </div>
                                  </div>
                                </div>}
                                {(this.state.showSurveyForms && !this.state.loadingSurveyFormOrder) && this.state.surveyForms.map((form,index) =>(!form.DeletedDate && 
                                <div key={index} className="row customFormRowDivPopupSub" draggable onDragEnd={(e) => this.drop('survey')} onDragStart={(e) => this.dragStart(e,index)} onDragEnter={(e) => this.dragEnter(e,index)}>
                                  <div className="row customFormRowDiv" onDragOver={(e) => {e.preventDefault();}}>
                                  
                                  <Button onClick={()=>{this.editForm(index, "survey");}} className="buttonAction buttonAccount formsElement">
                                    <ListIcon className="buttonIcon"/>
                                    <label htmlFor={"pickForm" + index} className="buttonText withIcon">{form.Name}</label>
                                  </Button>
                                  {form.Enabled &&
                                  <Button onClick={()=>{this.disableForm(index, "survey");}} className="buttonAction buttonAccount formsElementsmall">
                                    <RadioButtonCheckedIcon className="buttonIcon small"/>
                                    <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon small">Enabled</label>
                                  </Button>}
                                  {!form.Enabled &&
                                  <Button onClick={()=>{this.enableForm(index, "survey");}} className="buttonAction buttonAccount buttonFaded formsElementsmall">
                                    <RadioButtonUncheckedIcon className="buttonIcon small"/>
                                    <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon small">Disabled</label>
                                  </Button>}
                                  {form.AutoGenerated && !form.unsaved && <div>
                                    <Button className="buttonAction buttonAccount" onClick={() =>{this.setState({selectCSVFileForData : true, customFormForImport : form})}}>
                                      <label style={{margin: 0, cursor : 'pointer'}} className="buttonText small">Upload Data (CSV)</label>
                                    </Button>
                                  </div>
                                  }
                                  <label htmlFor={"pickForm" + index}  className="accountHeader customFormLabel formsElementsmall">{form.Type == "Pipeline Survey" ? "GIS Survey" : form.Type}</label>
                                  <label htmlFor={"pickForm" +  index}  className="accountHeader customFormLabel formsElementsmall">{form.Fields && form.Fields.filter(x => form.Type != "Pipeline Survey" || (x.Name != "Status" && x.Name != "AssignedUserId" && x.Name != "TaskCounter")).length} Fields {form.unsaved ? "(Unsaved)" : ""}</label>
                                  {form.Secured && <label className="accountHeader customFormLabel formsElementsmall">Secured</label>}

                                </div>

                                  <Button onClick={()=>{this.deleteCustomFormWarning(index,"survey")}} title="Remove Form" className="buttonAction buttonAccount customFormRowButton buttonFaded" style={{height:'41px !important'}}>
                                    <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                  </Button>
                                </div>))}
                              </div>}

                              {!this.state.loadingForms && this.state.FormCollection && this.state.investigationForms.length > 0 && <div style={{display:'flex', flexDirection: "column"}}>
                                <div  className="row customFormRowDivPopup" >
                                  <div className="row customFormRowDiv">
                                    <Button onClick={()=>{this.setState({showInvestigationForms : !this.state.showInvestigationForms})}} className="ButtonAction buttonAccount formsElement">
                                      <ListIcon className="buttonIcon"/>
                                      <label htmlFor={"pickSurveyForms"} className="buttonText withIcon">Custom Investigations</label>
                                    </Button>
                                    {this.state.investigationOrderChanged && <Button onClick={()=>{this.saveFormsOrder("investigation")}} className="ButtonAction buttonAccount formsElement">
                                      <lable className="buttonText">Save Forms Order</lable>
                                    </Button>}
                                  </div>
                                </div>
                                {(this.state.showInvestigationForms && this.state.loadingInvestigationFormOrder) && <div className="row  d-flex align-items-center justify-content-center" style={{width:500, marginTop:10, marginBottom : 10}}>
                                  <div className=" d-flex align-items-center justify-content-center authSpinnerDiv" style={{width:'100%'}}>
                                    <span className="labelLoadingBar">SAVING FORMS ORDER...</span>
                                    <div className="barLoaderPortal">
                                    <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true }/> </div>
                                  </div>
                                </div>}
                                {(this.state.showInvestigationForms && !this.state.loadingInvestigationFormOrder) && this.state.investigationForms.map((form,index) =>(!form.DeletedDate && 
                                <div key={index} className="row customFormRowDivPopupSub" draggable onDragEnd={(e) => this.drop('investigation')} onDragStart={(e) => this.dragStart(e,index)} onDragEnter={(e) => this.dragEnter(e,index)}>
                                  <div className="row customFormRowDiv" onDragOver={(e) => {e.preventDefault();}}>
                                  
                                  <Button onClick={()=>{this.editForm(index, "investigation");}} className="buttonAction buttonAccount formsElement">
                                    <ListIcon className="buttonIcon"/>
                                    <label htmlFor={"pickForm" + index} className="buttonText withIcon">{form.Name}</label>
                                  </Button>
                                  {form.Enabled &&
                                  <Button onClick={()=>{this.disableForm(index, "investigation");}} className="buttonAction buttonAccount formsElementsmall">
                                    <RadioButtonCheckedIcon className="buttonIcon small"/>
                                    <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon small">Enabled</label>
                                  </Button>}
                                  {!form.Enabled &&
                                  <Button onClick={()=>{this.enableForm(index,"investigation");}} className="buttonAction buttonAccount buttonFaded formsElementsmall">
                                    <RadioButtonUncheckedIcon className="buttonIcon small"/>
                                    <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon small">Disabled</label>
                                  </Button>}
                                  {form.AutoGenerated && !form.unsaved && <div>
                                    <Button className="buttonAction buttonAccount" onClick={() =>{this.setState({selectCSVFileForData : true, customFormForImport : form})}}>
                                      <label style={{margin: 0, cursor : 'pointer'}} className="buttonText small">Upload Data (CSV)</label>
                                    </Button>
                                  </div>
                                  }
                                  <label htmlFor={"pickForm" + index}  className="accountHeader customFormLabel formsElementsmall">{form.Type == "Pipeline Survey" ? "GIS Survey" : form.Type}</label>
                                  <label htmlFor={"pickForm" + index}  className="accountHeader customFormLabel formsElementsmall">{form.Fields && form.Fields.filter(x => form.Type != "Pipeline Survey" || (x.Name != "Status" && x.Name != "AssignedUserId" && x.Name != "TaskCounter")).length} Fields {form.unsaved ? "(Unsaved)" : ""}</label>
                                  {form.Secured && <label className="accountHeader customFormLabel formsElementsmall">Secured</label>}

                                </div>

                                  <Button onClick={()=>{this.deleteCustomFormWarning(index,"investigation")}} title="Remove Form" className="buttonAction buttonAccount customFormRowButton buttonFaded" style={{height:'41px !important'}}>
                                    <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                  </Button>
                                </div>))}
                              </div>}

                              {!this.state.loadingForms && this.state.FormCollection && this.state.gpsForms.length > 0 && <div style={{display:'flex', flexDirection: "column"}}>
                                <div  className="row customFormRowDivPopup" >
                                  <div className="row customFormRowDiv">
                                    <Button onClick={()=>{this.setState({showGPSForms : !this.state.showGPSForms})}} className="ButtonAction buttonAccount formsElement">
                                      <ListIcon className="buttonIcon"/>
                                      <label htmlFor={"pickSurveyForms"} className="buttonText withIcon">Custom GPS Surveys</label>
                                    </Button>
                                    {this.state.gpsOrderChanged && <Button onClick={()=>{this.saveFormsOrder("gps")}} className="ButtonAction buttonAccount formsElement">
                                      <lable className="buttonText">Save Forms Order</lable>
                                    </Button>}
                                  </div>
                                </div>
                                {(this.state.showGPSForms && this.state.loadingGPSFormOrder) && <div className="row  d-flex align-items-center justify-content-center" style={{width:500, marginTop:10, marginBottom : 10}}>
                                  <div className=" d-flex align-items-center justify-content-center authSpinnerDiv" style={{width:'100%'}}>
                                    <span className="labelLoadingBar">SAVING FORMS ORDER...</span>
                                    <div className="barLoaderPortal">
                                    <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true }/> </div>
                                  </div>
                                </div>}
                                {(this.state.showGPSForms && !this.state.loadingGPSFormOrder) && this.state.gpsForms.map((form,index) =>(!form.DeletedDate && 
                                <div key={index} className="row customFormRowDivPopupSub" draggable onDragEnd={(e) => this.drop('gps')} onDragStart={(e) => this.dragStart(e,index)} onDragEnter={(e) => this.dragEnter(e,index)}>
                                  <div className="row customFormRowDiv" onDragOver={(e) => {e.preventDefault();}}>
                                  
                                  <Button onClick={()=>{this.editForm(index, "gps");}} className="buttonAction buttonAccount formsElement">
                                    <ListIcon className="buttonIcon"/>
                                    <label htmlFor={"pickForm" + index} className="buttonText withIcon">{form.Name}</label>
                                  </Button>
                                  {form.Enabled &&
                                  <Button onClick={()=>{this.disableForm(index, "gps");}} className="buttonAction buttonAccount formsElementsmall">
                                    <RadioButtonCheckedIcon className="buttonIcon small"/>
                                    <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon small">Enabled</label>
                                  </Button>}
                                  {!form.Enabled &&
                                  <Button onClick={()=>{this.enableForm(index, "gps");}} className="buttonAction buttonAccount buttonFaded formsElementsmall">
                                    <RadioButtonUncheckedIcon className="buttonIcon small"/>
                                    <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon small">Disabled</label>
                                  </Button>}
                                  {form.AutoGenerated && !form.unsaved && <div>
                                    <Button className="buttonAction buttonAccount" onClick={() =>{this.setState({selectCSVFileForData : true, customFormForImport : form})}}>
                                      <label style={{margin: 0, cursor : 'pointer'}} className="buttonText small">Upload Data (CSV)</label>
                                    </Button>
                                  </div>
                                  }
                                  <label htmlFor={"pickForm" + index}  className="accountHeader customFormLabel formsElementsmall">{form.Type == "Pipeline Survey" ? "GIS Survey" : form.Type}</label>
                                  <label htmlFor={"pickForm" + index}  className="accountHeader customFormLabel formsElementsmall">{form.Fields && form.Fields.filter(x => form.Type != "Pipeline Survey" || (x.Name != "Status" && x.Name != "AssignedUserId" && x.Name != "TaskCounter")).length} Fields {form.unsaved ? "(Unsaved)" : ""}</label>
                                  {form.Secured && <label className="accountHeader customFormLabel formsElementsmall">Secured</label>}

                                </div>

                                  <Button onClick={()=>{this.deleteCustomFormWarning(index,"gps")}} title="Remove Form" className="buttonAction buttonAccount customFormRowButton buttonFaded" style={{height:'41px !important'}}>
                                    <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                  </Button>
                                </div>))}
                              </div>}

                              {!this.state.loadingForms && this.state.FormCollection && this.state.gisForms.length > 0 && <div style={{display:'flex', flexDirection: "column"}}>
                                <div  className="row customFormRowDivPopup" >
                                  <div className="row customFormRowDiv">
                                    <Button onClick={()=>{this.setState({showGISForms : !this.state.showGISForms})}} className="ButtonAction buttonAccount formsElement">
                                      <ListIcon className="buttonIcon"/>
                                      <label htmlFor={"pickSurveyForms"} className="buttonText withIcon">Custom GIS Surveys</label>
                                    </Button>
                                    {this.state.gisOrderChanged && <Button onClick={()=>{this.saveFormsOrder("gis")}} className="ButtonAction buttonAccount formsElement">
                                      <lable className="buttonText">Save Forms Order</lable>
                                    </Button>}
                                  </div>
                                </div>
                                {(this.state.showGISForms && this.state.loadingGISFormOrder) && <div className="row  d-flex align-items-center justify-content-center" style={{width:500, marginTop:10, marginBottom : 10}}>
                                  <div className=" d-flex align-items-center justify-content-center authSpinnerDiv" style={{width:'100%'}}>
                                    <span className="labelLoadingBar">SAVING FORMS ORDER...</span>
                                    <div className="barLoaderPortal">
                                    <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true }/> </div>
                                  </div>
                                </div>}
                                {(this.state.showGISForms && !this.state.loadingGISFormOrder) && this.state.gisForms.map((form,index) =>(!form.DeletedDate && 
                                <div key={index} className="row customFormRowDivPopupSub" draggable onDragEnd={(e) => this.drop('gis')} onDragStart={(e) => this.dragStart(e,index)} onDragEnter={(e) => this.dragEnter(e,index)}>
                                  <div className="row customFormRowDiv" onDragOver={(e) => {e.preventDefault();}}>
                                  
                                  <Button onClick={()=>{this.editForm(index, "gis");}} className="buttonAction buttonAccount formsElement">
                                    <ListIcon className="buttonIcon"/>
                                    <label htmlFor={"pickForm" + index} className="buttonText withIcon">{form.Name}</label>
                                  </Button>
                                  {form.Enabled &&
                                  <Button onClick={()=>{this.disableForm(index, "gis");}} className="buttonAction buttonAccount formsElementsmall">
                                    <RadioButtonCheckedIcon className="buttonIcon small"/>
                                    <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon small">Enabled</label>
                                  </Button>}
                                  {!form.Enabled &&
                                  <Button onClick={()=>{this.enableForm(index, "gis");}} className="buttonAction buttonAccount buttonFaded formsElementsmall">
                                    <RadioButtonUncheckedIcon className="buttonIcon small"/>
                                    <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon small">Disabled</label>
                                  </Button>}
                                  {form.AutoGenerated && !form.unsaved && <div>
                                    <Button className="buttonAction buttonAccount" onClick={() =>{this.setState({selectCSVFileForData : true, customFormForImport : form})}}>
                                      <label style={{margin: 0, cursor : 'pointer'}} className="buttonText small">Upload Data (CSV)</label>
                                    </Button>
                                  </div>
                                  }
                                  <label htmlFor={"pickForm" + index}  className="accountHeader customFormLabel formsElementsmall">{form.Type == "Pipeline Survey" ? "GIS Survey" : form.Type}</label>
                                  <label htmlFor={"pickForm" + index}  className="accountHeader customFormLabel formsElementsmall">{form.Fields && form.Fields.filter(x => form.Type != "Pipeline Survey" || (x.Name != "Status" && x.Name != "AssignedUserId" && x.Name != "TaskCounter")).length} Fields {form.unsaved ? "(Unsaved)" : ""}</label>
                                  {form.Secured && <label className="accountHeader customFormLabel formsElementsmall">Secured</label>}

                                </div>

                                  <Button onClick={()=>{this.deleteCustomFormWarning(index,"gis")}} title="Remove Form" className="buttonAction buttonAccount customFormRowButton buttonFaded" style={{height:'41px !important'}}>
                                    <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                  </Button>
                                </div>))}
                              </div>}
                              {!this.state.loadingForms && this.state.FormCollection && this.state.tagForms.length > 0 && <div style={{display:'flex', flexDirection: "column"}}>
                                <div  className="row customFormRowDivPopup" >
                                  <div className="row customFormRowDiv">
                                    <Button onClick={()=>{this.setState({showTagForms : !this.state.showTagForms})}} className="ButtonAction buttonAccount formsElement">
                                      <ListIcon className="buttonIcon"/>
                                      <label htmlFor={"pickSurveyForms"} className="buttonText withIcon">Tags</label>
                                    </Button>
                                    {this.state.tagOrderChanged && <Button onClick={()=>{this.saveFormsOrder("tag")}} className="ButtonAction buttonAccount formsElement">
                                      <lable className="buttonText">Save Forms Order</lable>
                                    </Button>}
                                  </div>
                                </div>
                                {(this.state.showTagForms && this.state.loadingTagFormOrder) && <div className="row  d-flex align-items-center justify-content-center" style={{width:500, marginTop:10, marginBottom : 10}}>
                                  <div className=" d-flex align-items-center justify-content-center authSpinnerDiv" style={{width:'100%'}}>
                                    <span className="labelLoadingBar">SAVING FORMS ORDER...</span>
                                    <div className="barLoaderPortal">
                                    <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true }/> </div>
                                  </div>
                                </div>}
                                {(this.state.showTagForms && !this.state.loadingTagFormOrder) && this.state.tagForms.map((form,index) =>(!form.DeletedDate && 
                                <div key={index} className="row customFormRowDivPopupSub" draggable onDragEnd={(e) => this.drop('tag')} onDragStart={(e) => this.dragStart(e,index)} onDragEnter={(e) => this.dragEnter(e,index)}>
                                  <div className="row customFormRowDiv" onDragOver={(e) => {e.preventDefault();}}>
                                  
                                  <Button onClick={()=>{this.editForm(index, "tag");}} className="buttonAction buttonAccount formsElement">
                                    <ListIcon className="buttonIcon"/>
                                    <label htmlFor={"pickForm" + index} className="buttonText withIcon">{form.Name}</label>
                                  </Button>
                                  {form.Enabled &&
                                  <Button onClick={()=>{this.disableForm(index, "tag");}} className="buttonAction buttonAccount formsElementsmall">
                                    <RadioButtonCheckedIcon className="buttonIcon small"/>
                                    <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon small">Enabled</label>
                                  </Button>}
                                  {!form.Enabled &&
                                  <Button onClick={()=>{this.enableForm(index, "tag");}} className="buttonAction buttonAccount buttonFaded formsElementsmall">
                                    <RadioButtonUncheckedIcon className="buttonIcon small"/>
                                    <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon small">Disabled</label>
                                  </Button>}
                                  {form.AutoGenerated && !form.unsaved && <div>
                                    <Button className="buttonAction buttonAccount" onClick={() =>{this.setState({selectCSVFileForData : true, customFormForImport : form})}}>
                                      <label style={{margin: 0, cursor : 'pointer'}} className="buttonText small">Upload Data (CSV)</label>
                                    </Button>
                                  </div>
                                  }
                                  <label htmlFor={"pickForm" + index}  className="accountHeader customFormLabel formsElementsmall">{form.Type == form.Type}</label>
                                  <label htmlFor={"pickForm" + index}  className="accountHeader customFormLabel formsElementsmall">{form.Fields && form.Fields.filter(x => form.Type != "Pipeline Survey" || (x.Name != "Status" && x.Name != "AssignedUserId" && x.Name != "TaskCounter")).length} Fields {form.unsaved ? "(Unsaved)" : ""}</label>
                                  {form.Secured && <label className="accountHeader customFormLabel formsElementsmall">Secured</label>}

                                </div>

                                  <Button onClick={()=>{this.deleteCustomFormWarning(index,"tag")}} title="Remove Form" className="buttonAction buttonAccount customFormRowButton buttonFaded" style={{height:'41px !important'}}>
                                    <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                  </Button>
                                </div>))}
                              </div>}
                              <div className="horizontalLine"></div>
                              <div className="row" style={{ marginTop: 10 }}>
                                <div className="justify-content-left">
                                {!this.state.loadingForms && <div>
                                  <Button onClick={()=>{if(auth.isSuperViewer()){return;}this.setState({csvCustomFormTypeOpen : true, selectingCSVCustomFormType: true, selectingCSVFile : false})}} className = "buttonAction buttonAccount">
                                    <CreateNewFolderSharp className="buttonIcon"/>
                                    <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText">New Form (CSV)</label>
                                  </Button>
                                  <Button onClick={()=>{this.setState({copyCustomFormTypeOpen : true})}} className = "buttonAction buttonAccount">
                                    <AddBoxSharpIcon className="buttonIcon"/>
                                    <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText">New Form (Copy)</label>
                                  </Button>
                                  <Button onClick={()=>{this.setState({newFormTypeOpen : true});}} className = "buttonAction buttonAccount">
                                    <AddBoxSharpIcon className="buttonIcon"/>
                                    <label style ={{ margin: 0, cursor: 'pointer'}} accept=".csv" className ="buttonText">New Form</label>
                                  </Button>
                                  
                                  </div>}
                                {this.state.loadingForms && 
                                <div className="row  d-flex align-items-center justify-content-center" style={{width:500, marginTop:10}}>
                                  <div className=" d-flex align-items-center justify-content-center authSpinnerDiv" style={{width:'100%'}}>
                                    <span className="labelLoadingBar">UPDATING FORMS...</span>
                                    <div className="barLoaderPortal">
                                    <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true }/> </div>
                                  </div>
                                </div>}
                                {this.state.uploadingCSVFormData && 
                                <div className="row  d-flex align-items-center justify-content-center" style={{width:500, marginTop:50}}>
                                  <div className=" d-flex align-items-center justify-content-center authSpinnerDiv" style={{width:'100%'}}>
                                  <span className="labelLoadingBar">Uploading Form Data Records...</span>
                                    <div className="barLoaderPortal">
                                    <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true }/> </div>
                                  </div>
                                </div>}
                                </div>
                              </div>
                            </div>
                          </div>
                          }

                          <GroupManagementTab />
                          <GroupsPage forceRefresh={this.props.forceRefresh}/>
                          <div style={{marginTop : 20}}>
                            <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10}}>Required Tasks
                            <div className="labelHeaderBarSubHeader">Automatically create tasks for users in groups every day, week, or month.</div>
                            </div>
                            <div className="licenseEditDivContent">
                              {this.state.RequiredTasks && this.state.RequiredTasks.map((requiredTask, requiredTask_index) => ( !requiredTask.DeletedDate &&
                                <div key={"requiredTask"+ requiredTask_index} style={{display : "felx", flexDirection : "column"}}>
                                  <div className="row notificationRowDiv">
                                    <label htmlFor={"requiredTaskGroupsList" + requiredTask_index} className="accountHeader notificationLabel notificationRowControl">Groups</label>
                                    <div id={"requiredTaskGroupsList" + requiredTask_index} className="dropdown-check-list">
                                      <span className="anchor" onClick={() =>{
                                        let checkList = document.getElementById("requiredTaskGroupsList" + requiredTask_index);
                                        if(checkList.classList.contains("visible"))checkList.classList.remove("visible");
                                        else checkList.classList.add("visible");
                                      }}>Select Groups</span>
                                      <ul className="items">
                                        {this.state.companyGroups && this.state.companyGroups.length > 0 && this.state.companyGroups.map((group,requiredTaskGroup_index) => (
                                          <li key={requiredTaskGroup_index} className="" style={{marginLeft: 5}}>
                                            <input type="checkbox" id={group.CompanyGroupId} checked={requiredTask.GroupsList && ((group.CompanyGroupId == -1 && requiredTask.GroupsList.length > 0 && requiredTask.GroupsList.length == this.state.companyGroups.length -1) || requiredTask.GroupsList.includes(group.CompanyGroupId))} onChange={()=>{this.selectGroupForRequiredTask(group,requiredTask_index)}} />  
                                            <label onClick={()=>{this.selectGroupForRequiredTask(group,requiredTask_index)}}> {group.GroupLabel}</label>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>

                                    <label htmlFor={"requiredTaskFormList" + requiredTask_index} className="accountHeader notificationLabel notificationRowControl">Form</label>
                                    <select id={"requiredTaskFormList" + requiredTask_index} className = "form-control notificationRowControl" 
                                          value = {requiredTask.FormId ? requiredTask.FormId : -1} 
                                          onChange ={ e => { let RequiredTasks = this.state.RequiredTasks; RequiredTasks[requiredTask_index].FormId = e.target.value; this.setState({RequiredTasks}); }} 
                                          >
                                            <option value={-1}></option>
                                          {this.state.FormCollection.map((form, form_index) => (
                                            <option key={'requiredTaskForm'+form_index} value={form.FormId}>{form.Name}</option>
                                          ))}
                                    </select>
                                    <label htmlFor={"requiredTaskSchedule" + requiredTask_index} className="accountHeader notificationLabel notificationRowControl">Schedule:</label>
                                    <select id={"requiredTaskSchedule" + requiredTask_index} className = "form-control notificationRowControl" value = {requiredTask.Schedule}
                                      onChange={(e) =>{
                                        let RequiredTasks = this.state.RequiredTasks; RequiredTasks[requiredTask_index].Schedule = e.target.value; this.setState({RequiredTasks});
                                      }}>
                                          <option></option>
                                          <option value="daily">Daily (12am)</option>
                                          <option value="weekly">Weekly (Monday)</option>
                                          <option value="monthly">Monthly (1st)</option>
                                    </select>
                                    <Button onClick={() =>{if(requiredTask.RequiredTaskId != -1){this.setState({requiredTaskindexToDelete: requiredTask_index, showDeleteRequiredTaskWarning : true})} else{this.deleteRequiredTask(requiredTask_index);}}} className="buttonAction buttonAccount notificationRowControl notificationRowButton">
                                      <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                    </Button>
                                  </div>
                                </div>
                              ))}
                              <div className="horizontalLine"></div>
                              <div className="row" style={{marginTop : 10}}>
                                <div className="justify-content-left">
                                  { !this.state.loadRequiredTasks && <div>
                                    <Button  onClick={() =>{this.addRequiredTask();}} className="buttonAction buttonAccount">
                                      <AddBoxSharpIcon className="buttonIcon"/>
                                      <label style={{margin: 0, cursor : 'pointer'}} className="buttonText">New Required Task</label>
                                    </Button>
                                    <Button onClick={()=>{this.updateRequiredTasks();}} className="buttonAction buttonAccount">
                                      <SaveSharpIcon className="buttonIcon"/>
                                      <label style={{ margin: 0, cursor: 'pointer'}} className="buttonText">Save</label>
                                    </Button>
                                    </div>}
                                    { this.state.loadRequiredTasks && 
                                      <div className="row d-flex align-items-center justify-content-center" style={{width: 500, marginTop:10}}>
                                        <div className=" d-flex align-items-center justify-content-center authSpinnerDiv" style={{width:'100%'}}>
                                          <span className="labelLoadingBar">UPDATING Required Tasks...</span>
                                          <div className="barLoaderPortal">
                                            <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={true}/>
                                          </div>
                                        </div>
                                      </div>}
                                </div>
                              </div>
                            </div>
                          </div>
                          <ProjectManagementTab setProjects={this.setProjects} FormCollection={this.state.FormCollection} formProjects={this.state.formProjects} editFormProjects={this.editFormProjects}/>

                        {/* TRIGGERS & EVENTS */}
                        {this.state.customFormsTriggersEnabled == true && 
                          <div style={{marginTop : 20}}>
                            <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10}}>Triggers & Update Events
                            <div className="labelHeaderBarSubHeader">Create automated update events with custom, data-driven triggers</div>
                            </div> 
                            
                            <div className="licenseEditDivContent">
                              
                              {this.state.FormCollection && this.state.FormCollection.map((form, f_index) => (
                                <div key={"triggerGroup"+f_index} className="accountFormTriggerGroup">

                                  {/* HEADER */}
                                  <div className="accountFormTriggerGroupHeader">  
                                    <label htmlFor={"pickTrigger" + f_index} className="accountHeader triggerFormLabel">{form.Name}</label>
                                    {!this.state.loadingForms && <div>
                                      {this.unsavedTriggers(f_index) &&
                                        <Button onClick={()=>{this.saveTrigger(f_index);}} className = "buttonAction buttonAccount small confirm">
                                        <SaveSharpIcon className="buttonIcon small"/>
                                        <label style ={{ margin: 0, cursor: 'pointer', fontSize:12}} className ="buttonText">Save</label>
                                        </Button>}
                                    <Button onClick={()=>{this.addTrigger(f_index);}} className = "buttonAction buttonAccount small">
                                      <AddBoxSharpIcon className="buttonIcon small"/>
                                      <label style ={{ margin: 0, cursor: 'pointer', fontSize:12}} className ="buttonText">Add Trigger</label>
                                    </Button>
                                    </div>}
                                  </div>

                                  {/* TRIGGERS */}
                                  {form && form.FormTriggerCollection && form.FormTriggerCollection.map((trigger, t_index) => (
                                    
                                    !trigger.DeletedDate &&
                                    <div key={"triggerCollection"+t_index} className='accountFormTriggerGroupTriggers'>
                                      
                                      <div className="formTriggerGroupTriggerValues">
                                        
                                        <label htmlFor={"formTriggerFieldFromLabel" + t_index} className="accountHeader notificationLabel">If:</label>
                                        <select id={"formTriggerFieldFrom" + t_index} className = "form-control notificationRowControl trigger" 
                                            value = {trigger.TriggerFieldFromNameIndex} 
                                            onChange ={ e => { this.updateTriggerFieldFrom(e, form, f_index, t_index) }} >
                                            <option></option>
                                            {form.Fields.map((field, field_index) => (
                                              <option key={'triggerFieldOption'+field_index} value={field_index}>{field.Name}</option>
                                            ))}
                                        </select>
                                        <select id={"formTriggerFieldFromValue" + t_index} className = "form-control notificationRowControl trigger" 
                                            value = {trigger.TriggerFieldFromValueUpdateIndex}
                                            onChange ={ e => { this.updateTriggerFieldValueEventAction(e, form, f_index, t_index) }} >
                                            <option value='any' selected="selected">Has Any Value</option>
                                            <option value='specific'>Has Specific Value:</option>
                                        </select>

                                        {form.FormTriggerCollection[t_index].TriggerField && form.FormTriggerCollection[t_index].TriggerField.CustomValues && form.FormTriggerCollection[t_index].TriggerFieldFromValueUpdateIndex == "specific" ?
                                          <select id={"formTriggerFieldFrom" + t_index} className = "form-control notificationRowControl trigger" 
                                            value = {trigger.TriggerFieldValueIndex}
                                            onChange ={ e => { this.updateTriggerFieldFromValue(e, form, f_index, t_index, true); }} >
                                            <option></option>
                                            {form.FormTriggerCollection[t_index].TriggerField.CustomValues.map((value, valueindex) => (
                                              <option key={'triggerFieldOption'+valueindex} value={valueindex}>{value}</option>
                                            ))}
                                          </select> 
                                        : form.FormTriggerCollection[t_index].TriggerField && form.FormTriggerCollection[t_index].TriggerField.Type == 'Date' ?
                                          <div>
                                            <DatetimePicker name="dateFrom" value={trigger.TriggerFieldValue} style={{height:10}} showTime={false} buttonClasses={"notificationRowControl trigger datetime"}
                                            onChange={e => { this.updateTriggerFieldFromValue(e, form, f_index, t_index, false); }}/>
                                          </div>
                                        : form.FormTriggerCollection[t_index].TriggerField && form.FormTriggerCollection[t_index].TriggerField.Type == 'Time' ?
                                        <div>
                                          <DatetimePicker name="dateFrom" value={trigger.TriggerFieldValue} showTime={true} buttonClasses={"notificationRowControl trigger datetime"}
                                          onChange={e => { this.updateTriggerFieldFromValue(e, form, f_index, t_index, false); }}/>
                                        </div>
                                        : form.FormTriggerCollection[t_index].TriggerField && form.FormTriggerCollection[t_index].TriggerField.Type =="Yes or No" && form.FormTriggerCollection[t_index].TriggerFieldFromValueUpdateIndex == "specific" ? 
                                          <div>
                                            <select className ="form-control notificationRowControl trigger" value ={form.FormTriggerCollection[t_index].TriggerFieldValue ? form.FormTriggerCollection[t_index].TriggerFieldValue : ''} 
                                              onChange={e => { this.updateTriggerFieldFromValue(e, form, f_index, t_index, false);}}>
                                              <option></option>
                                              <option value={true}>Yes</option>
                                              <option value ={false}>No</option>
                                            </select>
                                          </div>
                                        :form.FormTriggerCollection[t_index].TriggerFieldFromValueUpdateIndex == 'specific' ?
                                          <input id={"formTriggerFieldFrom" + t_index} value={trigger.TriggerFieldValue} className = "form-control customFormRowControlCustomValueEdit customValue"
                                          onChange = {e => { this.updateTriggerFieldFromValue(e, form, f_index, t_index, false); }} 
                                          /> : null}
                                        
                                        <ArrowForwardIcon className="buttonIcon  darkmain"/>

                                        <label htmlFor={"formTriggerFieldUpdateLabel" + t_index} className="accountHeader notificationLabel">Update:</label>
                                        <select id={"formTriggerFieldUpdate" + t_index} className = "form-control notificationRowControl trigger" value = {trigger.TriggerFieldUpdateNameIndex}
                                            onChange ={ e => { this.updateTriggerFieldUpdate(e, form, f_index, t_index) }} >
                                            <option></option>
                                            <option value="requirePhoto">Make Photo Required</option>
                                            {form.Fields.map((field, field_index) => (
                                              <option key={'triggerFieldOption'+field_index} value={field_index}>{field.Name}</option>
                                            ))}
                                        </select>

                                        {trigger.TriggerFieldUpdateNameIndex != "requirePhoto" && <select id={"formTriggerFieldUpdate" + t_index} className = "form-control notificationRowControl trigger" value = {trigger.TriggerFieldUpdateIndex}
                                            onChange ={ e => { this.updateTriggerFieldEventAction(e, form, f_index, t_index) }} >
                                            <option value='setValue' selected="selected">Set Value To:</option>
                                            <option value='required'>Make Required.</option>
                                        </select>}

                                        {trigger.TriggerFieldUpdateNameIndex != "requirePhoto" && form.FormTriggerCollection[t_index].TriggerFieldUpdate && form.FormTriggerCollection[t_index].TriggerFieldUpdate.CustomValues ?
                                        <select id={"formTriggerFieldFrom" + t_index} className = "form-control notificationRowControl trigger" value = {trigger.TriggerFieldUpdateValueIndex}
                                            onChange ={ e => { this.updateTriggerFieldFromValue(e, form, f_index, t_index); }} >
                                            <option></option>
                                            {form.FormTriggerCollection[t_index].TriggerFieldUpdate.CustomValues.map((value, valueindex) => (
                                              <option key={'triggerFieldOption'+valueindex} value={valueindex}>{value}</option>
                                            ))}
                                        </select> : trigger.TriggerFieldUpdateNameIndex != "requirePhoto" && form.FormTriggerCollection[t_index].TriggerFieldUpdateIndex != 'required' ?
                                          <input id={"formTriggerFieldFrom" + t_index} value={trigger.TriggerFieldSetValue} className = "form-control customFormRowControlCustomValueEdit customValue"
                                          onChange ={e => { this.updateTriggerFieldSetValue(e, form, f_index, t_index); }} 
                                          /> : null}

                                      </div>

                                      <div className="formTriggerGroupTriggerControls">
                                        {trigger.Enabled &&
                                        <Button onClick={()=>{this.disableTrigger(trigger, f_index, t_index);}} className="buttonAction formsElementsmall xsmall">
                                          <ToggleOnIcon className="buttonIcon"/>
                                          <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon xsmall ">On</label>
                                        </Button>}
                                        
                                        {!trigger.Enabled &&
                                        <Button onClick={()=>{this.enableTrigger(trigger, f_index, t_index);}} className="buttonAction buttonFaded formsElementsmall xsmall">
                                          <ToggleOffIcon className="buttonIcon"/>
                                          <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon xsmall ">Off</label>
                                        </Button>}
                                      
                                        <Button onClick={()=>{this.deleteTriggerWarning(trigger, f_index, t_index)}} title="Remove Trigger" 
                                          className="buttonAction buttonAccount formsElementsmall xsmall square">
                                          <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                        </Button>
                                      </div>

                                    </div>
                                  ))}
                                </div>
                              ))}

                              <div className="horizontalLine"></div>
                              <div className="row" style={{ marginTop: 10 }}>
                                <div className="justify-content-left">
                                {this.state.loadingForms && 
                                <div className="row  d-flex align-items-center justify-content-center" style={{width:500, marginTop:10}}>
                                  <div className=" d-flex align-items-center justify-content-center authSpinnerDiv" style={{width:'100%'}}>
                                  <span className="labelLoadingBar">UPDATING EVENTS & TRIGGERS...</span>
                                    <div className="barLoaderPortal">
                                    <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true }/> </div>
                                  </div>
                                </div>}

                                </div>
                              </div>
                            </div>
                          </div>
                          }
                          <Dialog open={this.state.showDeleteRequiredTaskWarning ? true : false} onClose={() =>{this.handleCloseRequiredTaskWarning()}}>
                            <DialogTitle>{"Do you want to delete this Project"}</DialogTitle>
                            <DialogActions>
                              <Button className="buttonAction btn btn-primary dehighlighted" onClick={() =>this.handleCloseRequiredTaskWarning()}>
                                <label className="buttonText small" style={{margin: 0, cursor : "pointer"}}>Cancel</label>
                              </Button>
                              <Button className="buttonAction btn btn-primary dehighlighted" onClick={() =>{this.handleCloseRequiredTaskWarning(); this.deleteRequiredTask(this.state.requiredTaskindexToDelete);}}>
                                <label className="buttonText small" style={{margin: 0, cursor : 'pointer'}}>Accept</label>
                              </Button>
                            </DialogActions>
                          </Dialog>
                          {/*CSV IMPORT DATA */}
                          <Dialog open={this.state.selectCSVFileForData ? true : false}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">{"Validate form and select file"}</DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                {"Ensure all fields and field types are correct in the Form before uploading data. Once data is uploaded, the fields cannot be changed."}
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button className="buttonAction buttonAccount" style={{position : 'relative', left : 140}}>
                                  <AttachmentIcon className="buttonIcon"/>
                                  <label style={{ margin: 0, cursor : 'pointer'}} className="buttonText">Select File</label>
                                </Button>
                              {this.state.customFormForImport && <input type="file" style={{width:140}} id={"uploadFormDataCSV"+this.state.customFormForImport.FormId} onClick={() =>{this.value=null;}} accept=".csv" onChange={()=>{this.setState({selectCSVFileForData : false});this.importFormDataFromCSV()}} className="form-control-file attachDocumentButton"/>}
                              <Button className="buttonAction btn btn-primary" onClick={()=>{this.setState({selectCSVFileForData : false})}} color="primary">
                                <label className="buttonText small" style={{ margin: 0, cursor: 'pointer'}}>Cancel</label>
                              </Button>
                            </DialogActions>
                          </Dialog>
                          {/* COPY CUSTOM FORM*/}
                          <Dialog open={this.state.copyCustomFormTypeOpen ? true : false}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">{"Custom Form Copy"}</DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                {"Select Custom Form to copy."}
                              </DialogContentText>
                              <div>
                                <select onChange={(e)=>{this.addFormCopy(e.target.value)}}> <option></option>
                                {this.state.FormCollection && this.state.FormCollection.map((form, index) =>(!form.DeletedDate && 
                                  <option key={index} value={form.FormId}>{form.Name}</option>  
                                ))}
                                </select></div>
                            </DialogContent>
                            <DialogActions>
                              <Button className="buttonAction btn btn-primary" onClick={()=>{this.setState({copyCustomFormTypeOpen : false})}} color="primary">
                                <label className="buttonText small" style={{ margin: 0, cursor: 'pointer'}}>Cancel</label>
                              </Button> 
                            </DialogActions>
                          </Dialog>
                          <Dialog open={this.state.newFormTypeOpen ? true : false}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">{"Custom Form Type"}</DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                {"Select Custom Form Type:"}
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button className="buttonAction btn btn-primary" onClick={() =>{this.addCustomForm("Survey");this.setState({newFormTypeOpen: false})}} color="primary">
                                <label className="buttonText small" style={{ margin: 0, cursor : 'pointer'}}>Survey</label>
                              </Button>
                              <Button className="buttonAction btn btn-primary" onClick={() =>{this.addCustomForm("investigation");this.setState({newFormTypeOpen: false})}} color="primary">
                                <label className="buttonText small" style={{ margin: 0, cursor : 'pointer'}}>Investigation</label>
                              </Button>
                              <Button className="buttonAction btn btn-primary" onClick={() =>{this.addCustomForm("gps");this.setState({newFormTypeOpen: false})}} color="primary">
                                <label className="buttonText small" style={{ margin: 0, cursor : 'pointer'}}>GPS Survey</label>
                              </Button>
                              <Button className="buttonAction btn btn-primary" onClick={() =>{this.addCustomForm("gis");this.setState({newFormTypeOpen: false})}} color="primary">
                                <label className="buttonText small" style={{ margin: 0, cursor : 'pointer'}}>GIS Survey</label>
                              </Button>
                              <Button className="buttonAction btn btn-primary" onClick={() =>{this.addCustomForm("tag");this.setState({newFormTypeOpen: false})}} color="primary">
                                <label className="buttonText small" style={{ margin: 0, cursor : 'pointer'}}>Tag</label>
                              </Button>
                            </DialogActions>
                          </Dialog>
                          {/* CSV IMPORT TYPE */}
                          <Dialog open={this.state.csvCustomFormTypeOpen ? true : false}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">{"Custom Form Type"}</DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                               {this.state.selectingCSVCustomFormType ?  "Select Custom Form Type:" : "Select CSV File"}
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              {this.state.selectingCSVCustomFormType && <Button className="buttonAction btn btn-primary" onClick={() =>{this.setState({csvCustomFormType : "Survey", selectingCSVCustomFormType: false, selectingCSVFile : true})}} color="primary">
                                <label className="buttonText small" style={{ margin: 0, cursor : 'pointer'}}>Survey</label>
                              </Button>}
                              {this.state.selectingCSVCustomFormType && <Button className="buttonAction btn btn-primary" onClick={() =>{this.setState({csvCustomFormType : "Investigation", selectingCSVCustomFormType: false, selectingCSVFile : true})}} color="primary">
                                <label className="buttonText small" style={{ margin: 0, cursor : 'pointer'}}>Investigation</label>
                              </Button>}
                              {this.state.selectingCSVCustomFormType && <Button className="buttonAction btn btn-primary" onClick={() =>{this.setState({csvCustomFormType : "GPS Survey", selectingCSVCustomFormType: false, selectingCSVFile : true})}} color="primary">
                                <label className="buttonText small" style={{ margin: 0, cursor : 'pointer'}}>GPS Survey</label>
                              </Button>}
                              {this.state.selectingCSVCustomFormType && <Button className="buttonAction btn btn-primary" onClick={() =>{this.setState({csvCustomFormType : "Pipeline Survey", selectingCSVCustomFormType: false, selectingCSVFile : true})}} color="primary">
                                <label className="buttonText small" style={{ margin: 0, cursor : 'pointer'}}>GIS Survey</label>
                              </Button>}
                              {this.state.selectingCSVCustomFormType && <Button className="buttonAction btn btn-primary" onClick={() =>{this.setState({csvCustomFormType : "Tag", selectingCSVCustomFormType: false, selectingCSVFile : true})}} color="primary">
                                <label className="buttonText small" style={{ margin: 0, cursor : 'pointer'}}>Tag</label>
                              </Button>}
                              {this.state.selectingCSVFile && <div>
                                <Button className="buttonAction buttonAccount" style={{position : 'relative', left : 166}}>
                                  <AttachmentIcon className="buttonIcon"/>
                                  <label style={{ margin: 0, cursor : 'pointer'}} className="buttonText">Select File</label>
                                </Button>
                                <input type="file" id="newFormCSV" accept=".csv" onClick={() =>{this.value=null;}} onChange={this.addCustomFormFromCSV} className="form-control-file attachDocumentButton"/>
                              </div>}
                              <Button className="buttonAction btn btn-primary" onClick={()=>{this.setState({csvCustomFormTypeOpen : false, selectingCSVCustomFormType: false, selectingCSVFile : false})}} color="primary">
                                <label className="buttonText small" style={{ margin: 0, cursor: 'pointer'}}>Cancel</label>
                              </Button>
                            </DialogActions>
                          </Dialog>
                          {/* CUSTOM FORMS EDITOR */}
                          <Dialog open={this.state.editCustomForm ? true : false} onClose={()=>{}} maxWidth='lg' fullWidth='true'>
                            <DialogTitle>Edit Form - {this.state.currentFormEdit.Name}</DialogTitle>
                            <DialogContent>
                              <div className="row">
                                <div className="" style={{width:'100%'}}>
                                  <div className="row customFormRowDiv" style={{width:'100%'}}>
                                    <label className="accountHeader customFormLabel large">Form Name:</label>
                                    <input id="customFormName" value={this.state.currentFormEdit.Name} maxLength="50" htmlFor="customFormName" className = "form-control customFormRowControl large highlighted"
                                      onChange ={e =>{ let currentFormEdit = this.state.currentFormEdit; currentFormEdit.Name = e.target.value; this.setState({currentFormEdit})}}
                                    />
                                    <label className="accountHeader customFormLabel">Form Type:</label>
                                    <select id={"customFormType"} className = "form-control customFormRowControl" value = {this.state.currentFormEdit.Type == "Pipeline Survey" ? "GIS Survey" : this.state.currentFormEdit.Type}
                                      onChange ={e => {this.setFormType(e.target.value)}} >
                                      <option>Survey</option>
                                      <option>Investigation</option>
                                      <option>GPS Survey</option>
                                      <option>GIS Survey</option>
                                      <option>Tag</option>
                                    </select>
                                  </div>

                                  {(this.state.showGeometryTypeSelector || this.state.currentFormEdit.Type == "GPS Survey" ) && 
                                    <div className="row customFormRowDiv" style={{width:'100%', marginTop:10}}>
                                      <label className="accountHeader customFormLabel large">Geometry:</label>
                                      <select id={"customFormType"} className = "form-control customFormRowControl" value = {this.state.currentFormEdit.GeometryType}
                                        onChange ={e => {this.setGeometryType(e.target.value)}} >
                                        <option>Point</option><option>Line</option><option>Polygon</option>
                                      </select>
                                      <label className="accountHeader customFormLabel">Color:</label>
                                      <Button onClick={()=>{this.showColor()}} className = "colorPickerDiv" 
                                        style={{backgroundColor:this.state.currentFormEdit.Color}}>
                                      </Button>
                                      {this.state.showColor && <div style={{display:'flex', flexDirection:'column'}}>
                                        <CompactPicker styles={{backgroundColor : this.state.currentFormEdit.Color ? this.state.currentFormEdit.Color : '#095399'}} color={ this.state.currentFormEdit.Color ? this.state.currentFormEdit.Color : '#095399' }  onChangeComplete={ this.setFormColor } />
                                          <Button onClick={()=>{this.showColor()}} className = "buttonAction buttonAccount small nomargin short">
                                            <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon xsmall ">Save Color</label>
                                          </Button>
                                        </div> }
                                        <label className="accountHeader customFormLabel">Marker:</label>
                                        <span onClick={()=>{this.showMarkerSelection()}} style={{cursor : 'pointer'}} >
                                          <svg style={{position : 'relative', top: 14}} height="50px" width="50px" fill={this.state.currentFormEdit.Color ? this.state.currentFormEdit.Color : '#095399'}><path d={this.MarkerUtil.getPath(this.state.currentFormEdit.MarkerSymbol)}></path></svg>
                                        </span>
                                        
                                        {this.state.showMarkerSelection && <div style={{display:'flex', flexDirection:'row', maxHeight : 125, overflowY : 'scroll', maxWidth : 220}} className="row">
                                          {this.state.markerList.map((marker,index) =>( 
                                            <span key={index} style={{cursor: 'pointer'}} onClick={() =>{
                                              let formEdit = this.state.currentFormEdit;
                                              formEdit.MarkerSymbol = marker.name;
                                              this.setState({currentFormEdit : formEdit});
                                              this.showMarkerSelection();
                                            }}>
                                              <svg style={{position : 'relative', top: 14}} height="50px" width="50px" fill={this.state.currentFormEdit.Color ? this.state.currentFormEdit.Color : "$095399"}>
                                                <path d={marker.path}></path>
                                              </svg>
                                            </span>
                                          ))} 
                                        </div>}
                                        
                                        
                                    </div>
                                  }
                                  {(this.state.showGeometryTypeSelector || this.state.currentFormEdit.Type == "GPS Survey" ) && this.state.currentFormEdit.GeometryType == "Line" && <div className="row customFormRowDiv" style={{width:'100%', marginTop:10}}>
                                          <label className="accountHeader customFormLabel large">Line Marker:</label>
                                          <span onClick={()=>{this.showLineMarkerSelection()}} style={{cursor:'pointer'}}>
                                            {this.MarkerUtil.getLineMarker(this.state.currentFormEdit.LineMarkerSymbol, this.state.currentFormEdit.Color ? this.state.currentFormEdit.Color : '#095399')}
                                          </span>
                                  </div>}
                                  {this.state.showLineMarkerSelection && <div>
                                      <div className="row customFormRowdiv ">
                                        <span className="lineMarkerDiv" onClick={()=>{this.setLineMarkerSymbol('solid')}} >
                                          {this.MarkerUtil.getLineMarker('solid', this.state.currentFormEdit.Color ? this.state.currentFormEdit.Color : '#095399')}
                                        </span>
                                      </div>
                                      <div className="row customFormRowdiv ">
                                        <span className="lineMarkerDiv" onClick={()=>{this.setLineMarkerSymbol('short-dot')}} >
                                          {this.MarkerUtil.getLineMarker('short-dot', this.state.currentFormEdit.Color ? this.state.currentFormEdit.Color : '#095399')}
                                        </span>
                                      </div>
                                      <div className="row customFormRowdiv ">
                                        <span className="lineMarkerDiv" onClick={()=>{this.setLineMarkerSymbol('short-dash-dot-dot')}} >
                                          {this.MarkerUtil.getLineMarker('short-dash-dot-dot', this.state.currentFormEdit.Color ? this.state.currentFormEdit.Color : '#095399')}
                                        </span>
                                      </div>
                                      <div className="row customFormRowdiv ">
                                        <span className="lineMarkerDiv" onClick={()=>{this.setLineMarkerSymbol('short-dash-dot')}} >
                                          {this.MarkerUtil.getLineMarker('short-dash-dot', this.state.currentFormEdit.Color ? this.state.currentFormEdit.Color : '#095399')}
                                        </span>
                                      </div>
                                      <div className="row customFormRowdiv ">
                                        <span className="lineMarkerDiv" onClick={()=>{this.setLineMarkerSymbol('short-dash')}} >
                                          {this.MarkerUtil.getLineMarker('short-dash', this.state.currentFormEdit.Color ? this.state.currentFormEdit.Color : '#095399')}
                                        </span>
                                      </div>
                                      <div className="row customFormRowdiv ">
                                        <span className="lineMarkerDiv" onClick={()=>{this.setLineMarkerSymbol('long-dash-dot-dot')}} >
                                          {this.MarkerUtil.getLineMarker('long-dash-dot-dot', this.state.currentFormEdit.Color ? this.state.currentFormEdit.Color : '#095399')}
                                        </span>
                                        </div>
                                      <div className="row customFormRowdiv ">
                                        <span className="lineMarkerDiv" onClick={()=>{this.setLineMarkerSymbol('long-dash-dot')}} >
                                          {this.MarkerUtil.getLineMarker('long-dash-dot', this.state.currentFormEdit.Color ? this.state.currentFormEdit.Color : '#095399')}
                                        </span>
                                      </div>
                                      <div className="row customFormRowdiv ">
                                        <span className="lineMarkerDiv" onClick={()=>{this.setLineMarkerSymbol('long-dash')}} >
                                          {this.MarkerUtil.getLineMarker('long-dash', this.state.currentFormEdit.Color ? this.state.currentFormEdit.Color : '#095399')}
                                        </span>
                                      </div>
                                      <div className="row customFormRowdiv ">
                                        <span className="lineMarkerDiv" onClick={()=>{this.setLineMarkerSymbol('dot')}} >
                                          {this.MarkerUtil.getLineMarker('dot', this.state.currentFormEdit.Color ? this.state.currentFormEdit.Color : '#095399')}
                                        </span>
                                      </div>
                                      <div className="row customFormRowdiv ">
                                        <span className="lineMarkerDiv" onClick={()=>{this.setLineMarkerSymbol('dash-dot')}} >
                                          {this.MarkerUtil.getLineMarker('dash-dot', this.state.currentFormEdit.Color ? this.state.currentFormEdit.Color : '#095399')}
                                        </span>
                                      </div>
                                      <div className="row customFormRowdiv ">
                                        <span className="lineMarkerDiv" onClick={()=>{this.setLineMarkerSymbol('dash')}} >
                                          {this.MarkerUtil.getLineMarker('dash', this.state.currentFormEdit.Color ? this.state.currentFormEdit.Color : '#095399')}
                                        </span>
                                      </div>
                                    </div>}
                                  {(this.state.currentFormEdit.Type != "GPS Survey" ) &&
                                  <div className="row customFormRowDiv" style={{width:'100%', marginTop:10}}>
                                    <label className="accountHeader customFormLabel large">Color:</label>
                                      <Button onClick={()=>{this.showColor()}} className = "colorPickerDiv" 
                                        style={{backgroundColor:this.state.currentFormEdit.Color, position : 'relative'}}>
                                      </Button>
                                      {this.state.showColor && <div style={{display:'flex', flexDirection:'column'}}>
                                        <CompactPicker color={ this.state.currentFormEdit.Color ? this.state.currentFormEdit.Color : '#095399' } onChangeComplete={ this.setFormColor } />
                                          <Button onClick={()=>{this.showColor()}} className = "buttonAction buttonAccount small nomargin short">
                                            <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon xsmall ">Save Color</label>
                                          </Button>
                                        </div> }
                                        <label className="accountHeader customFormLabel">Marker:</label>
                                        <span onClick={()=>{this.showMarkerSelection()}} style={{cursor : 'pointer'}} >
                                          <svg style={{position : 'relative', top: 14}} height="50px" width="50px" fill={this.state.currentFormEdit.Color ? this.state.currentFormEdit.Color : '#095399'}><path d={this.MarkerUtil.getPath(this.state.currentFormEdit.MarkerSymbol)}></path></svg>
                                        </span>
                                        {this.state.showMarkerSelection && <div style={{display:'flex', flexDirection:'row', maxHeight : 125, overflowY : 'scroll', maxWidth : 220}} className="row">
                                          {this.state.markerList.map((marker,index) =>(
                                            <span key={index} style={{cursor: 'pointer'}} onClick={() =>{
                                              let formEdit = this.state.currentFormEdit;
                                              formEdit.MarkerSymbol = marker.name;
                                              this.setState({currentFormEdit : formEdit});
                                              this.showMarkerSelection();
                                            }}>
                                              <svg style={{position : 'relative', top: 14}} height="50px" width="50px" fill={this.state.currentFormEdit.Color ? this.state.currentFormEdit.Color : "$095399"}>
                                                <path d={marker.path}></path>
                                              </svg>
                                            </span>
                                          ))} 
                                        </div>}
                                  </div>
                                  }

                                  <div className="row customFormRowDiv" style={{marginTop:10}}>
                                    <div className="accountHeader customFormLabel large">
                                      Groups: 
                                    </div>
                                    <div className="customFormSelectBox">
                                      <div className="customFormRowControl controlBorderBottom" style={{fontSize:12, maxWidth:200, overflowY:'auto', maxHeight:150, paddingRight:10}}>
                                        <div style={{minHeight : 10}}/>
                                        {this.state.companyGroups && this.state.companyGroups.length > 0 && this.state.companyGroups.map((group,index) => (
                                          <div key={index}className="" style={{marginLeft: 10}}><input type="checkbox" id={group.CompanyGroupId} checked={this.state.selectedGroups.find(x=> (x != undefined) && x.CompanyGroupId === group.CompanyGroupId) || false} onChange={()=>{this.selectGroup(group)}} />  <label htmlFor={group.CompanyGroupId}> {group.GroupLabel}</label></div>
                                        ))}
                                      </div>
                                    </div>
                                    <div className="accountHeader customFormLabel large">
                                      Projects:
                                    </div>
                                    <div className="customFormSelectBox">
                                      <div className="customFormRowControl controlBorderBottom" style={{fontSize:12, maxWidth:200, overflowY:'auto', maxHeight:150, paddingRight:10}}>
                                        <div style={{minHeight: 10}}/>
                                        {this.state.companyProjects && this.state.companyProjects.length > 0 && this.state.companyProjects.map((project,index) => (
                                          <div key={index}className="" style={{marginLeft:10}}><input type="checkbox" id={project.ProjectId} checked={this.state.selectedProjects.find(x => (x != undefined) && x.ProjectId == project.ProjectId) || false} onChange={()=>{this.selectProject(project)}}/> <label htmlFor={project.ProjectId}>{project.ProjectLabel}</label></div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row customFormRowDiv">
                                    <label className="accountHeader customFormLabel large">Secure Form:</label>
                                    <input type="checkbox" id={"customFormEnabled"} checked={this.state.currentFormEdit.Secured ? 'checked' : ''} value={this.state.currentFormEdit.Secured} className = "form-control customFormRowControl xsmall"
                                      onChange ={e =>{ let currentFormEdit = this.state.currentFormEdit; currentFormEdit.Secured = e.target.checked;this.setState({currentFormEdit})}} />
                                  </div>
                                  <div className="row customFormRowDiv">
                                    <label className="accountHeader customFormLabel large">Form Enabled:</label>
                                    <input type="checkbox" id={"customFormEnabled"} checked={this.state.currentFormEdit.Enabled ? 'checked' : ''} value={this.state.currentFormEdit.Enabled} className = "form-control customFormRowControl xsmall"
                                      onChange ={e =>{ let currentFormEdit = this.state.currentFormEdit; currentFormEdit.Enabled = e.target.checked;this.setState({currentFormEdit})}} />
                                  </div>
                                  <div className="row customFormRowDiv">
                                    <label className="accountHeader customFormLabel large">Allow Photos:</label>
                                    <input type="checkbox" id={"customFormPhotos"} checked={this.state.currentFormEdit.AllowPics ? 'checked' : ''} value={this.state.currentFormEdit.AllowPics} className = "form-control customFormRowControl xsmall"
                                      onChange ={e =>{ let currentFormEdit = this.state.currentFormEdit; currentFormEdit.AllowPics = e.target.checked;this.setState({currentFormEdit})}} />
                                  </div>
                                  <div className="row customFormRowDiv">
                                    <label className="accountHeader customFormLabel large">Allow Videos:</label>
                                    <input type="checkbox" id={"customFormVideos"} checked={this.state.currentFormEdit.AllowVids ? 'checked' : ''} value={this.state.currentFormEdit.AllowPics} className="form-control customFormRowControl xsmall"
                                      onChange={e =>{ let currentFormEdit = this.state.currentFormEdit; currentFormEdit.AllowVids = e.target.checked;this.setState({currentFormEdit})}} />
                                  </div>
                                  <div className="row customFormRowDiv">
                                    <label className="accountHeader customFormLabel large">Allow Map Sketch:</label>
                                    <input type="checkbox" id={"customFormMapSketch"} checked={this.state.currentFormEdit.AllowMapSketch ? 'checked' : ''} value={this.state.currentFormEdit.AllowMapSketch} className="form-control customFormRowControl xsmall"
                                      onChange={e =>{ let currentFormEdit = this.state.currentFormEdit; currentFormEdit.AllowMapSketch = e.target.checked; this.setState({currentFormEdit})}} />
                                  </div>
                                  <div className="row customFormRowDiv">
                                    <label className="accountHeader customFormLabel large">Allow Attach Document:</label>
                                    <input type="checkbox" id={"customFormMapSketch"} checked={this.state.currentFormEdit.AllowAttachDocument ? 'checked' : ''} value={this.state.currentFormEdit.AllowAttachDocument} className="form-control customFormRowControl xsmall"
                                      onChange={e =>{ let currentFormEdit = this.state.currentFormEdit; currentFormEdit.AllowAttachDocument = e.target.checked; this.setState({currentFormEdit})}} />
                                  </div>
                                </div>
                              </div>
                              <div className="horizontalLine" style={{marginTop:15}}></div>
                              {this.state.currentFormEdit.Fields.map((field, index) =>((!field.DeletedDate && this.state.currentFormEdit.Type != 'Pipeline Survey' || (this.state.currentFormEdit.Type == 'Pipeline Survey' && !(field.Name == 'Status' && field.Type == 'Drop Down') && !(field.Name =='AssignedUserId' && field.Type == 'Text') && !(field.Name == 'TaskCounter' && field.Type == "Number"))) && 
                                <div key={index} style={{display: "flex", flexDirection:"column"}}>
                                  <div className="row customFormRowDivPopup">
                                    <div className="row customFormRowDiv">
                                      <div className="column customOrderDiv">
                                        <Button onClick={()=>{this.moveFieldUp(index)}} title="Move Up" className="buttonIconSmall buttonAction dehighlighted halfHeight">
                                          <ArrowUpwardSharpIcon className="buttonIcon noLabel" style={{paddingBottom:6}}/>
                                        </Button>
                                        <Button onClick={()=>{this.moveFieldDown(index)}} title="Move Down" className="buttonIconSmall buttonAction dehighlighted halfHeight">
                                          <ArrowDownwardSharpIcon className="buttonIcon noLabel" style={{paddingBottom:6}}/>
                                        </Button>
                                      </div>
                                      <label className="accountHeader customFormLabel">Field Name:</label>
                                      <input id={"customFormFieldName" + index} value={field.Name} maxLength="50" className = "form-control customFormRowControl input large"
                                        onChange ={e =>{ if(this.state.currentFormEdit.Type == 'Pipeline Survey' && ((e.target.value == 'Status' && field.Type == 'Drop Down') || (e.target.value == "AssignedUserId" && (field.Type == "Text" || !field.Type)) || (e.target.value == "TaskCounter" && field.Type == "Number")) ){this.setState({updateStatusText : 'Can not name this field ' + e.target.value, alertSeverity : 'error'}); return;} if(e.target.value == "Project" || e.target.value == "project"){this.setState({updateStatusText : 'Can not name this field ' + e.target.value, alertSeverity : 'error'}); return;} let currentFormEdit = this.state.currentFormEdit; currentFormEdit.Fields[index].Name = e.target.value;this.setState({currentFormEdit})}} />

                                      <label className="accountHeader customFormLabel">Type:</label>
                                      <select id={"customFormFieldType" + index} className = "form-control customFormRowControl" value = {field.Type}
                                        onChange ={e =>{ if(this.state.currentFormEdit.Type == "Pipeline Survey" && ((field.Name == "Status" && e.target.value == "Drop Down") || (field.Name == "AssignedUserId" && (e.target.value == "Text" || !e.target.value)) || (field.Name == "TaskCounter" && e.target.value == "Number"))) {this.setState({updateStatusText : 'Can not have a field with the name ' + field.Name + " and the type " + e.target.value, alertSeverity : 'error'}); if(!field.Type || !field.Type == ""){let currentFormEdit = this.state.currentFormEdit; currentFormEdit.Fields[index].Type = "";this.setState({currentFormEdit})} return;} let currentFormEdit = this.state.currentFormEdit; currentFormEdit.Fields[index].Type = e.target.value;this.setState({currentFormEdit})}} >
                                        <option></option><option>Number</option><option>Text</option><option>Date</option><option>Time</option><option>Drop Down</option><option value="Yes or No">Yes/No</option><option>Comments</option>
                                        <option value="Scanner">Code Scanner (UPC/QR)</option><option value="Signature">Signature</option><option value="Section">Section Header</option>
                                        <option value="MultiSelect">Multiple Select</option>
                                      </select>

                                      {(field.Type == "Drop Down" || field.Type == "MultiSelect") &&
                                        <Button className="buttonAction btn btn-primary dehighlighted small short" title="Custom Field Values" onClick={() =>{this.editCustomValues(index)}} color="primary">
                                          {!field.ValueExpand && <ExpandMoreIcon className="buttonIcon withLabel"/>}
                                          {field.ValueExpand && <ExpandLessIcon className="buttonIcon withLabel"/>}
                                          <label className="buttonText small xsmall withIcon" style={{ margin: 0, cursor: "pointer" }}>Values</label>
                                        </Button>
                                      }

                                      <span className="customFieldAttr" title="Make it so you can repeat the field in the app."> 
                                        <div className="customFormControlGroup">
                                          <label className="accountHeader customFormLabel">Repeat:</label>
                                          <input type="checkbox" id={"customFormFieldRepeatGroup" + index} checked={field.RepeatGroup == true} value={field.RepeatGroup == true} className = "form-control customFormRowControl xsmall"
                                            onChange ={e =>{ let currentFormEdit = this.state.currentFormEdit; currentFormEdit.Fields[index].RepeatGroup = e.target.checked;this.setState({currentFormEdit})}}/>
                                        </div>
                                      </span>

                                      {field.Type != "Section" &&
                                        <span className="customFieldAttr" title="Make filling out this field required in order to save the form."> 
                                          <div className="customFormControlGroup"><label className="accountHeader customFormLabel">Require:</label>
                                            <input type="checkbox" id={"customFormFieldRequired" + index} checked={field.Required == true} value={field.Required == true} className = "form-control customFormRowControl xsmall"
                                              onChange ={e =>{ let currentFormEdit = this.state.currentFormEdit; currentFormEdit.Fields[index].Required = e.target.checked;this.setState({currentFormEdit})}}/>
                                            </div>
                                          <div className="customFormControlGroup" title="Make adding a photo with this field required in order to save the form.">
                                            <label className="accountHeader customFormLabel">Require Photo:</label>
                                            <input type="checkbox" id={"customFormFieldRequiredPhoto" + index} checked={field.RequiredPhoto == true} value={field.RequiredPhoto == true} className = "form-control customFormRowControl xsmall"
                                              onChange ={e =>{ let currentFormEdit = this.state.currentFormEdit; currentFormEdit.Fields[index].RequiredPhoto = e.target.checked;this.setState({currentFormEdit})}}/>
                                          </div>
                                          <div className="customFormControlGroup" title="Make this field uneditable."><label className="accountHeader customFormLabel">Read Only:</label>
                                            <input type="checkbox" id={"readonlyField" + index} checked={field.ReadOnly == true} value={field.ReadOnly == true} className="form-control customFormRowControl xsmall"
                                              onChange={e => { let currentFormEdit = this.state.currentFormEdit; currentFormEdit.Fields[index].ReadOnly = e.target.checked; this.setState({currentFormEdit})}}/>
                                          </div>
                                        </span>
                                      }

                                      {/* <label className="accountHeader customFormLabel">Searchable:</label>
                                      <input type="checkbox" id={"customFormFieldRequired" + index} value={field.Search == true} className = "form-control customFormRowControl xsmall"
                                        onChange ={e =>{ let currentFormEdit = this.state.currentFormEdit; currentFormEdit.Fields[index].Searchable = e.target.value;this.setState({currentFormEdit})}} /> */}

                                    </div>
                                    {<Button onClick={()=>{this.deleteCustomField(index)}} title="Remove Field" className="buttonAction buttonAccount notificationRowButton dehighlighted ">
                                      <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                    </Button>}
                                  </div>
                                
                                  {(field.Type == "Drop Down" || field.Type == "MultiSelect") && field.ValueExpand &&
                                    <div className="customFormValueDropDiv">
                                      {field.CustomValues.map((value, valueindex) =>(
                                        <div key={valueindex} style={{display: "flex", flexDirection:"column"}}>
                                          <div className="row customFormRowDivCustomValue">
                                          <input id={"customFormValueName" + valueindex} value={value} className = "form-control customFormRowControlCustomValueEdit customValue"
                                            onChange ={e =>{ let currentFormEdit = this.state.currentFormEdit; currentFormEdit.Fields[index].CustomValues[valueindex] = e.target.value;this.setState({currentFormEdit})}} />
                                          <Button onClick={()=>{this.deleteCustomValue(index, valueindex)}} title="Remove Value" style={{minWidth:36}}
                                            className="buttonAction buttonAccount notificationRowButton dehighlighted inputTight">
                                            <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                          </Button>
                                          </div>
                                        </div>
                                      ))}
                                      <div className="customFieldValueControl">
                                        <div className="customFieldValueControlDiv">
                                          <Button style= {{width: 124}} onClick={()=>{this.addFieldValue(index)}} className = "buttonAction buttonAccount dehighlighted small nomargin short">
                                            <AddBoxSharpIcon className="buttonIcon withText"/>
                                            <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon xsmall ">Add Value</label>
                                          </Button>
                                          <Button style= {{width: 58}} onClick={()=>{this.editCustomValues(index)}} className = "buttonAction buttonAccount dehighlighted small nomargin short">
                                            <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon xsmall ">Close</label>
                                          </Button>
                                        </div>
                                      </div>
                                      <div className="customFieldValueControl">
                                        <div style={{justifyContent : 'space-between'}}>
                                          <Button className="buttonAction btn btn-primary dehighlighted small nomargin short" style={{width : 125, marginLeft : 4}} color="primary">
                                            <label className="buttonText withIcon xsmall" style={{ margin: 0, cursor: 'pointer'}}>Import Values</label>
                                          </Button>
                                          <input type="file" style={{width:155, marginLeft : 4, bottom : 35}} id={"uploadCustomValuesFor" + index} onClick={() =>{this.value=null;}} accept=".txt" onChange={()=>{this.importCustomValues(index)}} className="form-control-file attachDocumentButton"/>
                                          <Button onClick={()=>{this.sortCustomValues(index)}}style={{position : 'relative', bottom : 81, left : 142, color : 'white'}} className = "buttonAction buttonAccount dehighlighted small nomargin short">
                                            <label style ={{ margin: 0, cursor: 'pointer'}} className ="withIcon xsmall buttonText ">
                                              <SortByAlphaIcon style={{color : 'white'}} className="buttonIcon" color="white"/>
                                            </label>
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                </div>
                              ))}
                              
                              <div className="row" style={{ marginTop: 10 }}>
                                <div className="justify-content-left">
                                </div>
                              </div>

                            </DialogContent>
                            <DialogActions>
                              
                              <Button onClick={()=>{this.addField()}} className = "buttonAction buttonAccount minWidth" style={{left:24, position:'absolute'}}>
                                <AddBoxSharpIcon className="buttonIcon withText"/>
                                <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon xsmall ">Add Field</label>
                              </Button>

                              <Button className="buttonAction dehighlighted btn btn-primary minWidth" onClick={() =>{this.saveCustomForm(false, true)}}>
                                <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Cancel</label>
                              </Button>
                              <Button className="buttonAction btn btn-primary minWidth" onClick={() =>{this.saveCustomForm(true, true)}} color="primary">
                                <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Save</label>
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </React.Fragment>
                      </Tab.Pane>
                    }
                    {
                      <Tab.Pane className="d-flex flex-column w-100 p-0 m-0 justify-content-center" eventKey="notifications" id="notifications" mountOnEnter unmountOnExit>
                        <React.Fragment>
                            {/* NOTIFICATION ALERTS */}
                          <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10}}>Notification Alerts
                            <div className="labelHeaderBarSubHeader">Create automated email notifications to alert to specific data-driven events</div>
                          </div>
                          <div className="licenseEditDivContent">
                            {this.state.uploadingPDFAlert && <div className="row d-flex align-items-center justify-content-center">
                                      <div className=" d-flex align-items-center justify-content-center authSpinnerDiv">
                                      <label className =" withIcon">UPLOADING PDF...</label>
                                        <div className="barLoaderPortal">
                                        <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true } /> </div>
                                    </div>
                                  </div>}
                          {!this.state.uploadingPDFAlert && this.state.Triggers && this.state.Triggers.map((trigger,index) =>(!trigger.DeletedDate && 
                          <div key={index} style={{display: "flex", flexDirection:"column"}}>
                            {trigger.Field == "CustomForm" ? 
                            <div className="row notificationRowDiv">
                                <label htmlFor={"pickTrigger" +index} className="accountHeader notificationLabel">{trigger.CustomFormNotificationObject.CustomFormType}: </label>
                                <select id={"pickTrigger"+index} className="form-control notificationRowControl" 
                                  onChange={e => {
                                    let triggers = this.state.Triggers;
                                    if(triggers[index].CustomFormNotificationObject){
                                      let form = this.state.FormCollection.find(x => x.FormId == e.target.value);
                                      if(form){
                                        triggers[index].CustomFormNotificationObject.CustomFormId = form.FormId;
                                        triggers[index].CustomFormNotificationObject.CustomFormName = form.Name;
                                        triggers[index].CustomFormNotificationObject.CustomFields = form.Fields; 
                                        triggers[index].CustomFormNotificationObject.CustomFormType = form.Type;
                                      }
                                      else{
                                        triggers[index].CustomFormNotificationObject = {CustomFields: [], CustomFieldValue : "any"}
                                      }
                                      this.setState({Triggers : triggers});
                                    }
                                  }}>
                                  <option></option>
                                  {this.state.FormCollection.map((form, index) => (!form.Secured  ? <option key={index} value={form.FormId} label={form.Name} selected={trigger.CustomFormNotificationObject.CustomFormId == form.FormId}></option> : null))}
                                </select>
                                <label htmlFor={"Field"+index} style={{marginLeft : 10}} className="accountHeader notificationLabel">If : </label>
                                <select id={"Field"+index} className="form-control notificationRowControl" value={trigger.CustomFormNotificationObject.CustomFormField}
                                  onChange={e =>{
                                    let triggers = this.state.Triggers;
                                    if(triggers[index].CustomFormNotificationObject){
                                      triggers[index].CustomFormNotificationObject.CustomFormField = e.target.value;
                                      if(e.target.value == "Task Status"){
                                        triggers[index].CustomFormNotificationObject.TriggerFieldValue = "open";
                                        triggers[index].CustomFormNotificationObject.CustomFieldValue = "specific";
                                      }
                                      this.setState({Triggers : triggers});
                                    }
                                  }}>
                                    <option></option>
                                    {(trigger.CustomFormNotificationObject.CustomFormType == "Ticket Survey") && 
                                    <option value="Ticket In Area">Ticket In Area</option>}
                                    {(trigger.CustomFormNotificationObject.CustomFormType == "Ticket Survey") && 
                                    <option value="Ticket Not In Area">Ticket Not In Area</option>}
                                    <option value="Task Status">Task Status</option>
                                    {this.state.Triggers[index].CustomFormNotificationObject.CustomFields.map((field, index) => (<option key={index} value={field.Name}>{field.Name}</option>))} 
                                    {/* {trigger.CustomFormNotificationObject.CustomFormType == "GPS Survey" && <option></option>} */}
                                  </select>
                                  {(trigger.CustomFormNotificationObject.CustomFormField != "Ticket In Area" && trigger.CustomFormNotificationObject.CustomFormField != "Ticket Not In Area"&& trigger.CustomFormNotificationObject.CustomFormField != "Task Status") && <select id={"CustomFieldValue" + index} className = "form-control notificationRowControl" 
                                    value = {trigger.CustomFormNotificationObject.CustomFieldValue}
                                    onChange ={ e => { 
                                      let triggers = this.state.Triggers;
                                      if(triggers[index].CustomFormNotificationObject){
                                        triggers[index].CustomFormNotificationObject.CustomFieldValue = e.target.value;
                                        this.setState({Triggers : triggers});
                                      }
                                     }} >
                                    <option value='any' >Has Any Value</option>
                                    <option value='specific' >Has Specific Value:</option>
                                  </select>}
                                  {(trigger.CustomFormNotificationObject.CustomFormField == "Ticket In Area" || trigger.CustomFormNotificationObject.CustomFormField == "Ticket Not In Area") &&
                                  <select id={"CustomFieldValue" + index} className = "form-control notificationRowControl" 
                                  value = {trigger.CustomFormNotificationObject.TriggerFieldValue}
                                  onChange ={ e => { 
                                    let triggers = this.state.Triggers;
                                    if(triggers[index].CustomFormNotificationObject){
                                      triggers[index].CustomFormNotificationObject.TriggerFieldValue = e.target.value;
                                      triggers[index].CustomFormNotificationObject.CustomFieldValue = "specific";
                                      this.setState({Triggers : triggers});
                                    }
                                   }} >
                                    <option></option>
                                  {this.state.ticketAssignmentAreas.map((area, areaIndex) => (<option key={areaIndex} value={area.AssignmentAreaId}>{area.Label}</option>)) }
                                  {this.state.CompanyArcGisLayers.filter(x => x.LayerType == "Analysis").map((area, areaIndex) => (<option key={areaIndex} value={"AnalysisLayer:"+area.CompanyArcGisLayerId}>{area.Name}</option>)) }
                                  {this.state.CompanyArcGisLayerFeatures.map((area,areaIndex) => (<option key={areaIndex} value={"TicketArea:" + area.CompanyArcGISLayerId +"|" + area.FeatureId + "|" + area.FeatureName}>{area.FeatureName}</option>))}
                                </select> }
                                {trigger.CustomFormNotificationObject.CustomFormField == "Task Status" && 
                                <select id={"CustomFieldValue" + index} className="form-control notificationRowControl"
                                value ={trigger.CustomFormNotificationObject.TriggerFieldValue}
                                  onChange={e=>{
                                    let triggers = this.state.Triggers;
                                    if(triggers[index].CustomFormNotificationObject){
                                      triggers[index].CustomFormNotificationObject.TriggerFieldValue = e.target.value;
                                      triggers[index].CustomFormNotificationObject.CustomFieldValue = "specific";
                                      this.setState({Triggers : triggers});
                                    }
                                  }}>
                                    <option value="open">Open</option>
                                    <option value="closed">Closed</option>
                                    <option value="project">Project</option>
                                    <option value="cancled">Canceled</option>
                                    </select>}
                                  {trigger.CustomFormNotificationObject.CustomFieldValue == 'any' ? <div style={{display : 'inherit'}}>
                                  <label htmlFor={"notificationEmail"+ index} className="accountHeader notificationRowControl small notificationLabel">Email:</label>
                                  <input id={"notificationEmail" + index} value={trigger.Emails} className = "form-control notificationRowControl largeEmail" 
                                    onChange={e => { let Triggers = this.state.Triggers; Triggers[index].Emails = e.target.value;this.setState({ Triggers }); }}/>
                                  </div> :
                                  <div style={{display : 'inherit'}}>
                                    {(this.getCustomValues(trigger) && (trigger.CustomFormNotificationObject.CustomFormField && trigger.CustomFormNotificationObject.CustomFields && trigger.CustomFormNotificationObject.CustomFormField && trigger.CustomFormNotificationObject.CustomFields.find(x => x.Name == trigger.CustomFormNotificationObject.CustomFormField) && (trigger.CustomFormNotificationObject.CustomFields.find(x => x.Name == trigger.CustomFormNotificationObject.CustomFormField).Type == 'Drop Down'|| trigger.CustomFormNotificationObject.CustomFields.find(x => x.Name == trigger.CustomFormNotificationObject.CustomFormField).Type == 'MultiSelect'))) ? 
                                      <select id={"notificationFieldCustomValue" + index} className="form-control notificationRowControl"
                                        value={trigger.CustomFormNotificationObject.TriggerFieldValue}
                                        onChange={e => {this.updateCustomFormNotificationTriggerFieldValue(e,index,true)}}>
                                        <option></option>
                                        {this.getCustomValues(trigger).map((value,index) => (
                                          <option key={index} value={value}>{value}</option>
                                        ))}
                                      </select> :
                                      trigger.CustomFormNotificationObject.CustomFormField && trigger.CustomFormNotificationObject.CustomFields && trigger.CustomFormNotificationObject.CustomFormField && trigger.CustomFormNotificationObject.CustomFields.find(x => x.Name == trigger.CustomFormNotificationObject.CustomFormField) && trigger.CustomFormNotificationObject.CustomFields.find(x => x.Name == trigger.CustomFormNotificationObject.CustomFormField).Type == 'Date' ?
                                      <div>
                                        <DatetimePicker name="dateFrom" value={trigger.CustomFormNotificationObject.TriggerFieldValue} style={{height : 10}} showTime={false} buttonClasses={"notificationRowControl datetime"}
                                          onChange={e => { this.updateCustomFormNotificationTriggerFieldValue(e,index,false)}}/>
                                      </div> :
                                      trigger.CustomFormNotificationObject.CustomFormField && trigger.CustomFormNotificationObject.CustomFields && trigger.CustomFormNotificationObject.CustomFormField && trigger.CustomFormNotificationObject.CustomFields.find(x => x.Name == trigger.CustomFormNotificationObject.CustomFormField) && trigger.CustomFormNotificationObject.CustomFields.find(x => x.Name == trigger.CustomFormNotificationObject.CustomFormField).Type == 'Time' ?
                                      <div>
                                        <DatetimePicker name="dateFrom" value={trigger.CustomFormNotificationObject.TriggerFieldValue} style={{height : 10}} showTime={true} buttonClasses={"notificationRowControl datetime"}
                                          onChange={e => { this.updateCustomFormNotificationTriggerFieldValue(e,index,false)}}/>
                                      </div> :
                                      (trigger.CustomFormNotificationObject.CustomFieldValue == "specific" && (trigger.CustomFormNotificationObject.CustomFormField != "Ticket In Area" && trigger.CustomFormNotificationObject.CustomFormField != "Ticket Not In Area" && trigger.CustomFormNotificationObject.CustomFormField != "Task Status")) ?
                                        <input style={{position : 'relative', bottom : 2}} id={"notificationFieldCustomValue" + index} value={trigger.CustomFormNotificationObject.TriggerFieldValue} className = "form-control customFormRowControlCustomValueEdit customValue"
                                        onChange = {e => { this.updateCustomFormNotificationTriggerFieldValue(e, index, false); }} 
                                        /> : null}
                                      <label htmlFor={"notificationEmail"+ index} className="accountHeader notificationRowControl small notificationLabel">Email:</label>
                                      <input id={"notificationEmail" + index} value={trigger.Emails} className = "form-control notificationRowControl largeEmail"
                                        onChange={e => { let Triggers = this.state.Triggers; Triggers[index].Emails = e.target.value;this.setState({ Triggers }); }}/>
                                  </div>}
                                  <label htmlFor={"attachPDF"+ index} className="accountHeader notificationRowControl small notificationLabel">Attach PDF:</label>
                                  <input type="checkbox" id={"attachPDF"+ index} checked={trigger.AttachPDF ? 'checked' : ''} value={trigger.AttachPDF} className = "form-control customFormRowControl xsmall"
                                            onChange ={e => { let Triggers = this.state.Triggers; Triggers[index].AttachPDF = e.target.checked;this.setState({ Triggers });} } />
                                  <Button onClick={()=>{this.deleteNotification(index);}} className="buttonAction buttonAccount notificationRowControl notificationRowButton">
                                    <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                  </Button>
                            </div> : 
                            <div className="row notificationRowDiv">
                              
                              <label htmlFor={"pickTrigger" + index} className="accountHeader notificationLabel">{trigger.Field == "SiteSafe" ? "Site Safe" : "GIS Survey"}:</label>
                              
                              {trigger.Field != "SiteSafe" && <select id={"pickTrigger" + index} className = "form-control notificationRowControl" value = {trigger.Field}
                                  onChange ={e =>{ let triggers = this.state.Triggers; triggers[index].Field = e.target.value;this.setState({Triggers : triggers}); }} >
                                  <option></option>
                                  <option>Leak Class</option>
                                  <option>Corrosion Grade</option>
                              </select>}
                              { trigger.Field != 'SiteSafe' && <select id={"greaterThanLessThan" + index} className = "form-control notificationRowControl"
                                value = {trigger.Equality == "<" ? "Less or Equal to" : "Greater or Equal to"}
                                onChange = {e => {let triggers = this.state.Triggers;
                                  triggers[index].Equality = e.target.value == "Less or Equal to" ? "<" : ">";
                                  this.setState({Triggers : triggers}) }}>
                                <option>Less or Equal to</option>
                                <option>Greater or Equal to</option>
                              </select> }
                              {trigger.Field != 'SiteSafe' && <select id={"triggerGrade" + index} className="form-control notificationRowControl small" value={trigger.Grade}
                                onChange={e =>{let triggers = this.state.Triggers; triggers[index].Grade = e.target.value;this.setState({Triggers : triggers})}}>
                                  <option></option>
                                  <option>Grade 1</option>
                                  <option>Grade 2</option>
                                  <option>Grade 3</option>
                              </select>}

                              {trigger.Field == 'SiteSafe' && <select id={"triggerGrade" + index} className="form-control notificationRowControl small" value={trigger.Grade}
                                onChange={e =>{let triggers = this.state.Triggers; triggers[index].Grade = e.target.value;this.setState({Triggers : triggers})}}>
                                  <option></option>
                                  <option>Pass</option>
                                  <option>Fail</option>
                              </select>}

                              <label htmlFor={"notificationEmail"+ index} className="accountHeader notificationRowControl small notificationLabel">Email:</label>
                              <input id={"notificationEmail" + index} value={trigger.Emails} className = "form-control notificationRowControl largeEmail"
                                onChange={e => { let Triggers = this.state.Triggers; Triggers[index].Emails = e.target.value;this.setState({ Triggers }); }}/>
                              <label htmlFor={"attachPDF"+ index} className="accountHeader notificationRowControl small notificationLabel">Attach PDF:</label>
                              <input type="checkbox" id={"attachPDF"+ index}  checked={trigger.AttachPDF ? 'checked' : ''} value={trigger.AttachPDF} className = "form-control customFormRowControl xsmall"
                                  onChange ={e => { let Triggers = this.state.Triggers; Triggers[index].AttachPDF = e.target.checked;this.setState({ Triggers });} } />
                              <Button onClick={()=>{this.deleteNotification(index);}} className="buttonAction buttonAccount notificationRowControl notificationRowButton">
                                <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                              </Button>
                            </div>}
                          </div>))}
                          {!this.state.uploadingPDFAlert && this.state.downloadSubscriptions && this.state.downloadSubscriptions.length > 0 && this.state.downloadSubscriptions.map((subscription, index) => ((!subscription.DeletedDate &&( subscription.Frequency == "OnCreation" ||  subscription.Frequency == "OnUpdate")) && 
                              <div key={index} style={{display : 'flex', flexDirection : 'column'}}>
                              <div className="row notificationRowDiv">
                              <label htmlFor={"label"+ index} className="accountHeader small downloadSubscriptionLabel" style={{marginLeft : 5}}>Label:</label>
                                <input id={"label" + index} value={subscription.Label} style={{maxWidth : 130}} className = "form-control downloadSubscriptionControl largeEmail"
                                  onChange={e => { let downloadSubscriptions = this.state.downloadSubscriptions; downloadSubscriptions[index].Label = e.target.value;this.setState({ downloadSubscriptions }); }}/>
                              <label htmlFor={"pickTrigger" +index} className="accountHeader notificationLabel">{subscription.FormIdList && subscription.FormIdList.length > 0 && this.state.FormCollection && this.state.FormCollection.length > 0 && this.state.FormCollection.find(x => x.FormId == subscription.FormIdList[0]) ? this.state.FormCollection.find(x => x.FormId == subscription.FormIdList[0]).Type : ""}: </label>
                                <select id={"pickTrigger"+index} className="form-control notificationRowControl" value={subscription.FormIdList && subscription.FormIdList.length > 0  ? subscription.FormIdList[0] : ""}
                                  onChange={e => {
                                    let downloadSubscriptions = this.state.downloadSubscriptions;
                                    let sub = downloadSubscriptions[index];
                                    sub.FormIdList[0] = e.target.value;
                                    let form = this.state.FormCollection.find(x => x.FormId == e.target.value);
                                    if(form && sub.Triggers && sub.Triggers.length > 0){
                                      sub.Triggers.forEach(trigger =>{
                                        trigger.CustomFormId = form.FormId;
                                        trigger.CustomFormType = form.Type;
                                        trigger.CustomFormName = form.Name;
                                        trigger.CustomFormField = null;
                                        trigger.CustomFields = form.Fields;
                                        trigger.CustomFieldValue = null;
                                      });
                                    }
                                    this.setState({downloadSubscriptions});
                                  }}>
                                  <option></option>
                                  {this.state.FormCollection.map((form, index) => (!form.Secured  ? <option value = {form.FormId} key={index}>{form.Name}</option> : null))}
                                </select>
                                <label htmlFor={"subscriptionFrequency" + index} className="accountHeader downloadSubscriptionLabel">FREQUENCY:</label>
                                  <select id={"subscriptionFrequency" + index} className="form-control downloadSubscriptionControl" value={this.state.downloadSubscriptions[index].Frequency}
                                    onChange={e =>{
                                      let downloadSubscriptions = this.state.downloadSubscriptions;
                                      downloadSubscriptions[index].Frequency = e.target.value;
                                      this.setState({downloadSubscriptions});
                                    }}>
                                    <option></option>
                                    <option value="OnCreation">On Creation</option>
                                    <option value="OnUpdate">All Updates</option>
                                  </select>
                                {
                                <label htmlFor={"contractorNotifTypeSub"+ index} className="accountHeader notificationRowControl  notificationLabel">Notification Type:</label>}
                              {
                                <select id={"contractorNotifTypeSub"+index} className="form-control notificationRowControl" style={{maxWidth: 155}} value={subscription.NotificationType} onChange={e => { let downloadSubscriptions = this.state.downloadSubscriptions; downloadSubscriptions[index].NotificationType = e.target.value;this.setState({ downloadSubscriptions }); }}>
                                  <option value=""></option>
                                  <option value="email">email</option>
                                  {(subscription.FormIdList && subscription.FormIdList.length > 0 && this.state.FormCollection && this.state.FormCollection.length > 0 && this.state.FormCollection.find(x => x.FormId == subscription.FormIdList[0]) && this.state.FormCollection.find(x => x.FormId == subscription.FormIdList[0]).Type == "Ticket Survey" ) && <option value="contractor">@contractor email</option>}
                                  <option value="phone">phone</option>
                                  </select>}


                                {(!subscription.NotificationType || subscription.NotificationType == "email") && <label htmlFor={"subscriptionEmail"+ index} className="accountHeader small downloadSubscriptionLabel" style={{marginLeft : 5}}>Email:</label>}
                                {(!subscription.NotificationType || subscription.NotificationType == "email") && <input id={"subscriptionEmail" + index} value={subscription.Emails} style={{maxWidth : 130}} className = "form-control downloadSubscriptionControl largeEmail"
                                  onChange={e => { let downloadSubscriptions = this.state.downloadSubscriptions; downloadSubscriptions[index].Emails = e.target.value;this.setState({ downloadSubscriptions }); }}/>}
                                {(subscription.NotificationType && subscription.NotificationType == "phone") && <label htmlFor={"subscriptionEmail"+ index} className="accountHeader small downloadSubscriptionLabel" style={{marginLeft : 5}}>Phone:</label>}
                                {(subscription.NotificationType && subscription.NotificationType == "phone") && <input id={"subscriptionEmail" + index} value={subscription.Phone} style={{maxWidth : 145}} className = "form-control downloadSubscriptionControl largeEmail"
                                  onChange={e => { let downloadSubscriptions = this.state.downloadSubscriptions; downloadSubscriptions[index].Phone = e.target.value;this.setState({ downloadSubscriptions }); }}/>}
                                
                               { subscription.NotificationType && subscription.NotificationType != "phone" && <label htmlFor={"attachPDFSub"+ index} className="accountHeader notificationRowControl small notificationLabel">Attach PDF:</label>}
                              {subscription.NotificationType && subscription.NotificationType != "phone" && <input type="checkbox" id={"attachPDFSub"+ index}  checked={subscription.AttachPDF ? 'checked' : ''} value={subscription.AttachPDF} className = "form-control customFormRowControl xsmall"
                                  onChange ={e => { let downloadSubscriptions = this.state.downloadSubscriptions; downloadSubscriptions[index].AttachPDF = e.target.checked;this.setState({ downloadSubscriptions });} } />}
                                {(subscription.FormIdList && subscription.FormIdList.length > 0 && this.state.FormCollection && this.state.FormCollection.length > 0 && this.state.FormCollection.find(x => x.FormId == subscription.FormIdList[0]) && this.state.FormCollection.find(x => x.FormId == subscription.FormIdList[0]).Type == "Ticket Survey" && subscription.AttachPDF && subscription.NotificationType != "phone") && <div id={"pdfList" + index} className="dropdown-check-list" >
                                    <span className="anchor" style={{width: 150}} onClick={() =>{
                                    let checkList = document.getElementById("pdfList"+index);
                                    if(checkList.classList.contains("visible"))checkList.classList.remove("visible");
                                    else checkList.classList.add("visible");
                                  }}>{subscription.AlertPDFId ? (subscription.AlertPDFId == -1 ? "Ticket Data" : this.state.alertPdfs.find(x => x.MultimediaAssetId == subscription.AlertPDFId) ? this.state.alertPdfs.find(x => x.MultimediaAssetId == subscription.AlertPDFId).Label : "Select PDF") : "Select PDF"}</span>
                                    <ul className="items">
                                      <li style ={{ margin: 0, cursor: 'pointer'}} onClick={() =>{
                                         let downloadSubscriptions = this.state.downloadSubscriptions; downloadSubscriptions[index].AlertPDFId = -1;
                                         this.setState({ downloadSubscriptions });
                                         let checkList = document.getElementById("pdfList"+index);
                                         if(checkList.classList.contains("visible"))checkList.classList.remove("visible");
                                         else checkList.classList.add("visible");
                                        }}
                                     ><div>Ticket Data</div></li>
                                      {(this.state.alertPdfs && this.state.alertPdfs.length > 0) && this.state.alertPdfs.map((pdf, pdfIndex) => 
                                      <li style ={{ margin: 0, cursor: 'pointer'}} key = {pdfIndex} onClick={() =>{
                                        let downloadSubscriptions = this.state.downloadSubscriptions; downloadSubscriptions[index].AlertPDFId = pdf.MultimediaAssetId;
                                        this.setState({ downloadSubscriptions });
                                        let checkList = document.getElementById("pdfList"+index);
                                        if(checkList.classList.contains("visible"))checkList.classList.remove("visible");
                                        else checkList.classList.add("visible");
                                       }}>
                                        {pdf.Label}
                                      </li>)}
                                      <li>
                                        <div className="">
                                          <label style ={{ margin: 0, cursor: 'pointer'}} className ="">Upload New PDF</label>
                                          <input type="file" className="form-control-file" accept=".pdf" id={"uploadAlertPDF" + index} style={{position : 'relative', bottom : 25, opacity : 0}} onChange={() => {this.uploadAlertPDF(index)}}/>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>}

                                <Button onClick={()=>{let downloadSubscriptions = this.state.downloadSubscriptions;
                                         downloadSubscriptions[index].showTriggers = !downloadSubscriptions[index].showTriggers;
                                         this.setState({ downloadSubscriptions });}} className = "buttonAction buttonAccount small">
                                    {subscription.showTriggers && <ExpandLessIcon className="buttonIcon small"/>}
                                    {!subscription.showTriggers && <ExpandMoreIcon className="buttonIcon small"/>}
                                  </Button>
                                  <Button onClick={()=>{this.addDownloadSubTrigger(index, true);}} className = "buttonAction buttonAccount small">
                                    <AddBoxSharpIcon className="buttonIcon small"/>
                                    <label style ={{ margin: 0, cursor: 'pointer', fontSize:12}} className ="buttonText">Add Trigger</label>
                                  </Button>
                                <Button onClick={()=>{this.deleteDownloadSubscription(index);}} className="buttonAction buttonAccount notificationRowControl notificationRowButton">
                                  <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                </Button>
                              </div>
                              {(subscription.showTriggers && (subscription.Triggers && subscription.Triggers.length > 0)) && subscription.Triggers.map((trigger,t_index) => (
                                <div key={t_index} style={{position : "relative", left : 25, width: "75%"}} className="row notificationRowDiv">
                                  <label htmlFor={"downloadSubscriptionFieldFormTrigger" + t_index} style={{marginLeft : 10}} className="accountHeader notificationLabel">If: </label>
                                  <select id={"downloadSubscriptionFieldFormTrigger" + t_index} className="form-control notificationRowControl" value={trigger.CustomFormField}
                                  onChange={e =>{
                                    let downloadSubscriptions = this.state.downloadSubscriptions;
                                    downloadSubscriptions[index].Triggers[t_index].CustomFormField = e.target.value;
                                    this.setState({downloadSubscriptions});
                                  }}>
                                    <option></option>
                                    {/* TODO: UPDATE WITH ANY NEW config ids */}
                                    {(trigger.CustomFormType == "Ticket Survey" && this.state.distinctMemberCodes && this.state.distinctMemberCodes[trigger.CustomFormName == "Ticket NV" ? 1 : 2] && this.state.distinctMemberCodes[trigger.CustomFormName == "Ticket NV" ? 1 : 2].length > 0) && 
                                    this.state.distinctMemberCodes[trigger.CustomFormName == "Ticket NV" ? 1 : 2].map((config, configIndex) =>
                                      <option key={configIndex} value={"Response: " + config}>{"Response: " + config}</option>)}
                                    {(trigger.CustomFormType == "Ticket Survey") && 
                                      <option value="Ticket In Area">Ticket In Area</option>
                                    }
                                    {(trigger.CustomFormType == "Ticket Survey") && 
                                      <option value="Ticket Not In Area">Ticket Not In Area</option>
                                    }
                                    <option value="Task Status">Task Status</option>
                                    {trigger.CustomFields && trigger.CustomFields.map((field, cField_index) =>
                                      <option key={cField_index} value={field.Name}>{field.Name}</option>)
                                    }
                                  </select>

                                  {(trigger.CustomFormField != "Ticket In Area" && trigger.CustomFormField != "Ticket Not In Area" && trigger.CustomFormField != "Task Status") && <select id={"downloadSubsctiptionCustomFieldValueForTrigger" + t_index} className = "form-control notificationRowControl" 
                                    value = {trigger.CustomFieldValue}
                                    onChange ={ e => { 
                                      let downloadSubscriptions = this.state.downloadSubscriptions;
                                      downloadSubscriptions[index].Triggers[t_index].CustomFieldValue = e.target.value;
                                      this.setState({downloadSubscriptions});
                                     }} >
                                    <option value='any' >Has Any Value</option>
                                    <option value='specific' >Has Specific Value:</option>
                                  </select>}
                                  {(trigger.CustomFormField == "Ticket In Area" || trigger.CustomFormField == "Ticket Not In Area") &&
                                    <select id={"CustomFieldValue" + index} className = "form-control notificationRowControl" 
                                      value = {trigger.TriggerFieldValue}
                                      onChange ={ e => {
                                        let downloadSubscriptions = this.state.downloadSubscriptions;
                                        downloadSubscriptions[index].Triggers[t_index].TriggerFieldValue = e.target.value;
                                        downloadSubscriptions[index].Triggers[t_index].CustomFieldValue = "specific";
                                        this.setState({downloadSubscriptions}); 
                                      }} >
                                        <option></option>
                                      {this.state.ticketAssignmentAreas.map((area, areaIndex) => (<option key={areaIndex} value={area.AssignmentAreaId}>{area.Label}</option>)) }
                                      {this.state.CompanyArcGisLayers.filter(x => x.LayerType == "Analysis").map((area, areaIndex) => (<option key={areaIndex} value={"AnalysisLayer:" +area.CompanyArcGisLayerId}>{area.Name}</option>))}
                                      {this.state.CompanyArcGisLayerFeatures.map((area,areaIndex) => (<option key={areaIndex} value={"TicketArea:" + area.CompanyArcGISLayerId +"|" + area.FeatureId + "|" + area.FeatureName}>{area.FeatureName}</option>))}
                                    </select> 
                                  }
                                  {trigger.CustomFormField == "Task Status" && 
                                <select id={"CustomFieldValue" + index} className="form-control notificationRowControl"
                                value ={trigger.TriggerFieldValue}
                                  onChange={e=>{
                                    let downloadSubscriptions = this.state.downloadSubscriptions;
                                        downloadSubscriptions[index].Triggers[t_index].TriggerFieldValue = e.target.value;
                                        downloadSubscriptions[index].Triggers[t_index].CustomFieldValue = "specific";
                                        this.setState({downloadSubscriptions}); 
                                  }}>
                                    <option value="open">Open</option>
                                    <option value="closed">Closed</option>
                                    <option value="project">Project</option>
                                    <option value="canceled">Canceled</option>
                                    </select>}
                                  { this.state.downloadSubscriptions[index].Triggers[t_index].CustomFieldValue == "specific" &&<div style={{display : "inherit"}}>
                                    {(this.getCustomValuesForDownloadSubscriptionTrigger(trigger) && trigger.CustomFormField && trigger.CustomFields && trigger.CustomFormField && trigger.CustomFields.find(x => x.Name == trigger.CustomFormField) &&( trigger.CustomFields.find(x => x.Name == trigger.CustomFormField).Type == 'Drop Down' || trigger.CustomFields.find(x => x.Name == trigger.CustomFormField).Type == 'MultiSelect')) ? 
                                    <select id={"notificationFieldCustomValueForDownloadSubscriptionTrigger" + t_index} className="form-control notificationRowControl"
                                      value={trigger.TriggerFieldValue}
                                      onChange={e => {this.updateCustomFormNotificationTriggerFieldValueForDownloadSubscriptionTrigger(e,index,t_index,true)}}>
                                      <option></option>
                                      {this.getCustomValuesForDownloadSubscriptionTrigger(trigger).map((value,index) => (
                                        <option key={index} value={value}>{value}</option>
                                      ))}
                                    </select> :
                                    trigger.CustomFormField && trigger.CustomFields && trigger.CustomFormField && trigger.CustomFields.find(x => x.Name == trigger.CustomFormField) && trigger.CustomFields.find(x => x.Name == trigger.CustomFormField).Type == 'Date' ?
                                    <div>
                                      <DatetimePicker name="dateFrom" value={trigger.TriggerFieldValue} style={{height : 10}} showTime={false} buttonClasses={"notificationRowControl datetime"}
                                        onChange={e => { this.updateCustomFormNotificationTriggerFieldValueForDownloadSubscriptionTrigger(e,index,t_index,false)}}/>
                                    </div> :
                                    trigger.CustomFormField && trigger.CustomFields && trigger.CustomFormField && trigger.CustomFields.find(x => x.Name == trigger.CustomFormField) && trigger.CustomFields.find(x => x.Name == trigger.CustomFormField).Type == 'Time' ?
                                    <div>
                                      <DatetimePicker name="dateFrom" value={trigger.TriggerFieldValue} style={{height : 10}} showTime={true} buttonClasses={"notificationRowControl datetime"}
                                        onChange={e => { this.updateCustomFormNotificationTriggerFieldValueForDownloadSubscriptionTrigger(e,index,t_index,false)}}/>
                                    </div> :
                                    (trigger.CustomFieldValue == "specific" && (trigger.CustomFormField != "Ticket In Area" &&  trigger.CustomFormField != "Ticket Not In Area" &&  !trigger.CustomFormField.includes("Response: ") && trigger.CustomFormField != "Task Status" && trigger.CustomFormField != "Member Code")) ?
                                      <input style={{position : 'relative', bottom : 2}} id={"notificationFieldCustomValueForDownloadSubscriptionTrigger" + t_index} value={trigger.TriggerFieldValue} className = "form-control customFormRowControlCustomValueEdit customValue"
                                      onChange = {e => { this.updateCustomFormNotificationTriggerFieldValueForDownloadSubscriptionTrigger(e, index, t_index, false); }} 
                                      /> :
                                      (trigger.CustomFieldValue == "specific" && trigger.CustomFormField.includes("Response: ")) ? 
                                      <select id={"notificationFieldCustomValueForDownloadSubscriptionTrigger" + t_index} className="form-control notificationRowControl"
                                      value={trigger.TriggerFieldValue}
                                      onChange={e => {this.updateCustomFormNotificationTriggerFieldValueForDownloadSubscriptionTrigger(e,index,t_index,true)}}>
                                      <option></option>
                                      {this.state.downloadSubscriptions[index].Triggers[t_index].CustomFormField && this.state.companyResponseCodes && this.state.companyResponseCodes.filter(x => x.MemberCode == this.state.downloadSubscriptions[index].Triggers[t_index].CustomFormField && !x.DeletedDate) && this.state.companyResponseCodes.filter(x => x.MemberCode == this.state.downloadSubscriptions[index].Triggers[t_index].CustomFormField.replace("Response: ","") && !x.DeletedDate).map((value,index) => (
                                        <option key={index} value={value.ResponseName}>{value.ResponseName}</option>
                                      ))}
                                    </select> :
                                    (trigger.CustomFieldValue == "specific" && trigger.CustomFormField == "Member Code"&& this.state.distinctMemberCodes[trigger.CustomFormName == "Ticket NV" ? 1 : 2] && this.state.distinctMemberCodes[trigger.CustomFormName == "Ticket NV" ? 1 : 2].length > 0) ?
                                      <select id={"notificationFieldCustomValueForDownloadSubscriptionTrigger" + t_index} className="form-control notificationRowControl"
                                      value={trigger.TriggerFieldValue}
                                      onChange={e => {this.updateCustomFormNotificationTriggerFieldValueForDownloadSubscriptionTrigger(e,index,t_index,true)}}>
                                      <option></option>
                                      {this.state.distinctMemberCodes[trigger.CustomFormName == "Ticket NV" ? 1 : 2].map((value,index) => (
                                        <option key={index} value={value}>{value}</option>
                                      ))}
                                    </select> :
                                    null}
                                  </div>}
                                  {
                                    (t_index != subscription.Triggers.length -1) && 
                                    <select id={"downloadSubscriptionTriggerAndOr" + t_index} className="form-control notificationRowControl"
                                    value ={trigger.AndOr} onChange={(e) =>{
                                      let downloadSubscriptions = this.state.downloadSubscriptions;
                                      downloadSubscriptions[index].Triggers[t_index].AndOr = e.target.value;
                                      this.setState({downloadSubscriptions}); 
                                    }}>
                                      <option value="AND">AND</option>
                                      <option value="OR">OR</option>
                                    </select>
                                  }
                                  <Button onClick={()=>{this.deleteDownloadSubscriptionTrigger(index,t_index);}} className="buttonAction buttonAccount notificationRowControl notificationRowButton">
                                    <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                  </Button>

                                </div>))
                                }
                            </div>))}

                          
                          
                          
                          <div className="horizontalLine"></div>
                          <div className="row" style={{ marginTop: 10 }}>
                            <div className="justify-content-left">
                            { !this.state.loadingNotifications && <div>
                              <Button onClick={()=>{if(auth.isSuperViewer()){return;}this.setState({notificationTypeOpen : true})}} className = "buttonAction buttonAccount">
                                <AddBoxSharpIcon className="buttonIcon"/>
                                <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText">New Alert</label>
                              </Button>
                              <Button onClick={() =>{this.updateNotifications();this.updateDownloadSubcriptions();}} className = "buttonAction buttonAccount">
                                <SaveSharpIcon className="buttonIcon"/>
                                <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText">Save</label>
                              </Button> </div> }
                              
                              { this.state.loadingNotifications && 
                                    <div className="row d-flex align-items-center justify-content-center">
                                      <div className=" d-flex align-items-center justify-content-center authSpinnerDiv">
                                      <label className ="buttonText withIcon">UPDATING NOTIFICATIONS...</label>
                                        <div className="barLoaderPortal">
                                        <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true } /> </div>
                                    </div>
                                  </div>} 
                            </div>
                            </div>
                          </div>

                          <div className="labelHeaderBar labelHeaderBarTight" style={{padding: 10, marginTop : 15}}>Download Subscriptions & Reports
                            <div className="labelHeaderBarSubHeader">Choose to be automatically emailed your data every day, week, or month. </div>
                          </div>
                          <div className="licenseEditDivContent">
                            {this.state.downloadSubscriptions && this.state.downloadSubscriptions.length > 0 && this.state.downloadSubscriptions.map((subscription, index) => ((!subscription.DeletedDate && ( subscription.Frequency != "OnCreation" &&  subscription.Frequency != "OnUpdate")) && 
                              <div key={index} style={{display : 'flex', flexDirection : 'column'}}>
                                <div className="row notificationRowDiv">
                                <label htmlFor={"label"+ index} className="accountHeader small downloadSubscriptionLabel" style={{marginLeft : 5}}>Label:</label>
                                <input id={"label" + index} value={subscription.Label} style={{maxWidth : 130}} className = "form-control downloadSubscriptionControl largeEmail"
                                  onChange={e => { let downloadSubscriptions = this.state.downloadSubscriptions; downloadSubscriptions[index].Label = e.target.value;this.setState({ downloadSubscriptions }); }}/>
                                  <label htmlFor={"subscriptionFrequency" + index} className="accountHeader downloadSubscriptionLabel">FREQUENCY:</label>
                                  <select id={"subscriptionFrequency" + index} className="form-control downloadSubscriptionControl" value={this.state.downloadSubscriptions[index].Frequency}
                                    onChange={e =>{
                                      let downloadSubscriptions = this.state.downloadSubscriptions;
                                      downloadSubscriptions[index].Frequency = e.target.value;
                                      this.setState({downloadSubscriptions});
                                    }}>
                                    <option></option>
                                    <option value="Daily">Daily</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Monthly">Monthly</option>
                                  </select>
                                  <div id={"subscriptionFormList" + index} className="dropdown-check-list" >
                                    <span className="anchor" onClick={() =>{
                                    let checkList = document.getElementById("subscriptionFormList"+index);
                                    if(checkList.classList.contains("visible"))checkList.classList.remove("visible");
                                    else checkList.classList.add("visible");
                                  }}>Select Forms</span>
                                    <ul className="items">
                                      <li><input checked={subscription.FormIdList.includes(0)} onChange={()=>{
                                        let downloadSubscriptions = this.state.downloadSubscriptions;
                                        let sub  = downloadSubscriptions[index];
                                        if(!sub.FormIdList.includes(0)){
                                          sub.FormIdList.push(0);
                                        }
                                        else{
                                          sub.FormIdList.splice(sub.FormIdList.indexOf(0), 1);
                                        } 
                                        this.setState({downloadSubscriptions});
                                      }} type="checkbox"/><div style={{marginLeft: 5, display: "inline-block"}} onClick={() =>{
                                        let downloadSubscriptions = this.state.downloadSubscriptions;
                                        let sub  = downloadSubscriptions[index];
                                        if(!sub.FormIdList.includes(0)){
                                          sub.FormIdList.push(0);
                                        }
                                        else{
                                          sub.FormIdList.splice(sub.FormIdList.indexOf(0), 1);
                                        }
                                        this.setState({downloadSubscriptions});
                                      }}>Site Survey</div></li>
                                      <li><input checked={subscription.FormIdList.includes(1)} onChange={()=>{
                                        let downloadSubscriptions = this.state.downloadSubscriptions;
                                        let sub  = downloadSubscriptions[index];
                                        if(!sub.FormIdList.includes(1)){
                                          sub.FormIdList.push(1);
                                        }
                                        else{
                                          sub.FormIdList.splice(sub.FormIdList.indexOf(1), 1);
                                        } 
                                        this.setState({downloadSubscriptions});
                                      }} type="checkbox"/><div style={{marginLeft: 5, display: "inline-block"}} onClick={() =>{
                                        let downloadSubscriptions = this.state.downloadSubscriptions;
                                        let sub  = downloadSubscriptions[index];
                                        if(!sub.FormIdList.includes(1)){
                                          sub.FormIdList.push(1);
                                        }
                                        else{
                                          sub.FormIdList.splice(sub.FormIdList.indexOf(1), 1);
                                        }
                                        this.setState({downloadSubscriptions});
                                      }}>Investigation</div></li>
                                      <li><input checked={subscription.FormIdList.includes(2)} onChange={()=>{
                                        let downloadSubscriptions = this.state.downloadSubscriptions;
                                        let sub  = downloadSubscriptions[index];
                                        if(!sub.FormIdList.includes(2)){
                                          sub.FormIdList.push(2);
                                        }
                                        else{
                                          sub.FormIdList.splice(sub.FormIdList.indexOf(2), 1);
                                        } 
                                        this.setState({downloadSubscriptions});
                                      }} type="checkbox"/><div style={{marginLeft: 5, display: "inline-block"}} onClick={() =>{
                                        let downloadSubscriptions = this.state.downloadSubscriptions;
                                        let sub  = downloadSubscriptions[index];
                                        if(!sub.FormIdList.includes(2)){
                                          sub.FormIdList.push(2);
                                        }
                                        else{
                                          sub.FormIdList.splice(sub.FormIdList.indexOf(2), 1);
                                        }
                                        this.setState({downloadSubscriptions});
                                      }}>Pipeline Survey</div></li>
                                      {this.state.FormCollection && this.state.FormCollection.length > 0 && this.state.FormCollection.map((form, i) =>(
                                        <li key={i}><input checked={subscription.FormIdList.includes(form.FormId)} onChange={() =>{
                                          let downloadSubscriptions = this.state.downloadSubscriptions;
                                          let sub  = downloadSubscriptions[index];
                                          if(!sub.FormIdList.includes(form.FormId)){
                                            sub.FormIdList.push(form.FormId);
                                          }
                                          else{
                                            sub.FormIdList.splice(sub.FormIdList.indexOf(form.FormId), 1);
                                          } 
                                          this.setState({downloadSubscriptions});
                                        }} type="checkbox"/><div style={{marginLeft: 5, display: "inline-block"}} onClick={() =>{
                                          let downloadSubscriptions = this.state.downloadSubscriptions;
                                          let sub  = downloadSubscriptions[index];
                                          if(!sub.FormIdList.includes(form.FormId)){
                                            sub.FormIdList.push(form.FormId);
                                          }
                                          else{
                                            sub.FormIdList.splice(sub.FormIdList.indexOf(form.FormId), 1);
                                          }
                                          this.setState({downloadSubscriptions});
                                        }}>{form.Name}</div></li>
                                      ))}
                                    </ul>
                                  </div>
                                  <label htmlFor={"subscriptionEmail"+ index} className="accountHeader small downloadSubscriptionLabel">Email:</label>
                                  <input id={"subscriptionEmail" + index} value={subscription.Emails} className = "form-control downloadSubscriptionControl largeEmail"
                                    onChange={e => { let downloadSubscriptions = this.state.downloadSubscriptions; downloadSubscriptions[index].Emails = e.target.value;this.setState({ downloadSubscriptions }); }}/>
                                  <label htmlFor={"subscriptionUseModified" + index} className="accountHeader downloadSubscriptionLabel">USE DATE:</label>
                                  <select id={"subscriptionUseModified" + index} className="form-control downloadSubscriptionControl" value={this.state.downloadSubscriptions[index].UseModified ? "Modified" : "Created"}
                                    onChange={e =>{
                                      let downloadSubscriptions = this.state.downloadSubscriptions;
                                      downloadSubscriptions[index].UseModified = e.target.value == "Modified";
                                      this.setState({downloadSubscriptions});
                                    }}>
                                      <option value="Created">Created</option>
                                      <option value="Modified">Modified</option>
                                  </select>
                                  <Button onClick={()=>{let downloadSubscriptions = this.state.downloadSubscriptions;
                                         downloadSubscriptions[index].showTriggers = !downloadSubscriptions[index].showTriggers;
                                         this.setState({ downloadSubscriptions });}} className = "buttonAction buttonAccount small">
                                    {subscription.showTriggers && <ExpandLessIcon className="buttonIcon small"/>}
                                    {!subscription.showTriggers && <ExpandMoreIcon className="buttonIcon small"/>}
                                  </Button>
                                  <Button onClick={()=>{this.addDownloadSubTrigger(index);}} className = "buttonAction buttonAccount small">
                                      <AddBoxSharpIcon className="buttonIcon small"/>
                                      <label style ={{ margin: 0, cursor: 'pointer', fontSize:12}} className ="buttonText">Add Trigger</label>
                                    </Button>
                                  <Button onClick={()=>{this.deleteDownloadSubscription(index);}} className="buttonAction buttonAccount notificationRowControl notificationRowButton">
                                    <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                  </Button>
                                </div>
                                {subscription.showTriggers && (subscription.Triggers && subscription.Triggers.length > 0) && subscription.Triggers.map((trigger,t_index) => (
                                <div key={t_index} style={{position : "relative", left : 25, width: "75%"}} className="row notificationRowDiv">
                                  <label htmlFor={"downloadSubscriptioncustomFormForTrigger" + t_index} className="accountHeader downloadSubscriptionLabel">TRIGGER:</label>
                                  <select id={"downloadSubscriptioncustomFormForTrigger" + t_index} className="form-control downloadSubscriptionControl" value={trigger.CustomFormId}
                                    onChange={e =>{
                                      let downloadSubscriptions = this.state.downloadSubscriptions;
                                      downloadSubscriptions[index].Triggers[t_index].CustomFormId = e.target.value;
                                      let form = this.state.FormCollection.find(x => x.FormId ==  e.target.value);
                                      downloadSubscriptions[index].Triggers[t_index].CustomFormType = form.Type;
                                      downloadSubscriptions[index].Triggers[t_index].CustomFields = form.Fields;
                                      downloadSubscriptions[index].Triggers[t_index].CustomFormField = null;
                                      downloadSubscriptions[index].Triggers[t_index].CustomFieldValue = "any";
                                      this.setState({downloadSubscriptions});
                                    }}>
                                    <option></option>
                                    {subscription.FormIdList && subscription.FormIdList.map((formId, fID_index) =>(
                                      this.state.FormCollection && this.state.FormCollection.find(x => x.FormId == formId)) ?
                                      <option key={fID_index} value={formId}>{this.state.FormCollection.find(x => x.FormId == formId).Name }</option> :
                                      null) 
                                    }
                                  </select>
                                  <label htmlFor={"downloadSubscriptionFieldFormTrigger" + t_index} style={{marginLeft : 10}} className="accountHeader notificationLabel">If : </label>
                                  <select id={"downloadSubscriptionFieldFormTrigger" + t_index} className="form-control notificationRowControl" value={trigger.CustomFormField}
                                  onChange={e =>{
                                    let downloadSubscriptions = this.state.downloadSubscriptions;
                                    downloadSubscriptions[index].Triggers[t_index].CustomFormField = e.target.value;
                                    this.setState({downloadSubscriptions});
                                  }}>
                                    <option></option>
                                    {/* TODO: UPDATE WITH ANY NEW config ids */}
                                    {(trigger.CustomFormType == "Ticket Survey" && this.state.distinctMemberCodes && this.state.distinctMemberCodes[trigger.CustomFormName == "Ticket NV" ? 1 : 2] && this.state.distinctMemberCodes[trigger.CustomFormName == "Ticket NV" ? 1 : 2].length > 0) && 
                                    this.state.distinctMemberCodes[trigger.CustomFormName == "Ticket NV" ? 1 : 2].map((config, configIndex) =>
                                      <option key={configIndex} value={"Response: " + config}>{"Response: " + config}</option>)}
                                    {(trigger.CustomFormType == "Ticket Survey") && 
                                      <option value="Ticket In Area">Ticket In Area</option>
                                    }
                                    {(trigger.CustomFormType == "Ticket Survey") && 
                                      <option value="Ticket Not In Area">Ticket Not In Area</option>
                                    }
                                    <option value="Task Status">Task Status</option>
                                    {trigger.CustomFields && trigger.CustomFields.map((field, cField_index) =>
                                      <option key={cField_index} value={field.Name}>{field.Name}</option>)
                                    }
                                  </select>

                                  {(trigger.CustomFormField != "Ticket In Area" && trigger.CustomFormField != "Ticket Not In Area"&& trigger.CustomFormField != "Task Status") && <select id={"downloadSubsctiptionCustomFieldValueForTrigger" + t_index} className = "form-control notificationRowControl" 
                                    value = {trigger.CustomFieldValue}
                                    onChange ={ e => { 
                                      let downloadSubscriptions = this.state.downloadSubscriptions;
                                      downloadSubscriptions[index].Triggers[t_index].CustomFieldValue = e.target.value;
                                      this.setState({downloadSubscriptions});
                                     }} >
                                    <option value='any' >Has Any Value</option>
                                    <option value='specific' >Has Specific Value:</option>
                                  </select>}
                                  {(trigger.CustomFormField == "Ticket In Area" || trigger.CustomFormField == "Ticket Not In Area") &&
                                    <select id={"CustomFieldValue" + index} className = "form-control notificationRowControl" 
                                      value = {trigger.TriggerFieldValue}
                                      onChange ={ e => {
                                        let downloadSubscriptions = this.state.downloadSubscriptions;
                                        downloadSubscriptions[index].Triggers[t_index].TriggerFieldValue = e.target.value;
                                        downloadSubscriptions[index].Triggers[t_index].CustomFieldValue = "specific";
                                        this.setState({downloadSubscriptions}); 
                                      }} >
                                        <option></option>
                                      {this.state.ticketAssignmentAreas.map((area, areaIndex) => (<option key={areaIndex} value={area.AssignmentAreaId}>{area.Label}</option>)) }
                                      {this.state.CompanyArcGisLayers.filter(x => x.LayerType == "Analysis").map((area, areaIndex) => (<option key={areaIndex} value={"AnalysisLayer:" +area.CompanyArcGisLayerId}>{area.Name}</option>))}
                                      {this.state.CompanyArcGisLayerFeatures.map((area,areaIndex) => (<option key={areaIndex} value={"TicketArea:" + area.CompanyArcGISLayerId +"|" + area.FeatureId + "|" + area.FeatureName}>{area.FeatureName}</option>))}
                                    </select> 
                                  }
                                  {trigger.CustomFormField == "Task Status" && 
                                <select id={"CustomFieldValue" + index} className="form-control notificationRowControl"
                                value ={trigger.TriggerFieldValue}
                                  onChange={e=>{
                                    let downloadSubscriptions = this.state.downloadSubscriptions;
                                        downloadSubscriptions[index].Triggers[t_index].TriggerFieldValue = e.target.value;
                                        downloadSubscriptions[index].Triggers[t_index].CustomFieldValue = "specific";
                                        this.setState({downloadSubscriptions}); 
                                  }}>
                                    <option value="open">Open</option>
                                    <option value="closed">Closed</option>
                                    <option value="project">Project</option>
                                    <option value="canceled">Canceled</option>
                                    </select>}
                                  <div style={{display : "inherit"}}>
                                    {this.getCustomValuesForDownloadSubscriptionTrigger(trigger) ? 
                                    <select id={"notificationFieldCustomValueForDownloadSubscriptionTrigger" + t_index} className="form-control notificationRowControl"
                                      value={trigger.TriggerFieldValue}
                                      onChange={e => {this.updateCustomFormNotificationTriggerFieldValueForDownloadSubscriptionTrigger(e,index,t_index,true)}}>
                                      <option></option>
                                      {this.getCustomValuesForDownloadSubscriptionTrigger(trigger).map((value,index) => (
                                        <option key={index} value={value}>{value}</option>
                                      ))}
                                    </select> :
                                    trigger.CustomFormField && trigger.CustomFields && trigger.CustomFormField && trigger.CustomFields.find(x => x.Name == trigger.CustomFormField) && trigger.CustomFields.find(x => x.Name == trigger.CustomFormField).Type == 'Date' ?
                                    <div>
                                      <DatetimePicker name="dateFrom" value={trigger.TriggerFieldValue} style={{height : 10}} showTime={false} buttonClasses={"notificationRowControl datetime"}
                                        onChange={e => { this.updateCustomFormNotificationTriggerFieldValueForDownloadSubscriptionTrigger(e,index,t_index,false)}}/>
                                    </div> :
                                    trigger.CustomFormField && trigger.CustomFields && trigger.CustomFormField && trigger.CustomFields.find(x => x.Name == trigger.CustomFormField) && trigger.CustomFields.find(x => x.Name == trigger.CustomFormField).Type == 'Time' ?
                                    <div>
                                      <DatetimePicker name="dateFrom" value={trigger.TriggerFieldValue} style={{height : 10}} showTime={true} buttonClasses={"notificationRowControl datetime"}
                                        onChange={e => { this.updateCustomFormNotificationTriggerFieldValueForDownloadSubscriptionTrigger(e,index,t_index,false)}}/>
                                    </div> :
                                    (trigger.CustomFieldValue == "specific" && (trigger.CustomFormField != "Ticket In Area" &&  trigger.CustomFormField != "Ticket Not In Area" &&  !trigger.CustomFormField.includes("Response: ") && trigger.CustomFormField != "Task Status" && trigger.CustomFormField != "Member Code")) ?
                                    <input style={{position : 'relative', bottom : 2}} id={"notificationFieldCustomValueForDownloadSubscriptionTrigger" + t_index} value={trigger.TriggerFieldValue} className = "form-control customFormRowControlCustomValueEdit customValue"
                                    onChange = {e => { this.updateCustomFormNotificationTriggerFieldValueForDownloadSubscriptionTrigger(e, index, t_index, false); }} 
                                    /> :
                                    (trigger.CustomFieldValue == "specific" && trigger.CustomFormField.includes("Response: ")) ? 
                                    <select id={"notificationFieldCustomValueForDownloadSubscriptionTrigger" + t_index} className="form-control notificationRowControl"
                                    value={trigger.TriggerFieldValue}
                                    onChange={e => {this.updateCustomFormNotificationTriggerFieldValueForDownloadSubscriptionTrigger(e,index,t_index,true)}}>
                                    <option></option>
                                    {this.state.downloadSubscriptions[index].Triggers[t_index].CustomFormField && this.state.companyResponseCodes && this.state.companyResponseCodes.filter(x => x.MemberCode == this.state.downloadSubscriptions[index].Triggers[t_index].CustomFormField && !x.DeletedDate) && this.state.companyResponseCodes.filter(x => x.MemberCode == this.state.downloadSubscriptions[index].Triggers[t_index].CustomFormField.replace("Response: ","") && !x.DeletedDate).map((value,index) => (
                                      <option key={index} value={value.ResponseName}>{value.ResponseName}</option>
                                    ))}
                                  </select> :
                                  (trigger.CustomFieldValue == "specific" && trigger.CustomFormField == "Member Code"&& this.state.distinctMemberCodes[trigger.CustomFormName == "Ticket NV" ? 1 : 2] && this.state.distinctMemberCodes[trigger.CustomFormName == "Ticket NV" ? 1 : 2].length > 0) ?
                                  <select id={"notificationFieldCustomValueForDownloadSubscriptionTrigger" + t_index} className="form-control notificationRowControl"
                                  value={trigger.TriggerFieldValue}
                                  onChange={e => {this.updateCustomFormNotificationTriggerFieldValueForDownloadSubscriptionTrigger(e,index,t_index,true)}}>
                                  <option></option>
                                  {this.state.distinctMemberCodes[trigger.CustomFormName == "Ticket NV" ? 1 : 2].map((value,index) => (
                                    <option key={index} value={value}>{value}</option>
                                  ))}
                                </select> :
                                  null}
                                  </div>
                                  {
                                    (t_index != subscription.Triggers.length -1) && 
                                    <select id={"downloadSubscriptionTriggerAndOr" + t_index} className="form-control notificationRowControl"
                                    value ={trigger.AndOr} onChange={(e) =>{
                                      let downloadSubscriptions = this.state.downloadSubscriptions;
                                      downloadSubscriptions[index].Triggers[t_index].AndOr = e.target.value;
                                      this.setState({downloadSubscriptions}); 
                                    }}>
                                      <option value="AND">AND</option>
                                      <option value="OR">OR</option>
                                    </select>
                                  }
                                  <Button onClick={()=>{this.deleteDownloadSubscriptionTrigger(index,t_index);}} className="buttonAction buttonAccount notificationRowControl notificationRowButton">
                                    <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                  </Button>

                                </div>))
                                }
                              </div>))}
                            <div className="horizontalLine"></div>
                            <div className="row" style={{marginTop: 10}}>
                              <div className="justify-content-left">
                                {  !this.state.loadingDownloadSubscriptions ? <div>
                                  <Button onClick={() =>{if(auth.isSuperViewer()){return;}this.addDownloadSubcription();}} className="buttonAction buttonAccount">
                                    <AddBoxSharpIcon className="buttonIcon"/>
                                    <label style={{ margin: 0, cursor : 'pointer'}} className="buttonText">New Subscription</label>
                                  </Button>
                                  <Button onClick={() =>{this.updateDownloadSubcriptions();}} className="buttonAction buttonAccount">
                                    <SaveSharpIcon className="buttonIcon"/>
                                    <label style={{ margin: 0, cursor: 'pointer'}} className="buttonText">Save</label>
                                  </Button>
                                </div>
                                : <div>
                                    <div className="row d-flex align-items-center justify-content-center">
                                      <div className="d-flex align-items-center justify-content-center authSpinnerDiv">
                                        <label className="buttonText withIcon">UPDATING DOWNLOAD SUBSCRIPTIONS...</label>
                                        <div className="barLoaderPortal">
                                          <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true } />
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                }
                              </div>
                            </div>
                          </div>

                          <div className="labelHeaderBar labelHeaderBarTight" style={{padding: 10, marginTop : 15}}>Generated Map Reports
                                <div className="labelHeaderBarSubHeader">Send map reports of all newly created data nightly (at midnight)</div>
                          </div>
                          <div className="licenseEditDivContent">
                          {/* {(!this.state.arcGisConnected ||  !this.state.validArcGisKeys) && <div>Not Connected to ARCGIS Layers. To include any ARCGIS layers in Map Reports configuration, connect to ARCGIS (GIS CONNECTION tab above) <br/></div>} */}
                            {this.state.FormCollection && this.state.FormCollection.length > 0 && this.state.mapReports && this.state.mapReports.length > 0 && this.state.mapReports.map((report, index) => (!report.DeletedDate && 
                              <div key={index} style={{display : 'flex', flexDirection : 'column'}}>
                                <div className="row notificationRowDiv">
                                  <label htmlFor={"mapReportFor"+index} className="accountHeader downloadSubscriptionLabel">Form For:</label>
                                  <select id={"mapReportFor"+index} className="form-control downloadSubscriptionControl" 
                                  onChange={(e) =>{
                                      let mapReports = this.state.mapReports;
                                      mapReports[index].FormIdToTriggerOn = parseInt(e.target.value);
                                      let form = this.state.FormCollection.find(x => x.FormId == e.target.value);
                                      let defaultHeaderField = form.Fields.find( x=> x.Type != "Scanner" && x.Type != "Section" && x.Type != "Scanner" && (x.Name.toLowerCase().includes("addr") || x.Name.toLowerCase().includes("address")))
                                      if(defaultHeaderField){
                                        mapReports[index].HeaderField = defaultHeaderField.Name;
                                      }
                                      this.setState({mapReports});
                                  }}>
                                    <option></option>
                                    {this.state.FormCollection.filter(x => x.FormTypeId == 3).map((form, index) => (<option key={index} value={form.FormId} label={form.Name} selected={report.FormIdToTriggerOn == form.FormId}></option>))}
                                  </select>
                                  <label htmlFor={"mapReportEmail" + index} className="accountHeader small downloadSubscriptionLabel">Email:</label>
                                  <input id={"mapReportEmail"+ index} value={report.Emails} className="form-control downloadSubscriptionControl"
                                    onChange={e => {
                                      let mapReports = this.state.mapReports;
                                      mapReports[index].Emails = e.target.value;
                                      this.setState({mapReports});
                                    }}/>
                                    <label htmlFor={"mapReportLayersList" + index} className="accountHeader small downloadSubscriptionLabel">Include Layers:</label>
                                    <div id={"mapReportLayersList" + index} className="dropdown-check-list" >
                                    <span className="anchor" style ={{maxWidth: 180}} onClick={() =>{
                                    let checkList = document.getElementById("mapReportLayersList"+index);
                                    if(checkList.classList.contains("visible"))checkList.classList.remove("visible");
                                    else checkList.classList.add("visible");
                                  }}>Select Layers</span>
                                  <ul className="items">
                                    {this.state.FormCollection.filter(x => x.FormTypeId == 3).map((form,i) =>( !(form.FormId == report.FormIdToTriggerOn) &&
                                      <li key={i}><input checked={report.IncludedFormIdList.includes(form.FormId)} onChange={() =>{
                                        let mapReports = this.state.mapReports;
                                        let rep = mapReports[index];
                                        if(!rep.IncludedFormIdList.includes(form.FormId)){
                                          if(rep.IncludedCompanyArcGisLayerUrlList.length + rep.IncludedFormIdList.length < 7){
                                            rep.IncludedFormIdList.push(form.FormId);
                                          }
                                          else{
                                            this.setState({updateStatusText : "Can not include more than 7 layers on a report.", alertSeverity : "warning"});
                                          }
                                        }
                                        else{
                                          rep.IncludedFormIdList.splice(rep.IncludedFormIdList.indexOf(form.FormId),1);
                                        }
                                        this.setState({mapReports});
                                      }} type="checkbox"/>
                                      <div style={{maringLeft : 5, display: "inline", cursor : "pointer"}} onClick={() =>{
                                        let mapReports = this.state.mapReports;
                                        let rep = mapReports[index];
                                        if(!rep.IncludedFormIdList.includes(form.FormId)){
                                          if(rep.IncludedCompanyArcGisLayerUrlList.length + rep.IncludedFormIdList.length < 8){
                                            rep.IncludedFormIdList.push(form.FormId);
                                          }
                                          else{
                                            this.setState({updateStatusText : "Can not include more than 7 layers on a report.", alertSeverity : "warning"});
                                          }
                                        }
                                        else{
                                          rep.IncludedFormIdList.splice(rep.IncludedFormIdList.indexOf(form.FormId),1);
                                        }
                                        this.setState({mapReports});
                                      }}>{form.Name}</div>
                                      </li>
                                    ))}
                                    {/* ADD ARCGIS LAYERS TO MAP REPORTS. UNIMPLEMENTED FOR NOW {this.state.arcGisConnectedFeatureLayerList && this.state.arcGisConnectedFeatureLayerList.map((layer,i) =>(
                                      <li key={i}><input checked={report.IncludedCompanyArcGisLayerUrlList.includes(layer.url)} onChange={()=>{
                                        let mapReports = this.state.mapReports;
                                        let rep = mapReports[index];
                                        if(!rep.IncludedCompanyArcGisLayerUrlList.includes(layer.url)){
                                          if(rep.IncludedCompanyArcGisLayerUrlList.length + rep.IncludedFormIdList.length < 8){
                                            rep.IncludedCompanyArcGisLayerUrlList.push(layer.url);
                                          }
                                          else{
                                            this.setState({updateStatusText : "Can not include more than 8 layers on a report.", alertSeverity : "warning"});
                                          }
                                        }
                                        else {
                                          rep.IncludedCompanyArcGisLayerUrlList.splice(rep.IncludedCompanyArcGisLayerUrlList.indexOf(layer.url),1);
                                        }
                                        this.setState({mapReports});
                                      }} type="checkbox"/>
                                        <div style={{marginLeft : 5, display : "inline", cursor : 'pointer', maxWidth : 150}} onClick={()=>{
                                        let mapReports = this.state.mapReports;
                                        let rep = mapReports[index];
                                        if(!rep.IncludedCompanyArcGisLayerUrlList.includes(layer.url)){
                                          if(rep.IncludedCompanyArcGisLayerUrlList.length + rep.IncludedFormIdList.length < 8){
                                            rep.IncludedCompanyArcGisLayerUrlList.push(layer.url);
                                          }
                                          else{
                                            this.setState({updateStatusText : "Can not include more than 8 layers on a report.", alertSeverity : "warning"});
                                          }
                                        }
                                        else {
                                          rep.IncludedCompanyArcGisLayerUrlList.splice(rep.IncludedCompanyArcGisLayerUrlList.indexOf(layer.url),1);
                                        }
                                        this.setState({mapReports});
                                      }}>{layer.name}</div>
                                      </li>
                                    ))} */}
                                  </ul>
                                  </div>
                                  <label htmlFor={"mapReportHeader" + index} className="accountHeader small downloadSubscriptionLabel">Report Header:</label>
                                  <select id={"mapReportHeader" + index} className="form-control downloadSubscriptionControl" value={report.HeaderField} onChange={(e) =>{
                                    let mapReports = this.state.mapReports;
                                    mapReports[index].HeaderField = e.target.value;
                                    this.setState({mapReports});
                                  }}>
                                    <option></option>
                                    {report.FormIdToTriggerOn && this.state.FormCollection && this.state.FormCollection.find(x => x.FormId == report.FormIdToTriggerOn) && this.state.FormCollection.find(x => x.FormId == report.FormIdToTriggerOn).Fields.map((field,i) =>(
                                   (field.Type != "Section" && field.Type != "Signature" && field.Type != "Scanner") && <option key={i} value={field.Name}>{field.Name}</option>)) }
                                  </select>


                                  <label htmlFor={"mapReportHeader" + index} className="accountHeader small downloadSubscriptionLabel">Filename Tag:</label>
                                  <select id={"mapReportHeader" + index} className="form-control downloadSubscriptionControl" value={report.FileNameTag} onChange={(e) =>{
                                    let mapReports = this.state.mapReports;
                                    mapReports[index].FileNameTag = e.target.value;
                                    this.setState({mapReports});
                                  }}>
                                    <option></option>
                                    {report.FormIdToTriggerOn && this.state.FormCollection && this.state.FormCollection.find(x => x.FormId == report.FormIdToTriggerOn) && this.state.FormCollection.find(x => x.FormId == report.FormIdToTriggerOn).Fields.map((field,i) =>(
                                   (field.Type = "Text" || field.Type != "Number") && <option key={i} value={field.Name}>{field.Name}</option>)) }
                                  </select>
                                  <Button onClick={()=>{this.deleteMapReport(index);}} className="buttonAction buttonAccount notificationRowControl notificationRowButton">
                                      <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                    </Button>
                                </div>
                              </div>))}
                          <div className="horizontalLine"></div>
                            <div className="row" style={{marginTop: 10}}>
                              <div className="justify-content-left">
                                {  !this.state.loadingMapReports ? <div>
                                  <Button onClick={() =>{if(auth.isSuperViewer()){return;}this.addMapReport();}} className="buttonAction buttonAccount">
                                    <AddBoxSharpIcon className="buttonIcon"/>
                                    <label style={{ margin: 0, cursor : 'pointer'}} className="buttonText">New Subscription</label>
                                  </Button>
                                  <Button onClick={() =>{this.updateMapReports();}} className="buttonAction buttonAccount">
                                    <SaveSharpIcon className="buttonIcon"/>
                                    <label style={{ margin: 0, cursor: 'pointer'}} className="buttonText">Save</label>
                                  </Button>
                                </div>
                                : <div>
                                    <div className="row d-flex align-items-center justify-content-center">
                                      <div className="d-flex align-items-center justify-content-center authSpinnerDiv">
                                        <label className="buttonText withIcon">UPDATING MAP REPORTS...</label>
                                        <div className="barLoaderPortal">
                                          <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true } />
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                }
                              </div>
                            </div>
                          </div>
                          <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10, marginTop : 15}}>Archive History
                                <div className="labelHeaderBarSubHeader">Manage Archived Data</div>
                          </div>
                          <div className="licesnseEditDivContent">
                          <div style={{display : 'flex', flexDirection : 'column'}}>
                            <div className="row notificationRowDiv">
                              {!this.state.loadingArchiveHistory && !tableData && <Button onClick={() =>{this.loadArchiveHistory()}} className="buttonAction buttonAccount">
                                <SearchIcon className="buttonIcon"/>
                                <label style={{ margin: 0, cursor: 'pointer'}} className="buttonText">Load Archive History</label>
                              </Button>}
                              {this.state.loadingArchiveHistory && !tableData && <div className="row d-flex align-items-center justify-content-center">
                                      <div className=" d-flex align-items-center justify-content-center authSpinnerDiv">
                                      <label className ="">LOADING ARCHIVE DATA...</label>
                                        <div className="barLoaderPortal">
                                        <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true } /> </div>
                                    </div>
                                  </div>}
                              {!this.state.loadingArchiveHistory && tableData && 
                              <MDBDataTable id="archived-siteright-datatable" className="w-100 mt-3" searchLabel=" " onSort={this.handleUnarchivedSort}
                                    btn Button striped bordered hover data={tableData} sortable={true} searching={false} paging={true} entries={100} pagesAmount={10}/>}
                            </div>
                          </div>
                          </div>
                          <Dialog open={this.state.notificationTypeOpen ? true : false}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">{"Notification Type"}</DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                Select a notification type:
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              {((this.state.licenseLeak && this.state.licenseLeak > 0) || this.state.trialMode) ? <Button className="buttonAction btn btn-primary" onClick={()=>{this.addNotification('LeakSurvey')}} color="primary">
                                <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>GIS Survey</label>
                              </Button> : null}
                              {((this.state.licenseScreening && this.state.licenseScreening > 0) || this.state.trialMode) ? <Button className="buttonAction btn btn-primary" onClick={() =>{this.addNotification('SiteSafe')}} color="primary">
                                <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Site Safe</label>
                              </Button> : null}
                              {((this.state.customFormsLicense) || this.state.trialMode) && <Button className="buttonAction btn btn-primary" onClick={() =>{this.addDownloadSubcription('OnCreation')}} color="primary">
                                <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Custom Form</label>
                              </Button>}
                              <Button className="buttonAction btn btn-primary" onClick={()=>{this.setState({notificationTypeOpen : false})}} color="primary">
                                <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Cancel</label>
                              </Button>
                          </DialogActions>
                          </Dialog>
                        </React.Fragment>
                      </Tab.Pane>
                    }
                    {
                      <Tab.Pane className="d-flex flex-column w-100 p-0 m-0 justify-content-center" eventKey="GisExport" id="Gis-export-tab" mountOnEnter unmountOnExit>
                        {/* Misc */}
                        <React.Fragment>
                          
                          
                          {/* GIS EXPORT CONFIGURATION */}
                          <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10}}>ArcGIS Account
                          </div>
                          <div className="licenseEditDivContent">
                            <div className="row">
                              <div className="row" style={{ display:'flex', flexDirection:'column', width: '100%' }}>
                                {this.state.arcGisAuthenticationUri && <div className="justify-content-left">
                                {this.state.arcGisConnected && this.state.arcGisToken ? 
                                  <div>
                                    <Button className="buttonAction buttonRed" style={{marginRight:20}} onClick={this.revokeArcGisToken.bind(this)}>
                                      <label className="buttonText withIcon">Disconnect ArcGIS</label>
                                    </Button>
                                  </div>
                                  :
                                  <WindowOpener url={this.state.arcGisAuthenticationUri} width={350} height={450} bridge={this.arcGisPopupWindowResponse.bind(this)}>
                                    <Button className="buttonAction buttonAccount" style={{marginRight:20}}>
                                      <label className="buttonText withIcon">Connect to ArcGIS</label>
                                    </Button>
                                  </WindowOpener>
                                }
                                

                                  {/* <Button onClick={()=>{}} className="buttonAction buttonAccount buttonFaded " style={{marginRight:20}}>
                                    <ContactSupportTwoToneIcon className="buttonIcon"/>
                                    <label className="buttonText withIcon">What is my key?</label>
                                  </Button> */}

                                </div>}
                               
                                {this.state.arcGisConnected && this.state.arcGisToken &&
                                  <div style={{marginTop: 10}}>Logged in: <strong>{this.state.arcGisToken.username}</strong></div>
                                }
                              </div>

                            </div>
                          </div>

                          <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10, marginTop: 20}}>AGS CONNECTION CONFIGURATION
                          </div>
                          <div className="licenseEditDivContent">
                            <div className="row">
                              <div className="row" style={{ display:'flex', flexDirection:'column',width:'100%'}}>
                                <form autoComplete="off">
                                  <div style={{height : 70, flexDirection: 'row', justifyContent: 'center', alignContent: 'center'}}>
                                    <input style={{marginLeft : 5, marginRight: 5, alignSelf: 'center'}} className="customFormCheckbox"  type="checkbox"  checked={this.state.useArcGisEnterprise} onChange={(e) => {
                                      this.setState({useArcGisEnterprise: !this.state.useArcGisEnterprise})
                                      }}/>
                                    <label style={{alignSelf: 'center'}} className="accountHeader">Use ArcGIS Enterprise</label>
                                  </div>

                                  {this.state.useArcGisEnterprise &&
                                    <div style={{height : 95}}><label htmlFor="enterpriseUrlInput" className="accountHeader">ArcGIS Enterprise Portal URL:</label><br/>
                                      <input id="enterprisePortalUrlInput" 
                                        style={{marginLeft: 5, maxWidth: "500px", maxHeight: 40, marginRight: 10, display : 'inline-block'}}
                                        value={this.state.ARCGISEnterprisePortalUrl}
                                        onChange={e =>{this.setState({ARCGISEnterprisePortalUrl : e.target.value})}}
                                        className="form-control"
                                        type={"text"}
                                        placeholder="https://example.myarcgisenterprise.com/portal"
                                      />
                                  </div>
                                  }

                                  {this.state.useArcGisEnterprise &&
                                    <div style={{height : 95}}><label htmlFor="enterpriseUrlInput" className="accountHeader">ArcGIS Enterprise REST URL:</label><br/>
                                      <input id="enterpriseUrlInput" 
                                        style={{marginLeft: 5, maxWidth: "500px", maxHeight: 40, marginRight: 10, display : 'inline-block'}}
                                        value={this.state.ARCGISEnterpriseUrl}
                                        onChange={e =>{this.setState({ARCGISEnterpriseUrl : e.target.value})}}
                                        className="form-control"
                                        type={"text"}
                                        placeholder="https://example.myarcgisenterprise.com/ABCDE/sharing/rest/"
                                      />
                                  </div>
                                  }

                                  {this.state.useArcGisEnterprise &&
                                    <div style={{height : 95}}><label htmlFor="enterpriseFeatureLayersUrlInput" className="accountHeader">ArcGIS Enterprise Feature Layer List URL:</label><br/>
                                      <input id="enterpriseFeatureLayersUrlInput" 
                                        style={{marginLeft: 5, maxWidth: "500px", maxHeight: 40, marginRight: 10, display : 'inline-block'}}
                                        value={this.state.ARCGISEnterpriseLayerListUrl}
                                        onChange={e =>{this.setState({ARCGISEnterpriseLayerListUrl : e.target.value})}}
                                        className="form-control"
                                        type={"text"}
                                        placeholder="https://example.myarcgisenterprise.com/server/rest/services/Hosted"
                                      />
                                  </div>
                                  }

                                  <div style={{height : 95}}><label htmlFor="clientId" className="accountHeader">Client ID:</label><br/>
                                    <input id="clientId" 
                                      style={{marginLeft: 5, maxWidth: "500px", maxHeight: 40, marginRight: 10, display : 'inline-block'}}
                                      value={this.state.ARCGISClientId}
                                      onChange={e =>{this.setState({ARCGISClientId : e.target.value})}}
                                      className="form-control"
                                      type={this.state.showARCGISClientId ? "text" : "password"}
                                      />
                                    {this.state.showARCGISClientId ? <VisibilityOffIcon onClick={()=>{this.setState({showARCGISClientId : false})}} style={{cursor : 'pointer'}}/>  : <VisibilityIcon onClick={()=>{this.setState({showARCGISClientId : true})}} style={{cursor : 'pointer'}}/>}
                                  </div>
                                  <div style={{height : 95}}><label htmlFor="clientSecret" className="accountHeader">Client Secret:</label><br/>
                                    <input id="clientSecret" 
                                      style={{marginLeft: 5, maxWidth: "500px", maxHeight: 40, marginRight: 10, display : 'inline-block'}}
                                      value={this.state.ARCGISClientSecret}
                                      onChange={e =>{this.setState({ARCGISClientSecret : e.target.value})}}
                                      className="form-control"
                                      type={this.state.showARCGISClientSecret ? "text" : "password"}
                                      />
                                    {this.state.showARCGISClientSecret ?  <VisibilityOffIcon onClick={()=>{this.setState({showARCGISClientSecret : false})}} style={{cursor : 'pointer'}}/> : <VisibilityIcon onClick={()=>{this.setState({showARCGISClientSecret : true})}} style={{cursor : 'pointer'}}/>}
                                  </div>
                                  <div className="horizontalLine"></div>
                                  {!this.state.validatingARCGISKeys && <div className="justify-content-left">
                                    <Button onClick={() =>{this.updateARCGISKeys();}} className="buttonAction buttonAccount" style={{marginRight : 20}}>
                                      <AssignmentTurnedInTwoToneIcon className="buttonIcon"/>
                                      <label className="buttonText withIcon">Save & Validate</label>
                                    </Button>
                                    {!this.state.useArcGisEnterprise && 
                                      <Button onClick={() =>{window.open('https://developers.arcgis.com/applications/', '_blank').focus();}} className="buttonAction buttonAccount" style={{marginRight : 20}}>
                                        <SearchIcon className="buttonIcon"/>
                                        <label className="buttonText withIcon">Show my ID\Secret</label>
                                      </Button>
                                    }
                                  </div>}
                                  {this.state.validatingARCGISKeys && 
                                    <div className="justify-content-left" style={{width:500}}>
                                    <div className=" d-flex align-items-center justify-content-center authSpinnerDiv">
                                      VALIDATING...
                                      <div className="barLoaderPortal"><BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true } /> </div>
                                    </div></div>
                                    }
                                </form>
                              </div>
                            </div>
                          </div>

                          <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10, marginTop : 20}}>Configure KML/SHAPEFILE Map Layers</div>
                          <div className="licenseEditDivContent">
                            <div className="row">
                            {this.state.loadingCompanyKMLShapeLayers && <div className="row d-flex align-items-center justify-content-center">
                                  <div className="d-flex align-items-center justify-content-center authSpinnerDiv">
                                    <label className="withIcon">LOADING YOUR KML/SHAPEFILE LAYERS...</label>
                                    <div className="barLoaderPortal">
                                      <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={true}/>
                                    </div>
                                  </div>
                                </div>}
                            {!this.state.loadingCompanyKMLShapeLayers && <div style={{width: '100%'}}>
                              {this.state.companyKMLShapeLayers && this.state.companyKMLShapeLayers.map((layer, index) => (
                                !layer.DeletedDate && <div key={index} style={{display : 'flex', flexDirection : "column"}}>
                                  <div className="row notificationRowDiv">
                                    <label htmlFor={"fileLayerName" +index} className="accountHeader notificationLabel">Layer Name: </label>
                                    <input id={"fileLayerName" + index}
                                      className="ARCGISFormControl notificationRowControl form-control" onChange={e =>{
                                        let companyKMLShapeLayers = this.state.companyKMLShapeLayers;
                                        let currentLayer = companyKMLShapeLayers[index];
                                        currentLayer.Name = e.target.value
                                        this.setState({companyKMLShapeLayers});
                                      }}
                                      value={this.state.companyKMLShapeLayers[index].Name}/>
                                    <label htmlFor={'fileLayerType' +index} className="accountHeader notificationLabel" style={{marginLeft : 15}}>Layer Type: </label>
                                    <select id={'fileLayerType' + index} className="ARCGISFormControl notificationRowControl" value={layer.LayerType} onChange={e =>{
                                      let companyKMLShapeLayers = this.state.companyKMLShapeLayers;
                                      let currentLayer = companyKMLShapeLayers[index];
                                      currentLayer.LayerType = e.target.value;
                                      this.setState({companyKMLShapeLayers});
                                    }}>
                                      <option value={'GPS'}>GPS Survey</option>
                                      <option value={'GIS'}>GIS Inspection</option>
                                    </select>
                                    <label className="accountHeader notificationLabel" style={{marginLeft : 15}}>Add to Map: </label>
                                    <input style={{marginRight: 5}} className="customFormRowControl xsmall" type="checkbox" checked={layer.ShowOnApp ? 'checked' : ''} value={layer.ShowOnApp} onChange={e =>{
                                      let companyKMLShapeLayers = this.state.companyKMLShapeLayers;
                                      companyKMLShapeLayers[index].ShowOnApp = e.target.checked;
                                      this.setState({companyKMLShapeLayers});
                                    }}/>App
                                    <input style={{marginRight: 5, marginLeft : 5}} className="customFormRowControl xsmall" type="checkbox" checked={layer.ShowOnWeb ? 'checked' : ''} value={layer.ShowOnWeb} onChange={e =>{
                                      let companyKMLShapeLayers = this.state.companyKMLShapeLayers;
                                      companyKMLShapeLayers[index].ShowOnWeb = e.target.checked;
                                      this.setState({companyKMLShapeLayers});
                                    }}/>Web
                                    <Button onClick={()=>{
                                      let companyKMLShapeLayers = this.state.companyKMLShapeLayers;
                                      companyKMLShapeLayers[index].DeletedDate = new Date();
                                      companyKMLShapeLayers[index].DeletedDateUTCOffset = - new Date().getTimezoneOffset() / 60;
                                      companyKMLShapeLayers[index].DeletedBy = auth.employeeId();
                                      this.setState({companyKMLShapeLayers});
                                    }} className="buttonAction buttonAccount notificationRowControl notificationRowButton">
                                      <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                    </Button>
                                  </div>
                                </div>
                              ))}
                            </div>}
                            </div>
                            <div className="horizontalLine"></div>
                            <div className="row" style={{marginTop : 10}}>
                              <div className="justify-content-left">
                                {this.state.updatingCompanyKMLShapeLayers == "" && <div>
                                  {!this.state.pendingUploadedFile && <Button className="buttonAction buttonAccount">
                                  <AddBoxSharpIcon className="buttonIcon"/>
                                  <label style={{margin: 0, cursor: 'pointer'}} className="buttonText">UPLOAD FILE</label>
                                </Button>}
                                { !this.state.pendingUploadedFile && <input type="file" id="uploadPicture attachDocumentButton" accept=".kml,.zip" style={{position : 'relative', right : 200, opacity : 0, width: 120, height: 40}} onChange={this.readyNewKMLShapeFile}/>}
                                <Button onClick={() =>{this.saveCompanyKMLShapeLayers();}} className="buttonAction buttonAccount">
                                  <SaveSharpIcon className="buttonIcon"/>
                                  <label style={{margin: 0, cursor: 'pointer'}} className="buttonText">Save</label>
                                </Button>
                                  </div>}
                                  {this.state.updatingCompanyKMLShapeLayers != "" &&
                                <div className="row d-flex align-items-center justify-content-center">
                                  <div className="d-flex align-items-center justify-content-center authSpinnerDiv">
                                    <label className="buttonText withIcon" style={{color : "black"}}>{this.state.updatingCompanyKMLShapeLayers}</label>
                                    <div className="barLoaderPortal">
                                      <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={true}/>
                                    </div>
                                  </div>
                                </div>
                              }
                              </div>
                            </div>
                          </div>

                          <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10, marginTop : 20}}>Configure ARCGIS Feature Map Layers
                          </div>
                          <div className="licenseEditDivContent">
                            <div className="row">
                              {(!this.state.arcGisConnected) && <div>Cannot configure map layers without connecting to ARCGIS (above)<br/></div>}
                              {!this.state.validArcGisKeys && <div>Cannot configure map layers without validated Id\Secret (above)</div>}
                              {this.state.loadingUserFeatureLayers && <div className="row d-flex align-items-center justify-content-center">
                                  <div className="d-flex align-items-center justify-content-center authSpinnerDiv">
                                    <label className="withIcon">LOADING YOUR FEATURE LAYERS...</label>
                                    <div className="barLoaderPortal">
                                      <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={true}/>
                                    </div>
                                  </div>
                                </div>}
                              {(this.state.arcGisConnected &&  this.state.validArcGisKeys && !this.state.loadingUserFeatureLayers) && 
                              <div style={{width: '100%'}}>
                                {this.state.CompanyArcGisLayers && this.state.CompanyArcGisLayers.map((layer, index) =>(
                                  !layer.DeletedDate && <div key={index} style={{display : 'flex', flexDirection : 'column'}}>
                                    <div className="row notificationRowDiv">
                                     <label htmlFor={"pickLayer"+index}  className="accountHeader notificationLabel">Feature Layer: </label>
                                     <select id={"pickLayer" +index}  className="ARCGISFormControl notificationRowControl" onChange={e =>{
                                      let CompanyArcGisLayers = this.state.CompanyArcGisLayers;
                                      let currentLayer = CompanyArcGisLayers[index];
                                      let featureLayer = this.state.arcGisConnectedFeatureLayerList[e.target.value];
                                      if(featureLayer){
                                        currentLayer.FeatureLayerId = featureLayer.id;
                                        currentLayer.Url = featureLayer.url;
                                        currentLayer.GeometryType = featureLayer.geometryType;
                                        currentLayer.Name = featureLayer.name;
                                        this.setState({CompanyArcGisLayers});
                                      }
                                      }} value={this.state.arcGisConnectedFeatureLayerList.findIndex(x => x.id == layer.FeatureLayerId && x.name == layer.Name)}>
                                      <option></option>
                                      {this.state.arcGisConnectedFeatureLayerList && this.state.arcGisConnectedFeatureLayerList.map((featureLayer, featureLayerIndex) =>(
                                        <option key={featureLayerIndex} value={featureLayerIndex}>{featureLayer.name}</option>
                                      ))}
                                     </select>
                                     <label htmlFor={'layerType' + index} className="accountHeader notificationLabel" style={{marginLeft : 15}}>Layer Type:</label>
                                     <select id={"layerType" + index} className="ARCGISFormControl notificationRowControl" value={layer.LayerType} onChange={e =>{
                                      let CompanyArcGisLayers = this.state.CompanyArcGisLayers;
                                      let currentLayer = CompanyArcGisLayers[index];
                                      currentLayer.LayerType = e.target.value;
                                      if(e.target.value == "TicketArea"){
                                        currentLayer.ShowOnApp = false;
                                      }
                                      this.setState({CompanyArcGisLayers});
                                     }}>
                                      <option value={'GPS'}>GPS Survey</option>
                                      <option value={'GIS'}>GIS Inspection</option>
                                      <option value={'Survey'}>Data/Ticket Map</option>
                                      {(this.state.CompanyArcGisLayers[index].GeometryType == "esriGeometryPolygon" || (this.state.CompanyArcGisLayers[index].LayerType == "TicketArea" ||this.state.CompanyArcGisLayers[index].LayerType == "Analysis" )) && <option value={'TicketArea'}>Ticket Area</option>}
                                      {(this.state.CompanyArcGisLayers[index].GeometryType == "esriGeometryPolygon" || (this.state.CompanyArcGisLayers[index].LayerType == "TicketArea" ||this.state.CompanyArcGisLayers[index].LayerType == "Analysis" )) && <option value={'Analysis'}>Analysis Layer</option>}
                                     </select>
                                     {layer.LayerType != "TicketArea" && <label className="accountHeader notificationLabel" style={{marginLeft : 15}}>Add to Map: </label>}
                                     {layer.LayerType != "TicketArea" && <input style={{marginRight : 5}} className="customFormRowControl xsmall" type="checkbox" checked ={layer.ShowOnApp ? 'checked' : ''} value={layer.ShowOnApp} onChange={e =>{
                                      let CompanyArcGisLayers = this.state.CompanyArcGisLayers;
                                      CompanyArcGisLayers[index].ShowOnApp = e.target.checked;
                                      this.setState({CompanyArcGisLayers});
                                      }}/>} {layer.LayerType != "TicketArea" &&  <div>App</div>}
                                     {layer.LayerType != "TicketArea" && <input style={{marginRight : 5, marginLeft : 5}} className="customFormRowControl xsmall" type="checkbox" checked ={layer.ShowOnWeb ? 'checked' : ''} value={layer.ShowOnWeb} onChange={e =>{
                                      let CompanyArcGisLayers = this.state.CompanyArcGisLayers;
                                      CompanyArcGisLayers[index].ShowOnWeb = e.target.checked;
                                      this.setState({CompanyArcGisLayers});
                                      }}/>}{layer.LayerType != "TicketArea" &&  <div>Web</div>}
                                      <Button onClick={()=>{
                                        let CompanyArcGisLayers = this.state.CompanyArcGisLayers;
                                        CompanyArcGisLayers[index].DeletedDate = new Date();
                                        CompanyArcGisLayers[index].DeletedDateUTCOffset = - new Date().getTimezoneOffset() / 60;
                                        CompanyArcGisLayers[index].DeletedBy = auth.employeeId();
                                        this.setState({CompanyArcGisLayers});
                                      }} className="buttonAction buttonAccount notificationRowControl notificationRowButton">
                                        <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                      </Button>
                                    </div>
                                  </div>
                                ))}
                              </div>}
                            </div>
                            <div className="horizontalLine"></div>
                          {(this.state.arcGisConnected &&  this.state.validArcGisKeys) && <div className="row" style={{ marginTop : 10}}>
                            <div className="justify-content-left">
                              {!this.state.loadingCompanyArcGisLayers && <div>
                                <Button onClick={() =>{if(auth.isSuperViewer()){return;}let CompanyArcGisLayers = this.state.CompanyArcGisLayers; CompanyArcGisLayers.push({CompanyArcGisLayerId : -1, CompanyId : auth.companyId(), CreatedBy: auth.employeeId(), CreatedDate : new Date(), CreatedDateUTCOffset : - new Date().getTimezoneOffset() / 60, LayerType : 'GPS',ShowOnWeb : true, ShowOnApp : true});this.setState({CompanyArcGisLayers})}}   className="buttonAction buttonAccount">
                                  <AddBoxSharpIcon className="buttonIcon"/>
                                  <label style={{margin: 0, cursor: 'pointer'}} className="buttonText">New Layer Connection</label>
                                </Button>
                                <Button onClick={() =>{this.saveCompanyArcGisLayers();}} className="buttonAction buttonAccount">
                                  <SaveSharpIcon className="buttonIcon"/>
                                  <label style={{margin: 0, cursor: 'pointer'}} className="buttonText">Save</label>
                                </Button>
                              </div>}
                              {this.state.loadingCompanyArcGisLayers &&
                                <div className="row d-flex align-items-center justify-content-center">
                                  <div className="d-flex align-items-center justify-content-center authSpinnerDiv">
                                    <label className="buttonText withIcon">UPDATING LAYER CONNECTIONS...</label>
                                    <div className="barLoaderPortal">
                                      <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={true}/>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>}
                          </div>
                          

                          <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10, marginTop: 20}}>Form Data Export Mapping
                          </div>
                          <div className="licenseEditDivContent">
                            <div className="row">
                            {this.state.loadingUserFeatureLayers && <div className="row d-flex align-items-center justify-content-center">
                                  <div className="d-flex align-items-center justify-content-center authSpinnerDiv">
                                    <label className="withIcon">LOADING YOUR FEATURE SERVICES...</label>
                                    <div className="barLoaderPortal">
                                      <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={true}/>
                                    </div>
                                  </div>
                                </div>}
                              {(!this.state.loadingForms && !this.state.loadingFeatureLayerData && this.state.arcGisConnected && this.state.arcGisUserFeatureLayerList.length > 0 && this.state.companyFormsWithFeatureLayerMappings && this.state.companyFormsWithFeatureLayerMappings.length > 0) &&
                                <div style={{width: "100%"}}>
                              {this.state.companyFormsWithFeatureLayerMappings.map((form, f_index) => ( 
                                <div key={"formToArcGisLayerMapGroup"+f_index} className="formToArcGisLayerMapGroup">

                                  <div className="formToArcGisLayerMapGroupHeader">  
                                    <label className="accountHeader arcGisFormToLayerMapFormLabel">{form.Name}</label>
                                    <div>
                                      <select id={"formFeatureServiceExportSelect" + f_index} className = "form-control arcGisFormToLayerMapDropdown" 
                                          value = {form.featureLayerMappings ? form.featureLayerMappings.FeatureServiceId : -1} 
                                          onChange ={ e => { this.updateFormExportFeatureLayer(e, f_index) }} 
                                          >
                                            {!form.featureLayerMappings && 
                                              <option key={'formFeatureLayerExportOption-1'} value={-1}>{"Select Feature Service"}</option>
                                            }
                                          {this.state.arcGisUserFeatureLayerList.map((featureLayer, featureLayer_index) => (
                                            <option key={'formFeatureLayerExportOption'+featureLayer_index} value={featureLayer.id}>{featureLayer.title}</option>
                                          ))}
                                      </select>
                                    </div>
                                  </div>
                                  {form.loadingFeatureLayerData  ?
                                    <div className='accountFormtoLayerMappingGroupLoading'>
                                      <div style={{display: 'flex', alignItems:'center', justifyContent: 'center', height: 60, flexDirection: 'column'}}>
                                      <label className="accountHeader arcGisFormToLayerMapFormLabel">LOADING...</label>
                                        <BarLoader sizeUnit={"px"} size={400} color={"#095399"} loading={ true} />
                                      </div>
                                    </div>
                                    : 
                                    form.featureLayerMappings &&
                                    <div>
                                        <div className='accountFormtoLayerMappingGroup'>
                                        <label className="accountHeader arcGisFormToLayerMapFormLabel">LAYER: </label>
                                          <select key={"formFeatureLayerExportSelect" + f_index} id={"formFeatureLayerExportSelect" + f_index} className = "form-control arcGisFormToLayerMapDropdown" 
                                              value = {form.SelectedLayerId} 
                                              onChange ={ e => { this.selectedExportLayerChange(e, f_index) }} 
                                              >
                                                {form.AvailableLayers.length == 0 && 
                                                  <option key={'formFeatureLayerExportOption-1'} value={-1}>{"No Compatible Layers"}</option>
                                                }
                                                {form.AvailableLayers.map((layer, layer_index) => (
                                                  <option key={'formFeatureLayerExportOption' + layer_index} value={layer.id}>{layer.name}</option>
                                                ))}
                                          </select>
                                        
                                        </div>
                                       
                                        <div className="gisExportEnableExportToLayerContainer"> 
                                        <label className="accountHeader arcGisFormToLayerMapFormLabel">Enable export to this layer:</label>
                                          <input type="checkbox" id={"customFormEnabled"} checked={form.featureLayerMappings.LayerMappings.filter(x => x.FeatureLayerId == form.SelectedLayerId).length > 0 ? 'checked' : ''} value={form.featureLayerMappings.LayerMappings.filter(x => x.FeatureLayerId == form.SelectedLayerId).length > 0} className = "form-control customFormRowControl xsmall"
                                            onChange ={e =>{ this.toggleGisLayerExport(e, f_index) } } />
                                        </div>

                                        {form.featureLayerMappings.FieldMappings.map((mapping, mapping_index) => ( mapping.FeatureLayerId == form.SelectedLayerId &&
                                          <div key={"featureLayerExportFieldSelectContainer" + f_index + "_" + mapping_index}  id={"featureLayerExportFieldSelectContainer" + f_index + "_" + mapping_index} className='accountFormtoLayerMappingGroup'>
                                            <label className="accountHeader arcGisFormToLayerMapFormLabel">FORM FIELD: </label>
                                            <div>
                                              <select key={"featureLayerExportFormFieldSelect" + f_index + "_" + mapping_index} id={"featureLayerExportFormFieldSelect" + f_index + "_" + mapping_index} className = "form-control arcGisFormToLayerMapDropdown" 
                                              value = {mapping.FormFieldName + "|" + (mapping.IsGeoDataField ? "1" : "0") + "|" + (mapping.IsFormDataField ? "1" : "0")} 
                                              onChange ={ e => { this.selectedLayerExportFormFieldChange(e, f_index, mapping_index) }} 
                                              >
                                                {(form.AvailableLayers[form.SelectedLayerIndex].geometryType == "esriGeometryPolyline" || form.AvailableLayers[form.SelectedLayerIndex].geometryType == "esriGeometryPolygon") ? form.Fields.filter(x => !x.IsGeoDataField).map((field, field_index) => ( !this.formFieldIsAlreadyMapped(field, f_index, mapping_index) && 
                                                  <option key={"formFeatureLayerExportFormFieldSelectOption" + f_index + "_" + mapping_index + "_" + field_index} value={field.Name + "|" + (field.IsGeoDataField ? "1" : "0") + "|" + (field.IsFormDataField ? "1" : "0")}>{field.Name}</option>
                                                ))
                                                :
                                                form.Fields.map((field, field_index) => ( !this.formFieldIsAlreadyMapped(field, f_index, mapping_index) && 
                                                    <option key={"formFeatureLayerExportFormFieldSelectOption" + f_index + "_" + mapping_index + "_" + field_index} value={field.Name + "|" + (field.IsGeoDataField ? "1" : "0") + "|" + (field.IsFormDataField ? "1" : "0")}>{field.Name}</option>
                                                ))
                                                }
                                              </select>
                                            </div>
                                            <label style={{marginLeft: 15}} className="accountHeader arcGisFormToLayerMapFormLabel">LAYER FIELD: </label>
                                            <div>
                                              <select key={"featureLayerExportLayerFieldSelect" + f_index + "_" + mapping_index} id={"featureLayerExportLayerFieldSelect" + f_index + "_" + mapping_index} className = "form-control arcGisFormToLayerMapDropdown" 
                                              value = {mapping.FeatureLayerFieldName} 
                                              onChange ={ e => { this.selectedLayerExportLayerFieldChange(e, f_index, mapping_index) }} 
                                              >
                                                {form.AvailableLayers[form.SelectedLayerIndex].fields.map((field, field_index) => ( !this.layerFieldIsAlreadyMapped(field, f_index, mapping_index) && 
                                                  <option key={"formFeatureLayerExportFormFieldSelectOption" + f_index + "_" + mapping_index + "_" + field_index} value={field.Name}>{field.name}</option>
                                                ))}
                                              </select>
                                            </div>
                                            <div>
                                              <Button onClick={()=>{this.deleteLayerFieldMapping(f_index, mapping_index)}} title="Remove Field Map" 
                                                className="buttonAction buttonAccount removeLayerFieldMapping xsmall square">
                                                <HighlightOffTwoToneIcon className="buttonIcon removeFieldMapping"/>
                                              </Button>
                                            </div>
                                          </div>
                                        ))}
                                      
                                      <div className='accountFormtoLayerMappingGroup'>
                                        {form.SelectedLayerId != -1 && form.featureLayerMappings.LayerMappings.filter(x => x.FeatureLayerId == form.SelectedLayerId).length > 0 &&
                                          <Button onClick={()=>{this.addArcGisFormFieldMapping(f_index);}} className = "buttonAction buttonAddFieldGisExport small">
                                            <AddBoxSharpIcon className="buttonIcon small"/>
                                            <label style ={{ margin: 0, cursor: 'pointer', fontSize:12}} className ="buttonText">Add Field</label>
                                          </Button>
                                        }
                                        {form.SelectedLayerId != -1 && form.featureLayerMappings && form.featureLayerMappings.FieldMappings && form.featureLayerMappings.FieldMappings.filter(x => x.FeatureLayerId == form.SelectedLayerId).length > 0 &&
                                          <Button onClick={()=>{this.validateArcGisMapping(f_index);}} className = "buttonAction buttonAddFieldGisExport small">
                                            <DoneIcon className="buttonIcon small"/>
                                            <label style ={{ margin: 0, cursor: 'pointer', fontSize:12}} className ="buttonText">Validate</label>
                                          </Button>
                                        }
                                        {this.state.validatingARCGISMapping && 
                                          <div className="justify-content-left" style={{width:500}}>
                                          <div className=" d-flex align-items-center justify-content-center authSpinnerDiv">
                                            VALIDATING...
                                            <div className="barLoaderPortal"><BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true } /> </div>
                                          </div></div>
                                          }
                                         {
                                          form.hasSavedMappings && !this.state.ARCGISEnterpriseUrl && 
                                            <div style={{margin: 5}}>
                                              <label>Auto Export Data: </label><input style={{marginLeft : 5}} className="customFormCheckbox"  type="checkbox"  checked={form.featureLayerMappings && form.featureLayerMappings.Frequency != null} onChange={(e) => {this.onAutoExportDataCheckboxChange(f_index)}}/>
                                            </div>
                                          }
                                          { form.hasSavedMappings && form.featureLayerMappings && form.featureLayerMappings.Frequency != null && 
                                              <select style={{position : 'relative', top: 10}} className = "form-control arcGisFormToLayerMapDropdown" value={form.featureLayerMappings.Frequency} onChange={(e) =>{this.onAutoExportFrequencyDropdownChange(e, f_index)}}>
                                                <option value="On Creation">On Creation</option>
                                                <option value="End of Day">End of Day (midnight)</option>
                                              </select>}
                                      </div>

                                      <div className='accountFormtoLayerMappingGroup'>
                                        {this.unsavedFormToLayerMap(f_index) &&
                                          <Button onClick={()=>{if(!form.mappingDataSaving && !form.deletingExportMap)this.saveArcGisFormToLayerMap(f_index);}} className = "buttonAction buttonFormFieldMappingSectionButtons small confirm">
                                            <SaveSharpIcon className="buttonIcon small"/>
                                            <label style ={{ margin: 0, cursor: 'pointer', fontSize:12}} className ="buttonText">{form.mappingDataSaving ? "Saving..." : "Save"}</label>
                                          </Button>
                                        }
                                        {(form.revertToMappings || !form.hasSavedMappings) &&
                                          <Button onClick={()=>{if(!form.mappingDataSaving && !form.deletingExportMap)this.discardFormFieldMappingChanges(f_index);}} className = "buttonAction buttonFormFieldMappingSectionButtons small cancel">
                                            <UndoTwoTone className="buttonIcon small"/>
                                            <label style ={{ margin: 0, cursor: 'pointer', fontSize:12}} className ="buttonText">Discard Changes</label>
                                          </Button>
                                        }
                                        {(form.hasSavedMappings) &&
                                          <Button onClick={()=>{if(!form.mappingDataSaving && !form.deletingExportMap)this.setState({deleteExportMapWarning: true, deleteExportMapFormIndex: f_index})}} title="Remove Field Map" 
                                            className="buttonAction buttonFormFieldMappingSectionButtons small cancel">
                                            <HighlightOffTwoToneIcon className="buttonIcon small"/>
                                            <label style ={{ margin: 0, cursor: 'pointer', fontSize:12}} className ="buttonText">{form.deletingExportMap ? "Deleting..." : "Delete"}</label>
                                          </Button>
                                        }
                                      </div>
                                    </div>
                                  }
                                  {/* <div key={"triggerCollection"+t_index} className='accountFormTriggerGroupTriggers'>
                                      
                                    <div className="formTriggerGroupTriggerValues">
                                        
                                      <label htmlFor={"formTriggerFieldFromLabel" + t_index} className="accountHeader notificationLabel">If:</label>
                                      <select id={"formTriggerFieldFrom" + t_index} className = "form-control notificationRowControl trigger" 
                                          value = {trigger.TriggerFieldFromNameIndex} 
                                          onChange ={ e => { this.updateTriggerFieldFrom(e, form, f_index, t_index) }} >
                                          <option></option>
                                          {form.Fields.map((field, field_index) => (
                                            <option key={'triggerFieldOption'+field_index} value={field_index}>{field.Name}</option>
                                          ))}
                                      </select>
                                    </div>
                                  </div> */}
                                </div>
                              ))}
                            </div>
                              }
                              

                            </div>
                          </div>
                          
                        </React.Fragment>
                      </Tab.Pane>
                    }
                    {
                      <Tab.Pane className="d-flex flex-column w-100 p-0 m-0 justify-content-center" eventKey="tickets" id="tickets-tab" mountOnEnter unmountOnExit>
                        <React.Fragment>
                        <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10}}>
                          Call Center Authentication Configuration
                          <div className="labelHeaderBarSubHeader">Configure Call Center permissions</div>
                        </div>
                        {this.state.CallCenters && <div className="licenseEditDivContent">
                          <div style={{display : "flex", flexDirection : "column"}}>
                            <div className="row notificationRowDiv">
                              <label htmlFor="CallCenterSelect" className="accountHeader notificationLabel">Call Center:</label>
                              <select id="CallCenterSelect" className="notificationRowControl form-control" value={this.state.selectedCallCenter.CallCenterId} onChange={e => {this.updateCallCenter(e)}}>
                                <option value={null}></option>
                                {this.state.CallCenters.map((callCenter, index) => 
                                  <option key={index} value={callCenter.CallCenterId}>{callCenter.CallCenterName}</option>)}
                              </select>
                              <label style={{marginLeft : 15}} htmlFor="callCenterUserName" className="accountHeader notificationLabel">User Name:</label>
                              <input id="callCenterUserName" className="notificationRowControl form-control" onChange={e =>{this.updateCallCenterUserName(e)}} value={this.state.selectedCallCenter.CallCenterUserName}/>
                              <label style={{marginLeft : 15}} htmlFor="callCenterPassword" className="accountHeader notificationLabel">Password:</label>
                              <input id="callCenterPassword" className="notificationRowControl form-control" type={this.state.showCallCenterPassword ? "text" : "password"} onChange={e =>{this.updateCallCenterPassword(e)}} value={this.state.selectedCallCenter.CallCenterPassword}/>
                              {this.state.showCallCenterPassword ?  <VisibilityOffIcon onClick={()=>{this.setState({showCallCenterPassword : false})}} style={{cursor : 'pointer', marginLeft:10, color:'#095399'}}/> : <VisibilityIcon onClick={()=>{this.setState({showCallCenterPassword : true})}} style={{cursor : 'pointer', marginLeft:10, color:'#095399'}}/>}
                            </div>
                          </div>
                           <div className="horizontalLine"></div>
                          <div className="row" style={{marginTop: 10}}>
                              <div className="justify-content-left">
                                {  !this.state.loadingCallCenterConfig ? <div>
                                  <Button onClick={() =>{this.updateCallCenterConfig();}} className="buttonAction buttonAccount">
                                    <SaveSharpIcon className="buttonIcon"/>
                                    <label style={{ margin: 0, cursor: 'pointer'}} className="buttonText">Save</label>
                                  </Button>
                                </div>
                                : <div>
                                    <div className="row d-flex align-items-center justify-content-center">
                                      <div className="d-flex align-items-center justify-content-center authSpinnerDiv">
                                        <label className="buttonText withIcon">UPDATING CALL CENTER CONFIG...</label>
                                        <div className="barLoaderPortal">
                                          <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true } />
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                }
                          </div>
                        </div>
                        </div>}
                        
                        <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10, marginTop : 10}}>
                          Member Codes
                          <div className="labelHeaderBarSubHeader">Configure Member Codes</div>
                        </div>
                        <div className="licenseEditDivContent">
                        {(this.state.selectedCallCenter.CallCenterId && this.state.distinctMemberCodes && this.state.distinctMemberCodes[this.state.selectedCallCenter.CallCenterId] && this.state.distinctMemberCodes[this.state.selectedCallCenter.CallCenterId].length > 0) && this.state.distinctMemberCodes[this.state.selectedCallCenter.CallCenterId].map((companyResponseCode, index) => 
                          <div key ={index} style={{display : 'flex', flexDirection : 'column'}}>
                          <div className="row notificationRowDiv">
                          <label htmlFor={"memberCode" + index} className="accountHeader notificationLabel">MEMBER CODE: </label>
                              <input id={"memberCode" + index} 
                                className="notificationRowControl form-control" onChange={e =>{
                                  let distinctMemberCodes = this.state.distinctMemberCodes;
                                  let callCenterCodes = distinctMemberCodes[this.state.selectedCallCenter.CallCenterId];
                                  let existingCode = callCenterCodes.indexOf(e.target.value);
                                  if(existingCode != -1 && existingCode != index){
                                    this.setState({updateStatusText :"MemberCode Already Exists.", alertSeverity : 'warning'});
                                    return;
                                  }
                                  let companyResponseCodes = this.state.companyResponseCodes;
                                  //Need to update all membercodes in responsecodes.
                                  try{companyResponseCodes.forEach(code =>{
                                    if(code.MemberCode == callCenterCodes[index]){
                                      code.MemberCode = e.target.value;
                                    }
                                  })}catch(e){console.log('erx',e)}
                                  
                                  callCenterCodes[index] = e.target.value;
                                  this.setState({distinctMemberCodes, companyResponseCodes});
                                }}
                                value = {this.state.distinctMemberCodes[this.state.selectedCallCenter.CallCenterId][index]}/>
                                <Button onClick={() =>{
                                  let companyResponseCodes = this.state.companyResponseCodes;
                                  let distinctMemberCodes = this.state.distinctMemberCodes;
                                  let callCenterCodes = distinctMemberCodes[this.state.selectedCallCenter.CallCenterId];
                                  companyResponseCodes.forEach(code =>{
                                    if(code.MemberCode == callCenterCodes[index]){
                                      code.DeletedDate = new Date();
                                      code.DeletedDateUTCOffset = - new Date().getTimezoneOffset() / 60;
                                      code.DeletedBy = auth.employeeId();
                                    }
                                  });
                                  callCenterCodes.splice(index,1);
                                  this.setState({companyResponseCodes,distinctMemberCodes});
                                }} className="buttonAction buttonAccount notificationRowControl notificationRowButton">
                                  <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                </Button>
                          </div>
                        </div>)}
                        <div className="horizontalLine"></div>
                        <Button onClick={() =>{
                          if(auth.isSuperViewer() || !this.state.selectedCallCenter.CallCenterId){return;}
                          let distinctMemberCodes = this.state.distinctMemberCodes;
                          if(!distinctMemberCodes[this.state.selectedCallCenter.CallCenterId]){
                            distinctMemberCodes[this.state.selectedCallCenter.CallCenterId] = [];
                          }
                          let callCenterCodes = distinctMemberCodes[this.state.selectedCallCenter.CallCenterId];
                          callCenterCodes.push("");
                          
                          this.setState({distinctMemberCodes});
                        }} className="buttonAction buttonAccount">
                          <AddBoxSharpIcon className="buttonIcon"/>
                          <label style={{ margin: 0, cursor : 'pointer'}} className="buttonText">ADD CODE</label>
                        </Button>
                        </div>

                        {(this.state.selectedCallCenter && this.state.selectedCallCenter.CallCenterId == 1) && <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10, marginTop : 10}}>
                          Response Codes
                          <div className="labelHeaderBarSubHeader">Configure Responses Codes</div>
                        </div>}
                        {(this.state.selectedCallCenter && this.state.selectedCallCenter.CallCenterId == 1) &&<div className="licenseEditDivContent">
                          {this.state.distinctResponseCodes && this.state.distinctResponseCodes.length > 0 && this.state.distinctResponseCodes.map((companyResponseCode, index) => (!companyResponseCode.DeletedDate && companyResponseCode.CallCenterId == this.state.selectedCallCenter.CallCenterId) &&
                          <div key ={index} style={{display : 'flex', flexDirection : 'column'}}>
                            <div className="row notificationRowDiv">
                                <label style={{marginLeft : 15}} htmlFor={"responseName" + index} className="accountHeader notificationLabel">RESPONSE NAME: </label>
                                <input id={"responseName" + index} 
                                className="notificationRowControl form-control" onChange={e =>{
                                  let companyResponseCodes = this.state.companyResponseCodes;
                                  let distinctResponseCodes = this.state.distinctResponseCodes;
                                  let existingCode = distinctResponseCodes.findIndex(x => x.ResponseName == e.target.value && x.CallCenterId == this.state.selectedCallCenter.CallCenterId);
                                  if(existingCode != -1 && existingCode != index){
                                    this.setState({updateStatusText : "Response Code Already Exists.", alertSeverity : "warning"});
                                    return;
                                  }
                                  companyResponseCodes.forEach(code =>{
                                    if(code.ResponseName == distinctResponseCodes[index].ResponseName){
                                      code.ResponseName = e.target.value;
                                    }
                                  });
                                  distinctResponseCodes[index].ResponseName = e.target.value;
                                  this.setState({companyResponseCodes,distinctResponseCodes});
                                }}
                                value = {this.state.distinctResponseCodes[index].ResponseName}/>
                                <label style={{marginLeft : 15}} htmlFor={"responseCode" + index} className="accountHeader notificationLabel">RESPONSE CODE: </label>
                                <input id={"responseCode" + index}
                                className="notificationRowControl form-control" onChange={e =>{
                                  let companyResponseCodes = this.state.companyResponseCodes;
                                  let distinctResponseCodes = this.state.distinctResponseCodes;
                                  companyResponseCodes.forEach(code =>{
                                    if(code.ResponseName == distinctResponseCodes[index].ResponseName){
                                      code.ResponseCode = e.target.value;
                                    }
                                  });
                                  distinctResponseCodes[index].ResponseCode = e.target.value;
                                  this.setState({companyResponseCodes,distinctResponseCodes});
                                }}
                                value = {this.state.distinctResponseCodes[index].ResponseCode}/>
                                <Button onClick={() =>{
                                  let companyResponseCodes = this.state.companyResponseCodes;
                                  let distinctResponseCodes = this.state.distinctResponseCodes;
                                  companyResponseCodes.forEach(code =>{
                                    if(code.ResponseName == distinctResponseCodes[index].ResponseName){
                                      code.DeletedDate = new Date();
                                      code.DeletedDateUTCOffset = - new Date().getTimezoneOffset() / 60;
                                      code.DeletedBy = auth.employeeId();
                                    }
                                  });
                                  distinctResponseCodes.splice(index,1);
                                  this.setState({companyResponseCodes,distinctResponseCodes});
                                }} className="buttonAction buttonAccount notificationRowControl notificationRowButton">
                                  <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                </Button>
                            </div>
                          </div>)}
                          <div className="horizontalLine"></div>
                            <div className="row" style={{marginTop: 10}}>
                              <div className="justify-content-left">
                                {  !this.state.loadingCompanyResponseCodes ? <div>
                                  <Button onClick={() =>{if(auth.isSuperViewer()){return;}
                                      let distinctResponseCodes = this.state.distinctResponseCodes;
                                      distinctResponseCodes.push({ResponseName : "", ResponseCode : "", CallCenterId : this.state.selectedCallCenter.CallCenterId});
                                      this.setState({distinctResponseCodes});
                                }} className="buttonAction buttonAccount">
                                    <AddBoxSharpIcon className="buttonIcon"/>
                                    <label style={{ margin: 0, cursor : 'pointer'}} className="buttonText">ADD CODE</label>
                                  </Button>
                                </div>
                                : <div>
                                    <div className="row d-flex align-items-center justify-content-center">
                                      <div className="d-flex align-items-center justify-content-center authSpinnerDiv">
                                        <label className="buttonText withIcon">UPDATING RESPONSE CODES...</label>
                                        <div className="barLoaderPortal">
                                          <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true } />
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                }
                              </div>
                            </div>
                        </div>}



                        {(this.state.selectedCallCenter && this.state.selectedCallCenter.CallCenterId == 2) && <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10, marginTop : 10}}>
                          Facilities
                          <div className="labelHeaderBarSubHeader">Configure Facilities</div>
                          </div>}
                        {(this.state.selectedCallCenter && this.state.selectedCallCenter.CallCenterId == 2) &&<div className="licenseEditDivContent">
                          {this.state.distinctFacilities && this.state.distinctFacilities.length > 0 && this.state.distinctFacilities.map((companyFacility, index) => (!companyFacility.DeletedDate && companyFacility.CallCenterId == this.state.selectedCallCenter.CallCenterId) &&
                          <div key ={index} style={{display : 'flex', flexDirection : 'column'}}>
                            <div className="row notificationRowDiv">
                                <label style={{marginLeft : 15}} htmlFor={"facility" + index} className="accountHeader notificationLabel">Facility NAME: </label>
                                <input id={"facility" + index} 
                                className="notificationRowControl form-control" onChange={e =>{
                                  let companyResponseCodes = this.state.companyResponseCodes;
                                  let distinctFacilities = this.state.distinctFacilities;
                                  let existingCode = distinctFacilities.findIndex(x => x.Facility == e.target.value && x.CallCenterId == this.state.selectedCallCenter.CallCenterId);
                                  if(existingCode != -1 && existingCode != index){
                                    this.setState({updateStatusText : "Facility Already Exists.", alertSeverity : "warning"});
                                    return;
                                  }
                                  companyResponseCodes.forEach(code =>{
                                    if(code.Facility == distinctFacilities[index].Facility){
                                      code.Facility = e.target.value;
                                    }
                                  });
                                  distinctFacilities[index].Facility = e.target.value;
                                  this.setState({companyResponseCodes,distinctFacilities});
                                }}
                                value = {this.state.distinctFacilities[index].Facility}/>
                                
                                <Button onClick={() =>{
                                  let companyResponseCodes = this.state.companyResponseCodes;
                                  let distinctFacilities = this.state.distinctFacilities;
                                  companyResponseCodes.forEach(code =>{
                                    if(code.Facility == distinctFacilities[index].Facility){
                                      code.DeletedDate = new Date();
                                      code.DeletedDateUTCOffset = - new Date().getTimezoneOffset() / 60;
                                      code.DeletedBy = auth.employeeId();
                                    }
                                  });
                                  distinctFacilities.splice(index,1);
                                  this.setState({companyResponseCodes,distinctFacilities});
                                }} className="buttonAction buttonAccount notificationRowControl notificationRowButton">
                                  <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                </Button>
                            </div>
                          </div>)}
                          <div className="horizontalLine"></div>
                            <div className="row" style={{marginTop: 10}}>
                              <div className="justify-content-left">
                                {  !this.state.loadingCompanyResponseCodes ? <div>
                                  <Button onClick={() =>{if(auth.isSuperViewer()){return;}
                                      let distinctFacilities = this.state.distinctFacilities;
                                      distinctFacilities.push({Facility : "", CallCenterId : this.state.selectedCallCenter.CallCenterId});
                                      this.setState({distinctFacilities});
                                }} className="buttonAction buttonAccount">
                                    <AddBoxSharpIcon className="buttonIcon"/>
                                    <label style={{ margin: 0, cursor : 'pointer'}} className="buttonText">ADD FACILITY</label>
                                  </Button>
                                </div>
                                : <div>
                                    <div className="row d-flex align-items-center justify-content-center">
                                      <div className="d-flex align-items-center justify-content-center authSpinnerDiv">
                                        <label className="buttonText withIcon">UPDATING FACILITIES...</label>
                                        <div className="barLoaderPortal">
                                          <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true } />
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                }
                              </div>
                            </div>
                        </div>}


                        <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10, marginTop : 10}}>
                          Member Assignment
                          <div className="labelHeaderBarSubHeader">Assign {(this.state.selectedCallCenter && this.state.selectedCallCenter.CallCenterId == 1) && "Responses Codes"}{(this.state.selectedCallCenter && this.state.selectedCallCenter.CallCenterId == 2) && "Facilities"} to Member Codes.</div>
                        </div>
                        <div className="licenseEditDivContent">
                          <div className="row">
                            {this.state.distinctMemberCodes && this.state.selectedCallCenter.CallCenterId &&  this.state.distinctMemberCodes[this.state.selectedCallCenter.CallCenterId] && <div className="col-5">
                                <label className="labelHeaderBar" style={{width:'98%'}}>Member Codes</label>
                                <select className="form-control customFormRowControl" value={this.state.selectedMemberCode} onChange={e => {this.selectMemberCode(e)}} style={{maxWidth:285,height : 42}}>
                                  <option value=""></option> 
                                  {this.state.distinctMemberCodes[this.state.selectedCallCenter.CallCenterId].map((member, index) => 
                                  <option key={index} value={member}>{member}</option>)}
                                </select>
                            </div>}
                            {(this.state.selectedCallCenter && this.state.selectedCallCenter.CallCenterId == 1) &&<div className="col-5">
                              <label className="labelHeaderBar">Response Codes</label>
                              <div className="groupAssignmentUserList" style={{width : "100%"}}>
                                <div style={{minHeight : 10}}/>
                                <div style={{marginLeft : 10}}>
                                    <input type="checkbox" id={"responseCodeSelectorAll"} checked={this.state.distinctResponseCodes && this.state.companyResponseCodes && this.state.selectedMemberCode && this.state.selectedMemberCode != '' && this.state.distinctResponseCodes.length == this.state.companyResponseCodes.filter(x => x.MemberCode == this.state.selectedMemberCode && !x.DeletedDate).length} onChange={()=>{this.selectResponseCode({ResponseName : "ALL"},this.state.distinctResponseCodes.length != this.state.companyResponseCodes.filter(x => x.MemberCode == this.state.selectedMemberCode && !x.DeletedDate).length)}}/>
                                    <label htmlFor={"responseCodeSelectorAll"} style={{paddingLeft: 5, fontSize:14}}>All</label>
                                  </div>
                                {this.state.distinctResponseCodes && this.state.distinctResponseCodes.map((code,index) => (
                                  (!code.DeletedDate && code.CallCenterId == this.state.selectedCallCenter.CallCenterId) &&
                                  <div key={index} style={{marginLeft : 10}}>
                                    <input type="checkbox" id={"responseCodeSelector" + index} checked={this.state.companyResponseCodes.find(x => x.MemberCode == this.state.selectedMemberCode && x.ResponseName == code.ResponseName && !x.DeletedDate)} onChange={()=>{this.selectResponseCode(code,!this.state.companyResponseCodes.find(x => x.MemberCode == this.state.selectedMemberCode && x.ResponseName == code.ResponseName && !x.DeletedDate))}}/>
                                    <label htmlFor={"responseCodeSelector" + index} style={{paddingLeft: 5, fontSize:14}}>{code.ResponseName}</label>
                                  </div>
                                ))}
                              </div>
                            </div>}
                            {(this.state.selectedCallCenter && this.state.selectedCallCenter.CallCenterId == 2) &&<div className="col-5">
                              <label className="labelHeaderBar">Facilities</label>
                              <div className="groupAssignmentUserList" style={{width : "100%"}}>
                                <div style={{minHeight : 10}}/>
                                <div style={{marginLeft : 10}}>
                                    <input type="checkbox" id={"responseCodeSelectorAll"} checked={this.state.distinctFacilities && this.state.companyResponseCodes && this.state.selectedMemberCode && this.state.selectedMemberCode != '' && this.state.distinctFacilities.length == this.state.companyResponseCodes.filter(x => x.MemberCode == this.state.selectedMemberCode && !x.DeletedDate).length} onChange={()=>{this.selectFacility({Facility : "ALL"},this.state.distinctFacilities.length != this.state.companyResponseCodes.filter(x => x.MemberCode == this.state.selectedMemberCode && !x.DeletedDate).length)}}/>
                                    <label htmlFor={"responseCodeSelectorAll"} style={{paddingLeft: 5, fontSize:14}}>All</label>
                                  </div>
                                {this.state.distinctFacilities && this.state.distinctFacilities.map((facility,index) => (
                                  (!facility.DeletedDate && facility.CallCenterId == this.state.selectedCallCenter.CallCenterId) &&
                                  <div key={index} style={{marginLeft : 10}}>
                                    <input type="checkbox" id={"facilitySelector" + index} checked={this.state.companyResponseCodes.find(x => x.MemberCode == this.state.selectedMemberCode && x.Facility == facility.Facility && !x.DeletedDate)} onChange={()=>{this.selectFacility(facility,!this.state.companyResponseCodes.find(x => x.MemberCode == this.state.selectedMemberCode && x.Facility == facility.Facility && !x.DeletedDate))}}/>
                                    <label htmlFor={"facilitySelector" + index} style={{paddingLeft: 5, fontSize:14}}>{facility.Facility}</label>
                                  </div>
                                ))}
                              </div>
                            </div>}
                          </div>
                          <div className="horizontalLine"></div>
                            <div className="row" style={{marginTop: 10}}>
                              <div className="justify-content-left">
                                {  !this.state.loadingCompanyResponseCodes ? <div>
                                  <Button onClick={() =>{this.updateCompanyResponseCodes();}} className="buttonAction buttonAccount">
                                    <SaveSharpIcon className="buttonIcon"/>
                                    <label style={{ margin: 0, cursor: 'pointer'}} className="buttonText">Save</label>
                                  </Button>
                                </div>
                                : <div>
                                    <div className="row d-flex align-items-center justify-content-center">
                                      <div className="d-flex align-items-center justify-content-center authSpinnerDiv">
                                        <label className="buttonText withIcon">UPDATING RESPONSE CODES...</label>
                                        <div className="barLoaderPortal">
                                          <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true } />
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                }
                              </div>
                            </div>
                        </div>

                        <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10, marginTop : 10}}>
                          Processing Rules
                          <div className="labelHeaderBarSubHeader">Configure triggers and actions to apply to data when processing ticket updates</div>
                        </div>
                        <div className="licenseEditDivContent">
                          {this.state.selectedCallCenter && this.state.processingRules && this.state.processingRules.length > 0 && this.state.processingRules.map((rule,index) => ((!rule.DeletedDate && (rule.CallCenterId == this.state.selectedCallCenter.CallCenterId)) && 
                            <div key={index} style={{display: 'flex', flexDirection : 'column', marginTop : index != 0 ? 25 : 0}}>
                              
                                {rule.TriggersObject && rule.TriggersObject.map((trigger, tIndex) => (<div key={tIndex} style={{flex : 1, position : 'relative', left : tIndex == 0 ? 0 : 25, marginTop : tIndex == 0 ? 0 : 5}} className="row notificationRow">
                                  <label htmlFor = {"firstProcessingTrigger"+index} className="accountHeader downloadSubscriptionLabel">If:</label>
                                <select id = {"firstProcessingTrigger"+index}  className = "form-control notificationRowControl" value={trigger.FormField}
                                  onChange={(e) =>{ 
                                    let rules = this.state.processingRules;
                                    rules[index].TriggersObject[tIndex].FormField = e.target.value;
                                    if(e.target.value == "ticketType") rules[index].TriggersObject[tIndex].TriggerFieldValue = "normal";
                                    else rules[index].TriggersObject[tIndex].TriggerFieldValue = "";
                                    this.setState({processingRules : rules});
                                  }}>
                                  <option></option>
                                  <option value="ticketInArea">Ticket In Area</option>
                                  <option value="ticketNotInArea">Ticket Not In Area</option>
                                  <option value="memberCode">Member Code</option>
                                  <option value="ticketType">Ticket Type</option>
                                </select>
                                {trigger.FormField == "memberCode" && <select  className="form-control notificationRowControl" 
                                  value = {trigger.TriggerType}
                                  onChange={e => {
                                    let rules = this.state.processingRules;
                                    rules[index].TriggersObject[tIndex].TriggerType = e.target.value;
                                    this.setState({processingRules : rules});
                                  }}>
                                  <option value='contains' >Contains</option>
                                  <option value='specific' >Has Specific Value</option>
                                  </select>}
                                {(trigger.FormField == "memberCode" && trigger.TriggerType == "contains") && <input style={{position : "relative", bottom : 3}} value={trigger.TriggerFieldValue} className = "form-control customFormRowControlCustomValueEdit customValue"
                                      onChange = {e => { 
                                        let rules = this.state.processingRules;
                                        rules[index].TriggersObject[tIndex].TriggerFieldValue = e.target.value;
                                        this.setState({processingRules : rules}); 
                                      }} />}

                                {(trigger.FormField == "memberCode" && trigger.TriggerType == "specific") && <select className="form-control notificationRowControl"
                                  value={trigger.TriggerFieldValue}
                                  onChange={(e)=>{
                                    let rules = this.state.processingRules;
                                    rules[index].TriggersObject[tIndex].TriggerFieldValue = e.target.value;
                                    this.setState({processingRules : rules});
                                  }}>
                                    <option></option>
                                    {(this.state.selectedCallCenter.CallCenterId && this.state.distinctMemberCodes && this.state.distinctMemberCodes[this.state.selectedCallCenter.CallCenterId] && this.state.distinctMemberCodes[this.state.selectedCallCenter.CallCenterId].length > 0)&& this.state.distinctMemberCodes[this.state.selectedCallCenter.CallCenterId].map((memberCode,mindex) =>(
                                      <option key={mindex}value={memberCode}>{memberCode}</option>
                                    ))}
                                  </select>}
                                {trigger.FormField == "ticketType" && <select className="form-control notificationRowControl"
                                  value={trigger.TriggerFieldValue}
                                  onChange={(e)=>{
                                    let rules = this.state.processingRules;
                                    rules[index].TriggersObject[tIndex].TriggerFieldValue = e.target.value;
                                    this.setState({processingRules : rules});
                                  }}>
                                  <option value='normal' >Normal</option>
                                  <option value='emergency' >Emergency</option>
                                  <option value='canceled' >Canceled</option>
                                  <option value='short' >Short</option>
                                  <option value='renew' >Renew</option>
                                </select>}
                                {(trigger.FormField == "ticketInArea" || trigger.FormField == "ticketNotInArea") && <select className="form-control notificationRowControl"
                                  value={trigger.TriggerFieldValue}
                                  onChange={(e) =>{
                                    let rules = this.state.processingRules;
                                    rules[index].TriggersObject[tIndex].TriggerFieldValue = e.target.value;
                                    this.setState({processingRules : rules});
                                  }}>
                                    <option></option>
                                    {this.state.CompanyArcGisLayers.filter(x => x.LayerType == "Analysis").map((area, areaIndex) => (<option key={areaIndex} value={area.CompanyArcGisLayerId}>{area.Name}</option>))}
                                  </select>}
                                  
                                  {tIndex != rule.TriggersObject.length -1 && <select className="form-control notificationRowControl"
                                    value={trigger.AndOr}
                                    onChange={(e) =>{
                                      let rules = this.state.processingRules;
                                    rules[index].TriggersObject[tIndex].AndOr = e.target.value;
                                    this.setState({processingRules : rules});
                                    }}>
                                      <option value="AND">AND</option>
                                      <option value="OR">OR</option>
                                      </select>}
                                  {tIndex == 0 && <Button onClick={()=>{
                                    let rules = this.state.processingRules;
                                    rules[index].TriggersObject.push({FormField : '', TriggerType : "contains", TriggerFieldValue : "", AndOr : "OR"});
                                    this.setState({processingRules : rules});
                                    }} className = "buttonAction buttonAccount small">
                                      <AddBoxSharpIcon className="buttonIcon small"/>
                                      <label style ={{ margin: 0, cursor: 'pointer', fontSize:12}} className ="buttonText">Add Trigger</label>
                                  </Button>}    
                                  {<Button onClick={()=>{
                                    let rules = this.state.processingRules;
                                    if(rules[index].TriggersObject.length > 1){
                                      rules[index].TriggersObject.splice(tIndex,1);
                                    }
                                    else if(rules[index].ProcessingRuleId == -1){
                                      rules.splice(index,1);
                                    }
                                    else{
                                      rules[index].DeletedDate = new Date();
                                      rules[index].DeletedDateUTCOffset = - (new Date().getTimezoneOffset() / 60);
                                      rules[index].DeletedBy = auth.employeeId();
                                    }
                                    this.setState({processingRules : rules});
                                  }} className="buttonAction buttonAccount notificationRowControl notificationRowButton">
                                    <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                  </Button>}
                                </div>))} 
                                {rule.ActionsObject && rule.ActionsObject.map((action, aIndex) => (<div key={aIndex} style={{flex : 1, position : 'relative', left : aIndex == 0 ? 0 : 25, marginTop : 5}} className="row notificationRow">
                                <label className="accountHeader downloadSubscriptionLabel">Action:</label>
                                <select className = "form-control notificationRowControl" value={action.ProcessAction}
                                  onChange={(e) =>{ 
                                    let rules = this.state.processingRules;
                                    rules[index].ActionsObject[aIndex].ProcessAction = e.target.value;
                                    if(e.target.value == "updateStatus")rules[index].ActionsObject[aIndex].ActionFieldValue = "open";
                                    else{
                                      rules[index].ActionsObject[aIndex].ActionFieldValue = "";
                                    }
                                    this.setState({processingRules : rules});
                                  }}>
                                  <option></option>
                                  <option value="forward">Forward Ticket</option>
                                  <option value="updateStatus">Update Task Status</option>
                                  <option value="setPositiveResponse">Set Positive Response</option>
                                </select>
                                {action.ProcessAction == "forward" && <label style={{marginLeft : 5}} className="accountHeader downloadSubscriptionLabel">Email:</label>}
                                {action.ProcessAction == "forward" && <input value={action.ActionFieldValue} className = "form-control customFormRowControlCustomValueEdit customValue"
                                      onChange = {e => { 
                                        let rules = this.state.processingRules;
                                        rules[index].ActionsObject[aIndex].ActionFieldValue = e.target.value;
                                        this.setState({processingRules : rules}); 
                                      }} />}
                                {action.ProcessAction == "setPositiveResponse" && <label style={{marginLeft : 5}} className="accountHeader downloadSubscriptionLabel">MEMBER CODE:</label>}
                                {action.ProcessAction == "setPositiveResponse" && <select className="form-control notificationRowControl"
                                  value={action.MemberCode}
                                  onChange={(e)=>{
                                    let rules = this.state.processingRules;
                                    rules[index].ActionsObject[aIndex].MemberCode = e.target.value;
                                    this.setState({processingRules : rules});
                                  }}>
                                    <option></option>
                                    {(this.state.selectedCallCenter.CallCenterId && this.state.distinctMemberCodes && this.state.distinctMemberCodes[this.state.selectedCallCenter.CallCenterId] && this.state.distinctMemberCodes[this.state.selectedCallCenter.CallCenterId].length > 0)&& this.state.distinctMemberCodes[this.state.selectedCallCenter.CallCenterId].map((memberCode,mindex) =>(
                                      <option key={mindex}value={memberCode}>{memberCode}</option>
                                    ))}
                                </select>}
                                {action.ProcessAction == "setPositiveResponse" && <label style={{marginLeft : 5}} className="accountHeader downloadSubscriptionLabel">RESPONSE:</label>}
                                {action.ProcessAction == "setPositiveResponse" && <select className="form-control notificationRowControl"
                                  value={action.ActionFieldValue}
                                  onChange={(e)=>{
                                    let rules = this.state.processingRules;
                                    rules[index].ActionsObject[aIndex].ActionFieldValue = e.target.value;
                                    this.setState({processingRules : rules});
                                  }}>
                                  <option></option>
                                  {this.state.companyResponseCodes && this.state.companyResponseCodes.length > 0 && this.state.companyResponseCodes.filter(x => x.MemberCode == this.state.processingRules[index].ActionsObject[aIndex].MemberCode && x.CallCenterId == this.state.selectedCallCenter.CallCenterId && !x.DeletedDate).map((companyResponseCode, Rindex) => 
                                  <option key={Rindex} value={companyResponseCode.ResponseName}>{companyResponseCode.ResponseName}</option>)}
                                </select>}
                                {action.ProcessAction == "updateStatus" && <select className="form-control notificationRowControl"
                                  value={action.ActionFieldValue}
                                  onChange={(e)=>{
                                    let rules = this.state.processingRules;
                                    rules[index].ActionsObject[aIndex].ActionFieldValue = e.target.value;
                                    this.setState({processingRules : rules});
                                  }}>
                                  <option value='open' >Open</option>
                                  <option value='closed' >Closed</option>
                                  <option value='project' >Project</option>
                                  <option value='canceled' >Canceled</option>
                                </select>}
                                {aIndex == 0 && <Button onClick={()=>{
                                    let rules = this.state.processingRules;
                                    rules[index].ActionsObject.push({ProcessAction : '', ActionFieldValue : "", MemberCode : ""});
                                    this.setState({processingRules : rules});
                                    }} className = "buttonAction buttonAccount small">
                                      <AddBoxSharpIcon className="buttonIcon small"/>
                                      <label style ={{ margin: 0, cursor: 'pointer', fontSize:12}} className ="buttonText">Add Action</label>
                                  </Button>}    
                                  {rule.ActionsObject.length > 1 && <Button onClick={()=>{
                                    let rules = this.state.processingRules;
                                    rules[index].ActionsObject.splice(aIndex,1);
                                    this.setState({processingRules : rules});
                                  }} className="buttonAction buttonAccount notificationRowControl notificationRowButton">
                                    <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                  </Button>}
                                </div>))}
                            </div>))}
                        <div className="horizontalLine"></div>
                        <div className="row" style={{marginTop: 10}}>
                          <div className="justify-content-left">
                          {  !this.state.loadingProcessingRules ? <div>
                            <Button onClick={() =>{
                              let rules = this.state.processingRules;
                              rules.push({
                                ProcessingRuleId : -1,
                                CallCenterId : this.state.selectedCallCenter.CallCenterId,
                                CompanyId : auth.companyId(),
                                CreatedDate : new Date(),
                                CreatedBy : auth.employeeId(),
                                CreatedDateUtcOffset : - (new Date().getTimezoneOffset()/60) ,
                                TriggersObject : [{FormField : '', TriggerType : "contains", TriggerFieldValue : "", AndOr : "OR"}],
                                ActionsObject : [{ProcessAction : '', ActionFieldValue : "", MemberCode : ""}]
                              });
                              this.setState({processingRules : rules});
                            }} className="buttonAction buttonAccount">
                                    <AddBoxSharpIcon className="buttonIcon"/>
                                    <label style={{ margin: 0, cursor : 'pointer'}} className="buttonText">ADD RULE</label>
                                  </Button>
                                  <Button onClick={() =>{this.updateProcessingRules();}} className="buttonAction buttonAccount">
                                    <SaveSharpIcon className="buttonIcon"/>
                                    <label style={{ margin: 0, cursor: 'pointer'}} className="buttonText">Save</label>
                                  </Button>
                                </div>
                                : <div>
                                    <div className="row d-flex align-items-center justify-content-center">
                                      <div className="d-flex align-items-center justify-content-center authSpinnerDiv">
                                        <label className="buttonText withIcon">UPDATING PROCESSING RULES...</label>
                                        <div className="barLoaderPortal">
                                          <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true } />
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                }
                          </div>
                        </div>
                        </div>

                        <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10, marginTop : 10}}>
                          User Assignment to Ticket Area
                          <div className="labelHeaderBarSubHeader">Users will only see data in areas they have been assigned</div>
                        </div>

                        {!this.state.loadingForms && <div className="licenseEditDivContent">
                          <div className='row'>
                            {this.state.users && <div className='col-5'>
                              <label className="labelHeaderBar" style={{width:'98%'}}>Users</label>
                              <select className="form-control customFormRowControl" value={this.state.userSelected != undefined ? this.state.userSelected.UserId : null} 
                                onChange={e=>{this.selectUserToSeeAssignmentArea(e)}} style={{maxWidth:285, height:42}}>
                                <option value={-1}></option>
                                { this.state.users.map((user,index) => (!user.DeletedDate &&
                                  <option key={index} value={user.UserId}>{user.DisplayNameProper}</option>
                                  ))}
                              </select>
                            </div>}
                            <div className="col-5">
                              <label className="labelHeaderBar">Assignment Area</label>
                              <div className="groupAssignmentUserList" style={{width : "100%", padding:10}}>
                                {this.state.assignmentAreas && this.state.assignmentAreas.map((area, index) => (
                                  !area.DeletedDate && <div key={index}>
                                    <input style={{marginRight: 10}} type="checkbox" id={'areaselector' + area.AssignmentAreaId} 
                                      
                                      checked={this.state.userSelected != undefined && this.state.userAssignmentAreas.findIndex((x => x.UserId == this.state.userSelected.UserId && 
                                        (area.AssignmentAreaId ? x.AssignmentAreaId == area.AssignmentAreaId : (x.CompanyArcGISLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId) || 
                                        (x.CompanyArcGisLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId))) ) != -1 } 

                                      onChange={e=>{this.selectAssignmentAreaForUser(area,e)}}
                                    />
                                    <label htmlFor={'areaselector' + area.AssignmentAreaId}>{area.FeatureName ? area.FeatureName : area.Label}</label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="horizontalLine"></div>
                          <div className="text-left" style={{marginTop: 10 }}>
                            <Button onClick={() =>{this.saveDataUserAssignmentArea()}} style={{ width:100 }} className ="buttonAction buttonEditUser">
                              <SaveSharpIcon className="buttonIcon"/>
                              <label className ="buttonText">SAVE</label>
                            </Button>
                          </div>
                        </div>}

                        </React.Fragment>
                      </Tab.Pane>
                    }
                    {
                      <Tab.Pane className="d-flex flex-column w-100 p-0 m-0 justify-content-center" eventKey="ticket-billing" id="ticket-billing-tab" mountOnEnter unmountOnExit>
                        <React.Fragment>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div>
                            <div className="labelHeaderBar labelHeaderBarTight" style={{marginTop:40, padding:10}}>Reports
                            <div className="labelHeaderBarSubHeader">Generate Billing Report for the Specified Date Range</div>
                            </div>
                            <div className="licenseEditDivContent">
                              <form className="row md-form">  
                              <Button className="buttonAction btn btn-primary dehighlighted" onClick={this.toggleFromDatePicker.bind(this)}  >
                              <DateRange className="buttonIcon light" style={{marginRight:4, marginBottom:2}}/>
                              <label className="buttonText withIcon small" id="FromDate" style={{ margin: 0, cursor: "pointer" }}>
                                {this.state.billingReportDateFrom}
                              </label>
                            </Button>
                            <label className="labelSubHeader4 labelSearchBar" style={{height:40}} >-</label>
                              {this.state.billingReportFromPickerOpen && ( <DatePicker value={this.state.billingReportDateFrom} onChange={this.fromHandleChange.bind(this)} onClose={ () => {this.setState({billingReportFromPickerOpen : false})}} open={this.state.billingReportFromPickerOpen} />)}
                              <Button className="buttonAction btn btn-primary dehighlighted" onClick={this.toggleToDatePicker.bind(this)}  >
                                <DateRange className="buttonIcon light" style={{marginRight:4, marginBottom:2}}/>
                                <label className="buttonText withIcon small" id="ToDate" style={{ margin: 0, cursor: "pointer" }}>
                                  {this.state.billingReportDateTo}
                                </label>
                              </Button>
              
                              { this.state.billingReportToPickerOpen && ( 
                                <DatePicker value={this.state.billingReportDateTo} onChange={this.toHandleChange.bind(this)} onClose={ () => {this.setState({billingReportToPickerOpen : false})}} open={this.state.billingReportToPickerOpen} /> 
                              )}
                              </form>
                              <br/>
                              <div style={{height: 40}}>
                                  {this.state.loadingBillingReport ?
                                    <Button className="buttonAction buttonAccount formsElementsmall">
                                      <RingLoader color={"white"} loading={true} size={20} />
                                    </Button>
                                  :
                                    <Button onClick={()=>{this.generateBillingReport()}} className="buttonAction buttonAccount">
                                      <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon small">Generate billing report</label>
                                    </Button>
                                  }
                                </div>
                            </div>
                          </div>
                        </MuiPickersUtilsProvider>
                        <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10, marginTop : 10}}>
                          Billing Groups
                          <div className="labelHeaderBarSubHeader">Configure Billing Groups</div>
                        </div>
                        <div className="licenseEditDivContent">
                          {(this.state.companyTicketBillingGroups.length > 0) && this.state.companyTicketBillingGroups.map((group, index) => 
                            {
                              if(group.DeletedDate == null) return(
                                <div key ={index} style={{display : 'flex', flexDirection : 'column'}}>
                                  <div className="row notificationRowDiv">
                                    <label style={{marginRight: -10}} htmlFor={"billingGroupName" + index} className="accountHeader notificationLabel">NAME: </label>
                                    <input id={"billingGroupName" + index} 
                                      className="notificationRowControl form-control" onChange={e =>{
                                        let companyTicketBillingGroups = this.state.companyTicketBillingGroups;
                                        companyTicketBillingGroups[index].Name = e.target.value;
                                        this.setState({companyTicketBillingGroups});
                                      }}
                                      value = {group.Name}
                                    />

                                    <label style={{marginLeft: 10, marginRight: -10}} htmlFor={"billingGroupType" + index} className="accountHeader notificationLabel">TYPE: </label>
                                    <select id={"billingGroupType" + index}  className="notificationRowControl form-control" 
                                      value={group.TicketBillingTypeId}
                                      onChange={e => {
                                        let companyTicketBillingGroups = this.state.companyTicketBillingGroups;
                                        companyTicketBillingGroups[index].TicketBillingTypeId = parseInt(e.target.value);
                                        if(e.target.value == 4){
                                          companyTicketBillingGroups[index].DistancePerUnit = companyTicketBillingGroups[index].DistancePerUnit ? companyTicketBillingGroups[index].DistancePerUnit : 50;
                                          companyTicketBillingGroups[index].DistancePerUnitDisplay = companyTicketBillingGroups[index].DistancePerUnit ? companyTicketBillingGroups[index].DistancePerUnit.toString() : "50";
                                        }
                                        this.setState({companyTicketBillingGroups});
                                      }} 
                                      style={{maxWidth:120}}>
                                      <option value={1}>Hourly</option>
                                      <option value={2}>Per Visit</option>
                                      <option value={3}>Per Ticket</option>
                                      <option value={4}>Footage</option>
                                    </select>

                                    <label style={{marginLeft: 10, marginRight: -10}} htmlFor={"billingGroupPrice" + index} className="accountHeader notificationLabel">PRICE:</label>
                                    <NumericFormat  style={{maxWidth:100}} id={"billingGroupPrice" + index} 
                                    allowNegative={false}
                                      decimalScale={2}
                                      //fixedDecimalScale={true}
                                      prefix={'$'}
                                      className="notificationRowControl form-control" 
                                      onValueChange={(values, sourceInfo) => {
                                        console.log(values);
                                        console.log(sourceInfo);
                                        let companyTicketBillingGroups = this.state.companyTicketBillingGroups;
                                        companyTicketBillingGroups[index].PricePerUnit = ((typeof values.floatValue == 'undefined') ? 0 : values.floatValue);
                                        this.setState({companyTicketBillingGroups});
                                      }}
                                      onBlur = {(e) => {
                                        let companyTicketBillingGroups = this.state.companyTicketBillingGroups;
                                        companyTicketBillingGroups[index].PricePerUnitDisplay = "$" + companyTicketBillingGroups[index].PricePerUnit.toFixed(2);
                                        let displayValue = "$" + companyTicketBillingGroups[index].PricePerUnit.toFixed(2);
                                        let target = e.target;
                                        this.setState({companyTicketBillingGroups});
                                        setTimeout(() => {
                                          target.value = displayValue;
                                        }, 200); 
                                      }}
                                      value = {group.PricePerUnitDisplay}
                                    />

                                    {group.TicketBillingTypeId == 4 &&
                                      <label style={{marginLeft: 10, marginRight: -10}} htmlFor={"billingGroupPrice" + index} className="accountHeader notificationLabel">FEET:</label>
                                    }
                                    {group.TicketBillingTypeId == 4 &&
                                      <NumericFormat  style={{maxWidth:100}} id={"billingGroupPrice" + index} 
                                        allowNegative={false}
                                        decimalScale={0}
                                        //fixedDecimalScale={true}
                                        className="notificationRowControl form-control" 
                                        onValueChange={(values, sourceInfo) => {
                                          let companyTicketBillingGroups = this.state.companyTicketBillingGroups;
                                          companyTicketBillingGroups[index].DistancePerUnit = ((typeof values.floatValue == 'undefined') ? 0 : values.floatValue);
                                          this.setState({companyTicketBillingGroups});
                                        }}
                                        onBlur = {(e) => {
                                          let companyTicketBillingGroups = this.state.companyTicketBillingGroups;
                                          companyTicketBillingGroups[index].DistancePerUnitDisplay = companyTicketBillingGroups[index].DistancePerUnit;
                                          let displayValue = companyTicketBillingGroups[index].DistancePerUnit;
                                          let target = e.target;
                                          this.setState({companyTicketBillingGroups});
                                          setTimeout(() => {
                                            target.value = displayValue;
                                          }, 200); 
                                        }}
                                        value = {group.DistancePerUnitDisplay}
                                      />
                                    }

                                    {(group.TicketBillingTypeId != 4 && this.state.companyTicketBillingGroups.findIndex(x => x.TicketBillingTypeId == 4) > -1) &&
                                      <div style={{width: 160}} />
                                    }

                                    <Button 
                                      onClick={() =>{
                                        this.removeCompanyTicketBillingGroup(index);
                                      }} 
                                      className="buttonAction buttonAccount notificationRowControl notificationRowButton"
                                    >
                                      <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                                    </Button>
                                  </div>
                                </div>
                              )
                            }
                          )}
                          <div className="horizontalLine"></div>
                          {this.state.loadingCompanyTicketBillingGroups ? 
                            <div className="row customFormRowDiv">
                              <div className="row">
                                <div className="d-flex authSpinnerDiv">
                                  <label className="accountHeader notificationLabel">UPDATING BILLING GROUPS...</label>
                                  <div className="barLoaderPortal">
                                    <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true } />
                                  </div>
                                </div>
                              </div>
                            </div>
                          :
                            <div className="row customFormRowDiv">
                              <Button onClick={this.addCompanyTicketBillingGroup.bind(this)} className="buttonAction buttonAccount">
                                <AddBoxSharpIcon className="buttonIcon"/>
                                <label style={{ margin: 0, cursor : 'pointer'}} className="buttonText">ADD GROUP</label>
                              </Button>
                              {(this.state.currentCompanyTicketBillingGroups != companyTicketBillingGroupsStringified) &&
                                <Button onClick={this.updateCompanyTicketBillingGroups.bind(this)} className="buttonAction buttonAccount confirm">
                                  <SaveSharpIcon className="buttonIcon"/>
                                  <lable className="buttonText">SAVE</lable>
                                </Button>
                              }
                              {(this.state.currentCompanyTicketBillingGroups != companyTicketBillingGroupsStringified) &&
                                <Button style={{paddingTop: 8}} onClick={this.revertCompanyTicketBillingGroups.bind(this)} className="ButtonAction buttonAccount">
                                  <UndoTwoTone className="buttonIcon small"/>
                                  <label className="buttonText">Discard Changes</label>
                                </Button>
                              }
                            </div>
                          }
                        </div>

                        <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10, marginTop : 10}}>
                          Billing Group Assignment
                          <div className="labelHeaderBarSubHeader">Assign Billing Groups to Member Codes.</div>
                        </div>
                        <div className="licenseEditDivContent">
                          <div className="row">
                            <div className="col-5">
                              <label className="labelHeaderBar" style={{width:'98%'}}>Billing Group</label>
                              <select className="form-control customFormRowControl" value={this.state.selectedBillingGroupIdForAssignment} onChange={e => {this.selectBillingGroupForAssignment(e)}} style={{maxWidth:285,height : 42}}>
                                <option value={-1}>Select Billing Group</option> 
                                {JSON.parse(this.state.currentCompanyTicketBillingGroups).map((group, index) => 
                                  {
                                    if(group.DeletedDate == null && group.CompanyTicketBillingGroupId != -1)
                                    return(<option key={index} value={group.CompanyTicketBillingGroupId}>{group.Name}</option>);
                                  }
                                )}  
                              </select>
                              {this.state.currentCompanyTicketBillingGroups != companyTicketBillingGroupsStringified &&
                                <span style={{width:'98%', color: 'red', fontSize: 14, fontWeight: 'bold'}}>You have unsaved changes to billing groups. Billing group changes will not reflect in this list until they are saved.</span>
                              }
                            </div>
                            <div className="col-5">
                              <label className="labelHeaderBar">Member Codes</label>
                              {this.state.selectedBillingGroupIdForAssignment != -1 &&
                                <div className="groupAssignmentUserList" style={{width : "100%"}}>
                                  <div style={{minHeight : 10}}/>
                                  {this.state.distinctMemberCodesUnsorted.filter(x=> !this.state.companyTicketBillingGroupMemberCodeAssignments.find(y => y.MemberCode == x && y.CompanyTicketBillingGroupId != this.state.selectedBillingGroupIdForAssignment && !y.DeletedDate)).length > 0 ?
                                    <div style={{marginLeft : 10}}>
                                      <input type="checkbox" id={"responseCodeSelectorAll"} checked={this.state.distinctMemberCodesUnsorted.filter(x=> !this.state.companyTicketBillingGroupMemberCodeAssignments.find(y => y.MemberCode == x && y.CompanyTicketBillingGroupId != this.state.selectedBillingGroupIdForAssignment && !y.DeletedDate)).length == this.state.companyTicketBillingGroupMemberCodeAssignments.filter(x => x.CompanyTicketBillingGroupId == this.state.selectedBillingGroupIdForAssignment && !x.DeletedDate).length} onChange={this.toggleAllBillingGroupMemberCodeOptions.bind(this)}/>
                                      <label htmlFor={"responseCodeSelectorAll"} style={{paddingLeft: 5, fontSize:14}}>All</label>
                                    </div>
                                    :
                                    <div style={{marginLeft : 10}}>
                                      <label htmlFor={"responseCodeSelectorAll"} style={{paddingLeft: 5, fontSize:14}}>No unassigned member codes found</label>
                                    </div>
                                  }
                                  {this.state.distinctResponseCodes && this.state.distinctMemberCodesUnsorted.filter(x=> !this.state.companyTicketBillingGroupMemberCodeAssignments.find(y => y.MemberCode == x && y.CompanyTicketBillingGroupId != this.state.selectedBillingGroupIdForAssignment && !y.DeletedDate)).map((memberCode,index) => (
                                    <div key={index} style={{marginLeft : 10}}>
                                      <input type="checkbox" id={"responseCodeSelector" + index} checked={this.state.companyTicketBillingGroupMemberCodeAssignments.filter(x => x.CompanyTicketBillingGroupId == this.state.selectedBillingGroupIdForAssignment && !x.DeletedDate).map(x => x.MemberCode).includes(memberCode)} onChange={()=>{this.toggleBillingGroupMemberCodeAssignment(memberCode)}}/>
                                      <label htmlFor={"responseCodeSelector" + index} style={{paddingLeft: 5, fontSize:14}}>{memberCode}</label>
                                    </div>
                                  ))}
                                </div>
                              }
                            </div>
                          </div>
                          <div className="horizontalLine"></div>
                            <div className="row" style={{marginTop: 10}}>
                              <div className="justify-content-left">
                                {!this.state.loadingCompanyTicketBillingGroupMemberCodeAssignments ? 
                                <div>
                                  {(this.state.currentCompanyTicketBillingGroupMemberCodeAssignments != companyTicketBillingGroupMemberCodeAssignmentsStringified) &&
                                    <Button onClick={() =>{this.updateCompanyTicketBillingGroupMemberCodeAssignments();}} className="buttonAction buttonAccount confirm">
                                      <SaveSharpIcon className="buttonIcon"/>
                                      <label style={{ margin: 0, cursor: 'pointer'}} className="buttonText">Save</label>
                                    </Button>
                                  }
                                  {(this.state.currentCompanyTicketBillingGroupMemberCodeAssignments != companyTicketBillingGroupMemberCodeAssignmentsStringified) &&
                                    <Button style={{paddingTop: 6}} onClick={() =>{this.revertCompanyTicketBillingGroupMemberCodeAssignments();}} className="buttonAction buttonAccount">
                                      <UndoTwoTone className="buttonIcon small"/>
                                      <label className="buttonText">Discard Changes</label>
                                    </Button>
                                  }
                                </div>
                                : 
                                  <div className="row customFormRowDiv">
                                    <div className="row">
                                      <div className="d-flex authSpinnerDiv">
                                        <label className="accountHeader notificationLabel">UPDATING BILLING GROUP ASSIGNMENTS...</label>
                                        <div className="barLoaderPortal">
                                          <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true } />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>
                        </div>

                        </React.Fragment>
                      </Tab.Pane>
                    }
                    { 
                      <Tab.Pane className="d-flex flex-column w-100 p-0 m-0 justify-content-center" eventKey="Misc" id="Misc-account-tab" mountOnEnter unmountOnExit>
                        {/* Misc */}
                        <React.Fragment>
                          {/* DIRT CONFIGURATION */}
                          {((auth.isAdmin() || auth.isSuperAdmin()) && this.state.displayInternalLinks) && 
                          <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10}}>DIRT CONFIGURATION
                          <div className="labelHeaderBarSubHeader">Damage Information Reporting Tool - is a secure online database that allows damage prevention stakeholders to anonymously submit information about damages and near-misses, which in turn helps create an industry-wide picture of opportunities to improve safety. Visit <a href='https://commongroundalliance.com/'>commongroundalliance.com</a> for more information.</div>
                          </div>}
                          {((auth.isAdmin() || auth.isSuperAdmin()) && this.state.displayInternalLinks) && <div className="licenseEditDivContent">
                            <div className="row">
                              <div className="row" style={{ display:'flex', flexDirection:'column', width: '100%' }}>
                                <div><label htmlFor="dirtKey" className ="accountHeader">Key:</label>
                                  <input  id="dirtKey" 
                                    style={{ marginLeft: 5, maxWidth:"500px", maxHeight: 40, marginRight: 10 }}
                                    value={this.state.dirtKey}
                                    onChange={e => { this.setState({ dirtKey : e.target.value}); }}
                                    className = "form-control"/>
                                </div>
                                <div style={{marginTop:10}}><label htmlFor="dirtSecret" className ="accountHeader">Secret:</label>
                                  <input  id="dirtSecret" 
                                    style={{ marginLeft: 5, maxWidth:"500px", maxHeight: 40, marginRight: 10 }}
                                    value={this.state.dirtSecret}
                                    onChange={e => { this.setState({ dirtSecret : e.target.value }); }}
                                    className = "form-control"/>
                                </div>
                                <div className="horizontalLine"></div>

                                {!this.state.loadingCredentials && <div className="justify-content-left">
                                  <Button onClick={()=>{this.updateDirtCredentials();}} className="buttonAction buttonAccount" style={{marginRight:20}}>
                                    <AssignmentTurnedInTwoToneIcon className="buttonIcon"/>
                                    <label className="buttonText withIcon">Validate</label>
                                  </Button>

                                </div>}
                                {this.state.loadingCredentials && 
                                  <div className="justify-content-left" style={{width:500}}>
                                  <div className=" d-flex align-items-center justify-content-center authSpinnerDiv">
                                    VALIDATING...
                                    <div className="barLoaderPortal"><BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true } /> </div>
                                  </div></div>
                                  }
                              </div>
                            </div>
                          </div>}

                          {(auth.isAdmin() || auth.isSuperAdmin()) && <div className="labelHeaderBar labelHeaderBarTight" style={{marginTop:40, padding:10}}>Operational Hours
                          <div className="labelHeaderBarSubHeader">Used for notifications, download subscriptions, and after hours workflows</div>
                          </div>}
                          { (auth.isAdmin() || auth.isSuperAdmin()) && <div className="licenseEditDivContent">
                          <div style={{display : "flex", flexDirection : "column"}}>
                            <div className="row notificationRowDiv">
                              <label htmlFor="" className="accountHeader notificationLabel">TimeZone:</label>
                              <select id="" className="notificationRowControl form-control" value={this.state.companyTimezone} onChange={(e)=>{this.setState({companyTimezone : e.target.value})}}>
                                <option></option>
                                <option value="EST">Eastern</option>
                                <option value="CST">Central</option>
                                <option value="MST">Mountain</option>
                                <option value="PST">Pacific</option>
                                <option value="HST">Hawaii</option>
                                <option value="AKST">Alaska</option>
                              </select>
                            </div>
                            <div className="row notificationRowDiv">
                            <label htmlFor="" className="accountHeader notificationLabel">Hours:</label>
                              <input id="" type="time" style={{maxWidth : 115}}className="notificationRowControl form-control" value={this.state.companyHourStart} onChange={(e)=>{this.setState({companyHourStart : e.target.value})}}/>
                              <div style={{marginLeft : 10, marginRight:10}}>to</div>
                              <input id="" type="time" style={{maxWidth : 115}}className="notificationRowControl form-control" value={this.state.companyHourEnd} onChange={(e)=>{this.setState({companyHourEnd : e.target.value})}}/>
                            </div>
                            <div className="row notificationRowDiv">
                            <label htmlFor="" className="accountHeader notificationLabel">Days:</label>
                              <div style={{marginLeft : 10, marginRight:5}}>Su</div> <input type="checkbox" checked={(this.state.companyDays && this.state.companyDays.includes("Su"))} onChange={(e)=>{
                                var companyDays = this.state.companyDays;
                                if(!companyDays)companyDays = [];
                                if(companyDays.includes("Su"))companyDays.splice(companyDays.indexOf("Su"),1);
                                else companyDays.push("Su");
                                this.setState({companyDays})}}className = "form-control customFormRowControl xsmall"/>
                              <div style={{marginLeft : 10, marginRight:5}}>Mo</div> <input type="checkbox" checked={this.state.companyDays && this.state.companyDays.includes("Mo")} onChange={(e)=>{
                                var companyDays = this.state.companyDays;
                                if(!companyDays)companyDays = [];
                                if(companyDays.includes("Mo"))companyDays.splice(companyDays.indexOf("Mo"),1);
                                else companyDays.push("Mo");
                                this.setState({companyDays})}}className = "form-control customFormRowControl xsmall"/>
                              <div style={{marginLeft : 10, marginRight:5}}>Tu</div> <input type="checkbox" checked={this.state.companyDays && this.state.companyDays.includes("Tu")} onChange={(e)=>{
                                var companyDays = this.state.companyDays;
                                if(!companyDays)companyDays = [];
                                if(companyDays.includes("Tu"))companyDays.splice(companyDays.indexOf("Tu"),1);
                                else companyDays.push("Tu");
                                this.setState({companyDays})}}className = "form-control customFormRowControl xsmall"/>
                              <div style={{marginLeft : 10, marginRight:5}}>We</div> <input type="checkbox" checked={this.state.companyDays && this.state.companyDays.includes("We")} onChange={(e)=>{
                                var companyDays = this.state.companyDays;
                                if(!companyDays)companyDays = [];
                                if(companyDays.includes("We"))companyDays.splice(companyDays.indexOf("We"),1);
                                else companyDays.push("We");
                                this.setState({companyDays})}}className = "form-control customFormRowControl xsmall"/>
                              <div style={{marginLeft : 10, marginRight:5}}>Th</div> <input type="checkbox" checked={this.state.companyDays && this.state.companyDays.includes("Th")} onChange={(e)=>{
                                var companyDays = this.state.companyDays;
                                if(!companyDays)companyDays = [];
                                if(companyDays.includes("Th"))companyDays.splice(companyDays.indexOf("Th"),1);
                                else companyDays.push("Th");
                                this.setState({companyDays})}}className = "form-control customFormRowControl xsmall"/>
                              <div style={{marginLeft : 10, marginRight:5}}>Fr</div> <input type="checkbox" checked={this.state.companyDays && this.state.companyDays.includes("Fr")} onChange={(e)=>{
                                var companyDays = this.state.companyDays;
                                if(!companyDays)companyDays = [];
                                if(companyDays.includes("Fr"))companyDays.splice(companyDays.indexOf("Fr"),1);
                                else companyDays.push("Fr");
                                this.setState({companyDays})}}className = "form-control customFormRowControl xsmall"/>
                              <div style={{marginLeft : 10, marginRight:5}}>Sa</div> <input type="checkbox" checked={this.state.companyDays && this.state.companyDays.includes("Sa")} onChange={(e)=>{
                                var companyDays = this.state.companyDays;
                                if(!companyDays)companyDays = [];
                                if(companyDays.includes("Sa"))companyDays.splice(companyDays.indexOf("Sa"),1);
                                else companyDays.push("Sa");
                                this.setState({companyDays})}}className = "form-control customFormRowControl xsmall"/>
                            </div>
                          </div>
                          {!this.state.loadingHours && <div className="justify-content-left">
                            <Button onClick={()=>{this.updateOperationalHours();}} className="buttonAction buttonAccount" style={{marginRight:20}}>
                              <SaveSharpIcon className="buttonIcon"/>
                              <label className="buttonText withIcon">SAVE</label>
                            </Button>
                                

                          </div>}
                          {this.state.loadingHours && 
                            <div className="justify-content-left" style={{width:500}}>
                            <div className=" d-flex align-items-center justify-content-center authSpinnerDiv">
                              SAVING HOURS...
                            <div className="barLoaderPortal"><BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true } /> </div>
                            </div></div>
                          }
                          </div>}
                          <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10, marginTop : 10}}>
                          On Call Area Assignment
                          <div className="labelHeaderBarSubHeader">Assign users to areas for after hours ticket assignments.</div>
                        </div>
                        <div className="licenseEditDivContent">
                          <div className='row'>
                            {this.state.users && <div className='col-5'>
                              <label className="labelHeaderBar" style={{width:'98%'}}>Users</label>
                              <select className="form-control customFormRowControl" value={this.state.userSelectedForAfterHours.UserId} onChange={e=>{this.selectUserForAfterHours(e)}} style={{maxWidth:285, height:42}}>
                                <option value={-1}></option>
                                { this.state.users.map((user,index) => ((!user.DeletedDate && (this.isUsersAvailableToOnCall(user.UserId))) &&
                                  <option key={index} value={user.UserId}>{user.DisplayNameProper}</option>
                                  ))}
                              </select>
                            </div>}
                            <div className="col-5">
                              <label className="labelHeaderBar">Ticket Area</label>
                              <div className="groupAssignmentUserList">
                                <div style={{minHeight:10}}/>
                                {this.state.assignmentAreas && this.state.assignmentAreas.map((feature, index) => (
                                  (!feature.DeletedDate && feature.FeatureId && this.isTicketAreaAvailableToOnCall(feature)) && <div key={index} style={{marginLeft : 10}}>
                                    <input type="checkbox" id={'featureselector' + feature.CompanyArcGISLayerId + feature.FeatureId} checked={this.state.afterHoursAssignmentAreas.find(x => x.DeletedDate == null && x.UserId == this.state.userSelectedForAfterHours.UserId && x.CompanyArcGISLayerId == feature.CompanyArcGISLayerId && x.FeatureId == feature.FeatureId) != null} onChange={()=>{this.selectTicketAreaFeatureForUser(feature)}}/>
                                    <label htmlFor={'featureselector' + feature.CompanyArcGISLayerId + feature.FeatureId}>{feature.FeatureName}</label>
                                  </div>
                                ))}
                                {this.state.assignmentAreas && this.state.assignmentAreas.map((feature, index) => (
                                  (!feature.DeletedDate && feature.AssignmentAreaId && this.isTicketAreaAvailableToOnCall(feature)) && <div key={index} style={{marginLeft : 10}}>
                                    <input type="checkbox" id={'featureselector' + feature.AssignmentAreaId} checked={this.state.afterHoursAssignmentAreas.find(x => x.DeletedDate == null && x.UserId == this.state.userSelectedForAfterHours.UserId && x.AssignmentAreaId == feature.AssignmentAreaId) != null} onChange={()=>{this.selectTicketAreaFeatureForUser(feature)}}/>
                                    <label htmlFor={'featureselector' + feature.AssignmentAreaId}>{feature.Label}</label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="row notificationRowDiv">
                              <label htmlFor="" className="accountHeader notificationLabel">After Hours Alert Email:</label>
                              <input id="" type="text" style={{maxWidth:"300px !important"}} className="notificationRowControl form-control" value={this.state.companyAfterHoursEmail} onChange={(e)=>{this.setState({companyAfterHoursEmail : e.target.value})}}/>
                            </div>
                          {!this.state.loadingAfterHoursAssignment && <div className="justify-content-left">
                            <Button onClick={()=>{this.updateAfterHoursAssignments();}} className="buttonAction buttonAccount" style={{marginRight:20}}>
                              <SaveSharpIcon className="buttonIcon"/>
                              <label className="buttonText withIcon">SAVE</label>
                            </Button>
                                

                          </div>}
                          {this.state.loadingAfterHoursAssignment && 
                            <div className="justify-content-left" style={{width:500}}>
                            <div className=" d-flex align-items-center justify-content-center authSpinnerDiv">
                              SAVING AFTER HOURS ASSIGNMENTS...
                            <div className="barLoaderPortal"><BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={ true } /> </div>
                            </div></div>
                          }
                        </div>
                          {(auth.isAdmin() || auth.isSuperAdmin()) && ((this.state.licenseSite && this.state.licenseSite > 0) || (this.state.licenseInvestigation && this.state.licenseInvestigation > 0)) && !this.state.companyConfigFetchFailed  ?
                            <div>
                              <div className="labelHeaderBar labelHeaderBarTight" style={{marginTop:40, padding:10}}>User Location Tracking
                              <div className="labelHeaderBarSubHeader">Enable background location tracking for mobile app users</div>
                              </div>
                              <div className="licenseEditDivContent">
                                <form className="md-form">  
                                  <div style={{height: 40}}>
                                    {this.state.loadingCompanyConfig ?
                                      <Button className="buttonAction buttonAccount formsElementsmall">
                                        <RingLoader color={"white"} loading={true} size={20} />
                                      </Button>
                                    : this.state.companyConfiguration && this.state.companyConfiguration.AppLocationTrackingEnabled ?
                                      <Button onClick={()=>{this.setMobileLocationTracking(false)}} className="buttonAction buttonAccount formsElementsmall">
                                        <RadioButtonCheckedIcon className="buttonIcon small"/>
                                        <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon small">Enabled</label>
                                      </Button>
                                    : 
                                      <Button onClick={()=>{this.setMobileLocationTracking(true)}} className="buttonAction buttonAccount buttonFaded formsElementsmall">
                                        <RadioButtonUncheckedIcon className="buttonIcon small"/>
                                        <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon small">Disabled</label>
                                      </Button>
                                    }
                                  </div>
                                </form>
                              </div>
                            </div> : null
                          }

                          {/* COMPANY LOGO */}
                          {(auth.isAdmin() || auth.isSuperAdmin()) && <div className="labelHeaderBar labelHeaderBarTight" style={{marginTop:40, padding:10}}>Company Logo
                          <div className="labelHeaderBarSubHeader">Add a company logo to apply as branding in application menus and reports </div>
                          </div>}
                          {(auth.isAdmin() || auth.isSuperAdmin()) && <div className="licenseEditDivContent">
                            <form className="md-form justify-content-center inputFormCenter">  
                              <div className="file-field divBorderBase">
                                <div className="z-depth-1-half mb-4">
                                  <img src={this.state.logoImage} className="img-fluid" id="logo"/>
                                </div>
                                <div className="d-flex justify-content-center">
                                  <div className="">
                                  <Button className = "buttonAction buttonAccount" style={{position : 'relative', left : 70, width : 200}}> 
                                    <AddBoxSharpIcon className="buttonIcon"/>
                                    <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText">Choose Photo</label>
                                  </Button>
                                    <input type="file" className="form-control-file" id="uploadPicture attachDocumentButton" style={{position : 'relative', bottom : 35, left : 70, opacity : 0}} onChange={this.readyNewPicture}/>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>}
                        </React.Fragment>
                      </Tab.Pane>
                    }
                  </Tab.Content>
                </div>
                
                  <Dialog open={this.state.deleteWarning ? true : false} onClose={()=>{}}>
                    <DialogTitle>{"Are you sure you want to delete this form?"}</DialogTitle>
                    <DialogActions>
                      <Button className="buttonAction btn btn-primary" onClick={()=>{this.setState({deleteWarning:false})}} color="primary">
                        <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Cancel</label>
                      </Button>
                      <Button className="buttonAction btn btn-primary" onClick={() =>{this.deleteCustomForm();}} color="primary" autoFocus>
                        <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Delete</label>
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog open={this.state.deleteExportMapWarning ? true : false} onClose={()=>{}}>
                    <DialogTitle>{"Are you sure you want to delete the export configuration for this form?"}</DialogTitle>
                    <DialogActions>
                      <Button className="buttonAction btn btn-primary" onClick={()=>{this.setState({deleteExportMapWarning:false})}} color="primary">
                        <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Cancel</label>
                      </Button>
                      <Button className="buttonAction btn btn-primary" onClick={() =>{this.deleteArcGisExportMapping();}} color="primary" autoFocus>
                        <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Delete</label>
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog open={this.state.deleteTriggerWarning ? true : false} onClose={()=>{}}>
                    <DialogTitle>{"Are you sure you want to delete this trigger?"}</DialogTitle>
                    <DialogActions>
                      <Button className="buttonAction btn btn-primary" onClick={()=>{this.setState({deleteTriggerWarning:false})}} color="primary">
                        <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Cancel</label>
                      </Button>
                      <Button className="buttonAction btn btn-primary" onClick={() =>{this.deleteTrigger();}} color="primary" autoFocus>
                        <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Delete</label>
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
          ) : (
            <div className="container-fluid">
              <div className="spinnerDivUserPage">
                <div className="text-center authSpinnerDiv">
                  <div className="authSpinnerDivMessage">{this.state.statusText}</div>
                  <div className="barLoaderPortal">
                    <BarLoader sizeUnit={"px"} size={150} color={"#095399"} loading={ this.state.statusText == "" ? false : 
                    this.state.statusText == "There was an error retrieving your information." || this.state.statusText == "Not authorized to view this page" ? false : true } />
                  </div>
                </div>
              </div>
            </div>
          )}
          </React.Fragment>
        </Tab.Container>
      </div>
    );
  }
}

AccountPage.propTypes = {
  match : PropTypes.object,
  forceRefresh : PropTypes.func
}

export default AccountPage;