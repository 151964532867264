import React from "react";
import auth from "../common/Auth";
import SearchStrip from "./SearchStrip";
import PropTypes from "prop-types";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import SearchIcon from '@material-ui/icons/Search';
import { BarLoader } from "react-spinners";
import { WebMapViewLeakSurvey } from "./WebMapViewLeakSurvey";
import DataGroupHelpers from '../../Utility/DataGroupHelpers';
import { get, set } from 'idb-keyval';
import Functions from '../../Functions/Functions';
import CreateTaskButton from './CreateTaskButton';
import OrgUtility from '../../Utility/Utility';

class MapPageLeakSurvey extends React.Component {
  constructor(props) {
    super(props);
    //let results = localStorage.getItem('resultSet_LeakSurvey');
    this.state = {
      resultSet: [],
      apiUrl: process.env.API_URL,
      zoomToExtent: true,
      updateMapExtent: true,
      status: "SEARCH",
      statusText: "",
      resultsCount: -1,
      surveyCount:0,
      incidentCount:0,
      leakCount:0,
      gpsCounty:0,
      includePhotos: false,
      includeOpen: true,
      includeClosed: true,
      assignedCount: 0,
      openCount: 0,
      userAssignedList: [],
      closedCount: 0,
      refresh: false,
      updateStatusText: "",
      filterUserValue : -1,
      filterStatusValue : -1,
      filterVisitValue : -1,
      filterAssignedValue : -1,
      searchValue : "",
      refreshFacetLayer : true,
      dateRangeDays : 1,
      dataGroups : [], 
      entityCollections:[],
      unfilteredResultSet : [],
      multimediaAssets : [],
      showAssignedList : false,
      creatingNewTask : false,
      useModifiedDate : false
    };

    this.map = React.createRef();
    this.strip = React.createRef();
    this.employeeIdValue = "";
    this.searchRequest = this.searchRequest.bind(this);
    this.statusTextUpdate = this.statusTextUpdate.bind(this);
    this.filterUpdate = this.filterUpdate.bind(this);
    this.downloadRequest = this.downloadRequest.bind(this);
    this.disableType = this.disableType.bind(this);
    this.updateIncludePhotos = this.updateIncludePhotos.bind(this);
    this.updateIncludeOpen = this.updateIncludeOpen.bind(this);
    this.updateIncludeClosed = this.updateIncludeClosed.bind(this);
    this.filterFromUserList = this.filterFromUserList.bind(this);
    this.updateAssignedCount = this.updateAssignedCount.bind(this);
    this.updateAssignmentAreas = this.updateAssignmentAreas.bind(this);
    this.displayToaster = this.displayToaster.bind(this);
    this.updateSearchValue = this.updateSearchValue.bind(this);
    this.doFiltering = this.doFiltering.bind(this);
    this.toggleDataGroup = this.toggleDataGroup.bind(this);
    this.toggleProject = this.toggleProject.bind(this);
    this.expandGroup = this.expandGroup.bind(this);
    this.getMultimediaAssets = this.getMultimediaAssets.bind(this);
    this.setCreatingNewTask = this.setCreatingNewTask.bind(this);
    this.setAlert = this.setAlert.bind(this);
    this.getCompanyLayers = this.getCompanyLayers.bind(this);
    this.updateFromArchive = this.updateFromArchive.bind(this);
  }

  getCompanyLayers(){
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      GIS : true,
      Web : true
    }
    fetch(process.env.API_URL + "/api/account/getCompanyKMLShapeLayers",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(async r =>{
      if(r.status ==200){
        r.json().then(async responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.CompanyKMLSHAPELayers && json.CompanyKMLSHAPELayers.length > 0){
              await json.CompanyKMLSHAPELayers.forEach(async (layer,index) =>{
                if(layer.FileType == "shape"){
                  let response = await fetch(process.env.API_URL+"/api/GetKMLShapeLayerFile/" + layer.CompanyKMLSHAPELayerId + "/" + layer.SecureCode,{method : "GET"});
                  let layerJson = await response.json();
                  layer.shapeJSON = layerJson;
                }
                if(index == json.CompanyKMLSHAPELayers.length - 1){
                  this.setState({CompanyKMLSHAPELayers : json.CompanyKMLSHAPELayers});
                }
              });
            }
            else{
              this.setState({CompanyKMLSHAPELayers : []});
            }
          }
          else{
            this.setState({CompanyKMLSHAPELayers : []});
          }
        });
      }
      else{
        this.setState({CompanyKMLSHAPELayers : []});
      }
    })
    .catch(error =>{
      console.log(error);
      this.setState({CompanyKMLSHAPELayers : []});
    });
    fetch(process.env.API_URL + "/api/account/getCompanyArcGisLayers",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status ==200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.CompanyArcGisLayers){
              this.setState({CompanyArcGisLayers : json.CompanyArcGisLayers, ARCGISClientSecret : json.ARCGISClientSecret,  ARCGISClientId : json.ARCGISClientId, EnterpriseConnection : json.EnterpriseConnection});
            }
            else{
              this.setState({CompanyArcGisLayers : []});
            }
          }
          else{
            this.setState({CompanyArcGisLayers : []});
          }
        });
      }
      else{
        this.setState({CompanyArcGisLayers : []});
      }
    })
    .catch(error =>{
      console.log(error);
      this.setState({CompanyArcGisLayers : []});
    });
  }

  componentDidMount() {
    this.getCompanyLayers();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let fromDetails = params.get('fromDetails');
    let detailsType = params.get("detailsType");
    get('resultSet_LeakSurvey').then((results)=>{
      if(results){
        let entityCollections = localStorage.getItem("entityCollections");
        let multimediaAssets = localStorage.getItem("multimediaAssetsLeak");
        let formProjects = [{ProjectLabel : "No Project", showData : true}];
        JSON.parse(results).forEach(result =>{
          if(result.FormData){
            let selectedProject = result.FormData.Fields && result.FormData.Fields.length > 0 && result.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down")  ? result.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down").Value : null;
            if(selectedProject && selectedProject != "" && !formProjects.find(x => x.ProjectLabel == selectedProject)){
              formProjects.push({ProjectLabel : selectedProject, showData : true});
            }
          }
        });
        this.setState({resultSet: JSON.parse(results), unfilteredResultSet: JSON.parse(results), entityCollections : ((entityCollections && entityCollections != '') ? JSON.parse(entityCollections) : []),formProjects,multimediaAssets : ((multimediaAssets && multimediaAssets != '') ? JSON.parse(multimediaAssets) : [])});
      }
      if(fromDetails == "true"){
        let mapSurvey = detailsType == "form" ? localStorage.getItem("mapForm") : localStorage.getItem("mapLeakSurvey");
        if(mapSurvey && mapSurvey != "'"){
          let surv = JSON.parse(mapSurvey);
          let resultSet = this.state.resultSet;
          let pushSurvey = false;
          if(!resultSet || resultSet.length == 0){
            pushSurvey = true;
            resultSet = [];
          }
          else{
            if(detailsType == "leakSurvey" && !resultSet.find(x => x.LeakSurveyId == surv.LeakSurveyId)){
              pushSurvey = true;
            }
            if(detailsType == "form" && !resultSet.find(x => x.FormData && x.FormData.FormDataId == surv.FormData.FormDataId)){
              surv.EntityTypeId = surv.FormData.FormId;
              pushSurvey = true;
            }
          }
          if(pushSurvey){
            resultSet.push(surv);
            this.setState({resultSet, unfilteredResultSet : resultSet});
          }
          this.setState({ detailsSurveyLat : surv.Latitude, detailsSurveyLon : surv.Longitude});
        }
      }
      //removed default search
      if ((!this.state.resultSet || this.state.resultSet.length == 0) && !this.checkSuper()){
        //this.searchRequest(this.props);
      }
      else{
        setTimeout(()=>{this.setState({refresh : true})},1000);
        this.updateAssignedCount(true);
      }

      // default map extent
      const mapZoom = sessionStorage.getItem("leakSurveyMapZoom");
      const mapExtent = sessionStorage.getItem("leakSurveyMapExtent");
      var filterUserValue = sessionStorage.getItem("searchStripFilterUserValue");
      // default date settings
      let today = new Date();
      today.setDate(today.getDate());
      this.setState({filterUserValue : filterUserValue == null ? '-1' : filterUserValue,toDate: today, fromDate: today, zoomToExtent: mapZoom == null || mapExtent == null || mapZoom == "null" || mapExtent == "null" || mapZoom == "undefined" || mapExtent == "undefined"});
    });
    DataGroupHelpers.getDataGroups(auth.employeeId(), auth.companyId(), auth.token()).then(dataGroups =>{
      this.setState({dataGroups});
    });
  }

  componentDidUpdate(){
    // STYLE CSS by ORG
    new OrgUtility().updateOrgCSS();
  }


  checkSuper(){
    let isSuper = false;
    let _SuperAdminRoleId = 0;
    let _SuperViewerRoleId = 11;
    var roles = localStorage.getItem("roles");
    if(roles != null) { roles.split(',').forEach(element => { if (element == _SuperAdminRoleId || element == _SuperViewerRoleId) { isSuper = true;}});}
    return isSuper;
  }

  addNewEntityToResults(formData){
    let newEntity = {
      EntityTypeId : formData.FormId,
      FormData : formData,
      Latitude : formData.Latitude,
      Longitude : formData.Longitude,
      ModifiedByDisplayName : formData.ModifiedByDisplayName,
      ModifiedDate : formData.ModifiedDate,
      Type : "Leak Survey" 
    }
    let results = this.state.resultSet;
    results.push(newEntity);
    this.setState({resultSet : results}, ()=>{this.updateAssignedCount(false)});
    return newEntity;
  }

  setCreatingNewTask(creatingNewTask){
    this.setState({creatingNewTask});
  }

  setAlert(alertSeverity, updateStatusText){
    this.setState({alertSeverity, updateStatusText});
  }

  handleClose(){

  }
  
  displayToaster(msg){
    this.setState({updateStatusText:msg, refresh:false});
  }

  expandGroup(group){
    let dataGroups = DataGroupHelpers.expandGroup(group.expanded,group.DataGroupId,this.state.dataGroups);
    this.setState({dataGroups});
  }

  toggleDataGroup(id,checked){
    let dataGroups = DataGroupHelpers.toggleDataGroup(id, checked, this.state.dataGroups);
    this.setState({dataGroups}, () =>{this.doFiltering()});
  } 

  toggleProject(name, checked){
    let formProjects = this.state.formProjects;
    formProjects.find(x => x.ProjectLabel == name).showData = checked;
    this.setState({formProjects},() =>{this.doFiltering()});
  }

  doFiltering(){
    let resultSet = [];
    let dataGroups = this.state.dataGroups;
    let excludeDataGroupsList = dataGroups.filter(x => !x.showData);
    let excludeProjectList = this.state.formProjects.filter(x => !x.showData);
    let entityCollections = this.state.entityCollections;
    let unfilteredResultSet = this.state.unfilteredResultSet;
    unfilteredResultSet.forEach(result =>{
      if(result.Type == 'Leak Survey'){
        let push = true;
        let type = result.LeakSurveyTypeId;
        let id = result.Id;
        if(result.FormData){
          type = 93;
          id = result.FormData.FormDataId;
          if(excludeProjectList && excludeProjectList.length > 0 ){
            let selectedProject = result.FormData.Fields && result.FormData.Fields.length > 0 && result.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down")  ? result.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down").Value : null;
            if(excludeProjectList.find(x => x.ProjectLabel == "No Project") && (!selectedProject || selectedProject == "")){
              push = false;
            }
            if(selectedProject && selectedProject != "" && excludeProjectList.find(x => x.ProjectLabel == selectedProject)){
              push = false;
            }
          }
        }
        if(excludeDataGroupsList && excludeDataGroupsList.length > 0){
          let collectionRecords = entityCollections.filter(x => x.EntityId == id && x.EntityTypeId == type);
          if(collectionRecords && collectionRecords.length > 0){
            collectionRecords.forEach(collection =>{
              if(excludeDataGroupsList.findIndex(x => x.DataGroupId == collection.DataGroupId) > -1){
                push = false;
              }
            });
          }
        }
        if(push){
          resultSet.push(result);
        }
      }
      else{
        resultSet.push(result);
      }
    });
    this.setState({resultSet, refresh : true, updateMapExtent : false});
  }

  getMultimediaAssets(EntityList){
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      EntityList
    }
    fetch(process.env.API_URL + "/api/entityCollectionMultimedia",{
      method: "POST",
      headers: {
        Accept : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(bodyJson =>{
          let response = JSON.parse(bodyJson);
          response.MultimediaAssets = response.MultimediaAssets.filter(x => x.MediaTypeId != 1);
          response.MultimediaAssets.forEach(asset =>{
            asset.AttachedEntityTypeId = asset.EntityTypeId;
            if(asset.EntityTypeId == 93){
              let entity = this.state.resultSet.find(x => x.FormData != null && x.FormData.FormDataId == asset.EntityId);
              if(entity){
                asset.FormData = entity.FormData;
                asset.EntityTypeId = entity.FormData.FormId;
              }
            }
          });
          this.setState({multimediaAssets : response.MultimediaAssets, refresh : true});
          localStorage.setItem("multimediaAssetsLeak", JSON.stringify(response.MultimediaAssets));
        })
      }
    })
  }

  updateFromArchive(entity, isForm){
    let results = this.state.resultSet;
    let delIndex = -1;
    results.forEach((element, index) =>{
      if(isForm && element.FormData && element.FormData.FormDataId == entity.FormData.FormDataId){
        delIndex = index;
      }
      else if(!isForm && element.LeakSurveyId == entity.LeakSurveyId){
        delIndex = index;
      }
    });
    if(delIndex != -1){
      results.splice(delIndex, 1);
    }
    this.setState({resultSet : results});
    try { set('resultSet_LeakSurvey', JSON.stringify(results)); }
    catch(e){
      console.log('error caching resultSet_LeakSurvey object');
    }
  }

  updateAssignmentAreas(assignmentArea,leakSurveyAssignmentId){
    let resultSet = this.state.resultSet;
    let delIndex;
    if(assignmentArea){
      assignmentArea.Type = "LeakSurveyAssignment";
      resultSet.forEach((result,index) =>{
        if(result.LeakSurveyAssignmentId && result.AssignedToUserId == assignmentArea.AssignedToUserId){
          delIndex = index;
        }
      });
      if(delIndex != null){
        resultSet.splice(delIndex,1);
      }
      resultSet.push(assignmentArea);
    }
    else{
      resultSet.splice(resultSet.findIndex(x => x.LeakSurveyAssignmentId == leakSurveyAssignmentId), 1);
    }
    this.setState({resultSet, refresh : true, zoomToExtent : true,updateMapExtent : true,updatedAssignementLayer : true});
  }

  updateAssignedCount(updateMapExtent){
    var surveySet = [];
    if (this.state.resultSet){
      let resultSet = this.state.resultSet
      
      if (resultSet != undefined && resultSet != null && resultSet.length > 0){   

        var count = 0;
        let inaccessibleCount = 0;
        let aocCount = 0;
        let pipelineCount = 0;
        let leakCount = 0;
        let meterCount = 0;
        let openCount = 0;
        let closedCount = 0;
        let assignedCount = 0;
        let insideMeterCount = 0;
        let userAssignedList = [];

        if (resultSet.length > 0){
          
          resultSet.forEach(element =>{
            if(element.Type == "Leak Survey User"){
              element.assignedCount = 0;
              element.assignedCountClosed = 0;
              userAssignedList.push(element);
            }
          });

          resultSet.forEach(element => {
            if (element.Type == 'Leak Survey'){
              count++;
              let filterUserValue = this.state.filterUserValue;
              let filterStatusValue = this.state.filterStatusValue;
              let filterVisitValue = this.state.filterVisitValue;
              let filterAssignedValue = this.state.filterAssignedValue;
              if(((filterUserValue == -1 || element.AssignedUserId == filterUserValue))
              &&
              ((filterAssignedValue == "Unassigned" && (element.AssignedUserId == null || element.AssignedUserId == "-1")) || 
              (filterAssignedValue == "Assigned" && element.AssignedUserId != null && element.AssignedUserId != "-1") || 
              (filterAssignedValue == -1)) &&
              (filterStatusValue == -1 || (element.Status == null && element.Status == "Open") || (element.Status == filterStatusValue))
              && 
              (filterVisitValue == -1 || 
                (filterVisitValue == 'Not Visited' && (element.TaskCounter == null || element.TaskCounter == 0)) || 
                (filterVisitValue == 'Visited 1 time' && element.TaskCounter == 1) || 
                (filterVisitValue == 'Visited 2 times' && element.TaskCounter == 2) || 
                (filterVisitValue == "Visited 3+ times" && element.TaskCounter >= 3))
              ){
                switch(element.LeakSurveyTypeId){
                  case 14:
                    inaccessibleCount++;
                    break;
                  case 15:
                    aocCount++;
                    break;
                  case 16:
                    leakCount++;
                    break;
                  case 22:
                    meterCount++;
                    break;
                  case 23:
                    pipelineCount++;
                    break;
                  case 24:
                    insideMeterCount++;
                    break;
                  default:
                    leakCount++;
                    break;
                }
              }

              switch(element.Status){
                //null status needs to also count as open
                case null:
                case"Open":
                  if(element.AssignedUserId && element.AssignedUserId != null){
                    openCount++;
                    var user = userAssignedList.find(x => x.UserId == element.AssignedUserId);
                    if (user){
                      user.assignedCount++;
                      assignedCount++;
                    }
                  }
                  break;
                case "Closed":
                  if(element.AssignedUserId  && element.AssignedUserId != null){
                    closedCount++;
                    var userClosed = userAssignedList.find(x => x.UserId == element.AssignedUserId);
                    if(userClosed){
                      userClosed.assignedCountClosed++;
                      assignedCount++;
                    }
                  }
                  break;
                default:
                  break;
              }
            } 
            surveySet.push(element);
          });
        }

        if (surveySet.length > 0) {
          this.setState({resultSet: surveySet, insideMeterCount, userAssignedList, openCount, assignedCount, closedCount, 
          resultsCount: count, inaccessibleCount, aocCount,pipelineCount, leakCount, meterCount, updateMapExtent, refresh:true});
        }
      }
    }
  }

  filterUpdate(value, type, updateMapExtent, zoomToExtent){
    let me = this;
    if (type == 'user') me.setState({filterUserValue: value, zoomToExtent: zoomToExtent, updateMapExtent: updateMapExtent, refresh:true},() =>{this.updateAssignedCount(true);});
    if (type == 'status') me.setState({filterStatusValue: value, zoomToExtent: zoomToExtent, updateMapExtent: updateMapExtent, refresh:true},() =>{this.updateAssignedCount(true);});
    if (type == 'visit') me.setState({filterVisitValue : value, zoomToExtent : zoomToExtent, updateMapExtent : updateMapExtent, refresh : true},() =>{this.updateAssignedCount(true);});
    if (type == 'assigned') me.setState({filterAssignedValue : value, zoomToExtent : zoomToExtent, updateMapExtent : updateMapExtent, refresh : true},() =>{this.updateAssignedCount(true);});
  }

  updateIncludePhotos(includePhotos){
    this.setState({includePhotos});
  }
  
  updateIncludeClosed(includeClosed){
    this.setState({includeClosed});
  }

  updateIncludeOpen(includeOpen){
    this.setState({includeOpen});
  }

  disableType(type,visibility){
    this.map.current.setLayerVisibility(type,visibility);
  }

  toHandleChange(date){
    this.setState({toDate: date, zoomToExtent: false, updateMapExtent: false});
  }

  fromHandleChange(date){
    this.setState({fromDate: date, zoomToExtent: false, updateMapExtent: false});
  }

  statusTextUpdate(text){
    let me = this;
    me.setState({ statusText: text, zoomToExtent: false, updateMapExtent: false });
  }

  downloadRequest(props){
    let DataGroups;
    let Projects;
    if(this.state.dataGroups && this.state.dataGroups.length > 0){
      DataGroups = [];
      this.state.dataGroups.forEach(group =>{
        if(group.showData){
          DataGroups.push(group.DataGroupId);
        }
      });
    }
    if(this.state.formProjects && this.state.formProjects.length > 0 && this.state.formProjects.filter(x => !x.showData).length > 0){ //Api won't filter if projects is null, no need to filter if none are hidden. 
      Projects = [];
      this.state.formProjects.forEach(project =>{
        if(project.showData){
          Projects.push(project.ProjectLabel);
        }
      })
    }
    let me = this;
    me.setState({ statusText: "Collecting Data...", zoomToExtent: false, updateMapExtent: false });
    let set = [];
    
    if (this.state.resultSet){
      this.state.resultSet.forEach((result) =>{
        if (result && result.LeakSurveyId){
          set.push(result);
        }
      })
    }

    let body = {
      UserId: auth.employeeId(),
      CompanyId: auth.companyId(),
      SearchParam: props.searchCriteriaValue,
      DateTo: props.dateTo,
      DateFrom: props.dateFrom,
      ReturnSurveyData: false,
      ReturnIncidentData: false,
      ReturnGPSSurveyData: false,
      ReturnLeakSurveyData: true,
      ReturnSiteSafeData: false,
      ReturnPictures: this.state.includePhotos,
      ReturnClosedLeakSurveys: this.state.includeClosed,
      ReturnOpenLeakSurveys: this.state.includeOpen,
      Offset : -new Date().getTimezoneOffset() /60,
      DataGroups,
      Projects,
      UseModifiedDate : this.state.useModifiedDate
    };

    fetch(process.env.API_URL + "/api/data/batchDownload/", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(r => {
      if (r.status == 200) { me.setState({ statusText: "" , updateStatusText : "Your download request has been received. An email will be sent to you shortly.", alertSeverity : "success"}); }
      else if (r.status == 400){
        this.setState({ statusText : "", updateStatusText : "Your download request exceeds the data limit.  Narrow the scope by date or keyword and try again.", alertSeverity : "error"});
      }
      else { console.log('/api/data/batchDownload/' + r.status) }
    }).catch(error => {
      console.log(error.message);
      me.setState({ statusText: "" });
    });
  }

  updateSearchValue(e){
    this.setState({searchValue : e.target.value, refreshFacetLayer : true});
  }

  searchRequest(props) {

    console.log('searchRequest', props);

    let me = this;
    me.setState({statusText: "Refreshing Data...", resultSet:null, zoomToExtent:false, updateMapExtent:false, refresh:true });

    let today = new Date(); today.setDate(today.getDate());
    let d = new Date(); d.setDate(d.getDate() - 31);
    let body = {
      UserId: auth.employeeId(),
      CompanyId: auth.companyId(),
      SearchParam: props.searchCriteriaValue == undefined ? "": props.searchCriteriaValue,
      DateFrom: props.dateFrom == undefined ? d.toLocaleDateString(): props.dateFrom,
      DateTo: props.dateTo == undefined ? today.toLocaleDateString(): props.dateTo,
      LeakSurveyMapSearch: true,
      WebSearch: true,
      Offset: -new Date().getTimezoneOffset() /60,
      DashboardSearch: true,
      UseModifiedDate : this.state.useModifiedDate
    };

    fetch(process.env.API_URL + "/api/search/", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(r => {
      if (r.status == 200) {
        r.json().then(bodyJson => {
          if (bodyJson.length > 0) {

            let response = JSON.parse(bodyJson);
            let leakSurveyCollection = [];
            let totalCount = response.LeakSurveyCollection.length;
            let aocCount = 0;
            let inaccessibleCount = 0;
            let leakCount = 0;
            let pipelineCount = 0;
            let meterCount = 0;
            let openCount = 0;
            let closedCount = 0;
            let assignedCount = 0;
            let insideMeterCount = 0;
            let userAssignedList = [];
            let entityList = [];

            if(response.EntityCollections){
              this.setState({entityCollections : response.EntityCollections});
              localStorage.setItem("entityCollections", JSON.stringify(response.EntityCollections))
            }

            let formProjects = [{ProjectLabel : "No Project", showData : true}];

            // clear user list
            var filterUser = document.getElementById("filterUser");
            if (filterUser != null) {
              var i, L = filterUser.options.length - 1;
              for(i = L; i >= 1; i--) { filterUser.remove(i); }
            }

            // assignment areas
            if (response.LeakSurveyAssignmentCollection) {
              response.LeakSurveyAssignmentCollection.forEach((element) => {
                if (element.ErasedDate == null){
                  element.Type = "LeakSurveyAssignment";
                  element.address = element.Address,
                  element.city = element.City,
                  element.state = element.State,
                  element.date = element.CreatedDate,
                  leakSurveyCollection.push(element);
                }
              });
            }

            // users
            if (response.UserAssignmentCollection){
              Functions.sortOn(response.UserAssignmentCollection, 'LastName');
              response.UserAssignmentCollection.forEach((element) => {
                element.Type = "Leak Survey User";
                element.assignedCount = 0;
                element.assignedCountClosed = 0;
                leakSurveyCollection.push(element);
                userAssignedList.push(element);
              });
            }

            // surveys
            if (response.LeakSurveyCollection){
              response.LeakSurveyCollection.forEach((element) => {
                element.Type = "Leak Survey";
                element.address = element.Address;
                element.city = element.City;
                element.state = element.State;
                element.date = element.CreatedDate;
                if(element.LeakSurveyTypeId == 22 && element.MeterInside){
                  element.LeakSurveyTypeId = 24;
                  element.EntityTypeId = 24;
                }
                if(element.FormData){
                  element.EntityTypeId = element.FormData.FormId;
                  let selectedProject = element.FormData.Fields && element.FormData.Fields.length > 0 && element.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down")  ? element.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down").Value : null;
                  if(selectedProject && selectedProject != "" && !formProjects.find(x => x.ProjectLabel == selectedProject)){
                    formProjects.push({ProjectLabel : selectedProject, showData : true});
                  }
                  entityList.push({EntityId : element.FormData.FormDataId, EntityTypeId : 93});
                }
                else{
                  entityList.push({EntityId : element.LeakSurveyId, EntityTypeId : element.LeakSurveyTypeId});
                }
                leakSurveyCollection.push(element);
                let filterUserValue = this.state.filterUserValue;
                let filterStatusValue = this.state.filterStatusValue;
                let filterVisitValue = this.state.filterVisitValue;
                let filterAssignedValue = this.state.filterAssignedValue;
                if(((filterUserValue == -1 || element.AssignedUserId == filterUserValue))
                &&
                ((filterAssignedValue == "Unassigned" && (element.AssignedUserId == null || element.AssignedUserId == "-1")) || 
                (filterAssignedValue == "Assigned" && element.AssignedUserId != null && element.AssignedUserId != "-1") || 
                (filterAssignedValue == -1)) &&
                (filterStatusValue == -1 || (element.Status == null && filterStatusValue == "Open") || (element.Status == filterStatusValue))
                && 
                (filterVisitValue == -1 || 
                  (filterVisitValue == 'Not Visited' && (element.TaskCounter == null || element.TaskCounter == 0)) || 
                  (filterVisitValue == 'Visited 1 time' && element.TaskCounter == 1) || 
                  (filterVisitValue == 'Visited 2 times' && element.TaskCounter == 2) || 
                  (filterVisitValue == "Visited 3+ times" && element.TaskCounter >= 3))
                ){
                  switch(element.LeakSurveyTypeId){
                    case 14:
                      inaccessibleCount++;
                      break;
                    case 15:
                      aocCount++;
                      break;
                    case 16:
                      leakCount++;
                      break;
                    case 22:
                      meterCount++;
                      break;
                    case 23:
                      pipelineCount++;
                      break;
                    case 24:
                      insideMeterCount++;
                      break;
                    default:
                      leakCount++;
                      break;
                  }
                }
                switch(element.Status){
                  case null:
                  case "Open":
                    if(element.AssignedUserId){
                      var user = userAssignedList.find(x => x.UserId == element.AssignedUserId);
                      if (user){
                        openCount++;
                        user.assignedCount++;
                        assignedCount++;
                      }
                    }
                    break;
                  case "Closed":
                    if(element.AssignedUserId){
                      var userClosed = userAssignedList.find(x => x.UserId == element.AssignedUserId);
                      if(userClosed){
                        closedCount++;
                        userClosed.assignedCountClosed++;
                        assignedCount++;
                      }
                      
                    }
                  break;
                  default:
                    break;
                }
              });
            }

            me.setState({statusText: "",  formProjects, resultSet: leakSurveyCollection, unfilteredResultSet : leakSurveyCollection, insideMeterCount, userAssignedList, resultsCount: totalCount, openCount, closedCount, 
            assignedCount, leakCount,  aocCount, pipelineCount, meterCount, inaccessibleCount, incidentCount: -1, zoomToExtent: true, updateMapExtent: true, refresh:false, detailsSurveyLat : null, detailsSurveyLon : null });
            this.doFiltering();
            //this.getMultimediaAssets(entityList);
            localStorage.setItem("searchStripToDateString", props.dateTo);
            localStorage.setItem("searchStripFromDateString", props.dateFrom);
            localStorage.setItem("searchStripSearchTerm", props.searchCriteriaValue == undefined ? "": props.searchCriteriaValue)
            localStorage.setItem("formProjects", JSON.stringify(formProjects));
            // cache data
            try { set('resultSet_LeakSurvey', JSON.stringify(leakSurveyCollection)); }
            catch(e){
              console.log('error caching resultSet_LeakSurvey object');
            }

            me.filterUpdate(me.state.filterUserValue, "user", true, true)
            me.filterUpdate(me.state.filterStatusValue, "status", true, true);
            me.filterUpdate(me.state.filterVisitValue, "visit", true, true);
            me.filterUpdate(me.state.filterAssignedValue, "assigned", true, true);
          }
        });
      }
    })
    .catch(error => {
      console.log(error.message);
      me.setState({ statusText: "" });
    });
  }

  setUpdateMapExtentProp(value){
    this.setState({updateMapExtent: value});
  }

  filterFromUserList(user){
    let event = {
      target: {
        value: user.UserId
      }
    }
    let otherEvent = {
      target: {
        value: "Assigned"
      }
    }
    this.strip.current.filterUserUpdate(event);
    this.strip.current.filterAssignedUpdate(otherEvent);
  }

  render() {
    return (
      <div className="col-10 d-flex justify-content-center p-0 m-0" style={{ backgroundColor: "#E9E9EF" }} >

        <Snackbar open={this.state.updateStatusText != ""} onClick={() => {this.setState({updateStatusText:''})}} autoHideDuration={6000} onClose={this.handleClose} 
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant="filled">
            {this.state.updateStatusText}
          </Alert>
        </Snackbar>

        <div className="container-fluid">
          <SearchStrip ref={this.strip} includeClosed={this.state.includeClosed} updateIncludeClosed={this.updateIncludeClosed} 
            includeOpen={this.state.includeOpen} updateIncludeOpen={this.updateIncludeOpen} includePhotos={this.state.includePhotos} 
            insideMeterCount={this.state.insideMeterCount} updateIncludePhotos={this.updateIncludePhotos} fromMap={true} searchRequest={this.searchRequest} downloadRequest={this.downloadRequest} 
            disableType={this.disableType} resultsCount={this.state.resultsCount} leakCount={this.state.leakCount} incidentCount={-1}  mapDownload={false} 
            leakSurveyDownload={true} headerLabel="Leak Survey" filterUpdate={this.filterUpdate} fromHandleChange = {this.fromHandleChange.bind(this)} defaultDateRangeDays={this.state.dateRangeDays}
            toHandleChange = {this.toHandleChange.bind(this)} aocCount={this.state.aocCount}
            dataGroups={this.state.dataGroups} toggleDataGroup={this.toggleDataGroup} expandGroup={this.expandGroup}
            pipelineCount={this.state.pipelineCount} inaccessibleCount={this.state.inaccessibleCount} 
            meterCount={this.state.meterCount} updateSearchValue={this.updateSearchValue}
            formProjects={this.state.formProjects} toggleProject={this.toggleProject} searching={this.state.statusText == "Refreshing Data..."}
            useModifiedDate={this.state.useModifiedDate} updateUseModifiedDate={(value) =>{this.setState({useModifiedDate : value})}}/> 

          {this.state.statusText == "" && <div className="assignTaskButtonBar">
            <CreateTaskButton createTask={()=>{document.getElementById("createTaskDiv").style.display = 'block';}}/>
            </div>}
          <div id="mapContainerDiv" className="mapContainerDiv">

            {<div className="row mapspin" id="loading" style={{display:(this.state.statusText != "" ? 'unset':'none')}}>
              <div className="spinnerDiv">
                <div className="text-center authSpinnerDiv">
                  {this.state.statusText != "" ? <div className="authSpinnerDivMessage">
                    {this.state.statusText}
                  </div>: ''}
                  <div className="barLoaderPortal">
                    <BarLoader sizeUnit={"px"} size={150} color={"#095399"} loading={ true }/>
                  </div> 
                </div>
              </div>
            </div>}

            <div  className="assignViewDropdown" >
              <div ref={node => this.node = node} onClick={()=>{this.setState({showAssignedList: !this.state.showAssignedList, refresh:false})}} className="assignViewDropdownHeader">
                <div className="leakCountBlock countBlock openBlock" >{this.state.openCount}</div>
                <span className="assignViewHeaderText">ASSIGNED</span>
                <div className="leakCountBlock closedCountBlock countBlock closedBlock">{this.state.closedCount}</div>
              </div>
              {this.state.showAssignedList && <div className="assignViewDropdownContent">
                <div className="assignViewOpenClosed">
                  <div className="openBlockText">open</div>
                  <div className="closeBlockText">closed</div>
                </div>
                {this.state.userAssignedList.map((user, index) =>
                <div key={index} style={{marginTop: 5, marginBotom:5}}>
                  <div className="assignViewDropDownItem" onClick={()=>{this.filterFromUserList(user)}}>
                    <div className="leakCountBlock countBlock openAssignedBlock">{user.assignedCount}</div>
                    <span className="assignViewUserName">{user.DisplayName}</span>
                    <div className="leakCountBlock closedCountBlock countBlock closedAssignedBlock">{user.assignedCountClosed}</div>
                  </div>
                </div>
                )}  
                <div style={{minHeight: 35}}/>
              </div>}
            </div>
            
            {this.state.CompanyArcGisLayers && this.state.CompanyKMLSHAPELayers &&  <WebMapViewLeakSurvey CompanyArcGisLayers ={this.state.CompanyArcGisLayers} EnterpriseConnection={this.state.EnterpriseConnection} CompanyKMLSHAPELayers={this.state.CompanyKMLSHAPELayers} ARCGISClientSecret ={this.state.ARCGISClientSecret} ARCGISClientId={this.state.ARCGISClientId} ref={this.map} resultSet={this.state.resultSet} zoomToExtent={this.state.zoomToExtent} 
              updateMapExtent={this.state.updateMapExtent} refresh={this.state.refresh} searchRequest={this.searchRequest}
              pipelineCount={this.state.pipelineCount} 
              aocCount={this.state.aocCount} 
              inaccessibleCount={this.state.inaccessibleCount} 
              meterCount={this.state.meterCount}
              insideMeterCount={this.state.insideMeterCount}
              leakCount={this.state.leakCount}
              addNewEntityToResults={this.addNewEntityToResults.bind(this)}
              setCreatingNewTask={this.setCreatingNewTask}
              creatingNewTask={this.state.creatingNewTask}
              setAlert={this.setAlert}
              statusTextUpdate={this.statusTextUpdate} setUpdateMapExtentProp={this.setUpdateMapExtentProp.bind(this)} 
              updateAssignedCount={this.updateAssignedCount} displayToaster={this.displayToaster}
              updateAssignmentAreas={this.updateAssignmentAreas}
              updatedAssignementLayer={this.state.updatedAssignementLayer}
              searchValue={this.state.searchValue}
              refreshFacetLayer={this.state.refreshFacetLayer}
              multimediaAssets={this.state.multimediaAssets}
              detailsSurveyLat={this.state.detailsSurveyLat} 
              detailsSurveyLon = {this.state.detailsSurveyLon}
              updateFromArchive ={ this.updateFromArchive} 
              fromDate = {this.state.fromDate}
              toDate = {this.state.toDate}
              filterUserValue={this.state.filterUserValue}
            />}
          </div>
        </div>
      </div>
    );
  }
}

MapPageLeakSurvey.propTypes = {
  location: PropTypes.object,
  forceRefresh: PropTypes.func
};

export default MapPageLeakSurvey;