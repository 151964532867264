import React from 'react';
import PropTypes from "prop-types";
import { Button } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import sizes from '../../Global/sizes';
import OrgUtility from '../../Utility/Utility'

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0, showPolicy: false }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    var orgUtility = new OrgUtility();
    this.setState({orgId: orgUtility.getOrgId(), displayFooter: orgUtility.displayFooter()});
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate() {
    if (this.props.displayFooter) this.updatePageContentHeight();
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    if (this.props.displayFooter) this.updatePageContentHeight();
  }

  updatePageContentHeight() {
    var pageContainer = document.getElementById("pageContainer");
    var footer = document.getElementById("footer");
    var footerHeight = 0;
    if (footer) footerHeight = document.getElementById("footer").clientHeight;
    pageContainer.style.minHeight = "calc(100vh - " + (footerHeight + sizes.homePageHeaderHeight) + "px)";
  }

  render() {
    return (this.props.displayFooter && this.state.displayFooter ?
      <footer id="footer" className="footer backgroundBase">
        <div className="row">
          <div className="col-md-4" style={{ display: 'flex', justifyContent: this.state.width > 767 ? 'flex-start' : 'center' }}>
            <div className="footerSection">
              <div className="footerMainLabel">
              {this.state.orgId == 2 ? "Vivax" : "LaunchPoint"}
              </div>
              <div className="footerMainLabel">
              {this.state.orgId == 2 ? "Metrotech" : "Software Systems"}
            </div>
              <div className="footerSubLabel">
                -
            </div>
            </div>
          </div>
          <div className="col-md-4" style={{ display: 'flex', justifyContent: 'center', paddingTop: this.state.width > 767 ? '0px' : '50px' }}>
            <div className="footerSection">
              <div className="footerMainLabel">
                Contact Us
              </div>
              <div className="footerSubLabel">
              {this.state.orgId == 2 ? <a className="footerLink" href="https://www.vivax-metrotech.com/contact-us-old/other-locations/">Contact Us</a> : <a className="footerLink" href="tel:8443037453">844.303.7453</a>}
              </div>
              <div className="footerSubLabel">
              {this.state.orgId == 2 ? <a className="footerLink" href="https://www.vivax-metrotech.com/support/">Support</a> : null}
              </div>
            </div>
          </div>
          <div className="col-md-4" style={{ display: 'flex', justifyContent: this.state.width > 767 ? 'flex-end' : 'center', paddingTop: this.state.width > 767 ? '0px' : '50px' }}>
            <div className="footerSection" style={{ marginLeft: '-20px' }}>
              <div className="footerMainLabel" style={{ display: 'flex', justifyContent: 'center' }}>
                Connect With Us
              </div>
              <div className='footerMainLabel' style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center', width: '140px', alignItems: 'center' }}>
                  <Button
                    variant="contained"
                    href={this.state.orgId == 2 ? "https://www.facebook.com/people/Vivax-Metrotech/100009316460831/" : "https://www.facebook.com/launchpointsoftwaresystems"}
                    target='_blank'
                    className="linkButtonHeader"
                    style={{
                      borderRadius: 0, width: '46px', height: '46px', display: 'flex', backgroundColor: 'transparent',
                    }}>
                    <img id="headerLogoImg" src={require("../../../src/media/socialMediaIcons/fbIcon34.png")} style={{ width: "28px", height: '28px' }} />
                  </Button>
                  <Button
                    variant="contained"
                    href={this.state.orgId == 2 ? "https://www.linkedin.com/company/vivax-metrotech/" : "https://www.linkedin.com/company/launchpointsoftwaresystems/"}
                    target='_blank'
                    className="linkButtonHeader"
                    style={{
                      borderRadius: 0, width: '46px', height: '46px', display: 'flex', backgroundColor: 'transparent',
                    }}>
                    <img id="headerLogoImg" src={require("../../../src/media/socialMediaIcons/inIcon34.png")} style={{ width: "28px", height: '28px' }} />
                  </Button>
                  <Button
                    variant="contained"
                    href={this.state.orgId == 2 ? "https://www.instagram.com/vivax_metrotech/" : "https://www.instagram.com/launchpointsoftwaresystems/"}
                    target='_blank'
                    className="linkButtonHeader"
                    style={{
                      borderRadius: 0, width: '46px', height: '46px', display: 'flex', backgroundColor: 'transparent',
                    }}>
                    <img id="headerLogoImg" src={require("../../../src/media/socialMediaIcons/igIcon34.png")} style={{ width: "28px", height: '28px' }} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <hr className="footerHr" />
        </div>
        <div className="row">
          <div className="col-md-4" />
          <div className="col-md-4" />
          <div className="col-md-4" style={{ display: 'flex', justifyContent: this.state.width > 767 ? 'flex-end' : 'center' }}>
            <div className="footerSection" style={{ display: 'flex', justifyContent: this.state.width > 767 ? 'flex-end' : 'center' }}>
              <div onClick={() => { this.setState({ showPolicy: true }) }} className="footerLabelTerms">
                Privacy Policy &amp; Terms of Use
              </div>
            </div>
          </div>
        </div>
        <Dialog open={this.state.showPolicy} onClose={() => { }}>
          <DialogTitle>{"Privacy Policy & Terms of Use"}</DialogTitle>
          <DialogContent>

            <Typography gutterBottom align="center" variant="h5" fontWeight="bold">LAUNCHPOINT&#39;S Privacy Policy</Typography>
            <Typography gutterBottom align="left" variant="h5">Privacy and Sensitive Information</Typography>

            <Typography gutterBottom align="left">This Privacy Notice describes how LAUNCHPOINT uses your personal information and respects your privacy rights.</Typography>
            <Typography gutterBottom align="left">LAUNCHPOINT limits its access, collection, use, and sharing of personal or sensitive data acquired through the app to purposes
                directly related to providing and improving the features of the app (e.g. location services providing verification of jobsite location). LAUNCHPOINT does not extend usage of this data for serving advertising.</Typography>
            <Typography gutterBottom align="left">LAUNCHPOINT accesses and collects user data from Location Services, Camera, Background Location, Fingerprints, and Face ID.</Typography>
            <Typography gutterBottom align="left">LAUNCHPOINT uses Finger Print and Face ID data for authentication and authorization for app access.  LAUNCHPOINT accesses camera services to provide on-site photo documentation. LAUNCHPOINT accesses location services and uses the collected data to provide verification of jobsite location.  Access to location services must be authorized by the user of the application but is not required.</Typography>
            <Typography gutterBottom align="left">LAUNCHPOINT accesses and collects user data from background location specifically in the GIS SURVEY module.  A prominent disclose & consent requirement is displayed prior to the user’s authorization of the service.</Typography>

            <Typography gutterBottom align="left">LAUNCHPOINT handles all personal or sensitive user data securely, including transmitting it using modern cryptography (for example, over HTTPS).</Typography>
            <Typography gutterBottom align="left">LAUNCHPOINT uses a runtime permissions request whenever available.</Typography>
            <Typography gutterBottom align="left">LAUNCHPOINT does not sell personal or sensitive user data.</Typography>
            <Typography gutterBottom align="left">LAUNCHPOINT collects information as part of its business operations, to provide services, to respond to requests and offer customer support, to fulfill legal and contractual obligations and to build and enhance its products and services.  Users provide some of this data directly, such as when a LaunchPoint service is ordered, there is interaction with LAUNCHPOINT, customer support is utilized, or when logging into web sites (including mobile websites and apps) owned and controlled by LAUNCHPOINT (“LAUNCHPOINT’s Sites”).</Typography>
            <Typography gutterBottom align="left">LAUNCHPOINT also collects information through the use of Cookies and similar technologies (hereafter “Cookies”) during interaction with LAUNCHPOINT’s Sites.</Typography>

            <Typography gutterBottom align="left" variant="h5">Information Provided to LaunchPoint Voluntarily</Typography>
            <Typography gutterBottom align="left">When an account is created, a purchase is made or information requested from LAUNCHPOINT, LAUNCHPOINT will ask for things like contact, billing, shipping and communication information, payment card or financial account data, and account ID or credentials in order to fulfill the request.</Typography>
            <Typography gutterBottom align="left">If personal information is submitted about other people to LAUNCHPOINT or to our service providers, the user is responsible for making sure they have the authority to do so and to allow us to use their personal information in accordance with this Privacy Notice (for example, by asking for their consent).</Typography>

            <Typography gutterBottom align="left" variant="h5">Information Obtained Through LaunchPoint&#39;s Sites & Services</Typography>
            <Typography gutterBottom align="left">LAUNCHPOINT collects information about how visitors interact with LAUNCHPOINT’s Sites through the use of Cookies.</Typography>

            <Typography gutterBottom align="left" variant="h5">How LaunchPoint Shares Information </Typography>
            <Typography gutterBottom align="left">LAUNCHPOINT does not share personal information so that it can fulfill orders, process payments, prepare and send advertising, or as otherwise necessary to complete any transaction or provide any product or service users have requested or authorized. When sharing takes place, LAUNCHPOINT uses controls to establish protections for the personal information that is shared. Sharing may take place with affiliates and subsidiaries; companies working on our behalf; law enforcement or government officials and for purposes consistent with this Notice including to protect LAUNCHPOINT’s customers; to protect lives; to maintain the security of LAUNCHPOINT’s products and services; and to protect LAUNCHPOINT’s rights and property.</Typography>
            <Typography gutterBottom align="left">Retention periods for this personal information can vary significantly based on the type of information and how it is used.  LAUNCHPOINT’s retention periods are based on criteria that include legally mandated retention periods, pending or potential litigation, LAUNCHPOINT’s intellectual property or ownership rights, contract requirements, operational directives or needs, and historical archiving.  When personal information is removed from LAUNCHPOINT’s systems it will be deleted or destroyed.</Typography>

            <Typography gutterBottom align="left" variant="h5">Third Party Vendors</Typography>
            <Typography gutterBottom align="left">LAUNCHPOINT utilizes the services and products of various third parties, including but not limited to Microsoft Corporation.  To the extent that such third-party vendors have access to users’ personal information, how they treat that information is governed by their own privacy policies which may be obtained through their respective websites.</Typography>

            <Typography gutterBottom align="left" variant="h5">Your Rights</Typography>
            <Typography gutterBottom align="left">LAUNCHPOINT respects users’ rights in how personal information is used and shared. Users may request access or corrections to personal data and make choices about the kinds of marketing materials received or choose not to receive.</Typography>

            <Typography gutterBottom align="left" variant="h5">Changes to This Policy</Typography>
            <Typography gutterBottom align="left">LAUNCHPOINT may change this Privacy Notice from time to time.  Please review this Privacy Notice periodically to stay informed about how LAUNCHPOINT collects, uses, and shares personal information.</Typography>

            {/* TERMS OF USE */}
            <Typography gutterBottom align="center" variant="h4" fontWeight="bold">LAUNCHPOINT&#39;S Terms of Use</Typography>
            <Typography gutterBottom align="left">Please read these Terms of Use (&quot;Terms&quot;) carefully before using this site. By accessing and using the LaunchPoint (&quot;LAUNCHPOINT&quot;) web sites, software, hardware and materials (&quot;LaunchPoint Software&quot;), you acknowledge and agree to abide by the following Terms of Use. If you do not agree to these Terms, do not use the LaunchPoint Software. </Typography>
            <Typography gutterBottom align="left" variant="h5">Scope of Terms</Typography>
            <Typography gutterBottom align="left">These Terms of Use are applicable to all LAUNCHPOINT Websites, whether a sponsored site, a site managed through a third party vendor or hosted by a third party (referred to hereafter collectively as &quot;Web Sites&quot;). These Terms also apply to any resources and tools provided through LAUNCHPOINT and/or its web sites, including but not limited to LaunchPoint Software, developer tools, download areas, mobile software applications, research areas, community forums, chat rooms, blogs, sharing sites and product information. These Terms apply to all the LaunchPoint Software, both now and in the future.  </Typography>
            <Typography gutterBottom align="left">LAUNCHPOINT may provide LaunchPoint Software specific Terms of Service, for example when LAUNCHPOINT provides interactive Materials and/or enables User Generated Content. If so, to the extent that these Terms do not conflict with the program specific Terms of Service, these Terms remain in full force and effect. </Typography>
            <Typography gutterBottom align="left" variant="h5">Security and Privacy </Typography>
            <Typography gutterBottom align="left">Personal Information: Information submitted to LAUNCHPOINT through forms on the Web Sites or LaunchPoint Software is governed according to LAUNCHPOINT&#39;s Privacy Policy. </Typography>
            <Typography gutterBottom align="left">Member Account, Password, and Security: LAUNCHPOINT may, from time to time, host a program or activity specific site that may require you to establish a member account and/or site password. In such cases, you are entirely responsible for maintaining the confidentiality of your password and account. Furthermore, you are entirely responsible for any and all activities that occur under your account. You agree to notify LAUNCHPOINT immediately of any unauthorized use of your account or any other breach of security regarding your account. LAUNCHPOINT is not liable for any loss that you may incur as a result of someone else using your password or account, either with or without your knowledge. However, you could be held liable for losses incurred by LAUNCHPOINT or another party due to someone else using your account or password. You may not use anyone else&#39;s account at any time, without the permission of the account holder. </Typography>
            <Typography gutterBottom align="left" variant="h5">Use Terms</Typography>
            <Typography gutterBottom align="left">No License: Unless otherwise specified in the LaunchPoint Software themselves, you may not modify, copy, distribute, publish, license, transmit, perform, display, reproduce, create derivative works from, transfer, or sell any LaunchPoint Software, information, products or services obtained from LAUNCHPOINT or its Web Sites.   </Typography>
            <Typography gutterBottom align="left">You may not use or facilitate the use of this site, or of LaunchPoint Software in connection with any infringement concerning LAUNCHPOINT products.  By your use of this site, and of LaunchPoint Software, you agree to grant LAUNCHPOINT a non-exclusive, royalty-free license to any intellectual property (patent, copyright or otherwise) claim thereafter drafted which includes subject matter disclosed on this site.  </Typography>
            <Typography gutterBottom align="left">No Unlawful or Prohibited Use: You agree that you will not use the Web Sites LaunchPoint Software for any purpose that is unlawful or prohibited by these Terms of Use or the terms of use or end user license agreements of any third party vendors who sponsor, manage, host, or license products or services in connection with the LaunchPoint Software, which includes but is not limited to the following: </Typography>
            <Typography gutterBottom align="left">You may not: </Typography>
            <Typography gutterBottom align="left">1. Upload, post, email, transmit or otherwise make available any content that is unlawful, harmful, threatening, vulgar, obscene, libelous, abusive, harassing, tortious, defamatory, invasive of another&#39;s privacy, hateful, or racially, ethnically or otherwise objectionable; </Typography>
            <Typography gutterBottom align="left">2. Use the LaunchPoint Software, Services or activities to &quot;stalk&quot; or otherwise harass or harm another; </Typography>
            <Typography gutterBottom align="left">3. Impersonate any person or entity, including, but not limited to, an LAUNCHPOINT official, forum leader, guide or host, or falsely state or otherwise misrepresent your affiliation with a person or entity or collect or store personal data about other users in connection with the prohibited conduct and activities; </Typography>
            <Typography gutterBottom align="left">4. Forge headers or otherwise manipulate identifiers in order to disguise the origin of any content transmitted through the Web Site, or LaunchPoint Software; </Typography>
            <Typography gutterBottom align="left">5. Upload, post, email, transmit or otherwise make available any content that you do not have a right to make available under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements); </Typography>
            <Typography gutterBottom align="left">6. Upload, post, email, transmit or otherwise make available any content that infringes any patent, trademark, trade secret, copyright or other proprietary rights (&quot;Rights&quot;) of any party; </Typography>
            <Typography gutterBottom align="left">7. Upload, post, email, transmit or otherwise make available any unsolicited or unauthorized advertising, promotional materials, &quot;junk mail&quot; &quot;spam,&quot; &quot;chain letters,&quot; &quot;pyramid schemes,&quot; or any other form of solicitation; </Typography>
            <Typography gutterBottom align="left">8. Upload, post, email, transmit or otherwise make available any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; </Typography>
            <Typography gutterBottom align="left">9. You may not use the Web Site, or LaunchPoint Software in any manner that could damage, disable, overburden, or impair any LAUNCHPOINT server, or network(s) connections, disobey any requirements, procedures, policies or regulations of networks connected to the Web Site, or LaunchPoint Software or interfere with any other party&#39;s use and enjoyment of the Web Sites, or LaunchPoint Software; </Typography>
            <Typography gutterBottom align="left">10. You may not attempt to gain unauthorized access to any Web Site, LaunchPoint Software, other accounts, computer systems or networks connected to any LAUNCHPOINT server, or LaunchPoint Software, through hacking, password mining or any other means or obtain or attempt to obtain any materials or information through any means not intentionally made available through the Web Sites or LaunchPoint Software; </Typography>
            <Typography gutterBottom align="left">11. Intentionally or unintentionally violate any applicable local, state, national or international law, including, but not limited to, regulations promulgated by the U.S. Securities and Exchange Commission, any rules of any national or other securities exchange, including, without limitation, the New York Stock Exchange, the American Stock Exchange or the NASDAQ, and any regulations having the force of law; and/or </Typography>
            <Typography gutterBottom align="left">12. Provide material support or resources (or to conceal or disguise the nature, location, source, or ownership of material support or resources) to any organization(s) designated by the United States government as a foreign terrorist organization pursuant to section 219 of the Immigration and Nationality Act. </Typography>
            <Typography gutterBottom align="left">US Government Restricted Rights: The Materials including LAUNCHPOINT Software are provided with &quot;RESTRICTED RIGHTS.&quot; Use, duplication, or disclosure by the Government is subject to restrictions as set forth in FAR52.227-14 and DFAR252.227-7013 et seq. or its successor. Use of the Materials by the Government constitutes acknowledgment of LAUNCHPOINT&#39;s proprietary rights in them. </Typography>
            <Typography gutterBottom align="left">Copyright Permission: The copyright in all content posted on LAUNCHPOINT website, LaunchPoint Software, and all connected sites is owned by LAUNCHPOINT Corporation. Use is authorized for editorial (news media) purposes only and subject to the LAUNCHPOINT Terms of Use. To request permission to use content outside of editorial purposes please submit your request via e-mail to support@golaunchpoint.com. Please include a description of your proposed usage of the materials, along with a link or copy of the materials being requested and your contact information. </Typography>
            <Typography gutterBottom align="left" variant="h5">Notices and Disclosures</Typography>
            <Typography gutterBottom align="left">Exclusions of Warranty: YOU EXPRESSLY UNDERSTAND AND AGREE THAT:</Typography>
            <Typography gutterBottom align="left">●YOUR USE OF THE WEB SITES, LAUNCHPOINT SOFTWARE IS AT YOUR SOLE RISK AND THAT ALL SERVICES ARE PROVIDED &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; WITHOUT ANY EXPRESS OR IMPLIED WARRANTY OF ANY KIND INCLUDING WARRANTIES OF MERCHANTABILITY, NONINFRINGEMENT OF INTELLECTUAL PROPERTY, OR FITNESS FOR ANY PARTICULAR PURPOSE.</Typography>
            <Typography gutterBottom align="left">●ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE WEBSITE OR LAUNCHPOINT SOFTWARE  IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.</Typography>
            <Typography gutterBottom align="left">●IN NO EVENT SHALL LAUNCHPOINT OR ITS SUPPLIERS BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF INFORMATION) ARISING OUT OF THE USE OF OR INABILITY TO USE THE WEB SITES, OR LAUNCHPOINT SOFTWARE, EVEN IF LAUNCHPOINT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</Typography>
            <Typography gutterBottom align="left">Limitations of Liability: YOU EXPRESSLY UNDERSTAND AND AGREE THAT LAUNCHPOINT AND ITS LICENSORS SHALL NOT BE LIABLE TO YOU FOR:</Typography>
            <Typography gutterBottom align="left">● ANY SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF ANY SOFTWARE, DOCUMENTS, PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR INFORMATION AVAILABLE FROM THE WEB SITE OR LAUNCHPOINT OR LAUNCHPOINT SOFTWARE.</Typography>
            <Typography gutterBottom align="left">● ANY LOSS OR DAMAGE WHICH MAY BE INCURRED BY YOU, INCLUDING BUT NOT LIMITED TO LOSS OR DAMAGE AS A RESULT OF ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY OR EXISTENCE OF ANY CONTENT, OR ANY CHANGES LAUNCHPOINT MAY MAKE TO THE SERVICES, DOCUMENTS, WEB SITE, CONTENT OR FOR ANY PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE WEB SITE (OR ANY FEATURES WITHIN THE WEB SITES); OR IF APPLICABLE, YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE AND CONFIDENTIAL.</Typography>
            <Typography gutterBottom align="left">● THESE LIMITATIONS ON LAUNCHPOINT&#39;S LIABILITY SHALL APPLY WHETHER OR NOT LAUNCHPOINT HAS BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY SUCH LOSSES.</Typography>
            <Typography gutterBottom align="left">Indemnification: You agree to indemnify and hold LAUNCHPOINT and its officers, agents, employees, partners and licensors harmless from any claim or demand, including reasonable attorneys&#39; fees, made by any third party due to or arising out of content you submit, post, transmit or otherwise make available through the Web Sites, or LaunchPoint Software, your use of the Web Sites, LaunchPoint Software or, your connection to the Web Sites, LaunchPoint Software or your violation of these Terms of Use or site specific Terms of Use, or your violation of any rights of another.</Typography>
            <Typography gutterBottom align="left">Accuracy and Completeness: LAUNCHPOINT does not warrant the accuracy or completeness of the information, text, graphics, links or other items contained within the Web Sites, LaunchPoint Software.</Typography>
            <Typography gutterBottom align="left">Changes and Updates: LAUNCHPOINT reserves the right to modify or discontinue, temporarily or permanently, Web Sites, LaunchPoint Software (or any part thereof) with or without notice. LAUNCHPOINT may make changes to Web Sites, LaunchPoint Software, or to the products described therein, at any time without notice. LAUNCHPOINT makes no commitment to update the Web Sites, LaunchPoint Software.</Typography>
            <Typography gutterBottom align="left">Forward Looking Statements: Some of the information on this Web Site may contain projections or other forward-looking statements regarding future events or the future financial performance of LAUNCHPOINT. Words such as &quot;expects,&quot; &quot;goals,&quot; &quot;plans,&quot; &quot;believes,&quot; &quot;continues,&quot; &quot;may,&quot; and variations of such words and similar expressions identify forward-looking statements. In addition, any statements that refer to or may imply future financial performance, our anticipated growth and trends in our businesses, and other characterizations of future events or circumstances are forward-looking statements. All such statements that are not historical facts are based on our current expectations and are subject to a number of risks and uncertainties, and the actual events or results may differ materially. </Typography>
            <Typography gutterBottom align="left" variant="h5">Content Related Notices</Typography>
            <Typography gutterBottom align="left">Ownership: You acknowledge and agree that LAUNCHPOINT owns all legal rights, title and interest in and to the LaunchPoint Software and Web Sites, including any LAUNCHPOINT trade names, trademarks, service marks, logos, domain names, and other distinctive brand features therein (whether those rights happen to be registered or not, and wherever in the world those rights may exist), and that they are protected by worldwide copyright laws and treaty provisions. They may not be copied, reproduced, modified, published, uploaded, posted, transmitted, or distributed in any way without LAUNCHPOINT&#39;s prior written permission. Except as expressly provided herein, LAUNCHPOINT does not grant any express or implied right to you under any patents, copyrights, trademarks, or trade secret information.</Typography>
            <Typography gutterBottom align="left">Third Party Content: Web Sites and LaunchPoint Software may contain user or third party submitted content; such content is not reviewed, approved or endorsed by LAUNCHPOINT and is provided solely as a convenience to our customers and users. Under no circumstances will LAUNCHPOINT be liable in any way for any third party submitted or provided content, including, but not limited to, any errors or omissions, or damages of any kind. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE WEBSITE OR LAUNCHPOINT SOFTWARE IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. By your use you agree that you must evaluate, and bear all risks associated with, the use of any third party content, including any reliance on the accuracy, completeness, or usefulness of such content. All postings and use of the Web Sites, LaunchPoint Software are subject to these Terms of Use and any other program and site specific terms.</Typography>
            <Typography gutterBottom align="left" variant="h5">LAUNCHPOINT Product Notices and Information</Typography>
            <Typography gutterBottom align="left">Limitations: WITH REGARD TO INFORMATION ON LAUNCHPOINT SERVICES IN THE WEBSITE OR LAUNCHPOINT SOFTWARE UNDERSTAND THAT NO LICENSE, EXPRESS OR IMPLIED, BY ESTOPPEL OR OTHERWISE, TO ANY INTELLECTUAL PROPERTY RIGHTS IS GRANTED IN THE WEBSITE OR LAUNCHPOINT SOFTWARE. EXCEPT AS PROVIDED IN LAUNCHPOINT&#39;S SERVICE AND/OR PRODUCT SALE CONTRACTS, LAUNCHPOINT ASSUMES NO LIABILITY WHATSOEVER, AND LAUNCHPOINT DISCLAIMS ANY EXPRESS OR IMPLIED WARRANTY, RELATING TO SALE AND/OR USE OF LAUNCHPOINT SERVICES INCLUDING LIABILITY OR WARRANTIES RELATING TO FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY, OR INFRINGEMENT OF ANY PATENT, COPYRIGHT OR OTHER INTELLECTUAL PROPERTY RIGHT.</Typography>
            <Typography gutterBottom align="left">Changes to Specifications: LAUNCHPOINT may make changes to specifications, service and product descriptions at any time, without notice. No reliance should be made on the absence or characteristics of any features or instructions marked &quot;reserved&quot; or &quot;undefined.&quot; LAUNCHPOINT reserves these for future definition and shall have no responsibility whatsoever for conflicts or incompatibilities arising from future changes to them. The product information on the Web Site, or LaunchPoint Software is subject to change without notice. </Typography>
            <Typography gutterBottom align="left" variant="h5">Notice Specific to LAUNCHPOINT Software</Typography>
            <Typography gutterBottom align="left">Any LaunchPoint Software that is made available from the Web Sites, LAUNCHPOINT, or a third party is the copyrighted work of LAUNCHPOINT. Use of the Software is governed by the terms of the end user license agreement (&quot;EULA&quot;), if any, which accompanies or is included with the Software. An end user will be unable to install any Software unless he or she first agrees to the EULA. The Software is made available from LAUNCHPOINT and for download solely for use by end users according to the EULA. Any reproduction or redistribution of the Software not in accordance with the EULA is expressly prohibited by law, and may result in severe civil and criminal penalties. Violators will be prosecuted to the maximum extent possible.</Typography>
            <Typography gutterBottom align="left">WITHOUT LIMITING THE FOREGOING, COPYING OR REPRODUCTION OF LAUNCHPOINT SOFTWARE TO ANY OTHER SERVER OR LOCATION FOR FURTHER REPRODUCTION OR REDISTRIBUTION IS EXPRESSLY PROHIBITED, UNLESS SUCH REPRODUCTION OR REDISTRIBUTION IS EXPRESSLY PERMITTED BY THE EULA ACCOMPANYING SUCH SOFTWARE. THE SOFTWARE IS WARRANTED, IF AT ALL, ONLY ACCORDING TO THE TERMS OF THE EULA. EXCEPT AS WARRANTED IN THE EULA, LAUNCHPOINT HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO THE SOFTWARE, INCLUDING ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, WHETHER EXPRESS, IMPLIED OR STATUTORY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</Typography>
            <Typography gutterBottom align="left">FOR YOUR CONVENIENCE, LAUNCHPOINT MAY MAKE AVAILABLE AS PART OF THE WEB SITE OR IN ITS SOFTWARE PRODUCTS, TOOLS AND UTILITIES FOR USE AND/OR DOWNLOAD. LAUNCHPOINT DOES NOT MAKE ANY ASSURANCES WITH REGARD TO THE ACCURACY OF THE RESULTS OR OUTPUT THAT DERIVES FROM SUCH USE OF ANY SUCH TOOLS AND UTILITIES. PLEASE RESPECT THE INTELLECTUAL PROPERTY RIGHTS OF OTHERS WHEN USING THE TOOLS AND UTILITIES MADE AVAILABLE ON THE SERVICES OR IN LAUNCHPOINT SOFTWARE PRODUCTS.</Typography>
            <Typography gutterBottom align="left" variant="h5">Notice Specific to LAUNCHPOINT Software</Typography>
            <Typography gutterBottom align="left">Permission to use LAUNCHPOINT owned press releases, datasheets, specification documents, FAQs etc. (&quot;Documents&quot;) from the Web Sites is granted, provided that (1) the below copyright notice appears in all copies and that both the copyright notice and this permission notice appear, (2) use of such Documents from the Services is for informational and non-commercial or personal use only and will not be copied or posted on any network computer or broadcast in any media, and (3) no modifications of any Documents are made. Use for any other purpose is expressly prohibited by law, and may result in severe civil and criminal penalties and requires written permission for use.</Typography>
            <Typography gutterBottom align="left">Documents specified above do not include the design or layout of the Web Site, LaunchPoint Software or any other LAUNCHPOINT owned, operated, licensed or controlled site. Elements of the Web Sites are protected by trade dress, trademark, unfair competition, and other laws and may not be copied or imitated in whole or in part. No logo, graphic, sound or image from any Web Site may be copied or retransmitted unless expressly permitted by LAUNCHPOINT.</Typography>
            <Typography gutterBottom align="left">LAUNCHPOINT MAKES NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE INFORMATION CONTAINED IN THE DOCUMENTS AND RELATED GRAPHICS PUBLISHED ON THE WEB SITE FOR ANY PURPOSE. ALL SUCH DOCUMENTS AND RELATED GRAPHICS ARE PROVIDED &quot;AS IS&quot; WITHOUT WARRANTY OF ANY KIND. LAUNCHPOINT HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, INCLUDING ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, WHETHER EXPRESS, IMPLIED OR STATUTORY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. IN NO EVENT SHALL LAUNCHPOINT AND/OR ITS RESPECTIVE SUPPLIERS BE LIABLE FOR ANY SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF INFORMATION AVAILABLE FROM THE SERVICES.</Typography>
            <Typography gutterBottom align="left">THE DOCUMENTS AND RELATED GRAPHICS PUBLISHED ON THE WEB SITE COULD INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. LAUNCHPOINT MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE PRODUCT(S) AND/OR THE PROGRAM(S) DESCRIBED AT ANY TIME.</Typography>
            <Typography gutterBottom align="left" variant="h5">THE DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) Copyright Infringement Claim</Typography>
            <Typography gutterBottom align="left">Pursuant to Title 17, United States Code, Section 512(c)(2), notifications of claimed copyright infringement should be sent to Service Provider&#39;s Designated Agent. </Typography>
            <Typography gutterBottom align="left">Notification:</Typography>
            <Typography gutterBottom align="left">If you believe that your work was copied or posted on our Website in a way that constitutes copyright infringement, please contact our designated agent at the email address below:</Typography>
            <Typography gutterBottom align="left">support@golaunchpoint.com</Typography>
            <Typography gutterBottom align="left">LAUNCHPOINT may, in appropriate circumstances, terminate an account holder or subscriber to an LAUNCHPOINT Web Site. If you believe that an account holder or subscriber is an infringer, please follow the instructions above to contact LAUNCHPOINT&#39;s DMCA agent and provide information sufficient for us to verify that the account holder or subscriber is an infringer.</Typography>
            <Typography gutterBottom align="left" variant="h5">User Content Submissions</Typography>
            <Typography gutterBottom align="left">Definition: User content submissions may include, but not limited to, user registration, participation, public sharing, uploading, linking, downloading, posting, and transferring, blogging, commenting, chat room, viewing, bulletin board and forum participation, or submitting or transmitting content, including, but not limited to graphics, art, data, text, video, software, code, audio, text, opinions, descriptions etc. (the &quot;User Content&quot;) on any LAUNCHPOINT web site. The content submitted by users is referred to here after as &quot;User Content&quot;.</Typography>
            <Typography gutterBottom align="left">Non-Endorsement: LAUNCHPOINT does not endorse any User Content or any opinion, recommendation, or advice expressed therein, and LAUNCHPOINT expressly disclaims any and all liability in connection with User Content submissions. LAUNCHPOINT does not permit copyright infringing activities and infringement of intellectual property rights on its Web Sites, and LAUNCHPOINT will remove User Content if properly notified that such submission infringes on another&#39;s intellectual property rights in accordance with the DMCA provisions. LAUNCHPOINT reserves the right to remove User Content at its sole discretion and without prior notice.</Typography>
            <Typography gutterBottom align="left">Ownership: LAUNCHPOINT does not claim ownership of any User Content submitted, posted, transmitted, made available or displayed on, or through, the Web Sites or LaunchPoint Software, including, but not limited to, data, text, sound, images, videos, audio, information, diagrams, software, code, graphs, or descriptions on the Web Sites or LaunchPoint Software. Unless otherwise agreed to in writing, there is no compensation for User Content. LAUNCHPOINT is under no obligation to post or use any User Content submitted. Unless otherwise agreed in writing with an authorized LAUNCHPOINT representative, any material, information or other communication you transmit or post to a Web Site will be considered non-confidential and non-proprietary.</Typography>
            <Typography gutterBottom align="left">License: Unless other specified on the Web Site or activity terms and conditions, by posting, displaying, uploading, inputting, providing or submitting such user content you grant LAUNCHPOINT, necessary sub-licensees and Web Site users, a perpetual, irrevocable and fully sub-licensable license to use worldwide, royalty-free and non-exclusive license, to use, adapt, publish, distribute, reproduce, modify, translate, publicly perform and publicly display the User Content (in whole or in part) and to incorporate such User Content into other works in any format or medium now known or later developed for any and all commercial or non-commercial purposes.</Typography>
            <Typography gutterBottom align="left">Warranties and Representations: By posting or submitting User Content you warrant and represent that you own or otherwise control all of the rights to your submission including, but not limited to, all the rights necessary for you to provide, post, upload, input or submit the User Content. To the extent that the User Content submitted contains photographs, pictures, images or graphical representations in whole or in part (&quot;Images&quot;), you warrant and represent that (a) you are the copyright owner of such Images, or that the copyright owner of such Images has granted you permission to use such Images or any content and/or images contained in such Images consistent with the manner and purpose of your use and as otherwise permitted by these Terms of Use, (b) you have the rights necessary to grant the licenses and sub-licenses described in these Terms of Use, and (c) that each person depicted in such Images, if any, has provided consent to the use of the Images as set forth in these Terms of Use.</Typography>
            <Typography gutterBottom align="left">Moderation or Pre-Screening: You acknowledge that LAUNCHPOINT may or may not moderate or pre-screen User Content. LAUNCHPOINT and its designees retain the right, at LAUNCHPOINT&#39;s sole discretion to pre-screen, refuse, or remove any User Content from its Web Site or LaunchPoint Software. LAUNCHPOINT, however, will have no liability related to the content of any such User Content, whether or not arising under the laws of copyright, libel, privacy, obscenity, or otherwise. Without limiting the foregoing, LAUNCHPOINT shall have the right to remove any User Content that violates these Terms of Use.</Typography>
            <Typography gutterBottom align="left" variant="h5">Links to Other Materials or Sites</Typography>
            <Typography gutterBottom align="left">The linked sites are not under the control of LAUNCHPOINT and LAUNCHPOINT is not responsible for the content of any linked site or any link contained in a linked site. LAUNCHPOINT reserves the right to terminate any link or linking program at any time. LAUNCHPOINT does not endorse companies or products to which it links and reserves the right to note as such on its web pages. If you decide to access any of the third party sites linked to this Site, you do this entirely at your own risk.</Typography>
            <Typography gutterBottom align="left" variant="h5">Unsolicited Idea Submission Policy</Typography>
            <Typography gutterBottom align="left">LAUNCHPOINT OR ITS EMPLOYEES DO NOT ACCEPT OR CONSIDER UNSOLICITED IDEAS, INCLUDING IDEAS FOR NEW ADVERTISING CAMPAIGNS, NEW PROMOTIONS, NEW SERVICES, PRODUCTS OR TECHNOLOGIES, PROCESSES, MATERIALS, MARKETING PLANS OR NEW PRODUCT NAMES. PLEASE DO NOT SEND ANY ORIGINAL CREATIVE ARTWORK, SAMPLES, DEMOS, OR OTHER WORKS. THE SOLE PURPOSE OF THIS POLICY IS TO AVOID POTENTIAL MISUNDERSTANDINGS OR DISPUTES WHEN LAUNCHPOINT&#39;S PRODUCTS, SERVICES OR MARKETING STRATEGIES MIGHT SEEM SIMILAR TO IDEAS SUBMITTED TO LAUNCHPOINT. SO, PLEASE DO NOT SEND YOUR UNSOLICITED IDEAS TO LAUNCHPOINT OR ANYONE AT LAUNCHPOINT. IF, DESPITE OUR REQUEST THAT YOU NOT SEND US YOUR IDEAS AND MATERIALS, YOU STILL SEND THEM, PLEASE UNDERSTAND THAT LAUNCHPOINT MAKES NO ASSURANCES THAT YOUR IDEAS AND MATERIALS WILL BE TREATED AS CONFIDENTIAL OR PROPRIETARY.</Typography>
            <Typography gutterBottom align="left" variant="h5">General</Typography>
            <Typography gutterBottom align="left">User access to this Web Site is governed by all applicable federal, state and local laws. All information available on the Web Site may be subject to the laws of the country where you reside.</Typography>
            <Typography gutterBottom align="left">LAUNCHPOINT makes no representation that Materials in the site are appropriate or available for use in other locations, and access to them from territories where their content is illegal is prohibited. Those who choose to access this site from other locations do so on their own initiative and are responsible for compliance with applicable local laws.</Typography>
            <Typography gutterBottom align="left">These Terms constitute the entire agreement between you and LAUNCHPOINT Corporation and govern your use of the Site. Each user may be subject to additional terms and conditions that may apply when that user uses affiliate services, third party content or third party software. The Terms and the relationship between you and LAUNCHPOINT shall be governed by the laws of the State of Illinois, USA without regard to its conflict of law provisions and each party shall submit to the personal and exclusive jurisdiction of the courts located within the State. If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties&#39;s intentions as reflected in the provision, and the other provisions of the Terms remain in full force and effect.</Typography>
            <Typography gutterBottom align="left">In the case of any violation of these rules and regulations, LAUNCHPOINT reserves the right to seek all remedies available by law and in equity for such violations.</Typography>
            <Typography gutterBottom align="left">LAUNCHPOINT may revise these Terms of Use at any time by updating this posting. You should visit this page from time to time to review the then-current Terms of Use because they are binding on your use of the Site. Certain provisions of these Terms of Use may be superseded by expressly designated legal notices or terms located on particular Web Sites or LaunchPoint Software.</Typography>

          </DialogContent>
          <DialogActions>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={() => { this.setState({ showPolicy: false }); }} color="primary" autoFocus>
              <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Close</label>
            </Button>
          </DialogActions>
        </Dialog>
      </footer>
      : null
    )
  }
}

Footer.propTypes = {
  displayFooter: PropTypes.bool,
  onLaunchPoint: PropTypes.bool,
  onMainPage: PropTypes.bool
}

export default Footer;