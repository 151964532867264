import React from 'react';
import auth from "../common/Auth";
import { loadModules } from 'esri-loader';
import ImageGallery from 'react-image-gallery';
import PropTypes from "prop-types";
import { GLOBAL } from "../../Global";
import moment from 'moment';
import { set } from 'idb-keyval';
import VideoPanel from './VideoPanel';
import { BarLoader } from 'react-spinners';
import MapTwoIcon from '@material-ui/icons/MapTwoTone';
import SearchIcon from '@material-ui/icons/Search';
import DescriptionIcon from '@material-ui/icons/Description';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import FormDataPage from './FormDataPage';
import { Button } from 'react-bootstrap';
import Functions from '../../Functions/Functions';
import MarkerUtilty from '../../Utility/MarkerUtility';
import OrgUtility from '../../Utility/Utility';
import ReactDOMServer from 'react-dom/server';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import LeakSurveyPage from './LeakSurveyPage';

export class WebMapViewLeakSurvey extends React.Component {
  
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.MarkerUtil = new MarkerUtilty();
    this.entityType = {
      Inaccessible:14,
      Aoc:15,
      Leak:16,
      MeterOutside:22,
      CustomPipeline:23,
      MeterInside:24
    }
    this.state = {
      map: null,
      mapLayerType: [14,15,16,22,23,24],
      zoomToExtent: true,
      users: [],
      images : [],
      videos : [],
      videoCount : 0,
      pictureCount : 0,
      beginScale: -1,
      currentFullscreenImage : [],
      firstPassthroughDone : false,
      legacySurveyPoints : process.env.API_URL == "https://siterightapi.golaunchpoint.com" ? "https://services8.arcgis.com/3UTMQWLH7xp6vpCH/arcgis/rest/services/SiteRight_TrailPoints/FeatureServer/" 
      : "https://services8.arcgis.com/3UTMQWLH7xp6vpCH/arcgis/rest/services/SiteRight_TrailPoints_DEV/FeatureServer/",
      legacySurveyLines : process.env.API_URL == "https://siterightapi.golaunchpoint.com" ? "https://services8.arcgis.com/3UTMQWLH7xp6vpCH/arcgis/rest/services/LeakSurveyLines/FeatureServer/" 
      : "https://services8.arcgis.com/3UTMQWLH7xp6vpCH/arcgis/rest/services/LeakSurveyLines_DEV/FeatureServer/",
      // xcelLayerURL : process.env.API_URL == "https://siterightapi.golaunchpoint.com" ?
      //   "https://services8.arcgis.com/3UTMQWLH7xp6vpCH/arcgis/rest/services/Xcel_Energy_LeakSurvey_CO/FeatureServer/" :
      //   "https://services8.arcgis.com/3UTMQWLH7xp6vpCH/arcgis/rest/services/Xcel_Energy_LeakSurvery_CO_DEV/FeatureServer/",
      //lgeLayerURL : "https://services8.arcgis.com/3UTMQWLH7xp6vpCH/arcgis/rest/services/LGE_Mobile_Demo/FeatureServer/",
      //blackHillsLayerURL : "https://services8.arcgis.com/3UTMQWLH7xp6vpCH/arcgis/rest/services/Black_Hills_Leak_Survey/FeatureServer/",
      assignedCustomFormColor : '#3c5873',
      assignedUserColor : '#3c5873',
      addressSearch : '',
      baseArcgisURL : "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/",
      addressSuggestions : [],
      currentLat : 0,
      currentLon : 0,
      newTaskAddressColor : '#3c5873',
      editingNewTask : false,
      mapReportTitle : "",
      mapReportTitleColor : 'black',
      arcGisConnectedLayersAccessToken : "",
      defaultLayerColor : "#ff6347",
      addressSearchForSearch : '',
      addressSearchViewOpen : false,
      viewingDetails : false,
      entityToView : null
    };

    this.isFirstRerender = true;
    this.rerenderingMap = false;
    this.archiveSurvey = this.archiveSurvey.bind(this);
    
    GLOBAL.ASSIGNMENT = {};
    var colorAssignmentArea = '#de9232';
    var colorGasLines = '#ffcc31';
    var colorSurveyLines = '#FF7536';
    var colorSurveyPoints = '#007ac2';
    var colorE14 = '#1babb9';
    var colorE14C = '#176782';
    var colorE15 = '#3275b9';
    var colorE15C = '#1C408A';
    var colorE16 = '#19ad84';
    var colorE16C = '#158566';
    var colorE22 = '#73b32e';
    var colorE22C = '#5B8A28';
    var colorE23 = '#35924b';//'#1bb940';
    var colorE23C = '#456e1f';
    var colorE24 = '#53498a';
    var colorE24C = '#172282';

    var colorInSurveyPoints = colorE14;
    var colorAocSurveyPoints = colorE15;
    var colorLeakSurveyPoints = colorE16;
    var colorMeterOutsidePoints = colorE22;
    var colorMeterInsidePoints = colorE24;
    var colorCustomPipeline = colorE23;
    var colorTee = '#ffa912';
    var colorRiser = '#19ad84';
    var colorMeter = '#0091d6';
    var colorPipe = '#00a1ff';
    var colorMain = '#b72b2b';
    var colorMainAccel = '#000000';
    var colorFacets = '#D4D4D4';
    var colorValve = '#4dab20';//'#37a800';
    //var colorAcceleratedFacet = '#0f89d1';
    var colorBusinessDistrict = '#bd3536'
    var colorServiceAccelerated = '#00456e';
    var colorMeterFull = '#19ad40';
    var colorFacetsAll = '#64c8c8';

    this.uniqueListViewItems = [];
    this.firstLoadComplete = false;
    this.markerStyle ={
      0 : 'default',
      14 : 'default',
      15 : 'default',
      16 : 'default',
      22 : 'default',
      23 : 'default',
      24 : 'default',
      'Inaccessible Survey':'default',
      'AOC Survey': 'default',
      'Meter - Outside': 'default',
      'Meter - Inside' : 'default',
      'Leak Survey': 'default',
    };
    this.legendStyle = {
      'Assignment Areas':colorAssignmentArea,
      'Gas lines':colorGasLines,
      'Survey Lines':colorSurveyLines,
      'Survey Lines (Pre 04/01/22)':colorSurveyLines,
      'Survey Points':colorSurveyPoints,
      'Inaccessible Survey':colorInSurveyPoints,
      'AOC Survey':colorAocSurveyPoints,
      'Meter - Outside': colorMeterOutsidePoints,
      'Meter - Inside' : colorMeterInsidePoints,
      'Leak Survey':colorLeakSurveyPoints,
      'Survey Data' : colorCustomPipeline,
      'VALVE' : colorValve,
      'TEE' : colorTee,
      'RISER' : colorRiser,
      'METER' : colorMeter,
      'SERVICE' : colorPipe,
      'MAIN' : colorMain,
      'WHOLE MAP' : colorFacets,
      'Business District' : colorBusinessDistrict,
      'Service Accelerated' : colorServiceAccelerated,
      'Main Accelerated' : colorMainAccel,
      'METER FULL' : colorMeterFull,
      'Accelerated Facets' : colorFacetsAll,
      'LGE DISTRIBUTION' : colorMeter,
      'LGE SERVICE' : colorServiceAccelerated,
      'Service Line' : colorPipe,
      0:colorAssignmentArea,
      14:colorE14,
      15:colorE15,
      16:colorE16,
      22:colorE22,
      23:colorE23,
      24:colorE24,
      '14C':colorE14C,
      '15C':colorE15C,
      '16C':colorE16C,
      '22C':colorE22C,
      '23C':colorE23C,
      '24C':colorE24C
    };

    this.leakSurveyName = {
      14:"Inaccessible",
      15:"AOC",
      16:"Leak",
      22:"Meter - Outside",
      23:"Custom Pipeline",
      24:"Meter - Inside"
    }
    this.clickThumbNail = this.clickThumbNail.bind(this);
    this.getVideo = this.getVideo.bind(this);
    this.controller = new AbortController();
    this.setVideoIndex = this.setVideoIndex.bind(this);
    this.setNewTaskLatLon = this.setNewTaskLatLon.bind(this);
    this.createAndAssignTask = this.createAndAssignTask.bind(this);
    this.searchAddress = this.searchAddress.bind(this);
    this.selectSuggestedAddress = this.selectSuggestedAddress.bind(this);
    this.createTask = this.createTask.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.uploadDoc = this.uploadDoc.bind(this);
    this.createMapReport = this.createMapReport.bind(this);
    this.openDetailsView = this.openDetailsView.bind(this);
    this.viewEntity = this.viewEntity.bind(this);
  } 

  beforeunload(){
    if(this.view){
      sessionStorage.setItem("leakSurveyMapZoom", this.view.zoom.toString());
      sessionStorage.setItem("leakSurveyMapExtent", JSON.stringify(this.view.extent));
    }
  }

  setLayerVisibility(layer, visibility){

    if(this.layerDictionary){
      switch(layer){
        case 'Leak':
          if(this.layerDictionary[16] && this.layerDictionary[16].layer){
            this.layerDictionary[16].layer.visible = visibility;
          }
          break;
        case 'AOC':
          if(this.layerDictionary[15] && this.layerDictionary[15].layer){
            this.layerDictionary[15].layer.visible = visibility;
          }
          break;
        case "Inaccessible":
          if(this.layerDictionary[14] && this.layerDictionary[14].layer){
            this.layerDictionary[14].layer.visible = visibility;
          }          
          break;
        case "Meter":
          if(this.layerDictionary[22] && this.layerDictionary[22].layer){
            this.layerDictionary[22].layer.visible = visibility;
          }
          break;
        case "Pipeline":
          if(this.layerDictionary[23] && this.layerDictionary[23].layer){
            this.layerDictionary[23].layer.visible = visibility;
          }
          break;
        case "MeterInside":
          if(this.layerDictionary[24] && this.layerDictionary[24].layer){
            this.layerDictionary[24].layer.visible = visibility;
          }
          break;
        default:
          break;
      }
    }
  }

  getFacetPopupContent(feature){
    let attributes = feature.graphic.attributes;
    let content = "<table class='esri-widget__table' summary='List of attributes and values'>" +
      "<tr><th class='esri-feature__field-header'>Name</th><td class='esri-feature__field-data'>"  +  (attributes.NAME ? attributes.NAME : "" ) + "</td></tr>" + 

      "<tr><th class='esri-feature__field-header'>Conversion Year</th><td class='esri-feature__field-data'>"  +  (attributes.Conversion_Year ? attributes.Conversion_Year : "" ) + "</td></tr>" + 
      "<tr><th class='esri-feature__field-header'>Compliant</th><td class='esri-feature__field-data'>"  +  (attributes.Compliant ? attributes.Compliant : "" ) + "</td></tr>" + 

      "<tr><th class='esri-feature__field-header'>Riser Count (Business)</th><td class='esri-feature__field-data'>"  +  (attributes.Riser_Count_Biz ? attributes.Riser_Count_Biz : "" ) + "</td></tr>" + 
      "<tr><th class='esri-feature__field-header'>Main Ft (Business)</th><td class='esri-feature__field-data'>"  +  (attributes.Main_Ft_Biz ? attributes.Main_Ft_Biz : "" ) + "</td></tr>" + 
      "<tr><th class='esri-feature__field-header'>Service Ft (Business)</th><td class='esri-feature__field-data'>"  +  (attributes.Serv_Ft_Biz ? attributes.Serv_Ft_Biz : "" ) + "</td></tr>" + 

      "<tr><th class='esri-feature__field-header'>Tap Count</th><td class='esri-feature__field-data'>"  +  (attributes.Sum_Tap ? attributes.Sum_Tap : "" ) + "</td></tr>" + 
      "<tr><th class='esri-feature__field-header'>Riser Count</th><td class='esri-feature__field-data'>"  +  (attributes.Sum_Riser ? attributes.Sum_Riser : "" ) + "</td></tr>" + 
      "<tr><th class='esri-feature__field-header'>Sum Total</th><td class='esri-feature__field-data'>"  +  (attributes.Sum_Total ? attributes.Sum_Total : "" ) + "</td></tr>" + 

      "<tr><th class='esri-feature__field-header'>Total Ft</th><td class='esri-feature__field-data'>"  +  (attributes.Total_Foot_Biz ? attributes.Total_Foot_Biz : "" ) + "</td></tr>" + 
      "<tr><th class='esri-feature__field-header'>Total Service</th><td class='esri-feature__field-data'>"  +  (attributes.Total_Service ? attributes.Total_Service : "" ) + "</td></tr>" + 
      "<tr><th class='esri-feature__field-header'>Main Ft</th><td class='esri-feature__field-data'>"  +  (attributes.Total_Main ? attributes.Total_Main : "" ) + "</td></tr>" + 

      "</table>" ;
    return content;
  }

  getAcceleratedFacetPopupContent(feature){
    let attributes = feature.graphic.attributes;
    let content = "<table class='esri-widget__table' summary='List of attributes and values'>" +
      "<tr><th class='esri-feature__field-header'>Name</th><td class='esri-feature__field-data'>"  +  (attributes.NAME ? attributes.NAME : "" ) + "</td></tr>" + 
      "<tr><th class='esri-feature__field-header'>Main Ft</th><td class='esri-feature__field-data'>"  +  (attributes.Main ? attributes.Main : "" ) + "</td></tr>" + 

      "<tr><th class='esri-feature__field-header'>Main Bare</th><td class='esri-feature__field-data'>"  +  (attributes.Main_Bare ? attributes.Main_Bare : "" ) + "</td></tr>" + 
      "<tr><th class='esri-feature__field-header'>Main CAB</th><td class='esri-feature__field-data'>"  +  (attributes.Main_CAB ? attributes.Main_CAB : "" ) + "</td></tr>" + 
      "<tr><th class='esri-feature__field-header'>Main PVC</th><td class='esri-feature__field-data'>"  +  (attributes.Main_PVC ? attributes.Main_PVC : "" ) + "</td></tr>" + 
      "<tr><th class='esri-feature__field-header'>Total Main</th><td class='esri-feature__field-data'>"  +  (attributes.Total_Main  ? attributes.Total_Main  : "" ) + "</td></tr>" + 

      "<tr><th class='esri-feature__field-header'>Service Bare</th><td class='esri-feature__field-data'>"  +  (attributes.Service_Bare ? attributes.Service_Bare : "" ) + "</td></tr>" + 
      "<tr><th class='esri-feature__field-header'>Service CAB</th><td class='esri-feature__field-data'>"  +  (attributes.Service_CAB ? attributes.Service_CAB : "" ) + "</td></tr>" + 
      "<tr><th class='esri-feature__field-header'>Service PVC</th><td class='esri-feature__field-data'>"  +  (attributes.Service_PVC ? attributes.Service_PVC : "" ) + "</td></tr>" + 
      "<tr><th class='esri-feature__field-header'>Total Service</th><td class='esri-feature__field-data'>"  +  (attributes.Total_Serv ? attributes.Total_Serv : "" ) + "</td></tr>" + 

      "<tr><th class='esri-feature__field-header'>Total Footage</th><td class='esri-feature__field-data'>"  +  (attributes.Total_Footage ? attributes.Total_Footage : "" ) + "</td></tr>" + 

      "<tr><th class='esri-feature__field-header'>Shape Area</th><td class='esri-feature__field-data'>"  +  (attributes.Shape__Area ? attributes.Shape__Area : "" ) + "</td></tr>" + 
      "<tr><th class='esri-feature__field-header'>Shape Length</th><td class='esri-feature__field-data'>"  +  (attributes.Shape__Length  ? attributes.Shape__Length  : "" ) + "</td></tr>" + 


      "</table>" ;
    return content;
  }

  getMainAcceleratedPopupContent(feature){
    let attributes = feature.graphic.attributes;
    let content = "<table class='esri-widget__table' summary='List of attributes and values'>"+
    "<tr><th class='esri-feature__field-header'>Pressure</th><td class='esri-feature__field-data'>"  +  (attributes.PRESSURE ? attributes.PRESSURE : "" ) + "</td></tr>" + 
    "<tr><th class='esri-feature__field-header'>Material</th><td class='esri-feature__field-data'>"  +  (attributes.MATERIAL ? attributes.MATERIAL : "" ) + "</td></tr>" + 
    "</table>"
    return content;
  }
  getServiceAcceleratedPopupContent(feature){
    let attributes = feature.graphic.attributes;
    let content = "<table class='esri-widget__table' summary='List of attributes and values'>"+
    "<tr><th class='esri-feature__field-header'>Pressure</th><td class='esri-feature__field-data'>"  +  (attributes.PRESSURE ? attributes.PRESSURE : "" ) + "</td></tr>" + 
    "<tr><th class='esri-feature__field-header'>Material</th><td class='esri-feature__field-data'>"  +  (attributes.MATERIAL ? attributes.MATERIAL : "" ) + "</td></tr>" + 
    "</table>"
    return content;
  }
  getAutoAttributePopupContent(feature){
    let attributes = feature.graphic.attributes;
    let content = "<table class='esri-widget__table' summary='List of attributes and values'>";
    Object.keys(attributes).forEach(key =>{
      if(key != "OBJECTID" && key != "Shape" && key != "Shape__Length" && key != "Shape__Area"){
        content += "<tr><th class='esri-feature__field-header'>" + key + "</th><td class='esri-feature__field-data'>"  +  (attributes[key] ? attributes[key] : "" ) + "</td></tr>"
      }
    });
    content +="</table>";
    return content;
  }

  getSurveyLinePopupContent(feature){

    var length = 0;
    var data = feature.graphic.attributes;
    if (this.geometryEngine)
      length = this.geometryEngine.geodesicLength(feature.graphic.geometry, "meters");
    var userName = data.CreatedBy;
    const user = this.state.users.find(u => u.UserId == data.CreatedBy);
    if(user)userName = user.DisplayNameProper;

    let utcOffset = moment().utcOffset();

    let groupStartDateTime = moment(data.GroupStartTime).add(-utcOffset, 'minutes').add(data.UTCOffset, 'hours');
    let groupEndDateTime = moment(data.GroupEndTime).add(-utcOffset, 'minutes').add(data.UTCOffset, 'hours');

    let content = "<table class='esri-widget__table' summary='List of attributes and values'><tr>" +
    "<th class='esri-feature__field-header'>Surveyed By: </th><td class='esri-feature__field-data'>" + userName + "</td></tr><tr>" +
    "<th class='esri-feature__field-header'>Start Time: </th><td class='esri-feature__field-data'>" + groupStartDateTime.format('MM[/]DD[/]YYYY, h:mm:ss a') + "</td></tr><tr>" +
    "<th class='esri-feature__field-header'>End Time: </th><td class='esri-feature__field-data'>" +  groupEndDateTime.format('MM[/]DD[/]YYYY, h:mm:ss a') + "</td></tr><tr>" +
    "<th class='esri-feature__field-header'>Approx. Distance</th><td class='esri-feature__field-data'>" + Math.round(length * 10) / 10 + " meters</td></tr></table>" +
    "<br/><p><b>All times displayed in local time of the survey location</b></p>";
  
    return content;
  }

  getSurveyPointPopupContent(feature){

    var length = 0;
    var data = feature.graphic.attributes;
    if (this.geometryEngine)
      length = this.geometryEngine.geodesicLength(feature.graphic.geometry, "meters");
    var userName = data.CreatedBy;
    const user = this.state.users.find(u => u.UserId == data.CreatedBy);
    if(user)userName = user.DisplayNameProper;

    let utcOffset = moment().utcOffset();

    let groupStartDateTime = moment(data.LocatedDatetime).add(-utcOffset, 'minutes').add(data.UTCOffset, 'hours');
    //let groupEndDateTime = moment(data.GroupEndTime).add(-utcOffset, 'minutes').add(data.UTCOffset, 'hours');
    console.log(length);
    let content = "<table class='esri-widget__table' summary='List of attributes and values'><tr>" +
    "<th class='esri-feature__field-header'>Surveyed By: </th><td class='esri-feature__field-data'>" + userName + "</td></tr><tr>" +
    "<th class='esri-feature__field-header'>Time: </th><td class='esri-feature__field-data'>" + groupStartDateTime.format('MM[/]DD[/]YYYY, h:mm:ss a') + "</td></tr><tr>" +
    "<th class='esri-feature__field-header'>Latitude: </th><td class='esri-feature__field-data'>" + data.Latitude + " </td></tr><tr>" +
    "<th class='esri-feature__field-header'>Longitude: </th><td class='esri-feature__field-data'>" + data.Longitude + " </td></tr><tr>" +
    "<th class='esri-feature__field-header'>Speed: </th><td class='esri-feature__field-data'>" + data.Speed + " </td></tr><tr>" +
    "<th class='esri-feature__field-header'>Heading: </th><td class='esri-feature__field-data'>" + data.Heading + " </td></tr><tr>" +
    "<th class='esri-feature__field-header'>Altitude: </th><td class='esri-feature__field-data'>" + data.Altitude + " </td></tr><tr>" +
    "<th class='esri-feature__field-header'>Satellites: </th><td class='esri-feature__field-data'>" + data.Sats + " </td></tr></table>" +
    "<br/><p><b>All times displayed in local time of the survey location</b></p>";
  
    return content;
  }

  getUserList(){
    let body = {CompanyId: auth.companyId()};
    fetch(process.env.API_URL + "/api/account/users",{
      method: "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson); 
          if(json.ResponseType == 0 && json.UserCollection.length >0){
            let i = json.UserCollection.length;
            while (i--) {
              if (json.UserCollection[i].DeletedBy != null ||json.UserCollection[i].DeletedDate != null || json.UserCollection[i].DeletedDateUTCOffset != null) {
                json.UserCollection.splice(i, 1);
              }
            }
            let trialMode = json.Company != null && json.Company.SubscriptionPlanId.includes("trial");
            Functions.sortOn(json.UserCollection, 'LastName');
            this.setState({users : json.UserCollection, trialMode});
          }
        })
      }
    })
  }

  getCustomFormList(){
    let body = {
      UserId: auth.employeeId(),
      CompanyId : auth.companyId(),
    }
    fetch(process.env.API_URL + "/api/account/form",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.FormCollection){
              this.setState({formProjects : json.FormProjectCollection,customFormList : json.FormCollection.filter(x => x.Enabled == true && x.FormTypeId == 2)});
            }
          }
        });
      }
    });
  }

  archiveSurvey(survey, popup){
    if(auth.isSuperViewer()){
      if(popup) popup.close();
      return;
    }
    this.props.statusTextUpdate("Archiving...");
    if(popup) popup.close();
    let formData;
    let now = new Date();
    if(survey.FormData){
      formData = survey.FormData;
      formData.FieldsToSave = formData.Fields;
      formData.DeletedDate = now;
      formData.DeletedDateUTCOffset = -now.getTimezoneOffset()/60;
      formData.DeletedBy = auth.userId();
      formData.ModifieddDate = now;
      formData.ModifiedDateUTCOffset = -now.getTimezoneOffset()/60;
      formData.ModifiedBy = auth.userId();
    }
    else{
      survey.DeletedDate = now;
      survey.DeletedDateUTCOffset = -now.getTimezoneOffset()/60;
      survey.DeletedBy = auth.userId();
      survey.ModifieddDate = now;
      survey.ModifiedDateUTCOffset = -now.getTimezoneOffset()/60;
      survey.ModifiedBy = auth.userId();
    }
    let url = process.env.API_URL;
    url += formData != null ? "/api/form/save" : "/api/LeakSurvey/save";
    let body = {
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      LeakSurvey : formData != null ? null : survey,
      FormData : formData != null ? formData : survey
    }
    fetch(url,{
      method : "POST",
      headers : {
        Accept : "application/json;",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : 'Bearer ' + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(response => response)
    .then((response) =>{
      if(response.status == 200){
        response.json().then(() =>{
          this.props.updateFromArchive(survey, formData != null);
          this.props.statusTextUpdate("");
          this.props.setAlert('success','Successfully Archived.');
          this.updateExtent = false;
          popup.close();
          setTimeout(() =>{this.rerenderMap();},1000); //Need to make sure that results had a chance to update and set state.
        });
      }
      else{
        this.props.statusTextUpdate("");
        this.props.setAlert('error','Error Archiving.');  
      }
    }).catch(error =>{
      this.props.statusTextUpdate("");
      this.props.setAlert('error','Error Archiving.');  
      console.log(error);
    });
  }

  createTask(){
    let form = this.state.customFormList.find(x => x.FormId == this.state.assignedCustomFormId);
    let now = new Date();
    //Add projects field to form if there are projects available to form. 
    if(this.state.formProjects && this.state.formProjects.length > 0){
      let projects = this.state.formProjects.filter(x => x.FormId == form.FormId);
      if(projects && projects.length > 0 && form.Fields && form.Fields.findIndex(x => x.Name == "Project" && x.Type == "Drop Down") == -1){
        form.Fields.unshift({Name : "Project", Type : "Drop Down", CustomValues : projects.map(x => {return x.ProjectLabel})});
      }
    }
    var newTaskCustomForm = {
      FormDataId : -1,
      Fields : form.Fields,
      FieldsToSave : form.Fields,
      FormId : form.FormId,
      FormTypeId : form.FormTypeId,
      Name : form.Name,
      CompanyId : auth.companyId(),
      CreatedDate : now,
      CreatedDateUTCOffset : - now.getTimezoneOffset()/60,
      CreatedBy : auth.employeeId(),
      ModifiedDate : now,
      ModifiedDateUTCOffset : - now.getTimezoneOffset()/60,
      ModifiedBy : auth.employeeId(),
      AllowPics : form.AllowPics,
      AllowVids : form.AllowVids
    }
    this.setState({newTaskCustomForm});
  }

  createMapReport(){
    if(!this.state.mapReportTitle || this.state.mapReportTitle == ""){
      this.setState({mapReportTitleColor : 'red'});
      return;
    }
    let reportModal = document.getElementById("mapReportDiv");
    reportModal.style.display = "none";
    this.props.statusTextUpdate("CREATING MAP REPORT...");
    var layers = [];
        for(const layer in this.layerDictionary){
          let currentLayer = this.layerDictionary[layer].layer;
          if(currentLayer && currentLayer.visible && currentLayer.graphics && currentLayer.graphics.length > 0){ 
            if(layer == "assignment"){
              layers.push({Name : "Assignment Areas", Color : "" , Symbol : ""});
            }
            else if(layer == "blackHillsServiceLayer"){
              layers.push({Name : "Black Hills Service Layer", Color : "" , Symbol : ""});
            }
            else if(layer == "blackHillsMainLayer"){
              layers.push({Name : "Black Hills Main Layer", Color : "" , Symbol : ""});
            }
            else if(layer == "legacyLeakSurveyLines"){
              layers.push({Name : "Survey Lines (Pre 04/01/22)", Color : "" , Symbol : ""});
            }
            else if(layer == "layerLeakSurveyTracking"){
              layers.push({Name : "Survey Lines", Color : "" , Symbol : ""});
            }
            else if(layer == "layerLeakSurveyTrackingPoint"){
              layers.push({Name : "Survey Points", Color : "" , Symbol : ""});
            }
            else if(layer == "valve"){
              layers.push({Name : "VALVE", Color : "" , Symbol : ""});
            }
            else if(layer == "tee"){
              layers.push({Name : "TEE", Color : "" , Symbol : ""});
            }
            else if(layer == "riser"){
              layers.push({Name : "RISER", Color : "" , Symbol : ""});
            }
            else if(layer == "meter"){
              layers.push({Name : "METER", Color : "" , Symbol : ""});
            }
            else if(layer == "pipe"){
              layers.push({Name : "SERVICE", Color : "" , Symbol : ""});
            }
            else if(layer == "main"){
              layers.push({Name : "MAIN", Color : "" , Symbol : ""});
            }
            else if(layer == "facets"){
              layers.push({Name : "Business District", Color : "" , Symbol : ""});
            }
            else if(layer == "mainAcceleratedLayer"){
              layers.push({Name : "Main Accelerated", Color : "" , Symbol : ""});
            }
            else if(layer == "serviceAcceleratedLayer"){
              layers.push({Name : "Service Accelerated", Color : "" , Symbol : ""});
            }
            else if(layer == "acceleratedFacetLayer"){
              layers.push({Name : "Accelerated Facets", Color : "" , Symbol : ""});
            }
            else if(layer == "facetsAllLayer"){
              layers.push({Name : "WHOLE MAP", Color : "" , Symbol : ""});
            }
            else{
              if(currentLayer.title != "Assignment Areas"){
                let firstGraphic = currentLayer.graphics.items[0];
                layers.push({Name : currentLayer.title, Color : {R : firstGraphic.symbol.color.r , G :firstGraphic.symbol.color.g , B :firstGraphic.symbol.color.b }, Symbol : firstGraphic.symbol.path});
              }
            }
          }
        }
        this.view.takeScreenshot().then(screenshot =>{
          let baseString = screenshot.dataUrl.split(",")[1];
          let body ={
            Layers : layers,
            Base64String : baseString,
            OrgId : new OrgUtility().getOrgId(),
            Scale : parseInt(this.view.scale),
            Title : this.state.mapReportTitle,
            UserId : auth.employeeId(),
            CompanyId : auth.companyId()
          }
          fetch(process.env.API_URL + "/api/data/mapReport",{
            method : "POST",
            headers:{
              "Content-Type" : "application/json; charset=utf-8",
              Accept : "application/json",
              Authorization : "Bearer " + auth.token()
            },
            body : JSON.stringify(body)
          })
          .then(r =>r.blob())
          .then(blob =>{
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.style= "display:none";
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = "MapReport.pdf";
            a.click();
            window.URL.revokeObjectURL(url);
            this.setState({mapReportTitle : ""});
            this.props.statusTextUpdate("");
          })
          .catch(error =>{
            console.log(error.message);
            this.setState({mapReportTitle : ""});
            this.props.statusTextUpdate("");
            this.props.setAlert("error", "There was a problem creating the Map Report.");
          });
        });
  }

  createAndAssignTask(){
    let missingInfo = false;
    if(!this.state.newTaskLat || !this.state.newTaskLon){
      this.setState({newTaskAddressColor : 'red'});
      missingInfo = true;
    }
    if(!this.state.assignedCustomFormId || this.state.assignedCustomFormId == ""){
      this.setState({assignedCustomFormColor : 'red'});
      missingInfo = true;
    }
    if(!this.state.assignedUserId || this.state.assignedUserId == ""){
      this.setState({assignedUserColor : 'red'});
      missingInfo = true;
    }
    if(missingInfo){
      return;
    }
    let taskModal = document.getElementById("createTaskDiv");
    taskModal.style.display = "none";
    this.props.statusTextUpdate("CREATING TASK...");
    let now = new Date();
    var customForm = this.state.newTaskCustomForm;
    customForm.Latitude = this.state.newTaskLat;
    customForm.Longitude = this.state.newTaskLon;
    customForm.CreatedDate = now;
    customForm.ModifiedDate = now;
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      FormData : customForm,
      NewTaskFromWeb : true
    }
    fetch(process.env.API_URL + "/api/form/save",{
      method : "POST",
      headers : {
        Accept : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer "+ auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(response => response)
    .then((response)=>{
      if(response.status ==200){
        response.json().then(bodyJson =>{
          let response = JSON.parse(bodyJson);
          if(response.ResponseType != 0){
            this.props.statusTextUpdate("");
            this.props.setAlert("error", "There was a problem creating the task.");
          }
          else{
            if(customForm.images && customForm.images.length > 0){
              customForm.images.forEach(async image =>{
                await this.uploadImage(image,response.FormData.FormDataId);
              });
            }
            if(customForm.docs && customForm.docs.length > 0){
              customForm.docs.forEach(async doc =>{
                await this.uploadDoc(doc,response.FormData.FormDataId);
              });
            }
            this.props.setCreatingNewTask(false);
            this.props.statusTextUpdate("");
            let newEntity = this.props.addNewEntityToResults(response.FormData);
            this.assignTask(this.state.assignedUserId,"", newEntity.FormData.FormDataId, true);
            this.setState({assignedCustomFormId : "", assignedUserId: "", addressSearch : '', newTaskLat : null, newTaskLon : null, addressSuggestions : [], foundAddress : ''});
          }
          
        });
      }
      else{
        this.props.statusTextUpdate("");
        this.props.setAlert("error", "There was a problem creating the task.");
        this.setState({assignedCustomFormId : "", assignedUserId: "", addressSearch : '', newTaskLat : null, newTaskLon : null, addressSuggestions : [], foundAddress : ''});
      }
    })
    .catch(() =>{
      this.props.statusTextUpdate("");
      this.props.setAlert("error","There was a problem creating the task.");
      this.setState({assignedCustomFormId : "", assignedUserId : ""});
    });
  }

  async uploadDoc(doc, id){
    let date = new Date();
    if(doc.size > 50000000){
      this.props.statusTextUpdate("");
      this.props.setAlert("error","There was a problem uploading documents for the task.");
      return;
    }
    let body = {
      MultimediaAsset : {
        EntityId : id,
        EntityTypeId : 93,
        CompanyId : auth.companyId(),
        ModifiedBy : auth.employeeId(),
        CreatedBy : auth.employeeId(),
        CreatedDate : date,
        ModifiedDate : date,
        UTCOffset : date.getTimezoneOffset(),
        ModifiedDateUTCOffset : - date.getTimezoneOffset() / 60,
        CreatedDateUTCOffset : - date.getTimezoneOffset() / 60,
        Timestamp : date.getTime(),
        Latitude : 0,
        Longitude : 0,
        Heading : 0,
        MediaTypeId : 3,
        Label : doc.name
      },
      Latitude : 0,
      Longitude : 0,
      Heading : 0,
      CreatedDate : date,
      UTCOffset : date.getTimezoneOffset(),
      Label : doc.name,
      FileType : doc.type
    }
    let fd = new FormData();
    fd.append('Document', doc);
    fd.append('DataObject', JSON.stringify(body));
    let req = new XMLHttpRequest();
    req.open("POST", process.env.API_URL + "/api/document", true);
    req.setRequestHeader("Authorization", "Bearer " + auth.token());
    req.onload = () =>{
      if(req.status == 200){
        return true;
      }
      else {
        this.props.statusTextUpdate("");
        this.props.setAlert("error","There was a problem uploading images for the task.");
      }
    }
    req.onerror = () =>{
      this.props.statusTextUpdate("");
      this.props.setAlert("error","There was a problem uploading images for the task.");
    }
    req.send(fd);
  }

  async uploadImage(image,id){
    let reader = new FileReader();
    reader.onloadend = async () =>{
      let baseString = reader.result;
      baseString = baseString.split(",")[1];
      let date = new Date();
      let body = {
        MultimediaAsset : {
          EntityId : id,
          EntityTypeId : 93,
          CompanyId : auth.companyId(),
          ModifiedBy : auth.employeeId(),
          CreatedBy : auth.employeeId(),
          CreatedDate : date,
          ModifiedDate : date,
          UTCOffset : date.getTimezoneOffset(),
          ModifiedDateUTCOffset : - date.getTimezoneOffset() / 60,
          CreatedDateUTCOffset : - date.getTimezoneOffset() / 60,
          Timestamp : date.getTime(),
          Latitude : 0,
          Longitude : 0,
          Heading : 0,
          MediaTypeId : 0
        },
        Base64Img : baseString
      }
      fetch(process.env.API_URL + "/api/image",{
        method : "POST",
        headers : {
          "Content-Type" : "application/json; charset=utf-8",
          Accept : "application/json",
          Authorization : "Bearer " + auth.token()
        },
        body : JSON.stringify(body)
      })
      .then(r =>{
        if(r.status == 200){
          return true;
        }
        else {
          this.props.statusTextUpdate("");
          this.props.setAlert("error","There was a problem uploading images for the task.");
        }
      }).catch(error =>{
        console.log(error);
        this.props.statusTextUpdate("");
        this.props.setAlert("error","There was a problem uploading images for the task.");
      });
    }
    reader.readAsDataURL(image.file);
  }

  setNewTaskLatLon(lat, lon){
    if(this.props.creatingNewTask){
      this.setState({newTaskLat : lat, newTaskLon : lon, newTaskAddressColor : '#3c5873'});
      document.getElementById("createTaskDiv").style.display = "block";
    }
  }

  getLeakSurveyLinesDefinitionExpression(){
    let fromToUse = "";
    let toToUse = "";
    let toDate = this.props.toDate;
    let fromDate = this.props.fromDate;

    if (toDate != null) { toToUse = toDate.getMonth()+1 + '/' + toDate.getDate() +'/' + toDate.getFullYear(); }
    if (fromDate != null) { fromToUse = fromDate.getMonth()+1 + '/' + fromDate.getDate() +'/' + fromDate.getFullYear(); }

    var toDateUnix = moment(toToUse, "MM/DD/YYYY").unix() * 1000;
    var fromDateUnix = moment(fromToUse, "MM/DD/YYYY").unix() * 1000;
    toDateUnix += 86399000; // add 23:59:59 to EndDateTime to set time to midnight on selected date
      
    // FILTER STATUS & USER
    var filterUserValue = -1;
    if (this.props.filterUserValue != null){
      filterUserValue = this.props.filterUserValue;
    }

    var linesDefinition = "CompanyId = " + auth.companyId() + "";
    linesDefinition += " AND ((GroupStartTime > " + fromDateUnix + " AND GroupStartTime < " + toDateUnix + ") OR (GroupEndTime > " + fromDateUnix + " AND GroupEndTime < " + toDateUnix + "))"
    if (filterUserValue != -1 && filterUserValue != '-1') linesDefinition = linesDefinition + " AND CreatedBy = '" + filterUserValue +"'";
    return linesDefinition;
  }

  getLeakSurveyPointsDefinitionExpression(){
    let fromToUse = "";
    let toToUse = "";
    let toDate = this.props.toDate;
    let fromDate = this.props.fromDate;
    if (toDate != null) { toToUse = toDate.getMonth()+1 + '/' + toDate.getDate() +'/' + toDate.getFullYear(); }
    if (fromDate != null) { fromToUse = fromDate.getMonth()+1 + '/' + fromDate.getDate() +'/' + fromDate.getFullYear(); }
    toToUse += ' 23:59:59'
    // FILTER STATUS & USER
    var filterUserValue = -1;
    if (this.props.filterUserValue != null){
      filterUserValue = this.props.filterUserValue;
    }
    var linesDefinition = "CompanyId = " + auth.companyId() + "";
    linesDefinition += " AND ((LocatedDatetime > '" + fromToUse + "' AND LocatedDatetime < '" + toToUse + "') OR (LocatedDatetime > '" + fromToUse + "' AND LocatedDatetime < '" + toToUse + "'))"
    if (filterUserValue != -1 && filterUserValue != '-1') linesDefinition = linesDefinition + " AND CreatedBy = '" + filterUserValue +"'";

    return linesDefinition;
  }

  getFacetDefinitionExpression(){
    return "NAME LIKE '%"+this.props.searchValue+"%'";
  }

  getAcceleratedFacetsDefinitionExpression(){
    return "NAME LIKE '%" + this.props.searchValue+"%'";
  }

  searchAddress(e, searchType){
    let text = e.target.value;
    if(searchType == "taskSearch"){
      this.setState({addressSearch : text});
    }
    else if(searchType == "addressSearch"){
      this.setState({addressSearchForSearch : text});
    }
    let lat = this.state.currentLat;
    let lon = this.state.currentLon; 
    let searchUrl = this.state.baseArcgisURL + "/suggest?f=JSON&category=Address" + (lat == 0 || lon == 0 ?  "" : "&location=" + lon + "," + lat) +"&text=" + text;
    fetch(searchUrl,
      {
        method: 'GET'
      })
      .then(async resp =>{
        if(resp.status == 200){
          resp.json().then(bodyJson =>{
            if(bodyJson.suggestions){
              this.setState({addressSuggestions : bodyJson.suggestions});
            }
            else{
              this.setState({addressSuggestions : []});
            }
          });
        }
        else{
          this.setState({addressSuggestions : []});
        }
      })
      .catch(e =>{
        console.log(e);
        this.setState({addressSuggestions : []});
      })
  }

  selectSuggestedAddressToZoom(suggestion){
    loadModules(['esri/Graphic'])
    .then(([Graphic]) =>{
      let url = this.state.baseArcgisURL + "findAddressCandidates?F=JSON&magicKey=" + suggestion.magicKey;
      if(this.selectedAddressGraphic){
        this.view.graphics.remove(this.selectedAddressGraphic);
      }
      fetch(url, 
        {
          method : "GET"
        })
        .then(async resp =>{
          if(resp.status == 200){
            resp.json().then(bodyJson =>{
              document.getElementById("addressSearchDiv").style.display = "none"; 
              this.setState({addressSearchForSearch : "", addressSuggestions: [] });
              this.view.center = [bodyJson.candidates[0].location.x,bodyJson.candidates[0].location.y];
              this.view.zoom = 18;
              var markerSymbol = {
                type: "text", // autocasts as new TextSymbol()
                color: "red",
                text:"\ue61d", // esri-icon-map-pin
                font: {
                  size: 24,
                  family: "CalciteWebCoreIcons"
                }
              };
              this.selectedAddressGraphic = new Graphic({ geometry : {type : "point", longitude : bodyJson.candidates[0].location.x, latitude : bodyJson.candidates[0].location.y}, symbol : markerSymbol});
              this.view.graphics.add(this.selectedAddressGraphic);
            });
          }
        });
    });
  }

  selectSuggestedAddress(suggestion){
    let url = this.state.baseArcgisURL + "findAddressCandidates?F=JSON&magicKey=" + suggestion.magicKey;
    fetch(url, 
      {
        method : "GET"
      })
      .then(async resp =>{
        if(resp.status == 200){
          resp.json().then(bodyJson =>{
            this.setState({newTaskAddressColor : '#3c5873',foundAddress : bodyJson.candidates[0].address, addressSearch : bodyJson.candidates[0].address, newTaskLat : bodyJson.candidates[0].location.y, newTaskLon : bodyJson.candidates[0].location.x, addressSuggestions: [] });
          });
        }
      });
  }

  componentDidMount() {
    
    // LEGACY TOKEN
    fetch("https://www.arcgis.com/sharing/rest/oauth2/token?grant_type=client_credentials&client_id=" + process.env.POINTSCLIENTID + "&client_secret="+process.env.POINTSCLIENTSECRET,{
      method: "POST",
      headers : {"Content-Type" : "application/x-www-form-urlencoded"}
    }).then(response =>{
      response.json().then(bodyJson =>{
        this.setState({surveyLinesToken : bodyJson.access_token});
      });
    });

    // LPS PORTAL TOKEN
    fetch("https://www.arcgis.com/sharing/rest/oauth2/token?grant_type=client_credentials&client_id=" + process.env.POINTSCLIENTID_23 + "&client_secret="+process.env.POINTSCLIENTSECRET_23,{
      method: "POST",
      headers : {"Content-Type" : "application/x-www-form-urlencoded"}
    }).then(response =>{
      response.json().then(bodyJson =>{
        this.setState({surveyLinesToken23 : bodyJson.access_token});
      });
    });

    // USER CONFIGURED TOKEN
    if(this.props.ARCGISClientSecret && this.props.ARCGISClientSecret  != '' && this.props.ARCGISClientId && this.props.ARCGISClientId != ''){
      if(this.props.EnterpriseConnection){
        fetch(process.env.API_URL + "/api/arcgis/getcompanyenterprisetoken/" + auth.companyId(),{
          method : "POST",
          headers : {
            "Content-Type" : "application/json; charset=utf-8",
            Accept : "application/json",
            Authorization : "Bearer " + auth.token()
          }
          }).then(response =>{
            response.json().then(bodyJson =>{
              this.setState({arcGisConnectedLayersAccessToken : bodyJson.access_token});
            })
          })
      }
      else{
        fetch("https://www.arcgis.com/sharing/rest/oauth2/token?grant_type=client_credentials&client_id=" + this.props.ARCGISClientId + "&client_secret=" + this.props.ARCGISClientSecret,{
          method : "POST",
          headers : {"Content-Type" : "application/x-www-form-urlencoded"}
        }).then(response =>{
          response.json().then(bodyJson =>{
            this.setState({arcGisConnectedLayersAccessToken : bodyJson.access_token});
          });
        });
      }
    }

    // setTimeout(()=>{
    //   // apply header labels and extra controls
    //   if (document.getElementsByClassName("esri-sketch-header").length == 0 && document.getElementsByClassName("esri-sketch")[0] != undefined){
    //     var header = document.createElement("div");
    //     header.className = "esri-sketch-header";
    //     header.innerHTML = "ASSIGN / AUDIT";
    //     document.getElementsByClassName("esri-sketch")[0].insertBefore(header,document.getElementsByClassName("esri-sketch")[0].childNodes[0]);
    //   }
    // }, 2000);

    window.onclick = e =>{
      let modal = document.getElementById("imageGalleryDiv");
      let taskModal = document.getElementById("createTaskDiv");
      let mapReportModal = document.getElementById("mapReportDiv");
      let addressSearchModal = document.getElementById("addressSearchDiv");
      let mapFeaturesModal = document.getElementById("mapFeaturesDiv");
      if(e.target == mapReportModal){
        mapReportModal.style.display = "none";
        this.setState({mapReportTitle : "", mapReportTitleColor : 'black'});
      }
      if(e.target == taskModal){
        this.props.setCreatingNewTask(false);
        taskModal.style.display = "none";
      }
      if(e.target == modal){
        this.setState({images : [], videos : [], thumbnails : [], videoCount : 0, pictureCount : 0});
        modal.style.display = "none";
      }
      if(e.target == addressSearchModal){
        addressSearchModal.style.display = "none";
      }
      if(e.target == mapFeaturesModal){
        mapFeaturesModal.style.display = "none";
      }
    }

    this.getUserList();
    this.getCustomFormList();
    let me = this;

    window.addEventListener('beforeunload', this.beforeunload.bind(this));
    
    // ESRI MAP SECTION
    loadModules(['esri/Map', 'esri/views/MapView', 'esri/layers/FeatureLayer', 'esri/widgets/LayerList',
    'esri/widgets/Sketch', 'esri/layers/GraphicsLayer', 'esri/geometry/support/webMercatorUtils',
    'dojo/dom-construct', 'esri/geometry/Point', 'esri/geometry/geometryEngine', 'esri/core/watchUtils', 'esri/widgets/Zoom', 'esri/widgets/BasemapGallery','esri/widgets/Expand', 'esri/config', 'esri/portal/Portal', 'esri/widgets/Measurement','esri/layers/KMLLayer','esri/layers/support/Field','esri/Graphic'], { css: true })
    .then(([ArcGISMap, MapView, FeatureLayer, LayerList, Sketch, GraphicsLayer,
      WebMercatorUtils, domConstruct, Point, geometryEngine, watchUtils,Zoom,BasemapGallery,Expand, esriConfig, Portal, Measurement,KMLLayer, Field, Graphic]) => {
      //Put token on basemap requests.can't use esriConfig.apiKey ="" because that will add the key to every request and using api key for feature layers is in beta and will not work yet
      esriConfig.request.interceptors.push({
        urls:["https://golaunchpoint.maps.arcgis.com/","https://ibasemaps-api.arcgis.com/","https://basemaps-api.arcgis.com/"],
        before : function(params){
          params.requestOptions.query = params.requestOptions.query || {};
          params.requestOptions.query.token =  process.env.ESRIAPIKEY;
        }
      });
      esriConfig.request.interceptors.push({
        urls:["https://services8.arcgis.com/3UTMQWLH7xp6vpCH/arcgis/rest/services/SiteRight_TrailLines_DEV/FeatureServer", 
              "https://services8.arcgis.com/3UTMQWLH7xp6vpCH/arcgis/rest/services/SiteRight_TrailPoints_DEV/FeatureServer",
              "https://services8.arcgis.com/3UTMQWLH7xp6vpCH/arcgis/rest/services/SiteRight_TrailLines/FeatureServer",
              "https://services8.arcgis.com/3UTMQWLH7xp6vpCH/arcgis/rest/services/SiteRight_TrailPoints/FeatureServer",
              "https://services8.arcgis.com/3UTMQWLH7xp6vpCH/arcgis/rest/services/LeakSurveyLines/FeatureServer",
              "https://services8.arcgis.com/3UTMQWLH7xp6vpCH/arcgis/rest/services/LeakSurveyLines_DEV/FeatureServer"],
        before : function(params){
          params.requestOptions.query = params.requestOptions.query || {};
          params.requestOptions.query.token = me.state.surveyLinesToken
        }
      });
      esriConfig.request.interceptors.push({
        urls:["https://services7.arcgis.com/M3wEDELOmhovdh6X/arcgis/rest/services/Trail/FeatureServer",
              "https://services7.arcgis.com/M3wEDELOmhovdh6X/arcgis/rest/services/Trail/FeatureServer/0/query",
              "https://services7.arcgis.com/M3wEDELOmhovdh6X/arcgis/rest/services/Trail/FeatureServer/1/query",
              "https://services7.arcgis.com/M3wEDELOmhovdh6X/arcgis/rest/services/Trail/FeatureServer/2/query",
              "https://services7.arcgis.com/M3wEDELOmhovdh6X/arcgis/rest/services/Trail/FeatureServer/3/query",],
        before : function(params){
          params.requestOptions.query = params.requestOptions.query || {};
          params.requestOptions.query.token = me.state.surveyLinesToken23
        }
      });
      esriConfig.request.interceptors.push({
        urls:["https://services8.arcgis.com/3UTMQWLH7xp6vpCH/arcgis/rest/services/LeakSurveyLines_DEV/FeatureServer/0/query",
              "https://services8.arcgis.com/3UTMQWLH7xp6vpCH/arcgis/rest/services/LeakSurveyLines/FeatureServer/0/query",
              "https://services8.arcgis.com/3UTMQWLH7xp6vpCH/arcgis/rest/services/LeakSurveyLines_DEV/FeatureServer/0/query",
              "https://services8.arcgis.com/3UTMQWLH7xp6vpCH/arcgis/rest/services/LeakSurveyLines/FeatureServer/0/query", ],
        before : function(params){
          params.requestOptions.query = params.requestOptions.query || {};
          params.requestOptions.query.where = params.requestOptions.query.where != "[object Object]" ? params.requestOptions.query.where : me.getLeakSurveyLinesDefinitionExpression()
        }
      });
      if(this.props.CompanyArcGisLayers.length > 0){
        esriConfig.request.interceptors.push({
          urls:this.props.CompanyArcGisLayers.map((layer) =>layer.Url),
          before: function(params){
            params.requestOptions.query = params.requestOptions.query || {};
            params.requestOptions.query.token =  me.state.arcGisConnectedLayersAccessToken;
          }
        });
      }
      esriConfig.portalUrl = 'https://golaunchpoint.maps.arcgis.com/';
      const portal = new Portal();
      portal.load()
      
      .then(() =>{
      
      // LOCAL REF
      me.geometryEngine = geometryEngine;
      let lastBasemap = localStorage.getItem("basemap");
      const map = new ArcGISMap({ basemap: lastBasemap && lastBasemap != "" ? lastBasemap : "arcgis-light-gray"});
      this.map = map;
      const view = new MapView({container: this.mapRef.current, popup:{defaultPopupTemplateEnabled : true}, map: map, center: [-98,40], zoom: 15, ui: {components: []}}); 
      this.view = view;
      
      // BASEMAP
      //const basemapToggle = new BasemapToggle({ view: this.view, nextBasemap: "hybrid"});
      const basemapGallery = new BasemapGallery({ view : this.view});
      basemapGallery.watch('activeBasemap',function(newValue){
        var newBasemap = "";
        switch(newValue.title){
          case "Imagery":
            newBasemap = 'arcgis-imagery-standard';
            break;
          case "Imagery Hybrid":
            newBasemap = "arcgis-imagery"
            break;
          case "Streets":
              newBasemap = "arcgis-streets"
              break;
          case "Topographic":
            newBasemap = "arcgis-topographic"
            break;
          case "Navigation":
            newBasemap = "arcgis-navigation"
            break;
          case "Streets (Night)":
            newBasemap = "arcgis-streets-night"
            break;
          case "Terrain with Labels":
            newBasemap = "arcgis-terrain"
            break;
          case "Light Gray Canvas":
            newBasemap = "arcgis-light-gray"
            break;
          case "Dark Gray Canvas":
            newBasemap = "arcgis-dark-gray"
            break;
          case "Oceans":
            newBasemap = "arcgis-oceans"
            break;
          // case "National Geographic Style Map":
          //   newBasemap = ""
          //   break;
          case "OpenStreetMap":
            newBasemap = "osm-standard"
            break;
          case "Charted Territory Map":
            newBasemap = "arcgis-charted-territory"
            break;
          case "Community Map":
            newBasemap = "arcgis-community"
            break;
          case "Navigation (Dark Mode)":
            newBasemap = "arcgis-navigation-night"
            break;
          case "Newspaper Map":
            newBasemap = "arcgis-newspaper"
            break;
          // case "Human Geography Map":
          //   newBasemap = "17ffb944733-basemap-23"
          //   break;
          // case "Human Geography Dark Map":
          //   newBasemap = "osm-dark-gray"
          //   break;
          case "Modern Antique Map":
            newBasemap = "arcgis-modern-antique"
            break;
          case "Mid-Century Map":
            newBasemap = "arcgis-midcentury"
            break;
          case "Nova Map":
            newBasemap = "arcgis-nova"
            break;
          case "Colored Pencil Map":
            newBasemap = "arcgis-colored-pencil"
            break;
          // case "Outline Map":
          //   newBasemap = "arcgis-colored-pencil"
          //   break;
          // case "FireFly Imagery Hybrid":
          //   newBasemap = "arcgis-colored-pencil"
          //   break;
          // case "NAIP Imagery Hybrid":
          //   newBasemap = "arcgis-colored-pencil"
          //   break;
          // case "USGS National Map":
          //   newBasemap = "arcgis-colored-pencil"
          //   break;
          // case "USA Topo Maps":
          //   newBasemap = "arcgis-colored-pencil"
          //   break;
          // case "OpenStreetMap (Blueprint)":
          //   newBasemap = "arcgis-colored-pencil"
          //   break;
          default:
            console.log(newValue.title);
        }
        localStorage.setItem("basemap",newBasemap);
      });
      const bgExpand = new Expand({
        view : this.view,
        content : basemapGallery
      });
      this.view.ui.add(bgExpand, {position : "bottom-left"});
      //this.view.ui.add(basemapToggle, { position: "bottom-left" });
      var zoom = new Zoom({ view : this.view, layout: "vertical"});
      this.view.ui.add(zoom, { position : 'bottom-right'});
      view.on("click", function(event){
        me.setNewTaskLatLon(event.mapPoint.latitude, event.mapPoint.longitude);
      });
      const measurement = new Measurement();
      measurement.view = view;
      view.ui.add(measurement, "bottom-right");
      var distanceButton = document.createElement("button");
      var areaButton = document.createElement("button");
      var clearButton = document.createElement("button");
      areaButton.className = "esri-widget--button esri-interactive esri-icon-measure-area";
      distanceButton.className = 'esri-widget--button esri-interactive esri-icon-measure-line';
      clearButton.className = "esri-widget--button esri-interactive esri-icon-trash";
      view.ui.add(distanceButton, 'bottom-left');
      distanceButton.addEventListener("click", ()=>{
        distanceButton.classList.add("active");
        measurement.activeTool = "distance";
        areaButton.classList.remove("active");
      });
      view.ui.add(areaButton, "bottom-left");
      areaButton.addEventListener("click", ()=>{
        areaButton.classList.add("active");
        measurement.activeTool = "area";
        distanceButton.classList.remove("active");
      });
      view.ui.add(clearButton, "bottom-left");
      clearButton.addEventListener("click", ()=>{
        distanceButton.classList.remove("active");
        areaButton.classList.remove("active");
        measurement.clear();
      });
      var addressBtn = document.createElement("button");
      addressBtn.innerText = "Search Address";
      //view.ui.add(addressBtn, "top-right");
      addressBtn.addEventListener("click", ()=>{
        document.getElementById("addressSearchDiv").style.display = 'block';
      });

      // var btn = document.createElement('button');
      // btn.innerText = "Map Report";
      // view.ui.add(btn, 'top-right');
      // btn.addEventListener('click',() =>{
      //   document.getElementById("mapReportDiv").style.display = 'block';
      // });

      var mapFeaturesButton = document.createElement("button");
      mapFeaturesButton.innerText = "MAP MENU";
      mapFeaturesButton.className = "mapActionButton"
      view.ui.add(mapFeaturesButton, "top-right");
      mapFeaturesButton.addEventListener('click', ()=>{
        document.getElementById("mapFeaturesDiv").style.display = 'block';
      });

      var layerLeakSurveyTracking;
      var layerLeakSurveyTrackingPoint;
      var legacyLeakSurveyPoints;
      var legacyLeakSurveyLines;

      // FollowMe Lines
      layerLeakSurveyTracking = new FeatureLayer({title: 'Survey Lines', 
      url: process.env.SURVEY_LINES_URL_23,
      definitionExpression: this.getLeakSurveyLinesDefinitionExpression(),
      popupTemplate: { title: "Survey Line", content: this.getSurveyLinePopupContent.bind(this), outFields: ["*"] }});
      map.layers.add(layerLeakSurveyTracking);
      this.layerLeakSurveyTracking = layerLeakSurveyTracking;

      // FollowMe Points
      layerLeakSurveyTrackingPoint = new FeatureLayer({title: 'Survey Points', 
      url: process.env.SURVEY_POINTS_URL_23,
      definitionExpression: this.getLeakSurveyPointsDefinitionExpression(), 
      popupTemplate: { title: "Survey Point", content: this.getSurveyPointPopupContent.bind(this), outFields: ["*"] }});
      map.layers.add(layerLeakSurveyTrackingPoint);
      this.layerLeakSurveyTrackingPoint = layerLeakSurveyTrackingPoint;
      // DYNAMIC DATA LAYERS
      this.layerDictionary = new Object();
      if(this.props.CompanyArcGisLayers.length > 0 && this.state.arcGisConnectedLayersAccessToken){
        this.props.CompanyArcGisLayers.forEach(companyLayer =>{
          var layer = new FeatureLayer({title: companyLayer.Name,url:companyLayer.Url, popupTemplate:{title: companyLayer.Name, content: me.getAutoAttributePopupContent, outFields : ["*"]}});
          map.layers.add(layer);
        })
      }
      if(this.props.CompanyKMLSHAPELayers.length > 0){
        this.props.CompanyKMLSHAPELayers.forEach(companyLayer =>{
          if(companyLayer.FileType == "kml"){
            var layer = new KMLLayer({title: companyLayer.Name, url:process.env.API_URL+"/api/GetKMLShapeLayerFile/" + companyLayer.CompanyKMLSHAPELayerId + "/" + companyLayer.SecureCode, popupTemplate:{title: companyLayer.Name, content: me.getAutoAttributePopupContent, outFields : ["*"]}});
            map.layers.add(layer);
          }
          if(companyLayer.FileType == "shape"){
            let sourceGraphics = [];
            let layers = companyLayer.shapeJSON.layers.map((layer) =>{
              let graphics = layer.featureSet.features.map((feature)=>{
                return Graphic.fromJSON(feature);
              });
              sourceGraphics = sourceGraphics.concat(graphics);
              let featureLayer = new FeatureLayer({
                objectIdField: "FID",
                source: graphics,
                title : layer.layerDefinition.name,
                fields: layer.layerDefinition.fields.map((field) => {
                  return Field.fromJSON(field);
              })
              });
              return featureLayer;
            });
            map.addMany(layers);
          }
        });
      }

      // HISTORIC SURVEY POINT AND TRAIL LAYERS (Xcel, Blackhills, Narvaro)
      if (auth.companyId() == -1 || auth.companyId() == 21 || auth.companyId() == 102 || auth.companyId() == 95){
        legacyLeakSurveyPoints = new FeatureLayer({title: 'Survey Points (2022)', 
          url : this.state.legacySurveyPoints,
          definitionExpression: this.getLeakSurveyPointsDefinitionExpression(),
        });
        legacyLeakSurveyLines = new FeatureLayer({title: 'Survey Lines (2022)',
          url : this.state.legacySurveyLines,
          definitionExpression: this.getLeakSurveyLinesDefinitionExpression(),
       });
       
       map.layers.add(legacyLeakSurveyPoints);
       map.layers.add(legacyLeakSurveyLines);
       this.legacyLeakSurveyPoints = legacyLeakSurveyPoints;
       this.legacyLeakSurveyLines = legacyLeakSurveyLines;
      }

      // MAP LAYERS
      var layerAssignmentAreas = new GraphicsLayer({title: 'Assignment Areas', listMode: 'hide', id : "assignmentLayer"});
      map.layers.add(layerAssignmentAreas);
      var layerSurveyType16 = new GraphicsLayer({title: 'Leak Survey', listMode: 'hide',visible:false});
      var layerSurveyType15 = new GraphicsLayer({title: 'AOC Survey', listMode: 'hide',visible:false});
      var layerSurveyType22 = new GraphicsLayer({title: 'Meter - Outside', listMode: 'hide',visible:false});
      var layerSurveyType14 = new GraphicsLayer({title: 'Inaccessible Survey', listMode: 'hide',visible:false});
      var layerSurveyType23 = new GraphicsLayer({title: 'Survey Data', listMode: 'hide',visible:false});
      var layerSurveyType24 = new GraphicsLayer({title: 'Meter - Inside', listMode: 'hide',visible:false});

      // LAYER DICTIONARY
      this.layerDictionary[14] = {layer:layerSurveyType14};
      this.layerDictionary[15] = {layer:layerSurveyType15};
      this.layerDictionary[22] = {layer:layerSurveyType22};
      this.layerDictionary[16] = {layer:layerSurveyType16};
      this.layerDictionary[17] = {layer:layerAssignmentAreas};
      this.layerDictionary[23] = {layer:layerSurveyType23};
      this.layerDictionary[24] = {layer:layerSurveyType24};
      this.layerDictionary["assignment"] = {layer: layerAssignmentAreas};
      // this.layerDictionary["legacyLeakSurveyLines"] = {layer: legacyLeakSurveyLines};
      // this.layerDictionary["layerLeakSurveyTracking"] = {layer: layerLeakSurveyTracking};
      // this.layerDictionary["layerLeakSurveyTrackingPoint"] = {layer: layerLeakSurveyTrackingPoint};
      map.layers.add(layerSurveyType16);
      map.layers.add(layerSurveyType14);
      map.layers.add(layerSurveyType15);
      map.layers.add(layerSurveyType22);
      map.layers.add(layerSurveyType24);
      //map.layers.add(layerSurveyType23);

      // SKETCH LAYER
      var sketchLayer = new GraphicsLayer({title: 'Graphics', listMode: 'hide'});
      map.layers.add(sketchLayer);
      var sketch = new Sketch({ layer: sketchLayer, view: view, id:'esri-sketch-leaksurvey', selectionTools :{"lasso-selection" : false, "rectangle-selection" : false},snappingOptions: {enabled: true, featureSources: [{layer: sketchLayer, enabled : true},{layer: layerAssignmentAreas, enabled : true}]} })
      sketch.on("create", function(event) {

        if (event.state === "complete") {
          var delAction = { title: "Erase", id: "del", className: "esri-icon-erase" };
          var chooseAction = { title: "Select Action", id: "chooseAction", className: "esri-icon-directions" }; 
          var geoGeometry = WebMercatorUtils.webMercatorToGeographic(event.graphic.geometry);
          GLOBAL.ASSIGNMENT.geometry = geoGeometry;
          GLOBAL.ASSIGNMENT.graphic = event.graphic;

          event.graphic.attributes = {
            name: "NEW ASSIGNMENT AREA",
            type: event.graphic.geometry.type,
            geoGeometry: geoGeometry
          };

          event.graphic.popupTemplate = {
            title: "{name}",
            content: setContentInfo,
            actions: [chooseAction, delAction],
          };

          // OPEN ASSIGNMENT
          this.view.popup.open({location: event.graphic.geometry.centroid,fetchFeatures: true});
        }
      });

      sketch.on("update", function(event) {
        if (event.state == "complete" || event.state == "cancel") {
          var geoGeometry = WebMercatorUtils.webMercatorToGeographic(event.graphics[0].geometry);
          GLOBAL.ASSIGNMENT.geometry = geoGeometry;
        }
      });

      sketch.on("delete", function(event) {
        if (GLOBAL.ASSIGNMENT.graphic != undefined) { 
          sketchLayer.remove(event.graphics[0]);
          GLOBAL.ASSIGNMENT.graphic = null;
          GLOBAL.ASSIGNMENT.geometry = null;
        }
      });

      sketch.availableCreateTools = ["polygon"];
      this.view.ui.add(sketch, { position: "top-right" });
      this.view.popup.on("trigger-action", function(event) {
        if(event.action.id === "assign"){ assignUser(me, {target : this.view.popup}); } 
        if(event.action.id === "assignTask"){ assignTask(me, {target : this.view.popup}); } 
        if(event.action.id === "del"){ deleteAssignment(me, {target : this.view.popup}); } 
        if(event.action.id == "viewDetails"){ viewDetails(me, {target : this.view.popup});}
        if(event.action.id == "chooseAction"){ chooseAction(me, {target : this.view.popup});}
        if(event.action.id == "viewLarger"){viewLarger(me,{target : this.view.popup});}
        if(event.action.id == "viewVideo"){viewVideo(me,{target : this.view.popup});}
        if(event.action.id == "archive"){archiveSurvey(me,{target : this.view.popup});}
        if(event.action.id == "viewMultimedia"){viewMultimedia(me,{target : this.view.popup});}
      });

      // CLUSTER LAYER CONFIG must add refs to map for update event access
      view.layerDictionary = this.layerDictionary;
      view.legendStyle = this.legendStyle;
      view.markerStyle = this.markerStyle;
      view.mapLayerType = this.state.mapLayerType;

      // Display the loading indicator when the view is updating
      watchUtils.whenTrue(view, "updating", function() {
        //document.getElementById("loading").style.display = 'unset';
      });

      // Hide the loading indicator when the view stops updating
      watchUtils.whenFalse(view, "updating", function() {
        //document.getElementById("loading").style.display = 'none';
      });
      
      // MAP CLICK EVENT
      view.on("click", function(event) { view.hitTest(event).then(getGraphics);});
      function getGraphics(e){
        e.results.forEach(g => {
          if (g.graphic.EntityTypeId || (g.graphic.attributes.clusterId && !g.graphic.EntityTypeId)){
            view.cluster_expandCluster(g.graphic, view);
          }
        });
      }

      // VIEW UPDATE EVENT cluster layer update zoom end
      watchUtils.when(view, "stationary", function() {
        try{
          if (this.extent){
            if (this.cluster_clusterGraphics) {
              
              // clear existing cluster graphics
              this._clusters.length = 0;
              if (this.surveyGraphics) { 
                this.mapLayerType.forEach(graphicLayer => {
                  if(this.surveyGraphics[graphicLayer])
                    this.surveyGraphics[graphicLayer].length = 0;
                });              
              }

              // update extent and generate new clusters
              this.extentGeo = WebMercatorUtils.webMercatorToGeographic(this.extent);
              this.cluster_clusterGraphics(this);
            }
          }
        }
        catch(e){
          console.log('watchUtils_exception', e);
        }
      });

      function archiveSurvey(context, event){
        let attr = event.target.selectedFeature.attributes;
        me.archiveSurvey(attr, view.popup);
      }

      function deleteAssignment(context, event) {
        var leakSurveyAssignmentId = -1;
        if (event.target.selectedFeature.attributes.name != "NEW ASSIGNMENT AREA") { 
          leakSurveyAssignmentId = event.target.selectedFeature.attributes.LeakSurveyAssignmentId; 
        }

        me.updateAssignmentArea(null, null, leakSurveyAssignmentId, true);
        view.popup.close();
      }

      function assignUser(context, event) { 
        var assignmentSelect = document.getElementById("assignmentSelect");
        var assignmentId = assignmentSelect.value;
        var username = assignmentSelect.options[assignmentSelect.selectedIndex].innerText;
        var leakSurveyAssignmentId = -1;

        if (event.target.selectedFeature.attributes.name != "NEW ASSIGNMENT AREA") { 
          leakSurveyAssignmentId = event.target.selectedFeature.attributes.LeakSurveyAssignmentId; 
        }

        me.updateAssignmentArea(assignmentId, username, leakSurveyAssignmentId);
        view.popup.close();
      }

      function assignAllSelectedTasks(context, event){
        var assignmentSelect = document.getElementById("assignmentSelect");

        var assignmentId = assignmentSelect.value;
        var username = assignmentSelect.options[assignmentSelect.selectedIndex].innerText;
        var leakSurveyIdsToAssign = []; 
        view.map.layers._items.forEach((layer)=>{
          if(layer.graphics && (layer.title != null && layer.title != "Survey Lines" && layer.title != "Main Accelerated" && layer.title != "Service Accelerated" && layer.title != "Graphics" &&
          layer.title != "Accelerated Facet" && layer.title != "WHOLE MAP" && layer.title != "MAIN" && layer.title != "SERVICE" && layer.title != "LGE DISTRIBUTION" && layer.title != "LGE SERVICE"
          && layer.title != "METER" && layer.title != "RISER" && layer.title != "VALVE" && layer.title != "TEE" && layer.title != "Assignment Areas") && layer.visible){
            layer.graphics.items.forEach((item)=>{
              if((item.attributes.AssignedUserId != assignmentId) && event.target.selectedFeature.attributes.geoGeometry.contains(new Point(item.attributes.Longitude, item.attributes.Latitude))){
                leakSurveyIdsToAssign.push(item.attributes.FormData ? {LeakSurveyId : item.attributes.FormData.FormDataId, CustomForm : true} : {LeakSurveyId : item.attributes.LeakSurveyId, CustomForm : false});
              }
            });
          }
        });
        
        if(leakSurveyIdsToAssign.length == 0) alert('All selected tasks are already assigned to this user.');
        else{
          me.assignTaskCollection(assignmentId, username, leakSurveyIdsToAssign);
          view.popup.close();
        } 
      }

      function unassignAllSelectedTasks(context, event){
        var assignmentSelect = document.getElementById("assignmentSelect");

        var assignmentId = "-1";
        var username = assignmentSelect.options[assignmentSelect.selectedIndex].innerText;
        var leakSurveyIdsToAssign = []; 
        view.map.layers._items.forEach((layer)=>{
          if(layer.graphics && (layer.title != null && layer.title != "Survey Lines" && layer.title != "Main Accelerated" && layer.title != "Service Accelerated" && layer.title != "Graphics" &&
          layer.title != "Accelerated Facet" && layer.title != "WHOLE MAP" && layer.title != "MAIN" && layer.title != "SERVICE" && layer.title != "LGE DISTRIBUTION" && layer.title != "LGE SERVICE"
          && layer.title != "METER" && layer.title != "RISER" && layer.title != "VALVE" && layer.title != "TEE" && layer.title != "Assignment Areas") && layer.visible){
            layer.graphics.items.forEach((item)=>{
              if(item.attributes.AssignedUserId != null && item.attributes.AssignedUserId != "-1" && event.target.selectedFeature.attributes.geoGeometry.contains(new Point(item.attributes.Longitude, item.attributes.Latitude))){
                leakSurveyIdsToAssign.push(item.attributes.FormData ? {LeakSurveyId : item.attributes.FormData.FormDataId, CustomForm : true} : {LeakSurveyId : item.attributes.LeakSurveyId, CustomForm : false});
              }
            });
          }
        });
        
        if(leakSurveyIdsToAssign.length == 0) alert('all selected tasks are already unassigned.')
        else{
          me.assignTaskCollection(assignmentId, username, leakSurveyIdsToAssign);
          view.popup.close();
        } 
      }

      function assignTask(context, event) { 
        var assignmentSelect = document.getElementById("assignmentSelect");
        var assignmentId = assignmentSelect.value;
        var username = assignmentSelect.options[assignmentSelect.selectedIndex].innerText;
        var customLeakSurvey = event.target.selectedFeature.attributes.FormData != null && event.target.selectedFeature.attributes.FormData != undefined;
        var leakSurveyId = customLeakSurvey ? event.target.selectedFeature.attributes.FormData.FormDataId : event.target.selectedFeature.attributes.LeakSurveyId; 
        me.assignTask(assignmentId, username, leakSurveyId, customLeakSurvey);
        view.popup.close();
      }

      function viewDetails(context, event){
        if(event.target.selectedFeature.attributes.FormData){
          let customForm = event.target.selectedFeature.attributes;
          customForm.EntityTypeId = 23;
          me.viewEntity(customForm,'form');
        }
        else{
          me.viewEntity(event.target.selectedFeature.attributes,'leak');
        }
      }
      function viewMultimedia(context, event){
        me.setState({loadingMultimedia : true});
        let attr = event.target.selectedFeature.attributes;
        let entityType = attr.CustomData ? 93 : attr.LeakSurveyTypeId;
        let id = entityType == 93 ? attr.FormData.FormDataId : attr.LeakSurveyId;
        fetch(process.env.API_URL + "/api/entityImages/" + id + "/" + entityType + "/web",{
          method : "POST",
          headers : {"Content-Type" : "application/json", Authorization : "Bearer " + auth.token()}
        })
        .then(r =>{
          if(r.status == 200){
            r.json().then(bodyJson =>{
              if(bodyJson.length > 0){
                let response = JSON.parse(bodyJson);
                response.MultimediaAssets.sort((a,b) => (a.timeStamp > b.timeStamp) ? 1 : -1);
                let pictures = response.MultimediaAssets.filter(x => x.MediaTypeId == null || x.MediaTypeId == 0);
                let thumbnails = response.MultimediaAssets.filter(x => x.MediaTypeId == 2);
                let images = [];
                let thumbs = [];
                pictures.forEach(asset =>{
                  let srcUrl = process.env.API_URL + "/api/image/" + asset.MultimediaAssetId;
                  images.push({original : srcUrl, thumbnail : srcUrl});
                });
                thumbnails.forEach(thumb =>{
                  let srcUrl = process.env.API_URL + "/api/image/" + thumb.MultimediaAssetId;
                  thumbs.push({original : srcUrl, thumbnail : srcUrl, videoId : thumb.CompareMultimediaAssetId});
                });
                me.setState({images, thumbnails : thumbs, videoCount : thumbs.length, pictureCount : images.length, loadingMultimedia : false})
              }
            });
          }
        });
        document.getElementById("imageGalleryDiv").style.display = 'block';
      }

      function viewLarger(context, event){
        let attr = event.target.selectedFeature.attributes;
        let srcUrl = process.env.API_URL + "/api/image/" + attr.MultimediaAssetId;
        me.setState({currentFullscreenImage : [{original : srcUrl, thumbnail : srcUrl}]}, () => {me._imageGallery.fullScreen();});
      }

      function viewVideo(context,event){
        let attr = event.target.selectedFeature.attributes;
        let videosrcUrl = process.env.API_URL + "/api/video/" + attr.CompareMultimediaAssetId;
        let imageDiv = document.getElementById('image' + attr.MultimediaAssetId);
        imageDiv.innerHTML = "<video loop controls src='" + videosrcUrl + "' style='margin-top : 5px; object-fit: cover; width: 99%; height: 250px;' type='video/mp4'/>";
      }

      function chooseAction(context,event){
        var actionSelect = document.getElementById("assignmentSelect");
        var actionId = actionSelect != null ?  actionSelect.value : 0;
        var title = document.getElementsByClassName("esri-popup__header-title")[0];
        var label = document.getElementsByClassName("assignmentLabel")[0];
        var icon = document.getElementsByClassName("esri-icon-directions")[0];
        var buttonText = document.getElementsByClassName("esri-popup__action-text")[0];
        if(actionId == 'assign'){
          title.innerHTML = "NEW ASSIGNMENT AREA";
          label.innerHTML = "Assign To: ";
          var action = document.getElementById("assignmentSelect");
          action.innerHTML = me.state.userOptionList;
          icon.className = "esri-popup__icon esri-icon-user";
          buttonText.innerHTML = 'Assign';
        }
        else if(actionId == "audit"){
          title.innerHTML = "CREATE AUDIT";
          var content = document.getElementsByClassName("esri-feature__main-container")[0];
          icon.className = "esri-popup__icon esri-icon-authorize";
          buttonText.innerHTML = 'Create Audit';
          content.innerHTML = "<div class='assignmentDiv'><div class='assignmentLabel'>Type of Audit: </div><select id='auditType' class='assignmentSelect'><option></option><option value='initial'>Initial Audit</option><option value='100'>100 Audit</option><option value='documentation'>Documentation Audit</option><option value='field'>Field Audit</option></select></div><div class='assignmentDiv'><div class='assignmentLabel'>Technician: </div><select style='margin-left: 18px;' id='technician'class='assignmentSelect'>"+
           me.state.userOptionList +
           "</select></div>"+"<div class='assignmentDiv'><div class ='assignmentLabel'>Pass/Fail: </div><select id='passfail' style='margin-left: 30px'class='assignmentSelect'><option></option><option value='Pass'>Pass</option><option value='Fail'>Fail</option></select></div>"+
           "<div class='assignmentDiv'><div class='assignmentLabel'>Comments: </div><input id='auditComments' style='height:25px;margin-left:14px;max-width:200px;' type='text' class='form-control searchInput'/></div></div>";
        }
        else if(actionId == 'assignAllTasks'){
          title.innerHTML = "ASSIGN ALL TASKS";
          label.innerHTML = "Assign To: ";
          action = document.getElementById("assignmentSelect");
          action.innerHTML = me.state.userOptionList;
          icon.className = "esri-popup__icon esri-icon-user";
          buttonText.innerHTML = 'Assign All';
        }
        else if(actionId == 'unassignAllTasks'){
          unassignAllSelectedTasks(context, event);
        }
        else if(actionId != "-1"){
          if(buttonText.innerHTML == 'Assign'){
            assignUser(context,event);
          }        
          else if(buttonText.innerHTML == 'Assign All'){
            assignAllSelectedTasks(context, event);
          }
          else if(buttonText.innerHTML == 'Create Audit'){
            var tech = document.getElementById('technician').value;
            var passed = document.getElementById('passfail').value;
            var comments = document.getElementById('auditComments').value;
            var auditType = document.getElementById('auditType').value;
            me.createAudit(tech,comments,passed,auditType,-1, view);
          }
        }
      }

      // CREATE NEW ASSIGNMENT AREA - POPUP CONTENT
      function setContentInfo(){
        var node = domConstruct.create("div", { innerHTML: 
          "<div class='assignmentDiv'><div class='assignmentLabel'>Choose Action:</div>" +
          "<select id='assignmentSelect' class='assignmentSelect' >" + me.state.actionList + "</select></div>"
        });
        return node;
      }

      

      // LAYER LIST
      var layerList = new LayerList({ view: this.view, selectionEnabled: false, listItemCreatedFunction: this.defineLayerListActions.bind(this)});
      this.view.ui.add(layerList, { position: "top-right" });
      this.setState({map: map, view:view, layerAssignmentAreas: layerAssignmentAreas, sketchLayer: sketchLayer});
    
    
    })
    .catch((error) =>{
      console.log(error);
    });
    });

    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition((position) =>{
        this.setState({currentLat : position.coords.latitude, currentLon : position.coords.longitude});
      },() =>{
        this.setState({currentLon : 0, currentLat : 0});
      });
    }
    else{
      this.setState({currentLat : 0, currentLon : 0});
    }
  }

  defineLayerListActions(event) {
    var item = event.item;
    if(!item.parent){
      if(!this.uniqueListViewItems.includes(item.title)){
        item.visible = this.getCachedLayerVisibility(item.title);
        this.uniqueListViewItems.push(item.title);
        item.watch("visible", (visible)=>this.setCachedLayerVisibility(item.title, visible));
      }
      
    }
  }

  setCachedLayerVisibility(title, visible){
    
    let button; 
    switch(title){
      case 'Leak Survey':
        button = document.getElementById("leakBlock");
        break;
      case 'AOC Survey':
        button = document.getElementById('aocBlock');
        break;
      case 'Inaccessible Survey':
        button = document.getElementById('inaccessBlock');
        break;
      case 'Meter - Outside':
        button = document.getElementById('meterBlock');
        break;
      case 'Meter - Inside':
        button = document.getElementById('meterInsideBlock');
        break;
      case 'Survey Data':
        button = document.getElementById('pipelineBlock');
        break;
      default:
        break;
    }

    if(button && button.classList.contains('countBlockDisabled') && visible){
      button.classList.remove('countBlockDisabled');
    }
    else if(button && !button.classList.contains('countBlockDisabled') && !visible){
      button.classList.add('countBlockDisabled');
    }

    if(this.props.disableType){
      this.props.disableType(title,visible);
    }
    if(title == "Assignment Areas"){
      sessionStorage.setItem("AssignmentAreasLayerVisibility", visible);
    }
    if(title == "Gas lines"){
      sessionStorage.setItem("GasLinesLayerVisibility", visible);
    }
    if(title == "Survey Points"){
      sessionStorage.setItem("SurveyPointsLayerVisibility", visible);
    }
    if(title == "Survey Lines"){
      sessionStorage.setItem("SurveyLinesLayerVisibility", visible);
    }
    if(title == "Leak Survey"){
      sessionStorage.setItem("LeakSurveyLayerVisibility", visible);
    }
    if(title == "Inaccessible Survey"){
      sessionStorage.setItem("InaccessibleSurveyLayerVisibility", visible);
    }
    if(title == "AOC Survey"){
      sessionStorage.setItem("AOCSurveyLayerVisibility", visible);
    }
    if(title == "Meter - Outside"){
      sessionStorage.setItem("MeterInspectionSurveyLayerVisibility", visible);
    }
    if(title == 'Meter - Inside'){
      sessionStorage.setItem("MeterInsideLayerVisibility", visible);
    }
    if(title == "Survey Data"){
      sessionStorage.setItem("PipelineSurveyLayerVisibility", visible);
    }
  }

  getCachedLayerVisibility(title){
    let valueFromStorage = null;
    if(title == "Assignment Areas"){
      valueFromStorage = sessionStorage.getItem("AssignmentAreasLayerVisibility");
    }
    if(title == "Gas lines"){
      valueFromStorage = sessionStorage.getItem("GasLinesLayerVisibility");
    }
    if(title == "Survey Points"){
      valueFromStorage = sessionStorage.getItem("SurveyPointsLayerVisibility");
    }
    if(title == "Survey Lines"){
      valueFromStorage = sessionStorage.getItem("SurveyLinesLayerVisibility");
    }
    if(title == "Leak Survey"){
      valueFromStorage = sessionStorage.getItem("LeakSurveyLayerVisibility");
    }
    if(title == "Inaccessible Survey"){
      valueFromStorage = sessionStorage.getItem("InaccessibleSurveyLayerVisibility");
    }
    if(title == "AOC Survey"){
      valueFromStorage = sessionStorage.getItem("AOCSurveyLayerVisibility");
    }
    if(title == "Meter - Outside"){
      valueFromStorage = sessionStorage.getItem("MeterInspectionSurveyLayerVisibility");
    }
    if(title == "Meter - Inside"){
      valueFromStorage = sessionStorage.getItem("MeterInsideLayerVisibility");
    }
    if(title == "Survey Data"){
      valueFromStorage = sessionStorage.getItem("PipelineSurveyLayerVisibility");
    }
    return valueFromStorage == null || valueFromStorage == 'undefined' || valueFromStorage == 'true';
  }

  openDetailsView(){
    this.setState({viewingDetails : !this.state.viewingDetails});
  }

  viewEntity(element, type){
    console.log(element);
    if(type == "form"){
      this.setState({viewingDetails : true, entityToView: {FormData : JSON.parse(JSON.stringify(element.FormData))}});
    }
    else if (type == "leak"){
      this.setState({viewingDetails : true, entityToView: element});
    }
  }
  
  componentDidUpdate(prevProps, prevState){ 
    let multiMediaUpdate = false
    multiMediaUpdate = prevState.videoIndex != this.state.videoIndex || prevState.loadingVideos != this.state.loadingVideos || prevState.loadingMultimedia != this.state.loadingMultimedia || prevState.images != this.state.images || prevState.videos != this.state.videos || prevState.thumbnails != this.state.thumbnails || prevState.videoCount != this.state.videoCount || prevState.pictureCount != this.state.pictureCount;
    var facetsLayer = this.state.facetsLayer;
    var facetsAllLayer = this.state.facetsAllLayer;
    if(this.props.refreshFacetLayer){
      if(facetsLayer) facetsLayer.definitionExpression = this.getFacetDefinitionExpression();
      if(facetsAllLayer) facetsAllLayer.definitionExpression = this.getAcceleratedFacetsDefinitionExpression();
    } 

    if(this.props.fromDate && this.props.toDate &&  this.props.filterUserValue && (prevProps.fromDate != this.props.fromDate || prevProps.toDate != this.props.toDate || prevProps.fromDate != this.props.filterUserValue)){
      if(this.layerLeakSurveyTrackingPoint) this.layerLeakSurveyTrackingPoint.definitionExpression = this.getLeakSurveyPointsDefinitionExpression();
      if(this.layerLeakSurveyTracking) this.layerLeakSurveyTracking.definitionExpression = this.getLeakSurveyLinesDefinitionExpression();
      if(this.legacyLeakSurveyLines) this.legacyLeakSurveyLines.definitionExpression = this.getLeakSurveyLinesDefinitionExpression();
      if(this.legacyLeakSurveyPoints) this.legacyLeakSurveyPoints.definitionExpression = this.getLeakSurveyPointsDefinitionExpression();
    }
    // NO RESULTS do not need a data refresh
    if ((this.props.resultSet != undefined && 
        this.props.resultSet != null && 
        this.props.resultSet.length == 0 || 
        this.props.resultSet == null ||
        this.props.refresh == false  || multiMediaUpdate)) { 
      return; 
    }

    if(!this.isFirstRerender && this.props.resultSet == prevProps.resultSet) return;

    this.isFirstRerender = false;

    if(!this.rerenderingMap)this.rerenderMap();

  }

  rerenderMap(){
    this.rerenderingMap = true
     // RESIZE force the map to redraw within the window size
     window.resize();
     loadModules(['esri/Graphic', 'esri/geometry/Extent', 'esri/layers/GraphicsLayer', 'dojo/dom-construct']).then(([Graphic, Extent, GraphicsLayer, domConstruct]) => {
       
       if(!this.state.firstPassthroughDone && this.layerDictionary){
         try{
           for(const layer in this.layerDictionary){
             if(layer == "valve" || layer == "tee"|| layer == "riser"|| layer == "meter"|| layer == "pipe"|| layer == "main"|| layer == "facets"
             || layer == "mainAcceleratedLayer"|| layer == "serviceAcceleratedLayer"|| layer == "acceleratedFacetLayer"|| layer == "assignment" || layer == "layerLeakSurveyTracking"  || layer == "legacyLeakSurveyLines" || layer == "layerLeakSurveyTrackingPoint" || layer == "facetsAllLayer")
              this.layerDictionary[layer].layer.visible = false;
           }
         }
         catch(e){
           console.log(e)
         } finally{this.setState({firstPassthroughDone : true});}
         
       }
       // ACTIONS
       var delAction = { title: "Erase", id: "del", className: "esri-icon-erase" };
       var delSurvey = { title: "Archive", id : "archive", className: "esri-icon-trash"}
       var assignTaskAction = { title: "Assign Task", id: "assignTask", className: "esri-icon-authorize" };
       var detailsPage = { title: "Details", id:"viewDetails", className: "esri-icon-attachment"};
       var viewMultimedia = {title: 'Multimedia', id: 'viewMultimedia', className: "esri-icon-media"};
       
       // DATA COLLECTIONS and CONFIG
       var xmax = -180, xmin = 180, ymax = -90, ymin = 90;
       var userOptionList = "<option value='-1'>Unassigned</option>";
       var userOptionCollection = [];
       var actionList = "<option value='-1'></option><option value = 'assign'>Assign Area</option><option value='audit'>Create Audit</option>"
                       + "<option value='assignAllTasks'>Assign All Tasks</option><option value='unassignAllTasks'>Unassign All Tasks</option>";
       var assignmentGraphics = [];
       var surveyGraphics = {14: [], 15: [], 16:[], 22:[], 23:[], 24:[]};
       let me = this;
       var filterUserValue = -1;
       var filterStatusValue = -1;
       var filterVisitValue = -1;
       var filterAssignedValue = -1;
       var graphicAdded = false;
       this.legendStyle['Assignment Areas'] = "#de9232";
       if(this.props.inaccessibleCount > 0 && this.layerDictionary && this.layerDictionary[14] && this.layerDictionary[14].layer && this.layerDictionary[14].layer.listMode != "show"){
         this.layerDictionary[14].layer.listMode = "show"
       }
       if(this.props.aocCount > 0 && this.layerDictionary && this.layerDictionary[15] && this.layerDictionary[15].layer && this.layerDictionary[15].layer.listMode != "show"){
         this.layerDictionary[15].layer.listMode = "show"
       }
       if(this.props.leakCount > 0 && this.layerDictionary && this.layerDictionary[16] && this.layerDictionary[16].layer && this.layerDictionary[16].layer.listMode != "show"){
         this.layerDictionary[16].layer.listMode = "show"
       }
       if(this.props.meterCount > 0 && this.layerDictionary && this.layerDictionary[22] && this.layerDictionary[22].layer && this.layerDictionary[22].layer.listMode != "show"){
         this.layerDictionary[22].layer.listMode = "show"
       }
       if(this.props.pipelineCount > 0 && this.layerDictionary && this.layerDictionary[23] && this.layerDictionary[23].layer && this.layerDictionary[23].layer.listMode != "show"){
         this.layerDictionary[23].layer.listMode = "show"
       }
       if(this.props.insideMeterCount > 0 && this.layerDictionary && this.layerDictionary[24] && this.layerDictionary[24].layer && this.layerDictionary[24].layer.listMode != "show"){
         this.layerDictionary[24].layer.listMode = "show"
       }
 
       // FILTER USER and STATUS
       if (document.getElementById("filterStatus") != null){
         filterUserValue = document.getElementById("filterUser").value;
         filterStatusValue = document.getElementById("filterStatus").value;
         filterVisitValue = document.getElementById("filterVisit").value;
         filterAssignedValue = document.getElementById("filterAssigned").value;
       }
 
       // FILTER LEAK SURVEY LINES
       var layerLeakSurveyTracking = this.state.layerLeakSurveyTracking;
       var layerLeakSurveyTrackingPoint = this.state.layerLeakSurveyTrackingPoint;
       var legacyLeakSurveyLines = this.state.legacyLeakSurveyLines;
       if (legacyLeakSurveyLines) legacyLeakSurveyLines.definitionExpression = this.getLeakSurveyLinesDefinitionExpression();
       if (layerLeakSurveyTracking) layerLeakSurveyTracking.definitionExpression = this.getLeakSurveyLinesDefinitionExpression();
       if (layerLeakSurveyTrackingPoint) layerLeakSurveyTrackingPoint.definitionExpression = this.getLeakSurveyPointsDefinitionExpression();
       
       const filterUserValueFromStorage = sessionStorage.getItem("searchStripFilterUserValue");
 
       // VIEW and CLUSTER CONFIG
       let v = this.view;
       if (v){
         v._clusterTolerance = 1;
         v._clusterData = [];
         v._clusters = [];
         v._singles = [];
         v.surveyGraphics = surveyGraphics;
         v._clusterSizeMax = 999;
         v._resolutionFactor = 2000;
         v._clusterCountDisplayMin = 20;
       }
 
       // ** VIEW initialization
       if (this.view){
        //Put assignment areas on top.
        let ind = this.map.layers.findIndex(layer => {return layer.id === "assignmentLayer"});
        this.map.layers.reorder(this.map.layers.getItemAt(ind), this.map.layers.length -1);
 
         // ** EXPAND CLUSTER
         this.view.cluster_expandCluster = function cluster_expandCluster(c, ref){
           // label click - find related cluster and use to expand
           var c2; if (c.attributes.clusterId && !c.singles) {
             c2 = ref._clusters.find(e => (e.attributes.clusterId == c.attributes.clusterId));
             if (c2){c = c2;}
           }
 
           // show singles - record zoom level to ensure it doesnt recluster until zoomed out
           c.singles.forEach(s => {s.attributes.zoom=ref.zoom;surveyGraphics[c.EntityTypeId].push(s);});
 
           // remove cluster & label
           surveyGraphics[c.EntityTypeId].forEach(ec => {
             if (ec.attributes && ec.attributes.clusterId == c.attributes.clusterId && ec.attributes.clusterCount > 1) ec.visible = false;
           });
           
           Object.keys(surveyGraphics).forEach(survey =>{
             if(!ref.layerDictionary[survey]){
               let element = surveyGraphics[survey][0].attributes;
               var layer = new GraphicsLayer({title: element.FormData.Name});
               ref.layerDictionary[survey] = {layer};
               this.mapLayerType.push((typeof(survey) == "string") ? parseInt(survey) : survey );
               this.legendStyle[survey + "C"] = element.FormData.Color ? element.FormData.Color : this.legendStyle['Survey Data'];
               this.markerStyle[element.FormData.Name] = element.FormData.MarkerSymbol ? element.FormData.MarkerSymbol : 'default';
               this.legendStyle[element.FormData.Name] = element.FormDataColor ? element.FormData.Color : this.legendStyle['Survey Data'];
               this.map.layers.add(layer);
             }
           })
 
           // update layer graphics
           ref.mapLayerType.forEach(surveyType => {
             ref.layerDictionary[surveyType].layer.graphics = surveyGraphics[surveyType];
           });
         }
 
         // ** CLUSTER GRAPHICS
         this.view.cluster_clusterGraphics = function cluster_clusterGraphics(ref) {
           let addedLayer = false;
           //console.log(new Date().getMilliseconds() + ' | 1 :: DATA_COUNT', ref._clusterData.length);
 
           // ALL DATA create clusters and singles
           for ( var j = 0, jl = ref._clusterData.length; j < jl; j++ ) {
             
             var p = ref._clusterData[j];
             var clustered = false;
             var inExtent = p.geometry.x < ref.extentGeo.xmax && p.geometry.x > ref.extentGeo.xmin && p.geometry.y < ref.extentGeo.ymax && p.geometry.y > ref.extentGeo.ymin;
             if (!inExtent) continue; // not in the extent, continue without drawing feature
 
             // ADD FEATURE TO EXISTING CLUSTER
             for ( var i = 0; i < ref._clusters.length; i++ ) {
               var c = ref._clusters[i];
               
               // POINT check if its in a CLUSTER RANGE
               if ( cluster_clusterTest(p, c, ref) && c.attributes.clusterCount < ref._clusterSizeMax && c.EntityTypeId == p.attributes.EntityTypeId) {
                 cluster_clusterAddPoint(p, c);
                 clustered = true;
                 break;
               }
             }
     
             // create new cluster
             if (!clustered) {
               cluster_clusterCreate(p, ref);
             }
           }
           
           // show all clusters
           //console.log(new Date().getMilliseconds() +' | 1 :: cluster_showAllClusters', ref._clusters.length);
           ref._clusters.forEach(c => {cluster_showCluster(c, ref);});
           //console.log(new Date().getMilliseconds() + ' | 2 :: cluster_showAllClusters', ref._clusters.length);
 
           // update layer graphics
           Object.keys(surveyGraphics).forEach(survey =>{
             if(!this.layerDictionary[survey]){
               addedLayer = true;
               let element = surveyGraphics[survey][0].attributes;
               if(!element.FormData){
                 element = surveyGraphics[survey][0].singles[0].attributes;
               }
               var layer = new GraphicsLayer({title: element.FormData.Name,visible : false});
               this.layerDictionary[survey] = {layer};
               this.mapLayerType.push((typeof(survey) == "string") ? parseInt(survey) : survey);
               this.legendStyle[survey] = element.FormData.Color ? element.FormData.Color : this.legendStyle['Survey Data'];
               this.markerStyle[element.FormData.Name] = element.FormData.MarkerSymbol ? element.FormData.MarkerSymbol : 'default';
               this.legendStyle[element.FormData.Name] = element.FormData.Color ? element.FormData.Color : this.legendStyle['Survey Data']; 
               this.map.layers.add(layer);
             }
           });
           this.mapLayerType.forEach(surveyType => {
             this.layerDictionary[surveyType].layer.graphics = surveyGraphics[surveyType];
             //console.log(new Date().getMilliseconds() + ' | 2 :: FEATURE_COUNT', surveyGraphics[surveyType].length);
           });
           if(addedLayer){
             setTimeout(() =>{
               if(document.getElementsByClassName("esri-layer-list__item-container") != undefined){
                 var listItemCollection = document.getElementsByClassName("esri-layer-list__item-container");
                 for(let index = 0; index < listItemCollection.length; index++){
                   listItemCollection[index].style.borderLeftColor = this.legendStyle[listItemCollection[index].firstElementChild.lastElementChild.innerHTML];
                 }
               }
             }, 1000);
           }
         }
 
       } // ** VIEW initialization
 
       // CLUSTER TEST if point is inside cluster range
       function cluster_clusterTest(p, cluster, ref) {
         var distance = (Math.sqrt( Math.pow((cluster.x - p.geometry.x), 2) + Math.pow((cluster.y - p.geometry.y), 2)) / ref.resolution * ref._resolutionFactor);
         return (distance <= ref._clusterTolerance);
       }
 
       // CLUSTER ADD POINT
       function cluster_clusterAddPoint(p, cluster) {
 
         // average in the new point to the cluster geometry
         var count, x, y;
         count = cluster.attributes.clusterCount;
         x = (p.geometry.x + (cluster.x * count)) / (count + 1);
         y = (p.geometry.y + (cluster.y * count)) / (count + 1);
         cluster.x = x;
         cluster.y = y;
 
         // build an extent that includes all points in a cluster
         // extents are for debug/testing only...not used by the layer
         if ( p.geometry.x < cluster.attributes.extent[0] ) {
           cluster.attributes.extent[0] = p.geometry.x;
         } else if ( p.x > cluster.attributes.extent[2] ) {
           cluster.attributes.extent[2] = p.geometry.x;
         }
         if ( p.geometry.y < cluster.attributes.extent[1] ) {
           cluster.attributes.extent[1] = p.geometry.y;
         } else if ( p.geometry.y > cluster.attributes.extent[3] ) {
           cluster.attributes.extent[3] = p.geometry.y;
         }
 
         // increment the count & add single
         if(!p.attributes.MultimediaAssetId){
           cluster.attributes.clusterCount++;
         }
         cluster.singles.push(p);
 
         // give the graphic a cluster id
         p.attributes.clusterId = cluster.attributes.clusterId+'s';
       }
 
       // CREATE CLUSTER
       function cluster_clusterCreate(p, ref) {
 
         var clusterId = ref._clusters.length + 1;
         p.attributes.clusterId = clusterId;
 
         var cluster = { 
           "x": p.geometry.x,
           "y": p.geometry.y,
           "attributes" : {
             "clusterCount": 1,
             "clusterId": clusterId,
             "extent": [ p.geometry.x, p.geometry.y, p.geometry.x, p.geometry.y ]
           },
           "singles": [p], // add point as first single in cluster
           EntityTypeId: p.attributes.EntityTypeId
         };
 
         ref._clusters.push(cluster);
       }
 
       // CREATE CLUSTER GRAPHIC
       function cluster_showCluster(c, ref) {
         // show singles - if the cluster has been expanded by user and the map has not been zoomed out
         var singlesExpanded = false;
         c.singles.forEach(s => {
           if (s.attributes.zoom && s.attributes.zoom <= ref.zoom){
             if(surveyGraphics[c.EntityTypeId]){
               surveyGraphics[c.EntityTypeId].push(s);
             }
             else{
               surveyGraphics[c.EntityTypeId] = [];
               surveyGraphics[c.EntityTypeId].push(s);
             }
             singlesExpanded = true;
           }
           if (s.attributes.zoom && s.attributes.zoom > ref.zoom){
             s.attributes.zoom == null;
           }
         });
 
         // show singles - instead of cluster if in the extent if there is exactly 1 single feature in the cluster
         if ((c.attributes.clusterCount < ref._clusterCountDisplayMin) || (c.attributes.clusterCount == 1) || (c.attributes.zoom && c.attributes.zoom > ref.zoom)){
           c.singles.forEach(s => {
             if (!s.zoom){
               if(surveyGraphics[c.EntityTypeId]){
                 surveyGraphics[c.EntityTypeId].push(s);
               }
               else{
                 surveyGraphics[c.EntityTypeId] = [];
                 surveyGraphics[c.EntityTypeId].push(s);
               }
             } 
           });
         }
         else if (!singlesExpanded) // if this clusters singles are expanded, do not recluster
         { // create cluster
           if(!ref.legendStyle[c.EntityTypeId + "C"]){
             let color = c.singles[0].attributes.FormData.Color;
             ref.legendStyle[c.EntityTypeId + "C"] = color ? color : ref.legendStyle['Survey Data']; 
           }
           var g = new Graphic({ 
             geometry: {type: "point", longitude: c.x, latitude: c.y, spatialReference: { wkid: 4326 }}, 
             symbol: {
               type: "simple-marker", 
               path: me.MarkerUtil.getPath(c.singles[0] && c.singles[0].attributes && c.singles[0].attributes.FormData && c.singles[0].attributes.FormData.MarkerSymbol ? c.singles[0].attributes.FormData.MarkerSymbol : ''), 
               color: ref.legendStyle[c.EntityTypeId + 'C'],
               size: 25,
               outline: {color: [ 222, 222, 222, 0.8 ], width: 1}
             },
             attributes: c.attributes
           });
 
           // add to graphics collection
           g.singles = c.singles;
           g.EntityTypeId = c.EntityTypeId;
           if(surveyGraphics[c.EntityTypeId]){
             surveyGraphics[c.EntityTypeId].push(g);
           }
           else{
             surveyGraphics[c.EntityTypeId] = [];
             surveyGraphics[c.EntityTypeId].push(g);
           }
           // add label
           var label = new Graphic({ geometry: {type: "point", longitude: c.x, latitude: c.y, spatialReference: { wkid: 4326 }}, 
           symbol: {type: "text",color: "white", text: g.attributes.clusterCount, haloColor: [90,90,90,1], haloSize: "1px", font: { size: 10, weight: "bold" }}});
           label.attributes = {clusterId: c.attributes.clusterId};
           if(surveyGraphics[c.EntityTypeId]){
             surveyGraphics[c.EntityTypeId].push(label);
           }
           else{
             surveyGraphics[c.EntityTypeId] = [];
             surveyGraphics[c.EntityTypeId].push(label);
           }
         }
       }
       // DATA RESULTS configure data for display
       for(let resultI = 0; resultI < this.props.resultSet.length; resultI ++ ){
         let element = this.props.resultSet[resultI];
         graphicAdded = false;
         // USER COLLECTION
         if (element.Type == "Leak Survey User"){
           let userOption = "<option value='"+element.UserId+"'>"+element.DisplayNameProper+"</option>";
           let newUserOptionElement = document.createElement("option");
           newUserOptionElement.text = element.DisplayNameProper;
           newUserOptionElement.value = element.UserId;
           newUserOptionElement.selected = element.UserId == filterUserValueFromStorage;
           userOptionList += userOption;
           userOptionCollection.push(newUserOptionElement);
         } // USER COLLECTION
       
         // ASSIGNMENT AREA
         else if (element.Type == "LeakSurveyAssignment" && element.AssignmentPolygonJSON != null){
           
           var newGraphic = new Graphic({
             geometry: {type: "polygon", rings: JSON.parse(element.AssignmentPolygonJSON), spatialReference: { wkid: 4326 }}, 
             symbol: {type: "simple-fill", style: "solid", color: [220, 140, 40, 0.6], outline: {color:[180,120,50,0.9], width:2}},
             attributes: element,
             popupTemplate: {
               title: "Assigned: <b>{Username}</b>",
               actions: [delAction],
               outFields:["*"]
             }
           });
 
           if ((newGraphic.geometry != null && newGraphic.geometry.extent != null)){
             var point = newGraphic.geometry.extent.center;
             var txtSym = {type: "text", color: "white", text: element.Username, haloColor: [110,110,110,1], haloSize: "1px", font: { size: 10, weight: "bold" }};
             var lblGra = new Graphic(point, txtSym);
 
             // FILTER USER | and add to graphic and label to their collections
             if (filterUserValue == -1 || element.AssignedToUserId == filterUserValue) {
               assignmentGraphics.push(newGraphic);
               assignmentGraphics.push(lblGra);
             }
 
             // Determine Data Extent
              if(!this.firstLoadComplete){
               if(point.longitude != null){
                 xmax = xmax < point.longitude ? point.longitude : xmax;
                 xmin = xmin > point.longitude ? point.longitude : xmin;
               }
               if(point.latitude != null){
                 ymax = ymax < point.latitude ? point.latitude : ymax;
                 ymin = ymin > point.latitude ? point.latitude : ymin;
               }
             }
             var layerAssignmentAreas = this.state.layerAssignmentAreas;
             if (layerAssignmentAreas != undefined) {
               layerAssignmentAreas.graphics = assignmentGraphics;
               layerAssignmentAreas.listMode = (assignmentGraphics.length == 0) ? 'hide' : 'show';
               if(!this.props.updatedAssignementLayer)layerAssignmentAreas.visible = false;
             }
             
            
           }
         } // ASSIGNMENT AREA
 
         // LEAK SURVEY
         else if (element.Type == "Leak Survey" && this.view) {
           // FILTER USER & STATUS & VISITCOUNT | and add Survey graphic to collection
           if ((filterUserValue == -1 || element.AssignedUserId == filterUserValue)) {
             if (((filterAssignedValue == "Unassigned" && (element.AssignedUserId == null || element.AssignedUserId == "-1")) || 
               (filterAssignedValue == "Assigned" && element.AssignedUserId != null && element.AssignedUserId != "-1") || 
               (filterAssignedValue == -1)) &&
               (filterStatusValue == -1 || (element.Status == null && filterStatusValue == "Open") || (element.Status == filterStatusValue))) {
               if(filterVisitValue == -1 || 
                 (filterVisitValue == 'Not Visited' && (element.TaskCounter == null || element.TaskCounter == 0)) || 
                 (filterVisitValue == 'Visited 1 time' && element.TaskCounter == 1) || 
                 (filterVisitValue == 'Visited 2 times' && element.TaskCounter == 2) || 
                 (filterVisitValue == "Visited 3+ times" && element.TaskCounter >= 3)){
 
                 if(!this.legendStyle[element.EntityTypeId]){
                   if(element.FormData.Color){
                     this.legendStyle[element.EntityTypeId] = element.FormData.Color;
                   }
                   else{
                     this.legendStyle[element.EntityTypeId] = this.legendStyle['Survey Data'];
                   }
                 }
                 if(!this.markerStyle[element.EntityTypeId]){
                   this.markerStyle[element.EntityTypeId] = element.FormData.MarkerSymbol ? element.FormData.MarkerSymbol : 'default';
                 }
                 // CREATE AND ADD GRAPHIC TO DATA COLLECTION
                 newGraphic = new Graphic({ 
                   geometry: {type: "point", longitude: element.Longitude, latitude: element.Latitude, spatialReference: { wkid: 4326 }}, 
                   symbol: {
                     type: "simple-marker", 
                     path: this.MarkerUtil.getPath(element.FormData ? element.FormData.MarkerSymbol : ''), 
                     color: this.legendStyle[element.EntityTypeId],
                     size: 23,
                     outline: {color: [ 222, 222, 222, 0.8 ], width: 1}
                   },
       
                   attributes: element,
                   popupTemplate: {
                     title: "<b><span class='leakSurveyPopupHeader' style='color:" + this.legendStyle[element.EntityTypeId] + "'>{Status} - " + (element.FormData ? ( element.FormData.Name + " </span></b>") : (this.leakSurveyName[element.EntityTypeId] + " Survey </span></b>")),
                     actions: [assignTaskAction, detailsPage,viewMultimedia, delSurvey],
                     content: setTaskAssignmentInfo,
                     outFields:["*"]
                   }
                 });
                 this.view._clusterData.push(newGraphic);
                 graphicAdded = true;
               }
               
             }
           }
           
           // CALCULATE DATA EXTENT
           if(element.Longitude != null && element.Longitude != 0 && graphicAdded){
             xmax = xmax < element.Longitude ? element.Longitude : xmax;
             xmin = xmin > element.Longitude ? element.Longitude : xmin;
           }
           if(element.Latitude != null && element.Latitude != 0 && graphicAdded){
             ymax = ymax < element.Latitude ? element.Latitude : ymax;
             ymin = ymin > element.Latitude ? element.Latitude : ymin;
           }
           
         }
         else{
           console.log(element.Type)
         } // LEAK SURVEY
       }
 
       // if(this.props.multimediaAssets){
       //   this.props.multimediaAssets.forEach(element =>{
       //     graphicAdded = false;
       //     var title = element.MediaTypeId == 0 || element.MediaTypeId == null ? "Picture" : "Video";
       //     if(this.view){
       //       newGraphic = new Graphic({
       //         geometry: {type: "point", longitude : element.Longitude, latitude : element.Latitude, spatialReference : {wkid : 4326}},
       //         symbol : {
       //           type : "text",
       //           color : "black",
       //           text : title == "Picture" ? "\ue661" : "\ue662",
       //           haloColor : [255,255,255,1],
       //           haloSize : "1px",
       //           font : {size : 18, family : "CalciteWebCoreIcons"}
       //         },
       //         attributes : element,
       //         popupTemplate : {
       //           title : "<b><span class='leakSurveyPopupHeader' style='color:black'>" + title + "</span></b>",
       //           actions : [element.MediaTypeId == 0 || element.MediaTypeId == null ? viewLarger : viewVideo],
       //           content: setMultimediaPopupContent,
       //           outFields: ["*"]
       //         }
       //       });
       //       this.view._clusterData.push(newGraphic);
       //       graphicAdded = true;
       //     }
       //     function setMultimediaPopupContent(multimediaAsset){
       //       var data = multimediaAsset.graphic.attributes;
       //       var srcUrl = process.env.API_URL + "/api/image/" + data.MultimediaAssetId;
       //       var node = domConstruct.create("div", {innerHTML:
       //         "<div id='image" + data.MultimediaAssetId + "'><img src='" + srcUrl + "' alt ='' style='margin-top : 5px; object-fit: cover; width: 99%; height: 250px;'/></div>"
       //       });
       //       return node;
       //     }
       //   })
       // }
       // TASK ASSIGNMENT POPUP
       function setTaskAssignmentInfo(feature){
         var data = feature.graphic.attributes;
         let html = "";
         if(data.FormData){
           html = "<table class='esri-widget__table' summary='List of attributes and values'><tbody>";
 
           if(data.FormData.Fields && data.FormData.Fields.length > 0){
             for(let i = 0; i < data.FormData.Fields.length && i < 4; i++){
               let field = data.FormData.Fields[i];
               if(field.Name != "AssignedUserId" && field.Name != "Status" && field.Name != "TaskCounter" && field.Type != "Signature" && field.Type != "Scanner" && field.Type != "Section"){
                 html += "<tr><th class='esri-feature__field-header'>" + field.Name +"</th><td class='esri-feature__field-data'>" + (field.Value != null ? field.Value : "") + "</td></tr>"
               }
             }
           }
           html += "<tr><th class='esri-feature__field-header'>Assigned User</th><td class='esri-feature__field-data'>" + (data.AssignedUserDisplayName ? data.AssignedUserDisplayName : "") + "</td></tr><tr><th class='esri-feature__field-header'>Status</th><td class='esri-feature__field-data'>" + (data.Status ? data.Status : "") + "</td></tr><tr><th class='esri-feature__field-header'>Visits</th><td class='esri-feature__field-data'>"+(data.TaskCounter ? data.TaskCounter : 0) +"</td></tr></tbody></table>";
           
           html += "<div class='assignmentDiv'><div class='assignmentLabel'>Assign to:</div>" +
           "<select id='assignmentSelect' class='assignmentSelect' >" + userOptionList + "</select></div>";
         }
         else{
           html = "<table class='esri-widget__table' summary='List of attributes and values'><tbody><tr>" +
           "<th class='esri-feature__field-header'>Address</th><td class='esri-feature__field-data'>" + (data.Address ? data.Address : "") + "</td></tr><tr>" +
           "<th class='esri-feature__field-header'>Assigned User</th><td class='esri-feature__field-data'>" + (data.AssignedUserDisplayName ? data.AssignedUserDisplayName : "") + "</td></tr><tr>" +
           "<th class='esri-feature__field-header'>Visits</th><td class='esri-feature__field-data'>‎" + (data.TaskCounter ? data.TaskCounter : "") + "</td></tr><tr>" +
           "<th class='esri-feature__field-header'>Status</th><td class='esri-feature__field-data'>" + (data.Status ? data.Status : "") + "</td></tr><tr>" +
           "<th class='esri-feature__field-header'>Comments</th><td class='esri-feature__field-data'>" + (data.Comments ? data.Comments : "") + "</td></tr></tbody></table>" +
           
           "<div class='assignmentDiv'><div class='assignmentLabel'>Assign to:</div>" +
           "<select id='assignmentSelect' class='assignmentSelect' >" + userOptionList + "</select></div>";
         }
         var node = domConstruct.create("div", { innerHTML: html});
         
         return node;
       }
 
       // UPDATE USER FILTER OPTIONS
       me.state.userOptionList = userOptionList;
       me.state.actionList = actionList;
       var filterUser = document.getElementById("filterUser");
       if (filterUser.options.length <= 1){
         userOptionCollection.forEach(userOption => {filterUser.options.add(userOption);});
       }
 
       // COLOR LEGEND LAYERS
       clearInterval(this.colorInterval);
       this.colorInterval = setInterval(() => this.colorLegendItems(), 1000);
 
       // REFRESH MAP, VIEW AND LAYERS
       if (this.layerDictionary != undefined){
         // update layer graphics
         this.view.extent = this.view.extent;
 
         if(me.props.updatedAssignementLayer){
          if (me.props.zoomToExtent) {
            if (xmin < 180 && xmax > -180)
              this.view.extent = new Extent({xmin: xmin-0.005, ymin: ymin-0.005, xmax: xmax+0.005, ymax: ymax+0.005, spatialReference: { wkid: 4326 }});
            else { this.view.center = [-98, 40]; this.view.zoom = 4; } 
          }
         }
         // update zoom extent
         if(this.props.detailsSurveyLon && this.props.detailsSurveyLat){
           this.view.center = [this.props.detailsSurveyLon, this.props.detailsSurveyLat];
           this.view.zoom = 15;
         }
         else if (me.props.updateMapExtent) {
           if (me.props.zoomToExtent) {
             if (xmin < 180 && xmax > -180)
               this.view.extent = new Extent({xmin: xmin-0.005, ymin: ymin-0.005, xmax: xmax+0.005, ymax: ymax+0.005, spatialReference: { wkid: 4326 }});
             else { this.view.center = [-98, 40]; this.view.zoom = 4; } 
           }
           else {
             const mapZoom = sessionStorage.getItem("leakSurveyMapZoom");
             const mapExtent = sessionStorage.getItem("leakSurveyMapExtent");
             if (mapZoom != null && mapExtent != null && mapZoom != "null" && mapExtent != "null") {
               this.view.extent = JSON.parse(mapExtent);
               this.view.zoom = parseInt(mapZoom); } 
             else { this.view.center = [-98, 40]; this.view.zoom = 4; }
           }
         }
 
         // apply header labels and extra controls
        //  if (document.getElementsByClassName("esri-sketch-header").length == 0 && document.getElementsByClassName("esri-sketch")[0] != undefined){
        //    var header = document.createElement("div");
        //    header.className = "esri-sketch-header";
        //    header.innerHTML = "ASSIGN / AUDIT";
        //    document.getElementsByClassName("esri-sketch")[0].insertBefore(header,document.getElementsByClassName("esri-sketch")[0].childNodes[0]);
        //  }
         this.firstLoadComplete = true;
       }
       this.rerenderingMap = false;
     });
  }

  createAudit(tech, comments, passed, auditType, LeakSurveyAuditId, view){
    if(auth.isSuperViewer()){
      this.props.statusTextUpdate('');
      return;
    }
    let valid = true;
    let AuditTypeId;
    if(!tech || tech == '-1'){
      valid = false;
    }
    if(!passed || passed ==''){
      valid = false;
    }
    if(!auditType || auditType == ''){
      valid = false;
    }
    else{
      switch(auditType){
        case "initial":
          AuditTypeId = 18;
          break;
        case "100":
          AuditTypeId = 19;
          break;
        case "documentation":
          AuditTypeId = 20;
          break;
        case "field":
          AuditTypeId = 21;
          break;
        default:
          break;
      }
    }

    if (valid) {
      this.props.statusTextUpdate('Saving Update...');
      let self = this;
      let body ={
        UserId : auth.employeeId(),
        CompanyId : auth.companyId(),
        Geometry : GLOBAL.ASSIGNMENT.geometry,
        Technician : tech,
        AuditTypeId,
        Passed : passed == "Pass",
        Comments : comments,
        LeakSurveyAuditId,
        UTCOffset : (-1) *( new Date().getTimezoneOffset() /60)
      };

      fetch(process.env.API_URL + "/api/leakSurveyAuditUpdate",{
        method: "POST",
        headers:{
          Accept : "application/json",
          "Content-Type" : "application/json; charset=utf-8",
          Authorization: "Bearer " + auth.token()
        },
        body: JSON.stringify(body)
      })
      .then(r =>{
        if(r.status == 200){
          r.json().then(bodyJson =>{
            if(bodyJson.length >0){
              let response = JSON.parse(bodyJson);
              if(response.ResponseType == 0){
                view.popup.close();
                this.props.setUpdateMapExtentProp(false);
                self.setState({statusText: "", resultSet: self.props.resultSet, zoomToExtent: false});
                this.props.searchRequest({});
                self.state.sketchLayer.remove(GLOBAL.ASSIGNMENT.graphic);
              }
            }
          });
        }
        else{
          console.log('Error');
          self.props.statusTextUpdate('');
        }
      })
      .catch(error =>{
        console.log(error.message);
        self.props.statusTextUpdate('');
      });
    }
  }

  updateAssignmentArea(assignmentUserId, username, leakSurveyAssignmentId, deleteAssignment){
    if(auth.isSuperViewer()){
      this.props.statusTextUpdate('');
      return;
    }
    let me = this;
    this.props.statusTextUpdate('Saving Update...');

    let body = {
      UserId: auth.employeeId(),
      CompanyId: auth.companyId(),
      Geometry: GLOBAL.ASSIGNMENT.geometry,
      AssignedToUserId: assignmentUserId,
      LeakSurveyAssignmentId: leakSurveyAssignmentId,
      DeleteAssignmentArea: deleteAssignment
    };
   
    fetch(process.env.API_URL + "/api/leakSurveyAssignmentUpdate/", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(r => {
      if (r.status == 200) {
        r.json().then(bodyJson => {
          if (bodyJson.length > 0) {
            let json = JSON.parse(bodyJson);
            this.props.updateAssignmentAreas(json.LeakSurveyAssignment, leakSurveyAssignmentId);
            this.props.setUpdateMapExtentProp(false);
            me.setState({statusText: "",  zoomToExtent: true});
            this.rerenderMap();
            this.showProgressBar(false);
            //this.props.searchRequest({});
          }
        });
      }
    })
    .catch(error => {
      console.log(error.message);
      me.setState({ statusText: "" });
    });

    me.state.sketchLayer.remove(GLOBAL.ASSIGNMENT.graphic);
    GLOBAL.ASSIGNMENT.graphic = null;
    GLOBAL.ASSIGNMENT.geometry = null;
  }

  showProgressBar(show){
    console.log('showProgressbar', show);
    document.getElementById("loading").style.display = show ? 'unset' : 'none';
  }

  assignTask(assignmentUserId, username, leakSurveyId, CustomLeakForm){
    if(auth.isSuperViewer()){
      self.props.statusTextUpdate('');
      return;
    }

    let me = this;
    let date = new Date();
    
    let body = {
      UserId: auth.employeeId(),
      CompanyId: auth.companyId(),
      AssignedToUserId: assignmentUserId,
      LeakSurveyId: leakSurveyId,
      CustomLeakForm,
      UTCOffset : - date.getTimezoneOffset() / 60,
    };

    this.showProgressBar(true);
   
    fetch(process.env.API_URL + "/api/leakSurveyAssignTask/", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(r => {
      if (r.status == 200) {
        r.json().then(bodyJson => {
          if (bodyJson.length > 0) {

            let response = JSON.parse(bodyJson);
            if (response.LeakSurvey) {

              // UPDATE LOCAL ENTITY ATTRIBUTES
              me.props.resultSet.forEach(element => {
                if(response.LeakSurvey.FormData){
                  if(element.FormData && element.FormData.FormDataId == response.LeakSurvey.FormData.FormDataId){
                    element.Status = response.LeakSurvey.Status;
                    element.AssignedUserId = response.LeakSurvey.AssignedUserId;
                    element.AssignedUserDisplayName = response.LeakSurvey.AssignedUserDisplayName;
                  }
                }
                else{
                  if (element.LeakSurveyId == response.LeakSurvey.LeakSurveyId) { 
                    element.Status = response.LeakSurvey.Status;
                    element.AssignedUserId = response.LeakSurvey.AssignedUserId;
                    element.AssignedUserDisplayName = response.LeakSurvey.AssignedUserDisplayName; 
                  }
                }
              });

              // display toaster message
              this.props.displayToaster('Task Assigned / Unassigned');

              // cache data
              try { 
                set('resultSet_LeakSurvey', JSON.stringify(me.props.resultSet));
              }
              catch(e){
                console.log('error caching resultSet_LeakSurvey object');
              }
            }
            
            this.props.updateAssignedCount(false);
            this.props.setUpdateMapExtentProp(false);
            me.setState({statusText: "", resultSet: me.props.resultSet, zoomToExtent: false});
            this.rerenderMap();
            this.showProgressBar(false);
          }
        });
      }else{
        alert("Assign / Unassign failed.");
        this.showProgressBar(false);
      }
    })
    .catch(error => {
      console.log(error.message);
      me.setState({ statusText: "" });
      this.showProgressBar(false);
    });

    me.state.sketchLayer.remove(GLOBAL.ASSIGNMENT.graphic);
    GLOBAL.ASSIGNMENT.graphic = null;
    GLOBAL.ASSIGNMENT.geometry = null;
  }

  assignTaskCollection(assignmentUserId, username, leakSurveyIdCollection){
    if(auth.isSuperViewer()){
      self.props.statusTextUpdate('');
      return;
    }

    let me = this;

    let body = {
      UserId: auth.employeeId(),
      CompanyId: auth.companyId(),
      AssignedToUserId: assignmentUserId,
      LeakSurveyIdCollection: leakSurveyIdCollection
    };
    this.showProgressBar(true);
   
    fetch(process.env.API_URL + "/api/leakSurveyAssignTaskCollection/", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(r => {
      if (r.status == 200) {
        r.json().then(bodyJson => {
          if (bodyJson.length > 0) {

            let response = JSON.parse(bodyJson);
            response.forEach((responseItem)=>{
              
              // update local entity attributes
              me.props.resultSet.forEach(element => {
                if(responseItem.LeakSurvey.FormData){
                  if(element.FormData && element.FormData.FormDataId == responseItem.LeakSurvey.FormData.FormDataId){
                    element.Status = responseItem.LeakSurvey.Status;
                    element.AssignedUserId = responseItem.LeakSurvey.AssignedUserId;
                    element.AssignedUserDisplayName = responseItem.LeakSurvey.AssignedUserDisplayName;
                  }
                }
                else{
                  if (element.LeakSurveyId == responseItem.LeakSurvey.LeakSurveyId) { 
                    element.Status = responseItem.LeakSurvey.Status;
                    element.AssignedUserId = responseItem.LeakSurvey.AssignedUserId;
                    element.AssignedUserDisplayName = responseItem.LeakSurvey.AssignedUserDisplayName; 
                  }
                }
              });

            }); 

            if(response.length < leakSurveyIdCollection.length){
              const failedCount = leakSurveyIdCollection.length - response.length;
              alert("Assign / Unassign failed on " + failedCount + " task(s)");
            }

            // display toaster message
            this.props.displayToaster('Tasks Assigned / Unassigned');
            this.props.updateAssignedCount(false);
            this.rerenderMap();

            try { 
              set('resultSet_LeakSurvey', JSON.stringify(me.props.resultSet));
            }
            catch(e){
              console.log('eating cache overload');
            }
            
            this.props.setUpdateMapExtentProp(false);
            me.setState({statusText: "", resultSet: me.props.resultSet, zoomToExtent: false});
            this.showProgressBar(false);
          }
        });
      }else{
        alert("Assign / Unassign failed.");
        this.showProgressBar(false);
      }
    })
    .catch(error => {
      console.log(error.message);
      me.setState({ statusText: "" });
      this.showProgressBar(false);
    });

    me.state.sketchLayer.remove(GLOBAL.ASSIGNMENT.graphic);
    GLOBAL.ASSIGNMENT.graphic = null;
    GLOBAL.ASSIGNMENT.geometry = null;
  }

  colorLegendItems() {
    let companyLayerNames = [];
    if(this.props.CompanyArcGisLayers && this.props.CompanyArcGisLayers.length > 0){
      companyLayerNames = this.props.CompanyArcGisLayers.map(layer => layer.Name);
    }
    if(this.props.CompanyKMLSHAPELayers && this.props.CompanyKMLSHAPELayers.length > 0){
      this.props.CompanyKMLSHAPELayers.forEach(layer =>{
        if(layer.FileType == "kml"){
          companyLayerNames.push(layer.Name);
        }
        if(layer.FileType == "shape"){
          companyLayerNames.push(layer.Name);
          if(layer.shapeJSON && layer.shapeJSON.layers){
            layer.shapeJSON.layers.forEach(shapeLayer =>{
              companyLayerNames.push(shapeLayer.layerDefinition.name);
            })
          }
        }
      });
    }
    if (document.getElementsByClassName("esri-layer-list__item-container") != undefined) {
      var listItemCollection = document.getElementsByClassName("esri-layer-list__item-container");
      for (let index = 0; index < listItemCollection.length; index++) {
        let layerName = listItemCollection[index].querySelector('.esri-layer-list__item-title').innerHTML;
        if(listItemCollection[index].firstElementChild.lastElementChild){
          listItemCollection[index].style.borderLeftColor = this.legendStyle[layerName] || this.state.defaultLayerColor;
          let parent = listItemCollection[index].parentElement;
          let grandparent = parent.parentElement;
          let greatGrand = grandparent.parentElement;
          if(!listItemCollection[index].innerHTML.includes('<svg ') && this.markerStyle[layerName] && !companyLayerNames.includes(layerName) && !parent.classList.contains("esri-layer-list__item--has-children")&& !grandparent.classList.contains("esri-layer-list__item--has-children")&& !greatGrand.classList.contains("esri-layer-list__item--has-children")){
            let marker = document.createElement("div");
            marker.innerHTML = ReactDOMServer.renderToString(<svg style={{position : 'relative'}} height="25px" width="25px" fill={this.legendStyle[layerName]}>
               <path d={this.MarkerUtil.getPath(this.markerStyle[layerName])}></path>
              </svg>);
            listItemCollection[index].insertBefore(marker, listItemCollection[index].firstChild);
          }
        }
      }
    }

    clearInterval(this.colorInterval);
  }

  componentWillUnmount() {
    this.beforeunload();
    if (this.view) { this.view.container = null; }
    clearInterval(this.colorInterval);
  }

  clickThumbNail(index){
    let id = this.state.thumbnails[index].videoId;
    let videoIndex = this.state.videos.findIndex(x => x.assetId == id);
    if(this.state.videos.length == 0 || videoIndex == -1){
      this.getVideo(id);
    }
    else{
      this.setState({videoIndex});
    }
  }

  getVideo(assetId){
    this.setState({loadingVideos : true});
    fetch(process.env.API_URL + "/api/video/" + assetId,{
      method : "GET",
      headers : {
        "Content-Type" : "appliction/json",
        Authorization : "Bearer " + auth.token()
      },
      signal : this.controller.signal
    })
    .then(r => r.blob())
    .then(blob =>{
      let videos = this.state. videos;
      let vid = URL.createObjectURL(blob);
      videos.push({uri : vid, assetId});
      this.setState({videos, videoIndex : videos.length -1});
      this.setState({loadingVideos : false});
    }).catch(error =>{console.log(error.message); this.setState({loadingVideos : false})});
  }

  setVideoIndex(direction){
    let index = this.state.videoIndex + direction;
    if(index == -1){
      index = this.state.videos.length -1;
    }
    else if(index == this.state.videos.length){
      index = 0;
    }
    this.setState({videoIndex : index});
  }

  render() {
    return (
      <div className="webmap" ref={this.mapRef} >
        {/* <div id="imageGalleryDiv" style={{display : 'none'}}>
          <ImageGallery items={this.state.currentFullscreenImage}
            showFullScreenButton={true}
            showPlayButton={false}
            onScreenChange={fullScreen =>{
              if(fullScreen){
                document.getElementById("imageGalleryDiv").style.display = 'flex';
              }
              else{
                document.getElementById("imageGalleryDiv").style.display = 'none';
              }
            }}
            ref={i => this._imageGallery = i}
          />
        </div> */}
        <Dialog open={this.state.viewingDetails} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth='lg' fullWidth='true' scroll='paper'>
          <DialogContent>
            {(this.state.entityToView && this.state.entityToView.FormData )&& <FormDataPage location={{formData : this.state.entityToView}} closeEntity={() =>{this.openDetailsView()}} fromDashboard={true} hideClose={true}/>}
            {(this.state.entityToView && !this.state.entityToView.FormData)&& <LeakSurveyPage location={{leakSurvey : this.state.entityToView}} closeEntity={() =>{this.openDetailsView()}} fromDashboard={true} hideClose={true}/>}
          </DialogContent>
          <DialogActions>
            <Button className="buttonAction btn btn-primary" onClick={this.openDetailsView} color="primary">
              <label className="buttonText small" style={{margin:0, cursor: 'pointer'}}>Close</label>
            </Button>
          </DialogActions>
        </Dialog>
        <div id="mapFeaturesDiv" className="modal" style={{paddingTop : 0}}>
          <div style={{width: 253, marginTop: 168, position: 'absolute', right: 12}}>
            <div className="row" style={{background: '#a8a8a8', marginRight: '15px !important'}}>
            <div className="menuDialogMapOption">
                <Button className="buttonAction buttonAccount btn btn-primary btnSubmit fullWidth mapMenuButton" onClick={() =>{
                  document.getElementById("mapFeaturesDiv").style.display = 'none';
                  document.getElementById("addressSearchDiv").style.display = 'block';
                }}><SearchIcon style={{marginRight: 8}}/>Search Address</Button>
                <Button className="buttonAction buttonAccount btn btn-primary btnSubmit fullWidth mapMenuButton" onClick={() =>{
                  document.getElementById("mapFeaturesDiv").style.display = 'none';
                  this.view.takeScreenshot().then(screenshot =>{
                    const downloadLink = document.createElement('a');
                    document.body.appendChild(downloadLink);
                    downloadLink.href = screenshot.dataUrl;
                    downloadLink.target = '_self';
                    downloadLink.download = "Map.jpeg";
                    downloadLink.click(); 
                  });
                }}><ScreenShareIcon style={{marginRight: 8}}/>Map Print</Button>
                <Button className="buttonAction buttonAccount btn btn-primary btnSubmit fullWidth mapMenuButton" onClick={() =>{
                  document.getElementById("mapFeaturesDiv").style.display = 'none';
                  document.getElementById("mapReportDiv").style.display = 'block';
                }}><DescriptionIcon style={{marginRight: 8}}/>Map Report</Button>
              </div>
            </div>
          </div>
        </div>
        <div id="addressSearchDiv" className="modal" style={{paddingTop : 0}}>
          <div className="modal-content" style={{width : '50%', marginTop: '200px', maxHeight : "90%"}}>
            <span className="close text-right" style={{top: 0, right: '8px'}} onClick={() =>{document.getElementById("addressSearchDiv").style.display = "none"; }}>
              &times;
            </span>
            <div className=" labelHeaderBar labelHeaderBarTight noMultiMediaHeader"> <label className="labelHeaderName" style={{margin : "0 auto"}}>Find Address and Select to Pin on map</label></div>
            <div className="row">
              <div style = {{margin: '0 auto', marginTop:  10, marginBottom : 15}}>
                <label className="labelHeaderName">ADDRESS: </label>
                <input className="form-control" style={{width : '400px', display : 'inline', marginTop:18}} type={"text"} value={this.state.addressSearchForSearch} onChange={(e) => {this.searchAddress(e, 'addressSearch');}}/>
              </div>
            </div>
            <div style={{marginBottom : 15}}>
              {this.state.addressSuggestions && this.state.addressSuggestions.length > 0 && this.state.addressSuggestions.map((suggestion,index)=>(
              <div key={index} className = "row underline hoverBold" style={{cursor : 'pointer'}} onClick={() =>{this.selectSuggestedAddressToZoom(suggestion)}}>
                <div style={{margin: '0 auto'}}>
                  {suggestion.text}
                </div>
              </div>))}
              </div>
          </div>
        </div>
        <div id="mapReportDiv" className="modal" style={{paddingTop : 0}}>
          <div className="modal-content modalDialogCustom" >
            <span className="close text-right" style={{top: 0, right: '8px'}} onClick={() =>{document.getElementById("mapReportDiv").style.display = "none"; this.setState({mapReportTitle : "", mapReportTitleColor : 'black'});}}>
              &times;
            </span>
            <div className="labelHeaderBar labelHeaderBarTight noMultiMediaHeader"><label className="labelHeaderName" style={{margin : "0 auto"}}>Map Report</label></div>
            <div className="row">
              <div style={{margin: '0 auto', marginTop : 10}}>
                <label className="labelHeaderName" style={{color : this.state.mapReportTitleColor}}>Map Title: </label>
                <input className="form-control" style={{width : 285, display: 'inline'}} type={"text"} value={this.state.mapReportTitle} onChange={(e) =>{this.setState({mapReportTitle : e.target.value, mapReportTitleColor : 'black'})}} />
              </div>
            </div>
            <div className="row">
              <div style={{margin: "0 auto", marginTop : 15, marginBottom : 10}}>
                <Button className="buttonAction buttonAccount btn btn-primary btnSubmit" style={{position : 'relative', left:117}} onClick={() =>{this.createMapReport()}}>
                  <label style={{margin:0, cursor:'pointer'}} className="buttonText">Create Map Report </label>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div id ="createTaskDiv" className="modal" style={{paddingTop: 0}}>
          <div className="modal-content modalDialogCustom">
            <span className="close text-right" style={{top: 0, right: "8px"}} onClick={()=>{document.getElementById("createTaskDiv").style.display = "none"; this.props.setCreatingNewTask(false)}}>
              &times;
            </span>
            <div className=" labelHeaderBar labelHeaderBarTight noMultiMediaHeader"> <label className="labelHeaderName" style={{margin : "0 auto"}}>Enter Address Or Select Location, Assign Form and User</label></div>
            <div className="row">
              <div style={{margin: '0 auto', marginTop : 10}}>
                <label className="labelHeaderName" style={{color : this.state.newTaskAddressColor}}>ADDRESS: </label> 
                <input className="form-control" style={{width : 285, display : 'inline'}} type={"text"} value={this.state.addressSearch} onChange={(e) => {this.searchAddress(e, 'taskSearch');}}/>
                <Button title="Click on map to select location" className="buttonAction  btn btn-primary btnSubmit" style={{display: 'inline', margin: 10}} onClick={() =>{this.props.setCreatingNewTask(true); this.setState({foundAddress : "", addressSuggestions : [], addressSearch : ""}); document.getElementById("createTaskDiv").style.display = "none";}}><MapTwoIcon style={{position : 'relative', bottom : 4}}/><label className="buttonText">Map Location</label></Button>
              </div>
            </div>
            {this.state.addressSuggestions && this.state.addressSuggestions.length > 0 && this.state.addressSuggestions.map((suggestion,index)=>(
            <div key={index} className = "row underline hoverBold" style={{cursor : 'pointer'}} onClick={() =>{this.selectSuggestedAddress(suggestion)}}>
              <div style={{margin: '0 auto'}}>
                {suggestion.text}
              </div>
            </div>))}
            {this.state.newTaskLat && this.state.newTaskLon && 
            <div className = "row">
              <div style={{margin: '0 auto', marginBottom : 10}}>
                {this.state.foundAddress && this.state.foundAddress != "" && <label className="labelHeaderName">Found: </label>}
                {this.state.foundAddress} 
                {this.state.foundAddress && this.state.foundAddress != "" && " - "} 
                <label className="labelHeaderName">Latitude: </label>
                {this.state.newTaskLat.toFixed(5)},
                <label className="labelHeaderName">Longitude: </label>
                {this.state.newTaskLon.toFixed(5)}
              </div>
            </div>}
            <div className="row">
              <div style={{margin:'0 auto'}}>
                <label className="labelHeaderName" style={{color : this.state.assignedCustomFormColor}}>CUSTOM FORM: </label>
                <select className ="assignmentSelect" value={this.state.assignedCustomFormId} onChange={e =>{this.setState({assignedCustomFormId: e.target.value, assignedCustomFormColor : '#3c5873', editingNewTask : false},() =>{this.createTask()})}}>
                  <option></option>
                  {this.state.customFormList && this.state.customFormList.length > 0 && this.state.customFormList.map((customForm, index)=>(
                    <option key={index} value={customForm.FormId}>{customForm.Name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div style={{margin:'0 auto'}}>
                <label className="labelHeaderName" style={{marginRight : 20, color : this.state.assignedUserColor}}>ASSIGN USER: </label>
                <select className="assignmentSelect" value={this.state.assignedUserId} onChange={e =>{this.setState({assignedUserId : e.target.value, assignedUserColor : '#3c5873'})}}>
                  <option></option>
                  {this.state.users && this.state.users.length > 0 && this.state.users.map((user,index) => (this.state.trialMode || (user.LicenseIdCollection && (user.LicenseIdCollection.includes("site") || user.LicenseIdCollection.includes("investigation")))) && (
                    <option key={index} value={user.UserId}>{user.DisplayNameProper}</option>
                  ))}
                </select>
              </div>
            </div>
            {this.state.editingNewTask && this.state.newTaskCustomForm && <div className="row">
              <div style={{margin: '0 auto'}}>
                <FormDataPage updateTaskDocuments={(docs) =>{let newTaskCustomForm = this.state.newTaskCustomForm; newTaskCustomForm.docs = docs; this.setState({newTaskCustomForm})}} updateTaskImages={(images) =>{let newTaskCustomForm = this.state.newTaskCustomForm; newTaskCustomForm.images = images; this.setState({newTaskCustomForm})}} location={{formData: {FormData : this.state.newTaskCustomForm}}} fromTaskMap={true} closeEntity={() =>{this.closeEntity()}}/> 
              </div>
            </div>}
            <div className="row" style={{marginTop: 5}}>
              <div style={{margin: '0 auto'}}>
                {this.state.newTaskCustomForm && <Button className='buttonAction buttonAccount btn btn-primary btnSubmit' style={{position : 'relative', left : 110}} onClick={() =>{this.setState({editingNewTask : !this.state.editingNewTask})}}>
                    <label style={{margin:0, cursro: 'pointer'}} className="buttonText">{this.state.editingNewTask ? "Hide" : "Edit"} Data</label>
                </Button>}
                <Button className="buttonAction buttonAccount btn btn-primary btnSubmit" style={{position : 'relative', left: 120}} onClick={() =>{this.createAndAssignTask()}}>
                  <label style={{margin:0, cursor:'pointer'}} className="buttonText">Create Task</label>
                </Button>
              </div>
            </div>
            <div style={{height: 25}}/>
          </div>
        </div>
        <div id = "imageGalleryDiv" className="modal" style={{paddingTop : 0}}>
          <div className = "modal-content" style={{width : '66%', marginTop :(this.state.videoCount == 0 && this.state.pictureCount == 0) || this.state.loadingMultimedia ? "10%" : "3%"}}>
            {this.state.loadingMultimedia ? <div className="barLoaderPortal" style={{width: '75%'}}><BarLoader sizeUnit={"px"} size={200} color={"#095399"} loading={this.state.loadingMultimedia}/></div> : <div>
            <span className = "close text-right" style={{top : 0, left : "63vw"}} onClick = {()=>{document.getElementById("imageGalleryDiv").style.display ="none";this.setState({images : [], videos : [], thumbnails : [], videoCount : 0, pictureCount : 0})}}>
              &times;
            </span>
            {this.state.pictureCount > 0 && <div>
              <div className=" labelHeaderBar labelHeaderBarTight"> <label className="labelHeaderName">Photos ({this.state.pictureCount})</label></div>
              <div className = "row carouselRow">
                <div className="carouselGalleryDiv">
                  <ImageGallery items={this.state.images} 
                    showFullscreenButton={this.state.images.length > 0}
                    showPlayButton={this.state.images.length > 0}
                    ref={i => this._imageGallery = i}
                    />
                  </div>
                </div>
            </div>}
            {this.state.videoCount > 0 && 
            <VideoPanel 
              setVideoIndex={this.setVideoIndex}
              videoIndex={this.state.videoIndex} 
              clickThumbNail={this.clickThumbNail} 
              thumbnails={this.state.thumbnails} 
              loadingVideos={this.state.loadingVideos} 
              videoCount={this.state.videoCount} 
              videos={this.state.videos}
              removeVideo={this.removeVideo}
              hideDelete={true}
            />}
            {(this.state.videoCount == 0 && this.state.pictureCount == 0) &&
              <div className=" labelHeaderBar labelHeaderBarTight noMultiMediaHeader"> <label className="labelHeaderName" style={{margin : "0 auto"}}>No Multimedia</label></div>
            }
            </div>}
          </div>
        </div>
      </div>
    );
  }
}

WebMapViewLeakSurvey.propTypes = {
  searchRequest: PropTypes.func,
  statusTextUpdate: PropTypes.func,
  zoomToExtent: PropTypes.bool,
  updateMapExtent: PropTypes.bool, 
  refresh: PropTypes.bool, 
  setUpdateMapExtentProp: PropTypes.func,
  pipelineCount : PropTypes.number,
  updateAssignedCount: PropTypes.func,
  displayToaster: PropTypes.func,
  resultSet : PropTypes.array,
  disableType : PropTypes.func,
  inaccessibleCount : PropTypes.number,
  aocCount : PropTypes.number,
  leakCount : PropTypes.number,
  meterCount : PropTypes.number,
  insideMeterCount : PropTypes.number,
  searchValue : PropTypes.string,
  refreshFacetLayer : PropTypes.bool,
  multimediaAssets : PropTypes.array,
  detailsSurveyLat : PropTypes.number,
  detailsSurveyLon : PropTypes.number,
  addNewEntityToResults : PropTypes.func,
  creatingNewTask : PropTypes.bool,
  setCreatingNewTask : PropTypes.func,
  setAlert : PropTypes.func,
  updateAssignmentAreas : PropTypes.func,
  updatedAssignementLayer : PropTypes.bool,
  CompanyArcGisLayers : PropTypes.array,
  CompanyKMLSHAPELayers : PropTypes.array,
  ARCGISClientSecret : PropTypes.string,
  ARCGISClientId : PropTypes.string,
  updateFromArchive : PropTypes.func
};

export default WebMapViewLeakSurvey;