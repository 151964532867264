import React from "react";
import auth from "../common/Auth";
import SearchStrip from "./SearchStrip";
import { MDBDataTable } from 'mdbreact';
import { BarLoader } from "react-spinners";
import { Button } from "react-bootstrap";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import PropTypes from "prop-types";
import { DirtUtility } from '../../Utility/DirtUtility';
import SurveyPage from './SurveyPage';
import FormDataPage from './FormDataPage';
import InvestigationPage from './InvestigationPage';
import LeakSurveyPage from './LeakSurveyPage';
import SiteSafePage from './SiteSafePage';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DataGroupHelpers from '../../Utility/DataGroupHelpers';
import { get, set } from 'idb-keyval';
import OrgUtility from '../../Utility/Utility';
import Functions from "../../Functions/Functions";

class ReportingPage extends React.Component {
  constructor(props) {
    super(props);

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let downloadBlock = params.get('d');

    this.state = {
      resultData: [],
      formData: [],
      apiUrl: process.env.API_URL,
      status: "SEARCH",
      responseBody: {},
      statusText: "",
      loadingMsg: "",
      id: "",
      resultsCount: -1,
      surveyCount:0,
      incidentCount:0,
      buttonText: "VIEW",
      updateStatusText: downloadBlock == 1 ? "Data Download Started" : downloadBlock > 1 ? "Data Download Started" : "",
      includePhotos : false,
      includeOpen : true,
      includeClosed : true,
      excludeList :[],
      excludeCustomList : [],
      unfilteredFormData : [],
      unfilteredResultData : [],
      userOptions: [],
      statusFilter : "-1",
      visitFilter : '-1',
      assignmentFilter : '-1',
      userFilter : "-1",
      dueDateFilter : "-1",
      ticketTypeFilter : "-1",
      viewingDetails : false,
      entityToView : null,
      maxFieldValueWidth: 40,
      dateRangeDays : 7,
      dataGroups: [],
      entityCollections : [],
      useModifiedDate : false,
      hasTickets : false,
      tagFormSearchOptions: null,
      loadingTagFormSearchOptions: false,
    };
    this.searchRef = null;
    this.employeeIdValue = "";
    this.filterUpdate = this.filterUpdate.bind(this);
    this.searchRequest = this.searchRequest.bind(this);
    this.swap = this.swap.bind(this);
    this.uploadDirt = this.uploadDirt.bind(this);
    this.downloadRequest = this.downloadRequest.bind(this);
    this.disableType = this.disableType.bind(this);
    this.sheet = this.setupSheet();
    this.viewEntity = this.viewEntity.bind(this);
    this.closeEntity = this.closeEntity.bind(this);
    this.expandGroup = this.expandGroup.bind(this);
    this.toggleDataGroup = this.toggleDataGroup.bind(this);
    this.toggleProject = this.toggleProject.bind(this);
    this.doFiltering = this.doFiltering.bind(this);
    this.getUserList = this.getUserList.bind(this);
  }

  hasLicense(licenseId){
    const licensesString = auth.licenses();
    if(licensesString){
      const licenseArray = licensesString.split(',');
      if(licenseArray.indexOf(licenseId) > -1)
        return true;
      return false;
    }else{
      return false;
    }
  }

  componentDidMount() { 
    this.getTagFormSearchOptions();
    this.props.forceRefresh();
    get('resultData').then((resultData)=>{
      if (resultData != undefined && resultData != null && resultData != ''){
        this.parseResultSet(JSON.parse(resultData));
      }
      //Removed auto searching
      // else{
      //   if(this.searchRef && !this.checkSuper()){
      //     this.searchRef.clickSubmit();
      //   }
      // }
    });
    DataGroupHelpers.getDataGroups(auth.employeeId(), auth.companyId(), auth.token()).then(dataGroups =>{
      dataGroups.forEach(group =>{
        group.expanded = false;
      })
      this.setState({dataGroups});
    });
    this.getUserList();
  }

  checkSuper(){
    let isSuper = false;
    let _SuperAdminRoleId = 0;
    let _SuperViewerRoleId = 11;
    var roles = localStorage.getItem("roles");
    if(roles != null) { roles.split(',').forEach(element => { if (element == _SuperAdminRoleId || element == _SuperViewerRoleId) { isSuper = true;}});}
    return isSuper;
  }

  getTagFormSearchOptions(){
    let body = {
      CompanyId : auth.companyId(),
      FormTypeId : 5 //Tag
    }
    this.setState({loadingTagFormSearchOptions:true});
    fetch(process.env.API_URL +"/api/account/formsInfo",{
      method: "POST",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    }).then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let tagFormSearchOptions = [];
          let json = JSON.parse(responseJson);
          if (json.ResponseType == 0){
            if (json.FormInfoCollection) {
              json.FormInfoCollection.forEach(form =>{  
                form.filterEnabled = false;
                tagFormSearchOptions.push(form);
              });
              tagFormSearchOptions.sort((a,b) => (a.FormOrder > b.FormOrder) ? 1 : -1);
              this.setState({tagFormSearchOptions, loadingTagFormSearchOptions: false});
            }
          }
        });
      } else{
        console.warn("Non 200 response on TagFormSearchOptions fetch. Status: " + r.status);
        this.setState({loadingTagFormSearchOptions:false});
      }
    }).catch(error =>{
      console.log(error.message);
      this.setState({loadingTagFormSearchOptions:false});
    })
  }

  expandGroup(group){
    let dataGroups = DataGroupHelpers.expandGroup(group.expanded,group.DataGroupId, this.state.dataGroups);
    this.setState(dataGroups);
  }

  toggleDataGroup(id,checked){
    let dataGroups = DataGroupHelpers.toggleDataGroup(id,checked,this.state.dataGroups);
    this.setState({dataGroups}, () =>{this.doFiltering()});
  }

  toggleProject(name,checked){
    let formProjects = this.state.formProjects;
    formProjects.find(x => x.ProjectLabel == name).showData = checked;
    this.setState({formProjects},() =>{this.doFiltering()});
  }


  setupSheet = () => {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    return style.sheet;
  }

  updateIncludePhotos(includePhotos){
    this.setState({includePhotos});
  }

  updateIncludeOpen(includeOpen){
    this.setState({includeOpen});
  }

  updateIncludeClosed(includeClosed){
    this.setState({includeClosed});
  }

  componentDidUpdate(){ 
    
    // Replace table search boxes with filter and move to header bar for multi column tables
    var searchInputs = document.getElementsByClassName('form-control form-control-sm');
    if (searchInputs && searchInputs.length > 0){
      for (let i = 0; i < searchInputs.length; i++) {
        var e = searchInputs[i];
        if (e.parentElement != null){
          var filterInput = e.parentElement.parentElement;
          if (filterInput.parentElement.parentElement.parentElement.parentElement.classList.contains('searchResultsColumnMulti')){
            filterInput.style = "right:54px;top:-43px;"
          }
        }
        e.placeholder = 'Filter';
        e.style = "width:200px;";
      }
    }
    window.resize(); 

    // STYLE CSS by ORG
    new OrgUtility().updateOrgCSS();
  }

  filterUpdate(value, type){
    let statusFilter = type == 'status' ? value : this.state.statusFilter;
    let visitFilter = type == 'visit' ? value : this.state.visitFilter;
    let assignmentFilter = type == 'assigned' ? value : this.state.assignmentFilter;
    let userFilter = type == 'user' ? value : this.state.userFilter;
    let dueDateFilter = type == 'dueDate' ? value : this.state.dueDateFilter;
    let ticketTypeFilter = type == 'ticketType' ? value : this.state.ticketTypeFilter;
    this.setState({statusFilter, visitFilter, assignmentFilter, userFilter, dueDateFilter, ticketTypeFilter}, () =>{this.doFiltering()});
  }

  doFiltering(){
    let dataGroups = this.state.dataGroups;
    let excludeDataGroupsList = dataGroups.filter(x => !x.showData);
    let excludeProjectList = this.state.formProjects.filter(x => !x.showData);
    let entityCollections = this.state.entityCollections;
    let excludeList = this.state.excludeList;
    let excludeCustomList = this.state.excludeCustomList;
    let resultData = [];
    let formData = [];
    let unfilteredResultData = this.state.unfilteredResultData;
    let unfilteredFormData = this.state.unfilteredFormData;
    unfilteredResultData.forEach(result =>{
      let push = true;
      if(excludeDataGroupsList && excludeDataGroupsList.length > 0){
        let collectionRecords = entityCollections.filter(x => x.EntityId == result.Id && x.EntityTypeId == result.EntityTypeId);
        if(collectionRecords && collectionRecords.length > 0){
          collectionRecords.forEach(collection =>{
            if(excludeDataGroupsList.findIndex(x => x.DataGroupId == collection.DataGroupId) > -1){
              push = false;
            }
          });
        }
      }
      if((excludeList.includes('survey') && result.Type == "Site") || (excludeList.includes('invest') && result.Type == "Investigation")){
        push = false;
      }
      else if((result.Type == "Investigation" || result.Type == "Site")){
        switch(this.state.statusFilter){
          case "Any Status":
            break;
          case "Open":
            if(!result.FormTask){
              push = false
            }
            else if(!((result.FormTask && result.FormTask.FormTaskStatus == 0))){
              push = false
            }
            break;
          case "Closed":
            if(!result.FormTask){
              push = false
            }
            else if(((result.FormTask && result.FormTask.FormTaskStatus == 0) )){
              push = false
            }
            break;
          default:
            break;
        }
        switch(this.state.assignmentFilter){
          case "Assigned":
            if(!result.FormTask){
              push = false;
            }
            else if(!result.FormTask.AssignedUserId || result.FormTask.AssignedUserId == "-1"){
              push = false;
            }
            break;
          case "Unassigned":
            if(result.FormTask && result.FormTask.AssignedUserId && result.FormTask.AssignedUserId != "-1"){
              push = false;
            }
            break;
          default:
            break;
        }
        switch(this.state.userFilter){
          case "-1":
            break;
          default:
            if(result.FormTask && (result.FormTask.AssignedUserId != this.state.userFilter && result.ModifiedBy != this.state.userFilter && result.CreatedBy != this.state.userFilter)){
              push = false;
            }
            if(result.ModifiedBy != this.state.userFilter && result.CreatedBy != this.state.userFilter){
              push = false;
            }
            break;
        }
      }
      if(excludeList.includes('gps') && result.Type == "GPS Survey"){
        push = false;
      }
      if(excludeList.includes('SiteSafe') && result.Type == "SiteSafe") {
        push = false;
      }
      if(excludeList.includes('Leak') && (result.Type == "Leak Survey" || result.Type =="Meter Inspection" || result.Type == "Abnormal Condition" || result.Type == "Inaccessible")){
        push = false;
      }
      else if(result.Type == "Leak Survey" || result.Type =="Meter Inspection" || result.Type == "Abnormal Condition" || result.Type == "Inaccessible"){
        switch(this.state.statusFilter){
          case "Any Status":
            break;
          case "Open":
            if(result.Status != "Open"){
              push = false;
            }
            break;
          case "Closed":
            if(result.Status != "Closed"){
              push = false;
            }
            break;
          default:
              break;
        }
        switch(this.state.assignmentFilter){
          case "Assigned" :
            if(result.AssignedUserId == null){
              push = false;
            }
            break;
          case "Unassigned":
            if(result.AssignedUserId != null){
              push = false;
            }
            break;
          default:
            break;
        }
        switch(this.state.visitFilter){
          case "-1":
            break;
          case "Not Visited":
            if(result.TaskCounter != 0){
              push = false;
            }
            break;
          case "Visited 1 time":
            if(result.TaskCounter != 1){
              push = false;
            }
            break;
          case "Visited 2 times":
            if(result.TaskCounter != 2){
              push = false;
            }
            break;
          case "Visited 3+ times":
            if(result.TaskCounter < 3){
              push = false;
            }
            break;
          default:
            break;
        }
      }

      if(push){
        resultData.push(result);
      }
    });
    unfilteredFormData.forEach(form =>{
      let push = true;
      var timeTillDue = 10000000;
      if(form.FormData && form.FormData.FormTypeId == 4){
        var dueDate = new Date(form.FormData.Fields.find(x => x.Name == "Due Date").Value);
        timeTillDue = (dueDate - new Date()) / 1000
        var ticketTypeField = form.FormData.Fields.find(x => x.Name == "Ticket Type");
        var ticketType = ticketTypeField ? ticketTypeField.Value : "";
      }
      if(excludeProjectList && excludeProjectList.length > 0 && form.FormData){
        let selectedProject = form.FormData.Fields && form.FormData.Fields.length > 0 && form.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down")  ? form.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down").Value : null;
        if(excludeProjectList.find(x => x.ProjectLabel == "No Project") && (!selectedProject || selectedProject == "")){
          push = false;
        }
        if(selectedProject && selectedProject != "" && excludeProjectList.find(x => x.ProjectLabel == selectedProject)){
          push = false;
        }
      }
      if(excludeDataGroupsList && excludeDataGroupsList.length > 0 && form.FormData){
        let collectionRecords = entityCollections.filter(x => x.EntityId == form.FormData.FormDataId && x.EntityTypeId == 93);
        if(collectionRecords && collectionRecords.length > 0){
          collectionRecords.forEach(collection =>{
            if(excludeDataGroupsList.findIndex(x => x.DataGroupId == collection.DataGroupId) > -1){
              push = false;
            }
          });
        }
      }
      switch(this.state.dueDateFilter){
        case "PastDue":
          if(timeTillDue > 0){
            push = false;
          }
          break;
        case "Today":
          if(timeTillDue < 0 || timeTillDue > 86400){
            push = false;
          }
          break;
        case "1-Day":
          if(timeTillDue < 86400 || timeTillDue > 172800){
            push = false;
          }
          break;
        case "2-Days":
          if(timeTillDue < 172800 || timeTillDue > 259200){
            push = false;
          }
          break;
        default:
          break;
      }
      if(this.state.ticketTypeFilter != "-1"){
        //Use ticket type or emergency field because different tickets have it in diffrent fields. 
        if(ticketType != this.state.ticketTypeFilter){
          push = false;
        }
      if(this.state.ticketTypeFilter == "Emergency" && ticketType != this.state.ticketTypeFilter){
          var isEmergencyField = form.FormData.Fields.find(x => x.Name == "Ticket Type");
          if(isEmergencyField){
            if(isEmergencyField.Value != "Emergency"){
              push = false;
            }
          }
        }
      }
      switch(this.state.statusFilter){
        case "Any Status":
          break;
        case "Open":
          if(!form.FormTask){
            let statusField = null;
            if(form.FormData){
              statusField = form.FormData.Fields.find(x => x.Name == 'Status' && x.Type == "Drop Down");
            }
            else{
              statusField = form.Fields.find(x => x.Name == 'Status' && x.Type == "Drop Down");
            }
            if(statusField){
              if(statusField.Value != "Open"){
                push = false;
              }
            }
            else{
              push = false
            }
          }
          else if(((form.FormTask && form.FormTask.FormTaskStatus != 0))){
            push = false
          }
          break;
        case "Closed":
          if(!form.FormTask){
            let statusField = null;
            if(form.FormData){
              statusField = form.FormData.Fields.find(x => x.Name == 'Status' && x.Type == "Drop Down");
            }
            else{
              statusField = form.Fields.find(x => x.Name == 'Status' && x.Type == "Drop Down");
            }
            if(statusField){
              if (statusField.Value != "Closed"){
                push = false;
              }
            }
            else{
              push = false
            }
          }
          else if(((form.FormTask && form.FormTask.FormTaskStatus != 1))){
            push = false
          }
          break;
        case "Project":
          if(!form.FormTask){
            let statusField = null;
            if(form.FormData){
              statusField = form.FormData.Fields.find(x => x.Name == 'Status' && x.Type == "Drop Down");
            }
            else{
              statusField = form.Fields.find(x => x.Name == 'Status' && x.Type == "Drop Down");
            }
            if(statusField){
              if (statusField.Value != "Project"){
                push = false;
              }
            }
            else{
              push = false
            }
          }
          else if(((form.FormTask && form.FormTask.FormTaskStatus != 2))){
            push = false
          }
          break;
        case "Canceled":
          if(!form.FormTask){
            let statusField = null;
            if(form.FormData){
              statusField = form.FormData.Fields.find(x => x.Name == 'Status' && x.Type == "Drop Down");
            }
            else{
              statusField = form.Fields.find(x => x.Name == 'Status' && x.Type == "Drop Down");
            }
            if(statusField){
              if (statusField.Value != "Canceled"){
                push = false;
              }
            }
            else{
              push = false
            }
          }
          else if(((form.FormTask && form.FormTask.FormTaskStatus != 3))){
            push = false
          }
          break;
        default:
          break;
      }

      switch(this.state.assignmentFilter){
        case "Assigned":
          if(!form.FormTask){
            push = false;
          }
          else if(!form.FormTask.AssignedUserId || form.FormTask.AssignedUserId == "-1"){
            push = false;
          }
          break;
        case "Unassigned":
          if(form.FormTask && form.FormTask.AssignedUserId && form.FormTask.AssignedUserId != "-1"){
            push = false;
          }
          break;
        default:
          break;
      }
      switch(this.state.userFilter){
        case "-1":
          break;
        default:
          if(form.FormTask && (form.FormTask.AssignedUserId != this.state.userFilter && form.ModifiedBy != this.state.userFilter &&  form.CreatedBy != this.state.userFilter)){
            push = false;
          }
          if(form.FormData.ModifiedBy != this.state.userFilter && form.FormData.CreatedBy != this.state.userFilter && form.AssignedUserId != this.state.userFilter){
            push = false;
          }
          break;
      }

      if(excludeList.includes('gps') && form.Type == "GPS Survey"){
        push = false;
      }
      if(form.FormData && excludeCustomList.includes(form.FormData.FormId)){
        push = false;
      }
      if(push){
        formData.push(form);
      }
    });
    this.setState({resultData, formData});
  }

  disableType(type){
    let excludeList = this.state.excludeList;
    let excludeCustomList = this.state.excludeCustomList;
    switch(type){
      case 'survey':
        if(excludeList.includes('survey')){
          excludeList.splice(excludeList.indexOf('survey'),1);
        }
        else{
          excludeList.push('survey');
        }
        break;
      case 'invest':
        if(excludeList.includes('invest')){
          excludeList.splice(excludeList.indexOf('invest'),1);
        }
        else{
          excludeList.push('invest');
        }
        break;
      case 'gps':
        if(excludeList.includes('gps')){
          excludeList.splice(excludeList.indexOf('gps'),1);
        }
        else{
          excludeList.push('gps');
        }
        break;
      case 'Leak':
        if(excludeList.includes('Leak')){
          excludeList.splice(excludeList.indexOf('Leak'),1);
        }
        else{
          excludeList.push('Leak');
        }
        break;
      case 'SiteSafe':
        if(excludeList.includes('SiteSafe')){
          excludeList.splice(excludeList.indexOf('SiteSafe'),1);
        }
        else{
          excludeList.push('SiteSafe');
        }
        break;
      default:
        if(typeof(type) == 'number'){
          if(excludeCustomList.includes(type)){
            excludeCustomList.splice(excludeCustomList.indexOf(type),1);
          }
          else{
            excludeCustomList.push(type);
          }
        }
        break;
    }
    this.setState({excludeList, excludeCustomList},()=>{this.doFiltering()});
  }

  async uploadDirt(id){
    this.setState({ loadingDirt: "Submitting..." });
    await DirtUtility.uploadDirt(id)
    .then(r =>{
      if(r.status == 200){
        r.json().then(async responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 2){
            this.setState({statusText : "", updateStatusText : "Error Uploading to DIRT " + json.ValidationMessageCollection[0].MessageText, alertSeverity : 'error'});
          }
          else{
            let data = await get('resultData');
            if(data){
              let storageData = JSON.parse(data);
              storageData.InvestigationCollection.forEach(investigation =>{
                if(investigation.InvestigationId == id){
                  investigation.DirtSubmitted = true;
                }
              });
              set('resultData', JSON.stringify(storageData));
              this.setState({statusText : ""},() =>{
                this.parseResultSet(storageData);
              });
            }
          }
        });
      }
    })
    .catch(error =>{
      this.setState({statusText : "", updateStatusText : "Error Uploading to DIRT " + error, alertSeverity : "error"});
    });
  }

  swap(props) {
    this.setState({ status: props.statusToSet });
  }

  viewEntity(element){
    if(this.state.entityToView){
      let oldId = this.state.entityToView.Type + this.state.entityToView.Id;
    
      if(this.state.entityToView.Type == 'Form' || this.state.entityToView.Type =="GPS Survey"){
        oldId =this.state.entityToView.Type + (this.state.entityToView.FormData ? this.state.entityToView.FormData.FormDataId : this.state.entityToView.FormDataId);
      }
      let oldCardElement = document.getElementById(oldId);
      if(oldCardElement){
        oldCardElement.classList.remove('highlight');
      }
    }
    let id = element.Type + element.Id;
    
    if(element.Type == 'Form' || element.Type =="GPS Survey"){
      id =element.Type + (element.FormData ? element.FormData.FormDataId : element.FormDataId);
    }
    
    let cardElement = document.getElementById(id);
    
    if(cardElement){
      cardElement.classList.add('highlight');
    }
    // close viewing entity
    this.setState({viewingDetails: false, entityToView: null});

    if (!this.state.viewingDetails){
      let details = document.getElementById("entityDetails");
      if(window.innerWidth <= 768){
        details.classList.add("col-12");
        details.style.display = "inline-block";
      }else{
        details.style.display = 'inline-block';
      }
      this.setState({viewingDetails: true});
    }

    // copy entity the entity with parse and stringify trick, fixes by-reference use bugs
    this.setState({entityToView: JSON.parse(JSON.stringify(element))});

    console.log('ReportingPage_viewEntity', this.state.entityToView)
    let elementIndex = this.state.resultData.findIndex(x => x.Type == element.Type && x.Id == element.Id);
    if(element.Type == "Form" || element.Type == "GPS Survey"){
      elementIndex = this.state.resultData.length;
      elementIndex += this.state.formData.findIndex(x => x.FormData.FormDataId == (element.FormData ? element.FormData.FormDataId : element.FormDataId));
    }
    let cardDiv = document.getElementById('searchResultsDiv');
    if(cardDiv && elementIndex != -1){
      cardDiv.scrollTop = 76*elementIndex;
    }
  }

  closeEntity(){
    let grid = document.getElementById("searchResultsDiv");
    let details = document.getElementById("entityDetails");
    if (window.innerWidth <= 768){
      grid.style.display = "inline-block";
      details.classList.remove("col-12");
      details.style.display = "none";
    }
    else{
      grid.classList.remove("col");
      grid.style.justifyContent = 'center';
      details.style.display = 'none';
    }
    this.setState({viewingDetails: false, entityToView: null});
  }

  getUserList(){
    let body = {CompanyId : auth.companyId()};
    fetch(process.env.API_URL + "/api/account/users",{
      method: "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status ==  200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0 && json.UserCollection.length>0){
            let i = json.UserCollection.length;
            while(i--){
              if(json.UserCollection[i].DeletedBy != null || json.UserCollection[i].DeletedDate != null || json.UserCollection[i].DeletedDateUTCOffset != null){
                json.UserCollection.splice(i,1);
              }
            }
            Functions.sortOn(json.UserCollection, 'LastName');
            this.setState({userOptions : json.UserCollection});
          }
        })
      }
    })
  }

  searchRequest(props) {
    
    if (this.state.viewingDetails){
      this.closeEntity();
    }
    let withAttachedTagFormIds = props.tagFilterOptions ? props.tagFilterOptions.filter(x=>x.filterEnabled).map(x=>x.FormId) : [];
    this.setState({ statusText: "Retrieving Data..." });
    let body = {
      UserId: auth.employeeId(),
      CompanyId: auth.companyId(),
      SearchParam: props.searchCriteriaValue,
      DateFrom: props.dateFrom,
      DateTo: props.dateTo,
      ReturnSurveyData: true,
      ReturnIncidentData: true,
      ReturnGPSSurveyData: true,
      ReturnLeakSurveyData: true,
      ReturnSiteSafeData: true,
      WebSearch: true,
      Offset: - new Date().getTimezoneOffset() /60,
      UseModifiedDate : this.state.useModifiedDate,
      WithAttachedTagFormIds: withAttachedTagFormIds
    };

    fetch(process.env.API_URL + "/api/search/", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        Authorization: "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(r => {
      if (r.status == 200) {
        r.json().then(responseJson => {
          if (responseJson.length > 0) {

            // PARSE RESULTS | into table format
            let response = JSON.parse(responseJson);
            this.parseResultSet(response);

            // STORE RESULTS | in local storage
            set('resultData', responseJson);
          }
        });
      }
    })
    .catch(error => {
      console.log(error.message);
    });
  }

  downloadRequest(props){
    let DataGroups;
    let Projects;
    if(this.state.dataGroups && this.state.dataGroups.length > 0){
      DataGroups = [];
      this.state.dataGroups.forEach(group =>{
        if(group.showData){
          DataGroups.push(group.DataGroupId);
        }
      });
    }
    if(this.state.formProjects && this.state.formProjects.length > 0 && this.state.formProjects.filter(x => !x.showData).length > 0){ //Api won't filter if projects is null, no need to filter if none are hidden. 
      Projects = [];
      this.state.formProjects.forEach(project =>{
        if(project.showData){
          Projects.push(project.ProjectLabel);
        }
      })
    }
    this.setState({ statusText: "Downloading Data..."});
    let body = {
      UserId: auth.employeeId(),
      CompanyId: auth.companyId(),
      SearchParam: props.searchCriteriaValue,
      DateTo: props.dateTo,
      DateFrom: props.dateFrom,
      ReturnSurveyData: true,
      ReturnIncidentData: true,
      ReturnGPSSurveyData: true,
      ReturnLeakSurveyData: true,
      ReturnSiteSafeData: true,
      ReturnPictures: this.state.includePhotos,
      ReturnClosedLeakSurveys: this.state.includeClosed,
      ReturnOpenLeakSurveys: this.state.includeOpen,
      Offset: - new Date().getTimezoneOffset() / 60,
      DataGroups,
      Projects,
      UseModifiedDate : this.state.useModifiedDate,
      FilterTaskStatus : this.state.statusFilter,
      FilterAssignment : this.state.assignmentFilter,
      FilterUser : this.state.userFilter,
      FilterDueDate : this.state.dueDateFilter,
      FilterTicketType : this.state.ticketTypeFilter
    }

    fetch(process.env.API_URL + "/api/data/batchDownload", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        Authorization:  "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
     .then(r => {
      if (r.status ==  200 ){
        this.setState({ statusText :"", updateStatusText : "Your download request has been received. An email will be sent to you shortly.", alertSeverity : "success"}); 
      }
      else if (r.status == 400){
        this.setState({ statusText : "", updateStatusText : "Your download request exceeds the data limit.  Narrow the scope by date or keyword and try again.", alertSeverity : "error"});
      }

    })
    .catch(error => {
      this.setState({ statusText : "", updateStatusText : error.message, alertSeverity : "error"});
    });
  }

  // generate a title for the details card from custom form data fields
  detailsCardMainTitle(element){

    var title = '';
    var fieldsUsed = 0;
    var fieldsToUse = 3;
    var i = 0;

    // iterate through fields to generate title from applicable data types, up to a certain field use count
    while (fieldsUsed < fieldsToUse && i < element.FormData.Fields.length) {
      var f = element.FormData.Fields[i];

      if (!f || (f.Type == 'Section' || f.Type == 'Signature')) { i++; continue;}

      if (f.Type == 'Date'){
        title += (f.Value && f.Value.length < this.state.maxFieldValueWidth) ? 
        new Date(f.Value).toLocaleString("en-us", { month: "short", day: "numeric", year: "numeric"}) + ' - ' : '';
        fieldsUsed++;
        i++;
      }
      else{
        title += (f.Value && f.Value.length < this.state.maxFieldValueWidth) ? f.Value + ' - ' : '';
        fieldsUsed++;
        i++;
      }
    }

    return title.substring(0, title.length - 2);
  }

  parseResultSet(response)
  {
    // VERIFY THE RESPONSE | is not empty
    if (response == null || response == undefined || Object.entries(response).length === 0) return;
    let compareArr = [];
    let picArr = [];
    let resultDataIn = [];
    let formDataIn = [];
    if (response.SiteSafeCollection == undefined) response.SiteSafeCollection = [];
    if (response.LeakSurveyCollection == undefined) response.LeakSurveyCollection = [];

    let totalCount = response.SiteSurveyCollection.length + response.InvestigationCollection.length + 
    response.GPSSurveyCollection.length + response.LeakSurveyCollection.length + response.SiteSafeCollection.length;

    console.log('parseResultSet', response);

    if(response.EntityCollections){
      this.setState({entityCollections : response.EntityCollections});
    }
    let formProjects = [{ProjectLabel : "No Project", showData : true}];
    let hasTickets = false;

    // SITE SURVEY
    if (response.SiteSurveyCollection) {

      response.SiteSurveyCollection.forEach((element) => {   
        if (element.CustomData == false || element.CustomData == undefined){
          element.picCount = element.MultimediaAssets ? element.MultimediaAssets.length : 0;
          element.Details = <button className="anchorButton padded" onClick={() => {this.viewEntity(element);}}><div className="text-center full">{this.state.buttonText}</div></button>;
          element.DetailsCard = <button id={"Site" + element.Id} className="anchorButton padded fullWidth" onClick={() => {this.viewEntity(element);}}>
            <div className="text-left full large">{(element.Address != null ? element.Address : '') + (element.City != null ? ', ' + element.City : '') + 
                            (element.State != null && element.State != '' ? ', ' + element.State : '')}</div>
            <div className="text-left full medium fadded">{element.Type} {element.Id}</div>
            <div className="text-left full medium fadded">{element.CreatedByDisplayName} - {new Date(element.ModifiedDate[element.ModifiedDate.length]-1 !="Z" ? element.ModifiedDate + "Z" : element.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"})}</div>
          </button>;
          element.ModifiedDateFormatted = new Date(element.ModifiedDate[element.ModifiedDate.length]-1 !="Z" ? element.ModifiedDate + "Z" : element.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
          element.ModifiedDateCreated = new Date(element.CreatedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
          element.SortableTimestamp = new Date(element.ModifiedDate + "Z").valueOf();
          element.SortableTimestampCreated = new Date(element.CreatedDate + "Z").valueOf();
          element.DirtReported = "-";
          element.Type = "Site";
          resultDataIn.push(element);
        }
        else {
          // CUSTOM SURVEY
          let buttonColor = "#305f8a";
          if(element.FormData.FormTypeId == 4){
            hasTickets = true;
            var timeTillDue = 10000000;
            buttonColor = "#282828";
            var dueDate = new Date(element.FormData.Fields.find(x => x.Name == "Due Date").Value);
            timeTillDue = (dueDate - new Date()) / 1000
            if(timeTillDue < 0){
              buttonColor = "#ac0d0d";
            }
            else if(timeTillDue < 86400){
              buttonColor = "#305f8a";
            }
            let task = response.FormTaskCollection.find(x => x.FormDataId == element.FormData.FormDataId);
            if(task && task.CloseDate){
              var pastDue = ((dueDate - new Date(task.CloseDate)) /1000) < 0;
              //If ticket has closed date, then color black if it was closed on time and red if it was closed past due date. 
              if(!pastDue) buttonColor = "#282828";
              else buttonColor = "#ac0d0d";
            }
            var ticketType = "";
            var typeField = element.FormData.Fields.find(x => x.Name == "Ticket Type");
            if(typeField) ticketType = typeField.Value;
            if(ticketType == "Emergency"){
              buttonColor = "#d56905";
            }
            
          }
          element.picCount = element.MultimediaAssets ? element.MultimediaAssets.length : 0,
          element.Details = <button className="anchorButton padded" style={{backgroundColor : buttonColor}} onClick={() => {this.viewEntity(element);}}><div className="text-center full">{this.state.buttonText}</div></button>
          element.DetailsCard = <button id={"Form" + element.FormData.FormDataId} className="anchorButton padded fullWidth" onClick={() => {this.viewEntity(element);}}>
          <div className="text-left full large">{this.detailsCardMainTitle(element)} </div>
            <div className="text-left full medium fadded">{element.FormData.Name} - {element.FormData.FormDataId}</div>
            <div className="text-left full medium fadded">{element.FormData.CreatedByDisplayName} - {new Date(element.ModifiedDate[element.ModifiedDate.length]-1 !="Z" ? element.ModifiedDate + "Z" : element.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"})}</div>
          </button>;
          element.ModifiedDateFormatted = new Date(element.ModifiedDate[element.ModifiedDate.length]-1 !="Z" ? element.ModifiedDate + "Z" : element.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
          element.SortableTimestamp = new Date(element.ModifiedDate + "Z").valueOf();
          element.SortableTimestampCreated = new Date(element.CreatedDate + "Z").valueOf();
          element.Type = "Form";
          let task = response.FormTaskCollection.find(x => x.FormDataId == element.FormData.FormDataId);
          if(task){
            element.FormTask = task;
            element.FormTaskStatus = task.FormTaskStatus == 0 ? "Open" : task.FormTaskStatus == 1 ? "Closed" : task.FormTaskStatus == 2 ? "Project" : "Canceled";
            element.FormTaskCloseDate = task.CloseDate ? new Date(task.CloseDate[task.CloseDate.length]-1 !="Z" ? task.CloseDate + "Z" : task.CloseDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"}): ""
          }
          

          let selectedProject = element.FormData.Fields && element.FormData.Fields.length > 0 && element.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down")  ? element.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down").Value : null;
          if(selectedProject && selectedProject != "" && !formProjects.find(x => x.ProjectLabel == selectedProject)){
            formProjects.push({ProjectLabel : selectedProject, showData : true});
          }
          formDataIn.push(element);
        }
      });
    }

    // GPS SURVEY
    if (response.GPSSurveyCollection && response.GPSSurveyCollection.FormDataCollection) {
      response.GPSSurveyCollection.FormDataCollection.forEach((element) => {
        
        let elementFormData = {FormData:element};
        
        //console.log('parsing form data', elementFormData);

        elementFormData.picCount = element.MultimediaAssets ? element.MultimediaAssets.length : 0,
        elementFormData.Details = <button className="anchorButton gpsSurvey padded" onClick={() => {this.viewEntity(element);}}><div className="text-center full">{this.state.buttonText}</div></button>
        elementFormData.DetailsCard = <button id={"GPS Survey" + element.FormDataId} className="anchorButton padded fullWidth gpsCountBlock" onClick={() => {this.viewEntity(element);}}>
        <div className="text-left full large">{this.detailsCardMainTitle(elementFormData)} </div>
          <div className="text-left full medium fadded">{elementFormData.FormData.Name} {elementFormData.FormData.FormDataId}</div>
          <div className="text-left full medium fadded">{element.CreatedByDisplayName} - {new Date(element.ModifiedDate[element.ModifiedDate.length]-1 !="Z" ? element.ModifiedDate + "Z" : element.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"})}</div>
        </button>;
        // element.DetailsCard = <button className="anchorButton padded fullWidth gpsCountBlock" onClick={() => {this.viewEntity(element);}}>
        //   <div className="text-left full large">{(element.Address != null ? element.Address : '') + (element.City != null ? ', ' + element.City : '') + 
        //                   (element.State != null && element.State != '' ? ', ' + element.State : '')}</div>
        //   <div className="text-left full medium fadded">{element.Type} {element.Id}</div>
        //   <div className="text-left full medium fadded">{new Date(element.ModifiedDate[element.ModifiedDate.length]-1 !="Z" ? element.ModifiedDate + "Z" : element.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"})}</div>
        // </button>;
        elementFormData.ModifiedDateFormatted = new Date(element.ModifiedDate[element.ModifiedDate.length]-1 !="Z" ? element.ModifiedDate + "Z" : element.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
        elementFormData.DirtReported = "-"
        elementFormData.ModifiedDateCreated = new Date(element.CreatedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
        elementFormData.SortableTimestamp = new Date(element.CreatedDate + "Z").valueOf();
        elementFormData.SortableTimestampCreated = new Date(element.CreatedDate + "Z").valueOf();
        elementFormData.DirtSubmitted = "-"
        elementFormData.Type = "GPS Survey";
        let selectedProject = element.Fields && element.Fields.length > 0 && element.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down")  ? element.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down").Value : null;
        if(selectedProject && selectedProject != "" && !formProjects.find(x => x.ProjectLabel == selectedProject)){
          formProjects.push({ProjectLabel : selectedProject, showData : true});
        }
        formDataIn.push(elementFormData);
      });
    }

    // LEAK SURVEY
    if (response.LeakSurveyCollection){
      response.LeakSurveyCollection.forEach((element) => {
        if(element.CustomData == false || element.CustomData == undefined){
          element.picCount = element.MultimediaAssets ? element.MultimediaAssets.length : 0,
          element.Details = <button className="anchorButton leakSurvey padded" onClick={() => {this.viewEntity(element);}}><div className="text-center full">{this.state.buttonText}</div></button>
          element.DetailsCard = <button id={element.Type + element.Id} className="anchorButton padded fullWidth leakCountBlock" onClick={() => {this.viewEntity(element);}}>
            <div className="text-left full large">{(element.Address != null ? element.Address : '') + (element.City != null ? ', ' + element.City : '') + 
                            (element.State != null && element.State != '' ? ', ' + element.State : '')}</div>
            <div className="text-left full medium fadded">{element.Type} {element.Id}</div>
            <div className="text-left full medium fadded">{element.CreatedByDisplayName} - {new Date(element.ModifiedDate[element.ModifiedDate.length]-1 !="Z" ? element.ModifiedDate + "Z" : element.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"})}</div>
          </button>;
          element.ModifiedDateFormatted = new Date(element.ModifiedDate[element.ModifiedDate.length]-1 !="Z" ? element.ModifiedDate + "Z" : element.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
          element.ModifiedDateCreated = new Date(element.CreatedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
          element.SortableTimestamp = new Date(element.ModifiedDate + "Z").valueOf();
          element.SortableTimestampCreated = new Date(element.CreatedDate + "Z").valueOf();
          element.DirtReported = "-";
          resultDataIn.push(element);
        }
        else{
          //CUSTOM SURVEY
          element.picCount = element.MultimediaAssets ? element.MultimediaAssets.length : 0;
          element.Details = <button className="anchorButton padded" onClick={()=>{this.viewEntity(element);}}><div className="text-center full">{this.state.buttonText}</div></button>;
          element.DetailsCard = <button id={"Form" + element.FormData.FormDataId} className="anchorButton padded fullWidth" onClick={() => {this.viewEntity(element);}}>
            <div className="text-left full large">{this.detailsCardMainTitle(element)}</div>
              <div className="text-left full medium fadded">{element.FormData.Name} {element.FormData.FormDataId}</div>
              <div className="text-left full medium fadded">{element.FormData.CreatedByDisplayName} - {new Date(element.ModifiedDate[element.ModifiedDate.length]-1 !="Z" ? element.ModifiedDate + "Z" : element.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"})}</div>
          </button>;
          element.ModifiedDateFormatted =  new Date(element.ModifiedDate[element.ModifiedDate.length]-1 !="Z" ? element.ModifiedDate + "Z" : element.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
          element.SortableTimeStamp = new Date(element.ModifiedDate + "Z").valueOf();
          element.SortableTimeStampCreated = new Date(element.CreatedDate + "Z").valueOf();
          element.Type = "Form";
          let selectedProject = element.FormData.Fields && element.FormData.Fields.length > 0 && element.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down")  ? element.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down").Value : null;
          if(selectedProject && selectedProject != "" && !formProjects.find(x => x.ProjectLabel == selectedProject)){
            formProjects.push({ProjectLabel : selectedProject, showData : true});
          }
          formDataIn.push(element);
        }
      });
    }

    // SITE SAFE
    if(response.SiteSafeCollection){

      response.SiteSafeCollection.forEach((element) =>{
        element.picCount = element.MultimediaAssets ? element.MultimediaAssets.length : 0,
        element.Details = <button className="anchorButton siteSafe padded" onClick={() => {this.viewEntity(element);}}><div className="text-center full">{this.state.buttonText}</div></button>
        element.DetailsCard = <button id={element.Type + element.Id} className="anchorButton padded fullWidth safetyCountBlock" onClick={() => {this.viewEntity(element);}}>
          <div className="text-left full large">{(element.Address != null ? element.Address : '') + (element.City != null ? ', ' + element.City : '') + 
                          (element.State != null && element.State != '' ? ', ' + element.State : '')}</div>
          <div className="text-left full large">{element.Type} {element.Id}</div>
          <div className="text-left full medium fadded">{element.CreatedByDisplayName} - {new Date(element.ModifiedDate[element.ModifiedDate.length]-1 !="Z" ? element.ModifiedDate + "Z" : element.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"})}</div>
        </button>;
        element.ModifiedDateFormatted = new Date(element.ModifiedDate[element.ModifiedDate.length]-1 !="Z" ? element.ModifiedDate + "Z" : element.ModifiedDate).toLocaleString("en-us", { month : "short", day:"numeric",year:"numeric", hour:"numeric",minute:"numeric"});
        element.ModifiedDateCreated = new Date(element.CreatedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
        element.SortableTimestamp = new Date(element.ModifiedDate + "Z").valueOf();
        element.SortableTimestampCreated = new Date(element.CreatedDate + "Z").valueOf();
        element.DirtReported = "-";
        element.EmployeeId = element.EmployeeIdDisplayName != null ? element.EmployeeIdDisplayName : element.EmployeeId;
        element.Address = element.EmployeeIdDisplayName != null ? element.EmployeeIdDisplayName :  element.EmployeeId;
        element.City = element.Passed ? "Passed" : "Failed";
        element.symptoms = element.FeverCough ? "Yes" : "No"
        element.travel = element.Traveled ? "Yes" : "No"
        element.contact = element.CloseContact ? "Yes" : "No"
        element.pass = element.Passed ? "Passed" : "Failed"
        resultDataIn.push(element);
      });
    }

    // INVESTIGATION
    if (response.InvestigationCollection) {
      response.InvestigationCollection.forEach((element) => {
        
        if (element.CustomData == false || element.CustomData == undefined) {

          // clear investigation pic collections
          picArr = [];
          compareArr = [];

          if (element.MultimediaAssets) {
            element.MultimediaAssets.forEach((pic, index) => {
              picArr.push({multimediaAssetId: pic.MultimediaAssetId,entityId: pic.EntityId, arrKey: index});
              if (pic.CompareMultimediaAssetId) 
              { compareArr.push({surveyMultimediaAssetId: pic.CompareMultimediaAssetId,
                investigationMultimediaAssetId: pic.MultimediaAssetId, ExternalURL: pic.ExternalURL, 
                CreatedDate: new Date(pic.CreatedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"})});
              }
            });
          }

          element.picCount = element.MultimediaAssets ? element.MultimediaAssets.length : 0,
          element.picArr = picArr;
          element.compareArr = compareArr;
          element.dirtConfigured = response.DirtConfigured
          element.Details = <button className="anchorButton submitButton incident padded" onClick={() => {this.viewEntity(element);}}><div className="text-center full">{this.state.buttonText}</div></button>;
          element.DetailsCard = <button id={element.Type + element.Id} className="anchorButton padded fullWidth incidentCountBlock" onClick={() => {this.viewEntity(element);}}>
            <div className="text-left full large">{(element.Address != null ? element.Address : '') + (element.City != null ? ', ' + element.City : '') + 
                            (element.State != null && element.State != '' ? ', ' + element.State : '')}</div>
            <div className="text-left full medium fadded">{element.Type} {element.Id}</div>
            <div className="text-left full medium fadded">{element.CreatedByDisplayName} - {new Date(element.ModifiedDate[element.ModifiedDate.length]-1 !="Z" ? element.ModifiedDate + "Z" : element.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"})}</div>
          </button>;
          element.ModifiedDateFormatted = new Date(element.ModifiedDate[element.ModifiedDate.length-1] !="Z" ? element.ModifiedDate + "Z" : element.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
          element.ModifiedDateCreated = new Date(element.CreatedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
          element.SortableTimestamp = new Date(element.ModifiedDate + "Z").valueOf();
          element.SortableTimestampCreated = new Date(element.CreatedDate + "Z").valueOf();

          if (!element.DirtSubmitted)
            element.DirtSubmitted = <Button className="anchorButton light" onClick={()=>{this.setState({statusText : "Uploading Investigation " + element.InvestigationId+" to DIRT..."});this.uploadDirt(element.InvestigationId)}}>submit</Button>;
          else
            element.DirtSubmitted = "SUBMITTED";

          resultDataIn.push(element);
        }
        else {
          // CUSTOM INVESTIGATION
          picArr = [];
          compareArr = [];

          if (element.MultimediaAssets) {
            element.MultimediaAssets.forEach((pic, index) => {
              picArr.push({multimediaAssetId: pic.MultimediaAssetId,entityId: pic.EntityId,arrKey: index});
              if (pic.CompareMultimediaAssetId) 
              { compareArr.push({surveyMultimediaAssetId: pic.CompareMultimediaAssetId,
                investigationMultimediaAssetId: pic.MultimediaAssetId, ExternalURL: pic.ExternalURL, 
                CreatedDate: new Date(pic.CreatedDate + "Z").toLocaleString("en-us", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"})});
              }
            });
          }
          element.picArr = picArr;
          element.compareArr = compareArr;
          element.picCount = element.MultimediaAssets ? element.MultimediaAssets.length : 0,
          element.Details = <button className="anchorButton submitButton incident padded" onClick={() => {this.viewEntity(element);}}><div className="text-center full">{this.state.buttonText}</div></button>;
          element.DetailsCard = <button id={"Form" + element.FormData.FormDataId} className="anchorButton padded fullWidth incidentCountBlock" onClick={() => {this.viewEntity(element);}}>
            <div className="text-left full large">{this.detailsCardMainTitle(element)}</div>
            <div className="text-left full medium fadded">{element.FormData.Name} {element.Id}</div>
            <div className="text-left full medium fadded">{element.FormData.CreatedByDisplayName} - {new Date(element.ModifiedDate[element.ModifiedDate.length]-1 !="Z" ? element.ModifiedDate + "Z" : element.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"})}</div>
          </button>;
          element.ModifiedDateFormatted = new Date(element.ModifiedDate[element.ModifiedDate.length]-1 !="Z" ? element.ModifiedDate + "Z" : element.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
          element.SortableTimestamp = new Date(element.ModifiedDate + "Z").valueOf();
          element.Type = "Form";
          let task = response.FormTaskCollection.find(x => x.FormDataId == element.FormData.FormDataId);
          if(task){
            element.FormTask = task;
          }
          let selectedProject = element.FormData.Fields && element.FormData.Fields.length > 0 && element.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down")  ? element.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down").Value : null;
          if(selectedProject && selectedProject != "" && !formProjects.find(x => x.ProjectLabel == selectedProject)){
            formProjects.push({ProjectLabel : selectedProject, showData : true});
          }
          formDataIn.push(element);
        }
      });
    }

    // SAVE TO STATE | for table to read
    this.setState({
      statusText: "",
      hasTickets,
      resultData: resultDataIn,
      formData: formDataIn,
      resultsCount: totalCount, 
      surveyCount: response.SurveyCount != null ? response.SurveyCount : response.SiteSurveyCollection.length,
      gpsCount: response.GPSCount != null ? response.GPSCount : response.GPSSurveyCollection.length,
      leakCount: response.LeakCount != null ? response.LeakCount : response.LeakSurveyCollection.length,
      incidentCount: response.IncidentCount != null ? response.IncidentCount : response.InvestigationCollection.length,
      safetyCount: response.SiteSafeCount != null ? response.SiteSafeCount : response.SiteSafeCollection ?  response.SiteSafeCollection.length :0,
      dirtConfigured: response.DirtConfigured,
      MaxResultsCount: response.MaxResultsCount,
      unfilteredResultData: resultDataIn,
      unfilteredFormData: formDataIn,
      formProjects
    },()=>{this.disableType()});
  }

  handleSort = (filters) => {
    // Finds "UPDATED" column in reporting data table and resets className
    if (filters.column !== "SortableTimestamp"){
      let element = document.querySelector("#siteright-rendered-timestamp");
      if(element) {element.className = "sorting";}
    }
  }
  
  handleClose() {
    
  }

  addCSSRule = (sheet, selector, rules, index = 1) => {
    if("insertRule" in sheet) {
      sheet.insertRule(selector + "{" + rules + "}", index);
    }
    else if("addRule" in sheet) {
      sheet.addRule(selector, rules, index);
    }
  }
  
  render() {
    var tableData;
    var addColumn;
    var siteDataReturned = false;
    var formColumns = [];
    var rowData = {};
    var formDataFormatted = {};
    var formDataIndicies = [];
    var _maxColumnDisplayCount = 9;
    var sortableTimestampColumns = [];
    var customFormList = [];
    let expandedCustomList = [];
    let defaultExpandedAmount = 10;
    if(this.state.unfilteredFormData.length > 0){
      this.state.unfilteredFormData.forEach((form) =>{
        if(form.FormData){
          let listIndex = customFormList.findIndex(x => x.FormId == form.FormData.FormId);
          if(listIndex > -1){
            let element = customFormList[listIndex];
            element.count = element.count + 1;
            customFormList[listIndex] = element;
          }
          else{
            customFormList.push({FormId: form.FormData.FormId, formName: form.FormData.Name, count: 1, 
              off: this.state.excludeCustomList.includes(form.FormData.FormId), color: form.FormData.Color});
          }
        }
      });
    }
    // CUSTOM FORMS
    if (this.state.formData.length > 0){
      
      this.state.formData.forEach((row) => {
          rowData = {Details:row.Details, FormTaskStatus: row.FormTaskStatus, FormTaskCloseDate : row.FormTaskCloseDate};

          // custom forms - fields
          let formId = row.FormData.FormId;
          if (formDataFormatted[row.FormData.FormId] == undefined){ 
            formColumns = [{label: '', field:'Details'}];
            formDataIndicies.push(row.FormData.FormId); // Array of keys
            if(row.FormData.FormTypeId == 4){
              var ticketNumberField = row.FormData.Fields.findIndex(x => x.Name == "Ticket Number");
              formColumns.push({label: "Ticket Number", field: ticketNumberField});
              var revField = row.FormData.Fields.findIndex(x => x.Name == "Rev");
              if(revField == -1) revField = row.FormData.Fields.findIndex(x => x.Name == "Ticket Revision");
              formColumns.push({label: "Ticket Revision", field: revField});
              var ticketTypeField = row.FormData.Fields.findIndex(x => x.Name == "Ticket Type");
              formColumns.push({label: "Ticket Type", field: ticketTypeField});
              formColumns.push({label: "Ticket Status", field: "FormTaskStatus"});
              var dueDateField = row.FormData.Fields.findIndex(x => x.Name == "Due Date");
              formColumns.push({label: "Due Date", field: dueDateField});
              formColumns.push({label: "Close Date", field: "FormTaskCloseDate"});
              var addressField = row.FormData.Fields.findIndex(x => x.Name == "Address");
              formColumns.push({label: "Address", field: addressField});
              var displayName = row.FormData.Fields.findIndex(x => x.Name == "Modified By");
              formColumns.push({label: "Modified By", field: displayName});
              var modifiedDate = row.FormData.Fields.findIndex(x => x.Name == "Modified Date");
              formColumns.push({label: "Modified Date", field: modifiedDate});
            }
            else{
              row.FormData.Fields.forEach((formField, fieldIndex) => {

                // MAX COLUMN DISPLAY COUNT check
                if (fieldIndex < _maxColumnDisplayCount) {
                  if (formField.Type === "Date"){
                    formColumns.push({ 
                      label: formField.Name, 
                      field: fieldIndex, 
                      attributes: {
                        id: `timestamp-toggle-${formField.Name}${fieldIndex}`,
                        onClick: async() => {
                          // timestamp column
                          let elem = document.querySelector(`table#reporting-form-${row.FormData.FormId} #sortable-timestamp-${formField.Name}${fieldIndex}`);
                          // display column
                          let self = document.querySelector(`table#reporting-form-${row.FormData.FormId} #timestamp-toggle-${formField.Name}${fieldIndex}`);
                          let tableRows = document.querySelectorAll(`table#reporting-form-${row.FormData.FormId} thead th`);
                          if(elem && self && tableRows){
                            // resets table header classnames
                            tableRows.forEach(element => {element.className = "sorting"});
                            // triggers sort on timestamp field
                            await elem.click();
                            // sets classname of display field
                            self.className = elem.className;
                          }
                        }
                      } 
                    });
                    sortableTimestampColumns.push({ 
                      label: `SortableTimestamp${formId}${sortableTimestampColumns.length}`, 
                      field: `SortableTimestamp${formId}${sortableTimestampColumns.length}`,
                      attributes: { id: `sortable-timestamp-${formField.Name}${fieldIndex}` }
                    });
                  } else if (formField.Type !== "Signature" && formField.Type !== "Section") {
                    formColumns.push({label: formField.Name, field: fieldIndex});
                  }
                }
              });
            }
            
            if(sortableTimestampColumns.length > 0){
              // formColumns = [...formColumns, ...sortableTimestampColumns];
              sortableTimestampColumns.forEach((col, index) =>{
                // hides all columns that are sortable timestamp
                formColumns.push(col);
                this.addCSSRule(document.styleSheets[0], `table#reporting-form-${formId} thead th:nth-last-child(${index + 1})`, "display: none !important;");
                this.addCSSRule(document.styleSheets[0], `table#reporting-form-${formId} td:nth-last-child(${index + 1})`, "display: none !important;");
              })
              sortableTimestampColumns = [];
            }
            if(!this.state.expandedCustomList){
              if(customFormList.find(x => x.FormId == row.FormData.FormId) && customFormList.find(x => x.FormId == row.FormData.FormId).count <= defaultExpandedAmount){
                expandedCustomList.push(row.FormData.Name);
              }
            }
            else if(this.state.expandedCustomList.includes(row.FormData.Name)){
              expandedCustomList.push(row.FormData.Name);
            }
            formDataFormatted[row.FormData.FormId] = {columns: formColumns, rows: [], name: row.FormData.Name, color: row.FormData.Color}; 
          }
          else
          {
            row.FormData.Fields.forEach((newColumn, fieldIndex) => {
              addColumn = row.FormData.FormTypeId != 4 && formDataFormatted[row.FormData.FormId].columns.length < _maxColumnDisplayCount;
              formDataFormatted[row.FormData.FormId].columns.forEach((existingColumn) => {
                // dont add existing columns or Signature columns to grid
                if (existingColumn.label == newColumn.Name || (newColumn.Type == "Signature" || newColumn.Type == "Section")) addColumn = false;
              });

              // finds first instance of SortableColumn
              let columnIndex = formDataFormatted[row.FormData.FormId] ? formDataFormatted[row.FormData.FormId].columns.findIndex(item => item.label && item.label.includes("SortableTimestamp")): -1;
              if (addColumn) {
                if (columnIndex === -1) columnIndex = formDataFormatted[row.FormData.FormId].columns.length;
                formDataFormatted[row.FormData.FormId].columns.splice(columnIndex, 0, { label: newColumn.Name, field: fieldIndex })
              }
            });
          }
          if(!this.state.expandedCustomList){
            this.setState({expandedCustomList});
          }

        // custom forms - rows
        let count = 0;
        row.FormData.Fields.forEach((rd, index) => {
          if (rd.Type == "Date"){
            rowData[index] = rd.Value != null ? new Date(rd.Value).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"}): '';
            rowData[`SortableTimestamp${formId}${count}`] = new Date(rd.Value).valueOf();
            count += 1;
          }
          else {
            // value check, to not add long data values (image/signature data)
            if (rd.Value && rd.Value.length < 100) rowData[index] = rd.Value;
          }
        });
        
        // ADD ROW
        formDataFormatted[row.FormData.FormId].rows.push(rowData);
        //console.log('formDataFormatted.columns',formDataFormatted.columns);
      });
    }

    // sets table data if resultData is populated
    if (this.state.resultData.length > 0)
    {
      // SITERIGHT COLUMNS
      siteDataReturned = (this.state.surveyCount > 0 || this.state.gpsCount > 0 || this.state.leakCount > 0 || this.state.incidentCount > 0 )
      tableData = siteDataReturned ? {
        columns: [
          { label: "", field: "Details" },
          { label: "Type", field: "Type" },
          { label: "Address", field: "Address", },
          { label: "City", field: "City", },
          { label: "State", field: "State", },
          { label: "Id", field: "Id", },
          { label: "Modified By", field: "ModifiedByDisplayName" },
          { label: "Created By", field: "CreatedByDisplayName" },
          { 
            label: "Updated", 
            field: "ModifiedDateFormatted",
            attributes: { 
              onClick: async () => {
                let elm = document.querySelector("#siteright-sortable-timestamp");
                let toSet = document.querySelector("#siteright-rendered-timestamp");
                await elm.click();
                if(toSet){ toSet.className = elm.className; }
              },
              id: "siteright-rendered-timestamp"
            } 
          },
          { 
            label: "Created", 
            field: "ModifiedDateCreated",
            attributes: {
              onClick: async () => {
                let elm = document.querySelector("#created-date-sortable-timestamp");
                let toSet = document.querySelector("#rendered-created-date-timestamp");
                await elm.click();
                if(toSet){ toSet.className = elm.className; }
              },
              id: "rendered-created-date-timestamp"
            } 
          },
          { label: "Sortable Timestamp", field: "SortableTimestamp", attributes: { id: "siteright-sortable-timestamp" } },
          { label: "Sortable Created Date Timestamp", field: "SortableTimestampCreated", attributes: { id: "created-date-sortable-timestamp" } }
        ],
        rows: this.state.resultData,
      }
      :
      // SITESAFE COLUMNS
      {
        columns: [
          { label: "", field: "Details"},
          { label: "Type", field: "Type"},
          { label: "Id", field: "EmployeeId"},
          { label: "Submitted By", field: "ModifiedByDisplayName"},
          { label: "Survey Date", field: "ModifiedDateFormatted"},
          { label: "Symptoms?", field: "symptoms"},
          { label: "Travel?", field: "travel"},
          { label: "Contact?", field: "contact"},
          { label: "Temperature", field: "Temperature"},
          { label: "Pass/Fail", field: "pass"},
          { label: "SortableTimestamp", field: "SortableTimestamp" }
        ],
        rows: this.state.resultData
      };
    }

    if (this.state.dirtConfigured && !siteDataReturned && formDataIndicies.length == 0 && tableData != null) tableData.columns.push({label: "DIRT", field:"DirtSubmitted"});

    return (
      <div className="col-10 d-flex justify-content-center p-0 m-0" style={{ backgroundColor: "#E9E9EF" }} >

        <Snackbar open={this.state.updateStatusText != ""} onClick={() => {this.setState({updateStatusText:''})}} autoHideDuration={6000} onClose={this.handleClose} 
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant="filled">
            {this.state.updateStatusText}
          </Alert>
        </Snackbar>

        <div className="container-fluid">
          <SearchStrip ref={x=>{this.searchRef = x}} 
            hasLicense ={this.hasLicense}
            searchRequest={this.searchRequest} 
            includePhotos={this.state.includePhotos} 
            updateIncludeClosed={this.updateIncludeClosed.bind(this)} 
            toggleDataGroup={this.toggleDataGroup}
            includeClosed={this.state.includeClosed} 
            updateIncludeOpen={this.updateIncludeOpen.bind(this)} 
            includeOpen={this.state.includeOpen}  
            expandGroup={this.expandGroup}
            includeDueDateFilter={this.state.hasTickets}
            includeTicketTypeFilter={this.state.hasTickets}
            disableType={this.disableType} 
            useModifiedDate={this.state.useModifiedDate} 
            updateUseModifiedDate={(value) =>{this.setState({useModifiedDate : value})}}
            userOptions={this.state.userOptions} 
            updateIncludePhotos={this.updateIncludePhotos.bind(this)} 
            resultsCount={this.state.resultsCount} 
            defaultDateRangeDays={this.state.dateRangeDays}
            surveyCount={this.state.surveyCount} 
            incidentCount={this.state.incidentCount} 
            filterUpdate={this.filterUpdate} 
            leakCount={this.state.leakCount}  
            dataGroups={this.state.dataGroups} 
            searching={this.state.statusText == "Retrieving Data..."}
            gpsCount={this.state.gpsCount} 
            safetyCount={this.state.safetyCount} 
            downloadRequest={this.downloadRequest} 
            reportingPage={true} customForms={customFormList} 
            formProjects={this.state.formProjects} 
            toggleProject={this.toggleProject}
            tagFormSearchOptions={this.state.tagFormSearchOptions}
            loadingTagFormSearchOptions={this.state.loadingTagFormSearchOptions}
            showAdvancedSearchButton={true}
          />
          
          <div className="row">
            {this.state.statusText == "" ? (
              
              // RESULTS DIV
              <div id="searchResultsDiv" className={"searchResultsDiv col full" + (!this.state.entityToView)}>
                <div className="searchResultsColumn">
                  {(this.state.MaxResultsCount && this.state.resultsCount > this.state.MaxResultsCount ) && 
                  <div style={{position: 'relative', maxWidth:0, minHeight:30 }}>
                    <div className="labelTopResults">Only displaying top {this.state.MaxResultsCount} results</div>
                  </div>}
                  {(!this.state.MaxResultsCount || (this.state.resultsCount < this.state.MaxResultsCount)) && <div style={{position : 'relative', maxWidth:0, minHeight:30}}></div>}
          
                  {/* DATA TABLE */}
                  {this.state.resultData.length > 0 && (!this.state.entityToView) ? (
                    <MDBDataTable id="reports-siteright-datatable" onSort={this.handleSort} className="mdbDataTable" searchLabel=" "
                    btn Button striped bordered hover data={tableData} sortable={true} paging={false}/>
                  ) : null}
                  
                  {/* DATA CARDS */}
                  {this.state.resultData.map((item, index) => (
                    <div key={'resultCardDiv' + index} className={"dataCards entityClosed" + (!this.state.entityToView)}>
                      <div className="cardMainDiv">
                        {item.DetailsCard}
                      </div>
                    </div>
                  ))}

                  {/* DATA CARDS (CUSTOM)*/}
                  {this.state.formData.map((item, index) => (
                    <div key={'resultCardDiv' + index} className={"dataCards entityClosed" + (!this.state.entityToView)}>
                      <div className="cardMainDiv">
                        {item.DetailsCard}
                      </div>
                    </div>
                  ))}

                  {/* CUSTOM FORMS TABLE */}
                  {formDataIndicies.length > 0 && !this.state.entityToView ?  (
                    this.state.statusText == "" ? (
                      <div id="searchResultsDivMultiMain4" className="searchResultsDivMultiMain4 mdbDataTable"> {
                      formDataIndicies.map((formDataIndex) => (
                        <div key={formDataIndex} className="searchResultsDivMulti">
                          <div className='searchResultsTableHeader' 
                            style={{display: 'inline', textShadow: '0px 0px 2px #000000'}}
                            onClick ={() =>{
                              let expanded = this.state.expandedCustomList;
                              if(expanded.includes(formDataFormatted[formDataIndex].name)){
                                let index = expanded.indexOf(formDataFormatted[formDataIndex].name);
                                expanded.splice(index, 1);
                              }
                              else{
                                expanded.push(formDataFormatted[formDataIndex].name)
                              }
                              this.setState({expandedCustomList: expanded});
                              }}>
                              
                              <div className="searchResultsTableHeaderCount" style={{backgroundColor: formDataFormatted[formDataIndex].color && formDataFormatted[formDataIndex].color != '' ? formDataFormatted[formDataIndex].color : '#587088' }}>
                                  {formDataFormatted[formDataIndex].rows.length}
                              </div>

                              {formDataFormatted[formDataIndex].name}

                              <div style={{display: 'inline', float: 'right', cursor: 'pointer'}} onClick ={() =>{
                                let expanded = this.state.expandedCustomList;
                                if(expanded.includes(formDataFormatted[formDataIndex].name)){
                                  let index = expanded.indexOf(formDataFormatted[formDataIndex].name);
                                  expanded.splice(index, 1);
                                }
                                else{
                                  expanded.push(formDataFormatted[formDataIndex].name)
                                }
                                this.setState({expandedCustomList: expanded});
                              }}>
                              {this.state.expandedCustomList && this.state.expandedCustomList.includes(formDataFormatted[formDataIndex].name) ? <KeyboardArrowUpIcon className="buttonIcon dark large"/>  :
                              <KeyboardArrowDownIcon className="buttonIcon dark large"/>}
                            </div>
                          </div>
                          <div id={formDataFormatted[formDataIndex].name} className="searchResultsColumnMulti" style={{display: this.state.expandedCustomList && this.state.expandedCustomList.includes(formDataFormatted[formDataIndex].name) ? '' : 'none' }}>
                            <MDBDataTable id={`reporting-form-${formDataIndex}`} btn button striped bordered hover data={formDataFormatted[formDataIndex]} sortable={true} paging={false} searchLabel=" "/>
                          </div>
                        </div>
                      ))}</div>
                      ) : null
                  ) : null 
                }
              </div>
            </div>) : null 
            }

            {/* Entity Details */}
            <div style={{display: "none"}} id="entityDetails" className="entityDetails">
              {this.state.entityToView ? 
                <div>
                  {this.state.entityToView.Type == "Site" ? 
                    <SurveyPage location={{survey: this.state.entityToView}} closeEntity={() =>{this.closeEntity()}}/>
                  : this.state.entityToView.Type =="Form" ? 
                    <FormDataPage location={{formData: this.state.entityToView }} userCollection={this.state.userOptions} closeEntity={() =>{this.closeEntity()}}/> 
                  : this.state.entityToView.Type == "Investigation" ? 
                    <InvestigationPage location={{incident: this.state.entityToView}} closeEntity={() =>{this.closeEntity()}}/>
                  : this.state.entityToView.Type == "Meter Inspection" || this.state.entityToView.Type == "Inaccessible" || this.state.entityToView.Type == "Abnormal Condition" || this.state.entityToView.Type == "Leak Survey" ? 
                    <LeakSurveyPage location={{leakSurvey: this.state.entityToView}} closeEntity={() =>{this.closeEntity()}}/>
                  : this.state.entityToView.Type == "SiteSafe" ? 
                    <SiteSafePage location={{siteSafe: this.state.entityToView}} closeEntity={() =>{this.closeEntity()}}/>
                  : this.state.entityToView.FormTypeId == 3 ? 
                    <FormDataPage location={{formData: {FormData: this.state.entityToView}}} closeEntity={() =>{this.closeEntity()}}/>
                  :<div>{this.state.entityToView.Type}</div>}
                </div> 
                :
                <div>
                  <button className ="buttonAction btn-primary btn buttonSearch" style={{float: "right", margin: 10}} onClick={() =>{this.closeEntity()}}>CLOSE</button>
                  <div className="text-center">Something went wrong.</div>
                </div>}
            </div>
          </div>

          {this.state.statusText != "" ? 
          <div className="row">
            <div className="spinnerDivUserPage">
              <div className="text-center authSpinnerDiv" style={{padding:10}}>
                  {this.state.statusText != "" ? <div className="authSpinnerDivMessage">
                    {this.state.statusText}
                  </div>: ''}
                <div className="barLoaderPortal">
                  <BarLoader sizeUnit={"px"} size={150} color={"#095399"}
                    loading={ this.state.statusText == "" ? false : this.state.statusText == "No Data Returned" ? false : true } />
                </div>
              </div>
            </div>
          </div> : null }
        </div>
      </div>
    );
  }
}

ReportingPage.propTypes = {
  forceRefresh: PropTypes.func
};

export default ReportingPage;