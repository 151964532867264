import React from "react";
import PropTypes from "prop-types";
import OrgUtility from '../../Utility/Utility'

class Contact extends React.Component {
    constructor(){
    super();
    }

    componentDidMount(){
    this.props.forceRefresh();
    var orgId = new OrgUtility().getOrgId();
    this.setState({orgId});
    }

    render(){
        return(
            <div className ="container-fluid justify-content-center align-content-center">
               <div className = "contactPageCenter">
                   <label className = "contactPageHeader">{this.state.orgId == 2 ? "Vivax Metrotech" : "LaunchPoint Solutions"}</label>
                   <label className = "contactPageContent">{this.state.orgId == 2 ? "" : "844-303-7453"}</label> 
                   <label className = "contactPageContent">{this.state.orgId == 2 ? <a href ="https://www.vivax-metrotech.com/support/">Support</a> : <a href ="mailto:support@golaunchpoint.com">Email Sales & Support</a>}</label>
                   <br/> 
               </div>
            </div>
        )
    }
}

Contact.propTypes ={
    forceRefresh: PropTypes.func
}

export default Contact;